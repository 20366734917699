import styled, {css} from 'styled-components'

export const StyledAssignmentDetails = styled.div(
    ({theme: {spacing, colors, typography}}) => css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        width: 100%;
        gap: ${spacing * 8}px;
        & h4 {
            ${typography.textMd};
            font-weight: 700;
            color: ${colors.lightBlack};
            margin-bottom: ${spacing * 4.5}px;
        }
        & .column-list {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 4}px;
        }
    `
)

export const StyledItem = styled.div(
    ({theme: {spacing, colors, typography}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        & span {
            ${typography.textSm};
            font-weight: 400;
            color: ${colors.darkGrey};
            min-width: 92px;
        }
        & p,
        a {
            overflow: hidden;
            word-wrap: normal;
            text-overflow: ellipsis;
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
        & a {
            text-decoration: underline;
            cursor: pointer;
        }
    `
)
