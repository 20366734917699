export const zIndex = {
    calendar: 0,
    calendarOffRange: 2,
    calendarError: 6,
    tooltip: 12,
    toolbar: 15,
    alerts: 16,
    header: 20,
    fullscreenImage: 30,
    dropdownMenu: 99,
    dropdownOverlay: 100,
    popover: 101,
    modalOverlay: 109,
    modal: 110,
    modalDatePicker: 111,
    snackbar: 120
} as const
