import {z} from 'zod'

export const AssignmentsCount = z.object({
    assignments_current: z.number().int(),
    assignments_request: z.number().int(),
    need_more_assets: z.number().int(),
    pending_assets: z.number().int(),
    pending_date: z.number().int()
})
export type AssignmentsCount = z.infer<typeof AssignmentsCount>
