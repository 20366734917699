import axios, {RawAxiosRequestHeaders} from 'axios'
import {ResponseTypeParam} from 'src/types.ts'

type HttpGetPaymentsDatesOptions = {
    params: ResponseTypeParam & {
        limit: number
        page: number
        from: string
        to: string
    }
    headers: RawAxiosRequestHeaders
}

export const httpGetPaymentsDates = ({params, headers}: HttpGetPaymentsDatesOptions) =>
    axios.get('/photographer-payments/payment-dates', {params, headers})

type HttpGetFreelancerPaymentsOptions = {
    params: ResponseTypeParam & {
        date: string
    }
    headers: RawAxiosRequestHeaders
}

export const httpGetFreelancerPayments = ({params, headers}: HttpGetFreelancerPaymentsOptions) => {
    return axios.get('/photographer-payments', {params, headers})
}
