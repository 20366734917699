import {TableButtonProps} from './TableButton.tsx'
import styled, {css} from 'styled-components'

export const StyledButton = styled.button<{
    $styleType: TableButtonProps['styleType']
    $background?: TableButtonProps['background']
    $iconPosition?: TableButtonProps['iconPosition']
}>`
    ${({theme: {spacing, mediaQuery, transition, palette}, $styleType, $background, $iconPosition}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing}px;
        flex-flow: ${$iconPosition == 'left' ? 'row' : 'row-reverse'};
        height: 28px;
        width: max-content;
        max-width: 100%;
        padding: ${spacing}px ${spacing * 2}px;
        border-radius: 8px;
        background: ${$background};
        border: 1px solid ${palette.neutral['300']}; /*todo: remove hardcoded color*/
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); /*todo: add a constant in the theme?*/

        &:not(:disabled) {
            ${transition};
            cursor: pointer;

            &:hover {
                border: 1px solid ${palette.neutral['500']};
            }
        }
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        & svg {
            color: ${$styleType == 'success'
                ? palette.success['600']
                : $styleType == 'error'
                ? palette.danger['600']
                : palette.neutral['500']};
            height: 16px;
        }

        ${mediaQuery.LG} {
            height: 40px;
            width: 100%;
        }
    `}
`

export const StyledText = styled.span<{$styleType?: TableButtonProps['styleType']}>`
    ${({theme: {palette, typography}, $styleType}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${$styleType == 'success'
            ? palette.success['600']
            : $styleType == 'error'
            ? palette.danger['600']
            : palette.neutral['500']};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`
