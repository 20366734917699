export const HARD_CANCELLATION_REASONS = [
    'request_by_mistake',
    'host_no_longer_interested',
    'the_listing_doesn_t_exist_anymore',
    'the_listing_has_been_already_photographed',
    'listing_not_ready',
    'other'
] as const

export const SOFT_CANCELLATION_REASONS = [
    'availabilities_dont_match',
    'too_far',
    'im_not_available_anymore',
    'not_comfortable',
    'by_mistake',
    'rescheduled_3_times',
    'other'
] as const
