import {InputText} from 'src/components/input-text/InputText.tsx'
import {useTranslation} from 'react-i18next'
import {Controller, useFormContext, useWatch} from 'react-hook-form'
import {CreateTaskValidationSchema} from 'src/features/admin-host-approval/components/create-task-modal/CreateTaskModal.tsx'
import {debounce, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {ChangeEvent, FC, ReactNode, useMemo, useState} from 'react'
import {httpGetListing} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {useAsync} from 'src/hooks/useAsync.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {StyledLink} from 'src/features/admin-host-approval/components/create-task-modal/listing-id-field/style.ts'

export const ListingIdField = () => {
    const {t} = useTranslation()
    const [fetchedListing, setFetchedListing] = useState<{title: string; id: number} | null>(null)
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')
    const form = useFormContext<CreateTaskValidationSchema>()
    const getListing = useAsync()

    const formListingId = useWatch({control: form.control, name: 'listing_id'})

    const onChange = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
        const listingId = event.target.value
        form.setValue('listing_id', listingId)
        void form.trigger('listing_id')

        if (!listingId) {
            setFetchedListing(null)
            return
        }
        try {
            const response = await getListing.run(
                httpGetListing({
                    headers: makeHttpAuthorizationHeader(adminAccessToken),
                    params: {listing_id: listingId}
                })
            )
            setFetchedListing(response.data)
        } catch (error) {
            return
        }
    }, 1000)

    const getListingStatusToHelpText = useMemo(
        () =>
            ({
                idle: undefined,
                loading: <Spinner size={14} />,
                error: formListingId ? <NotFoundHelpText listingId={formListingId} /> : undefined,
                success: fetchedListing?.title ?? <NotFoundHelpText listingId={formListingId} />
            } as const satisfies Record<ReturnType<typeof useAsync>['status'], ReactNode>),
        [fetchedListing, formListingId]
    )

    return (
        <Controller
            control={form.control}
            name="listing_id"
            render={() => (
                <InputText
                    type="text"
                    inputSize="sm"
                    placeholder={t('admin_host_approval:listing_id_field_placeholder')}
                    label={t('admin_host_approval:listing_id_field_label')}
                    errorMessage={form.formState.errors.listing_id?.message}
                    helpText={getListingStatusToHelpText[getListing.status]}
                    {...form.register('listing_id')}
                    onChange={onChange}
                />
            )}
        />
    )
}

const NotFoundHelpText: FC<{listingId: string}> = ({listingId}) => {
    const {t} = useTranslation()

    return (
        <Flexbox justify="space-between" fullWidth>
            <span>{t('admin_host_approval:listing_id_field_not_found')}</span>
            <StyledLink href={`https://admin.airbnb.com/rooms/${listingId}`} target="_blank">
                {t('admin_host_approval:open_in_airbnb_admin')}
            </StyledLink>
        </Flexbox>
    )
}
