import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'

import {Tab} from '../../../tab/Tab'
import {Spinner} from '../../../spinner-legacy/Spinner'

import {ChevronIcon} from '../../../../assets/icons/ChevronIcon'
import {formatLocaleDate, getMonthAndYear} from '../../../../helpers/utils'

import {StyledToolbar, StyledHeading, StyledNavBar, StyledChevronIcon, StyledTodaySpan} from './style'

export const CalendarToolbar = ({date, onChangeRange, onChangeMonth, loadingRange, fetchedMonths}) => {
    const {t} = useTranslation()
    const isPreviousMonthLoading =
        loadingRange && getMonthAndYear(dayjs(date).subtract(1, 'month')) === getMonthAndYear(loadingRange.start_date)

    const isNextMonthLoading =
        loadingRange && getMonthAndYear(dayjs(date).add(1, 'month')) === getMonthAndYear(loadingRange.end_date)

    const onPrevious = () => {
        const start = formatLocaleDate(dayjs(date).subtract(2, 'month').startOf('month'))
        const end = formatLocaleDate(dayjs(date).subtract(2, 'month').endOf('month'))

        if (!fetchedMonths.includes(getMonthAndYear(start))) onChangeRange(start, end)

        onChangeMonth(dayjs(date).subtract(1, 'month'))
    }

    const onToday = () => {
        onChangeMonth(dayjs(new Date()))
    }

    const onNext = () => {
        const start = formatLocaleDate(dayjs(date).add(2, 'month').startOf('month'))
        const end = formatLocaleDate(dayjs(date).add(2, 'month').endOf('month'))

        if (!fetchedMonths.includes(getMonthAndYear(start))) onChangeRange(start, end)

        onChangeMonth(dayjs(date).add(1, 'month'))
    }

    return (
        <StyledToolbar>
            <div>
                <StyledHeading>{formatLocaleDate(date, 'MMMM YYYY')}</StyledHeading>
            </div>
            <StyledNavBar>
                <Tab
                    iconPosition="right"
                    icon={
                        isPreviousMonthLoading ? (
                            <Spinner size={16} center={false} overlay={false} overlayFullscreen={false} />
                        ) : (
                            <ChevronIcon />
                        )
                    }
                    onClick={isPreviousMonthLoading ? Function.prototype : onPrevious}
                />
                <StyledTodaySpan onClick={onToday}>{t('calendar:today')}</StyledTodaySpan>
                <Tab
                    icon={
                        isNextMonthLoading ? (
                            <Spinner size={16} center={false} overlay={false} overlayFullscreen={false} />
                        ) : (
                            <StyledChevronIcon />
                        )
                    }
                    onClick={isNextMonthLoading ? Function.prototype : onNext}
                />
            </StyledNavBar>
        </StyledToolbar>
    )
}

CalendarToolbar.propTypes = {
    date: PropTypes.instanceOf(Date),
    onChangeRange: PropTypes.func,
    onChangeMonth: PropTypes.func,
    loadingRange: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string
    }),
    fetchedMonths: PropTypes.arrayOf(PropTypes.string)
}
