import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 5}px ${spacing * 8};
    `}
`

export const StyledOption = styled.div`
    ${({theme: {typography, colors, transition}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        ${typography.textSm};
        font-weight: 700;
        color: ${colors.lightBlack};
        ${transition};
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid ${colors.inputGrey};
        }

        &:hover {
            opacity: 0.8;
        }
    `}
`
