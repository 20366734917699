import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalTitle,
    ModalXCloseButton
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {FC, useRef} from 'react'
import {Button} from 'src/components/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {z} from 'zod'
import {FormProvider, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {ProjectField} from 'src/features/admin-host-approval/components/create-task-modal/project-field/ProjectField.tsx'
import {AccessibilityTypeField} from 'src/features/admin-host-approval/components/create-task-modal/task-type-field/AccessibilityTypeField.tsx'
import {HostIdField} from 'src/features/admin-host-approval/components/create-task-modal/host-id-field/HostIdField.tsx'
import {ListingIdField} from 'src/features/admin-host-approval/components/create-task-modal/listing-id-field/ListingIdField.tsx'
import {AssignedToField} from 'src/features/admin-host-approval/components/create-task-modal/assigned-to-field/AssignedToField.tsx'
import {useCreateAdminTask} from 'src/features/admin-host-approval/services/useCreateAdminTask.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'

export const CreateTaskValidationSchema = z.object({
    accessibility_type: z.coerce.number().int().positive(),
    accessibility_project: z.coerce.number().int().positive(),
    host_id: z.string().min(1),
    listing_id: z.string().min(1),
    assigned_to: z.coerce.number().int().positive()
})
export type CreateTaskValidationSchema = z.infer<typeof CreateTaskValidationSchema>

export interface CreateTaskModalProps {
    onClose: () => void
    defaultValues?: Partial<CreateTaskValidationSchema>
}

export const CreateTaskModal: FC<CreateTaskModalProps> = ({onClose, defaultValues}) => {
    const {t} = useTranslation()
    const modalRef = useRef<HTMLDivElement>(null)
    const form = useForm<CreateTaskValidationSchema>({
        mode: 'onChange',
        resolver: zodResolver(CreateTaskValidationSchema),
        defaultValues
    })
    const createAdminTaskMutation = useCreateAdminTask({onSuccess: onClose})

    const handleSubmit = form.handleSubmit(formValues =>
        createAdminTaskMutation.mutate({
            payload: {
                accessibility_project_id: formValues.accessibility_project,
                accessibility_type_id: formValues.accessibility_type,
                host_id: formValues.host_id,
                listing_id: formValues.listing_id,
                uploader_admin_id: formValues.assigned_to
            }
        })
    )

    return (
        <>
            <ModalOverlay onClick={onClose} />
            <Modal width="470px" ref={modalRef}>
                <ModalHeader>
                    <ModalXCloseButton onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <FormProvider {...form}>
                        <ModalTitle>{t('admin_host_approval:create_modal_title')}</ModalTitle>
                        <Flexbox direction="column" gap={4}>
                            <AccessibilityTypeField />
                            <ProjectField />
                            <HostIdField />
                            <ListingIdField />
                            <AssignedToField />
                        </Flexbox>
                    </FormProvider>
                </ModalBody>
                <ModalFooter>
                    <Button variant="tertiary" onClick={onClose}>
                        {t('commons:cancel')}
                    </Button>
                    <Button
                        variant="primaryGrayModern"
                        onClick={handleSubmit}
                        disabled={!form.formState.isValid || createAdminTaskMutation.isLoading}
                    >
                        {t('admin_host_approval:create_modal_cta')}
                        {createAdminTaskMutation.isLoading && <Spinner />}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
