import styled, {css} from 'styled-components'
import {Flexbox} from '../../../../components/flexbox/Flexbox'

export const StyledVideoUploadModalHeader = styled.h3(
    ({theme: {colors, typography, spacing}}) => css`
        color: ${colors.lightBlack};
        ${typography.textSm};
        font-weight: 700;
        padding: ${spacing * 4.5}px ${spacing * 6}px;
        text-align: center;
        position: relative;
        button {
            position: absolute;
            top: ${spacing * 2.5}px;
            left: ${spacing * 2}px;
        }
    `
)

export const StyledVideoUploadModalBody = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledVideoUploadModalFooter = styled.div(
    ({theme: {palette, spacing}}) => css`
        border-top: 1px solid ${palette.neutral['300']};
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)

export const StyledUploadingVideo = styled(Flexbox)(
    ({theme: {colors, typography, spacing}}) => css`
        h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
        b {
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
        a {
            cursor: pointer;
            text-underline-offset: ${spacing * 1.5}px;
            text-decoration: underline;
        }
    `
)

export const StyledProgressBarWrapper = styled(Flexbox)(
    ({theme: {colors, typography}, uploadProgress}) => css`
        width: 100%;
        div {
            height: 8px;
            border-radius: 4px;
        }
        & > div {
            background-color: ${colors.mercuryGrey};
            flex-grow: 1;
            & div {
                background-color: ${colors.airbnbRed};
                width: ${uploadProgress}%;
            }
        }
        span {
            width: 34px;
            text-align: right;
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
    `
)

export const StyledVideoCard = styled(Flexbox)(
    ({theme: {colors, spacing}}) => css`
        padding: ${spacing * 4}px;
        border: 1px solid ${colors.mercuryGrey};
        border-radius: 12px;

        button {
            width: 32px;
        }
    `
)

export const StyledVideoInfo = styled.div(
    ({theme: {typography, colors}}) => css`
        overflow-x: hidden;
        flex-grow: 1;

        h4,
        p {
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            ${typography.textSm};
        }

        h4 {
            font-weight: 500;
            color: ${colors.lightBlack};
        }
        p {
            color: ${colors.darkGrey};
        }
    `
)

export const StyledVideoPlayBox = styled(Flexbox)`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: linear-gradient(180deg, #a5c0ee 0%, #fbc5ec 100%);
    flex-shrink: 0;
`
