import {css} from 'styled-components'
import {colors} from './colors'

//todo: rename CustomScrollbar or move in another file cause it's not a component but just style
export const CustomScrollbar = css`
    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${colors.lightGrey};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.borderGrey};
        border-radius: 4px;

        &:hover {
            background-color: ${colors.grey};
        }
    }
`
