import {create} from 'zustand'
import {AccessibilityFeature, AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {ObjectFromEntries} from 'src/helpers/helpers'

export type AdminHostApprovalStore = {
    accessibilityFeaturesCounters: Record<AccessibilityFeature['internal_key'], number> | null
    initaccessibilityFeaturesCounters: (
        accessibilityFeatures: AccessibilityFeature[],
        adminHostApprovalTaskImages: NonNullable<AdminHostApprovalTaskImage[]>
    ) => void
}

export const useAdminHostApprovalStore = create<AdminHostApprovalStore>(set => ({
    accessibilityFeaturesCounters: null,
    initaccessibilityFeaturesCounters: (accessibilityFeatures, adminHostApprovalTaskImages) =>
        set({
            accessibilityFeaturesCounters: ObjectFromEntries(
                accessibilityFeatures.map(accessibilityFeature => [
                    accessibilityFeature.internal_key,
                    adminHostApprovalTaskImages.filter(image => image.amenity_id == accessibilityFeature.id).length
                ])
            )
        })
}))
