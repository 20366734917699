import {AssignmentDescriptionParagraph} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import styled, {css} from 'styled-components'

export const StyledAssignmentDescriptionParagraph = styled(AssignmentDescriptionParagraph)(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing}px;
    `
)
