import styled, {css} from 'styled-components'
import {BellIcon} from 'src/assets/icons/BellIcon.tsx'

export const TitleWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: ${spacing * 1.5}px;
    `}
`

export const StyledTitle = styled.h1`
    ${({theme: {typography, palette}}) => css`
        font-weight: 700;
        ${typography.textXl};
        color: ${palette.neutral.black};
    `}
`

export const StyledIcon = styled(BellIcon)`
    ${({theme: {mediaQuery}}) => css`
        position: absolute;
        left: -37px;
        ${mediaQuery.SM} {
            display: none;
        }
    `}
`

export const StyledExpand = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ${typography.textXs};
        font-weight: 500;
        text-transform: capitalize;
        color: ${palette.neutral['500']};
        gap: ${spacing}px;
        flex: 1;
    `}
`

export const TableWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        margin-top: ${spacing * 6}px;
        ${mediaQuery.SM} {
            margin-top: ${spacing * 4}px;
        }
    `}
`

export const StyledDates = styled.span`
    text-transform: capitalize;
`

export const StyledLocation = styled.a(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['900']};
        &:hover {
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledFirstCome = styled.div(
    ({theme: {colors, palette, spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 1.5}px;
        & span {
            color: ${colors.orange};
        }
        & svg {
            fill: ${palette.neutral['500']};
        }
    `
)
