import React, {ReactNode} from 'react'

type ErrorBoundaryProps = {
    fallback: ReactNode
    children: ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, {hasError: boolean}> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true}
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback
        }

        return this.props.children
    }
}
