import {forwardRef, InputHTMLAttributes} from 'react'
import {Input, LabelText, LabelWrapper, Root, TextError} from './style'

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'className'> {
    className?: string
    disabled?: boolean
    labelPosition?: 'left' | 'right'
    label?: string
    errors?: {message: string}
}

/**
 * @deprecated use the new CheckBox component instead
 * */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({className, label, labelPosition = 'right', disabled, errors, ...rest}, ref) => {
        return (
            <Root className={className}>
                <LabelWrapper $labelPosition={labelPosition}>
                    <Input type="checkbox" disabled={disabled} ref={ref} {...rest} />
                    {!!label && <LabelText $disabled={disabled}>{label}</LabelText>}
                </LabelWrapper>
                {!!errors && <TextError>{errors.message}</TextError>}
            </Root>
        )
    }
)
Checkbox.displayName = 'Checkbox'
