import styled, {css} from 'styled-components'
import {CALENDAR_VIEW} from './MySchedule'

export const MyScheduleWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}, type}) => css`
        display: flex;
        flex-direction: column;
        padding-bottom: ${spacing * 6}px;
        gap: ${type === CALENDAR_VIEW ? spacing * 10 : spacing * 3}px;
        ${mediaQuery.SM} {
            padding-bottom: 0;
        }
    `}
`

export const MyScheduleFiltersWrapper = styled.div`
    ${({theme: {spacing, colors, mediaQuery}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
        padding-bottom: ${spacing * 4}px;
        border-bottom: 1px solid ${colors.smokeGrey};

        ${mediaQuery.MD} {
            border: unset;
            align-items: flex-start;
            padding-bottom: 0;
        }

        ${mediaQuery.MD} {
            border: unset;
            align-items: flex-start;
            padding-bottom: 0;
        }
    `}
`

export const MyScheduleSwitchWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`
