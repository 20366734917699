import {AirbnbIcon, CalendarIcon, CancelIcon, CheckIcon, StatusIcon, UserIcon} from '../../../../../theme/icons'
import {useTranslation} from 'react-i18next'
import {
    errorHandler,
    formatLocaleDate,
    openLinkInNewTab,
    renderTableMatterportStatus,
    retrieveValueForRs
} from '../../../../../helpers/utils'
import {Table} from '../../../../../components/table-legacy/Table'
import {useCallback, useEffect, useState} from 'react'
import {ALERT_LEVELS, MATTERPORT_STATUSES, RESPONSE_TYPES, ROUTES} from '../../../../../helpers/constants'
import {debounce} from 'lodash-es'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from 'styled-components'
import {StyledEligibleRow} from './style'
import {Chip} from '../../../../../components/chip/Chip'
import {showAlert} from '../../../../../store/appGenerics'
import {
    httpClaimTask,
    httpChangeTaskAssignment,
    httpGetMatterportTable
} from '../../../../../http-requests/admin/matterport.http'
import {Spinner} from '../../../../../components/spinner-legacy/Spinner'
import {changeSingleTableCounter, setCurrentTask, usersAutocompleteSelector} from '../../../../../store/matterport'
import {SelectUncontrolled} from '../../../../../components/select/SelectUncontrolled'
import {useNavigate} from 'react-router'
import {TableControllers} from '../../../../../components/table-controllers/TableControllers'
import {useUserStore} from '../../../../../features/user/store'

export const UploadToMediaboxTable = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const usersAutocomplete = useSelector(usersAutocompleteSelector)
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [sorter, setSorter] = useState({
        order_by: 'created_at',
        order_direction: 'desc'
    })
    const [search, setSearch] = useState(null)
    const [selectSearch, setSelectSearch] = useState(null)
    const [showOnlyEligible, setShowOnlyEligible] = useState(false)
    const [tableIsLoading, setIsTableLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const tableColumns = [
        {
            name: 'external_id',
            label: t('commons:airbnb_id'),
            icon: <AirbnbIcon width={16} />,
            width: '20%',
            sortable: true
        },
        {
            name: 'uploader',
            label: t('commons:uploader'),
            icon: <UserIcon width={16} />,
            width: '30%',
            alignment: 'stretch',
            sortable: false,
            allowOverflow: true
        },
        {
            name: 'status',
            label: t('commons:status'),
            icon: <StatusIcon width={16} />,
            width: '25%',
            sortable: false
        },
        {
            name: 'created_at',
            label: t('commons:created_on'),
            icon: <CalendarIcon width={16} />,
            width: '15%',
            sortable: true
        },
        {
            name: 'exclude_for_category',
            label: t('admin:eligible'),
            width: '10%',
            sortable: false
        }
    ]

    useEffect(() => {
        if (page <= lastPage) getTableData()
    }, [page, sorter, search, selectSearch, showOnlyEligible, adminAccessToken])

    useEffect(() => {
        setPage(1)
    }, [sorter, search, selectSearch, showOnlyEligible])

    const toggleShowOnlyEligible = useCallback(() => {
        setShowOnlyEligible(!showOnlyEligible)
    }, [showOnlyEligible])

    const getTableData = async () => {
        try {
            setIsTableLoading(true)
            const {data} = await httpGetMatterportTable(adminAccessToken, {
                status: MATTERPORT_STATUSES.UPLOAD_MEDIABOX,
                limit: 30,
                page,
                ...(!!search && {search_by: 'code_external_id'}),
                search,
                response_type: RESPONSE_TYPES.EXTENDED,
                uploader_admin_id: selectSearch,
                show_only_eligible: showOnlyEligible,
                ...sorter
            })
            setTableData(page === 1 ? data.data : [...tableData, ...data.data])
            setLastPage(data?.last_page)
            dispatch(
                changeSingleTableCounter({
                    status: MATTERPORT_STATUSES.UPLOAD_MEDIABOX,
                    count: data?.total
                })
            )
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsTableLoading(false)
        }
    }

    const onSetPage = useCallback(() => {
        setPage(page + 1)
    }, [page])

    const onSortColumn = useCallback((columnName, orderDirection) => {
        setSorter({order_by: columnName, order_direction: orderDirection})
    }, [])

    const getRowLinkPath = ({id, uploader_admin_id}) =>
        !!id && !!uploader_admin_id ? ROUTES.ADMIN_MATTERPORT_UPLOADER.replace(':id', id) : null

    const onSubmit = debounce(e => {
        const value = e?.target?.value

        if (value?.length) {
            setSearch(value)
        } else {
            setSearch(null)
        }
    }, 750)

    const onSelectFilterSearch = selectedOption => {
        setSelectSearch(selectedOption?.value)
    }

    const onChangeTaskAssignment = async (selectedOption, taskId) => {
        try {
            setIsLoading(true)
            await httpChangeTaskAssignment(taskId, selectedOption.value, adminAccessToken)
            setTableData(
                tableData.map(row =>
                    row.id === taskId
                        ? {
                              ...row,
                              uploader_admin_id: selectedOption.value
                          }
                        : row
                )
            )
            dispatch(
                showAlert({
                    message: t('admin:assignment_changed'),
                    level: ALERT_LEVELS.SUCCESS
                })
            )
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const onClaimTask = async () => {
        try {
            setIsLoading(true)
            const {data} = await httpClaimTask(adminAccessToken, MATTERPORT_STATUSES.UPLOAD_MEDIABOX, showOnlyEligible)
            dispatch(setCurrentTask(data))
            dispatch(
                showAlert({
                    message: t('admin:claimed_task'),
                    level: ALERT_LEVELS.SUCCESS
                })
            )
            navigate(ROUTES.ADMIN_MATTERPORT_UPLOADER.replace(':id', data?.id))
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <Table
                data={tableData}
                isPaginated
                isLoading={tableIsLoading && tableData.length === 0}
                isFetchingNextPage={tableIsLoading && tableData.length >= 1}
                onChangePageCb={onSetPage}
                page={page}
                sorter={sorter}
                sortColumnCb={onSortColumn}
                columns={tableColumns}
                onClickRow={getRowLinkPath}
                headerComponent={
                    <TableControllers
                        showInputSearch
                        hasTopCta
                        onSearchCb={onSubmit}
                        searchInputPlaceholder={t('commons:search_airbnbid_mbid')}
                        selectFilter1={{
                            options: usersAutocomplete,
                            placeholder: t('admin:search_by_uploader'),
                            onChange: onSelectFilterSearch
                        }}
                        cta={{
                            text: t('admin:claim_task'),
                            onClick: onClaimTask
                        }}
                        showCheckbox
                        checkboxLabel={t('admin:eligible_for_accessibility')}
                        isCheckboxChecked={showOnlyEligible}
                        onCheckboxChange={toggleShowOnlyEligible}
                    />
                }
                tbodyHeight={`calc(${window.innerHeight}px - 451px)`}
                columnsRenderers={{
                    external_id: items => (
                        <span
                            style={{
                                color: theme.colors.red,
                                fontWeight: 700,
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                            onClick={e => {
                                e.stopPropagation()
                                openLinkInNewTab(items.airbnb_link)
                            }}
                        >
                            {'#'}
                            {items.entity?.external_id}
                        </span>
                    ),
                    uploader: items => (
                        <div onClick={e => e.stopPropagation()}>
                            <SelectUncontrolled
                                placeholder={t('admin:select_assignment')}
                                isClearable={false}
                                options={usersAutocomplete}
                                controlCustomStyle={{maxWidth: 264, width: '100%', minHeight: 40}}
                                onChangeCallback={value => onChangeTaskAssignment(value, items.id)}
                                defaultValue={retrieveValueForRs(usersAutocomplete, items.uploader_admin_id)}
                            />
                        </div>
                    ),
                    status: items => {
                        const {color, label} = renderTableMatterportStatus(items.status)

                        return (
                            <Chip
                                text={t(label)}
                                rounded
                                borderColor={color}
                                background={color}
                                color="white"
                                size="small"
                            />
                        )
                    },
                    created_at: items => <span>{formatLocaleDate(items.created_at)}</span>,
                    exclude_for_category: items => (
                        <StyledEligibleRow>
                            <span>{items.entity?.exclude_for_category ? t('commons:no') : t('commons:yes')}</span>
                            {items.entity?.exclude_for_category ? <CancelIcon /> : <CheckIcon />}
                        </StyledEligibleRow>
                    )
                }}
            />

            {isLoading && <Spinner overlayFullscreen />}
        </div>
    )
}
