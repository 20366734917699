import axios, {RawAxiosRequestHeaders} from 'axios'
import {ResponseTypeParam} from 'src/types.ts'

/*Get assignment*/
type HttpGetAssignmentOptions = {
    urlParams: {assignmentCode: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}
export const httpGetAssignment = ({urlParams, params, headers}: HttpGetAssignmentOptions) =>
    axios.get(`assignments/${urlParams.assignmentCode}`, {params, headers})

/*Accept assignment*/
type HttpAcceptAssignmentOptions = {
    urlParams: {assignmentId: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}
export const httpAcceptAssignment = ({urlParams, params, headers}: HttpAcceptAssignmentOptions) =>
    axios.get(`assignment/${urlParams.assignmentId}/accept`, {params, headers})

/*Decline assignment*/
export type HttpDeclineAssignmentOptions = {
    payload: {
        notes: string
        reason_id: number
    }
    urlParams: {assignmentId: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}
export const httpDeclineAssignment = ({urlParams, params, payload, headers}: HttpDeclineAssignmentOptions) =>
    axios.post(`assignment/${urlParams.assignmentId}/decline`, payload, {params, headers})

/*Get decline reasons*/
export const httpGetDeclineReasons = ({headers}: {headers: RawAxiosRequestHeaders}) =>
    axios.get('/reasons/decline', {headers})

/*Schedule assignment*/
export type HttpScheduleAssignmentOptions = {
    payload: {
        date: string
        timezone: string
    }
    params: ResponseTypeParam
    urlParams: {assignmentId: number}
    headers: RawAxiosRequestHeaders
}
export const httpScheduleAssignment = ({urlParams, payload, headers, params}: HttpScheduleAssignmentOptions) =>
    axios.post(`/assignment/${urlParams.assignmentId}/set-date`, payload, {params, headers})

/*Get unresponsive host reasons*/
type HttpGetUnresponsiveHostReasonsOptions = {
    headers: RawAxiosRequestHeaders
}
export const httpGetUnresponsiveHostReasons = ({headers}: HttpGetUnresponsiveHostReasonsOptions) =>
    axios.get('/reasons/host-unresponsive', {headers})

/*Get spaces*/
type HttpGetSpaces = {
    urlParams: {assignmentId: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpGetSpaces = ({urlParams, params, headers}: HttpGetSpaces) =>
    axios.get(`/assignment/${urlParams.assignmentId}/spaces`, {
        headers,
        params
    })

/*Update personal notes*/
export type HttpUpdatePersonalNotesOptions = {
    params: ResponseTypeParam
    urlParams: {assignmentId: number}
    headers: RawAxiosRequestHeaders
    payload: {notes: string}
}
export const httpUpdatePersonalNotes = ({urlParams, headers, params, payload}: HttpUpdatePersonalNotesOptions) =>
    axios.post(`/assignments/${urlParams.assignmentId}/notes`, payload, {
        params,
        headers
    })

/*Unschedule shooting*/
type HttpUnscheduleAssignment = {
    urlParams: {assignmentId: number}
    headers: RawAxiosRequestHeaders
    params: ResponseTypeParam
}

export const httpUnscheduleAssignment = ({urlParams, params, headers}: HttpUnscheduleAssignment) =>
    axios.get(`/assignment/${urlParams.assignmentId}/unschedule-task`, {
        params,
        headers
    })

/*Submit assets to Airbnb*/
export type HttpSubmitToAirbnbOptions = {
    params: {photographer_notes: string} & ResponseTypeParam
    urlParams: {assignmentId: number}
    headers: RawAxiosRequestHeaders
}
export const httpSubmitToAirbnb = ({urlParams, params, headers}: HttpSubmitToAirbnbOptions) =>
    axios.get(`/assignment/${urlParams.assignmentId}/assets-uploaded`, {
        params,
        headers
    })

/*Host responsive*/
export type HttpHostResponsiveOptions = {
    params: ResponseTypeParam
    urlParams: {assignmentId: number; taskId: number}
    headers: RawAxiosRequestHeaders
}
export const httpHostResponsive = ({urlParams, params, headers}: HttpHostResponsiveOptions) =>
    axios.patch(`/tasks/${urlParams.taskId}/assignments/${urlParams.assignmentId}/coordination/host-responsive`, null, {
        params,
        headers
    })

/*Coordination*/
export type HttpAssignmentCoordinationOptions = {
    params: ResponseTypeParam
    urlParams: {assignmentId: number; taskId: number}
    headers: RawAxiosRequestHeaders
    payload:
        | {
              status: 'host_is_unresponsive'
              reason_id: number
              notes?: string
          }
        | {
              status: 'host_is_not_ready' | 'photographer_not_available'
              date?: string
          }
        | {
              status: 'dates_dont_match' | 'manual_coordination' | 'host_contacted'
          }
}
export const httpAssignmentCoordination = ({urlParams, params, payload, headers}: HttpAssignmentCoordinationOptions) =>
    axios.patch(`tasks/${urlParams.taskId}/assignments/${urlParams.assignmentId}/coordination`, payload, {
        params,
        headers
    })

type HttpGetCancelReasonsOptions = {
    headers: RawAxiosRequestHeaders
}
export const httpGetCancelReasons = ({headers}: HttpGetCancelReasonsOptions) => axios.get(`reasons/cancel`, {headers})

type HttpDeleteAssignmentOptions = {
    urlParams: {assignmentId: number}
    params: {
        cancellation_type: 'soft_cancellation' | 'hard_cancellation'
        reason_id: number
        notes?: string
    }
    headers: RawAxiosRequestHeaders
}
export const httpDeleteAssignment = ({urlParams, params, headers}: HttpDeleteAssignmentOptions) => {
    return axios.delete(`/admin/assignments/${urlParams.assignmentId}`, {headers, params})
}
