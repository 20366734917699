import PropTypes, {shape} from 'prop-types'

import {Row} from '../row/TableRow'
import {TableLoader} from '../loader/TableLoader'
import {StyledTbody} from './style'
import {ErrorMessage} from '../../error-message/ErrorMessage'

import {TABLE_ALIGNMENTS} from '../../../../helpers/constants'

export const NotPaginatedBody = ({
    onClickRow,
    columns,
    columnsRenderers,
    expandedRowsIndexes,
    alternatingRowColors,
    isError,
    isLoading,
    onRefresh,
    tbodyHeight,
    data,
    variant
}) => {
    if (isError) {
        return (
            <StyledTbody $tbodyHeight={tbodyHeight}>
                <ErrorMessage onRefresh={onRefresh} />
            </StyledTbody>
        )
    }

    return (
        <StyledTbody $tbodyHeight={tbodyHeight} variant={variant}>
            {data.map((item, itemIndex) => {
                const clickableRowPath = onClickRow(item)

                return (
                    <Row
                        item={item}
                        itemIndex={itemIndex}
                        columns={columns}
                        columnsRenderers={columnsRenderers}
                        expandedRowsIndexes={expandedRowsIndexes}
                        alternatingRowColors={alternatingRowColors}
                        clickableRowPath={clickableRowPath}
                        key={itemIndex}
                        variant={variant}
                    />
                )
            })}

            {isLoading && <TableLoader columns={columns} />}
        </StyledTbody>
    )
}

NotPaginatedBody.propTypes = {
    alternatingRowColors: PropTypes.bool,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({width}, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            }
        })
    ).isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    tbodyHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClickRow: PropTypes.func,
    onRefresh: PropTypes.func,
    variant: PropTypes.oneOf(['default', 'grey'])
}
