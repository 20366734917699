import {Button} from 'src/components/button/Button.tsx'
import {AlertTriangleIcon} from 'src/components/icon'
import {useTranslation} from 'react-i18next'
import {useDeclineAssignment} from 'src/features/assignment/services/useDeclineAssignment.ts'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {useDeclineReasons} from 'src/features/assignment/services/useDeclineReasons.ts'
import {ErrorBox} from 'src/components/error-box/ErrorBox.tsx'
import {Radio} from 'src/components/radio/Radio.tsx'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Textarea} from 'src/components/textarea-legacy/Textarea'
import {
    ModalBody,
    ModalXCloseButton,
    ModalFooter,
    ModalHeader,
    ModalTitle
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {
    StyledDeclineModal,
    StyledDeclineModalBodyText,
    StyledSpinner
} from 'src/features/assignment/components/decline-modal/style.ts'

const ValidationSchema = z.object({
    reason_id: z.coerce.number().int().positive(),
    notes: z.string()
})
type ValidationSchema = z.infer<typeof ValidationSchema>

export const DeclineAssignmentModal = ({
    assignment,
    onClose,
    onSuccess,
    serviceType
}: {
    assignment: Assignment
    onSuccess: () => void
    onClose: () => void
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const declineAssignmentMutation = useDeclineAssignment(assignment, {onSuccess})
    const declineReasonsQuery = useDeclineReasons()
    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema)
    })

    const onSubmit = form.handleSubmit(values => {
        declineAssignmentMutation.mutate(values)
    })

    return (
        <StyledDeclineModal onOverlayClick={declineAssignmentMutation.isLoading ? undefined : onClose} width={480}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} disabled={declineAssignmentMutation.isLoading} />
            </ModalHeader>
            <ModalBody>
                {declineReasonsQuery.isLoading ? (
                    <StyledSpinner size={32} />
                ) : declineReasonsQuery.isError ? (
                    <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
                ) : (
                    <>
                        <StyledDeclineModalBodyText>
                            <ModalTitle>{t('assignment:decline:title')}</ModalTitle>
                            <p>{t(`assignment:decline:description:${serviceType}`)}</p>
                        </StyledDeclineModalBodyText>
                        <Flexbox direction="column" gap={2}>
                            {declineReasonsQuery.data.map(reason => (
                                <Radio
                                    {...form.register('reason_id')}
                                    label={t(
                                        `single_shooting:reasons:decline:${reason?.key_name.replace('reasons.', '')}`
                                    )}
                                    value={reason.id}
                                    key={reason.id}
                                />
                            ))}
                        </Flexbox>
                        <Textarea inputProps={form.register('notes')} label={t('commons:notes')} />
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} variant="tertiary" disabled={declineAssignmentMutation.isLoading}>
                    {t('commons:close')}
                </Button>
                <Button
                    type="submit"
                    disabled={declineAssignmentMutation.isLoading || !form.formState.isValid}
                    onClick={onSubmit}
                >
                    {t('assignment:decline:cta')}
                    {declineAssignmentMutation.isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </StyledDeclineModal>
    )
}
