import {CloseIcon} from '../../../../assets/icons/CloseIcon'
import {CheckIcon} from '../../../../assets/icons/CheckIcon'
import {StyledButton, StyledText} from './style'
import {ButtonHTMLAttributes, CSSProperties, ReactElement} from 'react'

export const TABLE_BUTTON_TYPES = {
    DEFAULT: 'default',
    SUCCESS: 'success',
    ERROR: 'error'
}

export interface TableButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    background?: CSSProperties['background']
    styleType?: 'default' | 'success' | 'error'
    text?: string
    icon?: ReactElement
    iconPosition?: 'left' | 'right'
}

export const TableButton = ({
    background = 'transparent',
    styleType,
    text,
    icon,
    iconPosition = 'left',
    ...rest
}: TableButtonProps) => {
    const defaultIcon =
        styleType == TABLE_BUTTON_TYPES.ERROR ? (
            <CloseIcon />
        ) : styleType == TABLE_BUTTON_TYPES.SUCCESS ? (
            <CheckIcon />
        ) : (
            <></>
        )

    return (
        <StyledButton
            type="button"
            $styleType={styleType}
            $background={background}
            $iconPosition={iconPosition}
            {...rest}
        >
            {defaultIcon}
            {!!icon && <>{icon}</>}
            <StyledText $styleType={styleType}>{text}</StyledText>
        </StyledButton>
    )
}
