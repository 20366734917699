import axios, {RawAxiosRequestHeaders} from 'axios'
import {Order, ResponseTypeParam} from 'src/types.ts'

type HttpGetAssignmentsCount = {
    headers: RawAxiosRequestHeaders
}
export const httpGetAssignmentCount = ({headers}: HttpGetAssignmentsCount) => axios.get('/assignments/count', {headers})

type HttpGetAssignmentsRequests = {
    headers: RawAxiosRequestHeaders
    params: ResponseTypeParam &
        Partial<Order> & {
            limit: number
            page: number
        }
}
export const httpGetAssignmentsRequests = ({params, headers}: HttpGetAssignmentsRequests) =>
    axios.get('/home/assignments_requests', {params, headers})
