import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {PageHelmet} from '../../../components/page-helmet/PageHelmet'
import {Assignment} from './assignment/Assignment'
import {SearchQuote} from '../../../components/assignments/search-quote-options-dropdown/SearchQuote'
import {AssignmentsContainer, AssignmentsWrapper} from './style'
import {Flexbox} from '../../../components/flexbox/Flexbox'
import {Divider} from '../../../components/divider/Divider'

export const AdminAssignments = () => {
    const {t} = useTranslation()
    const [selectedQuoteId, setSelectedQuoteId] = useState(null)
    const [hasQuoteData, setHasQuoteData] = useState(false)

    return (
        <AssignmentsWrapper $hasQuoteData={hasQuoteData}>
            <PageHelmet title={t('titles:admin_assignments')} />
            <AssignmentsContainer fullHeight={!hasQuoteData}>
                <Flexbox align="center" justify="space-between">
                    <h1>{t('admin:assignments:title')}</h1>
                    <SearchQuote onSelectQuoteId={setSelectedQuoteId} />
                </Flexbox>
                <Divider direction="horizontal" />
                <Assignment selectedQuoteId={selectedQuoteId} setHasQuoteData={setHasQuoteData} />
            </AssignmentsContainer>
        </AssignmentsWrapper>
    )
}
