import {FC, HTMLAttributes} from 'react'

export const ChevronDoubleIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 18, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3797 7.68279C12.636 7.93907 13.0515 7.93907 13.3078 7.68279C13.5641 7.42651 13.5641 7.01099 13.3078 6.75471L9.37029 2.81721C9.11401 2.56093 8.69849 2.56093 8.44221 2.81721L4.50471 6.75471C4.24843 7.01099 4.24843 7.42651 4.50471 7.68279C4.76099 7.93907 5.17651 7.93907 5.43279 7.68279L8.90625 4.20933L12.3797 7.68279ZM5.62029 10.3172C5.36401 10.0609 4.94849 10.0609 4.69221 10.3172C4.43593 10.5735 4.43593 10.989 4.69221 11.2453L8.62971 15.1828C8.88599 15.4391 9.30151 15.4391 9.55779 15.1828L13.4953 11.2453C13.7516 10.989 13.7516 10.5735 13.4953 10.3172C13.239 10.0609 12.8235 10.0609 12.5672 10.3172L9.09375 13.7907L5.62029 10.3172Z"
            fill="currentColor"
        />
    </svg>
)
