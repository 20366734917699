import {useState, useMemo} from 'react'
import {useWindowSize} from '@react-hook/window-size'
import {useTranslation} from 'react-i18next'
import {debounce} from 'lodash-es'
import {TabGroup} from '../../../../../components/tab-group/TabGroup'
import {Table} from '../../../../../components/table-legacy/Table'
import {InputText} from '../../../../../components/input-text/InputText'
import {AirbnbCell} from '../../../../../components/table-legacy/desktop/cell/airbnb/AirbnbCell'
import {StatusCell} from '../../../../../components/table-legacy/desktop/cell/status/Status'
import {ActionsCell} from '../../../../../components/table-legacy/desktop/cell/actions-cell/ActionsCell'
import {OpenButton} from '../../../../../components/table-legacy/desktop/button/open/OpenButton'
import {FILTER_STATUSES} from '../../../../../helpers/constants'
import {
    getNeedMoreAssetsColumns,
    getPendingAssetsColumns,
    getPendingShootDateColumns
} from '../../../../../helpers/columns'
import {screenWidth} from '../../../../../theme/breakpoints'
import {formatLocaleDate, getDesiredShootDates, openShootInNewTab} from '../../../../../helpers/utils'
import {useCurrentShoots} from '../../../../../hooks/useCurrentShoots'
import {useSelector} from 'react-redux'
import {languageSelector} from '../../../../../store/appGenerics'

import {ContainerWrapper, FiltersWrapper, StyledCoordinationStatusChip} from './style'
import {useAssignmentsCount} from '../../../../../features/assignments/services/useAssignmentsCount'
import {AlertCircleIcon, SearchLgIcon} from '../../../../../components/icon'
import {useTheme} from 'styled-components'
import {StatusIcon} from '../../../../../assets/icons/StatusIcon'

const coordinationStatusToLabel = {
    host_unresponsive: 'dashboard:calendar:table:coordination_statuses:host_unresponsive',
    host_not_ready: 'dashboard:calendar:table:coordination_statuses:host_not_ready',
    host_dates_shared: 'dashboard:calendar:table:coordination_statuses:host_dates_shared',
    photographer_not_available: 'dashboard:calendar:table:coordination_statuses:photographer_not_available',
    dates_dont_match: 'dashboard:calendar:table:coordination_statuses:dates_dont_match',
    manual_coordination: 'dashboard:calendar:table:coordination_statuses:manual_coordination',
    overdue_host_contacted: 'dashboard:calendar:table:coordination_statuses:overdue_host_contacted',
    host_contacted: 'dashboard:calendar:table:coordination_statuses:host_contacted',
    cancellation_requested: 'dashboard:calendar:table:coordination_statuses:cancellation_requested'
}

export const RequireMyAttention = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const language = useSelector(languageSelector)
    const [pageWidth, pageHeight] = useWindowSize()
    const isLG = pageWidth < screenWidth.LG
    const [shootStatus, setShootStatus] = useState(FILTER_STATUSES.PENDING_DATE)
    const [search, setSearch] = useState(null)

    const {
        data: tableData,
        page,
        onChangePage,
        sorter,
        onSortColumn,
        ...query
    } = useCurrentShoots({search, shootStatus})

    const {data: shootsCount} = useAssignmentsCount()
    const tabs = useMemo(
        () => [
            {
                status: FILTER_STATUSES.PENDING_DATE,
                text: t('statuses:pending_dates'),
                badgeText: shootsCount ? shootsCount.pending_date ?? 0 : '...',
                isSelected: shootStatus === FILTER_STATUSES.PENDING_DATE,
                callback: () => setShootStatus(FILTER_STATUSES.PENDING_DATE),
                columns: getPendingShootDateColumns()
            },
            {
                status: FILTER_STATUSES.PENDING_ASSETS,
                badgeText: shootsCount ? shootsCount.pending_assets ?? 0 : '...',
                text: t('statuses:pending_submission'),
                isSelected: shootStatus === FILTER_STATUSES.PENDING_ASSETS,
                callback: () => setShootStatus(FILTER_STATUSES.PENDING_ASSETS),
                columns: getPendingAssetsColumns()
            },
            {
                status: FILTER_STATUSES.ASSETS_REJECTED,
                text: t('statuses:need_more_assets'),
                badgeText: shootsCount ? shootsCount.need_more_assets ?? 0 : '...',
                isSelected: shootStatus === FILTER_STATUSES.ASSETS_REJECTED,
                callback: () => setShootStatus(FILTER_STATUSES.ASSETS_REJECTED),
                columns: getNeedMoreAssetsColumns()
            }
        ],
        [language, shootStatus, shootsCount]
    )

    const onSearch = debounce(e => {
        const value = e?.target?.value
        if (value?.length) {
            setSearch(value)
        } else {
            setSearch(null)
        }
    }, 500)

    const columns = useMemo(() => tabs.find(tab => tab.status === shootStatus)?.columns, [tabs, shootStatus])

    return (
        <ContainerWrapper>
            <FiltersWrapper>
                <TabGroup tabs={tabs} lastElementDivisor />
                <InputText
                    type="text"
                    placeholder={t('dashboard:calendar:table:search_by_listing_id')}
                    typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['400']} />}
                    onChange={onSearch}
                    inputSize="sm"
                    width={!isLG ? '280px' : '100%'}
                />
            </FiltersWrapper>
            <Table
                data={tableData}
                isPaginated
                isMobileVersion={isLG}
                isLoading={query.isLoading}
                isRefetching={query.isRefetching}
                isFetchingNextPage={query.isFetchingNextPage}
                onChangePageCb={onChangePage}
                page={page}
                sorter={sorter}
                sortColumnCb={onSortColumn}
                columns={columns}
                tbodyHeight={
                    pageWidth < screenWidth.LG ? `calc(${pageHeight}px - 160px)` : `calc(${pageHeight}px - 416px)`
                }
                columnsRenderers={{
                    external_id: item => <AirbnbCell value={item.entity?.external_id} item={item} />,
                    location: item => <span> {item.entity?.city ?? '-'}</span>,
                    host: item => <span>{item.host?.first_name}</span>,
                    status: item => {
                        // This is a temporary solution preceding the complete refactoring of the section
                        if (item?.task_status?.coordination_status === 'overdue_host_contacted') {
                            return (
                                <StyledCoordinationStatusChip
                                    text={t(coordinationStatusToLabel[item.task_status.coordination_status])}
                                    background={theme.palette.danger['50']}
                                    borderColor={theme.palette.danger['300']}
                                    color={theme.palette.danger['600']}
                                    icon={<AlertCircleIcon />}
                                    iconPosition="left"
                                    rounded
                                />
                            )
                        }
                        if (
                            item?.task_status?.coordination_status === 'manual_coordination' ||
                            item?.task_status?.coordination_status === 'host_dates_shared'
                        ) {
                            return (
                                <StyledCoordinationStatusChip
                                    text={t(coordinationStatusToLabel[item.task_status.coordination_status])}
                                    background={theme.palette.warning['50']}
                                    borderColor={theme.palette.warning['300']}
                                    color={theme.palette.warning['600']}
                                    icon={<AlertCircleIcon />}
                                    iconPosition="left"
                                    rounded
                                />
                            )
                        }
                        if (item?.task_status?.coordination_status) {
                            return (
                                <StyledCoordinationStatusChip
                                    text={t(coordinationStatusToLabel[item.task_status.coordination_status])}
                                    background={theme.palette.blue['50']}
                                    borderColor={theme.palette.blue['300']}
                                    color={theme.palette.blue['600']}
                                    icon={<StatusIcon />}
                                    iconPosition="left"
                                    rounded
                                />
                            )
                        }

                        return <StatusCell item={item} />
                    },
                    ...(shootStatus === FILTER_STATUSES.PENDING_DATE
                        ? {
                              host_availability_dates: item => (
                                  <span>{getDesiredShootDates(item.quote?.host_availability_dates)}</span>
                              )
                          }
                        : {
                              to_shoot_at: item => <span>{formatLocaleDate(item.to_shoot_at, 'MMM D, YYYY')}</span>
                          }),
                    actions: item => (
                        <ActionsCell>
                            <OpenButton showText={isLG} onClick={() => openShootInNewTab(item.code)} />
                        </ActionsCell>
                    )
                }}
            />
        </ContainerWrapper>
    )
}
