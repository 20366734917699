import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {Space} from 'src/features/assignment/types.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {httpGetSpaces} from 'src/features/assignment/services/assignment.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'

//TODO: remove queryKey and queryFn from options (using Omit) after the react-query upgrade
export const useGetSpaces = (assignmentId?: number, options?: UseQueryOptions<Space[]>) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.SPACES, assignmentId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetSpaces({
                    urlParams: {assignmentId: assignmentId ?? raise('The code is not provided')},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: z.array(Space),
                onZodError: captureException
            }),
        ...options
    })
}
