import {useDropzone} from 'react-dropzone'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {Image01Icon} from 'src/components/icon'
import {StyledAdminHostApprovalTaskDefaultTrigger} from 'src/features/admin-host-approval/components/admin-host-approval-task-uploader/style'
import {Button} from 'src/components/button/Button'
import {useUploadHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useUploadHostApprovalTaskImages'
import {ReactNode, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {showAlert} from 'src/store/appGenerics'
import {AdminHostApprovalTaskUploadModal} from 'src/features/admin-host-approval/components/admin-host-approval-task-upload-modal/AdminHostApprovalTaskUploadModal'
import {Dropzone} from 'src/components/dropzone/Dropzone'

const MAX_FILES = 100

export const AdminHostApprovalTaskUploader = ({taskId, trigger}: {taskId: number; trigger?: ReactNode}) => {
    const {t} = useTranslation()
    const [isUploadProgressModalOpen, setIsUploadProgressModalOpen] = useState(false)
    const [uploadingImagesCounter, setUploadingImagesCounter] = useState(0)
    const [progress, setProgress] = useState(0)
    const dispatch = useDispatch()

    const uploadPhotosMutation = useUploadHostApprovalTaskImages({
        taskId,
        onUploadProgress: progressEvent => {
            if (!progressEvent.total) return
            setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
        options: {
            onSettled: () => {
                setProgress(0)
                setIsUploadProgressModalOpen(false)
            }
        }
    })

    const dropzoneState = useDropzone({
        disabled: uploadPhotosMutation.status == 'loading',
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg']
        },
        multiple: true,
        maxFiles: MAX_FILES,
        onDropAccepted: acceptedFiles => {
            setUploadingImagesCounter(acceptedFiles.length)
            setIsUploadProgressModalOpen(true)
            const formData = new FormData()
            acceptedFiles.forEach(file => formData.append(`images[]`, file))
            uploadPhotosMutation.mutate(formData)
        },
        onDropRejected: rejectedFiles => {
            console.error('Rejected files: ', rejectedFiles)
            dispatch(
                showAlert({
                    message: t('host_approval_task:dropzone_rejected'),
                    level: 'error'
                })
            )
        }
    })

    return (
        <>
            <Dropzone state={dropzoneState}>
                {trigger ?? (
                    <StyledAdminHostApprovalTaskDefaultTrigger
                        $isDragActive={dropzoneState.isDragActive}
                        direction="column"
                        align="center"
                        justify="center"
                        gap={4}
                    >
                        <Flexbox direction="column" gap={1} align="center">
                            <Image01Icon size={32} />
                            <h3>{t('host_approval_task:dropzone_title')}</h3>
                            <em>{t('commons:or')}</em>
                            <Button variant="tertiary" shape="text" size="sm">
                                {t('host_approval_task:dropzone_cta')}
                            </Button>
                        </Flexbox>
                        <em>
                            <Trans i18nKey="commons:max_files_types" values={{files: MAX_FILES}} />
                        </em>
                    </StyledAdminHostApprovalTaskDefaultTrigger>
                )}
            </Dropzone>

            {isUploadProgressModalOpen && (
                <AdminHostApprovalTaskUploadModal progress={progress} uploadingImagesCounter={uploadingImagesCounter} />
            )}
        </>
    )
}
