import styled, {css} from 'styled-components'

export const StyledIconWrapper = styled.div(
    ({theme: {colors, palette}}) => css`
        border-radius: 8px;
        border: 1px solid ${colors.borderGrey};
        background: ${palette.neutral.white};
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    `
)
