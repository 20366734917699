import {useTheme} from 'styled-components'
import ContentLoader from 'react-content-loader'
import {StyledAdminHostApprovalImageCardSkeleton} from 'src/features/admin-host-approval/components/admin-host-approval-image-card/style'

export const AdminHostApprovalImageCardSkeleton: typeof ContentLoader = props => {
    const theme = useTheme()

    return (
        <StyledAdminHostApprovalImageCardSkeleton>
            <ContentLoader
                backgroundColor={theme.palette.neutral[100]}
                foregroundColor={theme.palette.neutral[200]}
                width="100%"
                height="100%"
                {...props}
            >
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
            </ContentLoader>
        </StyledAdminHostApprovalImageCardSkeleton>
    )
}

AdminHostApprovalImageCardSkeleton.displayName = 'AdminHostApprovalImageCardSkeleton'
