import {Outlet} from 'react-router-dom'
import {PrivateLayout} from '../containers/private-layout/PrivateLayout'
import {PublicLayout} from '../containers/public-layout/PublicLayout'
import {AdminLayout} from '../containers/admin-layout/AdminLayout'
import {useUserStore} from '../features/user/store'

export const SharedRoute = () => {
    const accessToken = useUserStore(store => store.accessToken)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    return adminAccessToken ? (
        <AdminLayout>
            <Outlet />
        </AdminLayout>
    ) : accessToken ? (
        <PrivateLayout>
            <Outlet />
        </PrivateLayout>
    ) : (
        <PublicLayout>
            <Outlet />
        </PublicLayout>
    )
}
