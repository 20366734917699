export const PlayButton = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_2263_247)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM9.28129 15.0348L15.4688 11.5764C15.9271 11.3202 15.9271 10.6798 15.4688 10.4236L9.28129 6.96516C8.82295 6.70898 8.25004 7.02921 8.25004 7.54157V14.4584C8.25004 14.9708 8.82295 15.291 9.28129 15.0348Z"
                fill="white"
                fillOpacity="0.3"
            />
        </g>
        <defs>
            <filter
                id="filter0_b_2263_247"
                x="-16"
                y="-16"
                width="54"
                height="54"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2263_247" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2263_247" result="shape" />
            </filter>
        </defs>
    </svg>
)
