import {CSSProperties, forwardRef, ReactNode} from 'react'
import {LinkProps} from 'react-router-dom'
import {ButtonStyleProps} from 'src/components/button/Button.tsx'
import {StyledButtonLink, StyledButtonLinkDisabled} from 'src/components/button-link/style.ts'

export type ButtonLinkProps = Omit<LinkProps, 'children' | 'className' | 'style'> &
    ButtonStyleProps & {
        children?: ReactNode
        className?: HTMLAnchorElement['className']
        disabled?: boolean
        style?: CSSProperties
    }

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
    ({fullWidth, shape = 'rectangle', size = 'md', to, variant = 'primary', ...props}, ref) =>
        props.disabled ? (
            <StyledButtonLinkDisabled
                ref={ref}
                $fullWidth={fullWidth}
                $shape={shape}
                $size={size}
                $variant={variant}
                {...props}
            />
        ) : (
            <StyledButtonLink
                ref={ref}
                to={to}
                $fullWidth={fullWidth}
                $shape={shape}
                $size={size}
                $variant={variant}
                {...props}
            />
        )
)
ButtonLink.displayName = 'ButtonLink'
