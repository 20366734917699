import {Calendar} from 'react-big-calendar'

import styled, {css} from 'styled-components'

export const StyledCalendarContainer = styled(Calendar)`
    ${({theme: {colors, zIndex, typography, palette}}) => css`
        /* BE CAREFUL: changing gaps, margins and heights can cause problems with renders */
        &.rbc-calendar {
            color: ${colors.lightBlack};
            min-width: 950px;
            border: unset;
            gap: 43px; // important!!!!
            width: 100%;
        }

        & .rbc-month-header {
            height: auto !important;
            text-transform: capitalize;
        }

        & .rbc-overlay {
            & .rbc-event {
                background: transparent !important;
                background-color: transparent !important;
            }
        }

        & .rbc-row {
            height: 36px; // important!!!!
        }

        & .rbc-row-segment {
            height: 34px; // important!!!!
        }

        & .rbc-event {
            background: transparent !important;
        }

        & .rbc-month-row {
            border-top: 0;
            border-right: 1px solid ${colors.gainsboroGrey};
            min-height: 145px;
        }

        & .rbc-month-view {
            border: unset;
            min-width: 950px;
        }

        & .rbc-event:focus {
            outline: unset;
        }

        & .rbc-header {
            ${typography.textSm};
            border: unset;
            font-weight: 400;
            color: ${colors.steelGrey};
            padding-bottom: 16px; /* BE CAREFUL: changing gaps, margins and heights can cause problems with renders */
            border-bottom: 1px solid ${colors.gainsboroGrey};
        }

        & .rbc-day-bg {
            padding: 4px; /* BE CAREFUL: changing gaps, margins and heights can cause problems with renders */
            border-left: 1px solid ${colors.gainsboroGrey};
            border-bottom: 1px solid ${colors.gainsboroGrey};
            background: ${palette.neutral.white};
        }

        & .rbc-off-range-bg {
            z-index: ${zIndex.calendarOffRange};
            pointer-events: none;
            cursor: unset;
        }

        & .rbc-today {
            background: ${palette.neutral.white};
        }

        & .rbc-button-link {
            ${typography.textMd};
            text-align: center;
            font-weight: 500;
            color: ${palette.neutral.black};
            margin: 6px 4px 0 2px; /* BE CAREFUL: changing gaps, margins and heights can cause problems with renders */
            cursor: unset;
        }

        & .rbc-show-more {
            ${typography.textXs};
            font-weight: 500;
            margin: 5px 8px; /* BE CAREFUL: changing gaps, margins and heights can cause problems with renders */
            cursor: pointer;
            color: ${colors.darkGrey};
            position: relative;
        }

        & .rbc-now > .rbc-button-link {
            text-align: center;
            background: ${colors.airbnbDarkPink};
            height: 30px;
            width: 30px;
            color: white;
            border-radius: 50%;
            cursor: unset;
        }

        & .rbc-date-cell {
            cursor: unset;
        }

        & .rbc-off-range > button {
            ${typography.textMd};
            color: ${colors.darkGrey};
            pointer-events: none !important;
        }
    `}
`
