import styled, {css} from 'styled-components'
import {Container} from 'src/components/container/Container.tsx'

export const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledErrorWrapper = styled.div`
    ${({theme: {spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: ${spacing * 6}px;
        max-width: 1024px;

        & h1 {
            ${typography.displayXs};
            font-weight: 600;
            text-align: center;
        }
    `}
`
