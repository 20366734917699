import styled, {css} from 'styled-components'
import {Container} from '../../../components/container/Container'

export const PartnersWrapper = styled(Container)`
    ${({theme: {spacing}}) => css`
        padding-top: ${spacing * 15}px;
        padding-bottom: ${spacing * 15}px;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        overflow: hidden;
        gap: ${spacing * 8}px;
    `}
`

export const StyledHeadingPartner = styled.div`
    ${({theme: {typography, colors}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        & h1 {
            ${typography.displayXs};
            font-weight: 600;
            color: ${colors.lightBlack};
        }
    `}
`

export const StyledActions = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
    `}
`
