import styled, {css} from 'styled-components'
import {PAYMENT_AMOUNT_TYPES} from '../../../private/dashboard/active-shoots-sections/my-schedule/completed/transactions-list/TransactionsList'

export const StyledColumns = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) 400px;
        gap: ${spacing * 4}px;
        width: 100%;

        & > *:nth-child(2) {
            grid-row: span 2;
        }
        & > *:nth-child(4),
        & > *:nth-child(5) {
            grid-column: span 2;
        }
    `
)

export const StyledAssignmentCardDetailsItem = styled.div(
    ({theme: {colors, typography}, width}) => css`
        display: flex;
        min-width: ${width || 120}px;
        flex-direction: column;

        & span {
            ${typography.textSm};
            font-weight: 400;
            color: ${colors.darkGrey};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        & p,
        & a {
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
    `
)

export const StyledStatusCell = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        align-items: start;
        flex-direction: column;
    `
)

export const StyledDetailCell = styled.span(
    ({theme: {colors, typography}}) => css`
        ${typography.textSm};
        font-weight: 400;
        color: ${colors.darkGrey};
    `
)

export const StyledSubStatusLabel = styled.span(
    ({theme: {colors, typography}}) => css`
        ${typography.textXs};
        font-weight: 400;
        color: ${colors.darkGrey};
        overflow-x: hidden;
        text-overflow: ellipsis;
    `
)

//TODO: make a common component
export const StyledTransactionAmount = styled.span`
    ${({type, theme: {colors}}) => css`
        font-weight: 500;
        color: ${type === PAYMENT_AMOUNT_TYPES.POSITIVE
            ? colors.darkGreen
            : type === PAYMENT_AMOUNT_TYPES.NEGATIVE
            ? colors.darkRed
            : colors.lightBlack};
    `}
`

export const ImageWrapper = styled.div(
    ({theme: {typography, spacing}}) => css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 8}px;

        & img {
            width: 220px;
        }
        & p {
            width: 316px;
            text-align: center;
            ${typography.textXl};
            font-weight: 500;
        }
    `
)

export const StyledNoResults = styled.h3(
    ({theme: {typography, colors}}) => css`
        ${typography.textXl};
        text-align: center;
        font-weight: 500;
        color: ${colors.grey};
    `
)

export const StyledExpandableRowDetails = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: ${spacing * 6}px;
        grid-row-gap: ${spacing * 4}px;
        padding: ${spacing * 2}px ${spacing * 6}px ${spacing * 4}px;
    `
)
