import {useUserStore} from 'src/features/user/store.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {useCallback, useState} from 'react'
import {CURRENT_MONTH, CURRENT_YEAR, FILTERS_STARTING_YEAR} from 'src/pages/private/payments/FreelancerPayments.tsx'
import {httpGetPaymentsDates} from 'src/features/freelancer-payments/services/freelancer-payments.http.ts'
import {captureException} from '@sentry/react'
import {FreelancerPaymentDate} from 'src/features/freelancer-payments/types.ts'

interface UseFreelancerPaymentsDatesParameters {
    months: {label: string; value: string}[]
}

export const useFreelancerPaymentsDates = ({months}: UseFreelancerPaymentsDatesParameters) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const [filters, setFilters] = useState<{
        from: string
        to: string
    }>({
        from: `${FILTERS_STARTING_YEAR}-${months[5].value}`,
        to: `${CURRENT_YEAR}-${months[CURRENT_MONTH].value}`
    })
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.FREELANCER_PAYMENTS_DATES, filters],
        queryFn: ({pageParam}) =>
            parseAxiosPromise({
                axiosPromise: httpGetPaymentsDates({
                    params: {
                        limit: 50,
                        page: pageParam ?? 1,
                        from: filters.from,
                        to: filters.to,
                        response_type: 'regular'
                    },
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                onZodError: captureException,
                responseShape: FreelancerPaymentDate
            })
    })

    const changePage = () => {
        const currentPage = query.data?.pages.at(-1)?.current_page ?? 1
        const lastPage = query.data?.pages.at(-1)?.last_page ?? 1
        if (!query.isFetching) {
            if (currentPage < lastPage) {
                void query.fetchNextPage({pageParam: currentPage + 1})
            }
        }
    }

    const changeFilters = useCallback(
        (formValues: {monthFrom: string; yearFrom: string; monthTo: string; yearTo: string}) => {
            const filtersFormatted = {
                from: `${formValues.yearFrom}-${formValues.monthFrom}`,
                to: `${formValues.yearTo}-${formValues.monthTo}`
            }
            setFilters(filtersFormatted)
        },
        []
    )

    return {
        ...query,
        remappedData: query.data ? query.data.pages?.flatMap(page => page.data) : [],
        totalPaidOut: query.data?.pages?.at(-1)?.paid_out,
        changePage,
        changeFilters,
        filters
    }
}
