import styled, {css} from 'styled-components'
import * as RSwitch from '@radix-ui/react-switch'
import {Flexbox} from 'src/components/flexbox/Flexbox'

type StyledSwitchSize = 'sm' | 'md'

export const StyledSwitchWrapper = styled(Flexbox)(
    ({theme: {typography}}) => css`
        white-space: nowrap;
        label {
            font-weight: 600;
            ${typography.textSm};
        }
    `
)

export const makeSwitchRootSizeStyle = () =>
    ({
        sm: css`
            width: 36px;
            height: 20px;
        `,
        md: css`
            width: 44px;
            height: 24px;
        `
    } as const satisfies Record<NonNullable<StyledSwitchSize>, ReturnType<typeof css>>)

export const StyledSwitchRoot = styled(RSwitch.Root)<{$size: StyledSwitchSize}>(
    ({theme: {palette}, $size}) => css`
        background-color: ${palette.neutral[300]};
        border-radius: 48px;
        position: relative;
        transition: background-color 100ms;
        will-change: background-color;
        cursor: pointer;
        &[data-state='checked'] {
            background-color: ${palette.grayModern[600]};
        }
        ${makeSwitchRootSizeStyle()[$size]}
    `
)

export const makeSwitchThumbSizeStyle = () =>
    ({
        sm: css`
            width: 16px;
            height: 16px;
            &[data-state='checked'] {
                transform: translateX(18px);
            }
        `,
        md: css`
            width: 20px;
            height: 20px;
            &[data-state='checked'] {
                transform: translateX(22px);
            }
        `
    } as const satisfies Record<NonNullable<StyledSwitchSize>, ReturnType<typeof css>>)

export const StyledSwitchThumb = styled(RSwitch.Thumb)<{$size: StyledSwitchSize}>(
    ({$size}) => css`
        display: block;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 100%;
        transition: transform 100ms;
        transform: translateX(2px);
        will-change: transform;
        box-shadow: 0px 1px 3px 0px #1018281a;
        ${makeSwitchThumbSizeStyle()[$size]}
    `
)
