import {z} from 'zod'

export const AccessibilityType = z.object({
    id: z.number().int().positive(),
    key_name: z.string().min(1),
    name: z.string().min(1)
})
export type AccessibilityType = z.infer<typeof AccessibilityType>

export const AccessibilityProject = z.object({
    id: z.number().int().positive(),
    key_name: z.string().min(1),
    name: z.string().min(1)
})
export type AccessibilityProject = z.infer<typeof AccessibilityProject>

export const AccessibilityFeature = z.object({
    id: z.coerce.number(),
    name: z.string(),
    description: z.string(),
    internal_key: z.enum([
        'HOME_STEP_FREE_ACCESS',
        'DISABLED_PARKING_SPOT',
        'WIDE_DOORWAY',
        'FLAT_SMOOTH_PATHWAY_TO_FRONT_DOOR',
        'BEDROOM_STEP_FREE_ACCESS',
        'BEDROOM_WIDE_DOORWAY',
        'BATHROOM_STEP_FREE_ACCESS',
        'WALK_IN_SHOWER',
        'BATHROOM_WIDE_DOORWAY',
        'TOILET_GRAB_BAR',
        'SHOWER_CHAIR',
        'CEILING_HOIST',
        'FLOOR_PLAN',
        'SHOWER_GRAB_BAR',
        'POOL_HOIST'
    ])
})
export type AccessibilityFeature = z.infer<typeof AccessibilityFeature>

export const Room = z.object({
    id: z.coerce.number(),
    name: z.string(),
    internal_name: z.enum(['EXTERIOR', 'BEDROOM', 'FULL_BATHROOM', 'ADAPTIVE_EQUIPMENT', 'EXTRA', 'HALF_BATHROOM'])
})
export type Room = z.infer<typeof Room>

export const AdminHostApprovalTaskImage = z.object({
    id: z.number().int().positive(),
    task_id: z.number().int().positive().nullable(),
    uploader_id: z.number().int().positive().nullish(),
    room_id: z.number().int().positive().nullable(),
    amenity_id: z.number().int().positive().nullable(),
    name: z.string(),
    is_host_approved: z.boolean().nullable(),
    from_photo_gallery: z.boolean(),
    is_uploaded: z.boolean(),
    path_location: z.string(),
    url: z.string().url()
})
export type AdminHostApprovalTaskImage = z.infer<typeof AdminHostApprovalTaskImage>

export const AdminHostApprovalTask = z.object({
    accessibility_project: AccessibilityProject,
    accessibility_type: AccessibilityType,
    approved_at: z.string().min(1).nullable(),
    created_at: z.string().min(1),
    completed_at: z.string().min(1).nullable(),
    elevate_url: z.string().url().nullable(),
    external_host_id: z.string().min(1),
    external_id: z.string().min(1),
    entity: z
        .object({
            title: z.string().min(1).nullable()
        })
        .nullable(),
    host: z
        .object({
            first_name: z.string().min(1).nullable()
        })
        .nullable(),
    id: z.coerce.number().int().positive(),
    publisher_admin: z
        .object({
            first_name: z.string(),
            id: z.number().int().positive(),
            last_name: z.string()
        })
        .nullable(),
    reviewer_admin: z
        .object({
            first_name: z.string(),
            id: z.number().int().positive(),
            last_name: z.string()
        })
        .nullable(),
    status: z.enum(['new', 'pending_host_approval', 'ready_for_review', 'completed']),
    uploader_admin: z
        .object({
            first_name: z.string(),
            id: z.number().int().positive(),
            last_name: z.string()
        })
        .nullable()
})
export type AdminHostApprovalTask = z.infer<typeof AdminHostApprovalTask>
