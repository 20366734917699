import PropTypes from 'prop-types'

import {TableView} from './table/Table'
import {CalendarView} from './calendar/Calendar'
import {CALENDAR_VIEW} from '../MySchedule'

export const ActiveTab = ({selectedView, search}) => {
    return selectedView === CALENDAR_VIEW ? <CalendarView /> : <TableView search={search} />
}

ActiveTab.propTypes = {
    selectedView: PropTypes.string,
    search: PropTypes.string
}
