import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {useCallback} from 'react'
import {debounce} from 'lodash-es'
import {Select} from '../../../../../../../components/select/Select'
import {httpGetAllPhotographers} from '../../../../../../../http-requests/admin/partners.http'
import {PAGE_LIMIT, PHOTOGRAPHERS_FILTER_OPTIONS, SERVICE_MEDIAS} from '../../../../../../../helpers/constants'
import {
    StyledSelectInfoWrapper,
    SelectWrapper,
    StyledSelectLabel,
    InfosWrapper,
    InfoWrapper,
    StyledInfoLabel,
    StyledInfo,
    SelectStyle
} from '../style'
import {errorHandler} from '../../../../../../../helpers/utils'
import {useAdminQuote} from '../../../../../../../hooks'
import {useSearchParams} from 'react-router-dom'
import {useUserStore} from '../../../../../../../features/user/store'

export const SelectFreelancer = ({control, freelancer, filterOptions, defaultPhotographer, previousPhotographers}) => {
    const {t} = useTranslation()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [searchParams] = useSearchParams()
    const quoteId = searchParams.get('quoteId')
    const quoteQuery = useAdminQuote(Number(quoteId))
    const filter =
        quoteQuery.data.task_media_id === SERVICE_MEDIAS.INSPECTION
            ? PHOTOGRAPHERS_FILTER_OPTIONS.INSPECTORS
            : PHOTOGRAPHERS_FILTER_OPTIONS.HOMES_PRODUCTION
    const groupedOptions = [
        {
            label: t('admin:assignments:adjustment_modal:currently_assigned'),
            options: filterOptions([defaultPhotographer])
        },
        {
            label: t('admin:assignments:adjustment_modal:previously_assigned'),
            options: filterOptions(previousPhotographers)
        }
    ]

    const loadFreelancersOptionsDebounced = useCallback(
        debounce(async (inputValue, callback) => {
            try {
                const results = await httpGetAllPhotographers(adminAccessToken, {
                    name: inputValue,
                    limit: PAGE_LIMIT,
                    filter_by: filter
                })
                callback(filterOptions(results?.data?.data))
            } catch (error) {
                errorHandler(error)
                callback([])
            }
        }, 700),
        []
    )

    return (
        <StyledSelectInfoWrapper>
            <SelectWrapper>
                <StyledSelectLabel>{t('admin:freelancer')}</StyledSelectLabel>
                <Select
                    {...SelectStyle}
                    isAsync
                    name="photographer"
                    isSearchable
                    placeholder={t('admin:assignments:select_freelancer')}
                    control={control}
                    loadOptions={loadFreelancersOptionsDebounced}
                    defaultOptions={groupedOptions}
                    cacheOptions
                    noOptionsMessage={() => t('admin:assignments:adjustment_modal:no_freelancer_found')}
                />
            </SelectWrapper>
            {!!freelancer && (
                <InfosWrapper>
                    <InfoWrapper>
                        <StyledInfoLabel>{t('admin:assignments:adjustment_modal:freelancer_id')}</StyledInfoLabel>
                        <StyledInfo>{freelancer?.data?.airbnb_user_id || '-'}</StyledInfo>
                    </InfoWrapper>
                    <InfoWrapper>
                        <StyledInfoLabel>{t('admin:assignments:adjustment_modal:freelancer_email')}</StyledInfoLabel>
                        <StyledInfo>{freelancer?.data?.email || '-'}</StyledInfo>
                    </InfoWrapper>
                    <InfoWrapper>
                        <StyledInfoLabel>{t('admin:assignments:adjustment_modal:freelancer_rate')}</StyledInfoLabel>
                        <StyledInfo>{freelancer?.data?.rate || '-'}</StyledInfo>
                    </InfoWrapper>
                </InfosWrapper>
            )}
        </StyledSelectInfoWrapper>
    )
}

SelectFreelancer.propTypes = {
    control: PropTypes.object,
    freelancer: PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        data: PropTypes.object
    }),
    defaultPhotographer: PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        data: PropTypes.object
    }),
    previousPhotographers: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            data: PropTypes.object
        })
    ),
    filterOptions: PropTypes.func
}
