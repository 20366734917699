import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalTaskImages = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-auto-rows: max-content;
        height: 100%;
        overflow-y: auto;
        padding: ${2 * spacing}px;
        gap: ${6 * spacing}px;

        ${mediaQueries.xl} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        ${mediaQueries.l} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    `
)
