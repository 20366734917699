import {Skeleton} from 'src/components/skeleton/Skeleton'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button'
import {StyledAdminHostApprovalTaskImagesHeader} from 'src/features/admin-host-approval/components/admin-host-approval-task-images-header/style'
import {AdminHostApprovalTaskUploader} from 'src/features/admin-host-approval/components/admin-host-approval-task-uploader/AdminHostApprovalTaskUploader'
import {useDeleteHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useDeleteHostApprovalTaskImages'
import {useUpdateHostApprovalTaskStatus} from 'src/features/admin-host-approval/services/useUpdateHostApprovalTaskStatus'
import {useNavigate} from 'react-router'
import {ROUTES} from 'src/helpers/constants'
import {useAdminHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useAdminHostApprovalTaskImages'
import {useDispatch} from 'react-redux'
import {showAlert} from 'src/store/appGenerics'
import {Spinner} from 'src/components/spinner/Spinner'

export const AdminHostApprovalTaskImagesHeader = ({taskId}: {taskId: number}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        data: taskImages,
        isLoading: isLoadingTaskImages,
        isError: isErrorTaskImages
    } = useAdminHostApprovalTaskImages(taskId, {
        enabled: false
    })

    const {mutate: removeImages, isLoading: isLoadingRemoveImages} = useDeleteHostApprovalTaskImages({
        taskId
    })

    const {mutate: updateHostApprovalTaskStatus, isLoading: isLoadingUpdateHostApprovalTaskStatus} =
        useUpdateHostApprovalTaskStatus({
            taskId,
            options: {
                onSuccess: () => {
                    dispatch(
                        showAlert({
                            message: t('host_approval_task:successfull_sent_for_approval'),
                            level: 'success'
                        })
                    )

                    navigate(ROUTES.ADMIN_HOST_APPROVAL_PENDING_HOST_APPROVAL)
                }
            }
        })

    return (
        <StyledAdminHostApprovalTaskImagesHeader fullWidth justify="space-between" align="center">
            {isLoadingTaskImages ? (
                <Skeleton width={160} height={60} />
            ) : (
                !isErrorTaskImages && (
                    <>
                        <Flexbox gap={3}>
                            <h3>
                                {t('host_approval_task:image_uploaded', {
                                    count: taskImages?.length ?? 0
                                })}
                            </h3>
                            <p>
                                {t('host_approval_task:image_to_be_tagged', {
                                    count: taskImages?.filter(image => !image.amenity_id && !image.room_id).length
                                })}
                            </p>
                        </Flexbox>

                        <Flexbox gap={2}>
                            <Button
                                variant="tertiary"
                                disabled={isLoadingRemoveImages}
                                onClick={() =>
                                    taskImages?.length &&
                                    removeImages({
                                        image_ids: taskImages.map(image => image.id)
                                    })
                                }
                            >
                                {t('host_approval_task:remove_all')}
                            </Button>
                            <AdminHostApprovalTaskUploader
                                taskId={taskId}
                                trigger={<Button variant="secondary">{t('commons:upload_images')}</Button>}
                            />
                            <Button
                                variant="primaryGrayModern"
                                onClick={() => updateHostApprovalTaskStatus({status: 'pending_host_approval'})}
                                disabled={
                                    isLoadingUpdateHostApprovalTaskStatus ||
                                    taskImages?.some(image => image.amenity_id == null || image.room_id == null)
                                }
                            >
                                {t('host_approval_task:send_for_approval')}
                                {isLoadingUpdateHostApprovalTaskStatus && <Spinner />}
                            </Button>
                        </Flexbox>
                    </>
                )
            )}
        </StyledAdminHostApprovalTaskImagesHeader>
    )
}
