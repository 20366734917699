import styled, {css} from 'styled-components'

export const StyledErrorMessage = styled.div`
    ${({theme: {spacing, colors, typography}}) => css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: ${spacing * 4}px;
        padding: ${spacing * 4}px;

        & h4 {
            ${typography.textXl};
            font-weight: 700;
            color: ${colors.red};
        }
    `}
`
