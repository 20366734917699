import {forwardRef} from 'react'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {StyledStepCard} from 'src/features/assignment/components/step-card/style.ts'
import {AlertTriangleIcon, CheckIcon} from 'src/components/icon'

export type StepStatus = 'active' | 'inactive' | 'completed' | 'error'
export interface StepCardProps {
    number: number
    status: StepStatus
    title: string
}

export const StepCard = forwardRef<HTMLDivElement, StepCardProps>(({number, status, title}, ref) => {
    return (
        <StyledStepCard ref={ref} $status={status}>
            <Flexbox className="number" justify="center" align="center">
                {status == 'completed' ? (
                    <CheckIcon size={20} />
                ) : status == 'error' ? (
                    <AlertTriangleIcon size={20} />
                ) : (
                    number
                )}
            </Flexbox>
            <h1>{title}</h1>
        </StyledStepCard>
    )
})
