import {UseMutationOptions, useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from 'src/queryClient.ts'
import {httpUpdatePersonalNotes} from 'src/features/assignment/services/assignment.http.ts'
import {Assignment} from 'src/features/assignment/types.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'

export const useUpdatePersonalNotes = ({
    assignmentId,
    options
}: {
    assignmentId: Assignment['id']
    options: UseMutationOptions<Assignment, unknown, string>
}) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_PERSONAL_NOTES],
        mutationFn: (notes: string) =>
            parseAxiosPromise({
                axiosPromise: httpUpdatePersonalNotes({
                    urlParams: {assignmentId},
                    payload: {notes},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onError: errorHandler,
        ...options
    })
}
