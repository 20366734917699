import {
    StyledAssignmentHero,
    StyledAssignmentHeroContainer
} from 'src/features/assignment/components/assignment-hero/style.ts'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {StyledBackButton} from 'src/pages/assignment/style.ts'
import {ROUTES} from 'src/helpers/constants.ts'
import {ChevronLeftIcon} from 'src/components/icon'
import {AssignmentData} from 'src/features/assignment/components/assignment-data/AssignmentData'
import {Steps} from 'src/features/assignment/components/steps/Steps.tsx'
import {getAssignmentDescription, getAssignmentSteps} from 'src/features/assignment/utils.tsx'
import {Assignment} from 'src/features/assignment/types.ts'
import {useTranslation} from 'react-i18next'

export const AssignmentHero = ({assignment}: {assignment: Assignment}) => {
    const {t} = useTranslation()

    return (
        <StyledAssignmentHero>
            <StyledAssignmentHeroContainer>
                <Flexbox direction="column" gap={6}>
                    <StyledBackButton to={ROUTES.INDEX} variant="tertiary">
                        <ChevronLeftIcon />
                        {t('commons:dashboard')}
                    </StyledBackButton>
                    <AssignmentData assignment={assignment} />
                </Flexbox>
                <Steps steps={getAssignmentSteps(assignment)} />
                {getAssignmentDescription(assignment)}
            </StyledAssignmentHeroContainer>
        </StyledAssignmentHero>
    )
}
