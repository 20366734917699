import styled, {css} from 'styled-components'
import {BackArrowIcon} from 'src/theme/icons.tsx'

export const StyledSubheader = styled.div(
    ({theme: {spacing, colors}}) => css`
        position: relative;
        background: ${colors.extraLightGrey};
        margin-bottom: ${spacing * 16}px;
    `
)

export const StyledEligible = styled.div(
    ({theme: {palette}}) => css`
        & > span {
            font-weight: 700;
            & > svg {
                width: 14px;
                fill: ${palette.neutral.black};
            }
        }
    `
)

export const StyledWrapper = styled.div(
    ({theme: {spacing, mediaQuery}}) => css`
        display: flex;
        align-items: stretch;
        & > div {
            width: 50%;
            padding: ${spacing * 6}px 0;
            &:first-child {
                padding-left: calc(((100vw - 1320px) / 2) + 12px);
                padding-right: ${spacing * 12}px;
            }
            &:last-child {
                padding-right: calc(((100vw - 1320px) / 2) + 12px);
                padding-left: ${spacing * 12}px;
            }
            ${mediaQuery.XL} {
                &:first-child {
                    padding-left: calc(((100vw - 1140px) / 2) + 12px);
                }
                &:last-child {
                    padding-right: calc(((100vw - 1140px) / 2) + 12px);
                }
            }
            ${mediaQuery.LG} {
                &:first-child {
                    padding-left: calc(((100vw - 960px) / 2) + 12px);
                }
                &:last-child {
                    padding-right: calc(((100vw - 960px) / 2) + 12px);
                }
            }
        }
    `
)

export const StyledLeftSection = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: ${spacing * 4}px;
        & > div {
            display: flex;
            align-items: flex-start;
            gap: ${spacing * 2}px;
            ${typography.textMd};
            color: ${palette.neutral.black};
            & > label {
                font-weight: 400;
                flex: none;
            }
            & > span {
                font-weight: 700;
                word-break: break-word;
            }
        }
    `
)

export const StyledRightSection = styled.div(
    ({theme: {colors, typography, spacing, palette}}) => css`
        background: ${colors.lightGrey};
        & > h5 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral.black};
            margin-bottom: ${spacing * 6}px;
        }
    `
)

export const StyledAmenetiesList = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: flex-start;
        gap: ${spacing * 4}px;
        & > div {
            width: calc(50% - 8px);
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: ${spacing * 3}px;
        }
    `
)

export const StyledListItem = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: ${spacing * 2}px;
        & > div {
            flex: none;
            min-width: 24px;
            height: 24px;
            border-radius: 4px;
            padding: ${spacing}px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${palette.neutral.black};
            color: ${palette.neutral.white};
            ${typography.textSm};
            font-weight: 500;
        }
        & > span {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral.black};
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)

export const StyledBackButton = styled(BackArrowIcon)(
    ({theme: {palette, transition}}) => css`
        position: absolute;
        top: 4px;
        left: 24px;
        width: 32px;
        fill: ${palette.neutral.black};
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    `
)
