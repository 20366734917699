import {useMutation, useQueryClient} from '@tanstack/react-query'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {httpUpdateForcedMatch, HttpUpdateForcedMatchOptions} from 'src/features/admin/services/admin.http.ts'
import {useUserStore} from 'src/features/user/store.ts'

export const useUpdateForcedMatch = ({quoteId, onSuccess}: {quoteId: number; onSuccess: () => void}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useMutation<unknown, unknown, {forcedMatchId: number; payload: HttpUpdateForcedMatchOptions['payload']}>({
        mutationFn: ({forcedMatchId, payload}) => {
            return httpUpdateForcedMatch({
                headers: makeHttpAuthorizationHeader(adminAccessToken),
                urlParams: {quoteId, forcedMatchId},
                payload
            })
        },
        onSuccess: () => {
            void queryClient.cancelQueries([QUERY_KEYS.ADMIN_QUOTE, quoteId])
            void queryClient.invalidateQueries([QUERY_KEYS.ADMIN_QUOTE, quoteId])
            void queryClient.cancelQueries([QUERY_KEYS.ADMIN_QUOTE_LOGS, quoteId])
            void queryClient.invalidateQueries([QUERY_KEYS.ADMIN_QUOTE_LOGS, quoteId])
            onSuccess()
        },
        onError: errorHandler
    })
}
