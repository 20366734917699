import PropTypes from 'prop-types'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {Link} from 'react-router-dom'
import {Spinner} from '../spinner-legacy/Spinner'
import {StyledFormCard, StyledHead, StyledFields, StyledActions, StyledInput} from './style'
import {Button} from '../button/Button'

export const FormCard = ({
    title = '',
    subtitle = '',
    buttonText = '',
    linkText = '',
    fields = [],
    validationSchema,
    onSubmitCb,
    linkRoute,
    isLoading,
    isAdmin,
    className,
    ...rest
}) => {
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        resolver: validationSchema ? yupResolver(validationSchema) : null
    })
    return (
        <StyledFormCard className={className} {...rest}>
            <StyledHead>
                <h3>{title}</h3>
                <h4>{subtitle}</h4>
            </StyledHead>
            <form onSubmit={handleSubmit(onSubmitCb)}>
                {fields.length > 0 && (
                    <StyledFields>
                        {fields.map((field, index) => (
                            <StyledInput
                                type={field.type}
                                label={field.label}
                                errorMessage={errors?.[field.name]?.message}
                                {...register(field.name)}
                                size="sm"
                                key={index}
                            />
                        ))}
                    </StyledFields>
                )}
                <StyledActions>
                    <Button type="submit" fullWidth size="lg">
                        {buttonText}
                    </Button>
                    {!isAdmin && linkRoute && <Link to={linkRoute}>{linkText}</Link>}
                </StyledActions>
            </form>

            {isLoading && <Spinner />}
        </StyledFormCard>
    )
}

FormCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonText: PropTypes.string,
    fields: PropTypes.array,
    validationSchema: PropTypes.any,
    onSubmitCb: PropTypes.func,
    linkText: PropTypes.string,
    linkRoute: PropTypes.string,
    isAdmin: PropTypes.bool,
    className: PropTypes.string,
    isLoading: PropTypes.bool
}
