import styled, {css} from 'styled-components'
import {CustomScrollbar} from '../../../../../theme/components'
import {ArrowRightIcon} from '../../../../../assets/icons/ArrowRightIcon'

export const StyledActivitiesCard = styled.div(
    ({theme: {colors, typography, spacing, palette}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        border-radius: 16px;
        border: 1px solid ${colors.smokeGrey};
        background: ${palette.neutral.white};

        & h3 {
            padding: ${spacing * 6}px 0 ${spacing * 4}px;
            margin: 0 ${spacing * 6}px;
            ${typography.textLg};
            font-weight: 700;
            color: ${colors.lightBlack};
            border-bottom: 1px solid ${colors.gainsboroGrey};
        }
    `
)

export const StyledActivityCardDetailsItem = styled.div<{$isLast: boolean}>(
    ({theme: {spacing, colors}, $isLast}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        padding-bottom: ${spacing * 4}px;
        margin-bottom: ${spacing * 4}px;
        ${!$isLast &&
        css`
            border-bottom: 1px solid ${colors.gainsboroGrey};
        `}
    `
)

export const StyledActivityCardDetailsItemTitle = styled.div(
    ({theme: {spacing, colors, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        & span {
            ${typography.textSm};
            font-weight: 400;
            color: ${colors.darkGrey};
        }
        & div {
            display: flex;
            align-items: center;
            gap: ${spacing * 2}px;

            & h4 {
                ${typography.textSm};
                font-weight: 500;
                color: ${colors.lightBlack};
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            & p {
                ${typography.textSm};
                font-weight: 500;
                color: ${colors.darkGrey};
            }
        }
    `
)

export const StyledActivityCardDetails = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        padding: ${spacing * 6}px ${spacing * 8}px;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
        ${CustomScrollbar}
    `
)

export const StyledAssignmentCardDetailsItemChips = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `
)

export const StyledNoResultsText = styled.span`
    ${({theme: {colors}}) => css`
        color: ${colors.darkGrey};
        text-align: center;
    `}
`

export const StyledCoordinationStatusLabel = styled.span(
    ({theme: {colors, typography}}) => css`
        ${typography.textXs};
        font-weight: 400;
        color: ${colors.darkGrey};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 120px;
    `
)

export const StyledStatusWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `
)

export const StyledArrowRight = styled(ArrowRightIcon)(
    () => css`
        margin-top: 3px;
    `
)
