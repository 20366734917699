import {Modal} from 'src/components/modal/Modal.tsx'
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalParagraph,
    ModalTitle,
    ModalXCloseButton
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {useTranslation} from 'react-i18next'
import dayjs from 'src/dayjs.ts'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Checkbox} from 'src/components/checkbox/Checkbox.tsx'
import {z} from 'zod'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {DatePicker} from 'src/components/date-picker/DatePicker.tsx'
import {TimePicker} from 'src/components/time-picker/TimePicker.tsx'
import {useAssignmentCoordination} from 'src/features/assignment/services/useAssignmentCoordination.ts'
import {ServiceType} from 'src/features/assignment/types.ts'
import {useEffect} from 'react'

const ValidationSchema = z
    .object({
        date: z.date().optional(),
        time: z.string().optional(),
        unknown_flag: z.boolean()
    })
    .superRefine((values, context) => {
        if (!values.unknown_flag && !values.date) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'The date field is required',
                path: ['date']
            })
        }
        if (!values.unknown_flag && !values.time) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'The time field is required',
                path: ['time']
            })
        }
    })
type ValidationSchema = z.infer<typeof ValidationSchema>

export const FreelancerNotAvailableModal = ({
    assignmentId,
    taskId,
    onClose,
    serviceType
}: {
    assignmentId: number
    taskId: number
    onClose: () => void
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const freelancerNotAvailableMutation = useAssignmentCoordination({
        assignmentId,
        taskId,
        options: {onSuccess: onClose}
    })
    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema),
        defaultValues: {
            unknown_flag: false
        }
    })
    const isUnknownFlagChecked = useWatch({name: 'unknown_flag', control: form.control})
    const handleSubmit = form.handleSubmit(values => {
        if (values.unknown_flag) {
            freelancerNotAvailableMutation.mutate({status: 'photographer_not_available'})
        } else {
            //date to send adapted to safari format too
            const dateToSend = dayjs(
                `${dayjs(values.date).format('YYYY-MM-DD')} ${values.time}`.replace(/-/g, '/')
            ).format('YYYY-MM-DD HH:mm:ss')

            freelancerNotAvailableMutation.mutate({
                status: 'photographer_not_available',
                date: dateToSend
            })
        }
    })

    useEffect(() => {
        if (isUnknownFlagChecked) {
            form.resetField('date', undefined)
            form.resetField('time', undefined)
        }
    }, [isUnknownFlagChecked])

    return (
        <Modal onOverlayClick={onClose} width={480}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <Flexbox direction="column" gap={2}>
                    <ModalTitle>{t('assignment:descriptions:freelancer_not_available_modal:title')}</ModalTitle>
                    <ModalParagraph>
                        {t(`assignment:descriptions:freelancer_not_available_modal:subtitle:${serviceType}`)}
                    </ModalParagraph>
                </Flexbox>
                <Flexbox gap={4}>
                    <Controller
                        name="date"
                        control={form.control}
                        render={({field}) => (
                            <DatePicker
                                asModal
                                triggerProps={{label: t('commons:date')}}
                                disabled={isUnknownFlagChecked}
                                minDate={dayjs().toDate()}
                                formatter={date => dayjs(date).format('YYYY-MM-DD')}
                                mode="single"
                                numMonths={1}
                                selectedDates={field.value ? [dayjs(field.value).toDate()] : []}
                                onDatesChange={dates => field.onChange(dates[0])}
                            />
                        )}
                    />
                    <Controller
                        name="time"
                        control={form.control}
                        render={({field}) => (
                            <TimePicker
                                asModal
                                triggerProps={{label: t('commons:time')}}
                                disabled={isUnknownFlagChecked}
                                selectedTime={field.value ?? ''}
                                onSelectTime={time => field.onChange(time)}
                            />
                        )}
                    />
                </Flexbox>
                <Checkbox
                    id="unknown_flag"
                    {...form.register('unknown_flag')}
                    label={t('assignment:descriptions:freelancer_not_available_modal:checkbox_label')}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} variant="tertiary" disabled={freelancerNotAvailableMutation.isLoading}>
                    {t('commons:close')}
                </Button>
                <Button
                    disabled={freelancerNotAvailableMutation.isLoading || !form.formState.isValid}
                    onClick={handleSubmit}
                >
                    {t('commons:confirm')}
                    {freelancerNotAvailableMutation.isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
