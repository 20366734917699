import styled, {css} from 'styled-components'

export const Wrapper = styled.div`
    ${({theme: {spacing}}) => css`
        .accordionBody {
            & > div {
                padding: ${spacing * 10}px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: ${spacing * 12}px;
            }
        }
    `}
`

export const RemoveButton = styled.button`
    ${({theme: {palette}}) => css`
        background: ${palette.neutral.black};
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        min-width: auto;
        width: 24px;
        height: 24px;
        gap: 0;
        padding: 0;
        border-radius: 0 0 0 4px;
        z-index: 2;

        & svg {
            width: 16px;
            height: 16px;
            fill: ${palette.neutral.white};
        }
    `}
`

export const StyledCheckCard = styled.div(
    ({theme: {colors}}) => css`
        position: relative;
        width: calc((100% - 144px) / 4); //4 cards per row
        background: ${colors.extraLightGrey};
        border-radius: 0 0 8px 8px;
    `
)

export const ImageWrapper = styled.div`
    cursor: pointer;
    width: 100%;
    & > span {
        width: 100%;
        & img {
            width: 100%;
            object-position: center;
            object-fit: cover;
            aspect-ratio: 1.5/1;
            @supports not (aspect-ratio: 1.5 / 1) {
                height: 150px;
            }
        }
    }
`

export const StyledCardFooter = styled.div(
    ({theme: {spacing, colors, typography}}) => css`
        height: 72px;
        padding: ${spacing}px ${spacing * 2}px ${spacing * 2}px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: ${spacing * 2}px;
        & > small {
            align-self: flex-end;
            ${typography.textXs};
            font-weight: 500;
            color: ${colors.darkGrey};
        }
        & > p {
            ${typography.textXs};
            font-weight: 500;
            word-break: break-all;
            overflow: hidden;
            line-clamp: 2;
            box-orient: vertical;
            display: flex;
        }
    `
)
