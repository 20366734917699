import {Modal} from '../../modal-legacy/Modal'
import {Button} from '../../button/Button'
import {useTranslation} from 'react-i18next'
import {FormProvider, useForm} from 'react-hook-form'
import {InputSectionWrapper, StyledSubTitle, RadioWrapper, StyledTitle, FooterWrapper, StyledBodyWrapper} from './style'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {showAlert} from 'src/store/appGenerics.ts'
import {ROUTES} from 'src/helpers/constants.ts'
import {Spinner} from '../../spinner-legacy/Spinner'
import {httpDeleteAssignment, httpGetCancelReasons} from 'src/features/assignment/services/assignment.http.ts'
import {ReasonsOptions} from './reasons-options/ReasonsOptions'
import {HARD_CANCELLATION_REASONS, SOFT_CANCELLATION_REASONS} from './constants'
import {useUserStore} from 'src/features/user/store.ts'
import {Radio} from '../../radio/Radio'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import i18n from 'src/translations/i18n.ts'
import {useURLParsedParams} from 'src/hooks/useURLParsedParams.ts'
import {AssignmentUrlParams} from 'src/features/assignment/types.ts'
import {useAssignment} from 'src/features/assignment/services/useAssignment.ts'

const CancelAssignmentModalSchema = z
    .object({
        cancellation_type: z.enum(['soft_cancellation', 'hard_cancellation']),
        reason: z.string(),
        notes: z.string().optional()
    })
    .superRefine((values, context) => {
        if (values.reason == 'other' && !values.notes) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t('errors:required'),
                path: ['notes']
            })
        }
    })
type CancelAssignmentModalSchema = z.infer<typeof CancelAssignmentModalSchema>

export const CancelAssignmentModal = ({onClose}: {onClose: () => void}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')
    const urlParams = useURLParsedParams(AssignmentUrlParams)
    const assignmentQuery = useAssignment(urlParams.data?.code, {
        enabled: urlParams.success
    })
    const [isLoading, setIsLoading] = useState(false)
    const [reasons, setReasons] = useState<{key_name: string; id: number}[]>([])
    const form = useForm<CancelAssignmentModalSchema>({
        mode: 'all',
        resolver: zodResolver(CancelAssignmentModalSchema)
    })
    const cancellationType = form.watch('cancellation_type')
    const cancellationTypeOptions = [
        {
            value: 'soft_cancellation',
            label: t('admin:assignment_cancellation:soft_cancellation'),
            helperText: t('admin:assignment_cancellation:cancel_by_partner')
        },
        {
            value: 'hard_cancellation',
            label: t('admin:assignment_cancellation:hard_cancellation'),
            helperText: t('admin:assignment_cancellation:cancel_by_host')
        }
    ]

    const getCancelReasons = async () => {
        try {
            const response = await httpGetCancelReasons({headers: makeHttpAuthorizationHeader(adminAccessToken)})
            setReasons(response.data)
        } catch (error) {
            errorHandler(error)
        }
    }

    //TODO: replace with react query
    useEffect(() => {
        void getCancelReasons()
    }, [])

    useEffect(() => {
        form.resetField('reason')
        form.resetField('notes')
    }, [cancellationType])

    const handleSubmit = form.handleSubmit(async values => {
        if (!assignmentQuery.isSuccess) {
            return raise('assignment is undefined')
        }
        try {
            const cancellationReasonId =
                reasons.find(reason => reason.key_name == `reasons.${values.reason}`)?.id ??
                raise('cancellationReasonId is undefined')
            setIsLoading(true)
            await httpDeleteAssignment({
                headers: makeHttpAuthorizationHeader(adminAccessToken),
                params: {
                    reason_id: cancellationReasonId,
                    cancellation_type: values.cancellation_type,
                    notes: values.notes
                },
                urlParams: {assignmentId: assignmentQuery.data.id}
            })
            dispatch(showAlert({message: t('admin:assignment_cancellation:cancellation_confirmed'), level: 'success'}))
            onClose()
            navigate(ROUTES.INDEX)
        } catch (error) {
            errorHandler(error)
        } finally {
            setIsLoading(false)
        }
    })

    return (
        <Modal onClose={onClose} width="580px">
            <FormProvider {...form}>
                <form onSubmit={handleSubmit}>
                    <StyledTitle>{t('admin:assignment_cancellation:cancel_assignment')}</StyledTitle>
                    <StyledBodyWrapper>
                        <InputSectionWrapper>
                            <StyledSubTitle>{t('admin:assignment_cancellation:cancellation_type')}</StyledSubTitle>
                            <RadioWrapper>
                                {cancellationTypeOptions.map(item => (
                                    <Radio
                                        value={item.value}
                                        label={item.label}
                                        helpText={item.helperText}
                                        {...form.register('cancellation_type')}
                                        key={item.value}
                                    />
                                ))}
                            </RadioWrapper>
                        </InputSectionWrapper>
                        {!!cancellationType && (
                            <ReasonsOptions
                                options={
                                    cancellationType == 'soft_cancellation'
                                        ? SOFT_CANCELLATION_REASONS
                                        : HARD_CANCELLATION_REASONS
                                }
                            />
                        )}
                    </StyledBodyWrapper>
                    <FooterWrapper>
                        <Button onClick={onClose} variant="tertiary">
                            {t('commons:cancel')}
                        </Button>
                        <Button type="submit" disabled={!form.formState.isValid}>
                            {t('admin:confirm')}
                        </Button>
                    </FooterWrapper>
                </form>
            </FormProvider>
            {isLoading && <Spinner />}
        </Modal>
    )
}
