import styled, {css} from 'styled-components'
import {RADIO_STYLE_TYPES} from './LanguageRadio'

export const StyledLanguageRadio = styled.label`
    ${({theme, styleType, color}) => css`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: ${theme.spacing * 2}px;
        cursor: pointer;

        & input {
            appearance: none;
            flex: none;
            border: 1px solid ${theme.colors.grey};
            border-radius: 50%;
            cursor: pointer;
            height: 18px;
            width: 18px;

            &:checked {
                ${getRadioTypeStyle(styleType, color, theme)}
            }
        }
    `}
`

export const StyledSpan = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 400;
        line-height: 18px !important;
        text-align: left;
    `}
`

const getRadioTypeStyle = (type, color, theme) => {
    switch (type) {
        case RADIO_STYLE_TYPES.OUTLINED:
            return css`
                content: url("data:image/svg+xml,%3Csvg viewBox='-4 -4 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='${color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                border-width: 2px;
                border-color: ${color === 'white' ? theme.palette.neutral.white : theme.colors.lightBlack};
            `
        default:
            return css`
                content: url("data:image/svg+xml,%3Csvg viewBox='-4 -4 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='${color ===
                'white'
                    ? 'black'
                    : 'white'}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background: ${color === 'white' ? theme.palette.neutral.white : theme.colors.lightBlack};
                border-color: ${color === 'white' ? theme.palette.neutral.white : theme.colors.lightBlack};
            `
    }
}
