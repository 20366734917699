import styled, {css} from 'styled-components'

export const StyledAccordionBody = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        padding: 0 ${spacing * 13}px ${spacing * 3}px ${spacing * 11}px;

        ${mediaQueries.m} {
            padding: 0 ${spacing * 11}px ${spacing * 3}px ${spacing * 5}px;
        }
    `
)

export const StyledAccordionBodyTransaction = styled.div(
    ({theme: {spacing, colors, typography, mediaQueries}}) => css`
        padding: ${spacing * 3}px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        ${typography.textSm};

        &:not(:last-child) {
            border-bottom: 1px solid ${colors.gainsboroGrey};
        }

        & hgroup {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 0.5}px;
            max-width: 100%;
            overflow: hidden;

            & h3 {
                ${typography.textSm};
                font-weight: 500;
            }
        }

        & .amount {
            display: flex;
            justify-content: flex-end;
            gap: ${spacing * 4}px;
        }

        ${mediaQueries.s} {
            ${typography.textXs};
            & hgroup h3 {
                ${typography.textXs};
                font-weight: 500;
            }
        }
        ${mediaQueries.m} {
            & .amount {
                flex-direction: column;
                gap: ${spacing * 2}px;
                align-items: flex-end;
            }
        }
    `
)

export const StyledAirbnbLink = styled.span(
    ({theme: {spacing, colors, typography, mediaQueries}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        ${typography.textSm};
        font-weight: 400;
        color: ${colors.darkGrey};

        & a {
            color: ${colors.darkGrey};
            overflow: hidden;
            text-overflow: ellipsis;
        }

        ${mediaQueries.m} {
            ${typography.textXs};
        }
    `
)

export const StyledAmount = styled.span<{$type?: 'positive' | 'negative' | 'neutral'}>(
    ({theme: {colors}, $type}) => css`
        justify-self: flex-end;
        text-align: right;
        font-weight: 500;
        white-space: nowrap;
        color: ${$type == 'positive' ? colors.darkGreen : $type == 'negative' ? colors.darkRed : colors.lightBlack};
    `
)
