import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {Assignment} from 'src/features/assignment/types.ts'
import {ButtonLink} from 'src/components/button-link/ButtonLink'
import dayjs from 'dayjs'
import {Tooltip} from 'src/components/tooltip/Tooltip'

export const WalkthroughFormButton = ({assignment}: {assignment: Assignment}) => {
    const {t} = useTranslation()

    const isDisabledButton = useMemo(() => {
        const toShootAt = dayjs(assignment.to_shoot_at)
        // Calculate the time difference in hours
        const hoursUntilAssignment = toShootAt.diff(dayjs(), 'hour')

        return hoursUntilAssignment > 12
    }, [assignment.to_shoot_at])

    return (
        <Tooltip
            content={isDisabledButton && t('assignment:upload_assets_info_walkthrough')}
            variant="light"
            rootProps={{open: isDisabledButton ? false : undefined}}
            contentProps={{side: 'bottom'}}
        >
            <ButtonLink
                variant="primary"
                disabled={isDisabledButton}
                target="_blank"
                to={`https://my.propertyinspect.com/tasks/${assignment.task.inspection_id}`}
            >
                {t('assignment:goto_walkthrough_form')}
            </ButtonLink>
        </Tooltip>
    )
}
