import {useMutation, useQueryClient} from '@tanstack/react-query'
import {errorHandler} from '../../helpers/utils'
import {QUOTE_LOGS_STATUSES} from '../../helpers/constants'
import {httpReassignTask} from '../../http-requests/admin/tasks.http'
import {QUERY_KEYS} from '../../queryClient'
import {useUserStore} from '../../features/user/store'

export const useReassignTask = ({selectedQuoteId, onSuccess}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    return useMutation({
        mutationFn: ({taskId, payload}) => {
            return httpReassignTask(adminAccessToken, taskId, payload)
        },
        onSuccess: () => {
            // invalidate admin single shoot
            queryClient.cancelQueries([QUERY_KEYS.ADMIN_QUOTE, selectedQuoteId])
            queryClient.invalidateQueries([QUERY_KEYS.ADMIN_QUOTE, selectedQuoteId])

            queryClient.cancelQueries({
                queryKey: [QUERY_KEYS.ADMIN_QUOTE_LOGS, QUOTE_LOGS_STATUSES.PRODUCTION]
            })
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.ADMIN_QUOTE_LOGS, QUOTE_LOGS_STATUSES.PRODUCTION]
            })

            queryClient.cancelQueries([QUERY_KEYS.ADMIN_QUOTE_LOGS, selectedQuoteId])
            queryClient.invalidateQueries([QUERY_KEYS.ADMIN_QUOTE_LOGS, selectedQuoteId])
            onSuccess()
        },
        onError: errorHandler
    })
}
