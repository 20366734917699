import {HTMLAttributes} from 'react'
import {
    StyledAssignmentDescriptionParagraph,
    StyledAssignmentDescriptionTitle,
    StyledAssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/style.ts'
import {Flexbox, FlexboxProps} from 'src/components/flexbox/Flexbox.tsx'

export const AssignmentDescriptionWrapper = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledAssignmentDescriptionWrapper {...props} />
)

export const AssignmentDescriptionText = (props: FlexboxProps<'div'>) => (
    <Flexbox direction="column" gap={2} {...props} />
)

export const AssignmentDescriptionTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledAssignmentDescriptionTitle {...props} />
)

export const AssignmentDescriptionParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledAssignmentDescriptionParagraph {...props} />
)
