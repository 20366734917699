import styled, {css} from 'styled-components'

export const Wrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 8}px;
        word-break: break-word;
    `}
`
