import styled, {css} from 'styled-components'

export const StyledSelect = styled.div`
    position: relative;
`

export const StyledLabel = styled.label`
    ${({theme: {colors, spacing}}) => css`
        color: ${colors.darkGrey};
        margin-bottom: ${spacing * 2}px;
    `}
`

export const reactSelectsStyleGenerator = (
    theme,
    isMulti,
    isCreatable,
    isAsync,
    error,
    isTouched,
    disabled,
    singleValueCustomStyle,
    optionCustomStyle,
    menuCustomStyle,
    multiValueLabelCustomStyle,
    multiValueCustomStyle,
    controlCustomStyle,
    menuContainerCustomStyle,
    placeholderStyle,
    menuPosition
) => ({
    placeholder: defaults => ({
        ...defaults,
        color: theme.colors.steelGrey,
        fontWeight: 400,
        fontSize: '16px',
        margin: isMulti ? `0 ${theme.spacing * 2.5}px` : 0,
        ...(placeholderStyle && placeholderStyle)
    }),
    valueContainer: defaults => ({
        ...defaults,
        fontSize: '16px',
        fontWeight: 400,
        padding: 0,
        gap: 4
    }),
    menuPortal: defaults => ({
        ...defaults,
        ...(menuPosition === 'fixed' && {
            left: 'auto',
            top: 'auto'
        })
    }),
    singleValue: defaults => ({
        ...defaults,
        fontWeight: 400,
        ...(singleValueCustomStyle && singleValueCustomStyle)
    }),
    control: (defaults, {isDisabled, isFocused}) => ({
        ...defaults,
        backgroundColor: theme.palette.neutral.white,
        minHeight: '48px',
        padding: isMulti ? '2px 4px' : '2px 4px',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        borderWidth: 0,
        borderBottomWidth: 2,
        borderRadius: 0,
        borderColor: `${theme.colors.inputGrey} !important`,
        ...(isDisabled && {
            borderColor: `${theme.colors.lightGrey} !important`
        }),
        ...(isFocused && {
            borderColor: `${theme.palette.neutral.black} !important`
        }),
        ...(error && {
            borderColor: `${theme.colors.red} !important`,
            svg: {
                fill: theme.colors.red
            }
        }),
        ...(!error &&
            isTouched &&
            !disabled && {
                borderColor: `${theme.colors.inputGrey} !important`,
                svg: {
                    fill: theme.palette.neutral.black
                }
            }),
        transition: 'ease-in-out 200ms',
        '&:hover': {
            opacity: 0.8
        },
        boxShadow: 'none',
        ...(controlCustomStyle && controlCustomStyle),
        ...(isAsync && {
            border: `1px solid ${theme.colors.gainsboroGrey} !important`,
            maxWidth: 336,
            maxHeight: 42,
            borderRadius: 8,
            paddingRight: theme.spacing * 3.5,
            svg: {
                fill: theme.colors.darkGrey
            }
        })
    }),
    option: (provided, {isDisabled, isFocused, isSelected}) => ({
        ...provided,
        color: isDisabled ? theme.colors.grey : theme.palette.neutral.black,
        padding: `12px 16px`,
        ...((isFocused || isSelected) && {
            backgroundColor: `${theme.palette.neutral['50']} !important`
        }),
        ...(isSelected && {
            backgroundColor: `${theme.colors.smokeGrey} !important`
        }),
        ...(isSelected && {fontWeight: 700}),
        fontSize: '16px',
        fontWeight: 500,
        borderRadius: 4,
        ...(optionCustomStyle && optionCustomStyle)
    }),
    indicatorsContainer: (defaults, {isDisabled}) => ({
        ...defaults,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        padding: isMulti ? '8px 0' : 0,
        '& svg': {
            fill: isDisabled ? theme.colors.lightGrey : theme.colors.steelGrey
        }
    }),
    indicatorSeparator: defaults => ({
        ...defaults,
        width: 0,
        padding: 0
    }),
    menu: defaults => ({
        ...defaults,
        zIndex: 20,
        borderRadius: 10,
        overflow: 'hidden',
        fontWeight: 600,
        ...(menuContainerCustomStyle && menuContainerCustomStyle)
    }),
    menuList: defaults => ({
        ...defaults,
        padding: 0,
        margin: 8,
        '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: theme.palette.neutral.white
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.borderGrey,
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: theme.colors.grey
            }
        },
        ...(menuCustomStyle && menuCustomStyle)
    }),
    dropdownIndicator: defaults => ({
        ...defaults,
        padding: '2px'
    }),
    clearIndicator: defaults => ({
        ...defaults,
        borderRadius: '50px',
        marginRight: 4,
        padding: 2,
        backgroundColor: 'transparent'
    }),
    multiValueLabel: defaults => ({
        ...defaults,
        textTransform: isCreatable ? 'uppercase' : 'none',
        padding: 0,
        color: theme.palette.neutral.black,
        fontWeight: 700,
        fontSize: '16px',
        letterSpacing: 0.5,
        ...(multiValueLabelCustomStyle && multiValueLabelCustomStyle)
    }),
    multiValue: defaults => ({
        ...defaults,
        margin: 0,
        padding: '4px',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.colors.lightBlue,
        ...(multiValueCustomStyle && multiValueCustomStyle)
    }),
    multiValueRemove: () => ({
        display: 'flex',
        alignItems: 'center',
        padding: '0 4px'
    }),
    groupHeading: defaults => ({
        ...defaults,
        fontSize: '14px',
        color: theme.palette.neutral.black,
        fontWeight: 600,
        paddingLeft: theme.spacing * 3.5
    }),
    input: defaults => ({
        ...defaults,
        paddingLeft: theme.spacing * 2
    })
})
