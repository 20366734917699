import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalTaskImagesHeader = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;

        & h3 {
            font-weight: 500;
        }

        & p {
            color: ${palette.neutral['700']};
        }
    `
)
