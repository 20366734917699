import {StyledVideoDropzone} from './style'
import {Image01Icon} from '../../../../theme/icons'
import {Trans, useTranslation} from 'react-i18next'

export const VideoDropzone = ({dropzone, file}) => {
    const {t} = useTranslation()

    return (
        <StyledVideoDropzone
            {...dropzone.getRootProps()}
            isDragAccept={dropzone.isDragAccept}
            isDragReject={dropzone.isDragReject}
        >
            <input {...dropzone.getInputProps()} />
            {!file ? (
                <>
                    <Image01Icon />
                    <p>{t('single_shooting:drop_video')}</p>
                    <p>
                        <Trans i18nKey="single_shooting:or_upload" components={{span: <span />}} />
                    </p>
                </>
            ) : (
                <>
                    <p>{file.name}</p>
                </>
            )}
        </StyledVideoDropzone>
    )
}
