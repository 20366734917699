import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalTaskImagesPool = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        width: 100%;
        padding-bottom: ${spacing * 6}px;
    `
)
