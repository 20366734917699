import {useCallback, useState} from 'react'
import {useInfiniteQuery, useQueryClient} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {QUERY_KEYS} from '../queryClient'
import {useUserStore} from '../features/user/store'
import {Order, PaginationParams} from 'src/types.ts'
import {httpGetAssignmentsRequests} from 'src/features/assignments/services/assignments.http.ts'
import {Assignment} from 'src/features/assignment/types.ts'
import {z} from 'zod'
import {captureException} from '@sentry/react'

const HttpGetAssignmentsRequestsResponse = PaginationParams.and(
    z.object({
        data: z.array(Assignment)
    })
)

export const useAssignmentsRequests = () => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const [sorter, setSorter] = useState<Order | null>(null)
    const queryClient = useQueryClient()

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.NEW_SHOOTS, sorter],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetAssignmentsRequests({
                    params: {
                        page: pageParam,
                        response_type: 'extended',
                        limit: 50,
                        ...sorter
                    },
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: HttpGetAssignmentsRequestsResponse,
                onZodError: captureException
            }),
        getNextPageParam: lastPage => (lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : null)
    })

    //sorter
    const onSortColumn = useCallback((columnName: Order['order_by'], orderDirection: Order['order_direction']) => {
        setSorter({order_by: columnName, order_direction: orderDirection})
    }, [])

    //Remove selected item from the list
    const newShootStatusToggle = (assignment: Assignment) => {
        if (query.data) {
            const oldPagesArray = [...query.data.pages]
            const newPagesArray = oldPagesArray.map(page => {
                return {
                    ...page,
                    data: page.data.filter(item => item.id !== assignment.id) ?? []
                }
            })

            //setQueriesData can change all the data matched by a partial match in query key
            queryClient.setQueriesData([QUERY_KEYS.NEW_SHOOTS], () => ({
                pages: newPagesArray,
                pageParams: query.data.pageParams
            }))
        }
    }

    return {
        ...query,
        data: query.data ? query.data.pages.flatMap(page => page.data) : [],
        page: query.data ? (query.data.pages.at(-1)?.current_page ?? 0) + 1 : 1,
        onChangePage: () => infiniteQueryFetchNextPage(query),
        sorter,
        onSortColumn,
        newShootStatusToggle
    }
}
