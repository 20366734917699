import {useMemo, useState} from 'react'
import {EVENT_TYPES, Event} from '../event/Event'
import {formatLocaleDate} from '../../../helpers/utils'

import {CalendarToolbar} from './toolbar/CalendarToolbar'
import {Spinner} from '../../spinner-legacy/Spinner'
import {RefreshCalendarMessage} from '../refresh-calendar-message/RefreshCalendarMessage'
import {useTranslation} from 'react-i18next'
import {
    StyledMobileContainer,
    StyledMobileCalendar,
    StyledNoResultsSpan,
    StyledEventList,
    StyledTodayElement,
    StyledDateElement,
    StyledDayText,
    StyledTodayText
} from './style'
import PropTypes from 'prop-types'

export const MobileCalendar = ({
    className,
    events,
    isCurrentMonthLoading,
    loadingRange,
    isError,
    fetchedMonths,
    onChangeRange
}) => {
    const {t} = useTranslation()
    const [currentDate, setCurrentDate] = useState(new Date())
    const today = formatLocaleDate(new Date())

    const groupedEvents = useMemo(() => {
        if (!events || events.length === 0) return {}

        const eventsCopy = [...events].filter(
            ev => formatLocaleDate(ev.start, 'MMMM YYYY') === formatLocaleDate(currentDate, 'MMMM YYYY')
        )
        eventsCopy.sort((a, b) => new Date(a.start) - new Date(b.start))

        const groups = {}
        eventsCopy.forEach(event => {
            const startDate = formatLocaleDate(event.start)
            if (!groups[startDate]) {
                groups[startDate] = []
            }
            groups[startDate].push(event)
        })
        return groups
    }, [events, currentDate])

    return (
        <StyledMobileCalendar className={className} id="mobile-calendar">
            {isError && <RefreshCalendarMessage />}
            {isCurrentMonthLoading && <Spinner overlay />}
            <CalendarToolbar
                date={currentDate}
                loadingRange={loadingRange}
                fetchedMonths={fetchedMonths}
                onChangeRange={onChangeRange}
                onChangeMonth={setCurrentDate}
            />
            <StyledMobileContainer>
                {!isCurrentMonthLoading &&
                    groupedEvents &&
                    Object.entries(groupedEvents).map(([start, events]) => (
                        <div key={start}>
                            {start !== today ? (
                                <StyledDateElement>
                                    <StyledDayText>{formatLocaleDate(start, 'dddd')}</StyledDayText>
                                    <span>{formatLocaleDate(start, 'MMM D, YYYY')}</span>
                                </StyledDateElement>
                            ) : (
                                <StyledTodayElement>
                                    <StyledTodayText>{formatLocaleDate(start, 'dddd')}</StyledTodayText>
                                    <span>{formatLocaleDate(start, 'MMM D, YYYY')}</span>
                                </StyledTodayElement>
                            )}

                            <StyledEventList>
                                {events.map(event => (
                                    <Event type={EVENT_TYPES.POPUP} key={event.resource.id} event={event} />
                                ))}
                            </StyledEventList>
                        </div>
                    ))}
                {!isCurrentMonthLoading && !isError && Object.keys(groupedEvents).length === 0 && (
                    <StyledNoResultsSpan>{t('commons:no_results')}</StyledNoResultsSpan>
                )}
            </StyledMobileContainer>
        </StyledMobileCalendar>
    )
}

MobileCalendar.propTypes = {
    className: PropTypes.string,
    events: PropTypes.arrayOf({
        allDay: PropTypes.bool,
        title: PropTypes.node,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        resource: PropTypes.object
    }).isRequired,
    isCurrentMonthLoading: PropTypes.bool,
    isError: PropTypes.bool,
    loadingRange: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string
    }),
    fetchedMonths: PropTypes.arrayOf(PropTypes.string),
    onChangeRange: PropTypes.func
}
