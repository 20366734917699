import styled, {css, DefaultTheme} from 'styled-components'
import {AdminLayoutProps} from './AdminLayout'

type StyledAdminLayoutProps = {
    $background: NonNullable<AdminLayoutProps['background']>
}

const makeAdminLayoutBackgroundColor = (theme: DefaultTheme) =>
    ({
        neutral: css`
            background: ${theme.palette.neutral['50']};
        `,
        white: css`
            background: ${theme.palette.neutral.white};
        `
    } as const satisfies Record<NonNullable<StyledAdminLayoutProps['$background']>, ReturnType<typeof css>>)

export const StyledAdminLayout = styled.div<StyledAdminLayoutProps>(
    ({theme, $background}) => css`
        height: 100%;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        overflow: hidden;
        ${makeAdminLayoutBackgroundColor(theme)[$background]};
    `
)
