import {FC, HTMLAttributes} from 'react'

export const ClockIcon: FC<{size?: number; color?: string} & HTMLAttributes<SVGSVGElement>> = ({
    size = 16,
    color = 'currentColor',
    ...rest
}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.36775 10.3014C2.36775 6.1593 5.72561 2.80143 9.86775 2.80143C14.0099 2.80143 17.3677 6.1593 17.3677 10.3014C17.3677 14.4436 14.0099 17.8014 9.86775 17.8014C5.72561 17.8014 2.36775 14.4436 2.36775 10.3014ZM9.86775 1.13477C4.80514 1.13477 0.70108 5.23882 0.70108 10.3014C0.70108 15.364 4.80514 19.4681 9.86775 19.4681C14.9304 19.4681 19.0344 15.364 19.0344 10.3014C19.0344 5.23882 14.9304 1.13477 9.86775 1.13477ZM10.7011 5.30143C10.7011 4.84119 10.328 4.4681 9.86775 4.4681C9.40751 4.4681 9.03441 4.84119 9.03441 5.30143V10.3014C9.03441 10.6171 9.21275 10.9056 9.49507 11.0468L12.8284 12.7135C13.2401 12.9193 13.7406 12.7524 13.9464 12.3408C14.1523 11.9291 13.9854 11.4286 13.5738 11.2227L10.7011 9.7864V5.30143Z"
            fill={color}
        />
    </svg>
)
