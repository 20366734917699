import {useState} from 'react'
import {LogoutIcon} from '../../../assets/icons/LogoutIcon'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {VideoIcon} from '../../../assets/icons/VideoIcon'
import {SettingsIcon} from '../../../assets/icons/SettingsIcon'
import {NavigationIcon} from '../../../assets/icons/NavigationIcon'
import {languageSelector} from '../../../store/appGenerics'
import {appLanguages} from '../../../translations/i18n'
import {SelectMenuModal} from '../../select-menu-modal/SelectMenuModal'
import {
    StyledMobileMenuOverlay,
    StyledMobileMenu,
    StyledAccountCardSection,
    StyledDivListItem,
    StyledNavListItem,
    StyledAnchorListItem,
    StyledMenuGroup,
    NavItemsWrapper
} from './style'
import {ROUTES} from '../../../helpers/constants'
import {AccountCard} from '../../account-card/AccountCard'
import {useUserStore} from '../../../features/user/store'

export const MobileMenu = ({showMobileMenu, setShowMobileMenuCb, onLogoutCb, navLinks}) => {
    const {t} = useTranslation()
    const currentUser = useUserStore(store => store.currentUser)
    const currentLanguage = useSelector(languageSelector)
    const currentLanguageObj = appLanguages.find(
        e => e.value === currentLanguage || e.value === localStorage.getItem('i18nextLng')
    )
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <StyledMobileMenuOverlay
                $showMobileMenu={showMobileMenu}
                $pageHeight={window.innerHeight}
                onClick={setShowMobileMenuCb}
            />
            <StyledMobileMenu $showMobileMenu={showMobileMenu}>
                <StyledAccountCardSection>
                    <AccountCard
                        onClick={e => e.stopPropagation()}
                        name={currentUser?.name}
                        email={currentUser?.email}
                    />
                </StyledAccountCardSection>
                <NavItemsWrapper $hasBorder>
                    {navLinks.map((link, index) => (
                        <StyledNavListItem
                            to={link.path}
                            onClick={setShowMobileMenuCb}
                            $isBold={link.activeCondition}
                            key={index}
                        >
                            <StyledMenuGroup>{link.text}</StyledMenuGroup>
                        </StyledNavListItem>
                    ))}
                </NavItemsWrapper>
                <NavItemsWrapper>
                    <StyledNavListItem onClick={setShowMobileMenuCb} to={ROUTES.SETTINGS}>
                        <SettingsIcon size={20} />
                        <span>{t('menu:settings')}</span>
                    </StyledNavListItem>

                    <StyledDivListItem onClick={() => setShowModal(true)}>
                        <NavigationIcon size={20} />
                        <span>{currentLanguageObj?.label}</span>
                    </StyledDivListItem>
                    <StyledAnchorListItem
                        onClick={setShowMobileMenuCb}
                        href="https://youtu.be/4IMHov7PkPU"
                        target="_blank"
                    >
                        <VideoIcon size={20} />
                        <span>{t('menu:video_walkthrough')}</span>
                    </StyledAnchorListItem>
                    <StyledDivListItem onClick={onLogoutCb}>
                        <LogoutIcon size={20} />
                        <span>{t('menu:logout')}</span>
                    </StyledDivListItem>
                </NavItemsWrapper>
            </StyledMobileMenu>
            {showModal && <SelectMenuModal onClose={() => setShowModal(false)} />}
        </>
    )
}

MobileMenu.propTypes = {
    showMobileMenu: PropTypes.bool,
    setShowMobileMenuCb: PropTypes.func,
    onLogoutCb: PropTypes.func,
    navLinks: PropTypes.array
}
