import {Avatar} from 'src/components/avatar-legacy/Avatar'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const AssignmentHostCardContent = styled(Flexbox)`
    width: 100%;
`

export const AssignmentHostCardAvatar = styled(Avatar)`
    width: 64px;
    height: 64px;
    flex-shrink: 0;
`

export const StyledHostInfoList = styled(Flexbox)(
    ({theme: {typography}}) => css`
        ${typography.textSm};

        & a {
            cursor: pointer;
            text-decoration: underline;
        }
    `
)
