import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AdminHostApprovalTask} from 'src/features/admin-host-approval/types'
import {useUserStore} from 'src/features/user/store'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers'
import {QUERY_KEYS} from 'src/queryClient'
import {httpGetAdminHostApprovalTask} from 'src/features/admin-host-approval/services/adminHostApproval.http'

export const useAdminHostApprovalTask = (taskId?: number, options?: UseQueryOptions<AdminHostApprovalTask>) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK, taskId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAdminHostApprovalTask({
                    urlParams: {taskId: taskId ?? raise('The task id is not provided')},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: AdminHostApprovalTask,
                onZodError: captureException
            }),
        ...options
    })
}
