import styled, {css} from 'styled-components'

export const PublicLayoutWrapper = styled.div(
    ({theme: {palette}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        background: ${palette.neutral['50']};
        height: 100%;
        overflow: hidden;
    `
)
