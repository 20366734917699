import styled, {css} from 'styled-components'
import {Virtuoso} from 'react-virtuoso'
import {FreelancerPaymentDate} from 'src/features/freelancer-payments/types.ts'

export const StyledPaymentsList = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        height: 100%;
        overflow-y: hidden;
    `
)

export const StyledListHead = styled.div(
    ({theme: {spacing, typography, colors}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;

        & h3 {
            font-weight: 700;
            ${typography.textXl};
        }

        & span {
            font-weight: 400;
            ${typography.textMd};
            color: ${colors.darkGrey};
        }
    `
)

export const StyledAccordionsContainer = styled(Virtuoso<FreelancerPaymentDate['data'][number]>)(
    ({theme: {palette}}) => css`
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['300']};
        border-radius: 6px;
    `
)
