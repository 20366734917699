import {Flexbox} from 'src/components/flexbox/Flexbox'
import {useAdminHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useAdminHostApprovalTaskImages'
import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button'
import {AdminHostApprovalRoomsAmenitiesImages} from 'src/features/admin-host-approval/components/admin-host-approval-rooms-amenities-images/AdminHostApprovalRoomsAmenitiesImages'
import {useUpdateHostApprovalTaskStatus} from 'src/features/admin-host-approval/services/useUpdateHostApprovalTaskStatus'
import {useDispatch} from 'react-redux'
import {showAlert} from 'src/store/appGenerics'
import {useNavigate} from 'react-router'
import {ROUTES} from 'src/helpers/constants'
import {StyledAdminAdminHostApprovalApprovedImagesHeader} from './style'
import {makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers'
import {useAsync} from 'src/hooks/useAsync'
import {httpHostApprovalTaskDownloadImages} from '../../services/adminHostApproval.http'
import {useUserStore} from 'src/features/user/store'
import {Spinner} from 'src/components/spinner/Spinner'
import {useAdminHostApprovalTask} from '../../services/useAdminHostApprovalTask'
import {formatLocaleDate} from 'src/dayjs'

export const AdminHostApprovalApprovedImages = ({taskId}: {taskId: number}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const downloadImages = useAsync()
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is null')
    const {data: taskData} = useAdminHostApprovalTask(taskId, {
        enabled: false
    })

    const {data: taskImages, isLoading: isLoadingTaskImages} = useAdminHostApprovalTaskImages(taskId, {
        enabled: false
    })

    const {mutate: updateHostApprovalTaskStatus, isLoading: isLoadingUpdateHostApprovalTaskStatus} =
        useUpdateHostApprovalTaskStatus({
            taskId,
            options: {
                onSuccess: () => {
                    dispatch(
                        showAlert({
                            message: t('host_approval_task:task_completed'),
                            level: 'success'
                        })
                    )

                    navigate(ROUTES.ADMIN_HOST_APPROVAL_COMPLETED)
                }
            }
        })

    const handleDownloadImages = async () => {
        try {
            const {data} = await downloadImages.run(
                httpHostApprovalTaskDownloadImages({
                    headers: makeHttpAuthorizationHeader(adminAccessToken),
                    params: {taskId}
                })
            )

            const url = window.URL.createObjectURL(new Blob([data], {type: 'application/zip'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${taskData?.external_id} - ${formatLocaleDate(Date.now(), 'll')}`)
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            dispatch(
                showAlert({
                    message: t('host_approval_task:download_images_failed'),
                    level: 'error'
                })
            )
        }
    }

    return (
        <>
            <StyledAdminAdminHostApprovalApprovedImagesHeader justify="space-between" align="center" fullWidth>
                <h3>{t('host_approval_task:upload_to_mys')}</h3>
                <Flexbox align="center" gap={2}>
                    <Button
                        disabled={downloadImages.isLoading}
                        onClick={() => handleDownloadImages()}
                        variant="secondary"
                    >
                        {t('host_approval_task:download_images')}
                        {downloadImages.isLoading && <Spinner />}
                    </Button>
                    <Button
                        variant="primaryGrayModern"
                        disabled={isLoadingUpdateHostApprovalTaskStatus}
                        onClick={() => updateHostApprovalTaskStatus({status: 'completed'})}
                    >
                        {t('host_approval_task:mark_as_completed')}
                        {isLoadingUpdateHostApprovalTaskStatus && <Spinner />}
                    </Button>
                </Flexbox>
            </StyledAdminAdminHostApprovalApprovedImagesHeader>
            <AdminHostApprovalRoomsAmenitiesImages
                taskId={taskId}
                isLoadingTaskImages={isLoadingTaskImages}
                emptyResultMessage={t('host_approval_task:no_images_approved')}
                showUploadedToggle
                taskImages={
                    taskImages
                        ? taskImages.filter(
                              taskImage => taskImage.amenity_id && taskImage.room_id && taskImage.is_host_approved
                          )
                        : []
                }
            />
        </>
    )
}
