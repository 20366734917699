import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledAnalyticsCardGrid,
    StyledParagraph,
    StyledTypeTab,
    StyledTypeTabsWrapper,
    StyledSubtitle,
    StyledSelectorLabel
} from './style'
import {Button} from '../../../../components/button/Button'
import {useGenerateTransactionsStats} from '../../../../features/admin-payments/services/useGenerateTransactionsStats'
import {formatCurrency} from '../../../../helpers/utils'
import {useGenerateTransactions} from '../../../../features/admin-payments/services/useGenerateTransactions'
import {showAlert} from '../../../../store/appGenerics'
import {useDispatch} from 'react-redux'
import {TransactionsStatsCard} from '../../../../features/admin-payments/components/transactions-stats-card/TransactionsStatsCard'

const PAYMENTS_GENERATION_TYPE = {
    allShootsType: 'all_shoots_type',
    walkthrough: 'walkthrough'
}
export const PaymentsGenerator = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [generationType, setGenerationType] = useState(PAYMENTS_GENERATION_TYPE.allShootsType)
    const {data: transactionsStats, isLoading} = useGenerateTransactionsStats(generationType)
    const {mutate: generateTransactions, isLoading: isGenerating} = useGenerateTransactions({
        onSuccess: () => {
            dispatch(showAlert({message: t('admin:payments:export:completed_message'), level: 'success'}))
        }
    })

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <StyledSubtitle>{t('admin:payments:title')}</StyledSubtitle>
                    <StyledParagraph>{t('admin:payments:export:subtitle')}</StyledParagraph>
                    <StyledSelectorLabel>{t('admin:payments:export:select_labels')}</StyledSelectorLabel>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <StyledTypeTabsWrapper>
                            {Object.values(PAYMENTS_GENERATION_TYPE).map(type => (
                                <StyledTypeTab
                                    onClick={() => setGenerationType(type)}
                                    isActive={generationType === type}
                                >
                                    {t(`admin:payments:export:${type}`)}
                                </StyledTypeTab>
                            ))}
                        </StyledTypeTabsWrapper>
                        <Button onClick={() => generateTransactions(generationType)} disabled={isGenerating}>
                            {t('admin:payments:export:cta')}
                        </Button>
                    </div>

                    <StyledAnalyticsCardGrid>
                        <TransactionsStatsCard
                            isLoading={isLoading}
                            label={t('admin:payments:export:total_transactions', {
                                count: transactionsStats?.transaction_count ?? 0
                            })}
                            count={
                                transactionsStats?.total_transaction_amount ||
                                transactionsStats?.total_transaction_amount === 0
                                    ? formatCurrency(transactionsStats?.total_transaction_amount, 'USD')
                                    : '-'
                            }
                        />
                        <TransactionsStatsCard
                            isLoading={isLoading}
                            label={t('admin:payments:export:total_payouts', {
                                count: transactionsStats?.payout_count ?? 0
                            })}
                            count={
                                transactionsStats?.total_payout_amount || transactionsStats?.total_payout_amount === 0
                                    ? formatCurrency(transactionsStats?.total_payout_amount, 'USD')
                                    : '-'
                            }
                        />
                        <TransactionsStatsCard
                            isLoading={isLoading}
                            label={t('admin:payments:export:total_adjustments', {
                                count: transactionsStats?.adjustment_count ?? 0
                            })}
                            count={
                                transactionsStats?.total_adjustment_amount ||
                                transactionsStats?.total_adjustment_amount === 0
                                    ? formatCurrency(transactionsStats?.total_adjustment_amount, 'USD')
                                    : '-'
                            }
                        />
                        <TransactionsStatsCard
                            isLoading={isLoading}
                            label={t('admin:payments:export:payments_left')}
                            count={transactionsStats?.remaining_payments ?? '-'}
                        />
                    </StyledAnalyticsCardGrid>
                </div>
            </div>
        </>
    )
}

PaymentsGenerator.displayName = 'PaymentsGenerator'
