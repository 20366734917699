import axios, {RawAxiosRequestHeaders} from 'axios'
import {Order, ResponseTypeParam} from 'src/types.ts'

export type HttpGetAdminHostsOptions = {
    headers: RawAxiosRequestHeaders
    params: Partial<Order> &
        ResponseTypeParam & {
            limit: number
            page: number
            search?: string
        }
}
export const httpGetAdminHosts = ({params, headers}: HttpGetAdminHostsOptions) => {
    return axios.get('/admin/hosts', {params, headers})
}

export type HttpGetImpersonateOptions = {
    headers: RawAxiosRequestHeaders
    urlParams: {
        externalId: string
    }
}
export const httpGetAdminHostsImpersonate = ({urlParams, headers}: HttpGetImpersonateOptions) =>
    axios.get(`admin/hosts/${urlParams.externalId}/impersonate`, {headers})
