import {CSSProperties, forwardRef, PropsWithChildren, ReactNode, useState} from 'react'
import * as RTooltip from '@radix-ui/react-tooltip'
import {StyledTooltipContent} from 'src/components/tooltip/style.ts'

interface TooltipProps extends PropsWithChildren {
    children: ReactNode
    content: ReactNode
    contentProps?: RTooltip.TooltipContentProps
    delayDuration?: number
    portalProps?: RTooltip.TooltipPortalProps
    rootProps?: RTooltip.TooltipProps
    triggerProps?: RTooltip.TooltipTriggerProps
    variant?: 'light' | 'dark'
    maxWidth?: CSSProperties['maxWidth']
}

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
    (
        {
            children,
            rootProps,
            triggerProps,
            portalProps,
            contentProps,
            content,
            variant = 'dark',
            delayDuration = 350,
            maxWidth
        },
        ref
    ) => {
        const [isForcedOpen, setIsForcedOpen] = useState(false)

        return (
            <RTooltip.Provider delayDuration={delayDuration}>
                <RTooltip.Root open={isForcedOpen || undefined} {...rootProps}>
                    <RTooltip.Trigger
                        {...triggerProps}
                        onClick={(...args) => {
                            setIsForcedOpen(true)
                            triggerProps?.onClick?.(...args)
                        }}
                    >
                        {children}
                    </RTooltip.Trigger>
                    <RTooltip.Portal {...portalProps}>
                        <StyledTooltipContent
                            collisionPadding={8}
                            {...contentProps}
                            onPointerDownOutside={(...args) => {
                                setIsForcedOpen(false)
                                contentProps?.onPointerDownOutside?.(...args)
                            }}
                            ref={ref}
                            $variant={variant}
                            $maxWidth={maxWidth}
                        >
                            {content}
                            <RTooltip.Arrow width={16} height={6} />
                        </StyledTooltipContent>
                    </RTooltip.Portal>
                </RTooltip.Root>
            </RTooltip.Provider>
        )
    }
)

Tooltip.displayName = 'Tooltip'
