/**
 * retrieveBotActualState
 * @param shooting
 * @returns {number}
 */
import {getMetaAttribute} from '../../helpers/utils'
import {isFromPull} from '../../helpers/chatbot'

/**
 * retrieveEntityInfos
 * @param shooting
 * @returns {{roomsNumber: *, quoteType: string}}
 */
export const retrieveEntityInfos = shooting => {
    let roomsNumber = ''
    let quoteType = ''

    if (shooting?.entity_meta?.attribute === 'Bedrooms') {
        roomsNumber = shooting?.entity_meta?.value
        quoteType = 'BnB'
    } else {
        roomsNumber = ''
        quoteType = 'Experience'
    }

    return {roomsNumber, quoteType}
}

/**
 *
 * @param reason
 * @param isInShootPage
 * @param shoot
 * @param steps
 * @param isNotListed
 * @returns {{subject: string, message: string, flow: string}}
 */
export const defineTicketInfoByReason = (reason, isInShootPage, shoot, steps, isNotListed) => {
    const getAirbnbId = stepIdToCheck => {
        return isInShootPage && !isFromPull(steps)
            ? shoot?.entity?.external_id
            : steps[stepIdToCheck]?.value?.entity?.external_id
    }

    const getBedroomsNumber = stepIdToCheck => {
        return isInShootPage && !isFromPull(steps)
            ? getMetaAttribute(shoot?.meta, 'Bedrooms')
            : getMetaAttribute(steps[stepIdToCheck]?.value?.meta, 'Bedrooms')
    }

    let airbnbId = null
    const bedroomsNumber = getBedroomsNumber('MB1_yes_pull')
    const bedroomsNotCompleted = getBedroomsNumber('MB1_no_pull')
    switch (reason) {
        case 'new_quote':
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Force Match',
                message: `The Host wants me to photograph another listing: #${steps['MB0.2.1']?.value}`,
                flow: 'MB.0 - HOST WANTS ME TO PHOTOGRAPH ANOTHER LISTING'
            }
        case 'wrong_br_number_completed':
            airbnbId = getAirbnbId('MB1_yes_pull')

            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Wrong n. of bedrooms/ post photoshoot',
                message: `The number of bedrooms in my assignment is wrong. I have already shoot the listing #${airbnbId}. Number of bedroom in MB: ${
                    bedroomsNumber || '-'
                }. Number of bedroom shoot: ${steps.MB1_yes_br_input?.value}`,
                flow: 'MB1.1.1 - THE NUMBER OF BEDROOMS IN MY ASSIGNMENT IS WRONG'
            }
        case 'wrong_br_number_completed_notfound':
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Wrong n. of bedrooms/ post photoshoot',
                message: `The number of bedrooms in my assignment is wrong. I have already shoot the listing #${steps['MB1_yes_notfound_input']?.value}. Number of bedroom in MB: UNKNOWN. Number of bedroom shoot: ${steps.MB1_yes_notfound_br_input?.value}`,
                flow: 'MB1.1.1 - THE NUMBER OF BEDROOMS IN MY ASSIGNMENT IS WRONG'
            }
        case 'wrong_br_number_not_completed':
            airbnbId = getAirbnbId('MB1_no_pull')

            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Wrong n. of bedrooms/ pre photoshoot',
                message: `The number of bedrooms in my assignment is wrong. I have not shoot the listing #${airbnbId}. Number of bedroom in MB: ${
                    bedroomsNotCompleted || '-'
                }. Number of bedroom shoot: ${steps.MB1_no_br_input?.value}`,
                flow: 'MB1.1.2 - THE NUMBER OF BEDROOMS IN MY ASSIGNMENT IS WRONG'
            }
        case 'wrong_br_number_not_completed_notfound':
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Wrong n. of bedrooms/ pre photoshoot',
                message: `The number of bedrooms in my assignment is wrong. I have not shoot the listing #${steps['MB1_no_notfound_input']?.value}. Number of bedroom in MB: UNKNOWN. Number of bedroom shoot: ${steps.MB1_no_notfound_br_input?.value}`,
                flow: 'MB1.1.2 - THE NUMBER OF BEDROOMS IN MY ASSIGNMENT IS WRONG'
            }
        case 'cancel_notready':
            airbnbId = getAirbnbId('MB1.4_pull')
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - The Host is not ready',
                message: ` I want to cancel the photoshoot / The Host is not ready/ Other reason. Airbnb ID: ${airbnbId}. Notes: ${steps['MB1.4_notready_notes_input']?.value}`,
                flow: 'MB1.4 - I WANT TO CANCEL THE PHOTOSHOOT - THE HOST IS NOT READY'
            }
        case 'cancel_listingproblem':
            airbnbId = getAirbnbId('MB1.4_pull')
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Problem with the listing',
                message: `I want to cancel the photoshoot / Problem with the listing/ Other reason. Airbnb ID: ${airbnbId}. Notes: ${steps['MB1.4_listingproblem_notes_input']?.value} `,
                flow: 'MB1.4 - I WANT TO CANCEL THE PHOTOSHOOT - PROBLEM WITH THE LISTING'
            }
        case 'cancel_coordination':
            airbnbId = getAirbnbId('MB1.4_pull')
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Coordination issues',
                message: `I want to cancel the photoshoot / Coordination issues/ Other reason. Airbnb ID: ${airbnbId}. Notes: ${steps['MB1.4_coordination_notes']?.value}`,
                flow: 'MB1.4 - I WANT TO CANCEL THE PHOTOSHOOT - COORDINATION ISSUES'
            }
        case 'cancel_noshow':
            airbnbId = getAirbnbId('MB1.4_pull')
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Host no-show',
                message: ` Host no-show - Compensation request. Airbnb ID: ${airbnbId}. Notes: ${steps['MB1.4_noshow_notes_input']?.value}`,
                flow: 'MB1.4 - I WANT TO CANCEL THE PHOTOSHOOT - HOST NO-SHOW'
            }
        case 'payment_issue':
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Payment information request ',
                message: `I want to check the status of my payment for ${steps['MB2.2_notfound_input']?.value}`,
                flow: 'MB2.2- I WANT TO CHECK IF MY PAYMENT HAS BEEN ISSUED'
            }
        case 'compensation':
            airbnbId = getAirbnbId('MB2.3_pull')
            switch (steps['MB2.3.1'].value) {
                case 'canceled':
                    return {
                        subject: '[Chatbot] Support request from the MB-CHATBOT - Photographer Compensation',
                        message: `My scheduled photoshoot was canceled within 24 hours. ${steps['MB2.3_notes']?.value}. ID: #${airbnbId}`,
                        flow: 'MB2.3.1- MY SCHEDULED PHOTOSHOOT WAS CANCELED WHITHIN 24 HOURS'
                    }
                case 'rescheduled':
                    return {
                        subject: '[Chatbot] Support request from the MB-CHATBOT - Photographer Compensation',
                        message: `My scheduled photoshoot was rescheduled within 24 hours. ${steps['MB2.3_notes']?.value}. ID: #${airbnbId}`,
                        flow: 'MB2.3.2 - MY SCHEDULED PHOTOSHOOT WAS RESCHEDULED WHITHIN 24 HOURS'
                    }
                case 'no_show':
                    return {
                        subject: '[Chatbot] Support request from the MB-CHATBOT - Photographer Compensation',
                        message: `Host did not show up at the scheduled date and time. ${steps['MB2.3_notes']?.value}. ID: #${airbnbId}`,
                        flow: 'MB2.3.3 - HOST DID NOT SHOW UP AT THE SCHEDULED DATE AND TIME'
                    }
                case 'wrong_br_number':
                    return {
                        subject: '[Chatbot] Support request from the MB-CHATBOT - Photographer Compensation',
                        message: `The listing was not ready at the scheduled date and time. ${steps['MB2.3_notes']?.value}. ID: #${airbnbId}`,
                        flow: 'MB2.3.4 - THE LISTING WAS NOT READY AT THE SCHEDULED DATE AND TIME'
                    }
            }
            break
        case 'compensation_notfound':
            return {
                subject: '[Chatbot] Support request from the MB-CHATBOT - Photographer Compensation',
                message: `I want to open a ticket for Airbnb ID #${steps['MB2.3_notfound_input']?.value}. ${steps['MB2.3_notfound_notes_input']?.value}`,
                flow: 'MB2.3 - GENERIC COMPENSATION ISSUE'
            }
        default:
            //'open_ticket' case
            return {
                message: isNotListed
                    ? `${steps['MB5_ticket_message']?.value || steps['MB6_input_question']?.value}`
                    : `${steps['MB4.1']?.value}`,
                subject: 'Support request from the MB-CHATBOT [open Ticket]',
                flow: 'My topic is not listed on the chatbot'
            }
    }
}

export const chatHighlights = chatHistory => {
    let highlights = []

    chatHistory.forEach(({id}) => {
        switch (id) {
            case 'MB0_ticket': // Ticket
                highlights.push('New quote request')
                break
            case 'MB1_yes_ticket': // Ticket
            case 'MB1_yes_notfound_ticket': // Ticket
            case 'MB1_no_ticket': // Ticket
            case 'MB1_no_notfound_ticket': // Ticket
                highlights.push('Wrong bedrooms number ticket')
                break
            case 'MB1.2_cancel':
                highlights.push('Cancel photoshoot')
                break
            case 'MB1.3.2_no':
                highlights.push('Get Host email')
                break
            case 'MB1.3.3_no':
                highlights.push('Get Host number')
                break
            case 'MB1.3.5_reminder':
                highlights.push('Send reminder')
                break
            case 'MB1.3.5_cancel':
                highlights.push('Cancel photoshoot')
                break
            case 'MB1.4_cancel':
                highlights.push('Cancel photoshoot')
                break
            case 'MB2.2_issuedate':
                highlights.push('Get payment issue date')
                break
            case 'MB2.2_ticket': // Ticket
                highlights.push('Payment issue ticket')
                break
            case 'MB2.3_ticket': // Ticket
                highlights.push('Compensation request')
                break
            case 'MB2.3_notfound_ticket': // Ticket
                highlights.push('Compensation request')
                break
            case 'MB4_ticket': // Ticket
                highlights.push('Generic ticket')
                break
        }
    })

    return highlights.join(' --- ')
}
