import {Modal} from 'src/components/modal/Modal.tsx'
import {
    ModalBody,
    ModalXCloseButton,
    ModalFooter,
    ModalHeader,
    ModalTitle
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {formatLocaleDate} from 'src/dayjs.ts'
import {Button} from 'src/components/button/Button.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {useScheduleAssignment} from 'src/features/assignment/services/useScheduleAssignment.ts'
import {Assignment} from 'src/features/assignment/types.ts'

export const ScheduleDateModal = ({
    assignmentId,
    onClose,
    selectedDate
}: {
    assignmentId: Assignment['id']
    onClose: () => void
    selectedDate: string
}) => {
    const {t} = useTranslation()
    const scheduleAssignmentMutation = useScheduleAssignment({
        assignmentId,
        options: {
            onSuccess: onClose
        }
    })

    return (
        <Modal onOverlayClick={onClose} width={480}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} disabled={scheduleAssignmentMutation.isLoading} />
            </ModalHeader>
            <ModalBody>
                <ModalTitle>
                    <Trans
                        i18nKey="assignment:descriptions:accepted_dates_shared:schedule_confirmation_title"
                        components={{b: <b />}}
                        values={{date: formatLocaleDate(selectedDate, 'LLLL')}}
                    />
                </ModalTitle>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} variant="tertiary" disabled={scheduleAssignmentMutation.isLoading}>
                    {t('commons:close')}
                </Button>
                <Button
                    type="submit"
                    disabled={scheduleAssignmentMutation.isLoading}
                    onClick={() => scheduleAssignmentMutation.mutate(selectedDate)}
                >
                    {t('assignment:descriptions:schedule')}
                    {scheduleAssignmentMutation.isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
