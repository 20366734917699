import {AlertTriangleIcon} from 'src/components/icon'
import {useTranslation} from 'react-i18next'
import {StyledAssignment, StyledLoadingContainer} from './style.ts'
import {useAssignment} from 'src/features/assignment/services/useAssignment.ts'
import {useURLParsedParams} from 'src/hooks/useURLParsedParams.ts'
import {ErrorBox} from 'src/components/error-box/ErrorBox.tsx'
import {AxiosError} from 'axios'
import {Container} from 'src/components/container/Container.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {Helmet} from 'react-helmet'
import {AssignmentHero} from 'src/features/assignment/components/assignment-hero/AssignmentHero.tsx'
import {AssignmentContent} from 'src/features/assignment/components/assignment-content/AssignmentContent.tsx'
import {checkIsCompletedStatus} from 'src/helpers/statuses.tsx'
import {AssignmentUrlParams} from 'src/features/assignment/types.ts'
import {useEffect} from 'react'
import {setCurrentAssignment} from 'src/store/chatbot.ts'
import {useDispatch} from 'react-redux'

/*const checkIsRatingModalOpen = () => {
    if (localStorage.getItem(RATING_MODAL.NEW_ASSIGNMENT_RATING_DONE)) {
        return false
    }
    const ratingsClosedTime = localStorage.getItem(RATING_MODAL.NEW_ASSIGNMENT_RATING_CLOSED_TIME_LS)
    const hasPassed24Hours = dayjs().diff(ratingsClosedTime, 'hour') >= 24
    return !ratingsClosedTime || hasPassed24Hours
}*/

export const Assignment = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const urlParams = useURLParsedParams(AssignmentUrlParams)
    //const [isRatingModalOpen, setIsRatingModalOpen] = useState(checkIsRatingModalOpen())
    const assignmentQuery = useAssignment(urlParams.data?.code, {
        enabled: urlParams.success
    })

    useEffect(() => {
        dispatch(setCurrentAssignment(assignmentQuery.data))
    }, [assignmentQuery.data])

    if (assignmentQuery.isLoading) {
        return (
            <StyledLoadingContainer>
                <Spinner size={40} />
            </StyledLoadingContainer>
        )
    }
    if (
        urlParams.error ||
        (assignmentQuery.error instanceof AxiosError && assignmentQuery.error.response?.status == 404)
    ) {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('assignment:not_found')} />
            </Container>
        )
    }
    if (assignmentQuery.error || !assignmentQuery.data) {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
            </Container>
        )
    }
    if (
        checkIsCompletedStatus(
            assignmentQuery.data.status,
            assignmentQuery.data.task_status.status,
            assignmentQuery.data.task_status.coordination_status
        )
    ) {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('assignment:not_available')} />
            </Container>
        )
    }

    return (
        <StyledAssignment>
            <Helmet
                title={t('assignment:meta_title', {
                    title: assignmentQuery.data.entity.title ?? assignmentQuery.data.entity.external_id
                })}
            />
            <AssignmentHero assignment={assignmentQuery.data} />
            <AssignmentContent assignment={assignmentQuery.data} />
            {/*{isRatingModalOpen && (
                <FeaturesRatingModal
                    mainQuestion={t('consent_collector:new_assignment_question')}
                    modalCompletedTitle={t('consent_collector:main_thanks_text')}
                    modalIcon={<RedPageIcon />}
                    heightValueLabel={t('consent_collector:helper_text_very_satisfied')}
                    lowValueLabel={t('consent_collector:helper_text_not_satisfied')}
                    onClose={() => setIsRatingModalOpen(false)}
                    type={'new-assignment'}
                />
            )}*/}
        </StyledAssignment>
    )
}
