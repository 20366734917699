import styled, {css} from 'styled-components'

export const PageNotFoundWrapper = styled.div<{$pageHeight: number}>(
    ({$pageHeight}) => css`
        height: ${$pageHeight}px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    `
)

export const StyledTitle = styled.h1(
    ({theme: {typography}}) => css`
        ${typography.display2xl};
        font-weight: 700;
    `
)

export const StyledMessage = styled.h2(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 700;
    `
)
