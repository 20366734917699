import {ChevronDownIcon} from 'src/components/icon'
import {FC} from 'react'
import {Select, SelectProvider, ComboboxProvider, useSelectContext} from '@ariakit/react'
import {StyledAdminSelectTrigger} from 'src/features/admin-host-approval/components/admin-select-cell/style.ts'
import {raise} from 'src/helpers/helpers.ts'
import {AdminSelectPopover} from 'src/features/admin-host-approval/components/admin-select-popover/AdminSelectPopover.tsx'
import {AdminSelectDisplayValue} from 'src/features/admin-host-approval/components/admin-select-display-value/AdminSelectDisplayValue.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'

interface AdminSelectCellProps {
    adminId: number
    isLoading?: boolean
    setValue: (adminId: number) => void
}

export const AdminSelectCell: FC<AdminSelectCellProps> = ({adminId, setValue, isLoading}) => {
    return (
        <ComboboxProvider resetValueOnHide>
            <SelectProvider value={adminId.toString()} setValue={value => setValue(Number(value))}>
                <AdminSelectCellContent isLoading={isLoading} />
            </SelectProvider>
        </ComboboxProvider>
    )
}

const AdminSelectCellContent: FC<{isLoading?: boolean}> = ({isLoading}) => {
    const selectContext = useSelectContext() ?? raise('SelectComboboxList must be a child of SelectCombobox')
    const selectValue = selectContext.useState('value') as string //as needed because it's a single select and the provided type is string | string[]

    return (
        <>
            <Select
                disabled={isLoading}
                render={
                    <StyledAdminSelectTrigger>
                        <AdminSelectDisplayValue id={selectValue} />
                        {isLoading ? <Spinner size={16} /> : <ChevronDownIcon size={16} />}
                    </StyledAdminSelectTrigger>
                }
            />
            <AdminSelectPopover />
        </>
    )
}
