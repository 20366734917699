import {ImagesList} from './images-list/ImagesList'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {roomsAutocompleteSelector, roomsListSelector, setRoomsAutocomplete} from '../../../../../store/matterport'
import {useTranslation} from 'react-i18next'
import {httpGetRoomsAutocomplete} from '../../../../../http-requests/admin/matterport.http'
import {errorHandler} from '../../../../../helpers/utils'
import {useEffect} from 'react'
import {useUserStore} from '../../../../../features/user/store'

export const ReviewView = ({setFullScreenImageCb}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const roomsAutocomplete = useSelector(roomsAutocompleteSelector)
    const roomsList = useSelector(roomsListSelector)
    const translatedRoomsList = roomsList.map(room => {
        const roomName = roomsAutocomplete?.find(e => e.id === room.roomId)?.internal_name?.toLowerCase()

        return {
            ...room,
            label: `${t(`admin:rooms:${roomName}`)} ${room.number || ''}`
        }
    })

    useEffect(() => {
        void getRoomsAutocomplete()
    }, [])

    const getRoomsAutocomplete = async () => {
        try {
            const {data} = await httpGetRoomsAutocomplete(adminAccessToken)
            dispatch(setRoomsAutocomplete(data))
        } catch (e) {
            errorHandler(e)
        }
    }

    return (
        <div className="container">
            <div className="row">
                {/*<StyledLeftColumn className='col-auto'>
                    <RoomsList
                        leftColumnSpacingFromTop={leftColumnSpacingFromTop}
                        setFullScreenImageCb={setFullScreenImageCb}
                        rooms={translatedRoomsList}
                    />
                </StyledLeftColumn>*/}
                <div className="col">
                    <ImagesList setFullScreenImageCb={setFullScreenImageCb} rooms={translatedRoomsList} />
                </div>
            </div>
        </div>
    )
}

ReviewView.propTypes = {
    leftColumnSpacingFromTop: PropTypes.number,
    setFullScreenImageCb: PropTypes.func
}
