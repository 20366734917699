import {PublicHeader} from '../../components/public-header/PublicHeader'
import {Outlet} from 'react-router-dom'
import {PublicLayoutWrapper} from './style'
import {Snackbar} from '../../components/snackbar/Snackbar'

export const PublicLayout = () => {
    return (
        <PublicLayoutWrapper id="public-layout">
            <PublicHeader />
            <Outlet />
            <Snackbar />
        </PublicLayoutWrapper>
    )
}
