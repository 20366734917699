import {useTranslation} from 'react-i18next'
import {Skeleton} from '../../../../../components/skeleton/Skeleton'
import {IconWrapper} from '../../../../../components/icon-wrapper/IconWrapper'
import {CheckIcon} from '../../../../../assets/icons/CheckIcon'
import {ListIcon} from '../../../../../assets/icons/ListIcon'
import {PAYMENTS_STATS} from '../../../../../helpers/constants'
import {colors} from '../../../../../theme/colors'
import {StyledPaymentCard, StyledPaymentCardWrapper, StyledPaymentCardTitle} from './style'
import {ReactNode} from 'react'
import {useTheme} from 'styled-components'

export const PaymentsAnalyticsSection = ({
    isLoading,
    paymentsStats
}: {
    isLoading?: boolean
    paymentsStats: Record<(typeof PAYMENTS_STATS)[keyof typeof PAYMENTS_STATS], ReactNode>
}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <StyledPaymentCardWrapper>
            <StyledPaymentCard>
                <StyledPaymentCardTitle>
                    <p>{t('admin:payments:uploaded_recently:paid_last_week')}</p>
                    {isLoading ? (
                        <Skeleton width="200" height={42} />
                    ) : (
                        <h6>{paymentsStats[PAYMENTS_STATS.PAID_LAST_WEEK]}</h6>
                    )}
                </StyledPaymentCardTitle>
                <IconWrapper>
                    <CheckIcon size={24} fill={colors.darkGreen} />
                </IconWrapper>
            </StyledPaymentCard>
            <StyledPaymentCard>
                <StyledPaymentCardTitle>
                    <p>{t('admin:payments:uploaded_recently:processed_last_week')}</p>
                    {isLoading ? (
                        <Skeleton width="200" height={42} />
                    ) : (
                        <h6>{paymentsStats[PAYMENTS_STATS.PROCESSED_LAST_WEEK]}</h6>
                    )}
                </StyledPaymentCardTitle>
                <IconWrapper>
                    <ListIcon size={24} fill={theme.palette.neutral['500']} />
                </IconWrapper>
            </StyledPaymentCard>
        </StyledPaymentCardWrapper>
    )
}
