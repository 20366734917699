import styled, {css} from 'styled-components'

export const StyledImageList = styled.div(
    ({theme: {spacing}}) => css`
        padding-left: ${spacing * 6}px;
        .accordionBody {
            padding: ${spacing * 4}px;
        }
    `
)

export const StyledTitle = styled.div(
    ({theme: {spacing, typography}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: ${spacing * 2}px;
        & > h2 {
            ${typography.displayXs};
            font-weight: 500;
        }
    `
)

export const StyledButtons = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 4}px;
    `
)

export const StyledInfo = styled.p(
    ({theme: {colors, spacing, typography}}) => css`
        ${typography.textSm};
        font-weight: 400;
        font-style: italic;
        color: ${colors.lightBlack};
        margin-bottom: ${spacing * 8}px;
        margin-top: ${spacing * 4}px;
    `
)

export const StyledError = styled.p(
    ({theme: {spacing, colors, typography}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing}px;
        margin-bottom: ${spacing * 4}px;
        & svg {
            width: 16px;
            height: auto;
            & stroke {
                fill: ${colors.red};
            }
        }
        & span {
            ${typography.textXs};
            font-weight: 700;
            color: ${colors.red};
        }
    `
)

export const StyledSubSection = styled.div(
    ({theme: {spacing, typography}}) => css`
        & > h3 {
            ${typography.displayXs};
            font-weight: 500;
            margin: ${spacing * 16}px 0 ${spacing * 8}px;
        }
    `
)

export const StyledList = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: ${spacing * 8}px;
    `
)
