import {CloseIcon} from '../../theme/icons'
import {StyledHeader} from './style'
import {useTranslation} from 'react-i18next'

export const ChatBotHeader = ({botOpened, setBotOpened}) => {
    const {t} = useTranslation()

    return (
        <StyledHeader>
            <p>{t('chatbot:chatbot_header:title')}</p>
            <CloseIcon onClick={() => setBotOpened(!botOpened)} />
        </StyledHeader>
    )
}
