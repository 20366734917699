import styled, {css} from 'styled-components'

export const StyledOpenIcon = styled.div<{$showText: boolean}>`
    ${({$showText, theme: {colors, spacing, mediaQuery, transition}}) => css`
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${colors.darkGrey};
        min-width: ${$showText ? '100%' : '0'};
        width: ${$showText ? '100%' : '28px'};
        height: 28px;
        cursor: pointer;
        gap: ${spacing * 2}px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        ${transition};
        border-radius: 8px;

        &:hover {
            border-color: ${colors.darkGrey};
        }

        ${mediaQuery.LG} {
            width: ${$showText ? '100%' : '40px'};
            height: 40px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }
    `}
`
