import styled, {css} from 'styled-components'
import {Tab} from '../../../tab/Tab'
import {ChevronIcon} from '../../../../assets/icons/ChevronIcon'

const StyledToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledHeading = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textLg}
        font-weight: 700;
        color: ${palette.neutral.black};
        text-transform: capitalize;
    `}
`

const StyledNavBar = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

const StyledTodayTab = styled(Tab)`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral.black};
    `}
`

const StyledChevronIcon = styled(ChevronIcon)`
    -ms-transform: rotate(180deg); /* IE 9 */
    transform: rotate(180deg);
`

export {StyledToolbar, StyledHeading, StyledNavBar, StyledTodayTab, StyledChevronIcon}
