import {
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {useState} from 'react'
import {ScheduleModal} from 'src/features/assignment/components/schedule-modal/ScheduleModal.tsx'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {ClockIcon} from 'src/components/icon'
import {formatLocaleDate} from 'src/dayjs.ts'
import {StyledAssignmentDescriptionParagraph} from 'src/features/assignment/components/assignment-descriptions/accepted-host-not-ready-description/style.ts'

export const AcceptedHostNotReadyDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {t('assignment:descriptions:accepted_host_not_ready:title', {
                        date: formatLocaleDate(assignment.host_not_ready_at, 'LL')
                    })}
                </AssignmentDescriptionTitle>
                {assignment.host_ready_at ? (
                    <StyledAssignmentDescriptionParagraph>
                        <ClockIcon size={20} />
                        <span>
                            <Trans
                                i18nKey="assignment:descriptions:accepted_host_not_ready:paragraph_date"
                                values={{date: formatLocaleDate(assignment.host_ready_at, 'llll')}}
                                components={{strong: <strong />}}
                            />
                        </span>
                    </StyledAssignmentDescriptionParagraph>
                ) : (
                    t('assignment:descriptions:accepted_host_not_ready:paragraph', {
                        date: formatLocaleDate(assignment.reminder_at, 'LL')
                    })
                )}
            </AssignmentDescriptionText>
            <Button onClick={() => setIsScheduleModalOpen(true)}>{t('assignment:descriptions:schedule')}</Button>
            {isScheduleModalOpen && (
                <ScheduleModal
                    assignment={assignment}
                    serviceType={serviceType}
                    onClose={() => setIsScheduleModalOpen(false)}
                />
            )}
        </AssignmentDescriptionWrapper>
    )
}
