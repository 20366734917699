import styled, {css} from 'styled-components'
import {BackArrowIcon} from '../../../../../../theme/icons'

export const StyledSubheader = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        z-index: 2;
        width: 100%;
        padding: ${spacing * 8}px;
        background: #efefef;
        margin-bottom: ${spacing * 16}px;
    `}
`

export const StyledData = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;

        & > div {
            display: flex;
            gap: ${spacing * 2}px;
            ${typography.textMd};
            color: ${palette.neutral.black};

            & > label {
                font-weight: 400;
                flex: none;
            }

            & > span {
                font-weight: 700;
                word-break: break-word;
            }
        }
    `
)

export const StyledEligible = styled.div`
    ${({theme: {palette}}) => css`
        & > span {
            font-weight: 700;

            & svg {
                width: 14px;
                fill: ${palette.neutral.black};
            }
        }
    `}
`

export const StyledButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
`

export const StyledTopButtons = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: ${spacing * 6}px;
    `}
`

export const StyledBottomButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
`

export const StyledBackButton = styled(BackArrowIcon)`
    ${({theme: {palette, transition}}) => css`
        position: absolute;
        top: 4px;
        left: 24px;
        width: 32px;
        fill: ${palette.neutral.black};
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    `}
`
