import styled, {css} from 'styled-components'

const BaseStyle = ({clickableRowPath, alternatingRowColors, theme: {spacing, colors, palette, transition}}) => css`
    display: flex;
    align-items: center;
    cursor: initial;
    flex-wrap: wrap;
    padding: 0 ${spacing * 3}px;
    border-bottom: 1px solid ${colors.smokeGrey};
    background: ${palette.neutral.white};
    ${clickableRowPath &&
    css`
        ${transition};
        cursor: pointer;

        &:hover {
            background: ${palette.neutral['50']};
        }
    `}

    &:nth-child(even) {
        background: ${alternatingRowColors ? palette.neutral['50'] : 'unset'};
    }
`

const ColorStyles = () => ({
    grey: css`
        background: transparent;
        padding: 0;
    `
})

export const StyledTRow = styled.div`
    ${BaseStyle};
    ${({variant, theme}) => ColorStyles({theme})[variant]};
`

export const StyledTCell = styled.div`
    ${({theme: {spacing}, alignment, width}) => css`
        display: grid;
        justify-content: ${alignment === 'stretch'
            ? 'stretch'
            : alignment === 'center'
            ? 'center'
            : alignment === 'right'
            ? 'flex-end'
            : 'flex-start'};
        align-items: center;
        min-height: 56px;
        padding: 0 ${spacing * 2}px;
        width: ${width};
    `}
`

export const StyledTCellText = styled.span`
    ${({theme: {palette, typography}, allowOverflow}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['900']};
        overflow: ${allowOverflow ? 'visible' : 'hidden'};
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`

export const StyledExpandedRow = styled.div`
    width: 100%;
`

export const StyledSubRow = styled.div`
    ${({theme: {typography, palette}}) => css`
        width: 100%;
        color: ${palette.neutral['900']};
        ${typography.textXs};
    `}
`
