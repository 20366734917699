import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {AssignmentScheduledDate} from 'src/features/assignment/components/assignment-scheduled-date/AssignmentScheduledDate'
import {FC} from 'react'
import {Assignment, ServiceType} from 'src/features/assignment/types'

interface ScheduledDescriptionProps {
    date: Assignment['to_shoot_at']
    serviceType: ServiceType
}

export const ScheduledDescription: FC<ScheduledDescriptionProps> = ({date, serviceType}) => {
    const {t} = useTranslation()

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>{t('assignment:descriptions:scheduled:title')}</AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:scheduled:paragraph:${serviceType}`)}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            <AssignmentScheduledDate date={date} />
        </AssignmentDescriptionWrapper>
    )
}
