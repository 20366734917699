import {
    StyledAccordionHead,
    StyledAccordionWrapper
} from 'src/features/freelancer-payments/components/payments-accordion/style.ts'
import {Skeleton} from 'src/components/skeleton/Skeleton.tsx'
import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'
import {formatDateWithLocaleIntl} from 'src/helpers/helpers.ts'
import {FreelancerPaymentDate} from 'src/features/freelancer-payments/types.ts'
import {ArrowUpIcon} from 'src/assets/icons/ArrowUpIcon.tsx'
import {ArrowDownIcon} from 'src/assets/icons/ArrowDownIcon.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {useState} from 'react'
import {formatTransactionAmount} from 'src/features/freelancer-payments/utils.ts'
import {PaymentsAccordionBody} from 'src/features/freelancer-payments/components/payments-accordion-body/PaymentsAccordionBody.tsx'

type PaymentsAccordionProps =
    | {
          isLoading?: false
          paymentDate: FreelancerPaymentDate['data'][number]
      }
    | {
          isLoading: true
          paymentDate?: undefined
      }

export const PaymentsAccordion = ({isLoading, paymentDate}: PaymentsAccordionProps) => {
    const {
        t,
        i18n: {language}
    } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <StyledAccordionWrapper>
            <StyledAccordionHead>
                <hgroup>
                    <h3>
                        {isLoading ? (
                            <Skeleton height={20} width={160} />
                        ) : (
                            formatDateWithLocaleIntl(language, dayjs(paymentDate.payment_date).toDate())
                        )}
                    </h3>
                    <h5>
                        {isLoading ? (
                            <Skeleton height={16} width={120} />
                        ) : (
                            t('transactions:transactions', {count: paymentDate.payment_count})
                        )}
                    </h5>
                </hgroup>
                {isLoading ? (
                    <Skeleton height={24} width={200} />
                ) : (
                    <Button size="sm" variant="tertiary" onClick={() => setIsOpen(!isOpen)}>
                        {formatTransactionAmount({
                            amount: paymentDate.paid_out_photographer_currency_amount,
                            amountUSD: paymentDate.paid_out,
                            currency: paymentDate.paid_out_photographer_currency
                        })}
                        {isOpen ? <ArrowUpIcon size={20} /> : <ArrowDownIcon size={20} />}
                    </Button>
                )}
            </StyledAccordionHead>
            {isOpen && <PaymentsAccordionBody paymentDate={paymentDate?.payment_date} />}
        </StyledAccordionWrapper>
    )
}
