import styled, {css} from 'styled-components'

export const StyledSimpleLink = styled.div(
    ({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm};
        display: flex;
        align-items: center;
        font-weight: 600;
        justify-content: space-between;
        & a {
            margin-left: ${spacing * 2}px;
            color: ${palette.neutral.black};
        }
    `
)
