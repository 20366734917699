import {AirbnbIcon, CalendarIcon, StatusIcon, UserIcon} from '../../../../../theme/icons'
import {useTranslation} from 'react-i18next'
import {
    errorHandler,
    formatLocaleDate,
    openLinkInNewTab,
    renderTableMatterportStatus
} from '../../../../../helpers/utils'
import {Table} from '../../../../../components/table-legacy/Table'
import {useCallback, useEffect, useState} from 'react'
import {MATTERPORT_STATUSES, RESPONSE_TYPES} from '../../../../../helpers/constants'
import {debounce} from 'lodash-es'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from 'styled-components'
import {Chip} from '../../../../../components/chip/Chip'
import {httpGetMatterportTable} from '../../../../../http-requests/admin/matterport.http'
import {changeSingleTableCounter, usersAutocompleteSelector} from '../../../../../store/matterport'
import {TableControllers} from '../../../../../components/table-controllers/TableControllers'
import {useUserStore} from '../../../../../features/user/store'

export const CompletedTable = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const usersAutocomplete = useSelector(usersAutocompleteSelector)
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [sorter, setSorter] = useState({
        order_by: 'completed_at',
        order_direction: 'desc'
    })
    const [search, setSearch] = useState(null)
    const [selectReviewerSearch, setSelectReviewerSearch] = useState(null)
    const [selectUploaderSearch, setSelectUploaderSearch] = useState(null)
    const [selectPublisherSearch, setSelectPublisherSearch] = useState(null)
    const [tableIsLoading, setIsTableLoading] = useState(false)

    const tableColumns = [
        {
            name: 'external_id',
            label: t('commons:airbnb_id'),
            icon: <AirbnbIcon width={16} />,
            width: '10%',
            sortable: true
        },
        {
            name: 'reviewer',
            label: t('commons:reviewer'),
            icon: <UserIcon width={16} />,
            width: '20%',
            sortable: false
        },
        {
            name: 'uploader',
            label: t('commons:uploader'),
            icon: <UserIcon width={16} />,
            width: '20%',
            sortable: false
        },
        {
            name: 'publisher',
            label: t('commons:publisher'),
            icon: <UserIcon width={16} />,
            width: '20%',
            sortable: false
        },
        {
            name: 'status',
            label: t('commons:status'),
            icon: <StatusIcon width={16} />,
            width: '15%',
            sortable: false
        },
        {
            name: 'completed_at',
            label: t('commons:completed_on'),
            icon: <CalendarIcon width={16} />,
            width: '15%',
            sortable: true
        }
    ]

    useEffect(() => {
        if (page <= lastPage) getTableData()
    }, [page, sorter, search, selectReviewerSearch, selectUploaderSearch, selectPublisherSearch, adminAccessToken])

    useEffect(() => {
        setPage(1)
    }, [sorter, search, selectReviewerSearch, selectUploaderSearch, selectPublisherSearch])

    const getTableData = async () => {
        try {
            setIsTableLoading(true)
            const {data} = await httpGetMatterportTable(adminAccessToken, {
                status: MATTERPORT_STATUSES.COMPLETED,
                limit: 30,
                page,
                ...(!!search && {search_by: 'code_external_id'}),
                search,
                reviewer_admin_id: selectReviewerSearch,
                uploader_admin_id: selectUploaderSearch,
                publisher_admin_id: selectPublisherSearch,
                response_type: RESPONSE_TYPES.EXTENDED,
                ...sorter
            })
            setTableData(page === 1 ? data.data : [...tableData, ...data.data])
            setLastPage(data?.last_page)
            dispatch(
                changeSingleTableCounter({
                    status: MATTERPORT_STATUSES.COMPLETED,
                    count: data?.total
                })
            )
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsTableLoading(false)
        }
    }

    const onSetPage = useCallback(() => {
        setPage(page + 1)
    }, [page])

    const onSortColumn = useCallback((columnName, orderDirection) => {
        setSorter({order_by: columnName, order_direction: orderDirection})
    }, [])

    const onSubmit = debounce(e => {
        const value = e?.target?.value

        if (value?.length) {
            setSearch(value)
        } else {
            setSearch(null)
        }
    }, 750)

    const onSelectFilterSearch = (selectedOption, type) => {
        type === 'reviewer'
            ? setSelectReviewerSearch(selectedOption?.value)
            : type === 'publisher'
            ? setSelectPublisherSearch(selectedOption?.value)
            : setSelectUploaderSearch(selectedOption?.value)
    }

    return (
        <div>
            <Table
                data={tableData}
                isPaginated
                isLoading={tableIsLoading && tableData.length === 0}
                isFetchingNextPage={tableIsLoading && tableData.length >= 1}
                onChangePageCb={onSetPage}
                page={page}
                sorter={sorter}
                sortColumnCb={onSortColumn}
                columns={tableColumns}
                headerComponent={
                    <TableControllers
                        showInputSearch
                        onSearchCb={onSubmit}
                        searchInputPlaceholder={t('commons:search_airbnbid_mbid')}
                        selectFilter1={{
                            options: usersAutocomplete,
                            placeholder: t('admin:search_by_uploader'),
                            onChange: selectedOption => onSelectFilterSearch(selectedOption, 'uploader')
                        }}
                        selectFilter2={{
                            options: usersAutocomplete,
                            placeholder: t('admin:search_by_reviewer'),
                            onChange: selectedOption => onSelectFilterSearch(selectedOption, 'reviewer')
                        }}
                        selectFilter3={{
                            options: usersAutocomplete,
                            placeholder: t('admin:search_by_publisher'),
                            onChange: selectedOption => onSelectFilterSearch(selectedOption, 'publisher')
                        }}
                    />
                }
                tbodyHeight={`calc(${window.innerHeight}px - 451px)`}
                columnsRenderers={{
                    external_id: items => (
                        <span
                            style={{
                                color: theme.colors.red,
                                fontWeight: 700,
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                            onClick={e => {
                                e.stopPropagation()
                                openLinkInNewTab(items.airbnb_link)
                            }}
                        >
                            {'#'}
                            {items?.entity?.external_id}
                        </span>
                    ),
                    reviewer: items =>
                        usersAutocomplete?.find(user => user?.value === items.reviewer_admin_id)?.label || '-',
                    uploader: items =>
                        usersAutocomplete?.find(user => user?.value === items.uploader_admin_id)?.label || '-',
                    publisher: items =>
                        usersAutocomplete?.find(user => user?.value === items.publisher_admin_id)?.label || '-',
                    status: items => {
                        const {color, label} = renderTableMatterportStatus(items.status)

                        return (
                            <Chip
                                text={t(label)}
                                rounded
                                borderColor={color}
                                background={color}
                                color="white"
                                size="small"
                            />
                        )
                    },
                    completed_at: items => <span>{formatLocaleDate(items.completed_at)}</span>
                }}
            />
        </div>
    )
}
