import {useTranslation} from 'react-i18next'
import {Button} from '../../../../../../components/button/Button'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTheme} from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'

import {SelectCard} from './select-card/SelectCard'
import {AlertIcon} from '../../../../../../assets/icons/AlertIcon'
import {SendToHostModal} from './send-to-host-modal/SendToHostModal'
import {SendBackModal} from './send-back-modal/SendBackModal'
import {imagesListSelector, setAmenitiesAutocomplete} from '../../../../../../store/matterport'
import {errorHandler} from '../../../../../../helpers/utils'
import {httpGetAmenitiesAutocomplete} from '../../../../../../http-requests/admin/matterport.http'
import {ImagesListView} from '../../../uploader/images-list-view/ImagesListView'
import {DropzoneView} from '../../../uploader/dropzone-view/DropzoneView'
import {Accordion} from '../../../../../../components/accordion/Accordion'
import {
    StyledButtons,
    StyledError,
    StyledImageList,
    StyledInfo,
    StyledList,
    StyledSubSection,
    StyledTitle
} from './style'
import {useUserStore} from '../../../../../../features/user/store'

export const ImagesList = ({setFullScreenImageCb, rooms}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const theme = useTheme()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const imagesList = useSelector(imagesListSelector)
    const [hasSubmitError, setHasSubmitError] = useState(false)
    const [showSendToHostModal, setShowSendToHostModal] = useState(false)
    const [showSendBackModal, setShowSendBackModal] = useState(false)
    const [imagesFromDropzone, setImagesFromDropzone] = useState({
        accepted: [],
        rejected: []
    })
    const showConfirmView = !!imagesFromDropzone.accepted.length
    const hasEmptySelects = useMemo(
        () =>
            imagesList.some(
                image => !image.doNotUse && !image.isOld && (!image.selectedRoom || !image.selectedAmenity)
            ),
        [imagesList]
    )
    const oldImages = imagesList.filter(image => image.isOld)
    const newImages = imagesList.filter(image => !image.isOld)
    const toUploadImages = imagesFromDropzone.accepted.map(image =>
        Object.assign(image, {
            preview: URL.createObjectURL(image)
        })
    )

    useEffect(() => {
        getAmenitiesAutocomplete()
    }, [])

    useEffect(() => {
        if (hasSubmitError && !hasEmptySelects) setHasSubmitError(false)
    }, [imagesList])

    const setImagesFromDropzoneCb = useCallback(images => {
        setImagesFromDropzone(images)
    }, [])

    const getAmenitiesAutocomplete = async () => {
        try {
            const {data} = await httpGetAmenitiesAutocomplete(adminAccessToken)
            dispatch(setAmenitiesAutocomplete(data))
        } catch (e) {
            errorHandler(e)
        }
    }

    const setShowSendToHostModalCb = useCallback(
        show => {
            show && hasEmptySelects ? setHasSubmitError(true) : setShowSendToHostModal(show)
        },
        [hasEmptySelects]
    )

    const setShowSendBackModalCb = useCallback(show => {
        setShowSendBackModal(show)
    }, [])

    return (
        <StyledImageList>
            {showSendToHostModal && <SendToHostModal setShowSendToHostModalCb={setShowSendToHostModalCb} />}
            {showSendBackModal && <SendBackModal setShowSendBackModalCb={setShowSendBackModalCb} />}

            <StyledTitle>
                <h2>{t('admin:qa_images')}</h2>
                <StyledButtons>
                    <Button onClick={() => setShowSendBackModalCb(true)} variant="secondary">
                        {t('admin:sendback_to_matterport')}
                    </Button>
                    <Button onClick={() => setShowSendToHostModalCb(true)} variant="primaryBlack">
                        {t('admin:send_to_host')}
                    </Button>
                </StyledButtons>
            </StyledTitle>
            {hasSubmitError && (
                <StyledError>
                    <AlertIcon />
                    <span>{t('errors:room_amenity_required')}</span>
                </StyledError>
            )}
            <StyledInfo>{t('admin:qa_images_info')}</StyledInfo>

            <StyledList>
                {newImages.map((image, index) => (
                    <SelectCard
                        hasSubmitError={hasSubmitError}
                        image={image}
                        key={index}
                        setFullScreenImageCb={setFullScreenImageCb}
                        rooms={rooms}
                    />
                ))}
            </StyledList>
            {!!oldImages.length && (
                <StyledSubSection>
                    <h3>{t('admin:host_uploaded_images')}</h3>
                    <StyledList>
                        {oldImages.map((image, index) => (
                            <SelectCard
                                hasSubmitError={hasSubmitError}
                                image={image}
                                key={index}
                                setFullScreenImageCb={setFullScreenImageCb}
                                rooms={rooms}
                            />
                        ))}
                    </StyledList>
                </StyledSubSection>
            )}
            <Accordion
                title={t('admin:add_other_images')}
                animated={false}
                style={{marginTop: `${theme.spacing * 16}px`}}
                bodyClassName="accordionBody"
            >
                {showConfirmView ? (
                    <ImagesListView
                        sectionType="to_upload"
                        imagesList={toUploadImages}
                        imagesFromDropzone={imagesFromDropzone}
                        setImagesFromDropzoneCb={setImagesFromDropzoneCb}
                        setFullScreenImageCb={setFullScreenImageCb}
                    />
                ) : (
                    <DropzoneView setImagesFromDropzoneCb={setImagesFromDropzoneCb} />
                )}
            </Accordion>
        </StyledImageList>
    )
}
