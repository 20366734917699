import {Button} from '../button/Button'
import {SelectUncontrolled} from '../select/SelectUncontrolled'
import {InputText} from '../input-text/InputText'
import {Checkbox} from '../checkbox-legacy/Checkbox'
import {StyledTableControllerTop, StyledFilters} from './style'
import {SearchLgIcon} from '../icon'
import {useTheme} from 'styled-components'

export const TableControllers = ({
    hasTopCta = false,
    cta = {},
    showInputSearch = true,
    onSearchCb,
    searchError,
    searchInputPlaceholder,
    selectFilter1,
    selectFilter2,
    selectFilter3,
    showCheckbox = false,
    checkboxLabel,
    isCheckboxChecked,
    onCheckboxChange,
    renderMobileTable = false
}) => {
    const theme = useTheme()

    return (
        <StyledTableControllerTop>
            {hasTopCta && (
                <Button className={cta.className} onClick={cta.onClick} variant="primaryBlack">
                    {cta.text}
                </Button>
            )}
            <StyledFilters hasTopCta={hasTopCta}>
                {selectFilter3 && (
                    <SelectUncontrolled
                        options={selectFilter3.options}
                        placeholder={selectFilter3.placeholder}
                        controlCustomStyle={{width: 240, minHeight: 40, backgroundColor: 'transparent'}}
                        onChangeCallback={selectFilter3.onChange}
                        isClearable
                    />
                )}
                {selectFilter2 && (
                    <SelectUncontrolled
                        options={selectFilter2.options}
                        placeholder={selectFilter2.placeholder}
                        controlCustomStyle={{width: 240, minHeight: 40, backgroundColor: 'transparent'}}
                        onChangeCallback={selectFilter2.onChange}
                        isClearable
                    />
                )}
                {selectFilter1 && (
                    <SelectUncontrolled
                        options={selectFilter1.options}
                        placeholder={selectFilter1.placeholder}
                        controlCustomStyle={{width: 240, minHeight: 40, backgroundColor: 'transparent'}}
                        onChangeCallback={selectFilter1.onChange}
                        isClearable
                    />
                )}
                {showInputSearch && (
                    <InputText
                        type="text"
                        onChange={onSearchCb}
                        inputSize="sm"
                        placeholder={searchInputPlaceholder}
                        errorMessage={searchError}
                        typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['400']} />}
                        width={renderMobileTable ? '100%' : '240px'}
                    />
                )}
                {showCheckbox && (
                    <Checkbox
                        label={checkboxLabel}
                        checked={isCheckboxChecked}
                        labelPosition="left"
                        onChange={onCheckboxChange}
                    />
                )}
            </StyledFilters>
        </StyledTableControllerTop>
    )
}
