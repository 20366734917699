import {PullShoots} from '../custom-components/pull-shoots/PullShoots'
import {CancelShoot} from '../custom-components/CancelShoot'
import {SendReminder} from '../custom-components/SendReminder'
import {GetInfo} from '../custom-components/GetInfo'
import {CHATBOT_SOURCES, TASK_STATUSES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import i18next from 'i18next'
import {MultiParagraphMessage} from '../custom-components/multi-paragraph-message/MultiParagraphMessage'
import {Trans} from 'react-i18next'
import {SimpleLink} from '../custom-components/simple-link/SimpleLink'

export const mb13FlowSteps = (isInShootPage, shoot) => [
    {
        id: 'MB1.3',
        message: i18next.t('chatbot:commons:which_shoot'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Which shoot are you referring to?'
                })
            )
            return 'MB1.3_pull'
        }
    },
    {
        id: 'MB1.3_pull',
        component: <PullShoots flowSource="MB1.3_pull" statuses={[TASK_STATUSES.ACCEPTED]} filterByDate="0" />
    },
    {
        id: 'MB1.3.1',
        message: i18next.t('chatbot:mb13:mb1.3.1'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.1',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Have you ever emailed the Host?'
                })
            )
            return 'MB1.3.2'
        }
    },
    {
        id: 'MB1.3.2',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    const shootPhone = store.getState().chatbot.shoot?.host?.phone

                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.2',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )

                    return shootPhone ? 'MB1.3.2_yes' : 'MB1.3.3_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.2',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB1.3.2_no'
                }
            }
        ]
    },
    {
        id: 'MB1.3.2_no',
        component: <GetInfo flowSource="MB1.3_email" />,
        asMessage: true,
        trigger: 'send_log'
    },

    {
        id: 'MB1.3.2_yes',
        message: i18next.t('chatbot:mb13:mb1.3.2_yes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.2_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Did you try to call the Host?'
                })
            )
            return 'MB1.3.3'
        }
    },
    {
        id: 'MB1.3.3',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.3',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB1.3.3_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.3',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB1.3.3_no'
                }
            }
        ]
    },
    {
        id: 'MB1.3.3_no',
        component: <GetInfo flowSource="MB1.3.3_no" />,
        asMessage: true,
        trigger: 'send_log'
    },

    {
        id: 'MB1.3.3_yes',
        message: i18next.t('chatbot:mb13:mb1.3.3_yes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.3_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Was your last attempt to contact the Host more than 24 hours ago?'
                })
            )
            return 'MB1.3.4'
        }
    },
    {
        id: 'MB1.3.4',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.4',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB1.3.4_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.4',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB1.3.4_no'
                }
            }
        ]
    },
    {
        id: 'MB1.3.4_no',
        message: i18next.t('chatbot:mb13:mb1.3.4_no'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.4_no',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please allow a total of 10 days since your last attempt to contact the Host. If the Host will still be unresponsive, reach back to us again.'
                })
            )
            return 'send_log'
        }
    },

    {
        id: 'MB1.3.4_yes',
        component: (
            <MultiParagraphMessage
                paragraphs={[
                    <Trans i18nKey="chatbot:mb13:mb1.3.4_yes_1" components={[<strong />]} />,
                    <Trans i18nKey="chatbot:mb13:mb1.3.4_yes_2" components={[<strong />]} />,
                    i18next.t('chatbot:mb13:mb1.3.4_yes_3'),
                    i18next.t('chatbot:mb13:mb1.3.4_yes_4')
                ]}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.4_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text:
                        'We are sorry the Host is unresponsive. Please go to the scheduling section and select the option " Cannot get in touch with the Host". ' +
                        'After you have selected this option, the photoshoot will be displayed in the "Require my Attention" section of your Mediabox under the tab "On Hold". ' +
                        'We will try to get in touch with the Host and have them share their desired shoot dates. If no action is taken by the Host in the following 15 days, we will automatically cancel the shoot. ' +
                        'You can review this guide for more information and details on the coordination system:'
                })
            )
            return 'MB1.3.4_yes_link'
        }
    },
    {
        id: 'MB1.3.4_yes_link',
        component: (
            <SimpleLink
                link="https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf"
                text={i18next.t('chatbot:commons:coordination_system_guide')}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.4_yes_link',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] Coordination system guide'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB1.3.5',
        options: [
            {
                value: 'send_reminder',
                label: i18next.t('chatbot:mb13:mb1.3.5'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.5',
                            source: CHATBOT_SOURCES.USER,
                            text: '📩 airbnb to send a reminder to the Host'
                        })
                    )
                    return 'MB1.3.5_reminder'
                }
            },
            {
                value: 'cancel_shoot',
                label: i18next.t('chatbot:mb13:mb1.3.5_cancel'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.5',
                            source: CHATBOT_SOURCES.USER,
                            text: '🚫 Cancel the shoot'
                        })
                    )
                    return 'MB1.3.5_confirm'
                }
            }
        ]
    },
    {
        id: 'MB1.3.5_reminder',
        component: <SendReminder flowSource="MB1.3_pull" shoot={shoot} />,
        asMessage: true,
        trigger: 'send_log'
    },
    {
        id: 'MB1.3.5_cancel',
        component: <CancelShoot flowSource="MB1.3_pull" />,
        asMessage: true,
        replace: true,
        trigger: 'MB1.3.5_cancel_sent'
    },
    {
        id: 'MB1.3.5_cancel_sent',
        message: i18next.t('chatbot:mb13:mb1.3.5_cancel_sent'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.5_cancel_sent',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thanks! This shoot is now canceled'
                })
            )
            return 'send_log'
        }
    },

    // Final steps for cancellation
    {
        id: 'MB1.3.5_confirm',
        message: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason
            return i18next.t('chatbot:commons:confirm_reason', {
                cancellationReason: cancellationReason.text
            })
        },
        trigger: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.3.5_confirm',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `Please confirm you want to cancel this shoot because of "${cancellationReason.text}"`
                })
            )
            return 'MB1.3.5_confirm_options'
        }
    },
    {
        id: 'MB1.3.5_confirm_options',
        options: [
            {
                value: 'confirm',
                label: i18next.t('chatbot:commons:confirm_cancellation'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.5_confirm_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Confirm cancellation'
                        })
                    )
                    return 'MB1.3.5_cancel'
                }
            },
            {
                value: 'abandon',
                label: i18next.t('chatbot:commons:changed_mind'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.3.5_confirm_options',
                            source: CHATBOT_SOURCES.USER,
                            text: "I've changed my mind"
                        })
                    )
                    return isInShootPage ? '3' : 'start_generic'
                }
            }
        ]
    }
]
