import {useTheme} from 'styled-components'
import {HTMLAttributes, ReactElement, ReactNode, useEffect, useRef, useState} from 'react'
import {ArrowDownIcon} from '../../theme/icons'
import {Button, ButtonProps} from 'src/components/button/Button'
import {Root, Head, Title, Body, Actions, HeadButtons} from './style'

interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    className?: string
    customBodyHeight?: number
    headButtons?: Array<{
        disabled?: boolean
        onClick: () => void
        text: ReactNode
        variant: ButtonProps['variant']
    }>
    headClassName?: string
    bodyClassName: string
    animated: boolean
    defaultOpen?: boolean
    title: string
    icon?: ReactElement
}

export const Accordion = ({
    children,
    className,
    headClassName,
    bodyClassName,
    customBodyHeight,
    animated = true,
    defaultOpen = false,
    title,
    icon,
    headButtons = [],
    ...rest
}: AccordionProps) => {
    const theme = useTheme()
    const [open, setOpen] = useState(defaultOpen)
    const bodyRef = useRef<HTMLDivElement | null>(null)
    const [bodyHeight, setBodyHeight] = useState<number>()

    useEffect(() => {
        if (bodyRef.current) {
            setBodyHeight(bodyRef.current.scrollHeight)
        }
    }, [])

    return (
        <Root className={className} {...rest}>
            <Head
                $hasChildren={!!children}
                className={headClassName}
                onClick={() => {
                    setOpen(prevState => !prevState)
                }}
            >
                <Title>
                    {icon}
                    <h5>{title}</h5>
                </Title>
                <Actions>
                    {!!headButtons.length && (
                        <HeadButtons>
                            {headButtons.map((button, index) => (
                                <Button
                                    variant={button.variant}
                                    onClick={e => {
                                        e.stopPropagation()
                                        button.onClick()
                                    }}
                                    disabled={button.disabled}
                                    key={index}
                                >
                                    {button.text}
                                </Button>
                            ))}
                        </HeadButtons>
                    )}
                    {!!children && (
                        <ArrowDownIcon
                            width={16}
                            fill={theme.palette.neutral.black}
                            style={{
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                ...(animated && {
                                    transition: 'ease-in-out 400ms' /*same of classes.body transition*/
                                })
                            }}
                        />
                    )}
                </Actions>
            </Head>
            <Body
                $animated={animated}
                $open={open}
                $customBodyHeight={customBodyHeight}
                $bodyHeight={bodyHeight}
                className={bodyClassName}
                ref={bodyRef}
            >
                {!!children && <div>{children}</div>}
            </Body>
        </Root>
    )
}
