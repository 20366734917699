import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 3}px;
        justify-content: center;
        align-items: center;
    `
)

export const StyledScore = styled.div(
    ({theme: {typography, colors, transition}, selectedRating, valueColor}) => css`
        text-align: center;
        cursor: ${selectedRating ? 'not-allowed' : 'pointer'};
        & svg {
            fill: ${selectedRating ? valueColor : colors.grey};
            ${transition};
            &:hover {
                fill: ${valueColor};
            }
        }
        & small {
            display: block;
            ${typography.textXs};
            font-weight: 500;
            color: ${valueColor};
        }
    `
)
