import {FC, HTMLAttributes} from 'react'

export const MenuIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 24, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="currentColor" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 5C1.5 4.44772 1.94772 4 2.5 4H17.5C18.0523 4 18.5 4.44772 18.5 5C18.5 5.55228 18.0523 6 17.5 6H2.5C1.94772 6 1.5 5.55228 1.5 5ZM1.5 10C1.5 9.44772 1.94772 9 2.5 9H17.5C18.0523 9 18.5 9.44772 18.5 10C18.5 10.5523 18.0523 11 17.5 11H2.5C1.94772 11 1.5 10.5523 1.5 10ZM2.5 14C1.94772 14 1.5 14.4477 1.5 15C1.5 15.5523 1.94772 16 2.5 16H17.5C18.0523 16 18.5 15.5523 18.5 15C18.5 14.4477 18.0523 14 17.5 14H2.5Z"
            fill="currentColor"
        />
    </svg>
)
