import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {Tooltip} from '../../../../../../components/tooltip-legacy/Tooltip'
import {QuestionIcon} from '../../../../../../assets/icons/QuestionIcon'
import {CloseIcon} from '../../../../../../assets/icons/CloseIcon'
import {CheckIcon} from '../../../../../../assets/icons/CheckIcon'

import {colors} from '../../../../../../theme/colors'

import {StyledQuestionIconWrapper, StyledTooltipContent, StyledTooltipContentItem, StyledTooltipWrapper} from './style'

export const AssignmentLogicTooltip = ({assignment}) => {
    const {t} = useTranslation()
    return (
        <StyledTooltipWrapper>
            {assignment?.assignment_logic}
            <Tooltip
                content={
                    <StyledTooltipContent>
                        <StyledTooltipContentItem>
                            {assignment?.ignored_markets ? <CheckIcon size={16} /> : <CloseIcon size={16} />}
                            {t('admin:assignments:production:ignored_markets')}
                        </StyledTooltipContentItem>
                        <StyledTooltipContentItem>
                            {assignment?.ignored_previous_rejections ? (
                                <CheckIcon size={16} />
                            ) : (
                                <CloseIcon size={16} />
                            )}

                            {t('admin:assignments:production:ignored_previous_rejections')}
                        </StyledTooltipContentItem>
                        <StyledTooltipContentItem>
                            {assignment?.manual_rate ? <CheckIcon size={16} /> : <CloseIcon size={16} />}

                            {t('admin:assignments:production:manual_rate')}
                        </StyledTooltipContentItem>
                    </StyledTooltipContent>
                }
            >
                <StyledQuestionIconWrapper>
                    <QuestionIcon size={16} fill={colors.darkGrey} />
                </StyledQuestionIconWrapper>
            </Tooltip>
        </StyledTooltipWrapper>
    )
}

AssignmentLogicTooltip.propTypes = {
    assignment: PropTypes.shape({
        assignment_logic: PropTypes.string,
        ignored_markets: PropTypes.number,
        ignored_previous_rejections: PropTypes.number,
        manual_rate: PropTypes.number
    })
}
