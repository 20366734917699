import PropTypes from 'prop-types'
import {FC, HTMLAttributes} from 'react'

export const ErrorIcon: FC<{size?: number; fill?: string} & HTMLAttributes<SVGSVGElement>> = ({
    size = 66,
    fill = 'currentColor',
    ...rest
}) => (
    <svg width={size} height={size} viewBox="0 0 66 66" fill="none" {...rest}>
        <path
            d="M33 60C47.9117 60 60 47.9117 60 33C60 18.0883 47.9117 6 33 6C18.0883 6 6 18.0883 6 33C6 47.9117 18.0883 60 33 60Z"
            fill={fill}
            fillOpacity="0.2"
        />
        <path
            d="M33 0C51.2254 0 66 14.7746 66 33C66 51.2254 51.2254 66 33 66C14.7746 66 0 51.2254 0 33C0 14.7746 14.7746 0 33 0ZM33 3C16.4315 3 3 16.4315 3 33C3 49.5685 16.4315 63 33 63C49.5685 63 63 49.5685 63 33C63 16.4315 49.5685 3 33 3ZM21.3327 19.2114L33.03 30.909L44.6985 19.2426L46.8198 21.364L35.1525 33.0315L46.7886 44.6673L44.6673 46.7886L33.0315 35.1525L21.364 46.8198L19.2426 44.6985L30.909 33.03L19.2114 21.3327L21.3327 19.2114Z"
            fill={fill}
        />
    </svg>
)

ErrorIcon.propTypes = {
    size: PropTypes.number,
    fill: PropTypes.string
}
