import {useUserStore} from 'src/features/user/store.ts'
import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {httpGetAdmins} from 'src/features/admin/services/admin.http.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {Admin} from 'src/features/admin/types.ts'

export const useAdmins = () => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useQuery({
        queryKey: [QUERY_KEYS.ADMINS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAdmins({headers: makeHttpAuthorizationHeader(adminAccessToken)}),
                onZodError: captureException,
                responseShape: z.array(Admin)
            })
    })
}
