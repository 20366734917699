import {forwardRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Label, StyledInput, StyledSlider, StyledSwitch} from './style'
import {DefaultTheme} from 'styled-components'

interface ToggleSwitchProps {
    className: string
    label: string
    isChecked: boolean
    isDefaultChecked: boolean
    small: boolean
    color: keyof DefaultTheme['colors']
}

export const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
    ({className, label, isChecked, isDefaultChecked, small = false, color = 'airbnbDarkPink', ...rest}, ref) => {
        const {t} = useTranslation()

        return (
            <StyledSwitch className={className}>
                <Label>{t(label)}</Label>
                <StyledInput
                    type="checkbox"
                    checked={isChecked}
                    defaultChecked={isDefaultChecked}
                    ref={ref}
                    $textColor={color}
                    $small={small}
                    {...rest}
                />
                <StyledSlider $small={small} id="slider" />
            </StyledSwitch>
        )
    }
)
ToggleSwitch.displayName = 'ToggleSwitch'
