import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './index.css'
import './translations/i18n'
import {App} from './App'
import reportWebVitals from './reportWebVitals'
import {appGenericsReducer} from './store/appGenerics'
import {matterportReducer} from './store/matterport'
import {chatbotReducer} from './store/chatbot'
import {configureStore} from '@reduxjs/toolkit'
import {Provider} from 'react-redux'
import axios from 'axios'
import {BrowserRouter as Router} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import {ErrorBoundary} from './ErrorBoundary'
import {ErrorFallback} from './components/error-fallback/ErrorFallback'
import {ThemeProvider} from 'styled-components'
import {GlobalStyles, getAppTheme} from './theme/theme'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {TooltipProvider} from '@radix-ui/react-tooltip'
import {queryClient} from './queryClient'
/*import applyAppTokenRefreshInterceptor from './helpers/refreshToken';*/

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT
//applyAppTokenRefreshInterceptor(axios);

//store and reducers configs
const reducer = {
    appGenerics: appGenericsReducer,
    matterport: matterportReducer,
    chatbot: chatbotReducer
}
export const store = configureStore({reducer})
const history = createBrowserHistory({basename: '/'})
const isInProd = window.location.host !== 'localhost:3000'

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <ThemeProvider theme={getAppTheme({name: 'light'})}>
                <ErrorBoundary fallback={<ErrorFallback />}>
                    <Provider store={store}>
                        <TooltipProvider>
                            <Router history={history} basename="/">
                                <App />
                                {!isInProd && <ReactQueryDevtools initialIsOpen={false} />}
                            </Router>
                        </TooltipProvider>
                    </Provider>
                </ErrorBoundary>
            </ThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
