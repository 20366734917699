import PropTypes from 'prop-types'
import {useWindowSize} from '@react-hook/window-size'

import {StatusCell} from '../../../../../../../components/table-legacy/desktop/cell/status/Status'
import {AirbnbCell} from '../../../../../../../components/table-legacy/desktop/cell/airbnb/AirbnbCell'
import {ActionsCell} from '../../../../../../../components/table-legacy/desktop/cell/actions-cell/ActionsCell'
import {Table} from '../../../../../../../components/table-legacy/Table'
import {OpenButton} from '../../../../../../../components/table-legacy/desktop/button/open/OpenButton'

import {screenWidth} from '../../../../../../../theme/breakpoints'
import {formatLocaleDate, openShootInNewTab} from '../../../../../../../helpers/utils'
import {getActiveShootsColumns} from '../../../../../../../helpers/columns'
import {useActiveShootsTable} from '../../../../../../../hooks/useActiveShootsTable'

import {ShootDateWrapper} from './style'

export const TableView = ({search}) => {
    const [pageWidth, pageHeight] = useWindowSize()
    const isLG = pageWidth < screenWidth.LG

    const {
        data: tableData,
        isError,
        page,
        onChangePage,
        sorter,
        onSortColumn,
        ...query
    } = useActiveShootsTable({search})

    return (
        <Table
            data={tableData}
            isPaginated
            isMobileVersion={isLG}
            isError={isError}
            isLoading={query.isLoading}
            isRefetching={query.isRefetching}
            isFetchingNextPage={query.isFetchingNextPage}
            onChangePageCb={onChangePage}
            page={page}
            sorter={sorter}
            sortColumnCb={onSortColumn}
            columns={getActiveShootsColumns()}
            tbodyHeight={pageWidth < screenWidth.LG ? `calc(${pageHeight}px - 160px)` : `calc(${pageHeight}px - 416px)`}
            columnsRenderers={{
                external_id: item => <AirbnbCell value={item.entity?.external_id} item={item} />,
                location: item => <span>{item.entity?.city ?? '-'}</span>,
                host: item => <span>{item.host?.first_name ?? '-'}</span>,
                status_name: item => <StatusCell item={item} />,
                to_shoot_at: item => (
                    <ShootDateWrapper>{formatLocaleDate(item.to_shoot_at, 'MMM D, YYYY')}</ShootDateWrapper>
                ),
                actions: item => (
                    <ActionsCell>
                        <OpenButton showText={isLG} onClick={() => openShootInNewTab(item.code)} />
                    </ActionsCell>
                )
            }}
        />
    )
}

TableView.propTypes = {
    search: PropTypes.string
}
