import {StyledPhotosDropzone} from './style'
import {Image01Icon} from '../../../../theme/icons'
import {Trans, useTranslation} from 'react-i18next'

export const PhotosDropzone = ({dropzone, isDisabled}) => {
    const {t} = useTranslation()

    return (
        <StyledPhotosDropzone
            {...dropzone.getRootProps()}
            isDragAccept={dropzone.isDragAccept}
            isDragReject={dropzone.isDragReject}
            isDisabled={isDisabled}
        >
            <input {...dropzone.getInputProps()} />
            <Image01Icon />
            <p>{t('single_shooting:drop_assets')}</p>
            <p>
                <Trans i18nKey="single_shooting:or_upload" components={{span: <span />}} />
            </p>
        </StyledPhotosDropzone>
    )
}
