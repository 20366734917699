import styled, {css} from 'styled-components'
import {DateTimeInput} from '../../../../components/date-time-input/DateTimeInput'

export const SubtitleWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledSubtitle = styled.h6`
    ${({theme: {colors, typography}}) => css`
        color: ${colors.lightBlack};
        margin: 0;
        ${typography.textXl};
    `}
`

export const SubheaderWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 12}px;
    `}
`

export const StyledTitle = styled.h4`
    ${({theme: {spacing}}) => css`
        font-weight: 600;
        margin-bottom: ${spacing * 14}px;
    `}
`

export const StyledParagraph = styled.p`
    ${({theme: {colors, spacing}}) => css`
        color: ${colors.darkGrey};
        margin: 0;
        margin-bottom: ${spacing * 2}px;
    `}
`

export const UploadWrapper = styled.div`
    ${({theme: {spacing, palette, headerHeight, mediaQuery}}) => css`
        margin-top: ${headerHeight}px;
        padding-top: ${spacing * 10}px;
        background: ${palette.neutral['50']};
        min-height: ${window.innerHeight - headerHeight}px;
        padding-bottom: ${spacing * 10}px;
        ${mediaQuery.SM} {
            padding: ${spacing * 4}px 0;
        }
        ${mediaQuery.LG} {
            gap: ${spacing * 9}px;
        }
    `}
`

export const PaymentFormWrapper = styled.form`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: start;
        width: 520px;
        gap: ${spacing * 4}px;
        margin: ${spacing * 8}px 0;
    `}
`
export const StyledCustomDateTimeInput = styled(DateTimeInput)`
    ${({theme: {colors, spacing, mediaQuery}}) => css`
        width: unset;
        & > div {
            max-width: 176px;
            padding: 0;
            border: 1px solid ${colors.inputGrey};
            height: 34px;
            border-radius: 6px;
            ${mediaQuery.SM} {
                max-width: 100%;
            }
        }
        & input {
            font-weight: 400;

            padding: 0 ${spacing * 2.5}px;
        }
    `}
`
