import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {CloseIcon, StatusIcon, AlertIcon} from '../../theme/icons'
import {ALERT_LEVELS} from '../../helpers/constants'
import {
    StyledAlertRoot,
    StyledAlertContainer,
    StyledAlertContent,
    StyledAlertMessage,
    StyledIcon,
    StyledCloseIcon
} from './style'

const getVariantStyle = variant => {
    switch (variant) {
        case ALERT_LEVELS.SUCCESS:
            return {
                icon: <StatusIcon width={16} height={16} />
            }
        case ALERT_LEVELS.ERROR:
            return {
                icon: <AlertIcon width={16} height={16} />
            }
        default:
            return {
                icon: <AlertIcon width={16} height={16} />
            }
    }
}

export const Alert = ({
    visible,
    message,
    actionCallback,
    dismissTimeout,
    canDismiss = true,
    dismissCallback,
    variant = ALERT_LEVELS.ERROR,
    withLeftIcon = false,
    closeIcon = <CloseIcon width={16} height={16} />,
    className
}) => {
    const variantStyle = getVariantStyle(variant)

    useEffect(() => {
        if (dismissTimeout && visible) {
            const timeoutId = setTimeout(() => {
                closeHandler()
            }, +dismissTimeout)
            return () => {
                clearTimeout(timeoutId)
            }
        }
    }, [visible])

    const closeHandler = () => {
        dismissCallback && dismissCallback()
    }

    return (
        <StyledAlertRoot $variant={variant} $visible={visible} className={className}>
            <StyledAlertContainer>
                {withLeftIcon && <StyledIcon>{variantStyle.icon}</StyledIcon>}
                <StyledAlertContent>
                    {message && <StyledAlertMessage>{message}</StyledAlertMessage>}
                    {actionCallback && <span />}
                </StyledAlertContent>
                {canDismiss && <StyledCloseIcon onClick={closeHandler}>{closeIcon}</StyledCloseIcon>}
            </StyledAlertContainer>
        </StyledAlertRoot>
    )
}

Alert.propTypes = {
    visible: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    actionCallback: PropTypes.func,
    dismissTimeout: PropTypes.number,
    canDismiss: PropTypes.bool,
    dismissCallback: PropTypes.func,
    variant: PropTypes.oneOf(Object.values(ALERT_LEVELS)),
    withLeftIcon: PropTypes.bool,
    closeIcon: PropTypes.element,
    className: PropTypes.string
}
