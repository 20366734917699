import styled, {css} from 'styled-components'

const Root = styled.div`
    ${({theme: {shadows}}) => css`
        width: 100%;
        box-shadow: ${shadows.grey};
    `}
`

const Head = styled.div<{$hasChildren: boolean}>`
    ${({theme: {spacing}, $hasChildren}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
        padding: ${spacing * 6}px;
        &:hover {
            ${$hasChildren &&
            css`
                cursor: pointer;
            `}
        }
    `}
`

const Title = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: ${spacing * 2}px;
        overflow: hidden;
        & h5 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        & > svg {
            width: ${spacing * 6}px;
        }
    `}
`

const Body = styled.div<{$animated: boolean; $open: boolean; $customBodyHeight?: number; $bodyHeight?: number}>`
    ${({$animated, $open, $customBodyHeight, $bodyHeight}) => css`
        display: ${$open ? 'block' : 'none'};
        max-height: ${$open ? ($animated ? `${$customBodyHeight || $bodyHeight}px` : '100%') : 0};
        overflow: ${$open ? 'unset' : 'hidden'};

        ${$animated &&
        css`
            transition: ease-in-out 400ms;
        `}

        & > div {
            max-height: ${$animated ? `${$customBodyHeight || $bodyHeight}px` : '100%'};
            overflow: auto;
        }
    `}
`

const Actions = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 8}px;
    `}
`

const HeadButtons = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 6}px;
    `}
`

export {Root, Head, Title, Body, Actions, HeadButtons}
