import PropTypes from 'prop-types'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {errorHandler} from '../../../../../../../helpers/utils'
import {useSelector} from 'react-redux'
import {Spinner} from '../../../../../../../components/spinner-legacy/Spinner'
import {httpSendTaskToHost} from '../../../../../../../http-requests/admin/matterport.http'
import {useNavigate, useParams} from 'react-router'
import {MATTERPORT_STATUSES, ROUTES} from '../../../../../../../helpers/constants'
import {imagesListSelector, isEligibleSelector} from '../../../../../../../store/matterport'
import {AdminPopover} from '../../../../../../../components/admin-popover/AdminPopover'
import {useUserStore} from '../../../../../../../features/user/store'

export const SendToHostModal = ({setShowSendToHostModalCb}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const imagesList = useSelector(imagesListSelector)
    const isEligible = useSelector(isEligibleSelector)
    const [isLoading, setIsLoading] = useState(false)

    const onClose = useCallback(() => setShowSendToHostModalCb(false), [])

    const onSubmit = async () => {
        const imagesToSend = imagesList.map(image => ({
            id: image.id,
            bedroom_id: image.selectedRoom,
            amenity_id: image.selectedAmenity,
            action: image.doNotUse ? 'to_remove' : 'to_accept'
        }))

        try {
            setIsLoading(true)
            await httpSendTaskToHost(adminAccessToken, taskId, imagesToSend, isEligible)
            navigate(ROUTES.ADMIN_MATTERPORT, {
                state: MATTERPORT_STATUSES.PENDING_APPROVAL
            })
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <AdminPopover
            onClose={onClose}
            title={t('admin:youre_send_set')}
            buttonLabel={t('commons:confirm')}
            callback={onSubmit}
        >
            <div className="position-relative">{isLoading && <Spinner overlayFullscreen />}</div>
        </AdminPopover>
    )
}

SendToHostModal.propTypes = {
    setShowSendToHostModalCb: PropTypes.func
}
