import styled, {css} from 'styled-components'

export const StyledHeadListItem = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        width: 100%;
        align-items: center;
        gap: ${spacing * 3}px;
    `}
`

export const StyledIconContainer = styled.div`
    ${({theme: {colors}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid ${colors.gainsboroGrey};
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: ${colors.airbnbDarkPink};
        span {
            flex: none;
        }
    `}
`

export const StyledInfoContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 0.5}px;
    `}
`

export const StyledName = styled.span`
    ${({theme: {typography, colors}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${colors.lightBlack};
    `}
`
export const StyledEmail = styled.span`
    ${({theme: {typography, colors}}) => css`
        ${typography.textSm};
        color: ${colors.darkGrey};
        font-weight: 400;
    `}
`
