import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAssignmentCardWrapper = styled(Flexbox)(
    ({theme: {spacing, palette, mediaQueries}}) => css`
        padding: ${spacing * 6}px;
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[300]};
        border-radius: 16px;
        width: 100%;

        ${mediaQueries.m} {
            border-radius: 0;
            max-width: unset;
        }
    `
)

export const StyledAssignmentCardTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textLg};
        font-weight: 600;
    `
)

export const StyledAssignmentCardSubtitle = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 400;
    `
)
