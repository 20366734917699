import {useEffect, useRef, useState} from 'react'
import ChatBot from 'react-simple-chatbot'
import {useTheme, ThemeProvider} from 'styled-components'
import {StyledArrowDownIcon, StyledFloating, StyledMessage, StyledWrapper} from './style'
import {mainSteps} from './steps'
import {ChatBotIcon, CloseIcon} from '../../theme/icons'
import {useDispatch, useSelector} from 'react-redux'
import {ChatBotHeader} from './ChatBotHeader'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {Spinner} from '../spinner-legacy/Spinner'
import {resetChatHistory, currentAssignmentSelector} from '../../store/chatbot'
import {chatBotTheme} from './chatbotTheme'
import {languageSelector} from '../../store/appGenerics'
import {useUserStore} from '../../features/user/store'

export const ChatBotWrapper = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {code} = useParams()
    const dispatch = useDispatch()
    const currentLanguage = useSelector(languageSelector)
    const currentUser = useUserStore(store => store.currentUser)
    const currentAssignment = useSelector(currentAssignmentSelector)
    const [botOpened, setBotOpened] = useState(false)
    const [helpTextVisible, setHelpTextVisible] = useState(true)
    const [botSteps, setBotSteps] = useState(null)
    const [resetChatbot, setResetChatbot] = useState(false)
    const currentPage = code === undefined ? 'homepage' : code
    const isInShootPage = code !== undefined
    const assignmentStatus = currentAssignment?.status
    const taskStatus = currentAssignment?.task_status?.status
    const chatbot = useRef()
    const chatbotInput = document.getElementById('chatbotinput')

    useEffect(() => {
        chatbotInput?.addEventListener('keydown', e => {
            if (e.code === 'Enter') e.preventDefault()
        })
    }, [chatbotInput])

    useEffect(() => {
        setResetChatbot(true)
        setTimeout(() => setResetChatbot(false), 1000)
        dispatch(resetChatHistory())
        setBotSteps(mainSteps(currentUser, currentPage, isInShootPage, currentAssignment, assignmentStatus, taskStatus))
    }, [currentAssignment, currentLanguage])

    const onBotToggle = () => {
        setBotOpened(!botOpened)
        setHelpTextVisible(false)
    }

    return (
        <>
            <StyledWrapper opened={botOpened}>
                {botSteps && (
                    <ThemeProvider theme={chatBotTheme}>
                        {resetChatbot ? (
                            <></>
                        ) : (
                            <ChatBot
                                steps={botSteps}
                                ref={chatbot}
                                isTextarea
                                cache={false}
                                inputAttributes={{id: 'chatbotinput'}}
                                placeholder={t('chatbot:chatbot_input:placeholder')}
                                hideUserAvatar
                                botAvatar="https://ca.slack-edge.com/T03RF4G89-U01NZ7UHYCQ-9f63803fcc00-512"
                                headerComponent={<ChatBotHeader botOpened={botOpened} setBotOpened={setBotOpened} />}
                            />
                        )}
                    </ThemeProvider>
                )}
            </StyledWrapper>

            <StyledMessage fadeOut={!helpTextVisible}>
                <span>{t('chatbot:message')}</span>
                <CloseIcon
                    width={16}
                    height={16}
                    fill={theme.colors.darkGrey}
                    onClick={() => {
                        setHelpTextVisible(false)
                    }}
                />
            </StyledMessage>
            <StyledFloating onClick={onBotToggle}>
                {resetChatbot ? (
                    <Spinner overlay={false} size={16} color={theme.palette.neutral.white} />
                ) : botOpened ? (
                    <StyledArrowDownIcon />
                ) : (
                    <ChatBotIcon />
                )}
            </StyledFloating>
        </>
    )
}
