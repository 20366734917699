import {useState} from 'react'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {FormCard} from '../../../components/form-card/FormCard'
import {ROUTES} from '../../../helpers/constants.ts'
import {PageHelmet} from '../../../components/page-helmet/PageHelmet.tsx'
import {httpLogin} from '../../../http-requests/auth.http.ts'
import {errorHandler} from '../../../helpers/utils.js'
import {StyledClientLogin, StyledClientLoginContainer} from './style'
import {useUserStore} from '../../../features/user/store'

export const ClientLogin = () => {
    const {t} = useTranslation()
    const setUser = useUserStore(store => store.setUser)
    const [isLoading, setIsLoading] = useState(false)

    const controls = [
        {name: 'email', type: 'email', label: t('commons:email')},
        {name: 'password', type: 'password', label: t('commons:password')}
    ]

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('errors:email_invalid')).required(t('errors:required')),
        password: Yup.string().required(t('errors:required')).min(8, t('errors:password_invalid_length'))
    })

    const onSubmit = async formValues => {
        try {
            setIsLoading(true)
            const {data} = await httpLogin(formValues)
            setUser({...data, isAdmin: false})
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <StyledClientLogin>
            <PageHelmet title={t('titles:login')} />
            <StyledClientLoginContainer>
                <FormCard
                    title={t('auth:login')}
                    subtitle={t('auth:enter_email_password')}
                    buttonText={t('auth:login')}
                    linkText={t('auth:forgot_password')}
                    fields={controls}
                    validationSchema={validationSchema}
                    onSubmitCb={onSubmit}
                    linkRoute={ROUTES.FORGOT_PASSWORD}
                    isLoading={isLoading}
                />
            </StyledClientLoginContainer>
        </StyledClientLogin>
    )
}
