import {colors} from '../../theme/colors'
import {palette} from '../../theme/palette'

export const chatBotTheme = {
    background: palette.light.neutral.white,
    fontFamily: 'Cereal',
    headerBgColor: palette.light.neutral.black,
    headerFontColor: palette.light.neutral.white,
    headerFontSize: `14px`,
    botBubbleColor: colors.lightGrey,
    botFontColor: palette.light.neutral.black,
    userBubbleColor: palette.light.neutral.black,
    userFontColor: palette.light.neutral.white
}
