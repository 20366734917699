import {CSSProperties, forwardRef} from 'react'
import {FooterWrapper} from './style'
import {Spinner} from 'src/components/spinner-legacy/Spinner'
import {useTheme} from 'styled-components'
import {Button} from 'src/components/button/Button.tsx'

export const MODAL_FOOTER_BUTTONS_ALIGNMENTS = {
    END: 'flex-end',
    SPACE_BETWEEN: 'space-between'
}

interface FooterProps {
    buttonPrimary?: {
        text: string
        onClick: () => void
        disabled?: boolean
        isLoading?: boolean
    }
    buttonSecondary?: {
        text: string
        onClick: () => void
        disabled?: boolean
    }
    buttonsAlignment?: CSSProperties['justifyContent']
}

export const Footer = forwardRef<HTMLDivElement, FooterProps>(
    ({buttonPrimary, buttonSecondary, buttonsAlignment}, ref) => {
        const theme = useTheme()

        return (
            <FooterWrapper ref={ref} $buttonsAlignment={buttonsAlignment}>
                {!!buttonSecondary && (
                    <Button
                        onClick={buttonSecondary.onClick}
                        fullWidth={!buttonPrimary}
                        disabled={buttonSecondary.disabled}
                        variant="tertiary"
                    >
                        {buttonSecondary.text}
                    </Button>
                )}
                {!!buttonPrimary && (
                    <Button
                        onClick={buttonPrimary.onClick}
                        fullWidth={!buttonSecondary}
                        disabled={buttonPrimary.disabled}
                        variant="primary"
                    >
                        {buttonPrimary.text}
                        {buttonPrimary.isLoading && (
                            <Spinner overlay={false} center={false} color={theme.palette.neutral.white} />
                        )}
                    </Button>
                )}
            </FooterWrapper>
        )
    }
)

Footer.displayName = 'Footer'
