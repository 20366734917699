import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {ScheduleButtons} from 'src/features/assignment/components/schedule-buttons/ScheduleButtons.tsx'

export const AcceptedDatesDeclinedDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>{t('assignment:descriptions:schedule')}</AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t('assignment:descriptions:accepted_dates_declined:paragraph')}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            <ScheduleButtons assignment={assignment} serviceType={serviceType} />
        </AssignmentDescriptionWrapper>
    )
}
