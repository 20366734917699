import styled, {css} from 'styled-components'

export const HomepageWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        height: 100%;
        overflow-y: scroll;

        .container {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            gap: ${spacing * 15}px;
            padding: ${spacing * 10}px 0;

            ${mediaQuery.SM} {
                padding: ${spacing * 4}px 0;
            }

            ${mediaQuery.LG} {
                gap: ${spacing * 9}px;
            }
        }
    `}
`
