import styled, {css} from 'styled-components'

export const StyledBody = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${palette.neutral.black};
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 6}px;
        padding: ${spacing * 6}px;
        text-align: center;
    `}
`
