import {FC, HTMLAttributes} from 'react'

export const LogoutIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 24, ...rest}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.57725 3.57749C3.73353 3.42121 3.94549 3.33341 4.1665 3.33341H7.49984C7.96007 3.33341 8.33317 2.96032 8.33317 2.50008C8.33317 2.03984 7.96007 1.66675 7.49984 1.66675H4.1665C3.50346 1.66675 2.86758 1.93014 2.39874 2.39898C1.9299 2.86782 1.6665 3.50371 1.6665 4.16675V15.8334C1.6665 16.4965 1.9299 17.1323 2.39874 17.6012C2.86758 18.07 3.50346 18.3334 4.1665 18.3334H7.49984C7.96007 18.3334 8.33317 17.9603 8.33317 17.5001C8.33317 17.0398 7.96007 16.6667 7.49984 16.6667H4.1665C3.94549 16.6667 3.73353 16.579 3.57725 16.4227C3.42097 16.2664 3.33317 16.0544 3.33317 15.8334V4.16675C3.33317 3.94573 3.42097 3.73377 3.57725 3.57749ZM12.7439 5.24416C13.0694 4.91872 13.597 4.91872 13.9224 5.24416L18.3332 10.0001C18.3332 10.0134 18.3329 10.0267 18.3322 10.0401C18.3227 10.2398 18.2416 10.4368 18.0891 10.5893L13.9224 14.756C13.597 15.0814 13.0694 15.0814 12.7439 14.756C12.4185 14.4306 12.4185 13.9029 12.7439 13.5775L15.488 10.8334H7.49984C7.0396 10.8334 6.6665 10.4603 6.6665 10.0001C6.6665 9.53984 7.0396 9.16675 7.49984 9.16675H15.488L12.7439 6.42267C12.4185 6.09723 12.4185 5.5696 12.7439 5.24416ZM13.9224 5.24416L18.3331 9.99239C18.3312 9.78169 18.2499 9.57159 18.0891 9.41083L13.9224 5.24416Z"
                fill="currentColor"
            />
        </svg>
    )
}
