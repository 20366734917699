import styled, {css} from 'styled-components'

export const StyledTab = styled.button`
    ${({
        theme: {spacing, colors, palette, transition},
        color,
        textColor,
        borderColor,
        size,
        isStrokeIcon,
        isPathFilledIcon,
        iconPosition,
        fullWidth,
        disabled,
        isSelected
    }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing}px;
        flex-flow: ${iconPosition === 'left' ? 'row' : 'row-reverse'};
        height: ${size === 'small' ? spacing * 8 : size === 'big' ? spacing * 16 : spacing * 12}px;
        width: ${fullWidth ? '100%' : 'max-content'};
        max-width: 100%;
        padding: ${spacing * 1.5}px ${spacing * 2.5}px;
        cursor: pointer;
        border-radius: ${spacing * 2}px;
        background: ${!isSelected ? 'transparent' : colors[color] || color};
        border: ${isSelected ? `1px solid ${colors[borderColor] || borderColor}` : '1px solid transparent'};
        ${disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed !important;
        `}

        ${!disabled &&
        css`
            ${transition};
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        `}
      
    & svg {
            color: ${isSelected ? colors[textColor] || textColor : palette.neutral['500']};
            height: ${size === 'small' ? spacing * 8 : size === 'big' ? spacing * 6 : spacing * 6}px;
            ${!isStrokeIcon &&
            css`
                fill: ${colors[textColor] || textColor};
            `}
            & path {
                ${isStrokeIcon &&
                css`
                    stroke: ${colors[textColor] || textColor};
                `}
                ${isPathFilledIcon &&
                css`
                    fill: ${colors[textColor] || textColor};
                `}
            }
        }
    `}
`

export const StyledInnerSpan = styled.span`
    ${({theme: {colors, palette, typography}, textColor, size, textUppercase, isSelected}) => css`
        ${size === 'big' ? typography.textMd : typography.textSm};
        font-weight: 500;
        text-transform: ${textUppercase ? 'uppercase' : 'inherit'};
        color: ${isSelected ? colors[textColor] || textColor : palette.neutral['500']};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`
