import {FC, HTMLAttributes} from 'react'

export const CheckIcon: FC<{size?: number; fill?: string} & HTMLAttributes<SVGSVGElement>> = ({
    size = 20,
    fill = 'currentColor',
    ...rest
}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.3174 3.56426C14.708 3.95478 14.708 4.58794 14.3174 4.97847L6.9841 12.3118C6.79657 12.4993 6.54221 12.6047 6.277 12.6047C6.01178 12.6047 5.75743 12.4993 5.56989 12.3118L2.23656 8.97847C1.84603 8.58794 1.84603 7.95478 2.23656 7.56426C2.62708 7.17373 3.26025 7.17373 3.65077 7.56426L6.277 10.1905L12.9032 3.56426C13.2937 3.17373 13.9269 3.17373 14.3174 3.56426Z"
            fill={fill}
        />
    </svg>
)
