import {Switch} from 'src/components/switch/Switch'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {useUpdateHostApprovalTaskImage} from 'src/features/admin-host-approval/services/useUpdateHostApprovalTaskImage'
import {useTranslation} from 'react-i18next'
import {AdminHostApprovalImageCard} from 'src/features/admin-host-approval/components/admin-host-approval-image-card/AdminHostApprovalImageCard'

export const AdminHostApprovalRoomAmenityImageCard = ({
    taskId,
    image,
    showUploadedToggle,
    isSelected
}: {
    taskId: number
    image: AdminHostApprovalTaskImage
    showUploadedToggle?: boolean
    isSelected?: boolean
}) => {
    const {t} = useTranslation()
    const {mutate: updateImage, isLoading: isLoadingUpdateImage} = useUpdateHostApprovalTaskImage({
        taskId,
        imageId: image.id
    })

    return (
        <AdminHostApprovalImageCard
            key={image.id}
            image={image}
            isSelected={!!isSelected}
            endSlot={
                showUploadedToggle && (
                    <Switch
                        label={t('host_approval_task:uploaded')}
                        disabled={isLoadingUpdateImage}
                        onChange={value => updateImage({is_uploaded: value})}
                        size="sm"
                        defaultChecked={!!image.is_uploaded}
                    />
                )
            }
        />
    )
}
