import styled, {css} from 'styled-components'
import {Container} from '../../../components/container/Container'

export const StyledCreateAccount = styled.div(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 20}px;
        padding-bottom: ${spacing * 20}px;
        overflow-y: auto;
        min-height: 100%;
        display: grid;
    `
)

export const StyledCreateAccountContainer = styled(Container)(
    ({theme: {mediaQuery}}) => css`
        display: grid;
        place-items: center;

        ${mediaQuery.SM} {
            justify-items: stretch;
        }
    `
)
