import {FC, HTMLAttributes} from 'react'

export const MapPinIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 16, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.2288 2.89543C5.229 1.89524 6.58555 1.33333 8.00004 1.33333C9.41453 1.33333 10.7711 1.89524 11.7713 2.89543C12.7715 3.89562 13.3334 5.25218 13.3334 6.66667C13.3334 8.73204 11.9895 10.7362 10.5161 12.2915C9.79297 13.0548 9.0676 13.6796 8.52226 14.1139C8.3193 14.2755 8.14202 14.4101 8.00004 14.5151C7.85806 14.4101 7.68078 14.2755 7.47783 14.1139C6.93249 13.6796 6.20712 13.0548 5.48401 12.2915C4.01053 10.7362 2.66671 8.73204 2.66671 6.66667C2.66671 5.25218 3.22861 3.89562 4.2288 2.89543ZM7.62988 15.8878C7.63007 15.8879 7.63024 15.888 8.00004 15.3333L7.63024 15.888C7.85417 16.0373 8.14591 16.0373 8.36984 15.888L8.00004 15.3333C8.36984 15.888 8.37001 15.8879 8.3702 15.8878L8.3707 15.8875L8.37211 15.8865L8.37655 15.8835L8.39183 15.8732C8.40481 15.8644 8.42332 15.8517 8.447 15.8353C8.49435 15.8025 8.5624 15.7548 8.64818 15.6928C8.81968 15.5689 9.06247 15.3881 9.35283 15.1569C9.93249 14.6954 10.7071 14.0286 11.484 13.2085C13.0105 11.5972 14.6667 9.26796 14.6667 6.66667C14.6667 4.89856 13.9643 3.20286 12.7141 1.95262C11.4638 0.702379 9.76815 0 8.00004 0C6.23193 0 4.53624 0.702379 3.286 1.95262C2.03575 3.20286 1.33337 4.89856 1.33337 6.66667C1.33337 9.26796 2.98955 11.5972 4.51607 13.2085C5.29297 14.0286 6.0676 14.6954 6.64726 15.1569C6.93761 15.3881 7.1804 15.5689 7.3519 15.6928C7.43768 15.7548 7.50573 15.8025 7.55308 15.8353C7.57676 15.8517 7.59527 15.8644 7.60825 15.8732L7.62353 15.8835L7.62797 15.8865L7.62938 15.8875L7.62988 15.8878ZM6.66671 6.66667C6.66671 5.93029 7.26366 5.33333 8.00004 5.33333C8.73642 5.33333 9.33337 5.93029 9.33337 6.66667C9.33337 7.40305 8.73642 8 8.00004 8C7.26366 8 6.66671 7.40305 6.66671 6.66667ZM8.00004 4C6.52728 4 5.33337 5.19391 5.33337 6.66667C5.33337 8.13943 6.52728 9.33333 8.00004 9.33333C9.4728 9.33333 10.6667 8.13943 10.6667 6.66667C10.6667 5.19391 9.4728 4 8.00004 4Z"
            fill="currentColor"
        />
    </svg>
)
