import styled, {css} from 'styled-components'

export const StyledButtons = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing * 2}px;

        ${mediaQueries.m} {
            flex-direction: column;
            width: 100%;
        }
    `
)

export const StyledHostDates = styled.p(
    ({theme: {palette, typography, mediaQueries}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['600']};
        width: 100%;
        text-align: center;
        word-break: break-word;

        & b {
            white-space: nowrap;
            font-weight: 500;
        }

        ${mediaQueries.m} {
            text-align: left;
        }
    `
)
