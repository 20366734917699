/**
 * What is that?
 *
 * We need to handle the behavior in which a user opens multiple tabs to do an action on a specific shoot.
 * Action on a specific shoot should trigger an update on our queryData or should invalidate the involved query
 * on the other tabs to keep the whole system synced.
 *
 * To proceed quickly without the support of the back-end for a socket logic, we decided to handle
 * our query invalidation with storage event listener.
 * ( https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event )
 *
 * Our Listener live in the App.js file, so we can use our queryClient instance to make our invalidations and updates.
 *
 * Remember ------
 * this won't work on the same page that is making the changes —
 * it is really a way for other pages on the domain using the storage to sync any changes that are made.
 * Pages on other domains can't access the same storage objects.
 */
import {QUERY_ACTION_KEYS, QUERY_KEYS, queryClient} from '../queryClient'
import {QueryClient} from '@tanstack/react-query'
type QueryActionKey = (typeof QUERY_ACTION_KEYS)[keyof typeof QUERY_ACTION_KEYS]

//{qc: QueryClient, type: "action-string"}
export const invalidationSignal = (actionKey: QueryActionKey) => {
    localStorage.setItem('invalidateAction', actionKey)
}

export const processInvalidationSignal = (actionKey: QueryActionKey, qc: QueryClient) => {
    //handle empty invalidateAction key
    if (!localStorage.getItem('invalidateAction')) return

    switch (actionKey) {
        case QUERY_ACTION_KEYS.ASSIGNMENT_DECLINED:
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.NEW_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.COMPLETED_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT]})
            break
        case QUERY_ACTION_KEYS.ASSIGNMENT_ACCEPTED:
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.SPACES]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.NEW_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.CURRENT_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT]})
            break
        case QUERY_ACTION_KEYS.ASSIGNMENT_SCHEDULED:
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.CURRENT_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT]})
            break
        case QUERY_ACTION_KEYS.SHOOT_UNSCHEDULED:
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.CURRENT_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT]})
            break
        case QUERY_ACTION_KEYS.ASSIGNMENT_CHANGE_COORDINATION_STATUS:
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.CURRENT_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT]})
            break
        case QUERY_ACTION_KEYS.ASSETS_UPLOADED:
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.CURRENT_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT]})
            break
        case QUERY_ACTION_KEYS.SUBMIT_TO_AIRBNB:
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.CURRENT_SHOOTS]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE]})
            void qc.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT]})
            break
        case QUERY_ACTION_KEYS.HOST_APPROVAL_TASK_TO_PENDING_HOST_APPROVAL:
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, 'new']})
            void queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, 'pending_host_approval']
            })
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_STATUSES_COUNTER]})
            break
        case QUERY_ACTION_KEYS.HOST_APPROVAL_TASK_TO_READY_FOR_REVIEW:
            void queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, 'pending_host_approval']
            })
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, 'ready_for_review']})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_STATUSES_COUNTER]})
            break
        case QUERY_ACTION_KEYS.HOST_APPROVAL_TASK_TO_COMPLETED:
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, 'ready_for_review']})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, 'completed']})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_STATUSES_COUNTER]})
            break
        default:
            console.error('actionKey', actionKey)
    }
    //remove invalidateAction key after the execution
    localStorage.removeItem('invalidateAction')
}
