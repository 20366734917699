import {useUserStore} from 'src/features/user/store.ts'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {
    httpUpdateHostApprovalTask,
    HttpUpdateAdminTaskOptions
} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {AdminHostApprovalTask} from 'src/features/admin-host-approval/types.ts'

export const useUpdateHostApprovalTask = ({
    status,
    options
}: {
    status: AdminHostApprovalTask['status']
    options?: UseMutationOptions<unknown, unknown, Omit<HttpUpdateAdminTaskOptions, 'headers'>>
}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useMutation({
        ...options,
        mutationFn: ({
            payload,
            urlParams
        }: {
            payload: HttpUpdateAdminTaskOptions['payload']
            urlParams: HttpUpdateAdminTaskOptions['urlParams']
        }) =>
            httpUpdateHostApprovalTask({
                headers: makeHttpAuthorizationHeader(adminAccessToken),
                urlParams,
                payload
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, status]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
