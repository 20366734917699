import axios from 'axios'

export const httpSearchAssignment = (adminAccessToken, searchValue) => {
    return axios.get(`/admin/assignments/search`, {
        headers: {
            Authorization: `Bearer ${adminAccessToken}`
        },
        params: {
            search: searchValue
        }
    })
}
