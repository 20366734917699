import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

export const FooterWrapper = styled.div<{$buttonsAlignment: CSSProperties['justifyContent']}>`
    ${({theme: {spacing}, $buttonsAlignment}) => css`
        padding: ${spacing * 5}px ${spacing * 8}px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: ${$buttonsAlignment};
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`
