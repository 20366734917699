import {useUserStore} from 'src/features/user/store.ts'
import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {
    httpGetHostApprovalTasks,
    HttpGetHostApprovalTasksOptions
} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {PaginationParams} from 'src/types.ts'
import {AdminHostApprovalTask} from 'src/features/admin-host-approval/types.ts'

export const useAdminHostApprovalTasks = ({
    status, //destructured to simplify the invalidations based on the status
    ...forwardedParams
}: Omit<HttpGetHostApprovalTasksOptions['params'], 'page' | 'limit' | 'response_type'>) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, status, forwardedParams],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetHostApprovalTasks({
                    headers: makeHttpAuthorizationHeader(adminAccessToken),
                    params: {
                        page: pageParam,
                        limit: 50,
                        response_type: 'extended',
                        status,
                        ...forwardedParams
                    }
                }),
                responseShape: PaginationParams.and(z.object({data: AdminHostApprovalTask.array()})),
                onZodError: captureException
            }),
        getNextPageParam: lastPage => (lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : null)
    })

    return {
        ...query,
        flatData: query.data ? query.data.pages.flatMap(page => page.data) : [],
        page: query.data ? (query.data.pages.at(-1)?.current_page ?? 0) + 1 : 1,
        fetchNextPage: () => infiniteQueryFetchNextPage(query)
    }
}
