import {Modal} from '../../../../components/modal-legacy/Modal'
import {Trans, useTranslation} from 'react-i18next'
import {VideoDropzone} from '../video-dropzone/VideoDropzone'
import {useDropzone} from 'react-dropzone'
import {
    httpConfirmUploadMedia,
    httpDeleteMedia,
    httpGetUploadedMedia,
    httpGetUploadMediaLink,
    httpUploadMedia
} from 'src/http-requests/single-assignment.http'
import {errorHandler} from '../../../../helpers/utils'
import {Button} from 'src/components/button/Button'
import {
    StyledProgressBarWrapper,
    StyledUploadingVideo,
    StyledVideoCard,
    StyledVideoInfo,
    StyledVideoPlayBox,
    StyledVideoUploadModalBody,
    StyledVideoUploadModalFooter,
    StyledVideoUploadModalHeader
} from './style'
import {useEffect, useState} from 'react'
import {PlayButton} from '../../../../assets/icons/PlayButton'
import {Flexbox} from '../../../../components/flexbox/Flexbox'
import {Spinner} from '../../../../components/spinner-legacy/Spinner'
import {AlertIcon} from '../../../../assets/icons/AlertIcon'
import {useTheme} from 'styled-components'
import {useUserStore} from '../../../../features/user/store'
import {Trash01Icon, XCloseIcon} from 'src/components/icon'
import {QUERY_KEYS, queryClient} from '../../../../queryClient'

export const VideoUploadModal = ({assignmentId, darylDirectoryId, onClose}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [uploadState, setUploadState] = useState('fetching') //fetching, pending, gettingLink, uploading, uploaded
    const [uploadProgress, setUploadProgress] = useState(0)
    const [file, setFile] = useState(null)
    const accessToken = useUserStore(store => store.accessToken)
    const dropzone = useDropzone({
        accept: {
            'video/mp4v': ['.mp4v'],
            'video/mpg4': ['.mpg4'],
            'video/mp4': ['.mp4'],
            'video/qt': ['.qt'],
            'video/quicktime': ['.mov'],
            'video/jpgv': ['.jpgv']
        },
        maxFiles: 1,
        onDropAccepted: ([file]) => setFile(file)
    })

    const getUploadedVideo = async () => {
        try {
            const {data} = await httpGetUploadedMedia(accessToken, assignmentId)
            setFile(data.media ?? null)
            setUploadState(data.media ? 'uploaded' : 'pending')
        } catch (error) {
            errorHandler(error)
        }
    }

    useEffect(() => {
        getUploadedVideo()
    }, [])

    const onUploadProgress = progressEvent => {
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }

    const uploadVideo = async () => {
        try {
            setUploadState('gettingLink')
            const {data} = await httpGetUploadMediaLink(accessToken, assignmentId, {media: file.name})
            setUploadState('uploading')
            await httpUploadMedia(accessToken, data.presigned_url, dropzone.acceptedFiles[0], onUploadProgress)
            await httpConfirmUploadMedia(accessToken, assignmentId, data.media.id)
            setFile({...data.media, name: dropzone.acceptedFiles[0].name})
            setUploadState('uploaded')
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENT]})
        } catch (error) {
            setUploadState('pending')
            errorHandler(error)
        } finally {
            setUploadProgress(0)
        }
    }

    const deleteVideo = async () => {
        try {
            await httpDeleteMedia(accessToken, assignmentId, file.id)
            setUploadState('pending')
            setFile(null)
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ASSIGNMENT]})
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <Modal onClose={onClose} width="480px">
            <StyledVideoUploadModalHeader>
                <Button
                    disabled={uploadState === 'gettingLink' || uploadState === 'uploading'}
                    variant="ghost"
                    onClick={onClose}
                    size="sm"
                    shape="square"
                >
                    <XCloseIcon />
                </Button>
                {t('single_shooting:upload_video_clip')}
            </StyledVideoUploadModalHeader>
            <StyledVideoUploadModalBody>
                {(uploadState === 'uploading' || uploadState === 'uploaded') && (
                    <StyledUploadingVideo direction="column" gap={3} align="stretch">
                        {uploadState === 'uploading' && (
                            <div>
                                <h3>{t('single_shooting:uploading_video_clip')}</h3>
                                <StyledProgressBarWrapper align="center" gap={1.5} uploadProgress={uploadProgress}>
                                    <div>
                                        <div />
                                    </div>
                                    <span>{`${uploadProgress}%`}</span>
                                </StyledProgressBarWrapper>
                            </div>
                        )}
                        <StyledVideoCard gap={1.5} justify="space-between" align="start">
                            <Flexbox align="center" gap={3} width="100%">
                                <StyledVideoPlayBox justify="center" align="center">
                                    <PlayButton />
                                </StyledVideoPlayBox>
                                <StyledVideoInfo>
                                    <h4>{file?.name ?? '-'}</h4>
                                    <p>
                                        {file?.size
                                            ? `${Math.round(file.size / 1000000)}MB`
                                            : uploadState === 'uploaded'
                                            ? t('admin:uploaded')
                                            : '-'}
                                    </p>
                                </StyledVideoInfo>
                            </Flexbox>
                            {uploadState === 'uploading' ? (
                                <Spinner center={false} overlay={false} overlayFullscreen={false} size={32} />
                            ) : (
                                <Button
                                    disabled={uploadState === 'gettingLink' || uploadState === 'uploading'}
                                    variant="ghost"
                                    onClick={deleteVideo}
                                    size="sm"
                                    shape="square"
                                >
                                    <Trash01Icon />
                                </Button>
                            )}
                        </StyledVideoCard>
                        {uploadState === 'uploaded' && (
                            <Flexbox align="center" gap={4}>
                                <AlertIcon size={20} color={theme.colors.ratingOrange} />
                                <b>
                                    <Trans
                                        i18nKey="single_shooting:remember_daryl"
                                        components={[
                                            <a
                                                href={`https://airbnb.orangelogic.com/asset-management/${darylDirectoryId}?Flat=y&WS=AssetManagement`}
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        ]}
                                    />
                                </b>
                            </Flexbox>
                        )}
                    </StyledUploadingVideo>
                )}
                {(uploadState === 'pending' || uploadState === 'gettingLink') && (
                    <VideoDropzone dropzone={dropzone} file={file} />
                )}
                {uploadState === 'fetching' && (
                    <Spinner center={false} overlay={false} overlayFullscreen={false} size={40} />
                )}
            </StyledVideoUploadModalBody>
            <StyledVideoUploadModalFooter>
                {uploadState === 'uploaded' ? (
                    <Button fullWidth variant="tertiary" onClick={onClose}>
                        {t('commons:close')}
                    </Button>
                ) : (
                    <Button
                        fullWidth
                        onClick={uploadVideo}
                        disabled={!file || uploadState === 'gettingLink' || uploadState === 'uploading'}
                    >
                        {t('admin:upload')}
                        {uploadState === 'gettingLink' && (
                            <Spinner color="currentColor" center={false} overlay={false} />
                        )}
                    </Button>
                )}
            </StyledVideoUploadModalFooter>
        </Modal>
    )
}
