import {TableMobileHeader} from './header/TableMobileHeader'
import {NotPaginatedBody} from './body/NotPaginatedBody'
import {PaginatedBody} from './body/PaginatedBody'

export const MobileTable = ({
    isLoading = false,
    isRefetching,
    isFetchingNextPage,
    isError,
    tbodyHeight = 'auto',
    columns = [],
    data = [],
    isPaginated,
    onChangePageCb,
    page,
    sortColumnCb,
    sorter,
    columnsRenderers,
    onClickRow = Function.prototype,
    onRefresh,
    expandedRowsIndexes = []
}) => {
    const bodyProps = {
        columns,
        expandedRowsIndexes,
        columnsRenderers,
        onClickRow,
        data,
        isLoading,
        isRefetching,
        isFetchingNextPage,
        isError,
        onChangePageCb,
        onRefresh,
        page,
        tbodyHeight
    }

    return (
        <div>
            <TableMobileHeader columns={columns} sorter={sorter} sortColumnCb={sortColumnCb} />
            {isPaginated ? <PaginatedBody {...bodyProps} /> : <NotPaginatedBody {...bodyProps} />}
        </div>
    )
}
