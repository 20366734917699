import PropTypes from 'prop-types'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {httpUploadImages} from '../../../../../http-requests/admin/matterport.http'
import {errorHandler} from '../../../../../helpers/utils'
import {Spinner} from '../../../../../components/spinner-legacy/Spinner'
import {addAccessibilityImages} from '../../../../../store/matterport'
import {AdminPopover} from '../../../../../components/admin-popover/AdminPopover'
import {useUserStore} from '../../../../../features/user/store'

export const ConfirmUploadModal = ({setShowConfirmUploadModalCb, imagesFromDropzone, setImagesFromDropzoneCb}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [isLoading, setIsLoading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const onClose = useCallback(() => setShowConfirmUploadModalCb(false), [])

    const onUploadProgress = progressEvent => {
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }

    const onSubmit = async () => {
        const formData = new FormData()
        imagesFromDropzone.accepted.forEach(file => formData.append(`photos[]`, file))

        try {
            setIsLoading(true)
            const {data} = await httpUploadImages(adminAccessToken, taskId, formData, onUploadProgress)
            dispatch(addAccessibilityImages(data))
            setImagesFromDropzoneCb({accepted: [], rejected: []})
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <AdminPopover
            onClose={!isLoading ? onClose : Function.prototype}
            title={t('admin:youre_upload_set')}
            buttonLabel={t('commons:confirm')}
            callback={onSubmit}
            hasActions={!isLoading}
            hasCloseButton={!isLoading}
        >
            <div className="position-relative">
                {isLoading && <Spinner center={false} overlay={false} progress={uploadProgress} />}
            </div>
        </AdminPopover>
    )
}

ConfirmUploadModal.propTypes = {
    setShowConfirmUploadModalCb: PropTypes.func
}
