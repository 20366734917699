import {useUserStore} from 'src/features/user/store.ts'
import {infiniteQueryFetchNextPage, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {PaginationParams} from 'src/types.ts'
import {z} from 'zod'
import {captureException} from '@sentry/react'
import {httpGetAdminHosts, HttpGetAdminHostsOptions} from 'src/features/admin-hosts/services/admin-hosts.http.ts'
import {AdminHost} from 'src/features/admin-hosts/types.ts'

export const useAdminHosts = ({
    params
}: {
    params: Omit<HttpGetAdminHostsOptions['params'], 'page' | 'limit' | 'response_type'>
}) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.ADMIN_HOSTS, params],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetAdminHosts({
                    headers: makeHttpAuthorizationHeader(adminAccessToken),
                    params: {
                        page: pageParam,
                        limit: 50,
                        response_type: 'regular',
                        ...params
                    }
                }),
                responseShape: PaginationParams.and(z.object({data: AdminHost.array()})),
                onZodError: captureException
            }),
        getNextPageParam: lastPage => (lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : null)
    })

    return {
        ...query,
        flatData: query.data ? query.data.pages.flatMap(page => page.data) : [],
        page: query.data ? (query.data.pages.at(-1)?.current_page ?? 0) + 1 : 1,
        fetchNextPage: () => infiniteQueryFetchNextPage(query)
    }
}
