import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'

import {Spinner} from '../../../../components/spinner-legacy/Spinner'
import {QuoteCard} from './quote/Quote'
import {ProductionCard} from './production/Production'
import {PostProductionCard} from './post-production/postProduction'
import {PaymentsCard} from './payments/Payments'
import {ActivitiesCard} from './activities/Activities'
import {AssignmentDetails} from './details/Details'

import EmptySearchImage from '../../../../assets/images/assignments_empty_search.svg'

import {useAdminQuote, useAdminQuoteLogs} from '../../../../hooks'

import {ImageWrapper, StyledColumns} from './style'
import {Flexbox} from '../../../../components/flexbox/Flexbox'
import {ForcedMatches} from './forced-matches/ForcedMatches'

export const Assignment = ({selectedQuoteId, setHasQuoteData}) => {
    const {t} = useTranslation()
    const quoteQuery = useAdminQuote(selectedQuoteId)
    const quoteLogsQuery = useAdminQuoteLogs(selectedQuoteId)
    const [, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (quoteQuery.isError) {
            setSearchParams({})
        }
    }, [quoteQuery.isError])

    useEffect(() => {
        if (quoteQuery.isSuccess) {
            setHasQuoteData(true)
        } else {
            setHasQuoteData(false)
        }
    }, [quoteQuery.isSuccess])

    return selectedQuoteId ? (
        quoteQuery.isLoading || quoteLogsQuery.isLoading ? (
            <Spinner overlayFullscreen={false} overlay={false} />
        ) : quoteQuery.isError ? (
            <ImageWrapper>
                <img src={EmptySearchImage} alt="Not valid Search" />
                <p>{t('admin:assignments:not_selected_page')}</p>
            </ImageWrapper>
        ) : (
            <Flexbox direction="column" gap={22}>
                <AssignmentDetails quoteData={quoteQuery.data} />
                <StyledColumns>
                    <QuoteCard quoteData={quoteQuery.data} />
                    <ActivitiesCard
                        activities={quoteLogsQuery.remappedData}
                        onChangePage={quoteLogsQuery.onChangePage}
                        isFetching={quoteLogsQuery.isFetching}
                    />
                    <ProductionCard quoteData={quoteQuery.data} selectedQuoteID={selectedQuoteId} />
                    <ForcedMatches forcedMatches={quoteQuery.data?.forced_matches} quoteId={selectedQuoteId} />
                    <PostProductionCard quoteData={quoteQuery.data} selectedQuoteID={selectedQuoteId} />
                    <PaymentsCard payments={quoteQuery.data?.assignments?.[0]?.payments} quoteData={quoteQuery.data} />
                </StyledColumns>
            </Flexbox>
        )
    ) : (
        <ImageWrapper>
            <img src={EmptySearchImage} alt="Empty Search" />
            <p>{t('admin:assignments:not_selected_page')}</p>
        </ImageWrapper>
    )
}

Assignment.propTypes = {
    selectedQuoteId: PropTypes.number,
    setHasQuoteData: PropTypes.func
}
