import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {MultiParagraphMessage} from '../custom-components/multi-paragraph-message/MultiParagraphMessage'
import {Trans} from 'react-i18next'
import i18next from 'i18next'
import {SimpleLink} from '../custom-components/simple-link/SimpleLink'

export const mb15FlowSteps = () => [
    {
        id: 'MB1.5',
        component: (
            <MultiParagraphMessage
                paragraphs={[
                    i18next.t('chatbot:mb15:mb1.5_1'),
                    <Trans i18nKey="chatbot:mb15:mb1.5_2" components={[<strong />]} />,
                    <Trans i18nKey="chatbot:mb15:mb1.5_3" components={[<strong />]} />,
                    i18next.t('chatbot:mb15:mb1.5_4')
                ]}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.5',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text:
                        'Hosts now have the ability to share two available photoshoot dates and times in their Host Portal. ' +
                        'You will be able to select from two time slots provided by the Host. If one of the dates works for you, select it, and click "Schedule". ' +
                        'If you are unavailable on either of these dates, click "None of these dates work"  and reach out to the Host to define another shoot date. ' +
                        'You can review this guide for more information and details on the coordination system:  https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf'
                })
            )
            return 'MB1.5_link'
        }
    },
    {
        id: 'MB1.5_link',
        component: (
            <SimpleLink
                link="https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf"
                text={i18next.t('chatbot:commons:coordination_system_guide')}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.5_link',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] Coordination system guide'
                })
            )
            return 'send_log'
        }
    }
]
