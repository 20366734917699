import {z} from 'zod'

export const FreelancerPaymentDate = z.object({
    current_page: z.number().int().positive(),
    last_page: z.number().int().positive(),
    paid_out: z.object({
        paid_out_amount: z.number(),
        paid_out_photographer_currency: z.string().nullable(),
        paid_out_photographer_currency_amount: z.number().nullable()
    }),
    data: z.array(
        z.object({
            payment_date: z.string(),
            payment_count: z.number(),
            paid_out_photographer_currency_amount: z.number().nullable(),
            paid_out: z.number(),
            paid_out_photographer_currency: z.string().nullable()
        })
    )
})
export type FreelancerPaymentDate = z.infer<typeof FreelancerPaymentDate>

export const FreelancerPayment = z.object({
    payment_type: z.string().min(1),
    external_id: z.string().min(1),
    airbnb_link: z.string().url(),
    payout_photographer_currency_amount: z.number().nullable(),
    payout_amount: z.number(),
    payout_photographer_currency: z.string().nullable()
})
export type FreelancerPayment = z.infer<typeof FreelancerPayment>
