import {Button} from 'src/components/button/Button'
import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalRemoveImageButton = styled(Button)(
    ({theme: {spacing, palette, shadows, transition}}) => css`
        position: absolute;
        right: ${spacing * 4}px;
        top: ${spacing * 4}px;
        box-shadow: ${shadows.xs};
        background-color: ${palette.neutral.white}dd;
        ${transition};

        &:hover {
            background-color: ${palette.neutral.white};
        }
    `
)
