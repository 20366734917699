import {StyledAvatarFallback, StyledAvatarImage, StyledAvatarRoot} from './style'
import {AvatarIcon} from '../../assets/icons/AvatarIcon'

/**
 * @deprecated use src/components/avatar/Avatar.tsx instead
 * */
export const Avatar = ({className, name, imageUrl}: {className?: string; name: string; imageUrl: string}) => (
    <StyledAvatarRoot className={className}>
        <StyledAvatarImage src={imageUrl} alt={name} />
        <StyledAvatarFallback delayMs={600}>
            <AvatarIcon />
        </StyledAvatarFallback>
    </StyledAvatarRoot>
)
