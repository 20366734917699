import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledCompensationRow = styled(Flexbox)<{$size?: 'sm' | 'md'}>(
    ({theme: {typography}, $size = 'md'}) => css`
        width: 100%;
        white-space: normal;

        & svg {
            width: 20px;
        }

        ${$size == 'md' &&
        css`
            ${typography.textMd}
        `}
        ${$size == 'sm' &&
        css`
            ${typography.textSm}
        `}
    `
)

export const StyledCompensationTotalInfo = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textXs};
    `
)

export const StyledCompensationAmount = styled.p<{$isBold: boolean}>(
    ({$isBold}) => css`
        font-weight: ${$isBold ? '500' : '400'};
    `
)
