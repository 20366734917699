import styled, {css} from 'styled-components'
import {SEARCH_ASSIGNMENT_WIDTH} from './SearchQuote'

export const SearchAssignmentWrapper = styled.div`
    position: relative;
`

export const AssignmentOptionsWrapper = styled.div(
    ({theme: {spacing, colors, palette}}) => css`
        display: flex;
        flex-direction: column;
        border: 1px solid ${colors.borderGrey};
        width: ${SEARCH_ASSIGNMENT_WIDTH}px;
        border-radius: 8px;
        background: ${palette.neutral.white};
        max-height: 322px;
        overflow-y: auto;
        position: absolute;
        margin-top: ${spacing}px;
        top: calc(100% + ${spacing}) px;
    `
)

export const StyledOptionWrapper = styled.div(
    ({theme: {spacing, colors, palette, transition}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing * 2}px;
        padding: ${spacing * 3}px ${spacing * 4}px;
        cursor: pointer;
        ${transition};
        &:not(:last-child) {
            border-bottom: 1px solid ${colors.borderGrey};
        }
        &:hover {
            background-color: ${palette.neutral.black}08;
        }
    `
)

export const StyledAssignmentDetails = styled.div(
    ({theme: {spacing, typography, colors, palette}}) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ${spacing}px;

        & > p {
            ${typography.textXs};
            font-weight: 400;
            color: ${colors.darkGrey};
            width: 302px;
        }

        & > h4 {
            ${typography.textMd};
            font-weight: 500;
            color: ${colors.lightBlack};
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 302px;
        }

        & > span {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral.black};
            width: 302px;
        }

        & > div {
            display: flex;
            align-items: center;
            gap: ${spacing * 2}px;
        }
    `
)
