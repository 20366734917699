import styled, {css, DefaultTheme} from 'styled-components'

export const StyledFilter = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        ${mediaQueries.m} {
            display: grid;
            grid-template-columns: 50px 1fr;
            gap: ${spacing * 3}px;
            align-items: center;
        }
    `
)

export const StyledFiltersWrapper = styled.form(
    ({theme: {spacing, typography, colors, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        align-items: flex-end;
        & h4 {
            font-weight: 400;
            ${typography.textSm};
            color: ${colors.darkGrey};
            text-overflow: ellipsis;
            overflow: hidden;
        }
        ${mediaQueries.m} {
            flex-direction: column;
            align-items: unset;
        }
    `
)

export const StyledSelectWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            display: grid;
            grid-template-columns: 3fr 2fr;
        }
    `
)

export const selectStyleYear = ({colors, spacing, mediaQuery, palette}: DefaultTheme) => ({
    controlCustomStyle: {
        width: 80,
        background: palette.neutral.white,
        minHeight: 36,
        border: `1px solid ${colors.borderGrey} !important`,
        borderRadius: 6,
        height: `32px !important`,
        padding: `0 ${spacing * 1.5}px !important`,
        svg: {fill: `${colors.steelGrey} !important`},
        [mediaQuery.SM]: {width: '100%', height: '36px !important'}
    }
})

export const selectStyleMonth = ({colors, spacing, mediaQuery, palette}: DefaultTheme) => ({
    controlCustomStyle: {
        width: 176,
        background: palette.neutral.white,
        minHeight: 36,
        border: `1px solid ${colors.borderGrey} !important`,
        borderRadius: 6,
        height: `32px !important`,
        padding: `0 ${spacing * 1.5}px !important`,
        svg: {fill: `${colors.steelGrey} !important`},
        [mediaQuery.SM]: {width: '100%', height: '36px !important'}
    }
})
