import styled, {css, DefaultTheme} from 'styled-components'
import {TooltipProps} from 'src/components/tooltip-legacy/Tooltip'

const BaseStyle = ({theme: {spacing, typography}}: {theme: DefaultTheme}) => css`
    padding: ${spacing * 2}px ${spacing * 4}px;
    ${typography.textSm};
    font-weight: 500;
    border-radius: 8px;
    max-width: 320px;
`

const ColorStyles = ({theme: {colors, palette}}: {theme: DefaultTheme}) => ({
    black: css`
        background: ${colors.lightBlack};
        color: ${palette.neutral.white};

        & > span svg {
            fill: ${colors.lightBlack};
        }
    `,
    orange: css`
        background: ${colors.bgOrange};
        color: ${colors.orange};
        border: 1px solid ${colors.borderOrange};
        & > span svg {
            fill: ${colors.borderOrange};
        }
    `
})

export const StyledContent = styled.div<{$variant: TooltipProps['variant']}>`
    ${BaseStyle};
    ${({$variant, theme}) => ColorStyles({theme})[$variant ?? 'black']};
`
