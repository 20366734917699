import {StyledTHead} from 'src/components/table/mobile-t-head/style'
import {TableColumn, TableSorter} from 'src/components/table/Table.tsx'
import {ChevronDownIcon, ChevronUpIcon} from 'src/components/icon/index.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {MouseEvent, useState} from 'react'
import {MobileSorterModal} from 'src/components/table/mobile-sorter-modal/MobileSorterModal'
import {useTranslation} from 'react-i18next'

interface MobileTHeadProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    sorter: TableSorter<TSortName>
}

export const MobileTHead = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    sorter
}: MobileTHeadProps<TData, TSortName>) => {
    const {t} = useTranslation()
    const [showSorterModal, setShowSorterModal] = useState(false)
    const sortedColumn = columns.filter(column => column.sortName).find(column => column.sortName == sorter.orderBy)
    const changeSortDirection = (event: MouseEvent) => {
        event.stopPropagation()
        const nextSortDirection = sorter.orderDirection == 'desc' ? 'asc' : 'desc'
        sorter.onSort({...sorter, orderDirection: nextSortDirection})
    }

    return (
        <StyledTHead>
            {showSorterModal && (
                <MobileSorterModal
                    onClose={() => {
                        setShowSorterModal(false)
                    }}
                    sorter={sorter}
                    columns={columns}
                />
            )}
            <Button
                size="sm"
                variant="secondary"
                fullWidth
                onClick={() => {
                    setShowSorterModal(true)
                }}
            >
                {t('commons:sort_by', {sortBy: sortedColumn?.label ?? '-'})}
                {sorter.orderDirection == 'asc' ? (
                    <ChevronUpIcon size={16} onClick={changeSortDirection} />
                ) : sorter.orderDirection == 'desc' ? (
                    <ChevronDownIcon size={16} onClick={changeSortDirection} />
                ) : null}
            </Button>
        </StyledTHead>
    )
}

MobileTHead.displayName = 'MobileTHead'
