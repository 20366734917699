import styled, {css} from 'styled-components'
import {Button} from 'src/components/button/Button.tsx'

export const StyledButtons = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing * 2}px;

        ${mediaQueries.m} {
            flex-direction: column;
            width: 100%;
        }
    `
)

export const StyledRejectButton = styled(Button)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            width: 100%;
        }
    `
)
