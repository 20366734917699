/**
 * @deprecated use breakpoints in mediaQueries.ts instead
 */
export const screenWidth = {
    XS: 575,
    SM: 767,
    MD: 991,
    LG: 1199,
    XL: 1399,
    small: 365,
    mobile: 1024,
    medium: 1366,
    middle: 640
} as const

/**
 * @deprecated use mediaQueries in mediaQueries.ts instead
 */
export const mediaQuery = {
    XS: `@media (max-width: ${screenWidth.XS}px)`,
    SM: `@media (max-width: ${screenWidth.SM}px)`,
    MD: `@media (max-width: ${screenWidth.MD}px)`,
    LG: `@media (max-width: ${screenWidth.LG}px)`,
    XL: `@media (max-width: ${screenWidth.XL}px)`,
    small: `@media (max-width: ${screenWidth.small}px)`
} as const
