import {useTranslation} from 'react-i18next'
import {Chip} from '../../../../chip/Chip'
import PropTypes from 'prop-types'
import {TASK_STATUSES} from '../../../../../helpers/constants'
import {retrieveCombinedStatus} from '../../../../../helpers/statuses'
import {StyledStatus} from './style'

export const StatusCell = ({item}) => {
    const {t} = useTranslation()
    const taskStatus = item?.task_status?.status
    const coordinationStatus = item?.task_status?.coordination_status
    const status = item?.status

    const {
        label: statusLabel,
        color: statusColor,
        background: statusBackground,
        icon,
        borderColor: statusBorderColor,
        borderStyle: statusBorderStyle
    } = retrieveCombinedStatus(taskStatus, status, false, coordinationStatus)

    return (
        <StyledStatus>
            <Chip
                rounded
                text={t(statusLabel)}
                color={statusColor}
                background={statusBackground}
                borderColor={statusBorderColor}
                borderStyle={statusBorderStyle}
                size="small"
                icon={icon}
            />
            <span>{taskStatus !== TASK_STATUSES.COMPLETED && item?.canceled_reason_notes}</span>
        </StyledStatus>
    )
}

StatusCell.propTypes = {
    item: PropTypes.object
}
