import {httpGetAdminTable} from '../http-requests/admin/partners.http'
import {useInfiniteQuery} from '@tanstack/react-query'
import {errorHandler} from '../helpers/utils'
import {useState, useCallback} from 'react'
import {QUERY_KEYS} from '../queryClient'
import {DEFAULT_LIMIT} from '../helpers/constants'
import {useUserStore} from '../features/user/store'

export const usePartners = () => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [sorter, setSorter] = useState({
        order_by: null,
        order_direction: null
    })
    const [search, setSearch] = useState(null)

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PARTNERS, sorter, search],
        queryFn: async ({pageParam = 1}) => {
            const {data: payload} = await httpGetAdminTable(adminAccessToken, {
                photographer: search,
                limit: DEFAULT_LIMIT,
                page: pageParam,
                ...sorter
            })
            return payload
        },
        onError: errorHandler,
        defaultPageParam: 1
    })

    const onChangePage = () => {
        const currentPage = query.data.pages.at(-1).current_page
        const lastPage = query.data.pages.at(-1).last_page
        if (!query.isFetching) {
            if (currentPage < lastPage) {
                query.fetchNextPage({pageParam: query.data.pages.at(-1).current_page + 1})
            }
        }
    }

    const onSortColumn = useCallback((columnName, orderDirection) => {
        setSorter({order_by: columnName, order_direction: orderDirection})
    }, [])

    return {
        ...query,
        remappedData: query.data ? query.data.pages?.flatMap(page => page.data) : [],
        page: query.data ? query.data.pages?.at(-1)?.current_page + 1 : 1,
        onChangePage,
        sorter,
        onSortColumn,
        search,
        setSearch
    }
}
