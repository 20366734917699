import styled, {css} from 'styled-components'

export const StyledAdminSelectTrigger = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: auto minmax(0, 1fr) auto;
        align-items: center;
        gap: ${spacing * 2.5}px;
        width: 100%;
        cursor: pointer;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    `
)
