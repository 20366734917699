import {SuccessIcon} from '../../theme/icons'
import {useTranslation} from 'react-i18next'
import {Modal} from '../modal-legacy/Modal'
import {StyledBody} from './style'
import {FC} from 'react'

interface SuccessModalProps {
    onClose: () => void
    buttonLabel: string
    callback: () => void
    paragraph: string
}

export const SuccessModal: FC<SuccessModalProps> = ({onClose, buttonLabel, callback, paragraph}) => {
    const {t} = useTranslation()

    return (
        <Modal
            onClose={onClose}
            width="400px"
            buttonPrimary={{
                text: buttonLabel,
                onClick: callback
            }}
        >
            <StyledBody>
                <SuccessIcon size={64} />
                <p>{t(paragraph)}</p>
            </StyledBody>
        </Modal>
    )
}
