import styled, {css} from 'styled-components'

export const StyledButtons = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;

        ${mediaQueries.m} {
            flex-direction: column;
            width: 100%;

            & > button {
                width: 100%;
            }
        }
    `
)
