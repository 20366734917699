import {StyledAdminHosts, StyledHeading} from 'src/pages/admin-hosts/style.ts'
import {PageHelmet} from 'src/components/page-helmet/PageHelmet.tsx'
import {useTranslation} from 'react-i18next'
import {Hash02Icon, Mail01Icon, SearchLgIcon, User01Icon} from 'src/components/icon'
import {InputText} from 'src/components/input-text/InputText.tsx'
import {useTheme} from 'styled-components'
import {debounce} from 'src/helpers/helpers.ts'
import {ChangeEvent} from 'react'
import {useAdminHosts} from 'src/features/admin-hosts/services/useAdminHosts.ts'
import {Table} from 'src/components/table/Table.tsx'
import {useParsedSearchParams} from 'src/hooks/useParsedSearchParams.ts'
import {z} from 'zod'
import {Order} from 'src/types.ts'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Tooltip} from 'src/components/tooltip/Tooltip'
import {ButtonLink} from 'src/components/button-link/ButtonLink'
import {AdminHostsImpersonateDropdown} from 'src/features/admin-hosts/components/admin-hosts-impersonate-dropdown/AdminHostsImpersonateDropdown'
import {elevateUrlEnvMap} from 'src/features/admin-hosts/utils'

export const ADMIN_HOSTS_ENV =
    window.location.hostname == 'localhost'
        ? 'development'
        : process.env.REACT_APP_ENDPOINT == 'https://mediabox-api.translated.com/api'
        ? 'production'
        : 'staging'

export const AdminHosts = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {searchParams, setSearchParams} = useParsedSearchParams(
        z
            .object({
                search: z.string().optional(),
                order_by: Order.shape.order_by,
                order_direction: Order.shape.order_direction
            })
            .partial()
    )
    const adminHostsQuery = useAdminHosts({params: searchParams})

    const handleSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({search: event.target.value || undefined})
    }, 1000)

    return (
        <StyledAdminHosts>
            <PageHelmet title={t('admin_hosts:meta_title')} />
            <StyledHeading>
                <h1>{t('admin_hosts:title')}</h1>
                <InputText
                    type="text"
                    typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['400']} />}
                    inputSize="sm"
                    width="280px"
                    placeholder={t('admin:search_by_name_email')}
                    onChange={handleSearch}
                    autoComplete="off"
                />
            </StyledHeading>
            <Table
                columns={[
                    {
                        sortName: 'external_host_id',
                        cellRender: host => (
                            <Tooltip content={host.external_host_id} triggerProps={{asChild: true}}>
                                <span>{host.external_host_id}</span>
                            </Tooltip>
                        ),
                        label: (
                            <Flexbox align="center" gap={1}>
                                <Hash02Icon size={16} /> {t('admin_hosts:external_id')}
                            </Flexbox>
                        ),
                        width: '15%'
                    },
                    {
                        sortName: 'first_name',
                        cellRender: host =>
                            host.first_name ? (
                                <Tooltip content={host.first_name} triggerProps={{asChild: true}}>
                                    <span>{host.first_name}</span>
                                </Tooltip>
                            ) : (
                                '-'
                            ),
                        label: (
                            <Flexbox align="center" gap={1}>
                                <User01Icon size={16} /> {t('commons:name')}
                            </Flexbox>
                        ),
                        width: '20%'
                    },
                    {
                        sortName: 'email',
                        cellRender: host =>
                            host.email ? (
                                <Tooltip content={host.email} triggerProps={{asChild: true}}>
                                    <span>{host.email}</span>
                                </Tooltip>
                            ) : (
                                '-'
                            ),
                        label: (
                            <Flexbox align="center" gap={1}>
                                <Mail01Icon size={16} /> {t('commons:email')}
                            </Flexbox>
                        ),
                        width: '35%'
                    },
                    {
                        alignment: 'right',
                        cellRender: host => (
                            <Flexbox gap={2}>
                                <ButtonLink
                                    disabled={!host.code || !host.password}
                                    variant="tertiary"
                                    size="sm"
                                    target="_blank"
                                    to={`${elevateUrlEnvMap[ADMIN_HOSTS_ENV]}/dashboard/${host.code}/${host.password}`}
                                >
                                    Elevate
                                </ButtonLink>
                                <AdminHostsImpersonateDropdown
                                    externalHostId={host.external_host_id}
                                    disabled={!host.last_login || !host.external_host_id}
                                />
                            </Flexbox>
                        ),
                        width: '30%'
                    }
                ]}
                data={adminHostsQuery.flatData}
                isError={adminHostsQuery.isError}
                isLoading={adminHostsQuery.isLoading}
                isChangingPage={adminHostsQuery.isFetchingNextPage}
                onChangePage={adminHostsQuery.fetchNextPage}
                searchValue={searchParams.search}
                sorter={{
                    orderBy: searchParams.order_by,
                    orderDirection: searchParams.order_direction,
                    onSort: ({orderBy, orderDirection}) =>
                        setSearchParams({
                            order_by: orderBy,
                            order_direction: orderDirection
                        })
                }}
            />
        </StyledAdminHosts>
    )
}
