import styled, {css} from 'styled-components'
import {CloseIcon} from '../../assets/icons/CloseIcon'
import {CustomScrollbar} from '../../theme/components'
import {CSSProperties} from 'react'

export const StyledModal = styled.div(
    ({theme: {zIndex}}) => css`
        position: fixed;
        z-index: ${zIndex.popover};
        inset: 0;
    `
)

export const StyledOverlay = styled.div`
    ${({theme: {palette}}) => css`
        position: absolute;
        inset: 0;
        background: ${palette.neutral.black};
        opacity: 0.4;
    `}
`

export const StyledContent = styled.div<{$width?: CSSProperties['width']}>`
    ${({theme: {palette}, $width}) => css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: ${palette.neutral.white};
        border-radius: 12px;
        width: ${$width ?? '500px'};
        max-width: calc(100% - 16px);
        max-height: calc(100% - 16px);
        overflow-y: auto;
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {typography, spacing, colors}}) => css`
        padding: ${spacing * 5}px ${spacing * 8}px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        text-align: center;

        & h1 {
            ${typography.displayXs};
            font-weight: 700;
            color: ${colors.lightBlack};
        }
    `}
`

export const StyledBody = styled.div<{$bodyHeight: number; $overflow: CSSProperties['overflow']}>`
    ${({$bodyHeight, $overflow}) => css`
        max-height: ${$bodyHeight}px;
        overflow-y: ${$overflow ?? 'auto'};
        ${CustomScrollbar}
    `}
`
export const StyledCloseIcon = styled(CloseIcon)`
    ${({theme: {colors}}) => css`
        position: absolute;
        top: 8px;
        right: 8px;
        fill: ${colors.lightBlack};
        cursor: pointer;
    `}
`
