import {FC, HTMLAttributes} from 'react'

export const ArrowUpIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 18, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.14447 12.6804C4.46991 13.0058 4.99754 13.0058 5.32298 12.6804L9.73372 8.26966L14.1445 12.6804C14.4699 13.0058 14.9975 13.0058 15.323 12.6804C15.6484 12.355 15.6484 11.8273 15.323 11.5019L10.323 6.50189C9.99754 6.17645 9.4699 6.17645 9.14447 6.50189L4.14447 11.5019C3.81903 11.8273 3.81903 12.355 4.14447 12.6804Z"
            fill="currentcolor"
        />
    </svg>
)
