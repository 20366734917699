import {Wrapper} from './style'

export const MultiParagraphMessage = ({paragraphs}) => {
    return (
        <Wrapper>
            {paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
        </Wrapper>
    )
}
