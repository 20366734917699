import {StyledTCell, StyledTRow} from '../row/style'
import {Skeleton} from '../../../skeleton/Skeleton'

export const TableLoader = ({columns}) => {
    return Array.from(Array(4).keys()).map((row, index) => (
        <StyledTRow key={index}>
            {columns.map((column, index) => (
                <StyledTCell alignment={column.alignment} width={column.width} key={index}>
                    <Skeleton />
                </StyledTCell>
            ))}
        </StyledTRow>
    ))
}
