import {FC} from 'react'
import {Avatar} from 'src/components/avatar/Avatar.tsx'
import {useAdmins} from 'src/features/admin/services/useAdmins.ts'
import {StyledDisplayValueText} from 'src/features/admin-host-approval/components/admin-select-display-value/style.ts'
import {Tooltip} from 'src/components/tooltip/Tooltip.tsx'

export const AdminSelectDisplayValue: FC<{id: string | string[]}> = ({id}) => {
    const adminsQuery = useAdmins()
    const currentAdmin = adminsQuery.data?.find(admin => admin.id == Number(id))

    return (
        <>
            <Avatar
                size="xs"
                name={currentAdmin ? `${currentAdmin.first_name} ${currentAdmin.last_name}` : ''}
                imageUrl=""
            />
            <Tooltip
                content={`${currentAdmin?.first_name} ${currentAdmin?.last_name}`}
                rootProps={{open: !currentAdmin ? false : undefined}}
                triggerProps={{asChild: true}}
            >
                <StyledDisplayValueText>
                    {currentAdmin ? `${currentAdmin.first_name} ${currentAdmin.last_name}` : ''}
                </StyledDisplayValueText>
            </Tooltip>
        </>
    )
}
