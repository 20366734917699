import styled, {css} from 'styled-components'
import {DESKTOP_TABLE_COLUMNS_PADDING, DESKTOP_TABLE_SCROLLBAR_WIDTH} from 'src/components/table/desktop-table/style'
import {Percentage} from 'src/types.ts'

export const StyledTHead = styled.div<{$variant?: 'neutral' | 'default'}>(
    ({$variant, theme: {palette}}) => css`
        display: flex;
        height: 44px;
        padding-right: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
        ${$variant == 'neutral' &&
        css`
            background-color: ${palette.neutral['50']};
            border: 1px solid ${palette.neutral['300']};
            color: ${palette.neutral['500']};
            border-radius: 8px;
        `};
    `
)

interface StyledTHProps {
    $width: Percentage
    $alignment?: 'left' | 'center' | 'right'
    $variant?: 'neutral' | 'default'
}
export const StyledTH = styled.div<StyledTHProps>(
    ({theme: {spacing}, $width, $alignment}) => css`
        width: ${$width};
        flex: none;
        display: flex;
        justify-content: ${$alignment == 'right' ? 'flex-end' : $alignment == 'center' ? 'center' : 'flex-start'};
        align-items: center;
        gap: ${spacing}px;
        padding: 0 ${DESKTOP_TABLE_COLUMNS_PADDING};

        & > svg {
            cursor: pointer;
        }
    `
)

export const StyledTHLabel = styled.div(
    ({theme: {spacing, typography}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        overflow: hidden;

        & h3 {
            ${typography.textXs};
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)
