import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {AssignmentCard} from '../../../../../components/assignments/assignment-card/AssignmentCard'
import {Table} from '../../../../../components/table-legacy/Table'
import {Button} from '../../../../../components/button/Button'
import {AdjustmentModal} from './adjustment-modal/AdjustmentModal'
import {
    capitalize,
    copyTextToClipboard,
    formatCurrency,
    formatLocaleDate,
    formatTransactionAmount
} from '../../../../../helpers/utils'
import {getPaymentColumns} from '../columns'
import {StyledDetailCell, StyledAssignmentCardDetailsItem, StyledNoResults, StyledTransactionAmount} from '../style'
import {PAYMENT_AMOUNT_TYPES} from '../../../../private/dashboard/active-shoots-sections/my-schedule/completed/transactions-list/TransactionsList'
import {StyledAdjustmentType, StyledCopyIcon} from './style'
import {Tooltip} from '../../../../../components/tooltip-legacy/Tooltip'
import {Flexbox} from '../../../../../components/flexbox/Flexbox'
import {showAlert} from '../../../../../store/appGenerics'
import {ALERT_LEVELS} from '../../../../../helpers/constants'
import {useDispatch} from 'react-redux'

export const PaymentsCard = ({payments = [], quoteData}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [showAdjustmentModal, setShowAdjustmentModal] = useState(false)

    return (
        <AssignmentCard
            title={<h3>{t('admin:assignments:payments:title')}</h3>}
            showUpdatedAt={false}
            actions={
                <div>
                    {quoteData?.assignments?.length !== 0 && (
                        <Button onClick={() => setShowAdjustmentModal(true)} variant="tertiaryPrimary">
                            {t('admin:assignments:payments:adjustment_button')}
                        </Button>
                    )}
                    {showAdjustmentModal && (
                        <AdjustmentModal quoteData={quoteData} onClose={() => setShowAdjustmentModal(false)} />
                    )}
                </div>
            }
            details={
                <>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:quote_price')}</span>
                        <p>{quoteData?.amount ? formatCurrency(quoteData.amount, 'USD') : t('commons:free')} </p>
                    </StyledAssignmentCardDetailsItem>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:freelancer_rate')}</span>
                        <p>
                            {quoteData?.assignments?.[0]?.amount
                                ? formatCurrency(quoteData.assignments[0].amount, 'USD')
                                : '-'}
                        </p>
                    </StyledAssignmentCardDetailsItem>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:travel_compensation')}</span>
                        <p>
                            {quoteData?.assignments?.[0]?.travel_compensation
                                ? formatCurrency(quoteData?.assignments?.[0]?.travel_compensation, 'USD')
                                : '-'}
                        </p>
                    </StyledAssignmentCardDetailsItem>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:bonus')}</span>
                        <p>
                            {quoteData?.assignments?.[0]?.bonus_amount ||
                            quoteData?.assignments?.[0]?.portrait_bonus_amount
                                ? formatCurrency(
                                      (+quoteData?.assignments[0].bonus_amount ?? 0) +
                                          (+quoteData?.assignments[0].portrait_bonus_amount ?? 0),
                                      'USD'
                                  )
                                : '-'}{' '}
                        </p>
                    </StyledAssignmentCardDetailsItem>

                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:total_adjustment')}</span>
                        <p>{quoteData?.total_adjustment ? formatCurrency(quoteData?.total_adjustment, 'USD') : '-'}</p>
                    </StyledAssignmentCardDetailsItem>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:total_payins')}</span>
                        <p>{quoteData?.total_payin ? formatCurrency(quoteData?.total_payin, 'USD') : '-'}</p>
                    </StyledAssignmentCardDetailsItem>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:total_payouts')}</span>
                        <p>{quoteData?.total_payout ? formatCurrency(quoteData?.total_payout, 'USD') : '-'}</p>
                    </StyledAssignmentCardDetailsItem>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:payments:task_balance')}</span>
                        <p>{quoteData?.task_balance ? formatCurrency(quoteData?.task_balance, 'USD') : '-'}</p>
                    </StyledAssignmentCardDetailsItem>
                </>
            }
            tableTitle={t('admin:assignments:payments:table_title')}
            table={
                payments?.length >= 1 ? (
                    <Table
                        tbodyHeight="224px"
                        columns={getPaymentColumns()}
                        data={payments}
                        variant="grey"
                        columnsRenderers={{
                            creation_date: payment =>
                                payment.created_at ? formatLocaleDate(payment.created_at, 'lll') : '-',
                            payment_date: payment =>
                                payment.payment_date ? formatLocaleDate(payment.payment_date, 'lll') : '-',
                            type: payment => {
                                if (payment?.payment_type === 'adjustment') {
                                    let adjustmentLabel = payment?.payment_type

                                    if (payment?.payout_amount && payment?.photographer_id) {
                                        adjustmentLabel = t('admin:assignments:payments:send_money_to_freelancer')
                                    }
                                    if (payment?.payin_amount && payment?.photographer_id) {
                                        adjustmentLabel = t('admin:assignments:payments:recover_money_from_freelancer')
                                    }
                                    if (payment?.payout_amount && payment?.host_id) {
                                        adjustmentLabel = t('admin:assignments:payments:send_money_to_host')
                                    }
                                    if (payment?.payin_amount && payment?.host_id) {
                                        adjustmentLabel = t('admin:assignments:payments:recover_money_from_host')
                                    }

                                    return (
                                        <StyledAdjustmentType $wrapText>
                                            <div>
                                                <p title={adjustmentLabel}>{adjustmentLabel}</p>
                                            </div>
                                            <Tooltip
                                                open={!payment?.adjustment_reason ? false : undefined}
                                                content={<small>{payment?.adjustment_reason}</small>}
                                            >
                                                <small title={payment?.admin}>
                                                    {payment?.adjustment_reason ?? '-'}
                                                </small>
                                            </Tooltip>
                                        </StyledAdjustmentType>
                                    )
                                }

                                return (
                                    <StyledDetailCell title={capitalize(payment?.payment_type)}>
                                        {capitalize(payment?.payment_type)}
                                    </StyledDetailCell>
                                )
                            },
                            freelancer: payment => {
                                return payment?.external_photographer_id || payment?.host_name ? (
                                    <StyledAdjustmentType>
                                        <p>{payment?.photographer_name ?? '-'}</p>
                                        <Flexbox align="center">
                                            <Tooltip
                                                content={<small>{payment?.external_photographer_id}</small>}
                                                open={!payment?.external_photographer_id ? false : undefined}
                                            >
                                                <small>
                                                    {t('commons:airbnb_id')}
                                                    {': '}
                                                    {payment?.external_photographer_id ?? '-'}
                                                </small>
                                            </Tooltip>
                                            {!!payment?.external_photographer_id && (
                                                <StyledCopyIcon
                                                    fill="currentColor"
                                                    height={14}
                                                    onClick={async () => {
                                                        await copyTextToClipboard(payment?.external_photographer_id)
                                                        dispatch(
                                                            showAlert({
                                                                message: t('admin:id_copied'),
                                                                level: ALERT_LEVELS.SUCCESS
                                                            })
                                                        )
                                                    }}
                                                />
                                            )}
                                        </Flexbox>
                                    </StyledAdjustmentType>
                                ) : (
                                    '-'
                                )
                            },
                            host: payment => {
                                return payment?.external_photographer_id || payment?.host_name ? (
                                    <StyledAdjustmentType>
                                        <p>{payment?.host_name ?? '-'}</p>
                                        <Flexbox align="center">
                                            <Tooltip
                                                content={<small>{payment?.external_host_id}</small>}
                                                open={!payment?.external_host_id ? false : undefined}
                                            >
                                                <small>
                                                    {t('commons:airbnb_id')}
                                                    {': '}
                                                    {payment?.external_host_id ?? '-'}
                                                </small>
                                            </Tooltip>
                                            {!!payment?.external_host_id && (
                                                <StyledCopyIcon
                                                    fill="currentColor"
                                                    height={14}
                                                    onClick={async () => {
                                                        await copyTextToClipboard(payment?.external_host_id)
                                                        dispatch(
                                                            showAlert({
                                                                message: t('admin:id_copied'),
                                                                level: ALERT_LEVELS.SUCCESS
                                                            })
                                                        )
                                                    }}
                                                />
                                            )}
                                        </Flexbox>
                                    </StyledAdjustmentType>
                                ) : (
                                    '-'
                                )
                            },
                            payin: payment =>
                                payment?.payin_amount ? (
                                    <StyledTransactionAmount type={PAYMENT_AMOUNT_TYPES.POSITIVE}>
                                        {formatTransactionAmount(
                                            payment?.payin_photographer_currency_amount,
                                            payment?.payin_amount,
                                            payment?.payin_photographer_currency
                                        )}
                                    </StyledTransactionAmount>
                                ) : (
                                    '-'
                                ),
                            payout: payment =>
                                payment?.payout_amount ? (
                                    <StyledTransactionAmount type={PAYMENT_AMOUNT_TYPES.NEGATIVE}>
                                        {formatTransactionAmount(
                                            payment?.payout_photographer_currency_amount,
                                            payment?.payout_amount,
                                            payment?.payout_photographer_currency
                                        )}
                                    </StyledTransactionAmount>
                                ) : (
                                    '-'
                                )
                        }}
                    />
                ) : (
                    <StyledNoResults>{t('admin:no_transactions')}</StyledNoResults>
                )
            }
        />
    )
}

PaymentsCard.propTypes = {
    payments: PropTypes.arrayOf(
        PropTypes.shape({
            quote_price: PropTypes.string,
            rate: PropTypes.string,
            travel_compensation: PropTypes.string,
            bonus: PropTypes.string,
            total_adjustment: PropTypes.string,
            total_payins: PropTypes.string,
            total_payout: PropTypes.string,
            task_balance: PropTypes.string,
            statusUpdatedAt: PropTypes.string,
            status: PropTypes.string
        })
    ),
    quoteData: PropTypes.object // too complex
}
