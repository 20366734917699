import {
    SelectComboboxInput,
    SelectComboboxList,
    SelectComboboxPopover
} from 'src/components/select-atoms/SelectAtoms.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText.tsx'
import {ComboboxItem, SelectPopoverProps, useComboboxContext} from '@ariakit/react'
import {Avatar} from 'src/components/avatar/Avatar.tsx'
import {useAdmins} from 'src/features/admin/services/useAdmins.ts'
import {raise} from 'src/helpers/helpers.ts'
import {useTranslation} from 'react-i18next'
import {StyledSelectItem} from 'src/features/admin-host-approval/components/admin-select-popover/style.ts'

export const AdminSelectPopover = (props: SelectPopoverProps) => {
    const {t} = useTranslation()
    const adminsQuery = useAdmins()
    const comboboxContext = useComboboxContext() ?? raise('SelectComboboxList must be a child of SelectCombobox')
    const searchValue = comboboxContext.useState('value').trim()
    const filteredAdmins =
        adminsQuery.data?.filter(admin =>
            `${admin.first_name} ${admin.last_name}`.toLowerCase().includes(searchValue.toLowerCase())
        ) ?? []

    return (
        <SelectComboboxPopover portal sameWidth={false} {...props}>
            <SelectComboboxInput autoSelect />
            {adminsQuery.isLoading ? (
                <Flexbox justify="center">
                    <Spinner size={20} />
                </Flexbox>
            ) : adminsQuery.isError ? (
                <Flexbox justify="center">
                    <InputHelpText error={t('errors:default')} />
                </Flexbox>
            ) : (
                <SelectComboboxList>
                    {filteredAdmins.map(admin => (
                        <StyledSelectItem value={admin.id.toString()} key={admin.id} render={<ComboboxItem />}>
                            <Avatar size="sm" name={`${admin.first_name} ${admin.last_name}`} imageUrl="" />
                            <span>
                                {admin.first_name} {admin.last_name}
                            </span>
                        </StyledSelectItem>
                    ))}
                </SelectComboboxList>
            )}
        </SelectComboboxPopover>
    )
}
