import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {dayjsLocalizer} from 'react-big-calendar'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {Event} from '../event/Event'
import {Toolbar} from './toolbar/Toolbar'
import {Modal} from './modal/Modal'
import {languageSelector} from '../../../store/appGenerics'
import {StyledCalendarContainer} from './style'
import {formatLocaleDate} from '../../../helpers/utils'
import {Spinner} from '../../spinner-legacy/Spinner'
import {RefreshCalendarMessage} from '../refresh-calendar-message/RefreshCalendarMessage'

export const DesktopCalendar = ({
    events,
    isCurrentMonthLoading,
    loadingRange,
    isError,
    fetchedMonths,
    onChangeRange
}) => {
    const {t} = useTranslation()
    const currentLang = useSelector(languageSelector)
    const [localizer, setLocalizer] = useState(dayjsLocalizer(dayjs))
    const [modal, setModal] = useState({
        show: false,
        selectedDate: null,
        selectedEvents: []
    })

    useEffect(() => {
        setLocalizer(dayjsLocalizer(dayjs))
    }, [currentLang])

    const {views, formats, components} = useMemo(
        () => ({
            components: {
                event: Event,
                toolbar: ({date, onNavigate}) =>
                    Toolbar({
                        date,
                        onNavigate,
                        onChangeRange,
                        loadingRange,
                        fetchedMonths
                    })
            },
            views: ['month'],
            formats: {
                dateFormat: 'D'
            }
        }),
        [fetchedMonths, loadingRange]
    )

    const onSelectedEvent = event => {
        const currentEvent = formatLocaleDate(event.start)
        setModal({
            show: true,
            selectedEvents: events.filter(ev => formatLocaleDate(ev.start) === currentEvent),
            selectedDate: event.start
        })
    }

    const onShowMore = events => {
        setModal({
            show: true,
            selectedEvents: events,
            selectedDate: events[0].start
        })
    }

    return (
        <div style={{position: 'relative'}}>
            {isCurrentMonthLoading && <Spinner overlay />}
            {isError && <RefreshCalendarMessage />}
            <StyledCalendarContainer
                components={components}
                localizer={localizer}
                events={events}
                views={views}
                formats={formats}
                onSelectEvent={onSelectedEvent}
                onShowMore={onShowMore}
                messages={{
                    noEventsInRange: t('calendar:no_events_in_range'),
                    showMore: total => t('calendar:show_more', {count: total})
                }}
            />
            {modal.show && (
                <Modal
                    events={modal.selectedEvents}
                    date={modal.selectedDate}
                    onClose={() =>
                        setModal({
                            show: false,
                            selectedDate: null,
                            selectedEvents: []
                        })
                    }
                />
            )}
        </div>
    )
}

DesktopCalendar.propTypes = {
    events: PropTypes.arrayOf({
        allDay: PropTypes.bool,
        title: PropTypes.node,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        resource: PropTypes.object
    }).isRequired,
    isCurrentMonthLoading: PropTypes.bool,
    loadingRange: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string
    }),
    isError: PropTypes.bool,
    fetchedMonths: PropTypes.arrayOf(PropTypes.string),
    onChangeRange: PropTypes.func
}
