import styled, {css} from 'styled-components'

export const ErrorPage = styled.div`
    height: 100vh;
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 32px;
`

export const StyledLogo = styled.h1(
    ({theme: {typography, colors}}) => css`
        ${typography.textLg};
        font-weight: 800;
        color: ${colors.lightBlack};
    `
)

export const ErrorWrapper = styled.div(
    ({theme: {colors, mediaQuery, palette}}) => css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        color: ${colors.red};

        & svg {
            width: 100px;
            height: 100px;
        }
        & h2 {
            font-size: 56px;
            font-weight: 700;
            text-align: center;
            color: ${palette.neutral.black};
        }

        ${mediaQuery.MD} {
            & svg {
                width: 72px;
                height: 72px;
            }
            & h2 {
                font-size: 40px;
            }
        }
    `
)

export const Button = styled.button(
    ({theme: {transition, colors, mediaQuery}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: max-content;
        max-width: 100%;
        padding: 0 10px;
        border-radius: 8px;
        background: transparent;
        border: 1px solid #e5e5e5;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGrey};
        ${transition};

        &:hover {
            border: 1px solid ${colors.darkGrey};
        }

        ${mediaQuery.XS} {
            width: 100%;
        }
    `
)
