import {Badge} from 'src/components/badge/Badge.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {
    StyledStatusTab,
    StyledStatusTabUnderline
} from 'src/features/admin-host-approval/components/status-tab/style.ts'
import {FC, ReactNode} from 'react'
import {To} from 'react-router-dom'

interface StatusTabProps {
    counter?: number
    to: To
    label: ReactNode
}
export const StatusTab: FC<StatusTabProps> = ({counter, to, label}) => {
    return (
        <StyledStatusTab to={to}>
            {({isActive}) => (
                <>
                    {label}
                    <Badge
                        variant={isActive ? 'grayModernDark' : 'neutral'}
                        shape={counter && counter >= 10 ? 'roundedRectangle' : 'circle'}
                    >
                        {counter ?? <Spinner />}
                    </Badge>
                    <StyledStatusTabUnderline $isActive={isActive} />
                </>
            )}
        </StyledStatusTab>
    )
}
