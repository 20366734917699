import {useState} from 'react'
import {StyledScore, StyledWrapper} from './style'
import {useTheme} from 'styled-components'
import PropTypes from 'prop-types'

export const BotRating = ({options, triggerNextStep}) => {
    const [selectedRating, setSelectedRating] = useState(null)
    const theme = useTheme()

    const valueColorMap = {
        1: theme.colors.ratingRed,
        2: theme.colors.ratingOrange,
        3: theme.colors.ratingYellow,
        4: theme.colors.ratingLightGreen,
        5: theme.colors.ratingGreen
    }

    const onOptionClick = rating => {
        setSelectedRating(rating)
        triggerNextStep({value: rating?.value, trigger: rating?.trigger})
    }

    return (
        <StyledWrapper>
            {options?.map(e => (
                <StyledScore
                    selectedRating={selectedRating}
                    valueColor={valueColorMap[e?.value]}
                    onClick={selectedRating ? Function?.prototype : () => onOptionClick(e)}
                    key={e.value}
                >
                    {e?.icon}
                    <small>{e.label}</small>
                </StyledScore>
            ))}
        </StyledWrapper>
    )
}

BotRating.propTypes = {
    options: PropTypes.array,
    triggerNextStep: PropTypes.func
}
