import {useTranslation} from 'react-i18next'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {ModalTitle} from 'src/components/modal-atoms/ModalAtoms'
import {Modal} from 'src/components/modal/Modal'
import {StyledAdminHostApprovalUploadModalBody, StyledAdminHostApprovalUploadModalParagraph} from './style'
import {ProgressBar} from 'src/components/progress-bar/ProgressBar'

export const AdminHostApprovalTaskUploadModal = ({
    uploadingImagesCounter,
    progress
}: {
    progress: number
    uploadingImagesCounter: number
}) => {
    const {t} = useTranslation()

    return (
        <Modal width={480}>
            <StyledAdminHostApprovalUploadModalBody>
                <Flexbox direction="column" align="center" justify="center" gap={6}>
                    <Flexbox direction="column" gap={2} align="center">
                        <ModalTitle>
                            {t('host_approval_task:upload_modal:title', {count: uploadingImagesCounter})}
                        </ModalTitle>
                        <StyledAdminHostApprovalUploadModalParagraph>
                            {t('host_approval_task:upload_modal:uploading_image', {
                                count: uploadingImagesCounter
                            })}
                        </StyledAdminHostApprovalUploadModalParagraph>
                    </Flexbox>
                    <ProgressBar progressValue={progress} />
                </Flexbox>
            </StyledAdminHostApprovalUploadModalBody>
        </Modal>
    )
}
