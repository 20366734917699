import {useState} from 'react'
import {debounce} from 'lodash-es'
import {useQuery} from '@tanstack/react-query'
import {errorHandler} from '../../helpers/utils'
import {httpSearchAssignment} from '../../http-requests/admin/assignments.http'
import {QUERY_KEYS} from '../../queryClient'
import {useUserStore} from '../../features/user/store'

export const useAdminSearchAssignment = () => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [searchError, setSearchError] = useState(null)
    const [search, setSearch] = useState(null)

    const onSearch = debounce(value => {
        if (value?.length >= 3) {
            setSearch(value)
            setSearchError(null)
        } else if (!value?.length) {
            setSearch(null)
            setSearchError(null)
        } else {
            setSearchError('errors:search_invalid_length')
        }
    }, 600)

    const query = useQuery({
        queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE, search],
        queryFn: async () => {
            const {data: payload} = await httpSearchAssignment(adminAccessToken, search)
            return payload
        },
        onError: errorHandler,
        enabled: !!search
    })

    return {
        ...query,
        search,
        onSearch,
        searchError
    }
}
