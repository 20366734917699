import {Flexbox} from 'src/components/flexbox/Flexbox'
import {compensationKeyToLabel} from 'src/features/assignment/utils'
import {
    StyledCompensationAmount,
    StyledCompensationRow
} from 'src/features/assignment/components/assignment-compensation-card/style'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {Compensation} from 'src/features/assignment/types'
import {Tooltip} from 'src/components/tooltip/Tooltip'
import {CurrencyDollarIcon, HelpCircleIcon} from 'src/components/icon'
import {useTheme} from 'styled-components'
import i18next from 'i18next'
import {formatLocalePrice} from 'src/helpers/helpers'

interface AssignmentCompensationRowProps {
    currency: Compensation['currency']
    compensationKey: Compensation['compensationKey']
    amount: number
    size?: 'sm' | 'md'
    isBoldAmount?: boolean
    tooltip?: string
}

export const AssignmentCompensationRow: FC<AssignmentCompensationRowProps> = ({
    currency,
    compensationKey,
    amount,
    size = 'sm',
    isBoldAmount = false,
    tooltip
}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    return (
        <StyledCompensationRow justify="space-between" $size={size}>
            <Flexbox gap={2} align="center">
                <CurrencyDollarIcon />
                <Flexbox gap={1}>
                    <p>{t(compensationKeyToLabel[compensationKey])}</p>
                    {!!tooltip && (
                        <Tooltip variant="light" content={<p>{tooltip}</p>}>
                            <HelpCircleIcon color={palette.neutral[400]} size={16} />
                        </Tooltip>
                    )}
                </Flexbox>
            </Flexbox>
            <StyledCompensationAmount $isBold={isBoldAmount}>
                {formatLocalePrice({locale: i18next.language, currency, amount})}
            </StyledCompensationAmount>
        </StyledCompensationRow>
    )
}
