import * as Yup from 'yup'
import i18n from '../../../../../../translations/i18n'

export const ForceMatchModalSchema = Yup.object().shape({
    photographer: Yup.object().required(),
    ignore_photographer_market: Yup.bool(),
    ignore_photographer_previous_rejection: Yup.bool(),
    set_a_manual_rate: Yup.bool(),
    rate: Yup.number().when('set_a_manual_rate', {
        is: true,
        then: Yup.number()
            .positive()
            .typeError(i18n.t('errors:number_invalid'))
            .test('maxDigitsAfterDecimal', i18n.t('errors:number_invalid'), amount =>
                /^[0-9]+(?:\.[0-9]{1,2})?$/.test(amount)
            )
    }),
    notes: Yup.string().nullable()
})
