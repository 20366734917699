import {ComponentProps, CSSProperties, HTMLAttributes} from 'react'
import {SupportedHTMLElements} from 'styled-components/dist/types'
import {StyledFlexbox} from './style.ts'

export type FlexboxProps<T extends SupportedHTMLElements> = {
    render?: T
    justify?: CSSProperties['justifyContent']
    align?: CSSProperties['alignItems']
    direction?: CSSProperties['flexDirection']
    gap?: number
    fullWidth?: boolean
} & HTMLAttributes<T>

export const Flexbox = <T extends SupportedHTMLElements>({
    justify,
    align,
    direction,
    gap,
    render,
    fullWidth,
    ...rest
}: FlexboxProps<T>) => {
    return (
        <StyledFlexbox
            {...(rest as Omit<ComponentProps<T>, keyof FlexboxProps<T> | 'as' | 'ref'>)}
            as={render ?? 'div'}
            $justify={justify}
            $align={align}
            $direction={direction}
            $gap={gap}
            $fullWidth={fullWidth}
        />
    )
}
Flexbox.displayName = 'Flexbox'
