import {SelectProvider, useSelectContext} from '@ariakit/react'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {SelectItem, SelectList, SelectPopover, SelectTrigger} from 'src/components/select-atoms/SelectAtoms'
import {Spinner} from 'src/components/spinner/Spinner'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText'
import {useTranslation} from 'react-i18next'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {useAccessibilityFeatures} from 'src/features/admin-host-approval/services/useAccessibilityFeatures'
import {useUpdateHostApprovalTaskImage} from 'src/features/admin-host-approval/services/useUpdateHostApprovalTaskImage'
import {raise} from 'src/helpers/helpers'
import {StyledHostApprovalImageAccessibilityTriggerText} from 'src/features/admin-host-approval/components/admin-host-approval-image-accessibility-selector/style'

export const AdminHostApprovalImageAccessibilitySelector = ({
    taskId,
    imageId,
    amenityId
}: {
    taskId: number
    imageId: number
    amenityId: AdminHostApprovalTaskImage['amenity_id']
}) => {
    const {t} = useTranslation()
    const {data: accessibilityFeatures, isLoading, isError} = useAccessibilityFeatures({staleTime: Infinity})
    const {mutate: updateImage} = useUpdateHostApprovalTaskImage({
        taskId,
        imageId
    })

    return (
        <SelectProvider
            defaultValue={amenityId?.toString() ?? ''}
            setValue={value => updateImage({amenity_id: +value})}
        >
            <Flexbox direction="column" gap={2} fullWidth>
                <AdminHostApprovalImageAccessibilityTrigger />
                <SelectPopover portal sameWidth={false}>
                    {isLoading ? (
                        <Flexbox justify="center">
                            <Spinner size={20} />
                        </Flexbox>
                    ) : isError ? (
                        <Flexbox justify="center">
                            <InputHelpText error={t('errors:default')} />
                        </Flexbox>
                    ) : (
                        <SelectList>
                            {accessibilityFeatures.map(accessibilityFeature => (
                                <SelectItem key={accessibilityFeature.id} value={accessibilityFeature.id.toString()}>
                                    {accessibilityFeature.name}
                                </SelectItem>
                            ))}
                        </SelectList>
                    )}
                </SelectPopover>
            </Flexbox>
        </SelectProvider>
    )
}

const AdminHostApprovalImageAccessibilityTrigger = () => {
    const {t} = useTranslation()
    const {data: amenities} = useAccessibilityFeatures({enabled: false})
    const selectContext = useSelectContext() ?? raise('Select must be a child of SelectCombobox')
    const selectValue = selectContext.useState('value') as string //as needed because it's a single select and the provided type is string | string[]

    return (
        <SelectTrigger
            placeholder={t('host_approval_task:select_accessibility_feature_placeholder')}
            fullWidth
            value={selectValue}
            displayValue={value => (
                <StyledHostApprovalImageAccessibilityTriggerText>
                    {amenities?.find(amenity => amenity.id.toString() == value)?.name}
                </StyledHostApprovalImageAccessibilityTriggerText>
            )}
        />
    )
}
