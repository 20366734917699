import {SelectProvider} from '@ariakit/react'
import {Label} from 'src/components/label/Label.tsx'
import {SelectItem, SelectList, SelectPopover, SelectTrigger} from 'src/components/select-atoms/SelectAtoms.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText.tsx'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {CreateTaskValidationSchema} from 'src/features/admin-host-approval/components/create-task-modal/CreateTaskModal.tsx'
import {useTheme} from 'styled-components'
import {useAccessibilityProjects} from 'src/features/admin-host-approval/services/useAccessibilityProjects.ts'

export const ProjectField = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const form = useFormContext<CreateTaskValidationSchema>()
    const adminHostApprovalProjectsQuery = useAccessibilityProjects()

    return (
        <Controller
            control={form.control}
            name="accessibility_project"
            render={({field: {value, onChange}}) => (
                <SelectProvider setValue={onChange} value={value?.toString()} defaultValue="">
                    <Flexbox direction="column" gap={2} fullWidth>
                        <Label>{t('commons:project')}</Label>
                        <SelectTrigger
                            placeholder={t('admin_host_approval:project_field_placeholder')}
                            fullWidth
                            value={value?.toString() ?? ''}
                            displayValue={value =>
                                adminHostApprovalProjectsQuery.data?.find(project => project.id.toString() == value)
                                    ?.name
                            }
                        />
                        <SelectPopover portal style={{zIndex: theme.zIndex.modal}}>
                            {adminHostApprovalProjectsQuery.isLoading ? (
                                <Flexbox justify="center">
                                    <Spinner size={20} />
                                </Flexbox>
                            ) : adminHostApprovalProjectsQuery.isError ? (
                                <Flexbox justify="center">
                                    <InputHelpText error={t('errors:default')} />
                                </Flexbox>
                            ) : (
                                <SelectList>
                                    {adminHostApprovalProjectsQuery.data.map(project => (
                                        <SelectItem value={project.id.toString()} key={project.key_name}>
                                            {project.name}
                                        </SelectItem>
                                    ))}
                                </SelectList>
                            )}
                        </SelectPopover>
                        <InputHelpText error={form.formState.errors.accessibility_project?.message} />
                    </Flexbox>
                </SelectProvider>
            )}
        />
    )
}
