import {ToUploadList} from './to-upload-list/ToUploadList'
import {StyledLeftColumn, StyledRightColumn} from './style'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    amenitiesAutocompleteSelector,
    roomsAutocompleteSelector,
    roomsListSelector,
    setAmenitiesAutocomplete,
    setRoomsAutocomplete
} from '../../../../../store/matterport'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {
    httpGetAmenitiesAutocomplete,
    httpGetRoomsAutocomplete
} from '../../../../../http-requests/admin/matterport.http'
import {errorHandler} from '../../../../../helpers/utils'
import {useUserStore} from '../../../../../features/user/store'
import {ToDeleteList} from './to-delete-list/ToDeleteList'

export const ConfirmView = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const roomsAutocomplete = useSelector(roomsAutocompleteSelector)
    const amenitiesAutocomplete = useSelector(amenitiesAutocompleteSelector)
    const roomsList = useSelector(roomsListSelector)
    const translatedRoomsList = roomsList.map(room => {
        const roomName = roomsAutocomplete?.find(e => e.id === room.roomId)?.internal_name?.toLowerCase()

        return {
            ...room,
            label: `${t(`admin:rooms:${roomName}`)} ${room.number || ''}`
        }
    })
    const translatedAmenitiesList = amenitiesAutocomplete.map(amenity => ({
        ...amenity,
        label: t(`admin:amenities:${amenity.label?.toLowerCase()}`)
    }))

    useEffect(() => {
        getRoomsAutocomplete()
        getAmenitiesAutocomplete()
    }, [])

    const getRoomsAutocomplete = async () => {
        try {
            const {data} = await httpGetRoomsAutocomplete(adminAccessToken)
            dispatch(setRoomsAutocomplete(data))
        } catch (e) {
            errorHandler(e)
        }
    }

    const getAmenitiesAutocomplete = async () => {
        try {
            const {data} = await httpGetAmenitiesAutocomplete(adminAccessToken)
            dispatch(setAmenitiesAutocomplete(data))
        } catch (e) {
            errorHandler(e)
        }
    }

    return (
        <div className="container">
            <div className="row">
                <StyledLeftColumn className="col-6">
                    <ToDeleteList rooms={translatedRoomsList} amenities={translatedAmenitiesList} />
                </StyledLeftColumn>
                <StyledRightColumn className="col-6">
                    <ToUploadList rooms={translatedRoomsList} amenities={translatedAmenitiesList} />
                </StyledRightColumn>
            </div>
        </div>
    )
}

ConfirmView.propTypes = {
    subheaderHeight: PropTypes.number
}
