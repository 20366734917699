import ContentLoader, {IContentLoaderProps} from 'react-content-loader'
import {useTheme} from 'styled-components'

export const Skeleton = ({
    height = 24,
    width = '100%',
    style
}: {
    height?: IContentLoaderProps['height']
    width?: IContentLoaderProps['width']
    style?: IContentLoaderProps['style']
}) => {
    const theme = useTheme()

    return (
        <ContentLoader
            height={height}
            width={width}
            style={style}
            foregroundColor={theme.palette.neutral['100']}
            backgroundColor={theme.palette.neutral['200']}
        >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
    )
}
Skeleton.displayName = 'Skeleton'
