import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router'
import {httpConfirmUpload} from '../../../../../http-requests/admin/matterport.http'
import {errorHandler} from '../../../../../helpers/utils'
import {Spinner} from '../../../../../components/spinner-legacy/Spinner'
import {MATTERPORT_STATUSES, ROUTES} from '../../../../../helpers/constants'
import {AdminPopover} from '../../../../../components/admin-popover/AdminPopover'
import {useUserStore} from '../../../../../features/user/store'

export const ConfirmModal = ({setShowConfirmModalCb}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [isLoading, setIsLoading] = useState(false)

    const onClose = useCallback(() => setShowConfirmModalCb(false), [])

    const onSubmit = async () => {
        try {
            setIsLoading(true)
            await httpConfirmUpload(adminAccessToken, taskId)
            navigate(ROUTES.ADMIN_MATTERPORT, {
                state: MATTERPORT_STATUSES.UPLOAD_MEDIABOX
            })
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <AdminPopover
            onClose={!isLoading ? onClose : Function.prototype}
            title={t('admin:youre_confirm')}
            buttonLabel={t('commons:confirm')}
            callback={onSubmit}
            hasActions={!isLoading}
            hasCloseButton={!isLoading}
        >
            <div className="position-relative">{isLoading && <Spinner center={false} overlay={false} />}</div>
        </AdminPopover>
    )
}
