import {z} from 'zod'

export const ResponseType = z.enum(['small', 'regular', 'extended'])
export type ResponseType = z.infer<typeof ResponseType>

export const ResponseTypeParam = z.object({response_type: ResponseType})
export type ResponseTypeParam = z.infer<typeof ResponseTypeParam>

export const PaginationParams = z.object({
    current_page: z.number(),
    from: z.number().catch(0),
    last_page: z.number(),
    per_page: z.coerce.number(),
    total: z.number(),
    to: z.number().catch(0)
})
export type PaginationParams = z.infer<typeof PaginationParams>

export const Order = z.object({
    order_by: z.string().min(1),
    order_direction: z.enum(['asc', 'desc'])
})
export type Order = z.infer<typeof Order>

export type Percentage = `${number}%`
