import styled, {css} from 'styled-components'

export const SelectsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 12}px;
    `}
`

export const SelectCountriesWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 6}px;
        margin-bottom: ${spacing * 8}px;
        ${mediaQuery.SM} {
            display: block;
            & > *:not(:last-child) {
                margin-bottom: ${spacing * 8}px;
            }
        }
    `}
`
