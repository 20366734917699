import styled, {css} from 'styled-components'

export const StyledTransactionsStatsCard = styled.div(
    ({theme: {colors, spacing, typography, palette}}) => css`
        display: flex;
        flex-direction: column;
        min-width: 200px;
        background: ${palette.neutral.white};
        border: 1px solid ${colors.lightGrey};
        border-radius: 12px;
        padding: ${spacing * 4}px;
        gap: ${spacing}px;
        & > p {
            font-weight: 500;
            ${typography.textSm};
            color: ${colors.darkGrey};
        }

        & > h6 {
            font-weight: 600;
            ${typography.displayXs};
            margin: 0;
        }
    `
)
