import styled, {css} from 'styled-components'

export const StyledOption = styled.div<{$isActive: boolean}>(
    ({theme: {spacing, typography, palette}, $isActive}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        width: 100%;
        border-radius: 12px;
        padding: ${spacing * 3}px ${spacing * 4}px;
        ${typography.textSm};
        border: 1px solid ${$isActive ? palette.primary['600'] : palette.neutral['300']};
        background-color: ${$isActive ? palette.primary['50'] : palette.neutral.white};
        cursor: pointer;

        & h4 {
            font-weight: 500;
        }
        & p {
            color: ${palette.neutral['700']};
        }
    `
)
