import styled, {css} from 'styled-components'
import {Checkbox} from '../../../../../../components/checkbox-legacy/Checkbox'

export const FooterWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 2.5}px;
        padding: ${spacing * 6}px;
    `
)

export const InputSectionWrapper = styled.div(
    ({theme: {spacing, colors}}) => css`
        padding: ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
        border-bottom: 1px solid ${colors.smokeGrey};
    `
)

export const CheckboxSectionWrapper = styled.div(
    ({theme: {spacing, colors}}) => css`
        padding: ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        border-bottom: 1px solid ${colors.smokeGrey};
    `
)

export const StyledTitleSection = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        padding: ${spacing * 6}px;
    `
)

export const StyledTitle = styled.h3(
    ({theme: {typography, colors}}) => css`
        ${typography.textXl};
        font-weight: 700;
        color: ${colors.lightBlack};
    `
)

export const StyledSubTitle = styled.h4(
    ({theme: {typography, colors}}) => css`
        ${typography.textSm};
        font-weight: 400;
        color: ${colors.steelGrey};
    `
)

export const StyledLabel = styled.label(
    ({theme: {typography, colors}}) => css`
        ${typography.textMd};
        color: ${colors.lightBlack};
        font-weight: 600;
    `
)

export const TextAreaWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `
)

export const StyledOptionContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5};
        padding: ${spacing * 1.5}px ${spacing * 2}px;
    `
)

export const StyledName = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 400;
        color: ${palette.neutral['900']};
    `
)

export const StyledCurrency = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 400;
        color: ${palette.neutral['400']};
    `
)

export const StyledRegion = styled.p(
    ({theme: {typography, colors}}) => css`
        ${typography.textSm};
        font-weight: 400;
        color: ${colors.darkGrey};
    `
)

export const StyledEmail = styled.p(
    ({theme: {typography, colors}}) => css`
        ${typography.textXs};
        font-weight: 400;
        color: ${colors.darkGrey};
    `
)

export const SelectWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `
)

export const StyledSelectLabel = styled.label(
    ({theme: {typography, colors}}) => css`
        ${typography.textSm};
        font-weight: 600;
        color: ${colors.steelGrey};
    `
)

export const StyledCheckbox = styled(Checkbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 2}px 0;
    `
)
