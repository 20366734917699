import styled, {css} from 'styled-components'

export const StyledIcon = styled.div`
    cursor: pointer;
    position: relative;
`

export const StyledMenu = styled.div`
    ${({theme: {colors, shadows}}) => css`
        border: 1px solid ${colors.transparentBlack};
        border-radius: 8px;
        box-shadow: ${shadows.grey};
    `}
`
export const StyledMenuItem = styled.div`
    ${({theme: {spacing, colors, typography, palette}}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ${spacing * 4}px;
        padding: ${spacing * 3}px ${spacing * 6}px;
        background: ${palette.neutral.white};
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral.black};
        cursor: pointer;
        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        &:hover {
            background: ${colors.lightGrey};
        }
        &:focus-visible {
            outline: none;
            background: ${colors.lightGrey};
        }
    `}
`
