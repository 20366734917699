import {FC, HTMLAttributes} from 'react'

export const PriceIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 24, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0001 0C10.4603 0 10.8334 0.373096 10.8334 0.833333V3.33333H14.1667C14.627 3.33333 15.0001 3.70643 15.0001 4.16667C15.0001 4.6269 14.627 5 14.1667 5H10.8334V9.16667H12.0834C13.078 9.16667 14.0318 9.56175 14.7351 10.265C15.4383 10.9683 15.8334 11.9221 15.8334 12.9167C15.8334 13.9112 15.4383 14.8651 14.7351 15.5683C14.0318 16.2716 13.078 16.6667 12.0834 16.6667H10.8334V19.1667C10.8334 19.6269 10.4603 20 10.0001 20C9.53984 20 9.16675 19.6269 9.16675 19.1667V16.6667H5.00008C4.53984 16.6667 4.16675 16.2936 4.16675 15.8333C4.16675 15.3731 4.53984 15 5.00008 15H9.16675V10.8333H7.91675C6.92219 10.8333 5.96836 10.4382 5.2651 9.73498C4.56184 9.03172 4.16675 8.0779 4.16675 7.08333C4.16675 6.08877 4.56184 5.13494 5.2651 4.43168C5.96836 3.72842 6.92219 3.33333 7.91675 3.33333H9.16675V0.833333C9.16675 0.373096 9.53984 0 10.0001 0ZM9.16675 5H7.91675C7.36421 5 6.83431 5.21949 6.44361 5.61019C6.05291 6.00089 5.83341 6.5308 5.83341 7.08333C5.83341 7.63587 6.05291 8.16577 6.44361 8.55647C6.83431 8.94717 7.36421 9.16667 7.91675 9.16667H9.16675V5ZM10.8334 10.8333V15H12.0834C12.6359 15 13.1659 14.7805 13.5566 14.3898C13.9473 13.9991 14.1667 13.4692 14.1667 12.9167C14.1667 12.3641 13.9473 11.8342 13.5566 11.4435C13.1659 11.0528 12.6359 10.8333 12.0834 10.8333H10.8334Z"
            fill="currentColor"
        />
    </svg>
)
