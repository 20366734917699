import {Checkbox} from 'src/components/checkbox/Checkbox'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAssignmentShootlist = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        width: 100%;
        padding: 0 ${spacing * 2}px;
    `
)

export const StyleAssignmentShootlistCheckbox = styled(Checkbox)(
    ({theme: {typography}}) => css`
        & label {
            ${typography.textMd};
        }
    `
)

export const StyledAssignmentShootlistItem = styled(Flexbox)(
    ({theme: {typography, mediaQueries}}) => css`
        width: 100%;
        white-space: nowrap;

        & .assignment-shootlist-checkbox label {
            ${typography.textMd};
        }

        & .assignment-shootlist-checkbox small {
            display: none;
        }

        ${mediaQueries.m} {
            & .assignment-shootlist-checkbox small {
                display: block;
            }
        }
    `
)

export const StyledDashedDivider = styled.span(
    ({theme: {palette, mediaQueries}}) => css`
        background-image: linear-gradient(to right, ${palette.neutral[300]} 33%, ${palette.neutral.white} 0%);
        background-position: bottom;
        background-size: 8px 1px;
        background-repeat: repeat-x;
        height: 1px;
        width: 100%;

        ${mediaQueries.m} {
            display: none;
        }
    `
)

export const StyledPhotosCounter = styled.p(
    ({theme: {palette, mediaQueries}}) => css`
        color: ${palette.neutral['600']};

        ${mediaQueries.m} {
            display: none;
        }
    `
)

export const StyledAssignmentShootlistLink = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        & a {
            cursor: pointer;
            text-decoration: underline;
            font-weight: 500;
            color: ${palette.purple[700]};
        }
    `
)
