import styled, {css, DefaultTheme} from 'styled-components'

export const StyledSwitch = styled.label(
    ({theme: {transition}}) => css`
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    `
)

export const Label = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textSm};
        font-weight: 700;
        margin-right: 8px;
    `}
`

export const StyledInput = styled.input<{$textColor: NonNullable<keyof DefaultTheme['colors']>; $small: boolean}>`
    ${({theme: {colors}, $textColor, $small}) => css`
        width: 0;
        &:checked + #slider {
            background-color: ${colors[$textColor]};
        }
        &:checked + #slider:before {
            transform: ${$small ? 'translateX(16px)' : 'translateX(22px)'};
        }
    `}
`

export const StyledSlider = styled.div<{$small: boolean}>`
    ${({theme: {spacing, colors, palette}, $small}) => css`
        position: relative;
        width: ${$small ? spacing * 8 : spacing * 12}px;
        height: ${$small ? spacing * 4 : spacing * 6}px;
        inset: 0;
        background-color: ${colors.darkGrey};
        transition: 0.5s;
        border-radius: ${spacing * 10}px;
        &:before {
            position: absolute;
            content: '';
            height: ${$small ? 12 : 18}px;
            aspect-ratio: 1;
            left: ${$small ? 2 : 4}px;
            bottom: ${$small ? 2 : 3}px;
            background-color: ${palette.neutral.white};
            transition: 0.5s;
            border-radius: 50%;
            @supports not (aspect-ratio: 16/9) {
                width: ${$small ? 12 : 18}px;
            }
        }
    `}
`
