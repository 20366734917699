import axios from 'axios'
import {RESPONSE_TYPES, PAGE_LIMIT} from '../helpers/constants'

export const httpGetActiveShootsTable = (params, accessToken) => {
    const URL = '/home/current_assignments'
    const nextParams = {
        status: 'active',
        limit: 50,
        response_type: RESPONSE_TYPES.EXTENDED,
        ...params
    }
    const headers = {Authorization: `Bearer ${accessToken}`}

    return axios.get(URL, {params: nextParams, headers})
}

export const httpGetCurrentShootsByStatus = (params, accessToken) => {
    const URL = 'home/current_assignments'
    const nextParams = {
        limit: PAGE_LIMIT,
        response_type: RESPONSE_TYPES.EXTENDED,
        ...params
    }
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.get(URL, {params: nextParams, headers})
}

export const httpGetCompletedShoots = (params, accessToken) => {
    const URL = '/history'
    const nextParams = {
        limit: PAGE_LIMIT,
        response_type: RESPONSE_TYPES.EXTENDED,
        status: 'completed',
        ...params
    }
    const headers = {Authorization: `Bearer ${accessToken}`}

    return axios.get(URL, {params: nextParams, headers})
}

export const httpGetActiveShootsByRange = (range, accessToken) => {
    const URL = 'home/current_assignments'
    const params = {
        response_type: RESPONSE_TYPES.EXTENDED,
        status: 'active',
        ...range
    }
    const headers = {Authorization: `Bearer ${accessToken}`}

    return axios.get(URL, {params, headers})
}
