import {useTranslation} from 'react-i18next'
import {TableButton} from '../desktop/button/TableButton.tsx'
import {StyledErrorMessage} from './style'

export const ErrorMessage = ({onRefresh}: {onRefresh: () => void}) => {
    const {t} = useTranslation()

    return (
        <StyledErrorMessage>
            <h4>{t('errors:default')}</h4>
            {onRefresh && <TableButton text={t('commons:reload_table')} onClick={onRefresh} />}
        </StyledErrorMessage>
    )
}
