import styled, {css} from 'styled-components'

export const StyledStatus = styled.div`
    ${({theme: {typography, colors, mediaQuery}}) => css`
        display: flex;
        flex-direction: column;

        & > span {
            ${typography.textXs};
            color: ${colors.darkGrey};
        }

        ${mediaQuery.LG} {
            align-items: flex-end;
        }
    `}
`
