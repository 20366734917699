import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useUnscheduleAssignment} from 'src/features/assignment/services/useUnscheduleAssignment.ts'
import {StyledAssignmentUnscheduleModal} from 'src/features/assignment/components/assignment-unschedule-modal/style'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalParagraph,
    ModalTitle,
    ModalXCloseButton
} from 'src/components/modal-atoms/ModalAtoms'
import {Button} from 'src/components/button/Button'
import {Spinner} from 'src/components/spinner/Spinner'
import {AlertTriangleIcon} from 'src/components/icon'
import {getServiceTypeDescriptionsVersion} from 'src/features/assignment/utils.tsx'
import {useAssignment} from 'src/features/assignment/services/useAssignment.ts'
import {useURLParsedParams} from 'src/hooks/useURLParsedParams.ts'
import {Assignment, AssignmentUrlParams} from 'src/features/assignment/types.ts'
import {ErrorBox} from 'src/components/error-box/ErrorBox.tsx'

const AssignmentUnscheduleModalContent = ({assignment, onClose}: {assignment: Assignment; onClose: () => void}) => {
    const {t} = useTranslation()
    const unscheduleAssignmentMutation = useUnscheduleAssignment({assignmentId: assignment.id})
    const serviceType = getServiceTypeDescriptionsVersion(assignment.task_media.name, assignment.task_category.name)

    return (
        <>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} disabled={unscheduleAssignmentMutation.isLoading} />
            </ModalHeader>
            <ModalBody>
                <Flexbox gap={2} direction="column">
                    <ModalTitle>{t(`assignment:unschedule_modal:title:${serviceType}`)}</ModalTitle>
                    <ModalParagraph>{t(`assignment:unschedule_modal:subtitle:${serviceType}`)}</ModalParagraph>
                </Flexbox>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} variant="tertiary" disabled={unscheduleAssignmentMutation.isLoading}>
                    {t('commons:close')}
                </Button>
                <Button
                    disabled={unscheduleAssignmentMutation.isLoading}
                    onClick={() => unscheduleAssignmentMutation.mutate()}
                >
                    {t('assignment:unschedule')}
                    {unscheduleAssignmentMutation.isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </>
    )
}

export const AssignmentUnscheduleModal: FC<{onClose: () => void}> = ({onClose}) => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(AssignmentUrlParams)
    const assignmentQuery = useAssignment(urlParams.data?.code)

    if (!assignmentQuery.isSuccess) {
        return <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
    }

    return (
        <StyledAssignmentUnscheduleModal width={480}>
            <AssignmentUnscheduleModalContent assignment={assignmentQuery.data} onClose={onClose} />
        </StyledAssignmentUnscheduleModal>
    )
}
