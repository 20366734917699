import {Menu, MenuGroup, MenuGroupLabel, MenuItem} from '@ariakit/react'
import styled, {css} from 'styled-components'

export const StyledAssignmentUploadVideoMenu = styled(Menu)(
    ({theme: {palette, shadows, mediaQueries}}) => css`
        background-color: ${palette.neutral['white']};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 8px;
        box-shadow: ${shadows.lg};
        width: 240px;
        overflow: hidden;

        ${mediaQueries.m} {
            width: 100%;
        }
    `
)

export const StyledAssignmentUploadVideoMenuGroup = styled(MenuGroup)(
    ({theme: {spacing}}) => css`
        padding: ${spacing}px ${spacing * 1.5}px;
    `
)

export const StyledAssignmentUploadVideoMenuItem = styled(MenuItem)(
    ({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm};
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        font-weight: 500;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
            background-color: ${palette.neutral[50]};
        }

        &[aria-disabled='true'] {
            color: ${palette.neutral[300]};
            & > svg {
                fill: ${palette.neutral[300]};
            }
        }

        & > svg {
            fill: ${palette.neutral[500]};
        }
    `
)

export const StyledAssignmentUploadVideoMenuGroupLabel = styled(MenuGroupLabel)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
        padding: ${spacing}px ${spacing * 2}px;
    `
)
