import styled, {css} from 'styled-components'

export const StyledVideoDropzone = styled.div(
    ({theme: {colors, spacing, typography, palette}, isDragReject, isDragAccept}) => css`
        min-height: 142px;
        padding: ${spacing * 7}px;
        border-width: 2px;
        border-style: dashed;
        border-color: ${isDragReject ? colors.red : isDragAccept ? colors.bgDarkGrey : palette.neutral['300']};
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 2}px;
        cursor: pointer;
        background-color: ${isDragReject ? colors.bgRed : isDragAccept ? colors.bgGrey : palette.neutral.white};

        p {
            overflow-x: hidden;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: ${palette.neutral.black};
            ${typography.textSm};

            span {
                text-decoration: underline;
                text-underline-offset: 2px;
            }
        }

        svg path {
            stroke: ${colors.bgDarkGrey};
        }
    `
)
