import {useEffect} from 'react'
import {errorHandler, raise} from 'src/helpers/helpers.ts'
// @ts-expect-error [TS7016] "We don't have types yet"
import {httpRequestAssignmentCancellation} from 'src/http-requests/chatbot.http.js'
import {useUserStore} from 'src/features/user/store.ts'
import {useDispatch, useSelector} from 'react-redux'
import {addStepToChatHistory, chatbotShootSelector} from 'src/store/chatbot.ts'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'
import {QUERY_ACTION_KEYS} from 'src/queryClient.ts'
import {useAsync} from 'src/hooks/useAsync.ts'
import {useTranslation} from 'react-i18next'
import {CHATBOT_SOURCES} from 'src/helpers/constants.ts'

export const RequestAssignmentCancellation = ({stepId}: {stepId: string}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const accessToken = useUserStore(store => store.accessToken)
    const selectedAssignment = useSelector(chatbotShootSelector)
    const requestAssignmentCancellation = useAsync()

    const handleRequestAssignmentCancellation = async () => {
        try {
            await requestAssignmentCancellation.run(
                httpRequestAssignmentCancellation(
                    accessToken,
                    selectedAssignment?.id ?? raise('selectedAssignment id is nullish'),
                    {by: 'host'}
                )
            )
            invalidationSignal(QUERY_ACTION_KEYS.ASSIGNMENT_CHANGE_COORDINATION_STATUS)
            dispatch(
                addStepToChatHistory({
                    id: stepId,
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: t('chatbot:mb14:MB1.4_host_cancel')
                })
            )
        } catch (error) {
            errorHandler(error)
        }
    }

    useEffect(() => {
        void handleRequestAssignmentCancellation()
    }, [])

    if (requestAssignmentCancellation.isError) {
        return t('errors:default')
    }
    if (requestAssignmentCancellation.isSuccess) {
        return t('chatbot:mb14:MB1.4_host_cancel')
    }
    return '...'
}
