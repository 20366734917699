import PropTypes from 'prop-types'
import {StyledTab, StyledInnerSpan} from './style'
import {BADGE_SIZES, Badge} from '../badge-legacy/Badge'
import {useTheme} from 'styled-components'

export const Tab = ({
    className,
    color = 'white',
    textColor = '#11181C',
    borderColor = '#D7DBDF',
    isSelected,
    size = 'small',
    text,
    badgeText = null,
    icon,
    hideText = false,
    isStrokeIcon,
    isPathFilledIcon,
    iconPosition = 'left',
    disabled,
    ...rest
}) => {
    const theme = useTheme()
    return (
        <StyledTab
            className={className}
            type="button"
            disabled={disabled}
            color={color}
            textColor={textColor}
            borderColor={borderColor}
            isSelected={isSelected}
            size={size}
            isStrokeIcon={isStrokeIcon}
            isPathFilledIcon={isPathFilledIcon}
            iconPosition={iconPosition}
            {...rest}
        >
            {icon}
            {!hideText && <StyledInnerSpan isSelected={isSelected}>{text}</StyledInnerSpan>}
            {badgeText !== null && (
                <Badge
                    size={BADGE_SIZES.SMALL}
                    text={badgeText}
                    background={isSelected ? theme.palette.primary['600'] : theme.palette.primary['200']}
                    color={isSelected ? theme.palette.neutral.white : theme.palette.primary['600']}
                />
            )}
        </StyledTab>
    )
}

Tab.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
    borderColor: PropTypes.string,
    isSelected: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'big']),
    text: PropTypes.string,
    icon: PropTypes.node,
    isStrokeIcon: PropTypes.bool,
    isPathFilledIcon: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    disabled: PropTypes.bool,
    hideText: PropTypes.bool
}
