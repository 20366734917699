import {StyledSimpleLink} from './style'
import PropTypes from 'prop-types'

export const SimpleLink = ({link, text}) => {
    return (
        <StyledSimpleLink>
            <span>{'🔗'}</span>{' '}
            <a href={link} target="_blank" rel="noreferrer">
                {' '}
                {text}
            </a>
        </StyledSimpleLink>
    )
}

SimpleLink.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string
}
