import styled, {css} from 'styled-components'
import {FlagIcon} from '../../../../theme/icons'
import {Badge} from '../../../../components/badge-legacy/Badge'
import {CloseIcon} from '../../../../assets/icons/CloseIcon'

export const StyledCalendar = styled.div`
    ${({theme: {zIndex, spacing, mediaQuery}}) => css`
        z-index: ${zIndex.calendar};
        ${mediaQuery.SM} {
            gap: ${spacing * 2.5}px;
        }
    `}
`

export const TabsWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: ${spacing * 6}px;

        ${mediaQuery.SM} {
            padding-bottom: ${spacing * 5}px;
        }
    `}
`

export const StyledIcon = styled(FlagIcon)`
    ${({theme: {mediaQuery}}) => css`
        position: absolute;
        left: -37px;
        ${mediaQuery.SM} {
            display: none;
        }
    `}
`

export const StyledTab = styled.div`
    ${({isSelected, theme: {typography, palette, spacing}}) => css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        ${typography.textXl};
        font-weight: 700;
        color: ${isSelected ? palette.neutral['900'] : palette.neutral['500']};
        margin-right: ${spacing * 4.5}px;
        ${isSelected &&
        css`
            border-bottom: 2px solid ${palette.neutral['900']};
        `}
        padding-bottom: ${isSelected ? spacing : spacing * 2}px;
        cursor: pointer;
    `}
`

export const StyledBadge = styled(Badge)`
    ${({theme: {spacing}}) => css`
        margin-left: ${spacing * 2}px;
    `}
`

export const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
`
export const TooltipContentWrapper = styled.div`
    display: flex;
    gap: 8px;
`
