import {SimpleLink} from '../custom-components/simple-link/SimpleLink'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import i18next from 'i18next'

export const mb21FlowSteps = () => [
    {
        id: 'MB2.1',
        message: i18next.t('chatbot:mb21:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.1',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Payment will be issued on your airbnb account. CheckIcon how to add a payout method here:'
                })
            )
            return 'MB2.1.1'
        }
    },
    {
        id: 'MB2.1.1',
        component: (
            <SimpleLink link="https://www.airbnb.com/help/topic/1452" text={i18next.t('chatbot:mb21:mb2.1.1')} />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.1.1',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] How to add a payout method'
                })
            )
            return 'send_log'
        }
    }
]
