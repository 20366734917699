import {TableColumn, TableSorter} from 'src/components/table/Table.tsx'
import {StyledBody, StyledButton, StyledHead, StyledSorterModal} from 'src/components/table/mobile-sorter-modal/style'
import {CheckIcon} from 'src/components/icon/index.tsx'
import {useTranslation} from 'react-i18next'
import {ModalOverlay} from 'src/components/modal-atoms/ModalAtoms.tsx'

interface MobileSorterModalProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    onClose: () => void
    sorter: TableSorter<TSortName>
}

export const MobileSorterModal = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    onClose,
    sorter
}: MobileSorterModalProps<TData, TSortName>) => {
    const {t} = useTranslation()

    return (
        <>
            <ModalOverlay onClick={onClose} />
            <StyledSorterModal width="320px">
                <StyledHead>
                    <h3>{t('commons:sort_the_table')}</h3>
                </StyledHead>
                <StyledBody>
                    {columns
                        .filter(column => column.sortName)
                        .map(column => (
                            <StyledButton
                                size="sm"
                                variant="ghost"
                                key={column.sortName}
                                onClick={() => {
                                    sorter.onSort({orderBy: column.sortName, orderDirection: 'desc'})
                                    onClose()
                                }}
                                fullWidth
                            >
                                {column.label}
                                {sorter.orderBy == column.sortName && <CheckIcon />}
                            </StyledButton>
                        ))}
                </StyledBody>
            </StyledSorterModal>
        </>
    )
}

MobileSorterModal.displayName = 'MobileSorterModal'
