import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {HostInfoWrapper, StyledRadioTitle, InfosWrapper, InfoWrapper, StyledInfoLabel, StyledInfo} from '../style'

export const HostInfos = ({defaultHost}) => {
    const {t} = useTranslation()

    return (
        <HostInfoWrapper>
            <StyledRadioTitle>{t('commons:host')}</StyledRadioTitle>
            <InfosWrapper>
                <InfoWrapper>
                    <StyledInfoLabel>{t('admin:host_id')}</StyledInfoLabel>
                    <StyledInfo>{defaultHost?.id || '-'}</StyledInfo>
                </InfoWrapper>
                <InfoWrapper>
                    <StyledInfoLabel>{t('admin:host_name')}</StyledInfoLabel>
                    <StyledInfo>{defaultHost?.first_name || '-'}</StyledInfo>
                </InfoWrapper>
                <InfoWrapper>
                    <StyledInfoLabel>{t('admin:host_email')}</StyledInfoLabel>
                    <StyledInfo>{defaultHost?.email || '-'}</StyledInfo>
                </InfoWrapper>
            </InfosWrapper>
        </HostInfoWrapper>
    )
}

HostInfos.propTypes = {
    defaultHost: PropTypes.shape({id: PropTypes.number, first_name: PropTypes.string, email: PropTypes.string})
}
