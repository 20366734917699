import {StepCardProps} from 'src/features/assignment/components/step-card/StepCard.tsx'
import i18next from 'i18next'
import {Assignment, ServiceType, Space, Compensation} from 'src/features/assignment/types.ts'
import {PendingDescription} from 'src/features/assignment/components/assignment-descriptions/pending-description/PendingDescription.tsx'
import {ReactElement} from 'react'
import {AcceptedDatesSharedDescription} from 'src/features/assignment/components/assignment-descriptions/accepted-dates-shared-description/AcceptedDatesSharedDescription.tsx'
import {AcceptedDatesDeclinedDescription} from 'src/features/assignment/components/assignment-descriptions/accepted-dates-declined-description/AcceptedDatesDeclinedDescription.tsx'
import {ScheduledDescription} from 'src/features/assignment/components/assignment-descriptions/scheduled-description/ScheduledDescription.tsx'
import {AssetsReviewDescription} from 'src/features/assignment/components/assignment-descriptions/assets-review-description/AssetsReviewDescription.tsx'
import {PendingPaymentDescription} from 'src/features/assignment/components/assignment-descriptions/pending-payment-description/PendingPaymentDescription.tsx'
import {UploadAssetsDescription} from './components/assignment-descriptions/upload-assets-description/UploadAssetsDescription'
import {AcceptedDatesDontMatchDescription} from 'src/features/assignment/components/assignment-descriptions/accepted-dates-dont-match-description/AcceptedDatesDontMatchDescription.tsx'
import {AcceptedFreelancerNotReadyDescription} from 'src/features/assignment/components/assignment-descriptions/accepted-freelancer-not-ready-description/AcceptedFreelancerNotReadyDescription.tsx'
import {AcceptedHostNotReadyDescription} from 'src/features/assignment/components/assignment-descriptions/accepted-host-not-ready-description/AcceptedHostNotReadyDescription.tsx'
import {AcceptedHostUnresponsiveDescription} from 'src/features/assignment/components/assignment-descriptions/accepted-host-unresponsive-description/AcceptedHostUnresponsiveDescription.tsx'
import {AcceptedNoHostDatesDescription} from 'src/features/assignment/components/assignment-descriptions/accepted-no-host-dates-description/AcceptedNoHostDatesDescription.tsx'
import {NeedsMoreAssetsDescription} from 'src/features/assignment/components/assignment-descriptions/needs-more-assets-description/NeedsMoreAssetsDescription'

export const getServiceType = (taskMedia: Assignment['task_media']['name'], taskCategory: string): ServiceType => {
    if (taskMedia == 'matterport') {
        return 'matterport'
    }
    if (taskMedia == 'walkthrough') {
        return 'walkthrough'
    }
    if ((taskCategory == 'Experiences' && taskMedia == 'video') || taskMedia == 'video') {
        return 'experience_video'
    }
    return 'home'
}

export const getAssignmentSteps = (assignment: Assignment): Array<StepCardProps & {isCurrent: boolean}> => {
    const serviceType = getServiceType(assignment.task_media.name, assignment.task_category.name)
    const serviceTypeVersion: ServiceType = serviceType == 'walkthrough' ? 'walkthrough' : 'home'

    if (assignment.task_status.status == 'completed') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t('assignment:make_a_decision')
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(`assignment:steps:step2:${serviceTypeVersion}`)
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title:
                    serviceTypeVersion == 'walkthrough'
                        ? i18next.t(`assignment:submit_form`)
                        : i18next.t(`assignment:steps:step3:${serviceTypeVersion}`)
            },
            {
                number: 4,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(`assignment:steps:step4:${serviceTypeVersion}`)
            },
            {
                number: 5,
                isCurrent: true,
                status: 'completed',
                title: i18next.t('assignment:steps:step5')
            }
        ]
    }
    if (assignment.task_status.status == 'pending_payment') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t('assignment:make_a_decision')
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(`assignment:steps:step2:${serviceTypeVersion}`)
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title:
                    serviceTypeVersion == 'walkthrough'
                        ? i18next.t(`assignment:submit_form`)
                        : i18next.t(`assignment:steps:step3:${serviceTypeVersion}`)
            },
            {
                number: 4,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(`assignment:steps:step4:${serviceTypeVersion}`)
            },
            {
                number: 5,
                isCurrent: true,
                status: 'active',
                title: i18next.t('assignment:steps:step5')
            }
        ]
    }
    if (assignment.task_status.status == 'needs_more_assets') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t('assignment:make_a_decision')
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(`assignment:steps:step2:${serviceTypeVersion}`)
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title:
                    serviceTypeVersion == 'walkthrough'
                        ? i18next.t(`assignment:submit_form`)
                        : i18next.t(`assignment:steps:step3:${serviceTypeVersion}`)
            },
            {
                number: 4,
                isCurrent: true,
                status: 'error',
                title: i18next.t(`assignment:steps:step4:${serviceTypeVersion}`)
            },
            {
                number: 5,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t('assignment:steps:step5')
            }
        ]
    }
    if (assignment.task_status.status == 'assets_in_review') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t('assignment:make_a_decision')
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(`assignment:steps:step2:${serviceTypeVersion}`)
            },
            {
                number: 3,
                isCurrent: false,
                status: 'completed',
                title:
                    serviceTypeVersion == 'walkthrough'
                        ? i18next.t(`assignment:submit_form`)
                        : i18next.t(`assignment:steps:step3:${serviceTypeVersion}`)
            },
            {
                number: 4,
                isCurrent: true,
                status: 'active',
                title: i18next.t(`assignment:steps:step4:${serviceTypeVersion}`)
            },
            {
                number: 5,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t('assignment:steps:step5')
            }
        ]
    }
    if (assignment.task_status.status == 'scheduled' || assignment.task_status.status == 'pending_assets') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t('assignment:make_a_decision')
            },
            {
                number: 2,
                isCurrent: false,
                status: 'completed',
                title: i18next.t(`assignment:steps:step2:${serviceTypeVersion}`)
            },
            {
                number: 3,
                isCurrent: true,
                status: 'active',
                title:
                    serviceTypeVersion == 'walkthrough'
                        ? i18next.t(`assignment:submit_form`)
                        : i18next.t(`assignment:steps:step3:${serviceTypeVersion}`)
            },
            {
                number: 4,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(`assignment:steps:step4:${serviceTypeVersion}`)
            },
            {
                number: 5,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t('assignment:steps:step5')
            }
        ]
    }
    if (assignment.status == 'accepted') {
        return [
            {
                number: 1,
                isCurrent: false,
                status: 'completed',
                title: i18next.t('assignment:make_a_decision')
            },
            {
                number: 2,
                isCurrent: true,
                status: 'active',
                title: i18next.t(`assignment:steps:step2:${serviceTypeVersion}`)
            },
            {
                number: 3,
                isCurrent: false,
                status: 'inactive',
                title:
                    serviceTypeVersion == 'walkthrough'
                        ? i18next.t(`assignment:submit_form`)
                        : i18next.t(`assignment:steps:step3:${serviceTypeVersion}`)
            },
            {
                number: 4,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t(`assignment:steps:step4:${serviceTypeVersion}`)
            },
            {
                number: 5,
                isCurrent: false,
                status: 'inactive',
                title: i18next.t('assignment:steps:step5')
            }
        ]
    }

    return [
        {
            number: 1,
            isCurrent: true,
            status: 'active',
            title: i18next.t('assignment:make_a_decision')
        },
        {
            number: 2,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t(`assignment:steps:step2:${serviceTypeVersion}`)
        },
        {
            number: 3,
            isCurrent: false,
            status: 'inactive',
            title:
                serviceTypeVersion == 'walkthrough'
                    ? i18next.t(`assignment:submit_form`)
                    : i18next.t(`assignment:steps:step3:${serviceTypeVersion}`)
        },
        {
            number: 4,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t(`assignment:steps:step4:${serviceTypeVersion}`)
        },
        {
            number: 5,
            isCurrent: false,
            status: 'inactive',
            title: i18next.t('assignment:steps:step5')
        }
    ]
}

export const getServiceTypeDescriptionsVersion = (
    taskMedia: Assignment['task_media']['name'],
    taskCategory: Assignment['task_category']['name']
): ServiceType => {
    const serviceType = getServiceType(taskMedia, taskCategory)
    return serviceType == 'walkthrough' ? 'walkthrough' : 'home'
}

export const getAssignmentDescription = (assignment: Assignment): ReactElement => {
    const serviceTypeVersion = getServiceTypeDescriptionsVersion(
        assignment.task_media.name,
        assignment.task_category.name
    )

    if (assignment.task_status.status == 'pending_payment') {
        return <PendingPaymentDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.status == 'assets_in_review') {
        return <AssetsReviewDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.status == 'scheduled') {
        return <ScheduledDescription date={assignment.to_shoot_at} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.status == 'pending_assets') {
        return <UploadAssetsDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.status == 'needs_more_assets') {
        return <NeedsMoreAssetsDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.coordination_status == 'dates_dont_match') {
        return <AcceptedDatesDontMatchDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.coordination_status == 'photographer_not_available') {
        return <AcceptedFreelancerNotReadyDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.coordination_status == 'host_not_ready') {
        return <AcceptedHostNotReadyDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.coordination_status == 'host_unresponsive') {
        return assignment.host_responsive_at ? (
            <AcceptedDatesDeclinedDescription assignment={assignment} serviceType={serviceTypeVersion} />
        ) : (
            <AcceptedHostUnresponsiveDescription assignment={assignment} serviceType={serviceTypeVersion} />
        )
    }
    if (
        assignment.task_status.coordination_status == 'host_contacted' ||
        assignment.task_status.coordination_status == 'overdue_host_contacted'
    ) {
        return <AcceptedDatesDeclinedDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.task_status.coordination_status == 'manual_coordination') {
        return <AcceptedNoHostDatesDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }
    if (assignment.status == 'accepted') {
        return <AcceptedDatesSharedDescription assignment={assignment} serviceType={serviceTypeVersion} />
    }

    return <PendingDescription assignment={assignment} serviceType={serviceTypeVersion} />
}

export const spaceKeyToLabel = {
    bathroom: {label: 'spaces:bathroom', value: null},
    bedroom_1: {label: 'spaces:bedroom_x', value: 1},
    bedroom_2: {label: 'spaces:bedroom_x', value: 2},
    bedroom_3: {label: 'spaces:bedroom_x', value: 3},
    bedroom_4: {label: 'spaces:bedroom_x', value: 4},
    bedroom_5: {label: 'spaces:bedroom_x', value: 5},
    bedroom_6: {label: 'spaces:bedroom_x', value: 6},
    bedroom_7: {label: 'spaces:bedroom_x', value: 7},
    bedroom_8: {label: 'spaces:bedroom_x', value: 8},
    bedroom_9: {label: 'spaces:bedroom_x', value: 9},
    bedroom_10: {label: 'spaces:bedroom_x', value: 10},
    common_spaces: {label: 'spaces:common_spaces', value: null},
    dining_room: {label: 'spaces:dining_room', value: null},
    exterior: {label: 'spaces:exterior', value: null},
    kitchen: {label: 'spaces:kitchen', value: null},
    living_room: {label: 'spaces:living_room', value: null},
    office_study: {label: 'spaces:office_study', value: null},
    other: {label: 'spaces:other', value: null},
    outdoor_space: {label: 'spaces:outdoor_space', value: null},
    pool_host_tub: {label: 'spaces:pool_host_tub', value: null}
} as const satisfies Record<Space['space']['key_name'], {label: string; value: number | null}>

export const compensationKeyToLabel = {
    photo_session: 'assignment:compensation_card:photo_session',
    travel: 'assignment:compensation_card:travel',
    profile_photo: 'assignment:compensation_card:profile_photo',
    bonus: 'assignment:compensation_card:bonus',
    walkthrough: 'commons:walkthrough',
    total: 'commons:total'
} as const satisfies Record<Compensation['compensationKey'], string>
