import styled, {css} from 'styled-components'

export const StyledRefreshCalendarMessage = styled.div`
    ${({theme: {zIndex, colors}}) => css`
        position: absolute;
        background: ${colors.transparentBlack};
        inset: -8px;
        border-radius: 4px;
        z-index: ${zIndex.calendarError};
    `}
`

export const StyledRefreshCalendarMessageContainer = styled.div`
    ${({theme: {colors, typography, spacing, palette}}) => css`
        ${typography.textXl};
        font-weight: 700;
        width: calc(100% - 32px);
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 4}px;
        box-shadow: 0 4px 16px ${colors.mercuryGrey};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: ${palette.neutral.white};
        color: ${colors.red};
        text-align: center;
        border-radius: 4px;
        padding: ${spacing * 6}px;
        border: 1px solid ${colors.transparentBlack};
    `}
`
