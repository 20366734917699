import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css'
import {getAppTheme} from './theme/theme'
import {ThemeProvider} from 'styled-components'
import {AppRoutes} from './routes'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setLanguage, showAlert} from './store/appGenerics'
import Cookies from 'js-cookie'
import {ALERT_LEVELS, GTM_ID} from './helpers/constants'
import {useTranslation} from 'react-i18next'
import {localStorageManager} from './helpers/utils'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import TagManager from 'react-gtm-module'
import {createBrowserHistory} from 'history'
import {processInvalidationSignal} from './helpers/reactQueryTabSync'
import {useQueryClient} from '@tanstack/react-query'
import {useUserStore} from './features/user/store'

const tagManagerArgs = {
    gtmId: GTM_ID
}

export const history = createBrowserHistory({basename: '/'})
const environment =
    process.env.REACT_APP_ENDPOINT === 'https://mediabox-api.translated.com/api' ? 'production' : 'development'

export const App = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const setUser = useUserStore(store => store.setUser)
    const queryClient = useQueryClient()

    useEffect(() => {
        if (environment === 'production') TagManager.initialize(tagManagerArgs)
    }, [])

    //to keep tabs in sync
    useEffect(() => {
        window.addEventListener('storage', ({newValue}) => processInvalidationSignal(newValue, queryClient))
        return () => {
            window.removeEventListener('storage', ({newValue}) => processInvalidationSignal(newValue, queryClient))
        }
    }, [])

    useEffect(() => {
        dispatch(setLanguage(localStorageManager.get('i18nextLng')))

        const adminAccessToken = Cookies.get('access_token')
        const adminLoginError = Cookies.get('admin_login_error')

        if (adminLoginError) {
            dispatch(
                showAlert({
                    message: t(`errors:${adminLoginError}`),
                    level: ALERT_LEVELS.ERROR
                })
            )
            Cookies.remove('admin_login_error', {
                path: '',
                domain: '.translated.com'
            })
            Cookies.remove('admin_login_error', {
                path: '',
                domain: '.mediabox.local'
            })
            Cookies.remove('admin_login_error', {
                path: '',
                domain: '.k-stage.dev'
            })
        } else if (adminAccessToken) {
            Cookies.remove('access_token', {
                path: '',
                domain: '.translated.com'
            })
            Cookies.remove('access_token', {
                path: '',
                domain: '.mediabox.local'
            })
            Cookies.remove('access_token', {
                path: '',
                domain: '.k-stage.dev'
            })
            setUser({
                access_token: adminAccessToken,
                user: {},
                isAdmin: true
            })
        }

        if (environment === 'production') {
            Sentry.init({
                dsn: 'https://57ea1e98e54044a9830e8604d60959da@o1170454.ingest.sentry.io/6581889',
                environment,
                integrations: [
                    new Integrations.BrowserTracing({
                        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
                    })
                ],
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 1.0
            })
        }
    }, [dispatch])

    return (
        <ThemeProvider theme={getAppTheme({name: 'light'})}>
            <AppRoutes />
        </ThemeProvider>
    )
}
