import styled, {css} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

export const StyledAvatarRoot = styled(AvatarPrimitive.Root)`
    ${({theme: {colors}}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        background-color: ${colors.smokeGrey};
        color: ${colors.darkGrey};
        border: 1px solid ${colors.grey};
    `}
`
export const StyledAvatarImage = styled(AvatarPrimitive.Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
`
export const StyledAvatarFallback = styled(AvatarPrimitive.Fallback)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
`
