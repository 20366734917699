import {useTranslation} from 'react-i18next'
import {useURLParsedParams} from 'src/hooks/useURLParsedParams'
import {useAssignment} from 'src/features/assignment/services/useAssignment'
import {Skeleton} from 'src/components/skeleton/Skeleton'
import {
    AssignmentCardTitle,
    AssignmentCardWrapper
} from 'src/features/assignment/components/assignment-card-atoms/AssignmentCardAtoms'
import {ErrorBox} from 'src/components/error-box/ErrorBox'
import {AlertTriangleIcon, Globe01Icon, Mail01Icon, PhoneIcon} from 'src/components/icon'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    AssignmentHostCardAvatar,
    AssignmentHostCardContent,
    StyledHostInfoList
} from 'src/features/assignment/components/assignment-host-card/style'
import {AxiosError} from 'axios'

import {AssignmentUrlParams} from 'src/features/assignment/types.ts'

export const AssignmentHostCard = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(AssignmentUrlParams)
    const assignmentQuery = useAssignment(urlParams.data?.code, {
        enabled: urlParams.success
    })

    if (assignmentQuery.isLoading) {
        return (
            <AssignmentCardWrapper>
                <AssignmentCardTitle>
                    <Skeleton height={28} />
                </AssignmentCardTitle>
                <AssignmentHostCardContent gap={2} direction="column">
                    {Array.from({length: 3}).map((_, index) => (
                        <Skeleton key={index} height={20} />
                    ))}
                </AssignmentHostCardContent>
            </AssignmentCardWrapper>
        )
    }

    if (
        urlParams.error ||
        (assignmentQuery.error instanceof AxiosError && assignmentQuery.error.response?.status == 404) ||
        (assignmentQuery.data?.status != 'pending' && assignmentQuery.data?.status != 'accepted')
    ) {
        return (
            <AssignmentCardWrapper>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('spaces:not_found')} />
            </AssignmentCardWrapper>
        )
    }

    return (
        <AssignmentCardWrapper>
            <AssignmentHostCardContent gap={4} justify="space-between">
                <Flexbox gap={4} direction="column">
                    <AssignmentCardTitle>
                        {t('commons:host')}: {assignmentQuery.data.host.first_name}
                    </AssignmentCardTitle>
                    <StyledHostInfoList render="ul" gap={2} direction="column">
                        <Flexbox render="li" gap={2} align="center">
                            <Mail01Icon size={20} />
                            <span>{t('commons:email')}:</span>
                            <a href={`mailto:${assignmentQuery.data.host.email}`}>{assignmentQuery.data.host.email}</a>
                        </Flexbox>
                        {assignmentQuery.data?.host.phone && (
                            <Flexbox render="li" gap={2} align="center">
                                <PhoneIcon size={20} />
                                <span>{t('commons:phone')}:</span>
                                <a href={`tel:${assignmentQuery.data.host.phone}`}>{assignmentQuery.data.host.phone}</a>
                            </Flexbox>
                        )}
                        {assignmentQuery.data?.host.preferred_languages && (
                            <Flexbox render="li" gap={2} align="center">
                                <Globe01Icon size={20} />
                                <span>{t('commons:preferred_languages')}:</span>
                                {assignmentQuery.data.host.preferred_languages}
                            </Flexbox>
                        )}
                    </StyledHostInfoList>
                </Flexbox>
                <AssignmentHostCardAvatar name={assignmentQuery.data.host.first_name} imageUrl={''} />
            </AssignmentHostCardContent>
        </AssignmentCardWrapper>
    )
}
