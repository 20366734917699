import {captureException} from '@sentry/react'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {errorHandler, parseAxiosPromise, raise} from 'src/helpers/helpers'
import {MUTATION_KEYS, QUERY_KEYS, queryClient} from 'src/queryClient'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {
    HttpUploadHostApprovalTaskImagesOptions,
    httpAdminHostApprovalTaskUpload
} from 'src/features/admin-host-approval/services/adminHostApproval.http'
import {AxiosProgressEvent} from 'axios'

export type UseUploadHostApprovalTaskImages = {
    taskId: number
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void | undefined
    options?: UseMutationOptions<
        AdminHostApprovalTaskImage[],
        unknown,
        HttpUploadHostApprovalTaskImagesOptions['payload']
    >
}

export const useUploadHostApprovalTaskImages = ({
    taskId,
    onUploadProgress,
    options
}: UseUploadHostApprovalTaskImages) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is null')
    return useMutation({
        mutationKey: [MUTATION_KEYS.HOST_APPROVAL_TASK_UPLOAD],
        mutationFn: (payload: HttpUploadHostApprovalTaskImagesOptions['payload']) =>
            parseAxiosPromise({
                axiosPromise: httpAdminHostApprovalTaskUpload({
                    urlParams: {taskId},
                    payload,
                    onUploadProgress,
                    adminAccessToken
                }),
                responseShape: AdminHostApprovalTaskImage.array(),
                onZodError: captureException
            }),
        ...options,
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK_IMAGES, taskId]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
