import styled, {css} from 'styled-components'
import {Container} from 'src/components/container/Container.tsx'

export const StyledAssignmentHero = styled.section(
    ({theme: {palette, spacing, mediaQueries}}) => css`
        background-color: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.neutral['300']};
        padding-top: ${spacing * 8}px;
        padding-bottom: ${spacing * 12}px;

        ${mediaQueries.m} {
            padding-top: ${spacing * 4.5}px;
            padding-bottom: ${spacing * 8}px;
        }
    `
)

export const StyledAssignmentHeroContainer = styled(Container)(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 12}px;
    `
)
