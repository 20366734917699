import {FC, HTMLAttributes} from 'react'

export const IncompleteCircleIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 20, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.0081 0.354524C8.00541 0.354503 8.00273 0.354492 8.00004 0.354492C3.76586 0.354492 0.333374 3.78698 0.333374 8.02116C0.333374 12.2553 3.76586 15.6878 8.00004 15.6878C8.00273 15.6878 8.00541 15.6878 8.0081 15.6878C8.01078 15.6878 8.01347 15.6878 8.01615 15.6878C9.05384 15.6878 10.0458 15.4811 10.9511 15.1057C11.4613 14.8941 11.7034 14.3091 11.4918 13.7989C11.2802 13.2887 10.6952 13.0467 10.185 13.2582C9.51826 13.5347 8.78635 13.6878 8.01615 13.6878C8.01347 13.6878 8.01078 13.6878 8.0081 13.6879C8.00541 13.6878 8.00273 13.6878 8.00004 13.6878C4.87043 13.6878 2.33337 11.1508 2.33337 8.02116C2.33337 4.89154 4.87043 2.35449 8.00004 2.35449C8.00273 2.35449 8.00541 2.35448 8.0081 2.35446C8.01078 2.35448 8.01347 2.35449 8.01615 2.35449C8.78635 2.35449 9.51826 2.5076 10.185 2.78409C10.6952 2.99564 11.2802 2.75358 11.4918 2.24342C11.7034 1.73326 11.4613 1.14819 10.9511 0.936637C10.0458 0.561204 9.05385 0.354492 8.01615 0.354492C8.01347 0.354492 8.01078 0.354503 8.0081 0.354524ZM15.1007 5.08619C14.8891 4.57603 14.3041 4.33396 13.7939 4.54552C13.2837 4.75707 13.0417 5.34214 13.2532 5.8523C13.5297 6.51905 13.6828 7.25096 13.6828 8.02116C13.6828 8.79135 13.5297 9.52327 13.2532 10.19C13.0417 10.7002 13.2837 11.2852 13.7939 11.4968C14.3041 11.7084 14.8891 11.4663 15.1007 10.9561C15.4761 10.0508 15.6828 9.05885 15.6828 8.02116C15.6828 6.98347 15.4761 5.99153 15.1007 5.08619Z"
            fill="currentColor"
        />
    </svg>
)
