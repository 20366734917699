import {StyledProgressBar} from './style'

export const ProgressBar = ({progressValue}: {progressValue: number}) => {
    return (
        <StyledProgressBar direction={'column'} align={'center'} gap={2} $progressValue={progressValue}>
            <div className="progress-bar-track">
                <div />
            </div>
            <span className="progress-bar-indicator">{`${progressValue}%`}</span>
        </StyledProgressBar>
    )
}
