import i18next from 'i18next'
import {store} from '../../../../index'
import {addStepToChatHistory} from '../../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../../helpers/constants'
import {MultiParagraphMessage} from '../../custom-components/multi-paragraph-message/MultiParagraphMessage'
import {SimpleLink} from '../../custom-components/simple-link/SimpleLink'
import {CancelShoot} from '../../custom-components/CancelShoot'
import {RequestAssignmentCancellation} from '../../custom-components/RequestAssignmentCancellation'

const hostConfirmCancelShoot = isInShootPage => [
    {
        id: 'host_confirm_cancel_shoot_options_ask',
        message: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason
            return i18next.t('chatbot:commons:confirm_reason', {
                cancellationReason: cancellationReason.text
            })
        },
        trigger: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason

            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.host_confirm_cancel_shoot_options_ask',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `Please confirm you want to cancel this shoot because of "${cancellationReason.text}"`
                })
            )
            return 'host_confirm_cancel_shoot_options'
        }
    },
    {
        id: 'host_confirm_cancel_shoot_options',
        options: [
            {
                value: 'confirm',
                label: i18next.t('chatbot:commons:confirm_cancellation'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'host_confirm_cancel_shoot_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Confirm cancellation'
                        })
                    )
                    return 'host_confirm_cancel_shoot_confirmed'
                }
            },
            {
                value: 'abandon',
                label: i18next.t('chatbot:commons:changed_mind'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'host_confirm_cancel_shoot_options',
                            source: CHATBOT_SOURCES.USER,
                            text: "I've changed my mind"
                        })
                    )
                    return isInShootPage ? '3' : 'start_generic'
                }
            }
        ]
    },
    {
        id: 'host_confirm_cancel_shoot_confirmed',
        component: <CancelShoot cancelBy="host" />,
        asMessage: true,
        replace: true,
        trigger: 'host_confirm_cancel_shoot_confirmed_sent'
    },
    {
        id: 'host_confirm_cancel_shoot_confirmed_sent',
        message: i18next.t('chatbot:mb14:mb1.4_cancel_sent'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'host_confirm_cancel_shoot_confirmed_sent',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thank you for letting us know the reason of the cancellation. We can confirm the photoshoot request has been canceled.'
                })
            )
            return 'send_log'
        }
    }
]

export const hostCancel = isInShootPage => [
    {
        id: 'MB1.4_host_cancel',
        component: <RequestAssignmentCancellation stepId="MB1.4_host_cancel" />,
        asMessage: true,
        trigger: 'send_log'
        /*options: [
            {
                value: 'mistake',
                label: i18next.t('chatbot:mb14:mb1.4_host_cancel1'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_host_cancel1'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_SIGNED_MISTAKE
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host requested this shoot by mistake'
                        })
                    )
                    return 'host_confirm_cancel_shoot_options_ask'
                }
            },
            {
                value: 'not_interested',
                label: i18next.t('chatbot:mb14:mb1.4_host_cancel2'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_host_cancel2'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_NOT_INTERESTED_ANYMORE
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host is no longer interested in the photoshoot'
                        })
                    )
                    return 'host_confirm_cancel_shoot_options_ask'
                }
            },
            {
                value: 'not_exists',
                label: i18next.t('chatbot:mb14:mb1.4_host_cancel3'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_host_cancel3'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_LISTING_DOESNT_EXIST_ANYMORE
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel',
                            source: CHATBOT_SOURCES.USER,
                            text: "The listing doesn't exist anymore"
                        })
                    )
                    return 'host_confirm_cancel_shoot_options_ask'
                }
            },
            {
                value: 'already_photographed',
                label: i18next.t('chatbot:mb14:mb1.4_host_cancel4'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_host_cancel4'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_LISTING_ALREADY_PHOTOGRAPHED
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The listing has been already photographed'
                        })
                    )
                    return 'host_confirm_cancel_shoot_options_ask'
                }
            },
            {
                value: 'not_ready',
                label: i18next.t('chatbot:mb14:mb1.4_host_cancel5'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_host_cancel5'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_LISTING_NOT_READY
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The listing is not ready'
                        })
                    )
                    return 'MB1.4_host_cancel.not_ready'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:cancellation_reasons:other_issues'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Other issues'
                        })
                    )
                    return 'MB1.4_options'
                }
            }
        ]*/
    },
    {
        id: 'MB1.4_host_cancel.not_ready',
        message: i18next.t('chatbot:mb14:mb1.4_host_cancel.not_ready'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_host_cancel.not_ready',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'What do you want to do?'
                })
            )
            return 'MB1.4_host_cancel.not_ready_options'
        }
    },
    {
        id: 'MB1.4_host_cancel.not_ready_options',
        options: [
            {
                value: 'keep_in_queue',
                label: i18next.t('chatbot:mb14:mb1.4_host_cancel.not_ready_keep_queue'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel.not_ready_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I want the opportunity to keep this photoshoot in my queue'
                        })
                    )
                    return 'MB1.4_host_cancel.not_ready.keep_queue'
                }
            },
            {
                value: 'cancel',
                label: i18next.t('chatbot:commons:want_cancel_photoshoot'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel.not_ready_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I want to cancel the photoshoot'
                        })
                    )
                    return 'host_confirm_cancel_shoot_confirmed'
                }
            }
        ]
    },
    {
        id: 'MB1.4_host_cancel.not_ready.keep_queue',
        asMessage: true,
        component: (
            <MultiParagraphMessage
                paragraphs={[
                    i18next.t('chatbot:mb14:mb1.4_host_cancel.not_ready.keep_queue1'),
                    i18next.t('chatbot:mb14:mb1.4_host_cancel.not_ready.keep_queue2'),
                    i18next.t('chatbot:mb14:mb1.4_host_cancel.not_ready.keep_queue3'),
                    i18next.t('chatbot:mb14:mb1.4_host_cancel.not_ready.keep_queue4'),
                    i18next.t('chatbot:mb14:mb1.4_host_cancel.not_ready.keep_queue5')
                ]}
            />
        ),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_host_cancel.not_ready.keep_queue',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text:
                        'If the Host is not ready to schedule the photoshoot date, go to the scheduling section and select the option " Host is not Ready to Schedule". ' +
                        'After you have selected this option, the photoshoot will be displayed in the "Require my Attention" section of your Mediabox under the tab "On Hold". ' +
                        'We also recommend that you ask the Host when they will be ready to schedule a date. ' +
                        'If the Host provides you with a date, add it in the following available section, and we will send reminders to you and the Host 7 days before the date you have selected. ' +
                        'If the Host doesn\'t share a reminder date, click the checkbox next to  "Host doesn\'t know when they will be ready" and we will set an automated reminder in 30 days. ' +
                        'You can review this guide for more information and details on the coordination system:'
                })
            )
            return 'MB1.4_host_cancel.not_ready.keep_queue_link'
        }
    },
    {
        id: 'MB1.4_host_cancel.not_ready.keep_queue_link',
        component: (
            <SimpleLink
                link="https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf"
                text={i18next.t('chatbot:commons:coordination_system_guide')}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_host_cancel.not_ready.keep_queue_link',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] Coordination system guide'
                })
            )
            return 'send_log'
        }
    },
    ...hostConfirmCancelShoot(isInShootPage)
]
