import {UserIcon} from '../../../../theme/icons'
import {Card} from '../../../../components/card/Card'
import {Trans, useTranslation} from 'react-i18next'
import {useCallback, useRef, useState} from 'react'
import {ChangePasswordModal} from '../../../../components/change-password-modal/ChangePasswordModal'
import {SuccessModal} from '../../../../components/success-modal/SuccessModal'
import {
    httpChangeAvailability,
    httpChangeSMSOption,
    httpUploadAvatar,
    httpChangeAvatar,
    httpChangeBio
} from '../../../../http-requests/user.http'
import {errorHandler} from '../../../../helpers/utils'
import {
    ChangePasswordButton,
    BetaChip,
    NotificationsWrapper,
    FullNameWrapper,
    AvailableToggleSwitch,
    SmsToggleSwitch,
    CardBodyWrapper,
    StyledTextAreaBioWrapper,
    StyledTextAreaBio,
    StyledChangePassword,
    StyledProfileInfoMessage
} from './style'
import {AvatarPicker} from './AvatarPicker'
import {useAsync} from '../../../../hooks/useAsync'
import {Flexbox} from '../../../../components/flexbox/Flexbox'
import {useTheme} from 'styled-components'
import {useUserStore} from '../../../../features/user/store'

export const YourInformation = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const setCurrentAvatar = useUserStore(store => store.setCurrentAvatar)
    const setCurrentUser = useUserStore(store => store.setCurrentUser)
    const setCurrentDescription = useUserStore(store => store.setCurrentDescription)
    const currentUser = useUserStore(store => store.currentUser)
    const accessToken = useUserStore(store => store.accessToken)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const {run: runChangeAvatar, isLoading: isAvatarChanging} = useAsync()
    const {run: runChangeDescription, isLoading: isDescriptionChanging} = useAsync()

    const [successModal, setSuccessModal] = useState({
        isOpen: false,
        paragraph: ''
    })

    const [currentBio, setCurrentBio] = useState(currentUser?.description ?? '')
    const smsCheckRef = useRef()
    const availableCheckRef = useRef()

    const setShowChangePasswordModalCb = useCallback(
        () => setShowChangePasswordModal(!showChangePasswordModal),
        [showChangePasswordModal]
    )
    const showSuccessModalCb = useCallback(
        () =>
            setSuccessModal({
                ...successModal,
                isOpen: !successModal
            }),
        [successModal]
    )
    const onPasswordChangedCb = useCallback(() => {
        setShowChangePasswordModal(false)
        setSuccessModal({
            isOpen: true,
            paragraph: t('commons:password_updated')
        })
    }, [t])

    const onChangeAvailability = async () => {
        try {
            const {data} = await httpChangeAvailability(accessToken, {
                shoot_available: +!currentUser?.shoot_available,
                region: currentUser?.region
            })
            setCurrentUser(data)
            setSuccessModal({
                isOpen: true,
                paragraph: t('settings:availability_updated')
            })
        } catch (e) {
            errorHandler(e)
            availableCheckRef.current.checked = !availableCheckRef.current.checked
        }
    }

    const onChangeNotifications = async () => {
        try {
            const {data} = await httpChangeSMSOption(accessToken, {optin_sms: !currentUser?.optin_sms})
            setCurrentUser(data)
        } catch (e) {
            errorHandler(e)
            smsCheckRef.current.checked = !availableCheckRef.current.checked
        }
    }

    const onChangeAvatar = async image => {
        try {
            const formData = new FormData()
            formData.append('image', image)
            formData.append('type', 'profile-photo')

            const {data: urlData} = await runChangeAvatar(httpUploadAvatar(accessToken, currentUser.id, formData))

            if (urlData?.url) {
                const {data} = await runChangeAvatar(httpChangeAvatar(accessToken, currentUser.id, urlData.url))
                setCurrentAvatar(data.profile_photo_url)
            }
        } catch (e) {
            errorHandler(e)
        }
    }

    const onRemoveAvatar = async () => {
        try {
            const {data} = await runChangeAvatar(httpChangeAvatar(accessToken, currentUser.id, null))
            setCurrentAvatar(data.profile_photo_url)
        } catch (e) {
            errorHandler(e)
        }
    }

    const onChangeBio = async () => {
        if (currentBio !== currentUser.description) {
            try {
                const {data} = await runChangeDescription(httpChangeBio(accessToken, currentUser.id, currentBio))
                setCurrentDescription(data.description)
            } catch (e) {
                errorHandler(e)
            }
        }
    }

    return (
        <Card icon={<UserIcon />} title="settings:your_information">
            {showChangePasswordModal && (
                <ChangePasswordModal onClose={setShowChangePasswordModalCb} onPasswordChangedCb={onPasswordChangedCb} />
            )}

            {successModal.isOpen && (
                <SuccessModal
                    onClose={showSuccessModalCb}
                    callback={showSuccessModalCb}
                    buttonLabel={t('commons:ok')}
                    paragraph={successModal.paragraph}
                />
            )}

            <AvailableToggleSwitch
                onChange={onChangeAvailability}
                label={t('settings:available')}
                isDefaultChecked={!!currentUser?.shoot_available}
                ref={availableCheckRef}
            />
            <CardBodyWrapper>
                <Flexbox gap={8} direction="column">
                    <AvatarPicker
                        currentAvatar={currentUser?.profile_photo_url}
                        onChange={onChangeAvatar}
                        onRemove={onRemoveAvatar}
                        isLoading={isAvatarChanging}
                    />

                    <FullNameWrapper>
                        <label>{t('commons:name')}</label>
                        <p title={currentUser?.name}>{currentUser?.name}</p>
                    </FullNameWrapper>

                    <StyledTextAreaBioWrapper direction="column">
                        <label>{t('settings:bio_label')}</label>
                        <StyledTextAreaBio
                            placeholder={t('settings:bio_placeholder')}
                            maxLength={80}
                            count={currentBio.length}
                            showCount={true}
                            rows={2}
                            value={currentBio}
                            onBlur={onChangeBio}
                            justifyHelpers="flex-end"
                            disabled={isDescriptionChanging}
                            onChange={e => setCurrentBio(e.target.value)}
                        />
                    </StyledTextAreaBioWrapper>

                    <StyledProfileInfoMessage>{t('settings:info_message')}</StyledProfileInfoMessage>
                </Flexbox>

                <NotificationsWrapper>
                    <StyledChangePassword justify="space-between">
                        <div>
                            <label>{t('commons:password')}</label>
                            <p>{'••••••••'}</p>
                        </div>
                        <ChangePasswordButton variant="secondary" onClick={setShowChangePasswordModalCb}>
                            {t('settings:change_password')}
                        </ChangePasswordButton>
                    </StyledChangePassword>

                    <div>
                        <label>
                            {t('settings:notifications')}
                            <BetaChip
                                borderColor="#E59700"
                                background="#E59700"
                                text={t('settings:beta')}
                                color={theme.palette.neutral.white}
                                size="small"
                                rounded
                            />
                        </label>
                        <p>
                            <Trans
                                i18nKey="settings:notifications_info"
                                components={[
                                    <a
                                        href="https://www.airbnb.com/help/article/2855/airbnb-privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    />
                                ]}
                            />
                        </p>
                    </div>
                    <SmsToggleSwitch
                        onChange={onChangeNotifications}
                        label={t('settings:sms')}
                        isDefaultChecked={!!currentUser?.optin_sms}
                        ref={smsCheckRef}
                    />
                </NotificationsWrapper>
            </CardBodyWrapper>
        </Card>
    )
}
