import {useQuery} from '@tanstack/react-query'
import {errorHandler} from '../../helpers/utils'
import {httpGetPhotographers} from '../../http-requests/admin/partners.http'
import {QUERY_KEYS} from '../../queryClient'
import {useUserStore} from '../../features/user/store'

export const useAdminPhotographers = (taskId, currentPhotographerId) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    const query = useQuery({
        queryKey: [QUERY_KEYS.PHOTOGRAPHERS, taskId],
        queryFn: async () => {
            const {data: payload} = await httpGetPhotographers(adminAccessToken, taskId)
            return payload
        },
        onError: errorHandler
    })

    const previousPhotographers = query?.data?.filter(obj => obj.id !== currentPhotographerId)

    return {query, previousPhotographers}
}
