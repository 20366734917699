import {
    StyledAccessibilityFeatureCounter,
    StyledAccessibilityFeatureCounterBadge,
    StyledAdminHostApprovalAccessibilityBody,
    StyledAdminHostApprovalAccessibilityFeatures,
    StyledAdminHostApprovalAccessibilityTitle
} from 'src/features/admin-host-approval/components/admin-host-approval-accessibility-features/style'
import {Button} from 'src/components/button/Button'
import {ChevronDownIcon, ChevronUpIcon} from 'src/components/icon'
import {useEffect, useMemo, useState} from 'react'
import {useAccessibilityFeatures} from 'src/features/admin-host-approval/services/useAccessibilityFeatures'
import {Skeleton} from 'src/components/skeleton/Skeleton'
import {useTranslation} from 'react-i18next'
import {useAdminHostApprovalStore} from 'src/features/admin-host-approval/store'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {AccessibilityFeature} from 'src/features/admin-host-approval/types'
import {ObjectValues} from 'src/helpers/helpers'
import {useAdminHostApprovalTaskImages} from '../../services/useAdminHostApprovalTaskImages'
import {Tooltip} from 'src/components/tooltip/Tooltip'

export const AdminHostApprovalAccessibilityFeatures = ({taskId}: {taskId: number}) => {
    const {t} = useTranslation()
    const [isAccessibilityFeaturesOpen, setIsAccessibilityFeaturesOpen] = useState(true)
    const accessibilityFeaturesCounters = useAdminHostApprovalStore(state => state.accessibilityFeaturesCounters)
    const initAccessibilityFeaturesCounters = useAdminHostApprovalStore(
        state => state.initaccessibilityFeaturesCounters
    )

    const {
        data: accessibilityFeatures,
        isLoading: isLoadingAccessibilityFeatures,
        isError
    } = useAccessibilityFeatures({
        staleTime: Infinity
    })

    const {
        data: taskImages,
        isLoading: isLoadingTaskImages,
        isError: isErrorTaskImages
    } = useAdminHostApprovalTaskImages(taskId, {
        enabled: false
    })

    useEffect(() => {
        if (accessibilityFeatures && !!taskImages) {
            initAccessibilityFeaturesCounters(accessibilityFeatures, taskImages)
        }
    }, [taskImages, accessibilityFeatures])

    const accessibilityFeaturesSelectedCount = useMemo(
        () =>
            accessibilityFeaturesCounters
                ? ObjectValues(accessibilityFeaturesCounters).filter(count => count > 0).length
                : 0,
        [accessibilityFeaturesCounters]
    )

    return (
        <StyledAdminHostApprovalAccessibilityFeatures>
            <Flexbox align="center" justify="space-between" fullWidth>
                {isLoadingAccessibilityFeatures || isLoadingTaskImages ? (
                    <Skeleton height={30} width={230} />
                ) : (
                    <StyledAdminHostApprovalAccessibilityTitle>
                        {t('host_approval_task:accessibility_features_selected', {
                            count: accessibilityFeaturesSelectedCount
                        })}
                    </StyledAdminHostApprovalAccessibilityTitle>
                )}
                <Button variant="tertiary" onClick={() => setIsAccessibilityFeaturesOpen(prev => !prev)}>
                    {isAccessibilityFeaturesOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Button>
            </Flexbox>

            {isAccessibilityFeaturesOpen && (
                <StyledAdminHostApprovalAccessibilityBody>
                    {isLoadingAccessibilityFeatures || isErrorTaskImages ? (
                        Array.from({length: AccessibilityFeature.shape['internal_key'].options.length}).map(
                            (_, index) => <Skeleton key={index} height={40} />
                        )
                    ) : !accessibilityFeaturesCounters || isError ? (
                        <p>{t('errors:default')}</p>
                    ) : (
                        accessibilityFeatures.map(accessibilityFeature => (
                            <StyledAccessibilityFeatureCounter
                                key={accessibilityFeature.id}
                                gap={2}
                                $isHighlight={accessibilityFeaturesCounters[accessibilityFeature.internal_key] > 0}
                                title={accessibilityFeature.name}
                            >
                                <StyledAccessibilityFeatureCounterBadge
                                    shape="circle"
                                    variant={
                                        accessibilityFeaturesCounters[accessibilityFeature.internal_key] > 0
                                            ? 'grayModernDark'
                                            : 'grayModernLight'
                                    }
                                >
                                    {accessibilityFeaturesCounters[accessibilityFeature.internal_key]}
                                </StyledAccessibilityFeatureCounterBadge>
                                <Tooltip content={accessibilityFeature.name} triggerProps={{asChild: true}}>
                                    <p>{accessibilityFeature.name}</p>
                                </Tooltip>
                            </StyledAccessibilityFeatureCounter>
                        ))
                    )}
                </StyledAdminHostApprovalAccessibilityBody>
            )}
        </StyledAdminHostApprovalAccessibilityFeatures>
    )
}
