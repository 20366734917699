import {Modal} from 'src/components/modal-legacy/Modal'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {useDropzone} from 'react-dropzone'
import {
    httpBulkDeleteImages,
    httpConfirmUploadImage,
    httpDeleteImage,
    httpGetUploadImagesLinks,
    httpGetUploadedImages,
    httpUploadMedia
} from 'src/http-requests/single-assignment.http'
import {errorHandler} from 'src/helpers/helpers'
import {
    StyledProgressBarWrapper,
    StyledUploadingPhotos,
    StyledPhotoCard,
    StyledPhotosUploadModalBody,
    StyledPhotosUploadModalFooter,
    StyledPhotosUploadModalHeader,
    StyledPhotosUploadSectionTitle,
    StyledDropzoneInfoBanner
} from './style'
import {useEffect, useMemo, useRef, useState} from 'react'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {PhotosDropzone} from '../photos-dropzone/PhotosDropzone'
import {GroupedVirtuoso} from 'react-virtuoso'
import {TASK_STATUSES} from 'src/helpers/constants'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync'
import {QUERY_ACTION_KEYS, QUERY_KEYS} from 'src/queryClient'
import {showAlert} from 'src/store/appGenerics'
import {useAsync} from 'src/hooks/useAsync'
import {PhotosUploadProgress} from './PhotosUploadProgress'
import {uniqueId} from 'lodash-es'
import {useUserStore} from 'src/features/user/store'
import {useAssignmentsCount} from 'src/features/assignments/services/useAssignmentsCount'
import {useQueryClient} from '@tanstack/react-query'
import {useParams} from 'react-router'
import {Button} from 'src/components/button/Button'
import {AlertCircleIcon, AlertTriangleIcon, CheckIcon, Trash01Icon, XCloseIcon} from 'src/components/icon'
import {Spinner} from 'src/components/spinner/Spinner'
import {Checkbox} from 'src/components/checkbox/Checkbox'

export const PhotosUploadModal = ({assignment, maxFiles, onClose, taskStatus, canSendToAirbnb, onSubmit}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {data: shootsCount, updateShootsCount} = useAssignmentsCount()
    const [uploadState, setUploadState] = useState('pending') //pending, uploading, uploaded
    const [files, setFiles] = useState([])
    const [deletingPhoto, setDeletingPhoto] = useState(null) // file.id
    const accessToken = useUserStore(store => store.accessToken)
    const selectAllCheckboxRef = useRef(null)

    const {code: stringCode} = useParams()
    const code = parseInt(stringCode)

    const queryClient = useQueryClient()

    const dropzone = useDropzone({
        disabled: uploadState === 'uploading',
        accept: {
            'image/x-adobe-dng': ['.dng']
        },
        multiple: true,
        maxFiles,
        onDropAccepted: files =>
            setFiles(prev => [
                ...files.map(file =>
                    Object.assign(file, {
                        isSelected: false,
                        uploadStatus: null, //null, loading, success, error
                        uploadProgress: 0,
                        localId: uniqueId(),
                        local: true // used to identify remote and local images
                    })
                ),
                ...prev
            ]),
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > maxFiles) {
                dispatch(
                    showAlert({
                        message: t('single_shooting:upload_photo_limit_error', {maxFiles}),
                        level: 'error'
                    })
                )
            }
        }
    })

    const {run: runGetUploadedPhotos, isLoading: isLoadingUploadedPhotos} = useAsync()
    const {run: runBulkDeletePhotos, isLoading: isLoadingBulkDeletePhotos} = useAsync()
    const {run: runDeleteSinglePhoto} = useAsync()

    const filesToUpload = useMemo(() => {
        return files.filter(file => file.local)
    }, [files])

    const listSections = [filesToUpload.length, files.length - filesToUpload.length].filter(Boolean)

    // Get the uploaded photos list and set it into state
    const getUploadedPhotos = async () => {
        try {
            const {data} = await runGetUploadedPhotos(httpGetUploadedImages(accessToken, assignment.id))
            setFiles(data.media)
        } catch (error) {
            errorHandler(error)
        }
    }

    // Fn runned on the very first upload
    const onUploadFirstPhoto = () => {
        queryClient.setQueryData([QUERY_KEYS.ASSIGNMENT, code], prev => {
            return {
                ...prev,
                can_send_to_airbnb: true
            }
        })

        updateShootsCount({
            ...shootsCount,
            assignments_current: shootsCount?.assignments_current - 1,
            ...(taskStatus === TASK_STATUSES.PENDING_ASSETS
                ? {pending_assets: shootsCount?.pending_assets - 1}
                : {need_more_assets: shootsCount?.pending_assets - 1})
        })
        invalidationSignal(QUERY_ACTION_KEYS.ASSETS_UPLOADED)
    }

    // Function to handle uploading a single photo
    const uploadSinglePhoto = async (presignedItem, file, next) => {
        try {
            // Uploads a single photo and updates the upload progress
            await httpUploadMedia(accessToken, presignedItem.presigned_url, file, progressEvent => {
                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                setFiles(prevState =>
                    prevState.map(prevFile => {
                        if (file.localId === prevFile.localId) {
                            return Object.assign(prevFile, {uploadProgress: percentage, uploadStatus: 'loading'})
                        }
                        return prevFile
                    })
                )
            })

            //confirm upload
            await httpConfirmUploadImage(accessToken, assignment.id, presignedItem.media.id)

            //set success state to the file
            setFiles(prevState =>
                prevState
                    .map(prevFile => {
                        if (
                            file?.localId === prevFile?.localId
                            /*
                             * This check has been removed because the progress loaded event at 100% occurs after the
                             * httpConfirmUploadImage response in the production build, causing a setState leak.
                             */
                            // && file?.uploadProgress === 100
                        ) {
                            return Object.assign(prevFile, {
                                id: presignedItem.media.id,
                                uploadStatus: 'success',
                                local: false
                            })
                        }

                        return prevFile
                    })
                    .sort((aFile, bFile) => {
                        if (aFile.local && !bFile.local) {
                            return -1 // aFile comes before bFile
                        } else if (!aFile.local && bFile.local) {
                            return 1 // bFile comes before aFile
                        } else {
                            return 0 // maintain the order
                        }
                    })
            )

            queryClient.setQueryData([QUERY_KEYS.ASSIGNMENT, code], prev => {
                return {
                    ...prev,
                    can_send_to_airbnb: prev.medias_to_upload - 1
                }
            })

            if (!canSendToAirbnb) {
                onUploadFirstPhoto()
            }

            if (next) {
                next()
            }
        } catch (error) {
            console.error(error)

            //set error state to the file
            setFiles(prevState =>
                prevState.map(prevFile => {
                    if (file.localId === prevFile.localId) {
                        return Object.assign(prevFile, {uploadStatus: 'error'})
                    }
                    return prevFile
                })
            )

            if (next) {
                next()
            }
        }
    }

    // Function to upload photos
    const uploadPhotos = async fileGroups => {
        // Uploads photos in groups
        let groupIndexToUpload = 0

        // This allows to go through the each photos group
        function nextGroup() {
            groupIndexToUpload += 1
            uploadPhoto()
        }

        // This allows to upload each photo one by one
        async function uploadPhoto() {
            const filesGroup = fileGroups?.[groupIndexToUpload]
            if (filesGroup) {
                try {
                    //get all the group links
                    const {data: presignedItems} = await httpGetUploadImagesLinks(accessToken, assignment.id, {
                        medias: filesGroup.map(file => file.name)
                    })
                    for (const [index, presignedItem] of presignedItems.entries()) {
                        uploadSinglePhoto(presignedItem, filesGroup[index], nextGroup)
                    }
                } catch (error) {
                    errorHandler(error)
                }
            }

            if (groupIndexToUpload === filesToUpload.length) {
                setUploadState('uploaded')
            }
        }

        // Invoke the single uploadPhoto fn until all the groups are over
        await uploadPhoto()
    }

    // Function to run a bulk delete
    const deletePhotos = async () => {
        // Deletes selected photos
        const filesToDelete = files.filter(file => !file.local && file.isSelected).map(file => file.id)

        try {
            await runBulkDeletePhotos(
                httpBulkDeleteImages(accessToken, assignment.id, {
                    ids: filesToDelete
                })
            )
        } catch (error) {
            errorHandler(error)
        }

        const updatedFilesList = files
            .filter(file => !file.isSelected)
            .map(file => Object.assign(file, {isSelected: false}))

        setFiles(updatedFilesList)
        queryClient.setQueryData([QUERY_KEYS.ASSIGNMENT, code], prev => {
            return {
                ...prev,
                medias_to_upload: prev.medias_to_upload + filesToDelete.length
            }
        })

        if (updatedFilesList.length === 0) {
            setUploadState('pending')
            queryClient.setQueryData([QUERY_KEYS.ASSIGNMENT, code], prev => {
                return {
                    ...prev,
                    can_send_to_airbnb: false
                }
            })
        }
    }

    // Function to delete a single photo
    const deleteSinglePhoto = async (file, isLocal) => {
        if (!isLocal) {
            try {
                setDeletingPhoto(isLocal ? file.localId : file.id)
                await runDeleteSinglePhoto(httpDeleteImage(accessToken, assignment.id, file.id))
            } catch (error) {
                errorHandler(error)
            }
        }

        const updatedFilesList = files.filter(prevFile =>
            isLocal ? prevFile.localId !== file.localId : prevFile.id !== file.id
        )

        setDeletingPhoto(null)
        setFiles(updatedFilesList)
        queryClient.setQueryData([QUERY_KEYS.ASSIGNMENT, code], prev => {
            return {
                ...prev,
                medias_to_upload: prev.medias_to_upload + 1
            }
        })

        if (updatedFilesList.length === 0) {
            setUploadState('pending')
            queryClient.setQueryData([QUERY_KEYS.ASSIGNMENT, code], prev => {
                return {
                    ...prev,
                    can_send_to_airbnb: false
                }
            })
        }
    }

    // This split the photos list into groups to run a stack upload
    const generateFileGroups = filesToUpload => {
        //split the files array in groups
        const largeGroupSize = 5
        const smallGroupSize = 3

        let fileGroups = [filesToUpload.slice(0, largeGroupSize)]
        if (filesToUpload.length > largeGroupSize) {
            let otherFiles = filesToUpload.slice(largeGroupSize)
            const threeFilesQueue = Array.from({length: Math.ceil(otherFiles.length / smallGroupSize)}, (_, index) =>
                otherFiles.slice(index * smallGroupSize, index * smallGroupSize + smallGroupSize)
            )

            fileGroups.push(...threeFilesQueue)
        }

        return fileGroups
    }

    const onConfirm = async () => {
        setUploadState('uploading')
        const fileGroups = generateFileGroups(filesToUpload)
        await uploadPhotos(fileGroups)
    }

    const onRetry = async () => {
        setUploadState('uploading')
        const errorFilesToUpload = files.filter(file => file.uploadStatus === 'error')
        const fileGroups = generateFileGroups(errorFilesToUpload)
        await uploadPhotos(fileGroups)
    }

    useEffect(() => {
        getUploadedPhotos()
    }, [])

    useEffect(() => {
        if (selectAllCheckboxRef?.current) {
            const every = files.every(file => file.isSelected)
            const some = files.some(file => file.isSelected)
            selectAllCheckboxRef.current.indeterminate = !every && some
        }
    }, [files, selectAllCheckboxRef])

    return (
        <Modal onClose={uploadState === 'uploading' ? Function.prototype : onClose} width={'720px'}>
            <StyledPhotosUploadModalHeader>
                <Button
                    disabled={uploadState === 'uploading'}
                    variant="ghost"
                    onClick={onClose}
                    size="sm"
                    shape="square"
                >
                    <XCloseIcon />
                </Button>
                {t('single_shooting:upload_raw_photos')}
            </StyledPhotosUploadModalHeader>

            <StyledPhotosUploadModalBody
                $hasFiles={files.length >= 1}
                $isUploadStatePending={uploadState === 'pending'}
            >
                {uploadState === 'pending' && (
                    <Flexbox gap={5} direction="column">
                        <PhotosDropzone dropzone={dropzone} files={files} isDisabled={uploadState === 'uploading'} />

                        <StyledDropzoneInfoBanner gap={1.25} align="center">
                            <AlertCircleIcon size={24} />
                            <p>{t('single_shooting:dropzone_banner')}</p>
                        </StyledDropzoneInfoBanner>
                    </Flexbox>
                )}

                {files.length >= 1 && (
                    <StyledUploadingPhotos>
                        {(uploadState === 'uploading' || uploadState === 'uploaded') && (
                            <PhotosUploadProgress files={files} uploadState={uploadState} />
                        )}

                        {uploadState === 'pending' && (
                            <Flexbox justify="space-between" align="center" gap={2}>
                                <Checkbox
                                    id="select-all"
                                    ref={selectAllCheckboxRef}
                                    checked={files.every(file => file.isSelected)}
                                    label={t('single_shooting:select_all')}
                                    disabled={uploadState === 'uploading'}
                                    onChange={event => {
                                        const isChecked = event.target.checked
                                        if (isChecked) {
                                            setFiles(prevState =>
                                                prevState.map(file => Object.assign(file, {isSelected: true}))
                                            )
                                        } else {
                                            setFiles(prevState =>
                                                prevState.map(file => Object.assign(file, {isSelected: false}))
                                            )
                                        }
                                    }}
                                />

                                <Button
                                    variant="secondary"
                                    disabled={uploadState === 'uploading' || files.every(file => !file.isSelected)}
                                    onClick={deletePhotos}
                                >
                                    {t('single_shooting:delete_selected')}
                                </Button>
                            </Flexbox>
                        )}

                        <GroupedVirtuoso
                            style={{height: '750px', maxHeight: '100%'}}
                            groupCounts={listSections}
                            groupContent={index => (
                                <StyledPhotosUploadSectionTitle>
                                    {files?.[listSections[index] - filesToUpload.length]?.local ? (
                                        <p>
                                            {t('single_shooting:to_be_uploaded')} ({listSections[index]})
                                        </p>
                                    ) : (
                                        <p>
                                            {t('single_shooting:already_uploaded')} ({listSections[index]})
                                        </p>
                                    )}
                                </StyledPhotosUploadSectionTitle>
                            )}
                            itemContent={index => {
                                const file = files[index]
                                return (
                                    <StyledPhotoCard gap={1} align="center" key={file.id}>
                                        {uploadState === 'pending' && (
                                            <Checkbox
                                                id={`select-${index}`}
                                                disabled={
                                                    (isLoadingBulkDeletePhotos && file.isSelected) ||
                                                    deletingPhoto === file.id ||
                                                    deletingPhoto === file.localId
                                                }
                                                checked={file.isSelected}
                                                onChange={event => {
                                                    const isChecked = event.target.checked

                                                    setFiles(prevState =>
                                                        prevState.map(prevFile => {
                                                            if (file.local) {
                                                                if (prevFile.localId === file.localId) {
                                                                    return Object.assign(file, {isSelected: isChecked})
                                                                }
                                                            } else {
                                                                if (prevFile.id === file.id) {
                                                                    return Object.assign(file, {isSelected: isChecked})
                                                                }
                                                            }

                                                            return prevFile
                                                        })
                                                    )
                                                }}
                                            />
                                        )}
                                        {file.uploadStatus === 'success' && <CheckIcon size={16} />}
                                        {file.uploadStatus === 'error' && <AlertTriangleIcon size={16} />}
                                        {file.uploadStatus === 'loading' && <Spinner size={16} />}
                                        <p title={file.name}>{file.name}</p>
                                        {file.uploadStatus === 'error' ? (
                                            <Button
                                                disabled={uploadState === 'uploading'}
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => onRetry()}
                                            >
                                                {t('commons:retry')}
                                            </Button>
                                        ) : uploadState !== 'pending' && file.local ? (
                                            <StyledProgressBarWrapper
                                                align="center"
                                                gap={1.5}
                                                $width="33%"
                                                $uploadProgress={file.uploadProgress}
                                            >
                                                <div>
                                                    <div />
                                                </div>
                                            </StyledProgressBarWrapper>
                                        ) : (
                                            false
                                        )}

                                        {!isLoadingBulkDeletePhotos &&
                                            deletingPhoto !== file.id &&
                                            file.uploadStatus !== 'error' &&
                                            (file.uploaded_at || (file.localId && uploadState !== 'uploading')) && (
                                                <div className="trash-btn-container">
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        shape="square"
                                                        onClick={() => deleteSinglePhoto(file, file.local)}
                                                    >
                                                        <Trash01Icon />
                                                    </Button>
                                                </div>
                                            )}

                                        {(deletingPhoto === file.id ||
                                            deletingPhoto === file.localId ||
                                            (isLoadingBulkDeletePhotos && file.isSelected)) && <Spinner size={18} />}
                                    </StyledPhotoCard>
                                )
                            }}
                        />
                    </StyledUploadingPhotos>
                )}

                {isLoadingUploadedPhotos && <Spinner size={32} />}
            </StyledPhotosUploadModalBody>

            <StyledPhotosUploadModalFooter justify="space-between" gap={2}>
                {uploadState === 'uploaded' ? (
                    <>
                        <Button variant="tertiary" onClick={onClose} disabled={uploadState === 'uploading'}>
                            {t('commons:close')}
                        </Button>
                        <Button onClick={onSubmit}>{t('commons:submit')}</Button>
                    </>
                ) : (
                    <>
                        <Button variant="tertiary" onClick={onClose} disabled={uploadState === 'uploading'}>
                            {t('commons:cancel')}
                        </Button>
                        <Button
                            onClick={onConfirm}
                            disabled={files.length === 0 || uploadState === 'uploading' || !filesToUpload.length}
                        >
                            {t('single_shooting:confirm_upload')}
                            {uploadState === 'uploading' && <Spinner size={32} />}
                        </Button>
                    </>
                )}
            </StyledPhotosUploadModalFooter>
        </Modal>
    )
}
