import styled, {css} from 'styled-components'
import {spacing} from 'src/theme/theme.ts'

export const DESKTOP_TABLE_COLUMNS_PADDING = `${spacing * 4}px`
export const DESKTOP_TABLE_SCROLLBAR_WIDTH = '4px'

export const StyledDesktopTable = styled.div(
    () => css`
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
    `
)
