import styled, {css} from 'styled-components'
import {CloseIcon} from '../../../../theme/icons'

export const StyledTask = styled.div`
    overflow: auto;
`

export const StyledFullscreenImage = styled.div`
    z-index: 30;
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        object-fit: contain;
        max-width: calc(100% - 32px);
        max-height: calc(100% - 32px);
        position: relative;
        z-index: 2;
    }
`

export const StyledFullscreenOverlay = styled.div`
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
`

export const StyledCloseIcon = styled(CloseIcon)`
    ${({theme: {palette}}) => css`
        z-index: 4;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 32px;
        fill: ${palette.neutral.white};
        cursor: pointer;
    `}
`
