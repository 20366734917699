import {FC, HTMLAttributes} from 'react'

export const HashtagIcon: FC<{size?: number; fill?: string} & HTMLAttributes<SVGSVGElement>> = ({
    size = 16,
    fill = 'currentColor'
}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.88921 1.11717C7.25515 1.15783 7.51884 1.48745 7.47818 1.85338L7.11599 5.1131H9.77445L10.153 1.70614C10.1937 1.3402 10.5233 1.07651 10.8892 1.11717C11.2552 1.15783 11.5188 1.48745 11.4782 1.85338L11.116 5.1131H13.4823C13.8504 5.1131 14.1489 5.41157 14.1489 5.77976C14.1489 6.14795 13.8504 6.44643 13.4823 6.44643H10.9678L10.6715 9.1131H13.4823C13.8504 9.1131 14.1489 9.41157 14.1489 9.77976C14.1489 10.148 13.8504 10.4464 13.4823 10.4464H10.5234L10.1448 13.8534C10.1042 14.2193 9.77458 14.483 9.40864 14.4424C9.0427 14.4017 8.77901 14.0721 8.81967 13.7061L9.18186 10.4464H6.5234L6.14485 13.8534C6.10419 14.2193 5.77458 14.483 5.40864 14.4424C5.0427 14.4017 4.77901 14.0721 4.81967 13.7061L5.18186 10.4464H2.81559C2.4474 10.4464 2.14893 10.148 2.14893 9.77976C2.14893 9.41157 2.4474 9.1131 2.81559 9.1131H5.33001L5.6263 6.44643H2.81559C2.4474 6.44643 2.14893 6.14795 2.14893 5.77976C2.14893 5.41157 2.4474 5.1131 2.81559 5.1131H5.77445L6.153 1.70614C6.19366 1.3402 6.52328 1.07651 6.88921 1.11717ZM9.33001 9.1131L9.6263 6.44643H6.96784L6.67155 9.1131H9.33001Z"
            fill={fill}
        />
    </svg>
)
