import styled, {css} from 'styled-components'
import {LanguageRadio} from './language-radio/LanguageRadio'

export const StyledRoot = styled.div`
    ${({theme: {colors, transition}}) => css`
        & > div {
            ${transition};

            &:not(:first-child) {
                border-top: 1px solid ${colors.lightGrey};
            }

            &:hover {
                background: ${colors.lightGrey};
            }
        }
    `}
`

export const StyledRadio = styled(LanguageRadio)`
    ${({theme: {colors, typography, spacing, palette}, isSelected}) => css`
        padding: ${spacing * 4}px;
        justify-content: space-between !important;
        align-items: center !important;
        flex-direction: row-reverse;
        ${isSelected &&
        css`
            background: ${palette.primary['600']};
        `}

        & span {
            ${typography.textLg};
            font-weight: 500;
            color: ${isSelected ? palette.neutral.white : colors.lightBlack};
        }

        & input {
            width: 24px !important;
            height: 24px !important;
        }
    `}
`
