import {useTranslation} from 'react-i18next'
import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms'
import {UploadButtons} from 'src/features/assignment/components/upload-buttons/UploadButtons'
import {FC} from 'react'
import {Assignment, ServiceType} from 'src/features/assignment/types'
import {WalkthroughFormButton} from 'src/features/assignment/components/walkthrough-form-button/WalkthroughFormButton'
import {AssignmentVideoButtons} from 'src/features/assignment/components/assignment-video-buttons/AssignmentVideoButtons'

export const UploadAssetsDescription: FC<{
    assignment: Assignment
    serviceType: ServiceType
}> = ({assignment, serviceType}) => {
    const {t} = useTranslation()

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {serviceType == 'walkthrough'
                        ? t('assignment:submit_form')
                        : t('assignment:descriptions:pending_assets:title')}
                </AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:pending_assets:paragraph:${serviceType}`)}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            {assignment.task_media.name == 'walkthrough' ? (
                <WalkthroughFormButton assignment={assignment} />
            ) : assignment.task_category.name == 'Experiences' && assignment.task_media.name == 'video' ? (
                <AssignmentVideoButtons assignment={assignment} />
            ) : (
                <UploadButtons assignment={assignment} />
            )}
        </AssignmentDescriptionWrapper>
    )
}
