import {useCallback, useState} from 'react'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {FormCard} from '../../../components/form-card/FormCard'
import {ROUTES} from '../../../helpers/constants'
import {PageHelmet} from '../../../components/page-helmet/PageHelmet'
import {SuccessModal} from '../../../components/success-modal/SuccessModal'
import {httpForgotPassword} from '../../../http-requests/auth.http'
import {errorHandler} from '../../../helpers/utils'
import {StyledForgotPassword, StyledForgotPasswordContainer} from './style'

export const ForgotPassword = () => {
    const {t} = useTranslation()
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const formFields = [{name: 'email', type: 'email', label: t('commons:email')}]

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('errors:email_invalid')).required(t('errors:required'))
    })

    const setShowSuccessModalCb = useCallback(() => setShowSuccessModal(!showSuccessModal), [showSuccessModal])

    const onSubmit = async formValues => {
        try {
            setIsLoading(true)
            await httpForgotPassword(formValues)
            setShowSuccessModal(true)
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <StyledForgotPassword>
            <PageHelmet title={t('titles:forgot_password')} />
            {showSuccessModal && (
                <SuccessModal
                    onClose={setShowSuccessModalCb}
                    callback={setShowSuccessModalCb}
                    buttonLabel={t('commons:ok')}
                    paragraph="auth:email_sent"
                />
            )}

            <StyledForgotPasswordContainer>
                <FormCard
                    title={t('auth:forgot_password')}
                    subtitle={t('auth:enter_email')}
                    buttonText={t('commons:submit')}
                    linkText={t('auth:login')}
                    fields={formFields}
                    validationSchema={validationSchema}
                    onSubmitCb={onSubmit}
                    linkRoute={ROUTES.LOGIN}
                    isLoading={isLoading}
                />
            </StyledForgotPasswordContainer>
        </StyledForgotPassword>
    )
}
