import {useTranslation} from 'react-i18next'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    AssignmentCardSubtitle,
    AssignmentCardTitle,
    AssignmentCardWrapper
} from 'src/features/assignment/components/assignment-card-atoms/AssignmentCardAtoms'
import {AssignmentNotesCardForm} from 'src/features/assignment/components/assignment-notes-card/AssignmentNotesCardForm'
import {FC} from 'react'
import {Assignment} from 'src/features/assignment/types'

export const AssignmentNotesCard: FC<{assignment: Assignment}> = ({assignment}) => {
    const {t} = useTranslation()

    return (
        <AssignmentCardWrapper>
            <Flexbox direction="column" gap={1}>
                <AssignmentCardTitle>{t('assignment:notes_card:title')}</AssignmentCardTitle>
                <AssignmentCardSubtitle>{t('assignment:notes_card:subtitle')}</AssignmentCardSubtitle>
            </Flexbox>

            <AssignmentNotesCardForm assignment={assignment} />
        </AssignmentCardWrapper>
    )
}
