import {StyledSmall, StyledSmallError} from 'src/components/input-help-text/style.ts'
import {HTMLAttributes, ReactNode} from 'react'

interface InputHelpTextProps extends HTMLAttributes<HTMLElement> {
    error?: ReactNode
    helpText?: ReactNode
}
export const InputHelpText = ({error, helpText, ...rest}: InputHelpTextProps) => {
    return (
        <>
            {error && <StyledSmallError {...rest}>{error}</StyledSmallError>}
            {helpText && !error && <StyledSmall {...rest}>{helpText}</StyledSmall>}
        </>
    )
}

InputHelpText.displayName = 'InputHelpText'
