import styled, {css} from 'styled-components'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'

export const StyledPhotosUploadModalHeader = styled.h3(
    ({theme: {colors, typography, spacing}}) => css`
        color: ${colors.lightBlack};
        ${typography.textSm};
        font-weight: 700;
        padding: ${spacing * 4.5}px ${spacing * 6}px;
        text-align: center;
        position: relative;
        button {
            position: absolute;
            top: ${spacing * 2.5}px;
            left: ${spacing * 2}px;
        }
    `
)

export const StyledPhotosUploadModalBody = styled.div<{$hasFiles: boolean; $isUploadStatePending: boolean}>(
    ({theme: {spacing}, $hasFiles, $isUploadStatePending}) => css`
        padding: ${spacing * 6}px;
        max-height: 75vh;
        overflow-y: hidden;
        display: grid;
        gap: ${spacing * 6}px;
        grid-template-rows: ${$hasFiles && $isUploadStatePending ? 'auto minmax(0, 1fr)' : 'minmax(0, 1fr)'};
    `
)

export const StyledDropzoneInfoBanner = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm};
        padding: ${spacing * 1.5}px ${spacing * 2}px;
        font-weight: 400;
        background-color: ${palette.blue['50']};
        border: 1px solid ${palette.blue['300']};
        border-radius: 16px;
        width: 100%;
        & svg {
            fill: ${palette.blue['600']};
        }
    `
)

export const StyledPhotosUploadModalFooter = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        border-top: 1px solid ${palette.neutral['300']};
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)

export const StyledUploadingPhotos = styled.div(
    ({theme: {colors, typography, spacing}}) => css`
        display: grid;
        gap: ${spacing * 4}px;
        grid-template-rows: auto minmax(0, 1fr);
        overflow: hidden;

        h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
        b {
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
    `
)

export const StyledProgressBarWrapper = styled(Flexbox)<{$uploadProgress: number; $width: string}>(
    ({theme: {colors, typography}, $uploadProgress, $width}) => css`
        width: ${$width};
        flex-shrink: 0;
        div {
            height: 8px;
            border-radius: 4px;
        }
        & > div {
            background-color: ${colors.mercuryGrey};
            flex-grow: 1;
            & div {
                background-color: ${colors.airbnbRed};
                width: ${$uploadProgress}%;
            }
        }
        span {
            width: 34px;
            text-align: right;
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.lightBlack};
        }
    `
)

export const StyledPhotoCard = styled(Flexbox)(
    ({theme: {colors, typography, spacing}}) => css`
        border-bottom: 1px solid ${colors.mercuryGrey};
        padding: ${spacing * 2}px;
        height: 45px;

        & > p {
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.lightBlack};
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-grow: 1;
        }

        &:not(:hover) > .trash-btn-container {
            opacity: 0;
            pointer-events: none;
        }
    `
)

export const StyledPhotosUploadSectionTitle = styled.div(
    ({theme: {palette, spacing}}) => css`
        padding: ${spacing * 4}px 0;
        background-color: ${palette.neutral['white']};
        /* The following instructions are added to address the GroupedVirtuoso section title UI issue, 
    which showed a 1px margin at the top, which becomes visible when scrolling through the list */
        position: relative;
        top: -1px;
    `
)
