import {useState} from 'react'
import dayjs from 'dayjs'
import {errorHandler, formatLocaleDate, getMonthAndYear} from '../helpers/utils'
import {SHOOT_DETAILS} from '../helpers/constants'
import {QueryObserver, useQuery, useQueryClient} from '@tanstack/react-query'
import {httpGetActiveShootsByRange} from '../http-requests/assignments.http'
import {QUERY_KEYS} from '../queryClient'
import {useUserStore} from '../features/user/store'

export const useActiveShootsByRange = () => {
    const accessToken = useUserStore(store => store.accessToken)
    const [fetchedMonths, setFetchedMonths] = useState([
        getMonthAndYear(dayjs().subtract(1, 'month')),
        getMonthAndYear(new Date()),
        getMonthAndYear(dayjs().add(1, 'month'))
    ])
    const [range, setRange] = useState({
        start_date: formatLocaleDate(
            dayjs(new Date()).subtract(1, 'month').startOf('month').add(0, 'hour').add(0, 'minutes'),
            'YYYY-MM-DD HH:mm:ss'
        ),
        end_date: formatLocaleDate(
            dayjs(new Date()).add(1, 'month').endOf('month').add(23, 'hour').add(59, 'minutes'),
            'YYYY-MM-DD HH:mm:ss'
        )
    })
    const queryClient = useQueryClient()
    const observer = new QueryObserver(queryClient, {
        queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE, range]
    })
    const isPreviousEventsStale = observer.getCurrentResult().isStale
    const [events, setEvents] = useState(isPreviousEventsStale ? [] : observer.getCurrentResult().data ?? [])

    const {isLoading, isRefetching, isError} = useQuery({
        queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE, range],
        queryFn: async () => {
            const {data: response} = await httpGetActiveShootsByRange(range, accessToken)
            const newData = response ?? []

            const dataToReturn = [
                ...events,
                ...newData.map(dataItem => ({
                    title: dataItem?.host?.first_name,
                    start: new Date(formatLocaleDate(dataItem.to_shoot_at, 'YYYY/MM/DD HH:mm')),
                    end: new Date(dayjs(dataItem.to_shoot_at).add(SHOOT_DETAILS.DURATION, 'h')),
                    resource: dataItem
                }))
            ]

            setEvents(dataToReturn)
            return dataToReturn
        },
        onError: errorHandler
    })

    const onChangeRange = (start, end) => {
        setRange({
            start_date: formatLocaleDate(
                dayjs(new Date(start)).add(0, 'hour').add(0, 'minutes'),
                'YYYY-MM-DD HH:mm:ss'
            ),
            end_date: formatLocaleDate(dayjs(new Date(end)).add(23, 'hour').add(59, 'minutes'), 'YYYY-MM-DD HH:mm:ss')
        })
        setFetchedMonths([...fetchedMonths, getMonthAndYear(start)])
    }

    return {
        data: events ?? [],
        loadingRange: isRefetching || isLoading ? range : null,
        isError,
        fetchedMonths,
        onChangeRange
    }
}
