import styled, {css} from 'styled-components'
import {Container} from 'src/components/container/Container.tsx'

export const StyledAdminHosts = styled(Container)(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 15}px;
        padding-bottom: ${spacing * 15}px;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${spacing * 8}px;
        overflow: hidden;
    `
)

export const StyledHeading = styled.div(
    ({theme: {typography}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        & h1 {
            ${typography.displayXs};
            font-weight: 600;
        }
    `
)
