import styled, {css} from 'styled-components'

export const StyledTextareaContainer = styled.label`
    width: 100%;
`

export const StyledLabel = styled.span`
    ${({theme: {typography, colors, spacing}}) => css`
        display: block;
        ${typography.textSm};
        font-weight: 400;
        color: ${colors.lightBlack};
        margin-bottom: ${spacing}px;
    `}
`

export const StyledTextarea = styled.textarea<{$errors: boolean}>`
    ${({theme: {colors, palette, spacing, typography, mediaQuery, transition}, $errors}) => css`
        position: relative;
        width: 100%;
        ${typography.textSm};
        color: ${colors.lightBlack};
        ${transition};
        background: transparent;
        font-weight: 400;
        border: 1px solid ${colors.inputGrey};
        border-radius: 8px;
        resize: none;
        padding: ${spacing * 2}px;
        min-height: 120px;
        &:-webkit-autofill {
            -webkit-background-clip: text;
        }
        &::placeholder {
            color: ${colors.grey};
            font-weight: 300;
        }

        &:focus {
            outline-width: 0;
            border: 1px solid ${palette.neutral.black};
            ${$errors &&
            css`
                border: 1px solid ${colors.red};
                outline: 0;
                border-radius: 8px;
            `}
            outline: 0;
        }

        ${mediaQuery.SM} {
            height: 40px;
            &:focus {
                padding: 0 ${spacing * 2}px;
                border-bottom-width: 0;
                border-radius: 24px;
                border-bottom-color: ${palette.neutral.white};
                background: ${colors.lightBlue};
            }
        }

        ${$errors &&
        css`
            position: relative;
            width: 100%;
            ${typography.textSm};
            font-weight: 500;
            padding: ${spacing * 2}px;
            ${transition};
            background: transparent;
            color: ${colors.red};
            border: 1px solid ${colors.red} !important;
            &:-webkit-autofill {
                -webkit-background-clip: text;
            }
            &::placeholder {
                color: ${colors.grey};
                font-weight: 300;
            }
            &:focus {
                outline-width: 0;
            }
            ${mediaQuery.SM} {
                height: 40px;
                &:focus {
                    padding: 0 ${spacing * 2}px;
                    border: none;
                    border-radius: 24px;
                    background: ${colors.lightRed};
                }
            }
        `}
    `}
`
export const StyledBottomContainer = styled.span`
    display: grid;
`

export const StyledError = styled.small`
    ${({theme: {colors, typography}}) => css`
        ${typography.textXs};
        font-weight: 700;
        color: ${colors.red};
    `}
`

export const StyledHint = styled.small`
    ${({theme: {colors, typography}}) => css`
        font-weight: 400;
        ${typography.textXs};
        color: ${colors.darkGrey};
    `}
`

export const StyledCounter = styled.small<{$error: boolean}>`
    ${({theme: {colors, typography}, $error}) => css`
        ${typography.textXs};
        font-weight: 700;
        color: ${colors.darkGrey};
        text-align: right;
        ${$error &&
        css`
            color: ${colors.red};
        `}
    `}
`
