import {httpGetAllPhotographers} from '../http-requests/admin/partners.http'
import {useInfiniteQuery} from '@tanstack/react-query'
import {errorHandler} from '../helpers/utils'
import {QUERY_KEYS} from '../queryClient'
import {useState} from 'react'
import {DEFAULT_LIMIT} from '../helpers/constants'
import {useUserStore} from '../features/user/store'

export const usePhotographers = ({filter}) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [search, setSearch] = useState(null)

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.ALL_PHOTOGRAPHERS, filter, search],
        queryFn: ({pageParam = 1}) =>
            httpGetAllPhotographers(adminAccessToken, {
                photographer: search,
                limit: DEFAULT_LIMIT,
                page: pageParam,
                filter_by: filter
            }),
        onError: errorHandler,
        defaultPageParam: 1
    })

    const onChangePage = () => {
        const currentPage = query.data.pages.at(-1).current_page
        const lastPage = query.data.pages.at(-1).last_page
        if (!query.isFetching) {
            if (currentPage < lastPage) {
                query.fetchNextPage({pageParam: query.data.pages.at(-1).current_page + 1})
            }
        }
    }

    return {
        ...query,
        remappedData: query.data ? query.data.pages?.flatMap(page => page.data) : [],
        page: query.data ? query.data.pages?.at(-1)?.current_page + 1 : 1,
        onChangePage,
        setSearch
    }
}
