import {StyledButtons} from 'src/features/assignment/components/assignment-descriptions/accepted-dates-declined-description/style.ts'
import {Button} from 'src/components/button/Button.tsx'
import {HostNotReadyModal} from 'src/features/assignment/components/host-not-ready-modal/HostNotReadyModal.tsx'
import {UnresponsiveHostModal} from 'src/features/assignment/components/unresponsive-host-modal/UnresponsiveHostModal.tsx'
import {FreelancerNotAvailableModal} from 'src/features/assignment/components/freelancer-not-available-modal/FreelancerNotAvailableModal.tsx'
import {CannotScheduleModal} from 'src/features/assignment/components/cannot-schedule-modal/CannotScheduleModal.tsx'
import {ScheduleModal} from 'src/features/assignment/components/schedule-modal/ScheduleModal.tsx'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'

export type ModalName = 'host_not_ready' | 'unresponsive_host' | 'not_available' | 'schedule' | 'cannot_schedule'
export const ScheduleButtons = ({assignment, serviceType}: {assignment: Assignment; serviceType: ServiceType}) => {
    const {t} = useTranslation()
    const [openModal, setOpenModal] = useState<ModalName | null>(null)

    return (
        <>
            <StyledButtons>
                <Button variant="secondary" onClick={() => setOpenModal('cannot_schedule')}>
                    {t('assignment:descriptions:cannot_schedule')}
                </Button>
                <Button variant="primary" onClick={() => setOpenModal('schedule')}>
                    {t('assignment:descriptions:ready_to_schedule')}
                </Button>
            </StyledButtons>
            {openModal == 'host_not_ready' && (
                <HostNotReadyModal
                    assignmentId={assignment.id}
                    taskId={assignment.task.id}
                    onClose={() => setOpenModal(null)}
                />
            )}
            {openModal == 'unresponsive_host' && (
                <UnresponsiveHostModal
                    assignmentId={assignment.id}
                    onClose={() => setOpenModal(null)}
                    taskId={assignment.task.id}
                />
            )}
            {openModal == 'not_available' && (
                <FreelancerNotAvailableModal
                    serviceType={serviceType}
                    assignmentId={assignment.id}
                    taskId={assignment.task.id}
                    onClose={() => setOpenModal(null)}
                />
            )}
            {openModal == 'cannot_schedule' && (
                <CannotScheduleModal
                    setOpenModal={setOpenModal}
                    assignmentId={assignment.id}
                    taskId={assignment.task.id}
                    onClose={() => setOpenModal(null)}
                    serviceType={serviceType}
                />
            )}
            {openModal == 'schedule' && (
                <ScheduleModal assignment={assignment} serviceType={serviceType} onClose={() => setOpenModal(null)} />
            )}
        </>
    )
}
