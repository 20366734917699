import {Flexbox} from 'src/components/flexbox/Flexbox'
import {Skeleton} from 'src/components/skeleton/Skeleton'
import {StyledHostApprovalRoomsAmenitiesTitle, StyledRoomsAmenitiesImagesGrid} from './style'
import {AdminHostApprovalImageCard} from '../admin-host-approval-image-card/AdminHostApprovalImageCard'

export const AdminHostApprovalRoomsAmenitiesImagesLoader = () => (
    <Flexbox direction="column" gap={4} fullWidth>
        <StyledHostApprovalRoomsAmenitiesTitle>
            <Skeleton width={120} height={20} />
        </StyledHostApprovalRoomsAmenitiesTitle>
        <Flexbox direction="column" gap={2} fullWidth>
            <StyledHostApprovalRoomsAmenitiesTitle>
                <Skeleton width={240} height={20} />
            </StyledHostApprovalRoomsAmenitiesTitle>
            <StyledRoomsAmenitiesImagesGrid>
                {Array.from({length: 4}).map((_, index) => (
                    <AdminHostApprovalImageCard asSkeleton key={index} />
                ))}
            </StyledRoomsAmenitiesImagesGrid>
        </Flexbox>
    </Flexbox>
)
