import i18next from 'i18next'
import {store} from '../../../../index'
import {addStepToChatHistory, setCancellationReason} from '../../../../store/chatbot'
import {CHATBOT_CANCELLATION_REASONS, CHATBOT_SOURCES} from '../../../../helpers/constants'
import {CancelShoot} from '../../custom-components/CancelShoot'

const confirmReassignShoot = isInShootPage => [
    {
        id: 'host_confirm_reassign_shoot_options_ask',
        message: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason
            return i18next.t('chatbot:commons:confirm_reason', {
                cancellationReason: cancellationReason.text
            })
        },
        trigger: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason

            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.host_confirm_reassign_shoot_options_ask',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `Please confirm you want to cancel this shoot because of "${cancellationReason.text}"`
                })
            )
            return 'host_confirm_reassign_shoot_options'
        }
    },
    {
        id: 'host_confirm_reassign_shoot_options',
        options: [
            {
                value: 'confirm',
                label: i18next.t('chatbot:commons:confirm_cancellation'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'host_confirm_reassign_shoot_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Confirm cancellation'
                        })
                    )
                    return 'host_confirm_reassign_shoot_confirmed'
                }
            },
            {
                value: 'abandon',
                label: i18next.t('chatbot:commons:changed_mind'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'host_confirm_reassign_shoot_options',
                            source: CHATBOT_SOURCES.USER,
                            text: "I've changed my mind"
                        })
                    )
                    return isInShootPage ? '3' : 'start_generic'
                }
            }
        ]
    },
    {
        id: 'host_confirm_reassign_shoot_confirmed',
        component: <CancelShoot cancelBy="creator" />,
        asMessage: true,
        replace: true,
        trigger: 'host_confirm_reassign_shoot_confirmed_sent'
    },
    {
        id: 'host_confirm_reassign_shoot_confirmed_sent',
        message: i18next.t('chatbot:mb14:mb1.4_cancel_sent'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'host_confirm_reassign_shoot_confirmed_sent',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thank you for letting us know the reason of the cancellation. We can confirm the photoshoot request has been canceled.'
                })
            )
            return 'send_log'
        }
    }
]

export const reassign = isInShootPage => [
    {
        id: 'MB1.4_reassign',
        options: [
            {
                value: 'matching_availabilities',
                label: i18next.t('chatbot:mb14:mb1.4_reassign1'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_reassign1'),
                            id: CHATBOT_CANCELLATION_REASONS.CREATOR_DONT_MATCHING_AVAIBILITIES
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_reassign',
                            source: CHATBOT_SOURCES.USER,
                            text: 'We don’t have matching availabilities'
                        })
                    )
                    return 'host_confirm_reassign_shoot_options_ask'
                }
            },
            {
                value: 'not_available',
                label: i18next.t('chatbot:mb14:mb1.4_reassign2'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_reassign2'),
                            id: CHATBOT_CANCELLATION_REASONS.CREATOR_NOT_AVAILABLE_ANYMORE
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_reassign',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I am not available anymore'
                        })
                    )
                    return 'host_confirm_reassign_shoot_options_ask'
                }
            },
            {
                value: 'too_far',
                label: i18next.t('chatbot:mb14:mb1.4_reassign3'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_reassign3'),
                            id: CHATBOT_CANCELLATION_REASONS.CREATOR_LOCATED_FAR_AWAY
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_reassign',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The listing is located too far away'
                        })
                    )
                    return 'host_confirm_reassign_shoot_options_ask'
                }
            },
            {
                value: 'not_comfortable',
                label: i18next.t('chatbot:mb14:mb1.4_reassign4'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_reassign4'),
                            id: CHATBOT_CANCELLATION_REASONS.CREATOR_DONT_FEEL_COMFORTABLE
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_reassign',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I don’t feel comfortable shooting this property'
                        })
                    )
                    return 'host_confirm_reassign_shoot_options_ask'
                }
            },
            {
                value: 'mistake',
                label: i18next.t('chatbot:mb14:mb1.4_reassign5'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_reassign5'),
                            id: CHATBOT_CANCELLATION_REASONS.CREATOR_ACCEPTED_MISTAKE
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_reassign',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I accepted the shoot by mistake'
                        })
                    )
                    return 'host_confirm_reassign_shoot_options_ask'
                }
            },
            {
                value: 'reschedule_3_times',
                label: i18next.t('chatbot:mb14:mb1.4_reassign6'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:mb14:mb1.4_reassign6'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_RESCHEDULED_3_TIMES
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_reassign',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host rescheduled more than 3 times'
                        })
                    )
                    return 'host_confirm_reassign_shoot_options_ask'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:cancellation_reasons:other_issues'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_host_cancel',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Other issues'
                        })
                    )
                    return 'MB1.4_options'
                }
            }
        ]
    },
    ...confirmReassignShoot(isInShootPage)
]
