import {InputSectionWrapper, StyledSubTitle} from '../style'
import {StyledOtherLabel, OtherTextAreaWrapper} from './style'
import {TextArea} from 'src/components/textarea/Textarea.tsx'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Radio} from 'src/components/radio/Radio.tsx'
import {
    HARD_CANCELLATION_REASONS,
    SOFT_CANCELLATION_REASONS
} from 'src/components/impersonate-toolbar/cancel-assignment-modal/constants.ts'

export const ReasonsOptions = ({
    options
}: {
    options: typeof HARD_CANCELLATION_REASONS | typeof SOFT_CANCELLATION_REASONS
}) => {
    const {t} = useTranslation()
    const form = useFormContext()
    const selectedReason = form.watch('reason')
    const isOtherSelected = selectedReason == 'other'

    return (
        <>
            <InputSectionWrapper>
                <StyledSubTitle>{t('admin:assignment_cancellation:reason')}</StyledSubTitle>
                <Flexbox direction="column" gap={3}>
                    {options.map(item => (
                        <Radio
                            value={item}
                            label={t(`admin:assignment_cancellation:${item}`)}
                            {...form.register('reason')}
                            key={item}
                        />
                    ))}
                </Flexbox>
                {isOtherSelected && (
                    <OtherTextAreaWrapper>
                        <StyledOtherLabel>{`${t('admin:assignment_cancellation:other_reason')}*`}</StyledOtherLabel>
                        <TextArea
                            placeholder={t('admin:assignment_cancellation:write_here_reason')}
                            {...form.register('notes')}
                            //todo: remove toString after react hook form upgrade
                            errorMessage={form.formState.errors?.notes?.message?.toString()}
                        />
                    </OtherTextAreaWrapper>
                )}
            </InputSectionWrapper>

            {!!selectedReason && !isOtherSelected && (
                <InputSectionWrapper>
                    <Flexbox direction="column" gap={1}>
                        <StyledSubTitle>{t('commons:notes')}</StyledSubTitle>
                        <TextArea
                            placeholder={t('admin:assignment_cancellation:write_here_notes')}
                            {...form.register('notes')}
                        />
                    </Flexbox>
                </InputSectionWrapper>
            )}
        </>
    )
}
