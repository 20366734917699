import {rootFlowStepsHome} from './flows/rootHome'
import {rootFlowStepsShoot} from './flows/rootShoot'
import {finalFlowsSteps} from './flows/finalSteps'
import {mb0FlowSteps} from './flows/mb0'
import {mb11FlowSteps} from './flows/mb11'
import {mb12FlowSteps} from './flows/mb12'
import {mb13FlowSteps} from './flows/mb13'
import {mb14FlowSteps} from './flows/mb14'
import {mb21FlowSteps} from './flows/mb21'
import {mb22FlowSteps} from './flows/mb22'
import {mb23FlowSteps} from './flows/mb23'
import {mb4FlowSteps} from './flows/mb4'
import {mb5FlowSteps} from './flows/mb5'
import {mb6FlowSteps} from './flows/mb6'
import {mb15FlowSteps} from './flows/mb15'
import {mb16FlowSteps} from './flows/mb16'
import {mb17FlowSteps} from './flows/mb17'

export const mainSteps = (currentUser, currentPage, isInShootPage, shoot, assignmentStatus, taskStatus) => [
    ...(isInShootPage
        ? rootFlowStepsShoot(currentUser?.name, currentPage, shoot, assignmentStatus, taskStatus)
        : rootFlowStepsHome(currentUser?.name)),
    ...mb0FlowSteps(), // open new quote | API: TicketSubmit
    ...mb11FlowSteps(isInShootPage, shoot), // Wrong bedroom number | API: pull-shoots TicketSubmit
    ...mb12FlowSteps(), // (Work in progress) Schedule in the future | API: pull-shoots, CancelShoot
    ...mb13FlowSteps(isInShootPage, shoot), // Host is unresponsive | API: pull-shoots, CancelShoot, SendReminder, GetInfo
    ...mb14FlowSteps(isInShootPage, shoot), // Cancel shoot | API: pull-shoots, CancelShoot
    ...mb15FlowSteps(),
    ...mb16FlowSteps(),
    ...mb17FlowSteps(),
    ...mb21FlowSteps(), // How do I get payment? | API: /
    ...mb22FlowSteps(isInShootPage, shoot), // Get issue date | API: pull-shoots, GetIssueDate, TicketSubmit
    ...mb23FlowSteps(isInShootPage, shoot), // Photoshoot canceled/rescheduled/no-show | API: pull-shoots, TicketSubmit
    ...mb4FlowSteps(), // General ticket submission | API: TicketSubmit
    ...mb5FlowSteps(), // Not in list cancellation
    ...mb6FlowSteps(), //Not in list topic not listed
    ...finalFlowsSteps(isInShootPage) // Final steps | API: SurveySubmit, bot-rating, SubmitLog
]
