import React, {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import {Spinner} from '../../spinner/Spinner'
import {InputText} from '../../input-text/InputText'
import {SearchQuoteOptionsDropdown} from './SearchQuoteOptionsDropdown'
import {useClickOutside} from '../../../hooks/useClickOutside'
import {useAdminSearchAssignment} from '../../../hooks'
import {positiveIntRegex} from '../../../helpers/utils'

import {SearchAssignmentWrapper} from './style'
import {SearchLgIcon} from '../../icon'
import {useTheme} from 'styled-components'
import {InputHelpText} from '../../input-help-text/InputHelpText'

export const SEARCH_ASSIGNMENT_WIDTH = 464

export const SearchQuote = ({onSelectQuoteId}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [isSearchInFocus, setIsSearchInFocus] = useState(false)
    const dropdownRef = useClickOutside(() => setIsSearchInFocus(false))
    const query = useAdminSearchAssignment()
    const [searchParams, setSearchParams] = useSearchParams()

    const onChange = value => {
        query.onSearch(value)
        setIsSearchInFocus(true)
    }

    const onSelectAssignment = quoteId => {
        onSelectQuoteId(quoteId)
        setSearchParams({quoteId: quoteId})
        setIsSearchInFocus(false)
    }

    useEffect(() => {
        const quoteIdParam = searchParams.get('quoteId')
        positiveIntRegex.test(quoteIdParam) ? onSelectAssignment(Number(quoteIdParam)) : setSearchParams({})
    }, [])

    return (
        <SearchAssignmentWrapper ref={dropdownRef}>
            <InputText
                type="text"
                typeIcon={
                    query.isFetching ? (
                        <Spinner size={20} fill={theme.palette.neutral['400']} />
                    ) : (
                        <SearchLgIcon size={20} fill={theme.palette.neutral['400']} />
                    )
                }
                size="lg"
                width={`${SEARCH_ASSIGNMENT_WIDTH}px`}
                placeholder={t('admin:assignments:search_placeholder')}
                onChange={e => onChange(e.target.value)}
                onFocus={() => setIsSearchInFocus(true)}
                autoComplete="off"
            />
            {!!query.searchError && <InputHelpText style={{position: 'absolute'}} error={t(query.searchError)} />}
            {isSearchInFocus && query.search && !query.isFetching && (
                <SearchQuoteOptionsDropdown onSelect={onSelectAssignment} assignmentsData={query.data} />
            )}
        </SearchAssignmentWrapper>
    )
}

SearchQuote.propTypes = {
    onSelectQuoteId: PropTypes.func
}
