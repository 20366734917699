import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PageHelmet} from '../../../components/page-helmet/PageHelmet.tsx'
import {ROUTES} from '../../../helpers/constants.ts'
import {errorHandler} from '../../../helpers/utils.js'
import {httpAdminLogin} from '../../../http-requests/admin/auth.http.ts'
import {StyledAdminLogin, StyledAdminLoginContainer} from './style'
import {FormCard} from '../../../components/form-card/FormCard'

export const AdminLogin = () => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    const onLogin = async () => {
        try {
            setIsLoading(true)
            const {data: redirectUrl} = await httpAdminLogin()
            window.location.href = redirectUrl
        } catch (e) {
            errorHandler(e)
            setIsLoading(false)
        }
    }

    return (
        <StyledAdminLogin>
            <PageHelmet title={t('titles:admin_login')} />
            <StyledAdminLoginContainer>
                <FormCard
                    className="formCard"
                    title={t('auth:admin_login')}
                    subtitle={t('auth:admin_login_subtitle')}
                    buttonText={t('auth:login')}
                    linkText={t('auth:forgot_password')}
                    onSubmitCb={onLogin}
                    linkRoute={ROUTES.FORGOT_PASSWORD}
                    isLoading={isLoading}
                    isAdmin
                />
            </StyledAdminLoginContainer>
        </StyledAdminLogin>
    )
}
