import {StyledIdDescriptionCell} from 'src/features/admin-host-approval/components/id-description-cell/style.ts'
import {FC} from 'react'
import {Tooltip} from 'src/components/tooltip/Tooltip.tsx'

export const IdDescriptionCell: FC<{id: string | number; link: string; name: string | null}> = ({id, link, name}) => {
    return (
        <StyledIdDescriptionCell>
            <Tooltip content={id} triggerProps={{asChild: true}}>
                <a href={link} target="_blank">
                    #{id}
                </a>
            </Tooltip>
            {!!name && (
                <Tooltip content={name} triggerProps={{asChild: true}}>
                    <span>{name}</span>
                </Tooltip>
            )}
        </StyledIdDescriptionCell>
    )
}
