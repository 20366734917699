import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
    StyledModalHeader,
    StyledModalOverlay,
    StyledModalParagraph,
    StyledModalTitle
} from 'src/components/modal-atoms/style.ts'
import {ButtonHTMLAttributes, forwardRef, HTMLAttributes} from 'react'
import {Button} from 'src/components/button/Button.tsx'
import {XCloseIcon} from 'src/components/icon'

export const ModalOverlay = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalOverlay {...props} />
export const Modal = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & {width: string}>(
    ({width, ...props}, ref) => <StyledModal $width={width} ref={ref} {...props} />
)
export const ModalHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalHeader {...props} />
export const ModalBody = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalBody {...props} />
export const ModalFooter = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalFooter {...props} />
export const ModalTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledModalTitle {...props} />
export const ModalParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => <StyledModalParagraph {...props} />
export const ModalXCloseButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Button variant="ghost" size="sm" shape="square" {...props}>
        <XCloseIcon />
    </Button>
)
