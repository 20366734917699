import {Flexbox} from 'src/components/flexbox/Flexbox'
import {AdminHostApprovalTask} from '../../types'
import {Home02Icon, User01Icon, CalendarIcon} from 'src/components/icon'
import {Trans} from 'react-i18next'
import {StyledAdminHostApprovalTaskMetadataItem} from './style'
import {formatLocaleDate} from 'src/dayjs'

export const AdminHostApprovalTaskMetadata = ({
    adminHostApprovalTask
}: {
    adminHostApprovalTask: AdminHostApprovalTask
}) => (
    <Flexbox gap={8}>
        <StyledAdminHostApprovalTaskMetadataItem gap={2}>
            <Home02Icon size={20} />
            <p>
                <Trans
                    i18nKey="host_approval_task:metadata:listing"
                    values={{value: adminHostApprovalTask.external_id}}
                    components={{strong: <b />}}
                />
            </p>
        </StyledAdminHostApprovalTaskMetadataItem>
        <StyledAdminHostApprovalTaskMetadataItem gap={2}>
            <User01Icon size={20} />
            <p>
                <Trans
                    i18nKey="host_approval_task:metadata:host"
                    values={{value: adminHostApprovalTask.external_host_id}}
                    components={{strong: <b />}}
                />
            </p>
        </StyledAdminHostApprovalTaskMetadataItem>

        {(!!adminHostApprovalTask?.uploader_admin || !!adminHostApprovalTask.reviewer_admin) && (
            <StyledAdminHostApprovalTaskMetadataItem gap={2}>
                <User01Icon size={20} />
                {adminHostApprovalTask.status == 'new' ? (
                    adminHostApprovalTask?.uploader_admin?.first_name &&
                    adminHostApprovalTask?.uploader_admin?.last_name ? (
                        <p>
                            <Trans
                                i18nKey="host_approval_task:metadata:assigned_to"
                                values={{
                                    value: `${adminHostApprovalTask.uploader_admin.first_name} ${adminHostApprovalTask.uploader_admin.last_name}`
                                }}
                                components={{strong: <b />}}
                            />
                        </p>
                    ) : (
                        '-'
                    )
                ) : adminHostApprovalTask?.reviewer_admin?.first_name &&
                  adminHostApprovalTask?.reviewer_admin?.last_name ? (
                    <p>
                        <Trans
                            i18nKey="host_approval_task:metadata:reviewer_admin"
                            values={{
                                value: `${adminHostApprovalTask.reviewer_admin.first_name} ${adminHostApprovalTask.reviewer_admin.last_name}`
                            }}
                            components={{strong: <b />}}
                        />
                    </p>
                ) : (
                    '-'
                )}
            </StyledAdminHostApprovalTaskMetadataItem>
        )}

        <StyledAdminHostApprovalTaskMetadataItem gap={2}>
            <CalendarIcon size={20} />
            <p>
                <Trans
                    i18nKey="host_approval_task:metadata:created_on"
                    values={{value: formatLocaleDate(adminHostApprovalTask.created_at, 'll')}}
                    components={{strong: <b />}}
                />
            </p>
        </StyledAdminHostApprovalTaskMetadataItem>
    </Flexbox>
)
