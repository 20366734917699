import styled, {css} from 'styled-components'
import {ALERT_LEVELS} from '../../helpers/constants'

export const StyledAlertRoot = styled.div<{
    $variant: (typeof ALERT_LEVELS)[keyof typeof ALERT_LEVELS]
    $visible: boolean
}>`
    ${({theme: {spacing}, $variant, $visible}) => css`
        padding: ${spacing * 0.5}px ${spacing}px;
        opacity: 0.9;
        display: ${$visible ? 'block' : 'none'};
        ${$variant == ALERT_LEVELS.SUCCESS && StyledSuccessAlert}
        ${$variant == ALERT_LEVELS.ERROR && StyledErrorAlert}
		${$variant == ALERT_LEVELS.WARNING && StyledWarningAlert}
    `}
`

const StyledSuccessAlert = css`
    ${({theme: {colors}}) => css`
        color: ${colors.darkGreen};
        background-color: ${colors.darkGreen};
    `}
`

//TODO: Change text color
const StyledErrorAlert = css`
    ${({theme: {colors, palette}}) => css`
        color: ${palette.neutral.black};
        background-color: ${colors.red};
    `}
`

const StyledWarningAlert = css`
    ${({theme: {colors, palette}}) => css`
        color: ${palette.neutral.white};
        background-color: ${colors.yellow};
    `}
`

export const StyledAlertContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const StyledAlertContent = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const StyledAlertMessage = styled.span`
    ${({theme: {mediaQuery, palette, typography}}) => css`
        color: ${palette.neutral.white};
        word-break: break-word;
        ${typography.textSm};
        ${mediaQuery.SM} {
            ${typography.textXs};
        }
    `}
`

export const StyledIcon = styled.span`
    ${({theme: {palette}}) => css`
        & svg {
            & path {
                stroke: ${palette.neutral.white};
            }
        }
    `}
`

export const StyledCloseIcon = styled.span`
    ${({theme: {palette}}) => css`
        cursor: pointer;
        & svg {
            max-height: 20px;
            & path {
                fill: ${palette.neutral.white};
            }
        }
    `}
`
