import {StyledTab} from './style'
import {Flexbox} from '../../../../../components/flexbox/Flexbox'

export const SelectTable = ({tabs, activeTab, setActiveTabCb, tablesCounter}) => {
    return (
        <Flexbox align="center" justify="center">
            {tabs.map((tab, index) => (
                <Tab
                    tab={tab}
                    activeTab={activeTab}
                    setActiveTabCb={setActiveTabCb}
                    tablesCounter={tablesCounter}
                    key={index}
                />
            ))}
        </Flexbox>
    )
}

const Tab = ({tab, activeTab, setActiveTabCb, tablesCounter}) => {
    return (
        <StyledTab className="col" onClick={() => setActiveTabCb(tab)} isActive={activeTab.name === tab.name}>
            {tab.text}
            <span>{tablesCounter[tab.name] || 0}</span>
        </StyledTab>
    )
}
