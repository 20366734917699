import {Label} from 'src/components/label/Label.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText.tsx'
import {Radio} from 'src/components/radio/Radio.tsx'
import {useHostApprovalAccessibilityTypes} from 'src/features/admin-host-approval/services/useHostApprovalAccessibilityTypes.ts'
import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'
import {CreateTaskValidationSchema} from 'src/features/admin-host-approval/components/create-task-modal/CreateTaskModal.tsx'

export const AccessibilityTypeField = () => {
    const {t} = useTranslation()
    const form = useFormContext<CreateTaskValidationSchema>()
    const adminHostApprovalTaskTypesQuery = useHostApprovalAccessibilityTypes()

    return (
        <Flexbox direction="column" gap={2} fullWidth>
            <Label>Approval type</Label>
            <Flexbox direction="column" gap={1}>
                {adminHostApprovalTaskTypesQuery.isLoading ? (
                    <Spinner size={16} />
                ) : adminHostApprovalTaskTypesQuery.isError ? (
                    <InputHelpText error={t('errors:default')} />
                ) : (
                    adminHostApprovalTaskTypesQuery.data.map(taskType => (
                        <Radio
                            defaultChecked
                            value={taskType.id}
                            key={taskType.key_name}
                            variant="grayModern"
                            label={taskType.name}
                            {...form.register('accessibility_type')}
                        />
                    ))
                )}
            </Flexbox>
        </Flexbox>
    )
}
