import {StyledBadge} from 'src/components/badge/style'
import {HTMLAttributes} from 'react'

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
    variant?: 'neutral' | 'primary' | 'blue' | 'grayModernDark' | 'grayModernLight'
    size?: 'sm' | 'md' | 'lg'
    shape?: 'roundedRectangle' | 'circle' | 'rectangle' | 'square'
}

export const Badge = ({variant = 'neutral', shape = 'roundedRectangle', size = 'md', ...rest}: BadgeProps) => {
    return <StyledBadge $shape={shape} $variant={variant} $size={size} {...rest} />
}
