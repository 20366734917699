import {SurveySubmit} from '../custom-components/SurveySubmit'
import {BotRating} from '../custom-components/bot-rating/BotRating'
import {SubmitLog} from '../custom-components/SubmitLog'
import {AverageIcon, ExcellentIcon, GoodIcon, PoorIcon, VeryPoorIcon} from '../../../theme/icons'
import {store} from '../../../index'
import {addStepToChatHistory, resetChatHistory} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import i18next from 'i18next'

export const finalFlowsSteps = isInShootPage => [
    {
        id: 'send_log',
        component: <SubmitLog />,
        replace: true,
        trigger: () => {
            store.dispatch(resetChatHistory())
            return 'oq_message'
        }
    },
    {
        id: 'oq_message',
        message: i18next.t('chatbot:final_steps:starting'),
        trigger: 'oq_options'
    },
    {
        id: 'oq_options',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'oq_options',
                            source: CHATBOT_SOURCES.USER,
                            text: '[Other question]'
                        })
                    )
                    return isInShootPage ? '3' : 'start_generic'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: 's_start'
            }
        ]
    },
    {
        id: 's_start',
        message: i18next.t('chatbot:final_steps:s_start'),
        trigger: 's_options_rating'
    },
    {
        id: 's_options_rating',
        component: (
            <BotRating
                options={[
                    {
                        value: '1',
                        label: i18next.t('chatbot:final_steps:s_options_rating_verypoor'),
                        icon: <VeryPoorIcon />,
                        color: '#f93533',
                        trigger: 's_comment_bad'
                    },
                    {
                        value: '2',
                        label: i18next.t('chatbot:final_steps:s_options_rating_poor'),
                        icon: <PoorIcon />,
                        color: '#f96b04',
                        trigger: 's_comment_bad'
                    },
                    {
                        value: '3',
                        label: i18next.t('chatbot:final_steps:s_options_rating_average'),
                        icon: <AverageIcon />,
                        color: '#f9bb06',
                        trigger: 's_comment'
                    },
                    {
                        value: '4',
                        label: i18next.t('chatbot:final_steps:s_options_rating_good'),
                        icon: <GoodIcon />,
                        color: '#c4d718',
                        trigger: 's_comment'
                    },
                    {
                        value: '5',
                        label: i18next.t('chatbot:final_steps:s_options_rating_excellent'),
                        icon: <ExcellentIcon />,
                        color: '#68c239',
                        trigger: 's_comment'
                    }
                ]}
            />
        )
    },
    {
        id: 's_comment_bad',
        message: i18next.t('chatbot:final_steps:s_comment_bad'),
        trigger: 's_user_comment'
    },
    {
        id: 's_comment',
        message: i18next.t('chatbot:final_steps:s_comment'),
        trigger: 's_user_comment'
    },
    {
        id: 's_user_comment',
        placeholder: i18next.t('chatbot:final_steps:s_user_comment'),
        user: true,
        trigger: 's_submit_survey'
    },
    {
        id: 's_submit_survey',
        component: <SurveySubmit />,
        trigger: 's_end',
        asMessage: true
    },
    {
        id: 's_end',
        message: i18next.t('chatbot:final_steps:s_end'),
        end: true
    }
]
