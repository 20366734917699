import PropTypes from 'prop-types'
import {Tab} from '../tab/Tab'
import {StyledHeadTabs, StyledLastDivisor} from './style'
import {Fragment} from 'react'

export const TabGroup = ({tabs = [], lastElementDivisor = false}) => {
    return (
        <StyledHeadTabs>
            {tabs.map(({text, badgeText, isSelected, callback}, index) => (
                <Fragment key={`${text}-${index}`}>
                    {lastElementDivisor && tabs?.length - 1 === index && <StyledLastDivisor />}
                    <Tab
                        badgeText={badgeText}
                        text={text}
                        size="small"
                        isSelected={isSelected}
                        onClick={e => {
                            e.stopPropagation()
                            callback()
                        }}
                    />
                </Fragment>
            ))}
        </StyledHeadTabs>
    )
}

TabGroup.propTypes = {
    tabs: PropTypes.array.isRequired,
    lastElementDivisor: PropTypes.bool
}
