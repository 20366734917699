import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {palette as mainPalette} from './palette.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {css, DefaultTheme} from 'styled-components'
import {typography} from './typography.ts'
import {zIndex} from './zIndex.ts'
import {mediaQuery, screenWidth} from './breakpoints'
import {colors} from './colors'
import {breakpoints, mediaQueries} from './mediaQueries.ts'
import {opacities} from 'src/theme/opacities.ts'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
    name: 'light' //| 'dark'
    direction?: 'ltr' | 'rtl'
}

const getAppTheme = ({name}: ThemeInterface): DefaultTheme => {
    const palette = mainPalette[name]

    return {
        breakpoints,
        headerHeight: 64,
        mediaQuery,
        mediaQueries,
        colors,
        opacities,
        palette,
        screenWidth,
        shadows,
        spacing,
        transition: css`
            transition: ease-in-out 200ms;
        `,
        typography,
        zIndex
    }
}

export {GlobalStyles, getAppTheme}
