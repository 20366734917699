import styled, {css} from 'styled-components'

export const PrivateLayoutWrapper = styled.div(
    ({theme: {palette}}) => css`
        background: ${palette.neutral['50']};
        height: 100%;
        overflow-y: hidden;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr);
    `
)

export const StyledLoader = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
