import {getServiceName} from '../../../../../helpers/utils'
import {StyledAirbnbID, StyledDescription} from './style'

export const AirbnbCell = ({value, item}) => {
    return (
        <StyledAirbnbID>
            <a href={item?.airbnb_link} target="_blank" rel="noopener noreferrer">
                {value || '-'}
            </a>
            <StyledDescription>
                {getServiceName(item?.entity_type, item?.task_service, item?.task_category, item?.task_media)}
            </StyledDescription>
        </StyledAirbnbID>
    )
}
