import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAssignmentScheduledDate = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        color: ${palette.success[600]};
        ${typography.displaySm};
        & h3 {
            font-weight: 500;
        }
    `
)
