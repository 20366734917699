import styled, {css} from 'styled-components'

export const StyledIconContainer = styled('div')<{$size: 'md' | 'lg'}>(
    ({$size, theme: {palette}}) => css`
        width: ${$size == 'lg' ? '48px' : '40px'};
        height: ${$size == 'lg' ? '48px' : '40px'};
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${palette.neutral['200']};

        svg {
            width: ${$size == 'lg' ? '24px' : '20px'};
            height: ${$size == 'lg' ? '24px' : '20px'};
        }
    `
)
