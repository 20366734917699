import styled, {css} from 'styled-components'
import {Virtuoso} from 'react-virtuoso'
import {CustomScrollbar} from '../../../../theme/components'

const BodyStyle = css`
    ${({theme: {spacing}, $tbodyHeight}) => css`
        height: ${$tbodyHeight} !important;
        position: relative;
        overflow-y: auto;
        margin-top: ${spacing * 4}px;
        ${CustomScrollbar}
    `}
`

export const StyledTbody = styled.div`
    ${BodyStyle}
`

export const StyledPaginatedTbody = styled(Virtuoso)`
    ${BodyStyle}
`

export const StyledNoResults = styled.div`
    ${({$tbodyHeight, theme: {spacing, colors, palette, typography}}) => css`
        ${BodyStyle};
        height: ${$tbodyHeight} !important;
        background: ${palette.neutral.white};
        ${typography.displayXs};
        font-weight: 500;
        color: ${colors.grey};
        text-align: center;
        padding-top: ${spacing * 6}px;
    `}
`
