import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {checkConfirmImage} from '../../../../../../store/matterport'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import {StyledToggleSwitch, StyledCheckCard, StyledImageWrapper} from './style'

export const CheckCard = ({image, isDeleted = false, hasSubmitError = false}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const onToggle = e => {
        dispatch(
            checkConfirmImage({
                imageId: image.id,
                isChecked: e.target.checked,
                isDeleteList: isDeleted
            })
        )
    }

    return (
        <StyledCheckCard hasSubmitError={hasSubmitError && !image.checked}>
            {!isDeleted && (
                <StyledToggleSwitch
                    small
                    label={isDeleted ? t('admin:deleted') : t('admin:uploaded')}
                    isDefaultChecked={image.doNotUse}
                    onChange={onToggle}
                />
            )}
            <StyledImageWrapper>
                <LazyLoadImage src={image.url} alt={image.name || '-'} effect="blur" threshold={100} />
                <p>{image.name || '-'}</p>
            </StyledImageWrapper>
        </StyledCheckCard>
    )
}

CheckCard.propTypes = {
    image: PropTypes.object,
    isDeleted: PropTypes.bool,
    hasSubmitError: PropTypes.bool
}
