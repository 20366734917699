import axios from 'axios'

//Get payments to process aggregate data
export const httpGetPaymentsStatus = adminAccessToken => {
    return axios.get(`admin/photographer-payments/count`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

//Upload the payments to process .csv file
export const httpUploadPaymentsCsv = (adminAccessToken, formData, onUploadProgress) => {
    return axios.post(`/admin/photographer-payments/upload-csv`, formData, {
        onUploadProgress,
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${adminAccessToken}`}
    })
}

//Get transactions to generate aggregate data
export const httpGetFutureTransactionStats = (adminAccessToken, serviceType) => {
    return axios.get(`/admin/payments/export-analytics?export_type=${serviceType}`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

//Generate transactions file action
export const httpGenerateTransactions = (serviceType, adminAccessToken) => {
    return axios.post(
        `/admin/payments/generate-export-file`,
        {service_type: serviceType},
        {
            headers: {Authorization: `Bearer ${adminAccessToken}`}
        }
    )
}
