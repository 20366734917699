import {FC, HTMLAttributes} from 'react'

export const CalendarIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 20, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3333 1.33332C11.3333 0.965133 11.0348 0.666656 10.6666 0.666656C10.2984 0.666656 9.99992 0.965133 9.99992 1.33332V1.99999H5.99992V1.33332C5.99992 0.965133 5.70144 0.666656 5.33325 0.666656C4.96506 0.666656 4.66659 0.965133 4.66659 1.33332V1.99999H3.33325C2.22868 1.99999 1.33325 2.89542 1.33325 3.99999V6.66666V13.3333C1.33325 14.4379 2.22868 15.3333 3.33325 15.3333H12.6666C13.7712 15.3333 14.6666 14.4379 14.6666 13.3333V6.66666V3.99999C14.6666 2.89542 13.7712 1.99999 12.6666 1.99999H11.3333V1.33332ZM13.3333 5.99999V3.99999C13.3333 3.6318 13.0348 3.33332 12.6666 3.33332H11.3333V3.99999C11.3333 4.36818 11.0348 4.66666 10.6666 4.66666C10.2984 4.66666 9.99992 4.36818 9.99992 3.99999V3.33332H5.99992V3.99999C5.99992 4.36818 5.70144 4.66666 5.33325 4.66666C4.96506 4.66666 4.66659 4.36818 4.66659 3.99999V3.33332H3.33325C2.96506 3.33332 2.66659 3.6318 2.66659 3.99999V5.99999H13.3333ZM2.66659 7.33332H13.3333V13.3333C13.3333 13.7015 13.0348 14 12.6666 14H3.33325C2.96506 14 2.66659 13.7015 2.66659 13.3333V7.33332Z"
            fill="currentColor"
        />
    </svg>
)
