import styled, {css, DefaultTheme} from 'styled-components'
import {BadgeProps} from 'src/components/badge/Badge'

type StyledBadgeProps = {
    $shape: NonNullable<BadgeProps['shape']>
    $size: NonNullable<BadgeProps['size']>
    $variant: NonNullable<BadgeProps['variant']>
}

const makeBadgeBaseStyle = ({theme, $shape}: {theme: DefaultTheme; $shape: StyledBadgeProps['$shape']}) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: ${theme.spacing}px;
    outline-offset: -1px;
    ${($shape == 'rectangle' || $shape == 'roundedRectangle') &&
    css`
        height: max-content;
    `}
    ${($shape == 'square' || $shape == 'circle') &&
    css`
        flex-shrink: 0;
    `}
`

const makeBadgeSizeStyle = (theme: DefaultTheme) =>
    ({
        sm: {
            rectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2}px;
                ${theme.typography.textXs};
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            roundedRectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2}px;
                ${theme.typography.textXs};
                border-radius: 11px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            square: css`
                width: 22px;
                height: 22px;
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            circle: css`
                width: 22px;
                height: 22px;
                border-radius: 11px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `
        },
        md: {
            rectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2.5}px;
                ${theme.typography.textSm};
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            roundedRectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2.5}px;
                ${theme.typography.textSm};
                border-radius: 12px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            square: css`
                width: 24px;
                height: 24px;
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            circle: css`
                width: 24px;
                height: 24px;
                border-radius: 12px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `
        },
        lg: {
            rectangle: css`
                padding: ${theme.spacing}px ${theme.spacing * 3}px;
                ${theme.typography.textSm};
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            roundedRectangle: css`
                padding: ${theme.spacing}px ${theme.spacing * 2}px;
                ${theme.typography.textSm};
                border-radius: 14px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            square: css`
                width: 28px;
                height: 28px;
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            circle: css`
                width: 28px;
                height: 28px;
                border-radius: 14px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `
        }
    } as const satisfies Record<
        NonNullable<StyledBadgeProps['$size']>,
        Record<NonNullable<StyledBadgeProps['$shape']>, ReturnType<typeof css>>
    >)

const makeBadgeVariantStyle = (theme: DefaultTheme) =>
    ({
        primary: css`
            color: ${theme.palette.primary['700']};
            background: ${theme.palette.primary['50']};
            outline: 1px solid ${theme.palette.primary['200']};
            & svg {
                fill: ${theme.palette.primary['500']};
            }
        `,
        neutral: css`
            color: ${theme.palette.neutral['700']};
            background: ${theme.palette.neutral['50']};
            outline: 1px solid ${theme.palette.neutral['200']};
            & svg {
                fill: ${theme.palette.neutral['500']};
            }
        `,
        blue: css`
            color: ${theme.palette.blue['700']};
            background: ${theme.palette.blue['50']};
            outline: 1px solid ${theme.palette.blue['200']};
            & svg {
                fill: ${theme.palette.neutral['500']};
            }
        `,
        grayModernLight: css`
            color: ${theme.palette.neutral['700']};
            background: ${theme.palette.grayModern['100']};
        `,
        grayModernDark: css`
            color: ${theme.palette.neutral.white};
            background: ${theme.palette.grayModern['600']};
        `
    } as const satisfies Record<NonNullable<StyledBadgeProps['$variant']>, ReturnType<typeof css>>)

export const StyledBadge = styled.div<StyledBadgeProps & {disabled?: boolean}>(
    ({theme, ...props}) => css`
        ${makeBadgeSizeStyle(theme)[props.$size][props.$shape]};
        ${makeBadgeVariantStyle(theme)[props.$variant]};
        ${makeBadgeBaseStyle({theme, $shape: props.$shape})};
    `
)
