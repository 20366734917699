import styled, {css} from 'styled-components'

export const StyledPopover = styled.div`
    ${({theme: {zIndex}}) => css`
        position: fixed;
        inset: 0;
        z-index: ${zIndex.popover};
    `}
`

export const StyledOverlay = styled.div`
    ${({theme: {colors}}) => css`
        position: absolute;
        inset: 0;
        background-color: ${colors.transparentBlack};
    `}
`

export const StyledModal = styled.section`
    ${({theme: {colors, palette}}) => css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: ${palette.neutral.white};
        width: fit-content;
        min-width: 300px;
        border-radius: 8px;
        box-shadow: 0 26px 58px rgba(0, 0, 0, 0.08);
        border: 1px solid ${colors.gainsboroGrey};
    `}
`

export const StyledHeader = styled.div`
    ${({theme: {spacing, colors}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 2}px;
        padding: ${spacing * 4}px;
        text-transform: capitalize;
        border-bottom: 1px solid ${colors.transparentBlack};
    `}
`

export const StyledTotal = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral.black};
    `}
`

export const StyledDate = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral.black};
    `}
`

export const StyledBody = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        overflow: auto;
        height: 300px;
    `}
`
