import {StyledAdminHostApprovalTaskImagesPool} from 'src/features/admin-host-approval/components/admin-host-approval-task-images-pool/style'
import {ErrorBox} from 'src/components/error-box/ErrorBox'
import {AlertCircleIcon} from 'src/components/icon'
import {useTranslation} from 'react-i18next'
import {ScrollArea} from 'src/components/scroll-area/ScrollArea'
import {Scrollbar} from 'src/components/scrollbar/Scrollbar'
import {AdminHostApprovalTaskImages} from 'src/features/admin-host-approval/components/admin-host-approval-task-images/AdminHostApprovalTaskImages'
import {AdminHostApprovalImageCard} from 'src/features/admin-host-approval/components/admin-host-approval-image-card/AdminHostApprovalImageCard'
import {AdminHostApprovalTaskImagesHeader} from 'src/features/admin-host-approval/components/admin-host-approval-task-images-header/AdminHostApprovalImagesHeader'
import {useAdminHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useAdminHostApprovalTaskImages'
import {AdminHostApprovalNewTaskImageCard} from 'src/features/admin-host-approval/components/admin-host-approval-new-task-image-card/AdminHostApprovalNewTaskImageCard'

export const AdminHostApprovalTaskImagesPool = ({taskId}: {taskId: number}) => {
    const {t} = useTranslation()
    const {
        data: taskImages,
        isLoading: isLoadingTaskImages,
        isError: isErrorTaskImages
    } = useAdminHostApprovalTaskImages(taskId, {
        enabled: false
    })

    return (
        <StyledAdminHostApprovalTaskImagesPool>
            <AdminHostApprovalTaskImagesHeader taskId={taskId} />
            {isErrorTaskImages ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : (
                <ScrollArea scrollbar={<Scrollbar />}>
                    <AdminHostApprovalTaskImages>
                        {isLoadingTaskImages
                            ? Array.from({length: 6}).map((_, index) => (
                                  <AdminHostApprovalImageCard asSkeleton key={index} />
                              ))
                            : taskImages.map(image => (
                                  <AdminHostApprovalNewTaskImageCard image={image} taskId={taskId} key={image.id} />
                              ))}
                    </AdminHostApprovalTaskImages>
                </ScrollArea>
            )}
        </StyledAdminHostApprovalTaskImagesPool>
    )
}
