import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_ACTION_KEYS, QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {httpHostResponsive} from 'src/features/assignment/services/assignment.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {Assignment} from 'src/features/assignment/types.ts'
import {captureException} from '@sentry/react'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'

export const useHostResponsive = ({assignmentId, taskId}: {assignmentId: number; taskId: number}) => {
    const queryClient = useQueryClient()
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    return useMutation({
        mutationKey: [MUTATION_KEYS.ASSIGNMENT_HOST_RESPONSIVE],
        mutationFn: () =>
            parseAxiosPromise({
                axiosPromise: httpHostResponsive({
                    urlParams: {assignmentId, taskId},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onError: errorHandler,
        onSuccess: data => {
            invalidationSignal(QUERY_ACTION_KEYS.ASSIGNMENT_CHANGE_COORDINATION_STATUS)
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.ASSIGNMENT, data.code]}, data)
        }
    })
}
