import {useCallback, useState, useRef} from 'react'
import {useWindowWidth} from '@react-hook/window-size'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useMatch, useLocation} from 'react-router'

import {MobileMenu} from './mobile-menu/MobileMenu'
import {Spinner} from '../spinner-legacy/Spinner'
import {NavigationDropdown} from './navigation-dropdown/NavigationDropdown'
import {SelectMenuModal} from '../select-menu-modal/SelectMenuModal'

import {GUIDE_LINK, ROUTES} from '../../helpers/constants'
import {httpLogout} from '../../http-requests/auth.http'
import {errorHandler} from '../../helpers/utils'
import {languageSelector} from '../../store/appGenerics'
import {screenWidth} from '../../theme/breakpoints'
import {appLanguages} from '../../translations/i18n'
import {VideoIcon} from '../../assets/icons/VideoIcon'
import {NavigationIcon} from '../../assets/icons/NavigationIcon'
import {
    StyledLogo,
    LogoWrapper,
    StyledRightIcons,
    ClientHeaderWrapper,
    StyledMenuIcon,
    StyledAnchorListItem,
    StyledDivListItem,
    StyledCloseIcon,
    StyledNavGroup,
    NavLinksWrapper,
    StyledNavLink,
    NavItemsWrapper
} from './style'
import {Avatar} from '../avatar-legacy/Avatar'
import {useUserStore} from '../../features/user/store'

export const ClientHeader = () => {
    const {t} = useTranslation()
    const resetUser = useUserStore(store => store.resetUser)
    const accessToken = useUserStore(store => store.accessToken)
    const [openMobileMenu, setOpenMobileMenu] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const showMobileMenu = useWindowWidth() <= screenWidth.MD
    const [showModal, setShowModal] = useState(false)
    const currentLanguage = useSelector(languageSelector)
    const currentUser = useUserStore(store => store.currentUser)
    const currentLanguageObj = appLanguages.find(
        e => e.value === currentLanguage || e.value === localStorage.getItem('i18nextLng')
    )
    const headerRef = useRef()
    const location = useLocation()
    const pathname = location.pathname

    const setOpenMobileMenuCb = useCallback(() => {
        setOpenMobileMenu(!openMobileMenu)
    }, [openMobileMenu])

    const navLinks = [
        {
            text: t('commons:dashboard'),
            path: ROUTES.INDEX,
            activeCondition: useMatch(ROUTES.INDEX) || pathname.includes(ROUTES.HOME)
        },
        {
            text: t('menu:payments'),
            path: ROUTES.TRANSACTION_HISTORY,
            activeCondition: pathname.includes(ROUTES.TRANSACTION_HISTORY)
        }
    ]

    const onLogout = useCallback(async () => {
        try {
            setIsLoading(true)
            await httpLogout(accessToken)
            resetUser('user')
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }, [accessToken])

    return (
        <>
            <ClientHeaderWrapper ref={headerRef}>
                <div className="container h-100">
                    <div className="row h-100">
                        <NavLinksWrapper className="col-auto">
                            <LogoWrapper>
                                <StyledLogo to={ROUTES.INDEX}>{'Mediabox'}</StyledLogo>
                            </LogoWrapper>
                            <NavItemsWrapper $showMobileMenu={showMobileMenu}>
                                {navLinks.map((link, index) => (
                                    <StyledNavLink to={link.path} key={index} $isActive={link.activeCondition}>
                                        {link.text}
                                    </StyledNavLink>
                                ))}
                            </NavItemsWrapper>
                        </NavLinksWrapper>

                        <StyledRightIcons className="col">
                            <StyledNavGroup $showMobileMenu={showMobileMenu}>
                                <StyledAnchorListItem href={GUIDE_LINK} target="_blank">
                                    <VideoIcon size={20} />
                                    <span>{t('menu:video_walkthrough')}</span>
                                </StyledAnchorListItem>
                                <StyledDivListItem onClick={() => setShowModal(true)}>
                                    <NavigationIcon size={20} />
                                    <span>{currentLanguageObj?.label}</span>
                                </StyledDivListItem>
                            </StyledNavGroup>
                            <div>
                                {showMobileMenu ? (
                                    openMobileMenu ? (
                                        <StyledCloseIcon size={20} onClick={setOpenMobileMenuCb} />
                                    ) : (
                                        <StyledMenuIcon size={20} onClick={setOpenMobileMenuCb} />
                                    )
                                ) : (
                                    <NavigationDropdown
                                        onLogoutCb={onLogout}
                                        trigger={
                                            <Avatar
                                                name={currentUser?.name ?? '-'}
                                                imageUrl={currentUser?.profile_photo_url || ''}
                                            />
                                        }
                                    />
                                )}
                            </div>
                        </StyledRightIcons>
                    </div>
                </div>
            </ClientHeaderWrapper>
            {showModal && <SelectMenuModal onClose={() => setShowModal(false)} />}
            {showMobileMenu && (
                <MobileMenu
                    showAvatar
                    showMobileMenu={openMobileMenu}
                    setShowMobileMenuCb={setOpenMobileMenuCb}
                    onLogoutCb={onLogout}
                    navLinks={navLinks}
                />
            )}
            {isLoading && <Spinner overlayFullscreen />}
        </>
    )
}
