import {useTranslation} from 'react-i18next'

import {
    StyledTableTitle,
    StyledAssignmentCardStatusHeader,
    StyledAssignmentCard,
    StyledAssignmentCardHeader,
    StyledAssignmentCardTitle,
    StyledAssignmentCardDetails
} from './style'
import {FC, ReactElement, ReactNode} from 'react'

interface AssignmentCard {
    title: ReactNode
    actions?: ReactNode
    statusUpdatedAt?: string
    showUpdatedAt?: boolean
    details?: ReactNode
    tableTitle?: string
    table?: ReactElement
}
export const AssignmentCard: FC<AssignmentCard> = ({
    title,
    actions,
    showUpdatedAt = true,
    statusUpdatedAt,
    details,
    tableTitle,
    table
}) => {
    const {t} = useTranslation()

    return (
        <StyledAssignmentCard>
            <StyledAssignmentCardHeader>
                <StyledAssignmentCardTitle>
                    <StyledAssignmentCardStatusHeader>
                        <div className="title">{title}</div>
                        {showUpdatedAt && (
                            <span>
                                {t('admin:assignments:status_updated_on')}
                                <strong>{statusUpdatedAt || '-'}</strong>
                            </span>
                        )}
                    </StyledAssignmentCardStatusHeader>

                    {!!actions && <div className="actions">{actions}</div>}
                </StyledAssignmentCardTitle>
            </StyledAssignmentCardHeader>

            {!!details && <StyledAssignmentCardDetails>{details}</StyledAssignmentCardDetails>}
            {!!tableTitle && <StyledTableTitle>{tableTitle}</StyledTableTitle>}
            {table}
        </StyledAssignmentCard>
    )
}
