import {useCallback, useState} from 'react'
import {httpGetCompletedShoots} from '../http-requests/assignments.http'
import {useInfiniteQuery} from '@tanstack/react-query'
import {errorHandler} from '../helpers/utils'
import {QUERY_KEYS} from '../queryClient'
import {useUserStore} from '../features/user/store'

export const useCompletedShoots = ({search}) => {
    const accessToken = useUserStore(store => store.accessToken)
    const [sorter, setSorter] = useState(null)

    const {data, error, fetchNextPage, ...query} = useInfiniteQuery({
        queryKey: [QUERY_KEYS.COMPLETED_SHOOTS, sorter, search],
        queryFn: async ({pageParam = 1}) => {
            const {data: payload} = await httpGetCompletedShoots({page: pageParam, ...sorter, search}, accessToken)
            return payload
        },
        onError: errorHandler,
        defaultPageParam: 1
    })

    const onChangePage = () => {
        const currentPage = data.pages.at(-1).current_page
        const lastPage = data.pages.at(-1).last_page
        if (!query.isFetching) {
            if (currentPage < lastPage) {
                fetchNextPage({pageParam: data.pages.at(-1).current_page + 1})
            }
        }
    }

    //sorter
    const onSortColumn = useCallback((columnName, orderDirection) => {
        setSorter({order_by: columnName, order_direction: orderDirection})
    }, [])

    return {
        ...query,
        data: data ? data?.pages?.flatMap(page => page?.data) : [],
        error,
        page: data ? data.pages.at(-1)?.current_page + 1 : 1,
        onChangePage,
        sorter,
        onSortColumn
    }
}
