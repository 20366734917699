import {Markets} from './markets/Markets'
import {YourInformation} from './your-information/YourInformation'
import {PageHelmet} from '../../../components/page-helmet/PageHelmet'
import {useTranslation} from 'react-i18next'
import {SettingsWrapper} from './style'

export const Settings = () => {
    const {t} = useTranslation()

    return (
        <SettingsWrapper>
            <PageHelmet title={t('titles:settings')} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <YourInformation />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Markets />
                    </div>
                </div>
            </div>
        </SettingsWrapper>
    )
}
