import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {StyledButtons, StyledRejectButton} from './style.ts'
import {formatLocaleDate} from 'src/dayjs.ts'
import {useState} from 'react'
import {ScheduleDateModal} from 'src/features/assignment/components/schedule-date-modal/ScheduleDateModal.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {useAssignmentCoordination} from 'src/features/assignment/services/useAssignmentCoordination.ts'

const formatDate = (date: string) => formatLocaleDate(date, 'LLLL')

export const AcceptedDatesSharedDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const [date1, date2] = assignment.quote.host_availability_dates
    const [selectedDate, setSelectedDate] = useState<string | null>(null)
    const assignmentManualCoordinationMutation = useAssignmentCoordination({
        assignmentId: assignment.id,
        taskId: assignment.task.id
    })

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {t('assignment:descriptions:accepted_dates_shared:title')}
                </AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:accepted_dates_shared:paragraph:${serviceType}`)}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            <Flexbox direction="column" align="center" gap={2.5}>
                <StyledButtons>
                    <Button
                        variant="outlinedPrimary"
                        disabled={!date1 || assignmentManualCoordinationMutation.isLoading}
                        onClick={() => setSelectedDate(date1.starting_time)}
                    >
                        {date1 ? formatDate(date1.starting_time) : '-'}
                    </Button>
                    <Button
                        variant="outlinedPrimary"
                        disabled={!date2 || assignmentManualCoordinationMutation.isLoading}
                        onClick={() => setSelectedDate(date2.starting_time)}
                    >
                        {date2 ? formatDate(date2.starting_time) : '-'}
                    </Button>
                </StyledButtons>
                <StyledRejectButton
                    variant="tertiary"
                    onClick={() => assignmentManualCoordinationMutation.mutate({status: 'manual_coordination'})}
                    disabled={assignmentManualCoordinationMutation.isLoading}
                >
                    {assignmentManualCoordinationMutation.isLoading ? (
                        <Spinner size={20} />
                    ) : (
                        t('assignment:descriptions:accepted_dates_shared:reject_cta')
                    )}
                </StyledRejectButton>
            </Flexbox>

            {!!selectedDate && (
                <ScheduleDateModal
                    selectedDate={selectedDate}
                    assignmentId={assignment.id}
                    onClose={() => setSelectedDate(null)}
                />
            )}
        </AssignmentDescriptionWrapper>
    )
}
