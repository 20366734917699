import {Container} from 'src/components/container/Container'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {AdminHostApprovalRejectedImages} from '../admin-host-approval-rejected-images/AdminHostApprovalRejectedImages'
import {AdminHostApprovalApprovedImages} from '../admin-host-approval-approved-images/AdminHostApprovalApprovedImages'

export const AdminHostApprovalReadyForReviewTaskSection = ({taskId}: {taskId: number}) => {
    return (
        <Container fullHeight={false}>
            <Flexbox direction="column" gap={4}>
                <AdminHostApprovalRejectedImages taskId={taskId} />
                <AdminHostApprovalApprovedImages taskId={taskId} />
            </Flexbox>
        </Container>
    )
}
