import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {capitalize, formatLocaleDate} from '../../../../../helpers/utils'
import {StyledItem, StyledAssignmentDetails} from './style'
import {ENTITY_TYPES} from '../../../../../helpers/constants'

export const AssignmentDetails = ({quoteData}) => {
    const {t} = useTranslation()
    const isExperienceCategory = quoteData?.entity?.entity_type_id === ENTITY_TYPES.EXPERIENCES

    return (
        <StyledAssignmentDetails>
            <div>
                <h4>{t('admin:assignments:details:assignment_subtitle')}</h4>
                <div className="column-list">
                    <StyledItem>
                        <span>{t('admin:assignments:details:type')}</span>
                        <p title={capitalize(quoteData?.entity_type?.name ?? '-')}>
                            {capitalize(quoteData?.entity_type?.name ?? '-')}
                        </p>
                    </StyledItem>
                    <StyledItem>
                        <span>{t('admin:assignments:details:service')}</span>
                        <p title={capitalize(quoteData?.task_service?.name ?? '-')}>
                            {capitalize(quoteData?.task_service?.name ?? '-')}
                        </p>
                    </StyledItem>
                    <StyledItem>
                        <span>{t('admin:assignments:details:media')}</span>
                        <p title={capitalize(quoteData?.task_media?.name ?? '-')}>
                            {capitalize(quoteData?.task_media?.name ?? '-')}
                        </p>
                    </StyledItem>
                    <StyledItem>
                        <span>{t('admin:assignments:details:creation_date')}</span>
                        <p title={formatLocaleDate(quoteData?.assignments?.[0]?.created_at, 'LL')}>
                            {formatLocaleDate(quoteData?.assignments?.[0]?.created_at, 'LL')}
                        </p>
                    </StyledItem>
                </div>
            </div>
            <div>
                <h4>{t('admin:assignments:details:host_subtitle')}</h4>
                <div className="column-list">
                    <StyledItem>
                        <span>{t('admin:assignments:details:host_id')}</span>
                        <p title={quoteData?.host?.id ?? '-'}>{quoteData?.external_host_id ?? '-'}</p>
                    </StyledItem>
                    <StyledItem>
                        <span>{t('admin:assignments:details:host_name')}</span>
                        <p title={quoteData?.host?.first_name ?? '-'}>{quoteData?.host?.first_name ?? '-'}</p>
                    </StyledItem>
                    <StyledItem>
                        <span>{t('admin:assignments:details:host_email')}</span>
                        <p title={quoteData?.host?.email ?? '-'}>{quoteData?.host?.email ?? '-'}</p>
                    </StyledItem>
                </div>
            </div>
            <div>
                <h4>{t('commons:listing')}</h4>
                <div className="column-list">
                    <StyledItem>
                        <span>{t('admin:assignments:details:listing_id')}</span>
                        <a
                            href={
                                isExperienceCategory
                                    ? `https://admin.airbnb.com/experiences/${quoteData?.entity?.external_id}`
                                    : `https://admin.airbnb.com/rooms/${quoteData?.entity?.external_id}`
                            }
                            target="_blank"
                            rel="noreferrer"
                            title={quoteData?.entity?.external_id ?? '-'}
                        >
                            {quoteData?.entity?.external_id ?? '-'}
                        </a>
                    </StyledItem>
                    <StyledItem>
                        <span>{t('admin:assignments:details:listing_title')}</span>
                        <p title={quoteData?.entity?.title ?? '-'}>{quoteData?.entity?.title ?? '-'}</p>
                    </StyledItem>
                    <StyledItem>
                        <span>{t('admin:assignments:details:geo')}</span>
                        <p title={quoteData?.entity?.geo ?? '-'}>{quoteData?.entity?.geo ?? '-'}</p>
                    </StyledItem>
                </div>
            </div>
        </StyledAssignmentDetails>
    )
}

AssignmentDetails.propTypes = {
    quoteData: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        service: PropTypes.string,
        media: PropTypes.string,
        creation_date: PropTypes.string,
        geo: PropTypes.string,
        host_id: PropTypes.number,
        host_name: PropTypes.string,
        host_email: PropTypes.string,
        listing_id: PropTypes.string,
        listing_title: PropTypes.string
    })
}
