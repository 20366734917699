import {httpSendReminder} from '../../../http-requests/chatbot.http'
import {errorHandler} from '../../../helpers/utils'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {addStepToChatHistory, chatbotShootSelector} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {useTranslation} from 'react-i18next'
import {useUserStore} from '../../../features/user/store'

export function SendReminder({flowSource}) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const accessToken = useUserStore(store => store.accessToken)
    const selectedShoot = useSelector(chatbotShootSelector)

    useEffect(() => {
        onSendReminderResults()
    }, [])

    const onSendReminderResults = async () => {
        try {
            const entityId = selectedShoot?.quote?.entity_id
            const shootMediaId = selectedShoot?.quote?.task_media_id

            await httpSendReminder(accessToken, entityId, shootMediaId)
            dispatch(
                addStepToChatHistory({
                    id: flowSource,
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: "[Reminder sent] Awesome, we just emailed the host. Please get back to us if you don't hear from them within the next 10 days."
                })
            )
        } catch (e) {
            errorHandler(e)
            dispatch(
                addStepToChatHistory({
                    id: flowSource,
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: "[Reminder sent error] Awesome, we just emailed the host. Please get back to us if you don't hear from them within the next 10 days."
                })
            )
        }
    }

    return <p>{t('chatbot:send_reminder:send_reminder_confirm')}</p>
}
