import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

export const StyledModalOverlay = styled.div(
    ({theme: {palette, opacities, zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral['600']}${opacities[60]};
        z-index: ${zIndex.modalOverlay};
    `
)

export const StyledModal = styled.div<{$width?: CSSProperties['width']}>(
    ({$width, theme: {palette, zIndex, shadows, spacing}}) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 12px;
        background: ${palette.neutral.white};
        width: ${$width};
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        overflow-y: auto;
        box-shadow: ${shadows.lg};
    `
)

export const StyledModalHeader = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 2}px;
    `
)

export const StyledModalBody = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
    `
)

export const StyledModalFooter = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['300']};
        display: flex;
        justify-content: space-between;
    `
)

export const StyledModalTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textXl};
        font-weight: 500;
        & b {
            font-weight: 700;
        }
    `
)

export const StyledModalParagraph = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};
    `
)
