import axios from 'axios'
import {RESPONSE_TYPES} from '../helpers/constants'

export const httpSubmitSurvey = (accessToken, data) => {
    const url = `/chatbot/rate`
    return axios.post(url, data, {
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpSubmitLog = (accessToken, data) => {
    // Expected data is {flow: "...some string", recap: "...some string", highlights: "...some string", ticket: boolean}
    const url = `/chatbot/logs`
    return axios.post(url, data, {
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpPullShoots = (accessToken, statuses, filterByDate) => {
    const url = '/assignments'
    return axios.get(url, {
        params: {
            statuses,
            filter_by_date: filterByDate, //0: all shoots in the statuses, 1: shoots that have been in a determined status in the last month
            response_type: RESPONSE_TYPES.EXTENDED
        },
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpGetShootData = (accessToken, code) => {
    const url = `/assignments/${code}`
    return axios.get(url, {
        params: {response_type: RESPONSE_TYPES.EXTENDED},
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpSendReminder = (accessToken, entityId, media) => {
    const url = `/chatbot/entities/${entityId}/${media}/sendReminder`
    return axios.get(url, {headers: {Authorization: `Bearer ${accessToken}`}})
}

export const httpSubmitTicket = (accessToken, entityId, shootMediaId, data) => {
    const url = '/chatbot/entities/open-ticket'
    return axios.post(url, data, {
        params: {entity_id: entityId, task_media_id: shootMediaId},
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpGetIssueDate = (accessToken, entityId, media) => {
    const url = `/chatbot/entities/${entityId}/${media}/pullPaymentDate`
    return axios.get(url, {headers: {Authorization: `Bearer ${accessToken}`}})
}

//type Payload = {by: 'host', reason_id: number}
export const httpRequestAssignmentCancellation = (accessToken, assignmentId, payload) =>
    axios.post(`/assignment/${assignmentId}/cancel`, payload, {headers: {Authorization: `Bearer ${accessToken}`}})
