import {useUserStore} from 'src/features/user/store.ts'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {
    httpCreateAdminTask,
    HttpCreateAdminTaskOptions
} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'

export const useCreateAdminTask = (
    options?: UseMutationOptions<unknown, unknown, Omit<HttpCreateAdminTaskOptions, 'headers'>>
) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useMutation({
        ...options,
        mutationFn: ({payload}: {payload: HttpCreateAdminTaskOptions['payload']}) =>
            httpCreateAdminTask({
                headers: makeHttpAuthorizationHeader(adminAccessToken),
                payload
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, 'new']})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_STATUSES_COUNTER]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
