import {FC} from 'react'
import {Calendar, CalendarProps} from 'src/components/calendar/Calendar.tsx'
import {InputText, InputTextProps} from 'src/components/input-text/InputText.tsx'
import * as Ariakit from '@ariakit/react'
import {StyledDialog, StyledDialogBackdrop, StyledPopover} from 'src/components/date-picker/style.ts'

type DatePickerProps = {
    asModal?: boolean
    formatter: (date: Date) => string
    onBlur?: () => void
    disabled?: boolean
    errorMessage?: string
    mode: 'single' | 'range' | 'multiple'
    triggerProps?: Omit<InputTextProps, 'type' | 'readonly' | 'value'>
} & Omit<CalendarProps, 'mode'>

type FormatDateFnArgs = {
    selectedDates: Date[]
    formatter: (date: Date) => string
}
const formatFnByMode = {
    single: ({selectedDates, formatter}: FormatDateFnArgs) => {
        const date = selectedDates[0]
        return date ? formatter(date) : ''
    },
    multiple: ({selectedDates, formatter}: FormatDateFnArgs) => {
        return selectedDates.map(date => formatter(date))
    },
    range: ({selectedDates, formatter}: FormatDateFnArgs) => {
        const [start, end] = selectedDates
        if (!start && !end) {
            return ''
        }
        const formattedStart = start ? formatter(start) : ''
        const formattedEnd = end ? formatter(end) : ''

        return `${formattedStart} - ${formattedEnd}`
    }
} as const

export const DatePicker: FC<DatePickerProps> = ({
    asModal,
    mode,
    selectedDates,
    triggerProps,
    formatter,
    onBlur,
    disabled,
    errorMessage,
    ...props
}) => {
    if (asModal) {
        return (
            <Ariakit.DialogProvider
                setOpen={open => {
                    if (!open) {
                        onBlur?.()
                    }
                }}
                open={disabled ? false : undefined}
            >
                <Ariakit.DialogDisclosure
                    render={
                        <InputText
                            disabled={disabled}
                            {...triggerProps}
                            type="text"
                            value={formatFnByMode[mode]({selectedDates, formatter})}
                            readOnly
                            errorMessage={errorMessage}
                        />
                    }
                />
                <StyledDialog portal backdrop={<StyledDialogBackdrop />}>
                    <Calendar {...props} mode={mode} selectedDates={selectedDates} />
                </StyledDialog>
            </Ariakit.DialogProvider>
        )
    }

    return (
        <Ariakit.PopoverProvider
            setOpen={open => {
                if (!open) {
                    onBlur?.()
                }
            }}
            open={disabled ? false : undefined}
        >
            <Ariakit.PopoverDisclosure
                render={
                    <InputText
                        disabled={disabled}
                        {...triggerProps}
                        type="text"
                        value={formatFnByMode[mode]({selectedDates, formatter})}
                        readOnly
                        errorMessage={errorMessage}
                    />
                }
            />
            <StyledPopover portal>
                <Calendar {...props} mode={mode} selectedDates={selectedDates} />
            </StyledPopover>
        </Ariakit.PopoverProvider>
    )
}
