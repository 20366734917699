import {formatCurrency} from 'src/helpers/helpers.ts'

export const formatTransactionAmount = ({
    amount,
    amountUSD,
    currency,
    prefix = ''
}: {
    amount?: number | null
    amountUSD: number
    currency?: string | null
    prefix?: string
}) => {
    const isCurrencyUSD = !currency || currency == 'USD'
    return `${prefix}${formatCurrency(amount || amountUSD, currency ?? 'USD')}${
        isCurrencyUSD ? '' : `(${formatCurrency(amountUSD, 'USD')})`
    }`
}
