import {FC, HTMLAttributes} from 'react'

export const AlertIcon: FC<{size?: number; color?: string} & HTMLAttributes<SVGSVGElement>> = ({
    size = 16,
    color = '#FA5E5E',
    ...rest
}) => (
    <svg width={size} height={size} viewBox="0 0 95 83" fill="none" {...rest}>
        <path
            d="M40.6802 8.76128L6.09441 66.4996C5.38133 67.7345 5.00402 69.1346 5.00003 70.5606C4.99604 71.9865 5.3655 73.3887 6.07165 74.6276C6.77781 75.8665 7.79604 76.8988 9.02505 77.622C10.2541 78.3452 11.651 78.7339 13.0769 78.7496H82.2486C83.6745 78.7339 85.0714 78.3452 86.3004 77.622C87.5294 76.8988 88.5477 75.8665 89.2538 74.6276C89.96 73.3887 90.3294 71.9865 90.3255 70.5606C90.3215 69.1346 89.9442 67.7345 89.2311 66.4996L54.6452 8.76128C53.9173 7.56122 52.8924 6.56902 51.6693 5.88042C50.4462 5.19183 49.0663 4.83008 47.6627 4.83008C46.2592 4.83008 44.8793 5.19183 43.6562 5.88042C42.4331 6.56902 41.4082 7.56122 40.6802 8.76128V8.76128Z"
            stroke={color}
            strokeWidth="8.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.6621 29.75V46.0833"
            stroke={color}
            strokeWidth="8.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.6621 62.416H47.7021"
            stroke={color}
            strokeWidth="8.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
