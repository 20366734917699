import styled, {css} from 'styled-components'
import {Link, NavLink} from 'react-router-dom'
import {MenuIcon} from '../../assets/icons/MenuIcon'
import {CloseIcon} from '../../assets/icons/CloseIcon'

export const ClientHeaderWrapper = styled.header`
    ${({theme: {colors, zIndex, headerHeight, palette}}) => css`
        position: relative;
        width: 100%;
        z-index: ${zIndex.header};
        height: ${headerHeight}px;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${colors.inputGrey};
    `}
`

export const LogoWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const StyledMenuIcon = styled(MenuIcon)`
    cursor: pointer;
`
export const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
`

export const StyledLogo = styled(Link)(
    ({theme: {typography, colors}}) => css`
        ${typography.textLg};
        cursor: pointer;
        font-weight: 800;
        color: ${colors.lightBlack};
        text-decoration: none;

        &:hover {
            color: ${colors.lightBlack};
        }
    `
)

export const StyledRightIcons = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const StyledNavGroup = styled.div<{$showMobileMenu: boolean}>`
    ${({$showMobileMenu, theme: {spacing}}) => css`
        display: ${$showMobileMenu ? 'none' : 'flex'};
        gap: ${spacing * 2}px;
        height: 32px;
    `}
`

const StyledMenuListItem = css`
    ${({theme: {spacing, typography, colors}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 4}px;
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
        ${typography.textSm};
        font-weight: 500;
        text-decoration: none;
        color: ${colors.lightBlack};
        &:hover {
            color: ${colors.lightBlack};
            cursor: pointer;
        }
    `}
`

export const StyledAnchorListItem = styled.a`
    ${StyledMenuListItem}
`
export const StyledDivListItem = styled.div`
    ${StyledMenuListItem}
`

export const NavLinksWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 8}px;
        padding: 0;
    `}
`

export const StyledNavLink = styled(NavLink)<{$isActive?: boolean}>`
    ${({theme: {colors, typography, spacing, palette}, $isActive}) => css`
        cursor: pointer;
        ${typography.textSm};
        font-weight: 500;
        color: ${colors.darkGrey};
        text-decoration: none;
        line-height: 17px;
        padding: ${spacing * 2}px ${spacing * 3}px;
        ${$isActive &&
        css`
            border-radius: 8px;
            background-color: ${palette.neutral.black}0D;
            color: ${colors.lightBlack};
        `}
        &:hover {
            color: ${palette.neutral.black};
        }
    `}
`
export const NavItemsWrapper = styled.div<{$showMobileMenu: boolean}>`
    ${({theme: {spacing}, $showMobileMenu}) => css`
        display: ${$showMobileMenu ? 'none' : 'flex'};
        gap: ${spacing * 4}px;
    `}
`
