import {useCallback, useState} from 'react'
import {useWindowSize} from '@react-hook/window-size'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {useQueryClient} from '@tanstack/react-query'
import {Badge, BADGE_SIZES} from '../../../../components/badge-legacy/Badge'
import {Table} from '../../../../components/table-legacy/Table'
import {AirbnbCell} from '../../../../components/table-legacy/desktop/cell/airbnb/AirbnbCell'
import {ActionsCell} from '../../../../components/table-legacy/desktop/cell/actions-cell/ActionsCell'
import {OpenButton} from '../../../../components/table-legacy/desktop/button/open/OpenButton'
import {TABLE_BUTTON_TYPES, TableButton} from '../../../../components/table-legacy/desktop/button/TableButton'
import {ArrowDownIcon} from '../../../../assets/icons/ArrowDownIcon'
import {ArrowUpIcon} from '../../../../assets/icons/ArrowUpIcon'
import {screenWidth} from '../../../../theme/breakpoints'
import {getNewAssignmentsColumns} from '../../../../helpers/columns'
import {
    calculateDateDifference,
    isPasteDate,
    errorHandler,
    getDesiredShootDates,
    openShootInNewTab
} from '../../../../helpers/utils'
import {ALERT_LEVELS} from '../../../../helpers/constants'
import {httpGetAcceptShooting} from '../../../../http-requests/single-assignment.http'
import {showAlert} from '../../../../store/appGenerics'
import {useAssignmentsRequests} from '../../../../hooks/useAssignmentsRequests'
import {
    TitleWrapper,
    StyledTitle,
    StyledIcon,
    StyledExpand,
    TableWrapper,
    StyledDates,
    StyledLocation,
    StyledFirstCome
} from './style'
import {QUERY_KEYS} from '../../../../queryClient'
import {useUserStore} from '../../../../features/user/store'
import {useAssignmentsCount} from '../../../../features/assignments/services/useAssignmentsCount'
import {DeclineAssignmentModal} from '../../../../features/assignment/components/decline-modal/DeclineAssignmentModal'
import {getServiceType} from '../../../../features/assignment/utils'
import {Tooltip} from '../../../../components/tooltip/Tooltip'
import {HelpCircleIcon} from '../../../../components/icon'
import {useAsync} from '../../../../hooks/useAsync'

export const NewAssignments = () => {
    const theme = useTheme()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const accessToken = useUserStore(store => store.accessToken)
    const [pageWidth, pageHeight] = useWindowSize()
    const isLG = pageWidth < screenWidth.LG
    const isMobile = pageWidth < screenWidth.SM
    const [showDeclineModal, setShowDeclineModal] = useState(false)
    const [selectedShoot, setSelectedShoot] = useState(null)
    const [isExpanded, setIsExpanded] = useState(true)
    const queryClient = useQueryClient()
    const accept = useAsync()
    const {data, error, page, onChangePage, sorter, onSortColumn, newShootStatusToggle, ...query} =
        useAssignmentsRequests()

    const {data: shootsCount, updateShootsCount} = useAssignmentsCount()
    const onClickAccept = async item => {
        try {
            await accept.run(httpGetAcceptShooting(item?.id, accessToken))
            // remove element from the
            newShootStatusToggle(item)
            // update shoots count
            updateShootsCount({
                ...shootsCount,
                assignments_request: shootsCount?.assignments_request - 1,
                assignments_current: shootsCount?.assignments_current + 1,
                pending_date: shootsCount?.pending_date + 1
            })
            //invalidate queries
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.NEW_SHOOTS]
            })
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.CURRENT_SHOOTS]
            })
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]
            })
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_TABLE]
            })
            dispatch(
                showAlert({
                    message: t('dashboard:new_assignments:shoot_accept_success'),
                    level: ALERT_LEVELS.SUCCESS
                })
            )
        } catch (e) {
            errorHandler(e)
        }
    }

    const onDeclineShoot = () => {
        newShootStatusToggle(selectedShoot)

        dispatch(
            showAlert({
                message: t('dashboard:new_assignments:shoot_decline_success'),
                level: ALERT_LEVELS.SUCCESS
            })
        )
        //invalidate queries
        queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.CURRENT_SHOOTS]
        })
        queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.COMPLETED_SHOOTS]
        })

        onShowDeclineModalCb(null)
    }

    const onShowDeclineModalCb = useCallback(
        item => {
            setSelectedShoot(item)
            setShowDeclineModal(!showDeclineModal)
        },
        [showDeclineModal]
    )

    const getExpiresDate = date => {
        if (!date) {
            return '-'
        } else {
            const days = calculateDateDifference(date)
            if (isPasteDate(date)) {
                return t('dashboard:new_assignments:expires_dates_past', {
                    count: -days
                })
            } else {
                return days === 0
                    ? t('dashboard:new_assignments:expires_today')
                    : t('commons:x_days', {
                          count: days
                      })
            }
        }
    }

    return (
        <>
            <div>
                <TitleWrapper>
                    <StyledIcon color={theme.palette.neutral['500']} size={26} />
                    <StyledTitle>{t('dashboard:new_assignments:title')}</StyledTitle>
                    <Badge
                        size={BADGE_SIZES.MEDIUM}
                        text={shootsCount ? shootsCount.assignments_request ?? 0 : '...'}
                        background={theme.palette.success['600']}
                        color={theme.palette.neutral.white}
                    />
                    {isMobile && data?.length > 0 && !query.isFetching && (
                        <StyledExpand onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? (
                                <>
                                    {t('commons:collapse')}
                                    <ArrowUpIcon />
                                </>
                            ) : (
                                <>
                                    {t('commons:expand')}
                                    <ArrowDownIcon />
                                </>
                            )}
                        </StyledExpand>
                    )}
                </TitleWrapper>
                {(data?.length > 0 || query.isFetching) && (!isMobile || isExpanded) && (
                    <TableWrapper>
                        <Table
                            data={data}
                            isPaginated
                            isMobileVersion={isLG}
                            isLoading={query.isLoading}
                            isRefetching={query.isRefetching}
                            isFetchingNextPage={query.isFetchingNextPage}
                            isError={!!error}
                            onChangePageCb={onChangePage}
                            page={page}
                            sorter={sorter}
                            sortColumnCb={onSortColumn}
                            columns={getNewAssignmentsColumns()}
                            tbodyHeight={
                                pageWidth < theme.screenWidth.LG ? `calc(${pageHeight}px - 160px)` : `calc(280px)`
                            }
                            columnsRenderers={{
                                external_id: item => <AirbnbCell value={item?.entity?.external_id} item={item} />,
                                location: item => (
                                    <StyledLocation
                                        as={item?.entity?.address ? 'a' : 'span'}
                                        href={`https://www.google.com/maps/place/${item?.entity?.address}%20${
                                            item?.entity?.city ?? ''
                                        }`}
                                        title={item.entity?.city}
                                    >
                                        {item.entity?.city ?? '-'}
                                    </StyledLocation>
                                ),
                                expiration_date: item => {
                                    if (item.is_bulk_assignment) {
                                        return (
                                            <StyledFirstCome>
                                                <span>{t('dashboard:new_assignments:first_come')}</span>
                                                <Tooltip
                                                    maxWidth="300px"
                                                    content={t('dashboard:new_assignments:first_come_tooltip')}
                                                    triggerProps={{asChild: true}}
                                                >
                                                    <HelpCircleIcon size={16} />
                                                </Tooltip>
                                            </StyledFirstCome>
                                        )
                                    }
                                    return <span>{getExpiresDate(item?.expiration_date)}</span>
                                },
                                host_availability_dates: item => (
                                    <StyledDates>
                                        {getDesiredShootDates(item?.quote?.host_availability_dates)}
                                    </StyledDates>
                                ),
                                amount: item => (
                                    <span>
                                        {item.total_amount || item.total_amount === 0
                                            ? `${item.total_amount} USD`
                                            : '-'}
                                    </span>
                                ),
                                actions: item => (
                                    <ActionsCell>
                                        <TableButton
                                            text={t('single_shooting:accept')}
                                            styleType={TABLE_BUTTON_TYPES.SUCCESS}
                                            onClick={() => onClickAccept(item)}
                                            disabled={accept.isLoading}
                                        />
                                        <TableButton
                                            text={t('single_shooting:decline')}
                                            styleType={TABLE_BUTTON_TYPES.ERROR}
                                            onClick={() => onShowDeclineModalCb(item)}
                                            disabled={accept.isLoading}
                                        />
                                        <OpenButton onClick={() => openShootInNewTab(item.code)} />
                                    </ActionsCell>
                                )
                            }}
                        />
                    </TableWrapper>
                )}
            </div>
            {showDeclineModal && (
                <DeclineAssignmentModal
                    serviceType={
                        getServiceType(selectedShoot.task_media.name, selectedShoot.task_category.name) ===
                        'walkthrough'
                            ? 'walkthrough'
                            : 'home'
                    }
                    assignment={selectedShoot}
                    onSuccess={onDeclineShoot}
                    onClose={() => onShowDeclineModalCb(null)}
                />
            )}
        </>
    )
}
