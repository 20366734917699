import styled, {css} from 'styled-components'

export const StyledIdDescriptionCell = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 0.5}px;

        & a {
            text-decoration: underline;
            cursor: pointer;
            font-weight: 500;
            overflow-x: hidden;
            text-overflow: ellipsis;
            width: max-content;
            max-width: 100%;
        }
        & span {
            ${typography.textSm};
            color: ${palette.neutral['600']};
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    `
)
