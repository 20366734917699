import axios from 'axios'

export const httpLogin = (payload: {email: string; password: string}) => {
    return axios.post('/login', payload)
}

export const httpLogout = (accessToken: string) => {
    return axios.get('/logout', {
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpForgotPassword = (payload: {email: string}) => {
    return axios.post('/password/email', payload)
}

export const httpCheckRecoverPasswordToken = (token: string) => {
    return axios.get(`/password/recovery/${token}`)
}

export const httpResetPassword = (token: string, payload: {password: string}) => {
    return axios.post(`/password/${token}/change`, payload)
}

export const httpCheckAccountCreationToken = (token: string) => {
    return axios.get(`/validate/photographer/${token}`)
}

export const httpCreateAccount = (token: string, payload: {password: string; password_confirmation: string}) => {
    return axios.post(`/validate/photographer/${token}`, payload)
}
