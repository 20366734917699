import {useState, useEffect, useCallback} from 'react'
import {Outlet} from 'react-router-dom'
import {Spinner} from '../../components/spinner-legacy/Spinner'
import {httpGetMe} from '../../http-requests/user.http'
import {errorHandler} from '../../helpers/utils'
import {getHashedString} from '../../helpers/chatbot'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {APP_VERSION} from '../../helpers/constants'
import {Alert} from '../../components/alert/Alert'
import {ChatBotWrapper} from '../../components/chat-bot-wrapper/ChatBot'
import {setSessionId} from '../../store/chatbot'
import {PrivateLayoutWrapper, StyledLoader} from './style'
import {ClientHeader} from '../../components/client-header/ClientHeader'
import {Snackbar} from '../../components/snackbar/Snackbar'
import {ImpersonateToolbar} from '../../components/impersonate-toolbar/ImpersonateToolbar'

import {useUserStore} from '../../features/user/store'

export const PrivateLayout = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const setCurrentUser = useUserStore(store => store.setCurrentUser)
    const resetUser = useUserStore(store => store.resetUser)
    const accessToken = useUserStore(store => store.accessToken)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const currentUser = useUserStore(store => store.currentUser)

    const [showAvailabilityAlert, setShowAvailabilityAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const setShowAvailabilityAlertCb = useCallback(
        () => setShowAvailabilityAlert(!showAvailabilityAlert),
        [showAvailabilityAlert]
    )

    useEffect(() => {
        if (adminAccessToken) setIsLoading(false)

        const appVersion = localStorage.getItem('app_v')

        if (accessToken) {
            setIsLoading(true)
            //app version check
            appVersion === APP_VERSION ? getMe() : resetUser('all')
        }
    }, [accessToken])

    useEffect(() => {
        if (accessToken) {
            const hashedSessionID = getHashedString(`${currentUser?.id} ${new Date().toUTCString()}`)
            dispatch(setSessionId(hashedSessionID))
        }
    }, [accessToken])

    useEffect(() => {
        if (currentUser?.shoot_available !== undefined && !currentUser.shoot_available) {
            setShowAvailabilityAlert(true)
        } else {
            setShowAvailabilityAlert(false)
        }
    }, [currentUser])

    const getMe = async () => {
        try {
            const {data} = await httpGetMe(accessToken || adminAccessToken)
            setCurrentUser(data)
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <PrivateLayoutWrapper>
            <div>
                <ClientHeader />
                <Alert
                    visible={showAvailabilityAlert}
                    message={t('commons:availability_alert_text')}
                    withLeftIcon
                    dismissCallback={setShowAvailabilityAlertCb}
                />
                {adminAccessToken && <ImpersonateToolbar />}
            </div>
            {isLoading ? (
                <StyledLoader>
                    <Spinner size={80} overlay={false} overlayFullscreen={false} center={false} />
                </StyledLoader>
            ) : (
                <Outlet />
            )}
            <ChatBotWrapper />
            <Snackbar />
        </PrivateLayoutWrapper>
    )
}
