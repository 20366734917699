import styled, {css} from 'styled-components'
import {CloseIcon} from '../../theme/icons.tsx'

export const SnackbarStyled = styled.main<{$show: boolean}>`
    ${({$show, theme: {spacing, zIndex}}) => css`
        position: fixed;
        z-index: ${zIndex.snackbar};
        transition: ease-in-out 0.5s;
        left: 0;
        right: 0;
        bottom: ${$show ? spacing * 4 : -200}px;
    `}
`

export const InnerContent = styled.div<{$level: 'success' | 'warning' | 'error'}>`
    ${({theme: {colors, spacing, typography, palette}, $level}) => css`
        min-width: ${spacing * 80}px;
        width: max-content;
        max-width: 95%;
        margin: auto;
        background: ${$level == 'success' ? colors.green : $level == 'warning' ? colors.yellow : colors.red};
        color: ${palette.neutral.white};
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 2}px;
        ${typography.textSm};
        font-weight: 700;
        padding: ${spacing * 4}px;
        border-radius: ${spacing * 2}px;
    `}
`

export const Message = styled.p`
    margin-bottom: 0;
    word-break: break-word;
`

export const CloseIconStyled = styled(CloseIcon)`
    ${({theme: {palette}}) => css`
        width: 18px;
        fill: ${palette.neutral.white};
        cursor: pointer;
    `}
`
