import styled, {css} from 'styled-components'

export const StyledNoResultsBanner = styled.div(
    ({theme: {colors, typography}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        ${typography.textXl};
        font-weight: 500;
        color: ${colors.darkGrey};
    `
)
