import {FC, HTMLAttributes} from 'react'

export const SearchIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 20, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" {...rest} fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.24142 9.08756C3.24142 5.95795 5.77848 3.4209 8.90809 3.4209C12.0377 3.4209 14.5748 5.95795 14.5748 9.08756C14.5748 10.6099 13.9744 11.9921 12.9976 13.0102C12.967 13.0344 12.9375 13.0606 12.9092 13.0888C12.881 13.1171 12.8548 13.1466 12.8306 13.1772C11.8125 14.1539 10.4304 14.7542 8.90809 14.7542C5.77848 14.7542 3.24142 12.2172 3.24142 9.08756ZM13.576 15.1698C12.2833 16.1635 10.6647 16.7542 8.90809 16.7542C4.67391 16.7542 1.24142 13.3217 1.24142 9.08756C1.24142 4.85338 4.67391 1.4209 8.90809 1.4209C13.1423 1.4209 16.5748 4.85338 16.5748 9.08756C16.5748 10.8442 15.984 12.4628 14.9903 13.7556L17.9485 16.7138C18.339 17.1043 18.339 17.7375 17.9485 18.128C17.5579 18.5185 16.9248 18.5185 16.5342 18.128L13.576 15.1698Z"
            fill="currentColor"
        />
    </svg>
)
