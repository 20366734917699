import {FC} from 'react'
import {StyledSwitchRoot, StyledSwitchThumb, StyledSwitchWrapper} from 'src/components/switch/style'

interface SwitchProps {
    className?: string
    label?: string
    labelPosition?: 'left' | 'right'
    size?: 'sm' | 'md'
    disabled?: boolean
    defaultChecked?: boolean
    onChange?: (checked: boolean) => void
}

export const Switch: FC<SwitchProps> = ({
    label,
    className,
    labelPosition = 'right',
    size = 'md',
    disabled,
    defaultChecked = false,
    onChange
}) => {
    return (
        <StyledSwitchWrapper
            className={className}
            align="center"
            gap={2}
            direction={labelPosition == 'right' ? 'row' : 'row-reverse'}
        >
            <StyledSwitchRoot
                disabled={disabled}
                defaultChecked={defaultChecked}
                onCheckedChange={(value: boolean) => onChange && onChange(value)}
                $size={size}
            >
                <StyledSwitchThumb $size={size} />
            </StyledSwitchRoot>
            {label && <label>{label}</label>}
        </StyledSwitchWrapper>
    )
}

Switch.displayName = 'Switch'
