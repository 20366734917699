import styled, {css} from 'styled-components'
import {NavLink} from 'react-router-dom'

export const StyledStatusTab = styled(NavLink)(
    ({theme: {spacing, typography, transition, palette}}) => css`
        position: relative;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        padding: ${spacing * 0.5}px ${spacing * 6}px ${spacing * 4}px;
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['500']};
        cursor: pointer;
        ${transition};

        &.active {
            color: ${palette.neutral['900']};
        }

        &:hover {
            color: ${palette.neutral['700']};
        }
    `
)

export const StyledStatusTabUnderline = styled.div<{$isActive: boolean}>(
    ({theme: {palette, transition}, $isActive}) => css`
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        ${transition};
        background-color: ${palette.grayModern['600']};
        opacity: ${$isActive ? 1 : 0};
    `
)
