import {SelectProvider, useSelectContext} from '@ariakit/react'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {SelectList, SelectPopover, SelectItem, SelectTrigger} from 'src/components/select-atoms/SelectAtoms'
import {useAdminHostApprovalTaskRooms} from 'src/features/admin-host-approval/services/useAdminHostApprovalTaskRooms'
import {Spinner} from 'src/components/spinner/Spinner'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText'
import {useTranslation} from 'react-i18next'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {useUpdateHostApprovalTaskImage} from 'src/features/admin-host-approval/services/useUpdateHostApprovalTaskImage'
import {raise} from 'src/helpers/helpers'

export const AdminHostApprovalImageRoomSelector = ({
    taskId,
    imageId,
    roomId
}: {
    taskId: number
    imageId: number
    roomId: AdminHostApprovalTaskImage['room_id']
}) => {
    const {t} = useTranslation()
    const {data: rooms, isLoading, isError} = useAdminHostApprovalTaskRooms({staleTime: Infinity})
    const {mutate: updateImage} = useUpdateHostApprovalTaskImage({taskId, imageId})

    return (
        <SelectProvider defaultValue={roomId?.toString() ?? ''} setValue={value => updateImage({room_id: +value})}>
            <Flexbox direction="column" gap={2} fullWidth>
                <AdminHostApprovalImageRoomSelectorTrigger />
                <SelectPopover portal>
                    {isLoading ? (
                        <Flexbox justify="center">
                            <Spinner size={20} />
                        </Flexbox>
                    ) : isError ? (
                        <Flexbox justify="center">
                            <InputHelpText error={t('errors:default')} />
                        </Flexbox>
                    ) : (
                        <SelectList>
                            {rooms.map(room => (
                                <SelectItem key={room.id} value={room.id.toString()}>
                                    {room.name}
                                </SelectItem>
                            ))}
                        </SelectList>
                    )}
                </SelectPopover>
            </Flexbox>
        </SelectProvider>
    )
}

const AdminHostApprovalImageRoomSelectorTrigger = () => {
    const {t} = useTranslation()
    const {data: rooms} = useAdminHostApprovalTaskRooms({enabled: false})
    const selectContext = useSelectContext() ?? raise('Select must be a child of SelectCombobox')
    const selectValue = selectContext.useState('value') as string //as needed because it's a single select and the provided type is string | string[]

    return (
        <SelectTrigger
            placeholder={t('host_approval_task:select_room_placeholder')}
            fullWidth
            value={selectValue}
            displayValue={value => rooms?.find(room => room.id.toString() == value)?.name}
        />
    )
}
