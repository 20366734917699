import styled, {css} from 'styled-components'

export const StyledAlertIcon = styled.div(
    ({theme: {palette}}) => css`
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: ${palette.warning['100']};
        color: ${palette.warning['600']};
        display: flex;
        justify-content: center;
        align-items: center;
    `
)
