import {FC, ReactNode} from 'react'
import {StyledInputWrapper} from 'src/components/input-wrapper/style.ts'

interface InputWrapperProps {
    children: ReactNode
    className?: string
    hasError: boolean
    visibilityToggle?: boolean
}

export const InputWrapper: FC<InputWrapperProps> = ({children, className, hasError, visibilityToggle}) => {
    return (
        <StyledInputWrapper
            className={className}
            $hasError={hasError}
            $visibilityToggle={visibilityToggle}
            align="center"
            gap={1}
        >
            {children}
        </StyledInputWrapper>
    )
}

InputWrapper.displayName = 'InputWrapper'
