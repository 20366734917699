import {Modal} from 'src/components/modal/Modal.tsx'
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalParagraph,
    ModalTitle,
    ModalXCloseButton
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {
    ResponseUnresponsiveHostReasons,
    useUnresponsiveHostReasons
} from 'src/features/assignment/services/useUnresponsiveHostReasons.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {ErrorBox} from 'src/components/error-box/ErrorBox.tsx'
import {AlertTriangleIcon} from 'src/components/icon'
import {useForm, useWatch} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useAssignmentCoordination} from 'src/features/assignment/services/useAssignmentCoordination.ts'
import {Button} from 'src/components/button/Button.tsx'
import {Radio} from 'src/components/radio/Radio.tsx'
import {TextArea} from 'src/components/textarea/Textarea.tsx'

const OTHER_REASON_ID = 4

const unresponsiveHostReasonToLabel = {
    host_has_not_responded: 'assignment:descriptions:unresponsive_host_modal:has_not_responded_reason',
    host_has_stopped_responding: 'assignment:descriptions:unresponsive_host_modal:has_stopped_responding_reason',
    invalid_cellular_number_provided:
        'assignment:descriptions:unresponsive_host_modal:invalid_cellular_number_provided_reason',
    other: 'commons:other'
} as const satisfies Record<ResponseUnresponsiveHostReasons[number]['name'], string>

const ValidationSchema = z
    .object({
        reason_id: z.coerce.number().positive().int(),
        notes: z.string().optional()
    })
    .superRefine((values, context) => {
        if (values.reason_id == OTHER_REASON_ID && !values.notes) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'The note field is required',
                path: ['notes']
            })
        }
    })
type ValidationSchema = z.infer<typeof ValidationSchema>

export const UnresponsiveHostModal = ({
    assignmentId,
    taskId,
    onClose
}: {
    assignmentId: number
    taskId: number
    onClose: () => void
}) => {
    const {t} = useTranslation()
    const unresponsiveHostReasonsQuery = useUnresponsiveHostReasons()
    const hostUnresponsiveMutation = useAssignmentCoordination({assignmentId, taskId, options: {onSuccess: onClose}})
    const form = useForm<ValidationSchema>({resolver: zodResolver(ValidationSchema)})
    const selectedReason = useWatch({name: 'reason_id', control: form.control})
    const handleSubmit = form.handleSubmit(values => {
        hostUnresponsiveMutation.mutate({
            status: 'host_is_unresponsive',
            reason_id: values.reason_id,
            notes: values.reason_id == OTHER_REASON_ID ? values.notes : undefined
        })
    })

    return (
        <Modal onOverlayClick={onClose} width={480}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <Flexbox direction="column" gap={2}>
                    <ModalTitle>{t('assignment:descriptions:unresponsive_host_modal:title')}</ModalTitle>
                    <ModalParagraph>{t('assignment:descriptions:unresponsive_host_modal:subtitle')}</ModalParagraph>
                </Flexbox>
                {unresponsiveHostReasonsQuery.isLoading ? (
                    <Spinner size={20} />
                ) : unresponsiveHostReasonsQuery.isError ? (
                    <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
                ) : (
                    <Flexbox direction="column" gap={4}>
                        {unresponsiveHostReasonsQuery.data.map(reason => (
                            <Radio
                                value={reason.id}
                                label={t(unresponsiveHostReasonToLabel[reason.name])}
                                key={reason.id}
                                {...form.register('reason_id')}
                            />
                        ))}
                        {selectedReason == OTHER_REASON_ID && <TextArea {...form.register('notes')} />}
                    </Flexbox>
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} variant="tertiary" disabled={hostUnresponsiveMutation.isLoading}>
                    {t('commons:close')}
                </Button>
                <Button disabled={hostUnresponsiveMutation.isLoading || !form.formState.isValid} onClick={handleSubmit}>
                    {t('commons:confirm')}
                    {hostUnresponsiveMutation.isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
