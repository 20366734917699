import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import {capitalize, renderTableQuoteStatus} from '../../../helpers/utils'
import {StyledAssignmentDetails, AssignmentOptionsWrapper, StyledOptionWrapper} from './style'
import {Chip} from '../../chip/Chip'
import {taskStatusToLabel} from '../../../helpers/statuses'

export const SearchQuoteOptionsDropdown = ({onSelect, assignmentsData = []}) => {
    const {t} = useTranslation()
    return (
        <AssignmentOptionsWrapper>
            {assignmentsData?.map(assignment => (
                <StyledOptionWrapper key={assignment.id} onClick={() => onSelect(assignment.quote)}>
                    <StyledAssignmentDetails>
                        <p>{`${capitalize(assignment?.task_media_name)}`}</p>
                        <h4>{assignment.identifier}</h4>
                        <span>{`${capitalize(assignment?.task_category_name)} ${capitalize(
                            assignment?.task_service_name
                        )}`}</span>
                        <div>
                            <Chip rounded text={t(renderTableQuoteStatus(assignment.status).label)} />
                            {assignment.production_status && (
                                <Chip rounded text={t(taskStatusToLabel[assignment.production_status])} />
                            )}
                        </div>
                    </StyledAssignmentDetails>
                </StyledOptionWrapper>
            ))}
            {assignmentsData.length === 0 && (
                <StyledOptionWrapper>
                    <StyledAssignmentDetails>
                        <p>{t('commons:no_results')}</p>
                    </StyledAssignmentDetails>
                </StyledOptionWrapper>
            )}
        </AssignmentOptionsWrapper>
    )
}

SearchQuoteOptionsDropdown.propTypes = {
    onSelect: PropTypes.func,
    assignmentsData: PropTypes.arrayOf(
        PropTypes.shape({
            identifier: PropTypes.string,
            quote: PropTypes.number,
            status: PropTypes.string,
            task_media_name: PropTypes.string,
            task_service_name: PropTypes.string,
            task_category_name: PropTypes.string
        })
    )
}
