import {FC, HTMLAttributes} from 'react'

export const UserIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 16, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 4.66666C6 3.56209 6.89543 2.66666 8 2.66666C9.10457 2.66666 10 3.56209 10 4.66666C10 5.77123 9.10457 6.66666 8 6.66666C6.89543 6.66666 6 5.77123 6 4.66666ZM8 1.33333C6.15905 1.33333 4.66667 2.82571 4.66667 4.66666C4.66667 6.50761 6.15905 8 8 8C9.84095 8 11.3333 6.50761 11.3333 4.66666C11.3333 2.82571 9.84095 1.33333 8 1.33333ZM5.33333 9.33333C4.44928 9.33333 3.60143 9.68452 2.97631 10.3096C2.35119 10.9348 2 11.7826 2 12.6667V14C2 14.3682 2.29848 14.6667 2.66667 14.6667C3.03486 14.6667 3.33333 14.3682 3.33333 14V12.6667C3.33333 12.1362 3.54405 11.6275 3.91912 11.2524C4.29419 10.8774 4.8029 10.6667 5.33333 10.6667H10.6667C11.1971 10.6667 11.7058 10.8774 12.0809 11.2524C12.456 11.6275 12.6667 12.1362 12.6667 12.6667V14C12.6667 14.3682 12.9651 14.6667 13.3333 14.6667C13.7015 14.6667 14 14.3682 14 14V12.6667C14 11.7826 13.6488 10.9348 13.0237 10.3096C12.3986 9.68452 11.5507 9.33333 10.6667 9.33333H5.33333Z"
            fill="currentColor"
        />
    </svg>
)
