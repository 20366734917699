import {statusColors} from '../theme/colors'
import {CloseIcon} from '../assets/icons/CloseIcon'
import {CheckIcon} from '../assets/icons/CheckIcon'
import {CalendarIcon} from '../assets/icons/CalendarIcon'
import {CircleWarningIcon} from '../assets/icons/CircleWarningIcon'
import {IncompleteCircleIcon} from '../assets/icons/IncompleteCircleIcon'
import {DotIcon} from '../assets/icons/DotIcon'
import {ASSIGNMENT_STATUSES, POST_PRODUCTION_STATUSES, TASK_STATUSES, QUOTE_STATUSES} from './constants'
import {Assignment, CoordinationStatus} from 'src/features/assignment/types.ts'

export const assignmentStatusToLabel = {
    [ASSIGNMENT_STATUSES.AUTO_CANCELLED]: 'statuses:canceled_by_admin',
    [ASSIGNMENT_STATUSES.HARD_CANCELLED]: 'statuses:hard_cancelled',
    [ASSIGNMENT_STATUSES.SOFT_CANCELLED]: 'statuses:soft_cancelled',
    [ASSIGNMENT_STATUSES.ACCEPTED]: 'statuses:accepted',
    [ASSIGNMENT_STATUSES.DECLINED]: 'statuses:declined',
    [ASSIGNMENT_STATUSES.PENDING]: 'statuses:pending'
}

export const taskStatusToLabel = {
    [TASK_STATUSES.ACCEPTED]: 'statuses:accepted',
    [TASK_STATUSES.ASSETS_IN_REVIEW]: 'statuses:assets_in_review',
    [TASK_STATUSES.AUTO_CANCELLED]: 'statuses:canceled_by_admin',
    [TASK_STATUSES.COMPLETED]: 'commons:completed',
    [TASK_STATUSES.HARD_CANCELLED]: 'statuses:hard_cancelled',
    [TASK_STATUSES.NEEDS_MORE_ASSETS]: 'statuses:needs_more_assets',
    [TASK_STATUSES.OVERDUE]: 'statuses:overdue',
    [TASK_STATUSES.PENDING]: 'statuses:pending',
    [TASK_STATUSES.PENDING_ASSETS]: 'statuses:pending_assets',
    [TASK_STATUSES.PENDING_PAYMENT]: 'statuses:pending_payment',
    [TASK_STATUSES.SCHEDULED]: 'statuses:scheduled',
    [TASK_STATUSES.SOFT_CANCELLED]: 'statuses:soft_cancelled',
    [TASK_STATUSES.WAITLISTED]: 'statuses:waitlisted'
}

export const quoteStatusToLabel = {
    [QUOTE_STATUSES.RENEWED]: 'statuses:renewed',
    [QUOTE_STATUSES.SENT]: 'statuses:sent',
    [QUOTE_STATUSES.SIGNED]: 'statuses:signed',
    [QUOTE_STATUSES.EXPIRED]: 'statuses:expired',
    [QUOTE_STATUSES.DECLINED]: 'statuses:declined',
    [QUOTE_STATUSES.CANCELLED]: 'statuses:cancelLed',
    [QUOTE_STATUSES.COMPLETED]: 'commons:completed'
}

export const postProductionStatusToLabel = {
    [POST_PRODUCTION_STATUSES.ASSIGNED]: 'statuses:post_production:assigned',
    [POST_PRODUCTION_STATUSES.FINAL_QA_FAILED]: 'statuses:post_production:final_qa_failed',
    [POST_PRODUCTION_STATUSES.INITIAL_QA]: 'statuses:post_production:initial_qa',
    [POST_PRODUCTION_STATUSES.INITIAL_QA_FAILED]: 'statuses:post_production:initial_qa_failed',
    [POST_PRODUCTION_STATUSES.NEEDS_TO_BE_ASSIGNED]: 'statuses:post_production:needs_to_be_assigned',
    [POST_PRODUCTION_STATUSES.READY_FOR_FINAL_QA]: 'statuses:post_production:ready_for_final_qa',
    [POST_PRODUCTION_STATUSES.READY_FOR_PRE_UPLOAD]: 'statuses:post_production:ready_for_pre_upload',
    [POST_PRODUCTION_STATUSES.READY_FOR_UPLOAD]: 'statuses:post_production:ready_for_upload',
    [POST_PRODUCTION_STATUSES.UPLOAD_COMPLETED]: 'statuses:post_production:upload_completed',
    [POST_PRODUCTION_STATUSES.UPLOAD_IN_PROGRESS]: 'statuses:post_production:upload_in_progress',
    [POST_PRODUCTION_STATUSES.UPLOAD_ISSUE]: 'statuses:post_production:upload_issue',
    [POST_PRODUCTION_STATUSES.WORK_IN_PROGRESS]: 'statuses:post_production:work_in_progress'
}

export const coordinationStatusToLabel = {
    host_unresponsive: 'statuses:host_unresponsive',
    host_not_ready: 'statuses:host_not_ready',
    host_dates_shared: 'statuses:host_dates_shared',
    photographer_not_available: 'statuses:photographer_not_available',
    dates_dont_match: 'statuses:dates_dont_match',
    manual_coordination: 'statuses:manual_coordination',
    overdue_host_contacted: 'statuses:overdue_host_contacted',
    host_contacted: 'statuses:host_contacted',
    cancellation_requested: 'statuses:cancellation_requested'
} as const satisfies Record<CoordinationStatus, string>

export const retrieveCombinedStatus = (
    taskStatus: (typeof TASK_STATUSES)[keyof typeof TASK_STATUSES],
    assignmentStatus: (typeof ASSIGNMENT_STATUSES)[keyof typeof ASSIGNMENT_STATUSES],
    isWalkthrough: boolean,
    coordinationStatus: CoordinationStatus
) => {
    switch (assignmentStatus) {
        case ASSIGNMENT_STATUSES.PENDING:
            return isWalkthrough ? statusesData.NEW_WALKTHROUGH : statusesData.NEW
        case ASSIGNMENT_STATUSES.DECLINED:
            return statusesData.DECLINED
        case ASSIGNMENT_STATUSES.AUTO_CANCELLED:
            return statusesData.CANCELED_BY_ADMIN
        case ASSIGNMENT_STATUSES.HARD_CANCELLED:
            return statusesData.CANCELED_BY_HOST
        case ASSIGNMENT_STATUSES.SOFT_CANCELLED:
            return statusesData.CANCELED_BY_CREATOR
        default:
            // case: ASSIGNMENT_STATUSES.ACCEPTED
            switch (taskStatus) {
                case TASK_STATUSES.COMPLETED:
                    return statusesData.COMPLETED
                case TASK_STATUSES.OVERDUE:
                    return statusesData.OVERDUE
                case TASK_STATUSES.PENDING_ASSETS:
                    return isWalkthrough ? statusesData.PENDING_SUBMISSION : statusesData.PENDING_ASSETS
                case TASK_STATUSES.PENDING_PAYMENT:
                    return statusesData.PENDING_PAYMENT
                case TASK_STATUSES.SCHEDULED:
                    return statusesData.SCHEDULED
                case TASK_STATUSES.ACCEPTED:
                    return coordinationStatus == 'cancellation_requested'
                        ? statusesData.CANCELLATION_REQUESTED
                        : statusesData.PENDING_DATE
                case TASK_STATUSES.ASSETS_IN_REVIEW:
                    return isWalkthrough ? statusesData.SUBMISSION_REVIEW : statusesData.ASSETS_REVIEW
                case TASK_STATUSES.AUTO_CANCELLED:
                    return statusesData.CANCELED_BY_ADMIN
                case TASK_STATUSES.SOFT_CANCELLED:
                    return statusesData.CANCELED_BY_CREATOR
                case TASK_STATUSES.HARD_CANCELLED:
                    return statusesData.CANCELED_BY_HOST
                case TASK_STATUSES.NEEDS_MORE_ASSETS:
                    return isWalkthrough ? statusesData.SUBMISSION_REJECTED : statusesData.ASSETS_REJECTED
                case TASK_STATUSES.WAITLISTED:
                    return statusesData.WAITLISTED // TODO: check return statusesData.DECLINED
                default:
                    return isWalkthrough ? statusesData.NEW_WALKTHROUGH : statusesData.NEW
            }
    }
}

export const statusesData = {
    COMPLETED: {
        label: 'commons:completed',
        color: statusColors.COMPLETED.text,
        borderColor: statusColors.COMPLETED.border,
        background: statusColors.COMPLETED.background,
        borderStyle: 'solid',
        icon: <CheckIcon size={16} />
    },
    TO_BE_PAID: {
        label: 'statuses:to_be_paid',
        description: 'statuses:to_be_paid_description',
        color: statusColors.TO_BE_PAID.text,
        borderColor: statusColors.TO_BE_PAID.border,
        background: statusColors.TO_BE_PAID.background,
        borderStyle: 'solid',
        icon: <IncompleteCircleIcon size={16} />
    },
    ASSETS_REJECTED: {
        label: 'statuses:need_more_assets',
        color: statusColors.ASSETS_REJECTED.text,
        borderColor: statusColors.ASSETS_REJECTED.border,
        background: statusColors.ASSETS_REJECTED.background,
        borderStyle: 'solid',
        icon: <CircleWarningIcon size={16} />
    },
    SUBMISSION_REJECTED: {
        label: 'statuses:need_another_submission',
        color: statusColors.ASSETS_REJECTED.text,
        borderColor: statusColors.ASSETS_REJECTED.border,
        background: statusColors.ASSETS_REJECTED.background,
        borderStyle: 'solid',
        icon: <CircleWarningIcon size={16} />
    },
    ASSETS_REVIEW: {
        label: 'statuses:assets_in_review',
        description: 'statuses:assets_in_review_description',
        color: statusColors.ASSETS_REVIEW.text,
        borderColor: statusColors.ASSETS_REVIEW.border,
        background: statusColors.ASSETS_REVIEW.background,
        borderStyle: 'solid',
        icon: <IncompleteCircleIcon size={16} />
    },
    SUBMISSION_REVIEW: {
        label: 'statuses:submission_in_review',
        description: 'statuses:submission_in_review_description',
        color: statusColors.ASSETS_REVIEW.text,
        borderColor: statusColors.ASSETS_REVIEW.border,
        background: statusColors.ASSETS_REVIEW.background,
        borderStyle: 'dashed',
        icon: <IncompleteCircleIcon size={16} />
    },
    PENDING_ASSETS: {
        label: 'statuses:pending_assets',
        description: 'statuses:pending_assets_description',
        color: statusColors.PENDING_ASSETS.text,
        borderColor: statusColors.PENDING_ASSETS.border,
        background: statusColors.PENDING_ASSETS.background,
        borderStyle: 'dashed',
        icon: <CircleWarningIcon size={16} />
    },
    PENDING_ASSETS_WALKTHROUGH: {
        label: 'statuses:pending_assets',
        description: 'statuses:pending_assets_description_walkthrough',
        color: statusColors.PENDING_ASSETS.text,
        borderColor: statusColors.PENDING_ASSETS.border,
        background: statusColors.PENDING_ASSETS.background,
        borderStyle: 'dashed',
        icon: <CircleWarningIcon size={16} />
    },
    PENDING_SUBMISSION: {
        label: 'statuses:pending_submission',
        description: 'statuses:pending_submission_description',
        color: statusColors.PENDING_ASSETS.text,
        borderColor: statusColors.PENDING_ASSETS.border,
        background: statusColors.PENDING_ASSETS.background,
        borderStyle: 'dashed',
        icon: <CircleWarningIcon size={16} />
    },
    ASSIGNMENT_SCHEDULED: {
        label: 'statuses:shoot_scheduled',
        description: 'statuses:shoot_scheduled_description',
        color: statusColors.ASSIGNMENT_SCHEDULED.text,
        borderColor: statusColors.ASSIGNMENT_SCHEDULED.border,
        background: statusColors.ASSIGNMENT_SCHEDULED.background,
        borderStyle: 'solid',
        icon: <CalendarIcon size={16} />
    },
    SCHEDULED: {
        label: 'statuses:scheduled',
        description: 'statuses:scheduled_description',
        color: statusColors.ASSIGNMENT_SCHEDULED.text,
        borderColor: statusColors.ASSIGNMENT_SCHEDULED.border,
        background: statusColors.ASSIGNMENT_SCHEDULED.background,
        borderStyle: 'solid',
        icon: <CalendarIcon size={16} />
    },
    PENDING_DATE: {
        label: 'statuses:pending_date',
        description: 'statuses:pending_date_description',
        color: statusColors.PENDING_DATE.text,
        borderColor: statusColors.PENDING_DATE.border,
        background: statusColors.PENDING_DATE.background,
        borderStyle: 'dashed',
        icon: <CircleWarningIcon size={16} />
    },
    PENDING_SHOOT_DATE: {
        label: 'statuses:pending_shoot_date',
        description: 'statuses:pending_shoot_date_description',
        color: statusColors.PENDING_DATE.text,
        borderColor: statusColors.PENDING_DATE.border,
        background: statusColors.PENDING_DATE.background,
        borderStyle: 'dashed',
        icon: <CircleWarningIcon size={16} />
    },
    WAITLISTED: {
        label: 'statuses:waitlisted',
        color: statusColors.DECLINED.text,
        borderColor: statusColors.DECLINED.border,
        background: statusColors.DECLINED.background,
        borderStyle: 'solid',
        icon: <CloseIcon size={16} />
    },
    DECLINED: {
        label: 'statuses:declined',
        color: statusColors.DECLINED.text,
        borderColor: statusColors.DECLINED.border,
        background: statusColors.DECLINED.background,
        borderStyle: 'solid',
        icon: <CloseIcon size={16} />
    },
    OVERDUE: {
        label: 'statuses:overdue',
        color: statusColors.OVERDUE.text,
        borderColor: statusColors.OVERDUE.border,
        background: statusColors.OVERDUE.background,
        borderStyle: 'solid',
        icon: <CloseIcon size={16} />
    },
    CANCELED_BY_HOST: {
        label: 'statuses:canceled_by_host',
        color: statusColors.CANCELED_BY_HOST.text,
        borderColor: statusColors.CANCELED_BY_HOST.border,
        background: statusColors.CANCELED_BY_HOST.background,
        borderStyle: 'solid',
        icon: <CloseIcon size={16} />
    },
    CANCELED_BY_ADMIN: {
        label: 'statuses:canceled_by_admin',
        color: statusColors.CANCELED_BY_ADMIN.text,
        borderColor: statusColors.CANCELED_BY_ADMIN.border,
        background: statusColors.CANCELED_BY_ADMIN.background,
        borderStyle: 'solid',
        icon: <CloseIcon size={16} />
    },
    CANCELED_BY_CREATOR: {
        label: 'statuses:canceled_by_creator',
        color: statusColors.CANCELED_BY_CREATOR.text,
        borderColor: statusColors.CANCELED_BY_CREATOR.border,
        background: statusColors.CANCELED_BY_CREATOR.background,
        borderStyle: 'solid',
        icon: <CloseIcon size={16} />
    },
    PENDING_PAYMENT: {
        label: 'statuses:processing_payment',
        description: 'statuses:pending_payment_description',
        color: statusColors.PENDING_PAYMENT.text,
        borderColor: statusColors.PENDING_PAYMENT.border,
        background: statusColors.PENDING_PAYMENT.background,
        borderStyle: 'solid',
        icon: <IncompleteCircleIcon size={16} />
    },
    NEW: {
        label: 'commons:new',
        description: 'statuses:new_description',
        color: statusColors.NEW.text,
        borderColor: statusColors.NEW.border,
        background: statusColors.NEW.background,
        borderStyle: 'solid',
        icon: <DotIcon size={16} />
    },
    NEW_WALKTHROUGH: {
        label: 'commons:new',
        description: 'statuses:new_description_walkthrough',
        color: statusColors.NEW.text,
        borderColor: statusColors.NEW.border,
        background: statusColors.NEW.background,
        borderStyle: 'solid',
        icon: <DotIcon size={16} />
    },
    CANCELLATION_REQUESTED: {
        label: 'statuses:cancellation_requested',
        color: statusColors.PENDING_DATE.text,
        borderColor: statusColors.PENDING_DATE.border,
        background: statusColors.PENDING_DATE.background,
        borderStyle: 'dashed',
        icon: <CircleWarningIcon size={16} />
    }
}

export const checkIsCompletedStatus = (
    assignmentStatus: Assignment['status'],
    taskStatus: Assignment['task_status']['status'],
    coordinationStatus: Assignment['task_status']['coordination_status']
) =>
    [
        ASSIGNMENT_STATUSES.SOFT_CANCELLED,
        ASSIGNMENT_STATUSES.HARD_CANCELLED,
        ASSIGNMENT_STATUSES.AUTO_CANCELLED,
        ASSIGNMENT_STATUSES.DECLINED
    ].some(status => status == assignmentStatus) ||
    (assignmentStatus == ASSIGNMENT_STATUSES.ACCEPTED &&
        [
            TASK_STATUSES.COMPLETED,
            TASK_STATUSES.OVERDUE,
            TASK_STATUSES.HARD_CANCELLED,
            TASK_STATUSES.SOFT_CANCELLED,
            TASK_STATUSES.AUTO_CANCELLED,
            TASK_STATUSES
        ].some(status => status == taskStatus)) ||
    coordinationStatus == 'cancellation_requested'
