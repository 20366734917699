import {TicketSubmit} from '../custom-components/TicketSubmit'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import i18next from 'i18next'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'

export const mb5FlowSteps = () => [
    {
        id: 'MB5',
        message: i18next.t('chatbot:mb5:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please select the cancellation reason'
                })
            )
            return 'MB5_options'
        }
    },
    {
        id: 'MB5_options',
        options: [
            {
                value: 'unresponsive',
                label: i18next.t('chatbot:cancellation_reasons:host_unresponsive'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The Host is unresponsive'
                        })
                    )
                    return 'MB5_unresponsive'
                }
            },
            {
                value: 'step;MB5_notready',
                label: i18next.t('chatbot:cancellation_reasons:host_not_ready'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The Host is not ready'
                        })
                    )
                    return 'MB5_notready'
                }
            },
            {
                value: 'step;MB5_listingproblem',
                label: i18next.t('chatbot:cancellation_reasons:home_not_ready'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The home is not ready'
                        })
                    )
                    return 'MB5_listingproblem'
                }
            },
            {
                value: '6 creator',
                label: i18next.t('chatbot:cancellation_reasons:accepted_mistake'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I accepted this shoot by mistake'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: '13 host',
                label: i18next.t('chatbot:cancellation_reasons:host_not_show_up'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host did not show up'
                        })
                    )
                    return 'MB5_noshow'
                }
            },
            {
                value: 'step;MB5_coordination',
                label: i18next.t('chatbot:cancellation_reasons:other_issues'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Other issues'
                        })
                    )
                    return 'MB5_coordination'
                }
            }
        ]
    },

    // Not ready
    {
        id: 'MB5_notready',
        options: [
            {
                value: '25 host',
                label: i18next.t('chatbot:cancellation_reasons:host_not_ready_shoot'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_notready',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The Host is not ready to shoot'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: '15 host',
                label: i18next.t('chatbot:cancellation_reasons:host_unavailable_3_months'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_notready',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host is unavailable within the next 3 months'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: '16 host',
                label: i18next.t('chatbot:cancellation_reasons:host_not_comfortable_6_months'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_notready',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host is not comfortable to shoot within the next 6 months'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: '17 host',
                label: i18next.t('chatbot:cancellation_reasons:host_rescheduled_3_times'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_notready',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host rescheduled more than 3 times'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:commons:previous_options'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_notready',
                            source: CHATBOT_SOURCES.USER,
                            text: '⬅️ Previous options'
                        })
                    )
                    return 'MB1.4.1'
                }
            }
        ]
    },

    // Listing problem
    {
        id: 'MB5_listingproblem',
        options: [
            {
                value: '14 host',
                label: i18next.t('chatbot:cancellation_reasons:listing_not_ready'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_listingproblem',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The listing is not ready'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: '3 creator',
                label: i18next.t('chatbot:cancellation_reasons:listing_too_far'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_listingproblem',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The listing is located too far away'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: '5 creator',
                label: i18next.t('chatbot:cancellation_reasons:not_comfortable_property'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_listingproblem',
                            source: CHATBOT_SOURCES.USER,
                            text: "I don't feel comfortable shooting this property"
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:commons:previous_options'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_listingproblem',
                            source: CHATBOT_SOURCES.USER,
                            text: '⬅️ Previous options'
                        })
                    )
                    return 'MB1.4.1'
                }
            }
        ]
    },

    // Coordination
    {
        id: 'MB5_coordination',
        options: [
            {
                value: '2 creator',
                label: i18next.t('chatbot:cancellation_reasons:not_matching_availabilities'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: "We don't have matching availabilities"
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: '4 creator',
                label: i18next.t('chatbot:cancellation_reasons:not_available_anymore'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I am not available anymore'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:commons:previous_options'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: '⬅️ Previous options'
                        })
                    )
                    return 'MB1.4.1'
                }
            }
        ]
    },

    // No show
    {
        id: 'MB5_noshow',
        message: i18next.t('chatbot:commons:host_noshow'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_noshow',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'We are sorry to hear that the Host did not show up to the scheduled appointment.'
                })
            )
            return 'MB5_noshow.2'
        }
    },
    {
        id: 'MB5_noshow.2',
        message: i18next.t('chatbot:commons:host_noshow_detail'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_noshow.2',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please give us any relevant detail of the no-show.'
                })
            )
            return 'MB5_noshow_notes_input'
        }
    },
    {
        id: 'MB5_noshow_notes_input',
        user: true,
        validator: value => {
            if (value === '') {
                return i18next.t('chatbot:commons:enter_message')
            } else if (value.length < 30) {
                return i18next.t('chatbot:commons:message_should_be_longer')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_noshow_notes_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB5_ticket'
        }
    },

    // Unresponsive
    {
        id: 'MB5_unresponsive',
        message: i18next.t('chatbot:mb5:mb5_unresponsive'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Have you ever emailed the Host?'
                })
            )
            return 'MB5_unresponsive_email'
        }
    },
    {
        id: 'MB5_unresponsive_email',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive_email',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB5_unresponsive_email_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive_email',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB5_unresponsive_email_no'
                }
            }
        ]
    },
    {
        id: 'MB5_unresponsive_email_no',
        message: i18next.t('chatbot:mb5:mb5_unresponsive_email_no'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive_email_no',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please email the Host and give them at least 24 hours to get back to you. If they are still unresponsive, reach back to us again.'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB5_unresponsive_email_yes',
        message: i18next.t('chatbot:mb5:mb5_unresponsive_email_yes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive_email_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Have you tried calling the Host?'
                })
            )
            return 'MB5_unresponsive_phone'
        }
    },
    {
        id: 'MB5_unresponsive_phone',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive_phone',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB5_unresponsive_phone_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive_phone',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB5_unresponsive_phone_no'
                }
            },
            {
                value: 'not_available',
                label: i18next.t('chatbot:mb5:mb5_not_available_phone'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive_phone',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The phone number is not available in Mediabox'
                        })
                    )
                    return 'MB5_unresponsive_phone_not_available'
                }
            }
        ]
    },
    {
        id: 'MB5_unresponsive_phone_no',
        message: i18next.t('chatbot:mb5:mb5_unresponsive_phone_no'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive_phone_no',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please try to call the Host and give them at least 24 hours to get back to you. If they are still unresponsive, reach back to us again.'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB5_unresponsive_phone_yes',
        message: i18next.t('chatbot:mb5:mb5_unresponsive_phone_yes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive_phone_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Was your last attempt to contact the Host more than 24 hours ago?'
                })
            )
            return 'MB5_unresponsive.3'
        }
    },
    {
        id: 'MB5_unresponsive_phone_not_available',
        message: i18next.t('chatbot:mb5:mb5_unresponsive_phone_not_available'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive_phone_not_available',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: "We are sorry but the Host didn't share a valid phone number with us. What would you like to do?"
                })
            )
            return 'MB5_unresponsive.4'
        }
    },
    {
        id: 'MB5_unresponsive.3',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive.3',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB5_unresponsive.3_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive.3',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB5_unresponsive.3_no'
                }
            }
        ]
    },
    {
        id: 'MB5_unresponsive.3_no',
        message: i18next.t('chatbot:mb5:mb5_unresponsive.3_no'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive.3_no',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please allow a total of 24 hours since your last attempt to contact the Host. If the Host will still be unresponsive, reach back to us again.'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB5_unresponsive.3_yes',
        message: i18next.t('chatbot:mb5:mb5_unresponsive.3_yes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_unresponsive.3_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'We are sorry to hear that the Host is still unresponsive. What would you like to do?'
                })
            )
            return 'MB5_unresponsive.4'
        }
    },
    {
        id: 'MB5_unresponsive.4',
        options: [
            {
                value: 'send_reminder',
                label: i18next.t('chatbot:mb5:mb5_unresponsive.4'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive.4',
                            source: CHATBOT_SOURCES.USER,
                            text: '📩 Send a reminder to the Host'
                        })
                    )
                    return 'MB5_ticket'
                }
            },
            {
                value: 'cancel_shoot',
                label: i18next.t('chatbot:mb5:mb5_unresponsive.4_cancel'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB5_unresponsive.4',
                            source: CHATBOT_SOURCES.USER,
                            text: '🚫 Cancel the photoshoot on behalf of the Host'
                        })
                    )
                    return 'MB5_ticket'
                }
            }
        ]
    },

    // Cancellation notes
    {
        id: 'MB5_cancellation_notes',
        message: i18next.t('chatbot:commons:detail_cancellation_reason'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_cancellation_notes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please detail the cancellation reason.'
                })
            )
            return 'MB5_cancellation_notes_input'
        }
    },
    {
        id: 'MB5_cancellation_notes_input',
        user: true,
        validator: value => {
            if (value === '') {
                return i18next.t('chatbot:commons:enter_message')
            } else if (value.length < 30) {
                return i18next.t('chatbot:commons:message_should_be_longer')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_cancellation_notes_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB5_ticket'
        }
    },

    // Send ticket
    {
        id: 'MB5_ticket',
        message: i18next.t('chatbot:commons:share_airbnb_id'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please share the airbnb ID or any information to help us identify the listing in our system.'
                })
            )
            return 'MB5_ticket_message'
        }
    },
    {
        id: 'MB5_ticket_message',
        user: true,
        validator: value => (value === '' ? i18next.t('chatbot:commons:enter_message') : true),
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_ticket_message',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB5_ticket_send'
        }
    },
    {
        id: 'MB5_ticket_send',
        component: <TicketSubmit reason="generic_ticket" isNotListed />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB5_ticket_send',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        },
        asMessage: true
    }
]
