import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {debounce} from 'lodash-es'
import {useWindowWidth} from '@react-hook/window-size'

import {ActiveTab} from './active/Active'
import {Completed} from './completed/Completed'
import {TabGroup} from '../../../../../components/tab-group/TabGroup'
import {Switch} from '../../../../../components/switch-legacy/Switch'
import {InputText} from '../../../../../components/input-text/InputText'

import {CalendarIcon} from '../../../../../assets/icons/CalendarIcon'
import {SearchLgIcon} from '../../../../../components/icon'
import {ListIcon} from '../../../../../assets/icons/ListIcon'

import {ASSIGNMENT_STATUSES, TASK_STATUSES} from '../../../../../helpers/constants'
import {screenWidth} from '../../../../../theme/breakpoints'

import {MyScheduleWrapper, MyScheduleFiltersWrapper, MyScheduleSwitchWrapper} from './style'
import {useTheme} from 'styled-components'

export const CALENDAR_VIEW = 'calendar-view'
export const TABLE_VIEW = 'table-view'

export const MySchedule = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const isMD = useWindowWidth() <= screenWidth.MD
    const [selectedStatus, setSelectedStatus] = useState(ASSIGNMENT_STATUSES.ACCEPTED)
    const [selectedView, setSelectedView] = useState(CALENDAR_VIEW)
    const [search, setSearch] = useState(null)

    const viewsOptions = [
        {
            text: t('dashboard:calendar:filters:in_progress'),
            isSelected: selectedStatus === ASSIGNMENT_STATUSES.ACCEPTED,
            callback: () => onChangeView(ASSIGNMENT_STATUSES.ACCEPTED)
        },
        {
            text: t('dashboard:calendar:filters:completed'),
            isSelected: selectedStatus === TASK_STATUSES.COMPLETED,
            callback: () => onChangeView(TASK_STATUSES.COMPLETED)
        }
    ]

    const switchOptions = [
        {
            id: CALENDAR_VIEW,
            isSelected: selectedView === CALENDAR_VIEW,
            text: t('dashboard:calendar:filters:calendar'),
            icon: <CalendarIcon />,
            callback: () => setSelectedView(CALENDAR_VIEW)
        },
        {
            id: TABLE_VIEW,
            isSelected: selectedView === TABLE_VIEW,
            text: t('dashboard:calendar:filters:table'),
            icon: <ListIcon />,
            callback: () => setSelectedView(TABLE_VIEW)
        }
    ]

    const onChangeView = stat => {
        setSelectedStatus(stat)
        if (stat === TASK_STATUSES.COMPLETED) {
            setSelectedView(TABLE_VIEW)
        } else {
            setSelectedView(CALENDAR_VIEW)
        }
    }

    const onSearch = debounce(e => {
        const value = e?.target?.value
        if (value?.length) {
            setSearch(value)
        } else {
            setSearch(null)
        }
    }, 750)

    return (
        <MyScheduleWrapper type={selectedView}>
            <MyScheduleFiltersWrapper>
                <TabGroup tabs={viewsOptions} />
                <MyScheduleSwitchWrapper>
                    {selectedView === TABLE_VIEW && !isMD && (
                        <InputText
                            type="text"
                            placeholder={t('dashboard:calendar:table:search_by_listing_id')}
                            typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['400']} />}
                            onChange={onSearch}
                            inputSize="sm"
                            width="280px"
                        />
                    )}
                    {selectedStatus !== TASK_STATUSES.COMPLETED && <Switch options={switchOptions} />}
                </MyScheduleSwitchWrapper>
            </MyScheduleFiltersWrapper>
            {selectedView === TABLE_VIEW && isMD && (
                <InputText
                    type="text"
                    placeholder={t('dashboard:calendar:table:search_by_listing_id')}
                    typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['400']} />}
                    onChange={onSearch}
                    inputSize="sm"
                />
            )}
            <div>
                {selectedStatus === ASSIGNMENT_STATUSES.ACCEPTED ? (
                    <ActiveTab status={selectedStatus} selectedView={selectedView} search={search} />
                ) : (
                    <Completed status={selectedStatus} selectedView={selectedView} search={search} />
                )}
            </div>
        </MyScheduleWrapper>
    )
}
