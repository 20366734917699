import {StyledEmptyMessage, StyledDeleteTitle} from './style'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {toDeleteListSelector} from '../../../../../../store/matterport'
import PropTypes from 'prop-types'
import {List} from '../list/List'

export const ToDeleteList = ({hasSubmitError, rooms = [], amenities = []}) => {
    const {t} = useTranslation()
    const toDeleteList = useSelector(toDeleteListSelector)

    return (
        <div>
            <StyledDeleteTitle>{t('admin:delete_from_mys', {length: toDeleteList.length})}</StyledDeleteTitle>
            {toDeleteList.length ? (
                <List
                    isDeleted={true}
                    imagesList={toDeleteList}
                    hasSubmitError={hasSubmitError}
                    amenities={amenities}
                    rooms={rooms}
                />
            ) : (
                <StyledEmptyMessage>{t('admin:no_delete_images')}</StyledEmptyMessage>
            )}
        </div>
    )
}

ToDeleteList.propTypes = {
    hasSubmitError: PropTypes.bool,
    rooms: PropTypes.array,
    amenities: PropTypes.array
}
