import PropTypes, {shape} from 'prop-types'

import {ArrowDownIcon} from '../../../../assets/icons/ArrowDownIcon'
import {ArrowUpIcon} from '../../../../assets/icons/ArrowUpIcon'
import {ChevronDoubleIcon} from '../../../../assets/icons/ChevronDoubleIcon'
import {SortButton, StyledTH, StyledTHead} from './style'
import {TABLE_ALIGNMENTS} from '../../../../helpers/constants'

export const TableHeader = ({columns, sorter, sortColumnCb, variant = 'default'}) => {
    const handleSort = (sorterDirection, columnName) => {
        switch (sorterDirection) {
            case 'asc':
                sortColumnCb(null, null)
                break
            case 'desc':
                sortColumnCb(columnName, 'asc')
                break
            default:
                sortColumnCb(columnName, 'desc')
        }
    }

    return (
        <StyledTHead variant={variant}>
            {columns.map(column => {
                const sorterDirection = sorter?.order_by === column.name ? sorter?.order_direction : null

                return (
                    <StyledTH alignment={column.alignment} width={column.width} key={column.name}>
                        {column.icon}
                        <span title={column.label}>{column.label}</span>

                        {column.sortable && (
                            <SortButton onClick={() => handleSort(sorterDirection, column.name)}>
                                {sorterDirection === 'asc' ? (
                                    <ArrowUpIcon />
                                ) : sorterDirection === 'desc' ? (
                                    <ArrowDownIcon />
                                ) : (
                                    <ChevronDoubleIcon />
                                )}
                            </SortButton>
                        )}
                    </StyledTH>
                )
            })}
        </StyledTHead>
    )
}

export const HeaderVariant = PropTypes.oneOf(['default', 'grey'])

TableHeader.propTypes = {
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({width}, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            }
        })
    ).isRequired,
    sorter: PropTypes.object,
    sortColumnCb: PropTypes.func,
    variant: HeaderVariant
}
