import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_ACTION_KEYS, QUERY_KEYS} from 'src/queryClient.ts'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'
import {useAssignmentsCount} from 'src/features/assignments/services/useAssignmentsCount.ts'
import {useAssignmentsRequests} from 'src/hooks/useAssignmentsRequests.ts'
import {httpAcceptAssignment} from 'src/features/assignment/services/assignment.http.ts'
import {Assignment} from 'src/features/assignment/types.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'

export const useAcceptAssignment = (assignment: Assignment) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()
    const assignmentsCountQuery = useAssignmentsCount()
    const {newShootStatusToggle} = useAssignmentsRequests()

    return useMutation({
        mutationKey: [MUTATION_KEYS.ACCEPT_ASSIGNMENT],
        mutationFn: () =>
            parseAxiosPromise({
                axiosPromise: httpAcceptAssignment({
                    urlParams: {assignmentId: assignment.id},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onSuccess: data => {
            newShootStatusToggle(data)
            invalidationSignal(QUERY_ACTION_KEYS.ASSIGNMENT_ACCEPTED)
            assignmentsCountQuery.updateShootsCount(
                assignmentsCountQuery.data
                    ? {
                          ...assignmentsCountQuery.data,
                          assignments_request: assignmentsCountQuery.data.assignments_request - 1,
                          assignments_current: assignmentsCountQuery.data.assignments_current + 1,
                          pending_date: assignmentsCountQuery.data.pending_date + 1
                      }
                    : {
                          need_more_assets: 0,
                          pending_assets: 0,
                          assignments_request: 0,
                          assignments_current: 1,
                          pending_date: 1
                      }
            )
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.ASSIGNMENT, assignment.code]}, data)
            void queryClient.invalidateQueries([QUERY_KEYS.SPACES])
        },
        onError: errorHandler
    })
}
