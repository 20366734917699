import styled, {css} from 'styled-components'
import {ScrollAreaThumb, Scrollbar} from '@radix-ui/react-scroll-area'

export const StyledScrollbar = styled(Scrollbar)`
    display: flex;
    user-select: none;
    touch-action: none;
    background-color: transparent;

    &[data-orientation='horizontal'] {
        flex-direction: column;
        height: 6px;
    }
    &[data-orientation='vertical'] {
        width: 6px;
    }
`

export const StyledScrollbarThumb = styled(ScrollAreaThumb)(
    ({theme: {palette}}) => css`
        flex: 1;
        background: ${palette.neutral['300']};
        border-radius: 3px;
        position: relative;
        transition: background-color ease-in-out 200ms;

        &:hover {
            background: ${palette.neutral['400']};
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    `
)
