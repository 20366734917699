import styled, {css} from 'styled-components'
import {BackArrowIcon, CopyIcon} from 'src/theme/icons.tsx'

export const StyledSubheader = styled.div(
    ({theme: {spacing, colors}}) => css`
        position: relative;
        width: 100%;
        padding: ${spacing * 8}px;
        background: ${colors.extraLightGrey};
        margin-bottom: ${spacing * 16}px;
    `
)

export const StyledData = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: ${spacing * 4}px;
        & > div {
            display: flex;
            align-items: flex-start;
            gap: ${spacing * 2}px;
            ${typography.textMd};
            color: ${palette.neutral.black};
            & > label {
                font-weight: 400;
                flex: none;
            }
            & > span {
                font-weight: 700;
                word-break: break-word;
                & > a {
                    font-weight: 700;
                    ${typography.textSm};
                    word-break: break-word;
                }
            }
        }
    `
)

export const StyledEligible = styled.div(
    ({theme: {palette}}) => css`
        & > span {
            font-weight: 700;
            & > svg {
                width: 14px;
                fill: ${palette.neutral.black};
            }
        }
    `
)

export const StyledButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
`

export const StyledCopyIcon = styled(CopyIcon)(
    ({theme: {spacing, transition}}) => css`
        width: 12px;
        margin-left: ${spacing}px;
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    `
)

export const StyledBackButton = styled(BackArrowIcon)(
    ({theme: {spacing, palette, transition}}) => css`
        position: absolute;
        top: ${spacing}px;
        left: ${spacing * 6}px;
        width: 32px;
        fill: ${palette.neutral.black};
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    `
)

export const StyledButtonsRow = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: ${spacing * 6}px;
    `
)
