import React, {FC} from 'react'
import {StyledCalendarWrapper, StyledCalendarHeader, StyledYearMonthGrid} from 'src/components/calendar/style.ts'
import {DPPropGetter, DPYear} from '@rehookify/datepicker'
import dayjs from 'src/dayjs.ts'
import {ChevronLeftIcon, ChevronRightIcon} from 'src/components/icon'
import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button.tsx'
import {View} from 'src/components/calendar/Calendar.tsx'

type YearSelectorProps = {
    years: DPYear[]
    yearButton: (year: DPYear) => DPPropGetter
    nextYearsButton: () => DPPropGetter
    previousYearsButton: () => DPPropGetter
    currentYear: string
    setViewState: React.Dispatch<React.SetStateAction<View>>
}
export const YearSelector: FC<YearSelectorProps> = ({
    years,
    yearButton,
    nextYearsButton,
    previousYearsButton,
    currentYear,
    setViewState
}) => {
    const {t} = useTranslation()

    return (
        <StyledCalendarWrapper>
            <StyledCalendarHeader>
                <Button shape="square" variant="ghost" {...previousYearsButton()}>
                    <ChevronLeftIcon />
                </Button>
                <h3>{t('components:commons:calendar:years')}</h3>
                <Button shape="square" variant="ghost" {...nextYearsButton()}>
                    <ChevronRightIcon />
                </Button>
            </StyledCalendarHeader>
            <StyledYearMonthGrid>
                {years.map(year => (
                    <Button
                        key={year.year}
                        {...yearButton(year)}
                        variant={year.year.toString() == currentYear ? 'primary' : 'ghost'}
                        onClick={event => {
                            yearButton(year).onClick?.(event)
                            setViewState('date')
                        }}
                    >
                        {dayjs(year.$date).format('YYYY')}
                    </Button>
                ))}
            </StyledYearMonthGrid>
            <Button variant="ghost" onClick={() => setViewState('date')}>
                {t('components:commons:calendar:back')}
            </Button>
        </StyledCalendarWrapper>
    )
}
