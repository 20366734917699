import {createSelector, createSlice, SliceCaseReducers} from '@reduxjs/toolkit'
import {ALERT_LEVELS} from '../helpers/constants'
import dayjs from 'dayjs'
import {appLanguages} from 'src/translations/i18n.ts'

interface AppGenericsStore {
    windowSize: number | null
    language: (typeof appLanguages)[number]['value'] | null
    alert: {
        message: string
        level: 'success' | 'warning' | 'error'
        timeout: number
        show: boolean
    }
}

const appGenerics = createSlice<AppGenericsStore, SliceCaseReducers<AppGenericsStore>, 'appGenerics'>({
    name: 'appGenerics',
    initialState: {
        windowSize: null,
        language: null,
        alert: {
            message: '',
            level: ALERT_LEVELS.ERROR,
            timeout: 0,
            show: false
        }
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload
            dayjs.locale(action.payload) // change dayjs globally
        },
        showAlert: (state, action) => {
            const message = action?.payload?.message
            const level = action?.payload?.level
            state.alert = {message, level, timeout: 6000, show: true}
        },
        hideAlert: state => {
            const alert = state.alert
            state.alert = {...alert, timeout: 0, show: false}
        }
    }
})

export const appGenericsReducer = appGenerics.reducer

const selectSelf = (state: {appGenerics: AppGenericsStore}) => state.appGenerics
export const languageSelector = createSelector(selectSelf, state => state.language)
export const alertSelector = createSelector(selectSelf, state => state.alert)

export const {setLanguage, showAlert, hideAlert} = appGenerics.actions
