import {Controller, useFormContext} from 'react-hook-form'
import {CreateTaskValidationSchema} from 'src/features/admin-host-approval/components/create-task-modal/CreateTaskModal.tsx'
import {ComboboxProvider, SelectProvider} from '@ariakit/react'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Label} from 'src/components/label/Label.tsx'
import {useTranslation} from 'react-i18next'
import {SelectTrigger} from 'src/components/select-atoms/SelectAtoms.tsx'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText.tsx'
import {AdminSelectPopover} from 'src/features/admin-host-approval/components/admin-select-popover/AdminSelectPopover.tsx'
import {useTheme} from 'styled-components'
import {AdminSelectDisplayValue} from 'src/features/admin-host-approval/components/admin-select-display-value/AdminSelectDisplayValue.tsx'
import {useUserStore} from 'src/features/user/store'

export const AssignedToField = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const form = useFormContext<CreateTaskValidationSchema>()
    const currentAdmin = useUserStore(state => state.currentAdmin)

    return (
        <Controller
            control={form.control}
            name="assigned_to"
            render={({field: {value, onChange}}) => (
                <ComboboxProvider>
                    <SelectProvider
                        setValue={onChange}
                        value={value?.toString()}
                        defaultValue={currentAdmin?.id.toString() ?? ''}
                    >
                        <Flexbox direction="column" gap={2} fullWidth>
                            <Label>{t('commons:assigned_to')}</Label>
                            <SelectTrigger
                                fullWidth
                                placeholder={t('admin_host_approval:assigned_to_field_placeholder')}
                                displayValue={value => {
                                    return value ? (
                                        <Flexbox align="center" gap={2}>
                                            <AdminSelectDisplayValue id={value.toString()} />
                                        </Flexbox>
                                    ) : currentAdmin ? (
                                        <Flexbox align="center" gap={2}>
                                            <AdminSelectDisplayValue id={currentAdmin.id.toString()} />
                                        </Flexbox>
                                    ) : undefined
                                }}
                            />
                            <AdminSelectPopover style={{zIndex: theme.zIndex.modal}} sameWidth />
                            <InputHelpText error={form.formState.errors.assigned_to?.message} />
                        </Flexbox>
                    </SelectProvider>
                </ComboboxProvider>
            )}
        />
    )
}
