import * as Dropdown from '@radix-ui/react-dropdown-menu'
import {ReactElement, ReactNode, useRef} from 'react'
import {ContextMenuIcon} from '../../theme/icons'

import {StyledIcon, StyledMenu, StyledMenuItem} from './style'

export const DropdownMenu = ({
    menuItems = [],
    trigger = <ContextMenuIcon />
}: {
    menuItems: Array<{text: string; callback: () => void; icon: ReactElement}>
    trigger: ReactNode
}) => {
    const ref = useRef<HTMLButtonElement | null>(null)
    return (
        <Dropdown.Root>
            <Dropdown.Trigger asChild id="trigger" ref={ref}>
                <StyledIcon>{trigger}</StyledIcon>
            </Dropdown.Trigger>

            <Dropdown.Portal container={ref?.current}>
                <Dropdown.Content sideOffset={4} asChild align="end">
                    <StyledMenu>
                        {menuItems
                            .filter(e => e)
                            .map((menuItem, index) => (
                                <Dropdown.Item key={index} asChild>
                                    <StyledMenuItem onClick={menuItem.callback}>
                                        {menuItem?.text}
                                        {menuItem?.icon}
                                    </StyledMenuItem>
                                </Dropdown.Item>
                            ))}
                    </StyledMenu>
                </Dropdown.Content>
            </Dropdown.Portal>
        </Dropdown.Root>
    )
}
