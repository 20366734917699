import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {MultiParagraphMessage} from '../custom-components/multi-paragraph-message/MultiParagraphMessage'
import i18next from 'i18next'
import {SimpleLink} from '../custom-components/simple-link/SimpleLink'

export const mb16FlowSteps = () => [
    {
        id: 'MB1.6',
        component: (
            <MultiParagraphMessage
                paragraphs={[
                    i18next.t('chatbot:mb16:mb1.6_1'),
                    i18next.t('chatbot:mb16:mb1.6_2'),
                    i18next.t('chatbot:mb16:mb1.6_3')
                ]}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.6',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text:
                        'Hosts now have the ability to share two available photoshoot dates and times in their Host Portal. ' +
                        'If the Host did not share any preferred shoot dates, contact the Host via email or phone to determine the photoshoot date and time. ' +
                        'You can review this guide for more information and details on the coordination system: https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf'
                })
            )
            return 'MB1.6_link'
        }
    },
    {
        id: 'MB1.6_link',
        component: (
            <SimpleLink
                link="https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf"
                text={i18next.t('chatbot:commons:coordination_system_guide')}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.6_link',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] Coordination system guide'
                })
            )
            return 'send_log'
        }
    }
]
