import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {SorterModal} from './sorter-modal/SorterModal'
import {ArrowDownIcon} from '../../../../assets/icons/ArrowDownIcon'
import {ArrowUpIcon} from '../../../../assets/icons/ArrowUpIcon'
import {ChevronDoubleIcon} from '../../../../assets/icons/ChevronDoubleIcon'
import {TableButton} from '../../desktop/button/TableButton'

export const TableMobileHeader = ({columns, sorter, sortColumnCb}) => {
    const {t} = useTranslation()
    const orderOptions = columns.filter(e => e.sortable).map(e => ({label: e.label, value: e.name}))
    const [showSortModal, setShowSortModal] = useState(false)
    const currentSortedColumn = columns.find(column => column.name === sorter?.order_by)

    const getSortIcon = () => {
        if (currentSortedColumn) {
            return sorter?.order_direction === 'asc' ? (
                <ArrowUpIcon
                    onClick={e => {
                        e.stopPropagation()
                        sortColumnCb(null, null)
                    }}
                />
            ) : sorter?.order_direction === 'desc' ? (
                <ArrowDownIcon
                    onClick={e => {
                        e.stopPropagation()
                        sortColumnCb(sorter?.order_by, 'asc')
                    }}
                />
            ) : (
                <ChevronDoubleIcon
                    onClick={e => {
                        e.stopPropagation()
                        sortColumnCb(sorter?.order_by, 'desc')
                    }}
                />
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            <TableButton
                text={`${t('commons:order_by')}: ${currentSortedColumn?.label ?? '-'}`}
                icon={getSortIcon()}
                onClick={() => {
                    setShowSortModal(true)
                }}
            />
            {showSortModal && (
                <SorterModal
                    onClose={() => {
                        setShowSortModal(false)
                    }}
                    title="commons:order_by"
                    options={orderOptions}
                    sortColumnCb={sortColumnCb}
                />
            )}
        </>
    )
}
