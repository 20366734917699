import {useMutation, useQueryClient} from '@tanstack/react-query'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {httpForceMatch, HttpForceMatchOptions} from 'src/features/admin/services/admin.http.ts'
import {useUserStore} from 'src/features/user/store.ts'

export const useForceMatch = ({quoteId, onSuccess}: {quoteId: number; onSuccess: () => void}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useMutation<unknown, unknown, HttpForceMatchOptions['payload']>({
        mutationFn: payload => {
            return httpForceMatch({
                headers: makeHttpAuthorizationHeader(adminAccessToken),
                urlParams: {quoteId},
                payload
            })
        },
        onSuccess: () => {
            void queryClient.cancelQueries([QUERY_KEYS.ADMIN_QUOTE, quoteId])
            void queryClient.invalidateQueries([QUERY_KEYS.ADMIN_QUOTE, quoteId])
            void queryClient.cancelQueries([QUERY_KEYS.ADMIN_QUOTE_LOGS, quoteId])
            void queryClient.invalidateQueries([QUERY_KEYS.ADMIN_QUOTE_LOGS, quoteId])
            onSuccess()
        },
        onError: errorHandler
    })
}
