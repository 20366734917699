import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {httpGetUnresponsiveHostReasons} from 'src/features/assignment/services/assignment.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {z} from 'zod'
import {captureException} from '@sentry/react'

export const ResponseUnresponsiveHostReasons = z.array(
    z.object({
        name: z.enum([
            'host_has_not_responded',
            'host_has_stopped_responding',
            'invalid_cellular_number_provided',
            'other'
        ]),
        id: z.number().int().positive()
    })
)
export type ResponseUnresponsiveHostReasons = z.infer<typeof ResponseUnresponsiveHostReasons>

export const useUnresponsiveHostReasons = () => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.UNRESPONSIVE_HOST_REASONS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetUnresponsiveHostReasons({
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: ResponseUnresponsiveHostReasons,
                onZodError: captureException
            })
    })
}
