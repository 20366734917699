import {
    StyledAmenetiesList,
    StyledBackButton,
    StyledEligible,
    StyledLeftSection,
    StyledListItem,
    StyledRightSection,
    StyledSubheader,
    StyledWrapper
} from './style'
import {openLinkInNewTab, renderTableMatterportStatus} from '../../../../../../helpers/utils'
import {useTranslation} from 'react-i18next'
import {Chip} from '../../../../../../components/chip/Chip'
import {useRef} from 'react'
import {useSelector} from 'react-redux'
import {selectedAmenitiesCounterSelector, taskSelector} from '../../../../../../store/matterport'
import {CancelIcon, CheckIcon} from '../../../../../../theme/icons'
import {ROUTES} from '../../../../../../helpers/constants'
import {useNavigate} from 'react-router'
import {Button} from '../../../../../../components/button/Button'

export const ConfirmReviewSubheader = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const task = useSelector(taskSelector)
    const selectedAmenitiesCounter = useSelector(selectedAmenitiesCounterSelector)
    const status = renderTableMatterportStatus(task?.task_matterport?.status)
    const ref = useRef()
    const totalSelectedAmenities = selectedAmenitiesCounter.reduce(
        (previousValue, currentValue) => previousValue + currentValue.linkedImagesIds.length,
        0
    )
    const leftAmenitiesColumnList = selectedAmenitiesCounter.filter((amenity, index) => index % 2 === 0)
    const rightAmenitiesColumnList = selectedAmenitiesCounter.filter((amenity, index) => index % 2 !== 0)

    return (
        <StyledSubheader ref={ref}>
            <StyledBackButton
                onClick={() =>
                    navigate(ROUTES.ADMIN_MATTERPORT, {
                        state: task?.task_matterport?.status
                    })
                }
            />
            <StyledWrapper>
                <StyledLeftSection>
                    <div>
                        <label>{t('admin:listing_title')}</label>
                        <span>{task?.title || '-'}</span>
                    </div>
                    <div>
                        <label>
                            {t('admin:listing_id')}
                            {':'}
                        </label>
                        <span>{task?.external_id || '-'}</span>
                    </div>
                    <div>
                        <label>{t('commons:reviewer')}</label>
                        <span>
                            {task?.task_matterport?.reviewer_admin
                                ? `${task?.task_matterport?.reviewer_admin?.first_name} ${task?.task_matterport?.reviewer_admin?.last_name}`
                                : '-'}
                        </span>
                    </div>
                    <div>
                        <label>
                            {t('commons:status')}
                            {':'}
                        </label>
                        <span>
                            <Chip
                                rounded
                                textUppercase
                                borderColor={status.color}
                                background={status.color}
                                color="white"
                                text={t(status.label)}
                            />
                        </span>
                    </div>
                    <StyledEligible>
                        <label>
                            {t('admin:eligible_for_accessibility')}
                            {':'}
                        </label>
                        <span>
                            {task?.entity?.exclude_for_category ? (
                                <>
                                    {t('commons:no')} <CancelIcon />
                                </>
                            ) : (
                                <>
                                    {t('commons:yes')} <CheckIcon />
                                </>
                            )}
                        </span>
                    </StyledEligible>
                    <Button onClick={() => openLinkInNewTab(task?.pdp_link)} variant="secondary">
                        {'PDP'}
                    </Button>
                </StyledLeftSection>
                <StyledRightSection>
                    <h5>
                        {t('admin:selected_accessibility_features', {
                            total: totalSelectedAmenities || 0
                        })}
                    </h5>
                    <StyledAmenetiesList>
                        <div>
                            {leftAmenitiesColumnList.map(amenity => (
                                <ListItem amenity={amenity} key={amenity.id} />
                            ))}
                        </div>
                        <div>
                            {rightAmenitiesColumnList.map(amenity => (
                                <ListItem amenity={amenity} key={amenity.id} />
                            ))}
                        </div>
                    </StyledAmenetiesList>
                </StyledRightSection>
            </StyledWrapper>
        </StyledSubheader>
    )
}

const ListItem = ({amenity}) => {
    const {t} = useTranslation()

    return (
        <StyledListItem>
            <div>{amenity.linkedImagesIds?.length}</div>
            <span title={t(`admin:amenities:${amenity.key?.toLowerCase()}`)}>
                {t(`admin:amenities:${amenity.key?.toLowerCase()}`)}
            </span>
        </StyledListItem>
    )
}
