import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

export const StyledOverlay = styled.div(
    ({theme: {palette, zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral['600']};
        opacity: 0.6;
        z-index: ${zIndex.modalOverlay};
    `
)

export const StyledModal = styled.div<{width?: CSSProperties['width']}>(
    ({width, theme: {palette, zIndex, shadows, spacing}}) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 12px;
        background: ${palette.neutral.white};
        width: ${width}px;
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        overflow-y: auto;
        box-shadow: ${shadows.lg};
    `
)
