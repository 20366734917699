import {forwardRef, InputHTMLAttributes} from 'react'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText.tsx'
import {StyledRadio, StyledRadioInputWrapper} from './style.ts'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
    labelPosition?: 'left' | 'right'
    errorMessage?: string
    helpText?: string
    variant?: 'primary' | 'grayModern'
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
    ({label, labelPosition = 'right', helpText, variant = 'primary', ...rest}, ref) => {
        return (
            <Flexbox direction="column" gap={2}>
                <StyledRadioInputWrapper>
                    <StyledRadio $variant={variant} $labelPosition={labelPosition} type="radio" ref={ref} {...rest} />
                    <p>{label}</p>
                </StyledRadioInputWrapper>
                <InputHelpText helpText={helpText} />
            </Flexbox>
        )
    }
)

Radio.displayName = 'Radio'
