import {UserIcon} from '../../../../theme/icons'
import {Select} from '../../../../components/select/Select'
import {Button} from '../../../../components/button/Button'
import {Card} from '../../../../components/card/Card'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useCallback, useEffect, useState} from 'react'
import {
    httpChangeUserMarkets,
    httpGetAllRegions,
    httpGetFilteredMarketSelect
} from '../../../../http-requests/user.http'
import {errorHandler, retrieveValueForRs} from '../../../../helpers/utils'
import {Spinner} from '../../../../components/spinner-legacy/Spinner'
import {MARKET_TYPES} from '../../../../helpers/constants'
import {SuccessModal} from '../../../../components/success-modal/SuccessModal'

import {SelectCountriesWrapper, SelectsWrapper} from './style'
import {useUserStore} from '../../../../features/user/store'

export const Markets = () => {
    const {t} = useTranslation()
    const changeUserMarkets = useUserStore(store => store.changeUserMarkets)
    const setMarkets = useUserStore(store => store.setMarkets)
    const accessToken = useUserStore(store => store.accessToken)
    const currentUserMarkets = useUserStore(store => store.currentUser.photographers_markets)
    const marketsList = useUserStore(store => store.markets)
    const [isLoading, setIsLoading] = useState(false)
    const [isInitializing, setIsInitializing] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const validationSchema = Yup.object().shape({
        region: Yup.object().nullable().required(t('errors:required')),
        countries: Yup.array().min(1, t('errors:required')).required(t('errors:required')),
        markets: Yup.array().min(1, t('errors:required')).required(t('errors:required'))
    })

    const {
        handleSubmit,
        setValue,
        control,
        formState: {errors}
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        resolver: yupResolver(validationSchema)
    })

    const selectedRegion = useWatch({name: 'region', control: control})
    const selectedCountries = useWatch({name: 'countries', control: control})
    const selectedMarkets = useWatch({name: 'markets', control: control})

    useEffect(() => {
        setIsInitializing(true)
        getAllRegions()
    }, [])

    useEffect(() => {
        //setting initial value of region
        if (marketsList.regions.length > 0 && isInitializing) {
            setValue('region', retrieveValueForRs(marketsList.regions, currentUserMarkets?.[0]?.region))
        }
    }, [marketsList.regions])

    useEffect(() => {
        //cleaning the countries field
        if (selectedCountries?.length > 0) {
            setValue('countries', [])
        }

        //fetching countries of the selected region
        if (selectedRegion) {
            getFilteredMarkets('region', selectedRegion?.value, MARKET_TYPES.COUNTRIES)
        }
        if (isInitializing && !selectedRegion) {
            setIsInitializing(false)
        }
    }, [selectedRegion])

    useEffect(() => {
        //setting initial value of countries
        if (marketsList.countries.length > 0 && isInitializing) {
            setValue(
                'countries',
                retrieveValueForRs(
                    marketsList.countries,
                    currentUserMarkets?.map(market => market?.country),
                    true
                )
            )
        }
    }, [marketsList.countries])

    useEffect(() => {
        //cleaning the markets field
        if (selectedMarkets?.length > 0) {
            setValue('markets', [])
        }
        //fetching markets of the selected countries
        if (selectedCountries?.length > 0) {
            getFilteredMarkets(
                'countries',
                selectedCountries?.map(country => country?.value),
                MARKET_TYPES.MARKETS
            )
        }
    }, [selectedCountries])

    useEffect(() => {
        //setting initial value of markets
        if (marketsList.markets.length > 0 && isInitializing) {
            setValue(
                'markets',
                retrieveValueForRs(
                    marketsList.markets,
                    currentUserMarkets?.map(e => e.external_id),
                    true
                )
            )
            setIsInitializing(false)
        }
    }, [marketsList.markets])

    const setShowSuccessModalCb = useCallback(() => setShowSuccessModal(!showSuccessModal), [showSuccessModal])

    const getFilteredMarkets = async (filterName, filterValue, marketType) => {
        try {
            setIsLoading(true)
            const {data} = await httpGetFilteredMarketSelect(accessToken, filterName, filterValue)
            setMarkets({type: marketType, data: data[marketType]})
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const getAllRegions = async () => {
        try {
            setIsLoading(true)
            const {data} = await httpGetAllRegions(accessToken)
            setMarkets({type: MARKET_TYPES.REGIONS, data})
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const onSubmit = async formValues => {
        try {
            setIsLoading(true)
            await httpChangeUserMarkets(
                accessToken,
                formValues?.markets?.map(e => e.value)
            )
            changeUserMarkets(formValues)
            setShowSuccessModalCb()
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Card title={t('settings:markets')} icon={<UserIcon />}>
            {showSuccessModal && (
                <SuccessModal
                    onClose={setShowSuccessModalCb}
                    callback={setShowSuccessModalCb}
                    buttonLabel={t('commons:ok')}
                    paragraph="settings:markets_updated"
                />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <SelectsWrapper>
                    <SelectCountriesWrapper>
                        <div>
                            <Select
                                label={t('settings:select_region')}
                                name="region"
                                control={control}
                                errors={errors.region}
                                placeholder={t('settings:select_region')}
                                options={marketsList.regions}
                                disabled={isInitializing}
                            />
                        </div>
                        <div>
                            <Select
                                label={t('settings:select_country')}
                                name="countries"
                                control={control}
                                errors={errors.countries}
                                placeholder={t('settings:select_country')}
                                isMulti
                                options={marketsList.countries}
                                disabled={!marketsList.countries.length || !selectedRegion || isInitializing}
                            />
                        </div>
                    </SelectCountriesWrapper>
                    <div>
                        <Select
                            label={t('settings:select_markets')}
                            name="markets"
                            control={control}
                            errors={errors.markets}
                            isMulti
                            placeholder={t('settings:select_markets')}
                            options={marketsList.markets}
                            disabled={!marketsList.markets.length || !selectedCountries?.length || isInitializing}
                        />
                    </div>
                </SelectsWrapper>
                <div className="d-flex justify-content-end">
                    <Button type="submit" disabled={isLoading || isInitializing}>
                        {t('settings:update_markets')}
                    </Button>
                </div>
            </form>

            {(isLoading || isInitializing) && <Spinner />}
        </Card>
    )
}
