import styled, {css} from 'styled-components'

export const StyledShoots = styled.div(
    ({theme: {colors, spacing}}) => css`
        background: ${colors.lightGrey};
        padding: ${spacing * 4}px;
        box-shadow: rgb(0 0 0 / 15%) 0 4px 8 0;
        border-radius: 8px;
        display: grid;
        gap: ${spacing * 3}px;
        &.not-listed-button {
            pointer-events: none;
        }
    `
)

export const StyledShoot = styled.div(
    ({theme: {colors, spacing, typography, transition, palette}, selected}) => css`
        background: ${palette.neutral.white};
        padding: ${spacing * 1.5}px ${spacing * 4}px;
        box-shadow: rgb(0 0 0 / 5%) 0 4px 8px 0;
        text-align: center;
        ${typography.textSm};
        font-weight: 600;
        cursor: pointer;
        ${transition};
        display: grid;
        align-items: center;
        gap: ${spacing * 2}px;
        & > span {
            grid-column: span 2;
            justify-content: start;
            text-align: start;
            color: ${colors.red};
            font-weight: 700;
        }
        & p {
            display: grid;
            grid-row-start: 2;
            grid-template-columns: auto 1fr;
            gap: ${spacing}px;
            text-align: left;
            & span {
                font-weight: 400;
                max-width: 80px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        &:hover {
            background: ${colors.whiteSmoke};
        }
        ${selected &&
        css`
            pointer-events: none;
        `}
    `
)
