import {FC, HTMLAttributes} from 'react'

export const EmailIcon: FC<{size?: number; fill?: string} & HTMLAttributes<SVGSVGElement>> = ({
    size = 16,
    fill = 'currentColor'
}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.667082 3.98844C0.666974 3.99502 0.666963 4.00159 0.667049 4.00817V12C0.667049 13.1015 1.56553 14 2.66705 14H13.3337C14.4352 14 15.3337 13.1015 15.3337 12V4.00824C15.3338 4.00161 15.3338 3.99498 15.3337 3.98834C15.3274 2.89214 14.4314 2 13.3337 2H2.66705C1.56938 2 0.673343 2.8922 0.667082 3.98844ZM2.07143 3.70263C2.18169 3.48462 2.40841 3.33333 2.66705 3.33333H13.3337C13.5924 3.33333 13.8191 3.48462 13.9293 3.70263L8.00038 7.8529L2.07143 3.70263ZM14.0004 5.28044V12C14.0004 12.3651 13.6989 12.6667 13.3337 12.6667H2.66705C2.30191 12.6667 2.00038 12.3651 2.00038 12V5.28044L7.61807 9.21282C7.84762 9.3735 8.15314 9.3735 8.38269 9.21282L14.0004 5.28044Z"
            fill={fill}
        />
    </svg>
)
