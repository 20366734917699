import styled, {css} from 'styled-components'

export const TransactionsListWrapper = styled.div(
    ({theme: {spacing, colors, typography, mediaQuery}}) => css`
        padding: ${spacing * 2}px ${spacing * 8}px ${spacing * 2}px ${spacing * 16}px;
        border-top: 1px solid ${colors.gainsboroGrey};
        color: ${colors.lightBlack};
        ${typography.textSm};

        ${mediaQuery.LG} {
            padding: ${spacing * 2}px;
        }
    `
)

export const StyledTransactionContainer = styled.div`
    ${({theme: {colors, mediaQuery}}) => css`
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 1px solid ${colors.gainsboroGrey};
        }

        .amount {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        ${mediaQuery.LG} {
            height: 60px;

            &:not(:last-child) {
                border-bottom: none;
            }
        }
    `}
`

export const StyledTransactionTitle = styled.div`
    ${({theme: {typography}}) => css`
        & h3 {
            font-weight: 500;
            ${typography.textSm};
        }
        & span {
            font-weight: 400;
        }
    `}
`

export const StyledTransactionAmount = styled.span<{$type: 'positive' | 'neutral' | 'negative'}>`
    ${({$type, theme: {colors}}) => css`
        font-weight: 500;
        color: ${$type == 'positive' ? colors.darkGreen : $type == 'negative' ? colors.darkRed : colors.lightBlack};
    `}
`
