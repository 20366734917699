import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminAdminHostApprovalApprovedImagesHeader = styled(Flexbox)(
    ({theme: {spacing, typography}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;

        & > h3 {
            ${typography.textLg};
            font-weight: 500;
        }
    `
)
