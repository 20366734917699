import styled, {css} from 'styled-components'

export const StyledDates = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        ${typography.textSm};

        & h4 {
            color: ${palette.neutral['600']};
        }
        & p {
            font-weight: 500;
        }
        & li {
            &:before {
                content: '• ';
            }
            font-weight: 500;
        }
    `
)
