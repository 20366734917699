import styled, {css} from 'styled-components'
import * as Ariakit from '@ariakit/react'

export const StyledPopover = styled(Ariakit.Popover)(
    ({theme: {palette, shadows, zIndex}}) => css`
        width: 320px;
        height: 390px;
        overflow-y: auto;
        z-index: ${zIndex.modalDatePicker};
        background-color: ${palette.neutral.white};
        border-radius: 4px;
        box-shadow: ${shadows.md};
    `
)

export const StyledDialog = styled(Ariakit.Dialog)(
    ({theme: {palette, shadows, zIndex}}) => css`
        width: 320px;
        height: 390px;
        overflow-y: auto;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modalDatePicker};
        background-color: ${palette.neutral.white};
        border-radius: 4px;
        box-shadow: ${shadows.md};
    `
)

export const StyledDialogBackdrop = styled.div(
    ({theme: {palette}}) => css`
        background: ${palette.neutral['600']};
        opacity: 0.6;
    `
)

export const StyledTimeListItem = styled.div<{$isSelected: boolean}>(
    ({theme: {palette, typography}, $isSelected}) => css`
        height: 40px;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        ${typography.textSm};
        ${$isSelected
            ? css`
                  font-weight: 500;
                  background-color: ${palette.primary['600']};
                  color: ${palette.neutral.white};
                  &:hover {
                      background-color: ${palette.primary['700']};
                  }
              `
            : css`
                  color: ${palette.neutral['700']};
                  &:hover {
                      background-color: ${palette.neutral['50']};
                      font-weight: 500;
                  }
              `}
    `
)
