import styled, {css} from 'styled-components'

export const StyledAssignmentCard = styled.div(
    ({theme: {spacing, colors, shadows, palette}}) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        padding: ${spacing * 6}px ${spacing * 4}px;
        border-radius: 16px;
        border: 1px solid ${colors.smokeGrey};
        background: ${palette.neutral.white};
        gap: ${spacing * 6}px;
        box-shadow: ${shadows.lightGrey};
    `
)

export const StyledAssignmentCardHeader = styled.div(
    ({theme: {colors}}) => css`
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        border-bottom: 1px solid ${colors.gainsboroGrey};
    `
)

export const StyledAssignmentCardStatusHeader = styled.div(
    ({theme: {spacing, typography, colors}}) => css`
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-top: ${spacing * 2}px;
        padding-bottom: ${spacing * 6}px;
        gap: ${spacing * 2}px;

        & > span {
            ${typography.textSm};
            font-weight: 400;
            color: ${colors.steelGrey};
            & strong {
                margin-left: ${spacing * 2}px;
                font-weight: 500;
                color: ${colors.lightBlack};
            }
        }
    `
)

export const StyledAssignmentCardTitle = styled.div(
    ({theme: {spacing, colors, mediaQuery, typography}}) => css`
        display: flex;
        justify-content: space-between;

        & h3 {
            ${typography.textLg};
            font-weight: 700;
            color: ${colors.lightBlack};
        }
        & .title {
            display: flex;

            align-items: center;
            gap: ${spacing * 4}px;
        }
        & .actions {
            display: flex;
            justify-content: flex-end;
        }
        & p {
            ${typography.textSm};
            font-weight: 500;
            color: ${colors.darkGrey};
        }
        ${mediaQuery.LG} {
            flex-wrap: wrap;
        }
    `
)

export const StyledAssignmentCardDetails = styled.div(
    ({theme: {spacing, mediaQuery}}) => css`
        display: grid;
        gap: ${spacing * 6}px;
        grid-template-columns: repeat(4, minmax(200px, 1fr)); /* Adjust the minmax values as needed */
        ${mediaQuery.XL} {
            grid-template-columns: repeat(3, minmax(200px, 1fr));
        }
        ${mediaQuery.LG} {
            grid-template-columns: repeat(2, minmax(200px, 1fr));
        }
    `
)
export const StyledTableTitle = styled.h3(
    ({theme: {typography, colors}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${colors.lightBlack};
    `
)
