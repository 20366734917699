const palette = {
    whiteSmoke: '#F5F5F5',
    bgWhite: '#F7F7F7',

    darkGrey: '#687076',
    borderGrey: '#D7DBDF',
    bgGrey: '#FBFCFD',
    smokeGrey: '#ECEEF0',
    grey: '#CDCDCD',
    inputGrey: '#E4E4E4',
    lightGrey: '#EBEBEB',
    steelGrey: '#7E868C',
    gainsboroGrey: '#DFE3E6',
    mercuryGrey: '#E5E5E5',
    calendarGrey: '#dcdcdc',
    extraLightGrey: '#F0F0F0',
    bgDarkGrey: '#4D4D4D',

    lightBlack: '#11181C',
    transparentBlack: '#0000001A',

    airbnbDarkPink: '#D31E66',
    lightAirbnbDarkPink: '#E0BCCA',
    airbnbRed: '#F23A66',
    lightPink: '#FFF0F0',

    violet: '#CD1D8D',
    borderViolet: '#ECADD4',
    bgViolet: '#FFFCFE',

    cyan: '#18A6F5',

    blue: '#006ADC',
    borderBlue: '#96C7F2',
    darkBlue: '#3451B2',
    borderDarkBlue: '#AEC0F5',
    bgDarkBlue: '#FDFDFE',
    lightBlue: '#E2EEFF',
    bgBlue: '#FBFDFF',

    purple: '#793AAF',
    borderPurple: '#D3B4ED',
    bgPurple: '#FEFCFE',

    yellow: '#FFA318',
    bgYellow: '#F9E9D3',
    darkYellow: '#946800',
    ratingYellow: '#F9BB06',

    orange: '#AD5700',
    borderOrange: '#F3BA63',
    bgOrange: '#FEFDFB',
    ratingOrange: '#F96B04',

    turquoiseGreen: '#0C7792',
    borderTurquoiseGreen: '#84CDDA',
    bgTurquoiseGreen: '#FAFDFE',

    alphaRed: '#FA5E5E66',
    darkRed: '#CD2B31',
    darkRedRgba: 'rgba(211, 30, 102, 0.5)',
    red: '#FA5E5E',
    lightRed: '#FAC3C3',
    borderRed: '#F3AEAF',
    bgRed: '#FFFCFC',
    ratingRed: '#F93533',

    green: '#00BC55',
    lightGreen: '#BBE3BA',
    borderGreen: '#92CEAC',
    bgGreen: '#FBFEFC',
    darkGreen: '#18794E',
    borderDarkGreen: '#92CEAC',
    bgDarkGreen: '#FBFEFC',
    ratingLightGreen: '#C4D718',
    ratingGreen: '#68C239'
} as const

const gradients = {
    orangeGradient: 'linear-gradient(90deg, rgba(229, 72, 77, 0.1) 0%, rgba(224, 49, 119, 0.1) 100%)'
} as const

export const colors = {
    ...palette,
    ...gradients
} as const

export const statusColors = {
    GENERIC: {
        text: colors.darkGrey,
        borderColor: colors.borderGrey,
        background: colors.bgGrey
    },
    COMPLETED: {
        text: colors.darkGreen,
        border: colors.borderGreen,
        background: colors.bgGreen
    },
    TO_BE_PAID: {
        text: colors.purple,
        border: colors.borderPurple,
        background: colors.bgPurple
    },
    PENDING_PAYMENT: {
        text: colors.turquoiseGreen,
        border: colors.borderTurquoiseGreen,
        background: colors.bgTurquoiseGreen
    },
    ASSETS_REJECTED: {
        text: colors.darkRed,
        border: colors.borderRed,
        background: colors.bgRed
    },
    ASSETS_REVIEW: {
        text: colors.darkBlue,
        border: colors.borderDarkBlue,
        background: colors.bgDarkBlue
    },
    PENDING_ASSETS: {
        text: colors.orange,
        border: colors.borderOrange,
        background: colors.bgOrange
    },
    ASSIGNMENT_SCHEDULED: {
        text: colors.darkGreen,
        border: colors.borderDarkGreen,
        background: colors.bgDarkGreen
    },
    PENDING_DATE: {
        text: colors.blue,
        border: colors.borderBlue,
        background: colors.bgBlue
    },
    DECLINED: {
        text: colors.darkGrey,
        border: colors.borderGrey,
        background: colors.bgGrey
    },
    OVERDUE: {
        text: colors.darkGrey,
        border: colors.borderGrey,
        background: colors.bgGrey
    },
    CANCELED_BY_HOST: {
        text: colors.purple,
        border: colors.borderPurple,
        background: colors.bgPurple
    },
    CANCELED_BY_ADMIN: {
        text: colors.purple,
        border: colors.borderPurple,
        background: colors.bgPurple
    },
    CANCELED_BY_CREATOR: {
        text: colors.purple,
        border: colors.borderPurple,
        background: colors.bgPurple
    },
    NEW: {
        text: colors.blue,
        border: colors.borderBlue,
        background: colors.bgBlue
    }
} as const
