import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

interface ChipProps {
    $color: string
    $background: string
    $borderColor: string
    $borderStyle: CSSProperties['borderStyle']
    $size: 'small' | 'medium' | 'big'
    $rounded: boolean
    $isStrokeIcon: boolean
    $isPathFilledIcon: boolean
    $iconPosition: 'left' | 'right'
    $textUppercase: boolean
    $isClickable: boolean
    $fullWidth: boolean
    $disabled: boolean
}

export const StyledChip = styled.div<ChipProps>(
    ({
        theme: {spacing, colors, transition},
        $color,
        $background,
        $borderColor,
        $borderStyle,
        $size,
        $rounded,
        $isStrokeIcon,
        $isPathFilledIcon,
        $iconPosition,
        $textUppercase,
        $isClickable,
        $fullWidth,
        $disabled
    }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing}px;
        flex-flow: ${$iconPosition == 'left' ? 'row' : 'row-reverse'};
        height: 26px;
        width: ${$fullWidth ? '100%' : 'max-content'};
        max-width: 100%;
        padding: 4px 12px;
        border-radius: ${$rounded ? 25 : 4}px;
        background: ${$background in colors ? colors[$background as keyof typeof colors] : $background};
        border: 1px ${$borderStyle}
            ${$borderColor in colors ? colors[$borderColor as keyof typeof colors] : $borderColor};
        ${$disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed !important;
        `};

        ${$isClickable &&
        !$disabled &&
        css`
            ${transition};
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        `};

        & svg {
            color: ${$color in colors ? colors[$color as keyof typeof colors] : $color};
            height: ${$size == 'small' ? 16 : $size == 'big' ? 24 : 16}px;

            ${!$isStrokeIcon &&
            css`
                fill: ${$color in colors ? colors[$color as keyof typeof colors] : $color};
            `};

            & path {
                ${$isStrokeIcon &&
                css`
                    stroke: ${$color in colors ? colors[$color as keyof typeof colors] : $color};
                `};

                ${$isPathFilledIcon &&
                css`
                    fill: ${$color in colors ? colors[$color as keyof typeof colors] : $color};
                `};
            }
        }

        & span {
            font-size: ${$size == 'small' ? 12 : 13}px;
            font-weight: 500;
            text-transform: ${$textUppercase ? 'uppercase' : 'inherit'};
            color: ${$color in colors ? colors[$color as keyof typeof colors] : $color};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)
