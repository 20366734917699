import {AlertIcon} from '../../../../../assets/icons/AlertIcon'
import styled, {css} from 'styled-components'

export const StyledAlertIconReset = styled(AlertIcon)`
    fill: none !important;
`
export const StyledEligibleRow = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        font-weight: 700;
        text-transform: uppercase;
        & svg {
            fill: ${palette.neutral.black};
            height: 10px;
        }
    `}
`
