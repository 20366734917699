import styled, {css} from 'styled-components'
import {SelectItem} from 'src/components/select-atoms/SelectAtoms.tsx'

export const StyledSelectItem = styled(SelectItem)(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2.5}px;
    `
)
