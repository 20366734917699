import {FC, HTMLAttributes} from 'react'

export const StatusIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 16, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <g clipPath="url(#clip0_1969_6771)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.08118 0.936637C5.98653 0.561204 6.97846 0.354492 8.01615 0.354492C9.05385 0.354492 10.0458 0.561204 10.9511 0.936636C11.4613 1.14819 11.7034 1.73326 11.4918 2.24342C11.2802 2.75358 10.6952 2.99564 10.185 2.78409C9.51826 2.5076 8.78635 2.35449 8.01615 2.35449C7.24596 2.35449 6.51405 2.5076 5.84729 2.78409C5.33713 2.99564 4.75206 2.75358 4.54051 2.24342C4.32895 1.73326 4.57102 1.14819 5.08118 0.936637ZM13.7939 4.54551C14.3041 4.33396 14.8891 4.57603 15.1007 5.08619C15.4761 5.99153 15.6828 6.98347 15.6828 8.02116C15.6828 9.05885 15.4761 10.0508 15.1007 10.9561C14.8891 11.4663 14.3041 11.7084 13.7939 11.4968C13.2837 11.2852 13.0417 10.7002 13.2532 10.19C13.5297 9.52327 13.6828 8.79135 13.6828 8.02116C13.6828 7.25096 13.5297 6.51905 13.2532 5.85229C13.0417 5.34213 13.2837 4.75707 13.7939 4.54551ZM2.23841 4.54552C2.74857 4.75707 2.99064 5.34214 2.77908 5.8523C2.50259 6.51905 2.34949 7.25096 2.34949 8.02116C2.34949 8.79135 2.50259 9.52327 2.77908 10.19C2.99064 10.7002 2.74857 11.2852 2.23841 11.4968C1.72825 11.7084 1.14319 11.4663 0.931632 10.9561C0.556199 10.0508 0.349487 9.05885 0.349487 8.02116C0.349487 6.98347 0.556199 5.99153 0.931631 5.08619C1.14319 4.57603 1.72825 4.33396 2.23841 4.54552ZM11.4918 13.7989C11.7034 14.3091 11.4613 14.8941 10.9511 15.1057C10.0458 15.4811 9.05385 15.6878 8.01615 15.6878C6.97846 15.6878 5.98653 15.4811 5.08118 15.1057C4.57102 14.8941 4.32896 14.3091 4.54051 13.7989C4.75207 13.2887 5.33713 13.0467 5.84729 13.2582C6.51405 13.5347 7.24596 13.6878 8.01615 13.6878C8.78635 13.6878 9.51826 13.5347 10.185 13.2582C10.6952 13.0467 11.2802 13.2887 11.4918 13.7989Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1969_6771">
                <rect width={size} height={size} fill="white" />
            </clipPath>
        </defs>
    </svg>
)
