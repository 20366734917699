import {FC} from 'react'
import {StyledAssignmentContentWrapper} from 'src/features/assignment/components/assignment-content/style'
import {Assignment} from 'src/features/assignment/types'
import {AssignmentHomesCards} from 'src/features/assignment/components/assingment-homes-cards/AssignmentHomesCards'
import {AssignmentWalkthroughCards} from 'src/features/assignment/components/assignment-walkthrough-cards/AssignmentWalkthroughCards'

export const AssignmentContent: FC<{assignment: Assignment}> = ({assignment}) => {
    return (
        <StyledAssignmentContentWrapper>
            {assignment.task_media.name == 'walkthrough' ? (
                <AssignmentWalkthroughCards assignment={assignment} />
            ) : (
                <AssignmentHomesCards assignment={assignment} />
            )}
        </StyledAssignmentContentWrapper>
    )
}
