import {FC, HTMLAttributes} from 'react'

export const CircleWarningIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 20, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00004 2.33337C4.87043 2.33337 2.33337 4.87043 2.33337 8.00004C2.33337 11.1297 4.87043 13.6667 8.00004 13.6667C11.1297 13.6667 13.6667 11.1297 13.6667 8.00004C13.6667 4.87043 11.1297 2.33337 8.00004 2.33337ZM0.333374 8.00004C0.333374 3.76586 3.76586 0.333374 8.00004 0.333374C12.2342 0.333374 15.6667 3.76586 15.6667 8.00004C15.6667 12.2342 12.2342 15.6667 8.00004 15.6667C3.76586 15.6667 0.333374 12.2342 0.333374 8.00004ZM8.00004 4.33337C8.55233 4.33337 9.00004 4.78109 9.00004 5.33337V8.00004C9.00004 8.55233 8.55233 9.00004 8.00004 9.00004C7.44776 9.00004 7.00004 8.55233 7.00004 8.00004V5.33337C7.00004 4.78109 7.44776 4.33337 8.00004 4.33337ZM8.00004 9.66671C7.44776 9.66671 7.00004 10.1144 7.00004 10.6667C7.00004 11.219 7.44776 11.6667 8.00004 11.6667H8.00671C8.55899 11.6667 9.00671 11.219 9.00671 10.6667C9.00671 10.1144 8.55899 9.66671 8.00671 9.66671H8.00004Z"
            fill="currentColor"
        />
    </svg>
)
