import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

export const StyledPublicHeader = styled.header`
    ${({theme: {colors, palette}}) => css`
        height: 64px;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${colors.inputGrey};
    `}
`
export const StyledLogo = styled(Link)(
    ({theme: {typography, colors}}) => css`
        cursor: pointer;
        ${typography.textLg};
        font-weight: 800;
        color: ${colors.lightBlack};
        text-decoration: none;

        &:hover {
            color: ${colors.lightBlack};
        }
    `
)

export const StyledActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
`

export const StyledFeedbackLink = styled.a`
    ${({theme: {spacing, typography, colors, mediaQuery}}) => css`
        ${typography.textSm};
        font-weight: 500;
        text-decoration: none;
        color: ${colors.lightBlack};
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;

        &:hover {
            color: ${colors.lightBlack};
        }

        ${mediaQuery.SM} {
            & svg {
                width: 20px;
                height: 20px;
            }
            & span {
                display: none;
            }
        }
    `}
`

export const ChangeLanguageWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 4}px;
    `}
`

export const StyledChangeLanguage = styled.div`
    ${({theme: {spacing, colors, typography}}) => css`
        display: flex;
        color: ${colors.lightBlack};
        gap: ${spacing * 2}px;
        ${typography.textSm};
        font-weight: 500;
        cursor: pointer;
    `}
`
