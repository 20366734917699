import {
    StyledBackButton,
    StyledButtons,
    StyledButtonsRow,
    StyledCopyIcon,
    StyledData,
    StyledEligible,
    StyledSubheader
} from './style'
import {copyTextToClipboard, openLinkInNewTab, renderTableMatterportStatus} from '../../../../../helpers/utils'
import {useTranslation} from 'react-i18next'
import {Chip} from '../../../../../components/chip/Chip'
import {useDispatch, useSelector} from 'react-redux'
import {taskSelector} from '../../../../../store/matterport'
import {Button} from '../../../../../components/button/Button'
import {SendBackModal} from '../../task/review-view/images-list/send-back-modal/SendBackModal'
import {useCallback, useState} from 'react'
import {CancelIcon, CheckIcon} from '../../../../../theme/icons'
import {showAlert} from '../../../../../store/appGenerics'
import {ALERT_LEVELS, ROUTES} from '../../../../../helpers/constants'
import {useNavigate} from 'react-router'
import {ConfirmModal} from '../confirm-modal/ConfirmModal'
import PropTypes from 'prop-types'
import {useTheme} from 'styled-components'

export const Subheader = ({showConfirmView}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const navigate = useNavigate()
    const task = useSelector(taskSelector)
    const dispatch = useDispatch()
    const [showSendBackModal, setShowSendBackModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const status = renderTableMatterportStatus(task?.task_matterport?.status)

    const setShowSendBackModalCb = useCallback(show => {
        setShowSendBackModal(show)
    }, [])

    const setShowConfirmModalCb = useCallback(show => {
        setShowConfirmModal(show)
    }, [])

    const onCopyLink = async text => {
        await copyTextToClipboard(text)
        dispatch(
            showAlert({
                message: t('admin:link_copied'),
                level: ALERT_LEVELS.SUCCESS
            })
        )
    }

    return (
        <StyledSubheader>
            {showSendBackModal && <SendBackModal setShowSendBackModalCb={setShowSendBackModalCb} />}
            {showConfirmModal && <ConfirmModal setShowConfirmModalCb={setShowConfirmModalCb} />}

            <StyledBackButton
                onClick={() =>
                    navigate(ROUTES.ADMIN_MATTERPORT, {
                        state: task?.task_matterport?.status
                    })
                }
            />

            <div className="container">
                <div className="row">
                    <StyledData className="col-7">
                        <div>
                            <label>{t('admin:listing_title')}</label>
                            <span>{task?.title || '-'}</span>
                        </div>
                        <div>
                            <label>
                                {t('admin:listing_id')}
                                {':'}
                            </label>
                            <span>{task?.external_id || '-'}</span>
                        </div>
                        <div>
                            <label>
                                {t('commons:status')}
                                {':'}
                            </label>
                            <span>
                                <Chip
                                    rounded
                                    textUppercase
                                    borderColor={status.color}
                                    background={status.color}
                                    color="white"
                                    text={t(status.label)}
                                />
                            </span>
                        </div>
                        <div>
                            <label>
                                {t('admin:link')}
                                {':'}
                            </label>
                            {task?.task_matterport?.link_matterport.split(',').map(link => (
                                <span>
                                    <a href={link} title={link} target={'_blank'} rel={'noreferrer'}>
                                        {link ?? '-'}
                                    </a>
                                    <StyledCopyIcon
                                        fill={theme.palette.neutral.black}
                                        onClick={() => onCopyLink(task?.task_matterport?.link_matterport ?? '')}
                                    />
                                </span>
                            ))}
                        </div>
                        <StyledEligible>
                            <label>
                                {t('admin:eligible_for_accessibility')}
                                {':'}
                            </label>
                            <span>
                                {task?.entity?.exclude_for_category ? (
                                    <>
                                        {t('commons:no')} <CancelIcon />
                                    </>
                                ) : (
                                    <>
                                        {t('commons:yes')} <CheckIcon />
                                    </>
                                )}
                            </span>
                        </StyledEligible>
                    </StyledData>
                    <StyledButtons className="col-5">
                        <StyledButtonsRow>
                            <Button onClick={() => openLinkInNewTab(task?.pdp_link)} size="sm" variant="secondary">
                                {'PDP'}
                            </Button>
                            {!showConfirmView && (
                                <Button
                                    onClick={() => openLinkInNewTab(task?.daryl_link)}
                                    size="sm"
                                    variant="secondary"
                                >
                                    {'DARYL'}
                                </Button>
                            )}
                        </StyledButtonsRow>
                        <StyledButtonsRow>
                            <Button onClick={() => setShowSendBackModalCb(true)} variant="secondary">
                                {t('admin:sendback_to_matterport')}
                            </Button>
                            <Button
                                disabled={!task?.accessibility_images?.length}
                                onClick={() => setShowConfirmModalCb(true)}
                                variant="primaryBlack"
                            >
                                {t('admin:confirm')}
                            </Button>
                        </StyledButtonsRow>
                    </StyledButtons>
                </div>
            </div>
        </StyledSubheader>
    )
}

Subheader.propTypes = {
    showConfirmView: PropTypes.bool
}
