import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_ACTION_KEYS} from 'src/queryClient.ts'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'
import {useAssignmentsCount} from 'src/features/assignments/services/useAssignmentsCount.ts'
import {useAssignmentsRequests} from 'src/hooks/useAssignmentsRequests.ts'
import {httpDeclineAssignment, HttpDeclineAssignmentOptions} from 'src/features/assignment/services/assignment.http.ts'
import {Assignment} from 'src/features/assignment/types.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'

export const useDeclineAssignment = (assignment: Assignment, options?: {onSuccess?: () => void}) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const assignmentsCountQuery = useAssignmentsCount()
    const {newShootStatusToggle} = useAssignmentsRequests()

    return useMutation<Assignment, unknown, HttpDeclineAssignmentOptions['payload']>({
        mutationKey: [MUTATION_KEYS.DECLINE_ASSIGNMENT],
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpDeclineAssignment({
                    payload,
                    urlParams: {assignmentId: assignment.id},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onSuccess: data => {
            newShootStatusToggle(data)
            invalidationSignal(QUERY_ACTION_KEYS.ASSIGNMENT_DECLINED)
            assignmentsCountQuery.updateShootsCount(
                assignmentsCountQuery.data
                    ? {
                          ...assignmentsCountQuery.data,
                          assignments_request: assignmentsCountQuery.data.assignments_request - 1
                      }
                    : {
                          need_more_assets: 0,
                          pending_assets: 0,
                          assignments_request: 0,
                          assignments_current: 0,
                          pending_date: 0
                      }
            )
            options?.onSuccess?.()
        },
        onError: errorHandler
    })
}
