import {CSSProperties, forwardRef, HTMLAttributes} from 'react'
import {
    StyledTextarea,
    StyledTextareaContainer,
    StyledLabel,
    StyledBottomContainer,
    StyledError,
    StyledHint,
    StyledCounter
} from './style'
import {Flexbox} from '../flexbox/Flexbox'

interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
    name?: string
    className?: string
    textAreaClassName?: string
    label?: string
    rootProps?: HTMLAttributes<HTMLLabelElement>
    inputProps?: HTMLAttributes<HTMLTextAreaElement>
    count?: number
    maxLength?: number
    showCount?: boolean
    hint?: string
    errors?: {message: string}
    justifyHelpers?: CSSProperties['justifyContent']
}

/**
 * @deprecated use the new Textarea component instead
 * */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    (
        {
            children,
            className,
            textAreaClassName,
            label,
            errors,
            rootProps,
            inputProps,
            count,
            showCount = false,
            justifyHelpers = 'flex-start',
            hint = null,
            maxLength,
            ...props
        },
        ref
    ) => {
        return (
            <StyledTextareaContainer className={className} {...rootProps}>
                {!!label && <StyledLabel>{label}</StyledLabel>}
                <StyledTextarea
                    className={textAreaClassName}
                    ref={ref}
                    {...inputProps}
                    {...props}
                    maxLength={maxLength}
                    $errors={!!errors}
                >
                    {children}
                </StyledTextarea>
                <StyledBottomContainer>
                    <Flexbox gap={4} justify={justifyHelpers}>
                        {hint && <StyledHint>{hint}</StyledHint>}
                        {showCount && (
                            <StyledCounter
                                $error={count != null && maxLength != null && count > maxLength}
                            >{`${count}/${maxLength}`}</StyledCounter>
                        )}
                    </Flexbox>

                    {!!errors && <StyledError>{errors.message}</StyledError>}
                </StyledBottomContainer>
            </StyledTextareaContainer>
        )
    }
)

Textarea.displayName = 'Textarea'
