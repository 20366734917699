import styled, {css} from 'styled-components'
import {Button} from '../../../../../components/button/Button'

export const StyledFileDropzone = styled.section`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        width: 752px;
    `}
`

export const StyledProcessButton = styled(Button)`
    align-self: flex-end;
`
export const StyledRemoveFileButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        align-self: flex-end;
        margin-left: ${spacing}px;
        height: unset;
    `}
`

export const FileDropzoneWrapper = styled.div`
    ${({theme: {colors, palette}, isDropzoneActive}) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 172px;
        border: ${isDropzoneActive ? `2px solid ${colors.airbnbRed}` : `1px solid ${colors.borderGrey}`};
        border-radius: 12px;
        background: ${palette.neutral.white};
        text-align: center;
    `}
`

export const EmptyFileDropzoneWrapper = styled.div`
    ${({isDisabled, isProcessing}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: ${isDisabled ? 'not-allowed' : isProcessing ? 'auto' : 'pointer'};
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {colors, typography, spacing}}) => css`
        margin-top: ${spacing * 3}px;
        ${typography.textSm};
        & > strong {
            font-weight: 600;
            ${typography.textSm};
            color: ${colors.airbnbRed};
        }
        & > p {
            font-weight: 400;
            ${typography.textXs};
        }
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `}
`
