import {ModalBody, ModalParagraph} from 'src/components/modal-atoms/ModalAtoms'
import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalUploadModalBody = styled(ModalBody)(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 14}px;
    `
)

export const StyledAdminHostApprovalUploadModalParagraph = styled(ModalParagraph)(
    ({theme: {typography}}) => css`
        ${typography.textMd};
    `
)
