import {Flexbox} from 'src/components/flexbox/Flexbox'
import {Container} from 'src/components/container/Container.tsx'
import styled, {css} from 'styled-components'

export const StyledAssignmentContentWrapper = styled(Container)(
    ({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 12}px ${spacing * 8}px;
        height: fit-content;

        ${mediaQueries.m} {
            padding: ${spacing * 8}px 0;
        }
    `
)

export const StyledAssignmentContent = styled.div(
    ({theme: {mediaQueries, spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${spacing * 6}px;

        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)

export const StyledAssignmentScheduledOverview = styled(Flexbox)`
    width: 100%;
`
