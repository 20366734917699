import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {errorHandler, openLinkInNewTab} from '../../../helpers/utils'
import {PageHelmet} from '../../../components/page-helmet/PageHelmet'
import {httpGetUserImpersonate} from '../../../http-requests/admin/users.http'
import {debounce} from 'lodash-es'
import {Spinner} from '../../../components/spinner-legacy/Spinner'
import {Table} from '../../../components/table/Table'
import {PartnersWrapper, StyledActions, StyledHeadingPartner} from './style'
import {UserIcon} from '../../../assets/icons/UserIcon'
import {EmailIcon} from '../../../assets/icons/EmailIcon'
import {HashtagIcon} from '../../../assets/icons/HashtagIcon'
import {usePartners} from '../../../hooks/usePartners'
import {EditModal} from './edit-freelancer-modal/EditFreelancerModal'
import {InputText} from '../../../components/input-text/InputText'
import {useUserStore} from '../../../features/user/store'
import {SearchLgIcon} from '../../../components/icon'
import {useTheme} from 'styled-components'
import {InputHelpText} from '../../../components/input-help-text/InputHelpText'
import {Button} from '../../../components/button/Button'
import {Flexbox} from '../../../components/flexbox/Flexbox'

export const AdminFreelancers = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const setUser = useUserStore(store => store.setUser)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [searchError, setSearchError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [editDefaultData, setEditDefaultData] = useState({airbnbId: '', email: '', id: ''})
    const query = usePartners()

    const tableColumns = [
        {
            label: (
                <Flexbox align="center" gap={1}>
                    <HashtagIcon width={16} /> {t('commons:airbnb_id')}
                </Flexbox>
            ),
            width: '15%',
            cellRender: freelancer => <span>{freelancer.airbnb_user_id || '-'}</span>
        },
        {
            sortName: 'name',
            label: (
                <Flexbox align="center" gap={1}>
                    <UserIcon size={16} /> {t('commons:name')}
                </Flexbox>
            ),
            width: '25%',
            cellRender: freelancer => <span>{freelancer.name}</span>
        },
        {
            sortName: 'email',
            label: (
                <Flexbox align="center" gap={1}>
                    <EmailIcon size={16} /> {t('commons:email')}
                </Flexbox>
            ),
            width: '40%',
            cellRender: freelancer => <span>{freelancer.email}</span>
        },
        {
            width: '20%',
            alignment: 'right',
            cellRender: freelancer => (
                <StyledActions>
                    <Button variant="secondary" size="sm" onClick={() => onImpersonate(freelancer.id)}>
                        {t('commons:impersonate')}
                    </Button>
                    <Button variant="tertiary" size="sm" onClick={() => onEditClick(freelancer)}>
                        {t('commons:edit')}
                    </Button>
                </StyledActions>
            )
        }
    ]

    const onSubmit = debounce(e => {
        const value = e?.target?.value
        if (value?.length >= 3) {
            query.setSearch(value)
            setSearchError(null)
        } else if (!value?.length) {
            query.setSearch(null)
            setSearchError(null)
        } else {
            query.setSearch(null)
            setSearchError({message: t('errors:search_invalid_length')})
        }
    }, 750)

    const onImpersonate = async userId => {
        try {
            setIsLoading(true)
            const {data} = await httpGetUserImpersonate(adminAccessToken, userId)
            setUser({user: {}, access_token: data, refresh_token: ''})
            openLinkInNewTab(`${window.location.origin}`)
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const onEditClick = item => {
        setShowModal(true)
        setEditDefaultData({airbnbId: item.airbnb_user_id, email: item.email, id: item.id})
    }

    return (
        <PartnersWrapper>
            <PageHelmet title={t('titles:admin_partners')} />
            <StyledHeadingPartner>
                <h1>{t('admin:partners')}</h1>
                <InputText
                    type="text"
                    typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['400']} />}
                    inputSize="sm"
                    width="280px"
                    placeholder={t('admin:search_by_name_email')}
                    onChange={onSubmit}
                    autoComplete="off"
                />
                {!!searchError?.message && <InputHelpText style={{position: 'absolute'}} error={searchError.message} />}
            </StyledHeadingPartner>
            <Table
                columns={tableColumns}
                data={query.remappedData}
                isError={query.isError}
                isChangingPage={query.isFetchingNextPage}
                isLoading={query.isLoading}
                onChangePage={query.onChangePage}
                searchValue={query.search}
                sorter={{
                    orderBy: query.sorter.order_by,
                    orderDirection: query.sorter.order_direction,
                    onSort: ({orderBy, orderDirection}) => query.onSortColumn(orderBy, orderDirection)
                }}
            />
            {isLoading && <Spinner />}
            {showModal && <EditModal defaultValues={editDefaultData} onClose={() => setShowModal(false)} />}
        </PartnersWrapper>
    )
}

AdminFreelancers.displayName = 'AdminFreelancers'
