import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {HttpUpdateHostApprovalTaskStatusOptions, httpUpdateHostApprovalTaskStatus} from './adminHostApproval.http'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers'
import {useUserStore} from 'src/features/user/store'
import {MUTATION_KEYS, QUERY_ACTION_KEYS, QUERY_KEYS, queryClient} from 'src/queryClient'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'

export const useUpdateHostApprovalTaskStatus = ({
    taskId,
    options
}: {
    taskId: number
    options?: UseMutationOptions<unknown, unknown, HttpUpdateHostApprovalTaskStatusOptions['payload']>
}) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is null')
    return useMutation({
        mutationKey: [MUTATION_KEYS.HOST_APPROVAL_SEND_TASK_TO_APPROVAL],
        mutationFn: (payload: HttpUpdateHostApprovalTaskStatusOptions['payload']) =>
            httpUpdateHostApprovalTaskStatus({
                urlParams: {taskId},
                payload,
                params: {
                    response_type: 'extended'
                },
                headers: makeHttpAuthorizationHeader(adminAccessToken)
            }),
        ...options,
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK, taskId]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_STATUSES_COUNTER]})
            invalidationSignal(
                variables.status == 'pending_host_approval'
                    ? QUERY_ACTION_KEYS.HOST_APPROVAL_TASK_TO_PENDING_HOST_APPROVAL
                    : variables.status == 'ready_for_review'
                    ? QUERY_ACTION_KEYS.HOST_APPROVAL_TASK_TO_READY_FOR_REVIEW
                    : QUERY_ACTION_KEYS.HOST_APPROVAL_TASK_TO_COMPLETED
            )
            options?.onSuccess?.(data, variables, context)
        }
    })
}
