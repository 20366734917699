import {z} from 'zod'

export const AdminHost = z.object({
    external_host_id: z.string().min(1).nullable(),
    first_name: z.string().min(1).nullable(),
    email: z.string().email().nullable(),
    last_login: z.string().nullable(),
    code: z.string().nullable(),
    password: z.string().nullable()
})

export type ImpersonateServicePath = 'photoshoot-requests' | 'co-alarm' | 'categories'
export type ElevateEnvironments = 'development' | 'staging' | 'production'
