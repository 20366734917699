import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

interface EventProps {
    $textColor: CSSProperties['color']
    $borderStyle: CSSProperties['borderStyle']
    $borderColor: CSSProperties['borderColor']
    $background: CSSProperties['background']
}

export const StyledEvent = styled.div<EventProps>`
    ${({theme: {spacing, typography}, $textColor, $borderStyle, $borderColor, $background}) => css`
        ${typography.textSm};
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: ${spacing * 1.5}px ${spacing * 2}px; // important:  change padding cause problem with overflow of events
        gap: ${spacing}px;
        background: ${$background};
        border: 1px ${$borderStyle} ${$borderColor};
        border-radius: 6px;
        color: ${$textColor};
        > svg {
            max-width: 16px;
            width: 100%;
            color: ${$textColor};
        }
    `}
`

export const StyledEventTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXs};
        color: ${palette.neutral.black};
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`

export const StyledPopupEvent = styled.div<EventProps>`
    ${({theme: {typography, spacing}, $textColor, $borderStyle, $borderColor, $background}) => css`
        ${typography.textSm};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        padding: ${spacing * 2}px ${spacing * 3}px;
        gap: ${spacing}px;
        background: ${$background};
        margin-bottom: ${spacing * 2}px;
        border: 1px ${$borderStyle} ${$borderColor};
        color: ${$textColor};
        > svg {
            max-width: 16px;
            width: 100%;
            margin-right: ${spacing * 2}px;
            margin-left: ${spacing}px;
            color: ${$textColor};
        }
    `}
`

export const StyledPopupEventIcon = styled.div`
    ${({theme: {spacing, colors}}) => css`
        position: absolute;
        width: 20px;
        height: 20px;
        right: ${spacing * 2}px;
        & svg path {
            fill: ${colors.darkGrey};
        }
    `}
`

export const StyledPopupEventTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral.black};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`

export const StyledPopupEventDates = styled.p`
    ${({theme: {typography, colors}}) => css`
        ${typography.textXs};
        color: ${colors.darkGrey};
    `}
`

export const StyledPopupEventLabel = styled.p<{$textColor: CSSProperties['color']}>`
    ${({theme: {spacing, typography}, $textColor}) => css`
        display: flex;
        align-items: center;
        ${typography.textXs};
        font-weight: 500;
        margin-top: ${spacing}px;
        gap: ${spacing * 2}px;
        padding-left: ${spacing}px;
        color: ${$textColor};
    `}
`
