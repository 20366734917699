import {useUserStore} from 'src/features/user/store.ts'
import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {httpGetAccessibilityProjects} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'
import {captureException} from '@sentry/react'
import {AccessibilityProject} from 'src/features/admin-host-approval/types.ts'

export const useAccessibilityProjects = () => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useQuery({
        queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_PROJECTS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAccessibilityProjects({
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: AccessibilityProject.array(),
                onZodError: captureException
            })
    })
}
