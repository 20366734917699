import useKey from 'use-key-hook'
import PropTypes from 'prop-types'
import {CloseIcon} from '../../theme/icons.tsx'
import {useTranslation} from 'react-i18next'
import {
    StyledPopover,
    StyledOverlay,
    StyledModal,
    StyledBodyWrapper,
    StyledFooterWrapper,
    StyledActions,
    StyledDivisor,
    StyledTitleSection
} from './style.js'
import {Button} from '../button/Button'

export const AdminPopover = ({
    children,
    title,
    subtitle,
    onClose = Function.prototype,
    width = 600,
    withDivisor = false,
    buttonLabel,
    titleSpacing = true,
    hasActions = true,
    newStyleGuide = false,
    hasCancelButton = true,
    borderRadius = 24,
    overflow = false,
    callback = Function.prototype,
    hasCloseButton = true,
    disableActions
}) => {
    const {t} = useTranslation()

    useKey(onClose, {detectKeys: [27]})

    return (
        <StyledPopover>
            <StyledOverlay onClick={onClose} />
            <StyledModal width={width} borderRadius={borderRadius} overflow={overflow}>
                {hasCloseButton && <CloseIcon onClick={onClose} />}
                <StyledBodyWrapper>
                    {title && (
                        <StyledTitleSection titleSpacing={titleSpacing}>
                            <h2>{title}</h2>
                            {subtitle && <h4>{subtitle}</h4>}
                        </StyledTitleSection>
                    )}

                    <div>{children}</div>
                </StyledBodyWrapper>
                {withDivisor && <StyledDivisor />}
                {hasActions && (
                    <StyledFooterWrapper>
                        <StyledActions
                            newStyleGuide={newStyleGuide}
                            hasCancelButton={hasCancelButton}
                            withDivisor={withDivisor}
                        >
                            {hasCancelButton && (
                                <Button onClick={onClose} variant="tertiary">
                                    {t('commons:cancel')}
                                </Button>
                            )}
                            <Button
                                onClick={callback}
                                variant="primaryBlack"
                                fullWidth={!hasCancelButton}
                                disabled={disableActions}
                            >
                                {buttonLabel}
                            </Button>
                        </StyledActions>
                    </StyledFooterWrapper>
                )}
            </StyledModal>
        </StyledPopover>
    )
}

AdminPopover.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onClose: PropTypes.func,
    buttonLabel: PropTypes.string,
    callback: PropTypes.func,
    withDivisor: PropTypes.bool, //todo: remove it after redesign
    titleSpacing: PropTypes.bool, //todo:  remove it after redesign
    borderRadius: PropTypes.number,
    overflow: PropTypes.bool,
    hasCloseButton: PropTypes.bool,
    disableActions: PropTypes.bool,
    width: PropTypes.number,
    hasActions: PropTypes.bool,
    newStyleGuide: PropTypes.bool,
    hasCancelButton: PropTypes.bool
}
