import {useTranslation} from 'react-i18next'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {ChevronLeftIcon} from 'src/components/icon'
import {ButtonLink} from 'src/components/button-link/ButtonLink'
import {AdminHostApprovalTask} from 'src/features/admin-host-approval/types'
import {AdminHostApprovalTaskHeading} from 'src/features/admin-host-approval/components/admin-host-approval-task-heading/AdminHostApprovalTaskHeading'
import {AdminHostApprovalTaskMetadata} from 'src/features/admin-host-approval/components/admin-host-approval-task-metadata/AdminHostApprovalTaskMetadata'
import {StyledAdminHostApprovalTaskHeroWrapper} from './style'
import {Container} from 'src/components/container/Container'
import {ROUTES} from 'src/helpers/constants'
import {useLocation} from 'react-router'

export const AdminHostApprovalTaskHero = ({adminHostApprovalTask}: {adminHostApprovalTask: AdminHostApprovalTask}) => {
    const {t} = useTranslation()
    const location = useLocation()

    return (
        <Container fullHeight={false}>
            <StyledAdminHostApprovalTaskHeroWrapper direction="column" gap={6}>
                <ButtonLink to={location?.state?.origin ?? ROUTES.ADMIN_HOST_APPROVAL} variant="tertiary" shape="text">
                    <ChevronLeftIcon />
                    {t('commons:host_approval')}
                </ButtonLink>

                <Flexbox gap={8} direction="column">
                    <AdminHostApprovalTaskHeading adminHostApprovalTask={adminHostApprovalTask} />
                    <AdminHostApprovalTaskMetadata adminHostApprovalTask={adminHostApprovalTask} />
                </Flexbox>
            </StyledAdminHostApprovalTaskHeroWrapper>
        </Container>
    )
}
