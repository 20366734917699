import PropTypes from 'prop-types'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {errorHandler} from '../../../../../../../helpers/utils'
import {Spinner} from '../../../../../../../components/spinner-legacy/Spinner'
import {useNavigate, useParams} from 'react-router'
import {httpSendBackTask} from '../../../../../../../http-requests/admin/matterport.http'
import * as Yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {MATTERPORT_STATUSES, ROUTES} from '../../../../../../../helpers/constants'
import {AdminPopover} from '../../../../../../../components/admin-popover/AdminPopover'
import {NotesTextArea} from './style'
import {useUserStore} from '../../../../../../../features/user/store'

export const SendBackModal = ({setShowSendBackModalCb}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [isLoading, setIsLoading] = useState(false)

    const validationSchema = Yup.object().shape({
        notes: Yup.string().nullable().required(t('errors:required'))
    })

    const {
        register,
        watch,
        formState: {errors}
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        resolver: yupResolver(validationSchema)
    })

    const onClose = useCallback(() => setShowSendBackModalCb(false), [])

    const onSubmit = async () => {
        try {
            setIsLoading(true)
            await httpSendBackTask(adminAccessToken, taskId, watch('notes'))
            navigate(ROUTES.ADMIN_MATTERPORT, {
                state: MATTERPORT_STATUSES.REVISION
            })
            onClose()
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <AdminPopover
            onClose={onClose}
            title={t('admin:send_back_matterport')}
            buttonLabel={t('commons:confirm')}
            callback={onSubmit}
            disableActions={!watch('notes')}
        >
            <div className="position-relative">
                <NotesTextArea
                    name="notes"
                    inputProps={register('notes')}
                    errors={errors.notes}
                    label={t('admin:provide_details')}
                />
                {isLoading && <Spinner overlayFullscreen />}
            </div>
        </AdminPopover>
    )
}

SendBackModal.propTypes = {
    setShowSendBackModalCb: PropTypes.func
}
