import {useCallback, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useMatch} from 'react-router'
import {useTranslation} from 'react-i18next'
import {Spinner} from '../spinner/Spinner'
import {ROUTES} from '../../helpers/constants'
import {httpAdminLogout} from 'src/http-requests/admin/auth.http'
import {errorHandler, raise} from 'src/helpers/helpers.ts'
import {
    AdminLinksWrapper,
    StyledLogo,
    HeaderWrapper,
    StyledAdminHeader,
    RightLinksWrapper,
    LeftLinksWrapper
} from './style'
import {appLanguages} from '../../translations/i18n'
import {useUserStore} from '../../features/user/store'
import {ButtonNavLink} from '../button-nav-link/ButtonNavLink'
import {Button} from '../button/Button'
import {Globe01Icon, LogOut01Icon} from '../icon'
// @ts-expect-error [TS7016] "We don't have types yet"
import {SelectMenuModal} from '../select-menu-modal/SelectMenuModal'

export const AdminHeader = () => {
    const {
        t,
        i18n: {language}
    } = useTranslation()
    const location = useLocation()
    const pathname = location.pathname
    const resetUser = useUserStore(store => store.resetUser)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const currentLanguageObj = appLanguages.find(
        e => e.value === language || e.value === localStorage.getItem('i18nextLng')
    )

    const menuLinks = [
        {
            text: t('admin:assignments:title'),
            path: ROUTES.ADMIN_ASSIGNMENTS,
            activeCondition: useMatch(ROUTES.ADMIN) || pathname.includes(ROUTES.ADMIN_ASSIGNMENTS)
        },
        {
            text: t('admin:partners'),
            path: ROUTES.ADMIN_PARTNERS,
            activeCondition: pathname.includes(ROUTES.ADMIN_PARTNERS)
        },
        {
            text: t('admin_hosts:title'),
            path: ROUTES.ADMIN_HOSTS,
            activeCondition: pathname.includes(ROUTES.ADMIN_HOSTS)
        },
        {
            text: t('admin:payments:title'),
            path: ROUTES.ADMIN_PAYMENTS_GENERATE,
            activeCondition:
                pathname.includes(ROUTES.ADMIN_PAYMENTS_PROCESS) || pathname.includes(ROUTES.ADMIN_PAYMENTS_GENERATE)
        },
        {
            text: t('admin:matterport'),
            path: ROUTES.ADMIN_MATTERPORT,
            activeCondition: pathname.includes(ROUTES.ADMIN_MATTERPORT)
        },
        {
            text: t('commons:host_approval'),
            path: ROUTES.ADMIN_HOST_APPROVAL,
            activeCondition: pathname.includes(ROUTES.ADMIN_HOST_APPROVAL)
        }
    ]

    const onAdminLogout = useCallback(async () => {
        try {
            setIsLoading(true)
            await httpAdminLogout(adminAccessToken ?? raise('adminAccessToken is nullish'))
            resetUser('admin')
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }, [adminAccessToken])

    return (
        <>
            <StyledAdminHeader>
                <HeaderWrapper fullHeight>
                    <LeftLinksWrapper>
                        <StyledLogo to={ROUTES.ADMIN}>{'Mediabox'}</StyledLogo>
                        <AdminLinksWrapper>
                            {menuLinks.map((link, index) => (
                                <ButtonNavLink
                                    size="sm"
                                    variant="ghost"
                                    to={link.path}
                                    className={link.activeCondition ? 'active' : undefined}
                                    key={index}
                                >
                                    {link.text}
                                </ButtonNavLink>
                            ))}
                        </AdminLinksWrapper>
                    </LeftLinksWrapper>
                    <RightLinksWrapper>
                        <Button size="sm" variant="ghost" onClick={() => setShowModal(true)}>
                            <Globe01Icon />
                            <span>{currentLanguageObj?.label}</span>
                        </Button>
                        <Button size="sm" variant="ghost" onClick={onAdminLogout} disabled={isLoading}>
                            <LogOut01Icon />
                            {t('menu:logout')}
                            {isLoading && <Spinner size={16} />}
                        </Button>
                    </RightLinksWrapper>
                </HeaderWrapper>
            </StyledAdminHeader>
            {showModal && <SelectMenuModal onClose={() => setShowModal(false)} />}
        </>
    )
}
