import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
//translations
import en from './en.json'
import it from './it.json'
import de from './de.json'
import fr from './fr.json'
import es from './es.json'
import ja from './ja.json'
import ko from './ko.json'
import nl from './nl.json'
import pt from './pt.json'
import ru from './ru.json'
import zh from './zh.json'

import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/ar'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'

//TODO: change the 'value' key to 'code' like the starter
export const appLanguages = [
    {label: 'English (EN)', value: 'en', flagCode: 'gb', json: en},
    {label: 'Español (ES)', value: 'es-419', flagCode: 'es', json: es},
    {label: 'Deutsch (DE)', value: 'de', flagCode: 'de', json: de},
    {label: 'Français (FR)', value: 'fr', flagCode: 'fr', json: fr},
    {label: 'Italiano (IT)', value: 'it', flagCode: 'it', json: it},
    {label: '日本語 (JP)', value: 'ja', flagCode: 'jp', json: ja},
    {label: '한국어 (KO)', value: 'ko', flagCode: 'kr', json: ko},
    {label: 'Nederlands (NL)', value: 'nl', flagCode: 'nl', json: nl},
    {label: 'Português (PT)', value: 'pt', flagCode: 'pt', json: pt},
    {label: 'Русский (RU)', value: 'ru', flagCode: 'ru', json: ru},
    {label: '中文 (ZH)', value: 'zh', flagCode: 'cn', json: zh}
] as const

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: Object.fromEntries(appLanguages.map(language => [language.value, language.json])),
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: appLanguages[0].value,
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: appLanguages.map(language => language.value),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

export const changeAppLanguage = async (nextLanguage: (typeof appLanguages)[number]['value']) => {
    await i18n.changeLanguage(nextLanguage)
}

export default i18n
