import {CheckCard} from '../check-card/CheckCard'
import {StyledList, StyledAmenitiesList, StyledCardsList} from './style'

export const List = ({rooms, amenities, imagesList, hasSubmitError, isDeleted = false}) => {
    return (
        <StyledList>
            {rooms.map((room, roomIndex) => {
                const hasRoomImages = imagesList.some(image => image.selectedRoom === room.value)

                return hasRoomImages ? (
                    <div key={roomIndex}>
                        <h4>{room.label}</h4>
                        <StyledAmenitiesList>
                            {amenities.map(amenity => {
                                const hasAmenityImages = imagesList.some(
                                    image =>
                                        image.selectedAmenity === amenity.value && image.selectedRoom === room.value
                                )

                                return hasAmenityImages ? (
                                    <div key={amenity.id}>
                                        <h5>{amenity.label}</h5>
                                        <StyledCardsList>
                                            {imagesList
                                                .filter(
                                                    image =>
                                                        image.selectedRoom === room.value &&
                                                        image.selectedAmenity === amenity.value
                                                )
                                                .map((image, imageIndex) => (
                                                    <CheckCard
                                                        isDeleted={isDeleted}
                                                        hasSubmitError={hasSubmitError}
                                                        image={image}
                                                        key={imageIndex}
                                                    />
                                                ))}
                                        </StyledCardsList>
                                    </div>
                                ) : (
                                    <></>
                                )
                            })}
                        </StyledAmenitiesList>
                    </div>
                ) : (
                    <></>
                )
            })}
        </StyledList>
    )
}
