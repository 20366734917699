import * as Yup from 'yup'
import i18n from '../../../../translations/i18n'

export const EditFreelancerModalSchema = Yup.object().shape({
    airbnbId: Yup.string()
        .required(i18n.t('errors:required'))
        .typeError(i18n.t('errors:number_invalid'))
        .test('isNumberPositive', i18n.t('errors:number_invalid'), id => /^\d+$/.test(id)),
    newEmail: Yup.string().email(i18n.t('errors:email_invalid')).required(i18n.t('errors:required'))
})
