import {Subheader} from './subheader/Subheader'
import {StyledCloseIcon, StyledContent, StyledFullScreenOverlay, StyledFullscreenImage, StyledUploader} from './style'
import {resetCurrentTask, setCurrentTask, taskSelector} from '../../../../store/matterport'
import {httpGetTask} from '../../../../http-requests/admin/matterport.http'
import {MATTERPORT_STATUSES, RESPONSE_TYPES, ROUTES} from '../../../../helpers/constants'
import {errorHandler} from '../../../../helpers/utils'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router'
import {useState, useEffect, useCallback} from 'react'
import {PageHelmet} from '../../../../components/page-helmet/PageHelmet'
import {Spinner} from '../../../../components/spinner-legacy/Spinner'
import {useTranslation} from 'react-i18next'
import {DropzoneView} from './dropzone-view/DropzoneView'
import {ImagesListView} from './images-list-view/ImagesListView'
import useKey from 'use-key-hook'
import {useUserStore} from '../../../../features/user/store'

export const Uploader = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const task = useSelector(taskSelector)
    const [imagesFromDropzone, setImagesFromDropzone] = useState({
        accepted: [],
        rejected: []
    })
    const showConfirmView = !!imagesFromDropzone.accepted.length
    const [fullScreenImage, setFullScreenImage] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const uploadedImages = task?.accessibility_images?.filter(image => !image?.deleted_on_upload)
    const removedImages = task?.accessibility_images?.filter(image => image?.deleted_on_upload)
    const toUploadImages = imagesFromDropzone.accepted.map(image =>
        Object.assign(image, {
            preview: URL.createObjectURL(image)
        })
    )

    useEffect(() => {
        getCurrentTask()

        return () => {
            dispatch(resetCurrentTask())
        }
    }, [])

    const setImagesFromDropzoneCb = useCallback(images => {
        setImagesFromDropzone(images)
    }, [])

    const setFullScreenImageCb = image => {
        setFullScreenImage(image)
    }

    useKey(() => setFullScreenImageCb(null), {detectKeys: [27]})

    const checkAllowedStatus = status => {
        if (status !== MATTERPORT_STATUSES.UPLOAD_MEDIABOX) {
            status === MATTERPORT_STATUSES.READY_QA || status === MATTERPORT_STATUSES.READY_PUBLISH
                ? navigate(ROUTES.ADMIN_MATTERPORT_TASK.replace(':id', taskId), {replace: true})
                : navigate(ROUTES.PAGE_NOT_FOUND, {replace: true})
        }
    }

    const getCurrentTask = async () => {
        try {
            const {data} = await httpGetTask(adminAccessToken, taskId, {
                response_type: RESPONSE_TYPES.EXTENDED
            })
            dispatch(setCurrentTask(data))
            checkAllowedStatus(data?.task_matterport?.status)
        } catch (e) {
            e?.response?.status === 404 ? navigate(ROUTES.PAGE_NOT_FOUND, {replace: true}) : errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <StyledUploader $pageHeight={window.innerHeight}>
            <PageHelmet title={t('titles:admin_matterport')} />
            {fullScreenImage && (
                <StyledFullscreenImage>
                    <StyledFullScreenOverlay onClick={() => setFullScreenImage(null)} />
                    <StyledCloseIcon onClick={() => setFullScreenImage(null)} />
                    <img src={fullScreenImage?.url} alt={fullScreenImage?.name} />
                </StyledFullscreenImage>
            )}

            {isLoading ? (
                <Spinner overlay={false} />
            ) : (
                <>
                    <Subheader showConfirmView={showConfirmView} imagesFromDropzone={imagesFromDropzone} />
                    <div className="container">
                        <div className="row">
                            <StyledContent className="col-12">
                                {!!task?.accessibility_images?.length && (
                                    <ImagesListView
                                        sectionType="uploaded"
                                        imagesList={uploadedImages}
                                        setFullScreenImageCb={setFullScreenImageCb}
                                    />
                                )}
                                {!!removedImages?.length && (
                                    <ImagesListView
                                        sectionType="removed"
                                        imagesList={removedImages}
                                        setFullScreenImageCb={setFullScreenImageCb}
                                    />
                                )}
                                {showConfirmView ? (
                                    <ImagesListView
                                        sectionType="to_upload"
                                        imagesList={toUploadImages}
                                        imagesFromDropzone={imagesFromDropzone}
                                        setImagesFromDropzoneCb={setImagesFromDropzoneCb}
                                        setFullScreenImageCb={setFullScreenImageCb}
                                    />
                                ) : (
                                    <DropzoneView
                                        uploadSizeLimit={3}
                                        setImagesFromDropzoneCb={setImagesFromDropzoneCb}
                                        acceptedFiles="image/*"
                                    />
                                )}
                            </StyledContent>
                        </div>
                    </div>
                </>
            )}
        </StyledUploader>
    )
}
