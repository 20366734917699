import {NavLink} from 'react-router-dom'
import styled, {css} from 'styled-components'

export const StyledIcon = styled.div`
    all: unset;
    cursor: pointer;
    position: relative;
`

export const StyledMenu = styled.div`
    ${({theme: {colors, palette, shadows, spacing}}) => css`
        border: 1px solid ${colors.transparentBlack};
        border-radius: 8px;
        box-shadow: ${shadows.grey};
        min-width: 320px;
        background-color: ${palette.neutral.white};
        cursor: default;
        & .menu_items {
            border-top: 1px solid ${colors.gainsboroGrey};
            padding-top: ${spacing * 3}px;
            padding-bottom: ${spacing * 3}px;
        }
    `}
`

export const StyledMenuItems = styled.div`
    ${({theme: {colors, spacing}}) => css`
        border-top: 1px solid ${colors.gainsboroGrey};
        padding-top: ${spacing * 3}px;
        padding-bottom: ${spacing * 3}px;
    `}
`

const StyledMenuListItem = css`
    ${({theme: {spacing, typography, colors, transition}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 4}px;
        width: 100%;
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
        text-decoration: none;
        color: ${colors.lightBlack};
        ${typography.textSm};
        font-weight: 500;
        ${transition};
        &:hover {
            background: ${colors.lightGrey};
            cursor: pointer;
            color: ${colors.lightBlack};
        }
        &:focus-visible {
            outline: none;
            background: ${colors.lightGrey};
        }
    `}
`

export const StyledDivListItem = styled.div`
    ${({isAccountInfo, theme: {palette, spacing}}) => css`
        ${StyledMenuListItem}
        ${isAccountInfo &&
        css`
            &:hover {
                background: ${palette.neutral.white} !important;
            }
            cursor: default !important;
            padding: ${spacing * 4}px ${spacing * 3}px !important;
        `}
    `}
`
export const StyledNavListItem = styled(NavLink)`
    ${({hasBadge}) => css`
        ${StyledMenuListItem}
        ${hasBadge &&
        css`
            justify-content: space-between;
        `}
    `}
`
