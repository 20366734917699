import {FC, HTMLAttributes} from 'react'

export const ArrowDownIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 18, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8555 7.3196C15.5301 6.99416 15.0025 6.99416 14.677 7.3196L10.2663 11.7303L5.85553 7.3196C5.5301 6.99416 5.00246 6.99416 4.67702 7.3196C4.35158 7.64504 4.35158 8.17267 4.67702 8.49811L9.67702 13.4981C10.0025 13.8235 10.5301 13.8235 10.8555 13.4981L15.8555 8.49811C16.181 8.17267 16.181 7.64504 15.8555 7.3196Z"
            fill="currentColor"
        />
    </svg>
)
