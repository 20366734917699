import styled, {css} from 'styled-components'
import {ToggleSwitch} from 'src/components/toggle-switch/ToggleSwitch.tsx'

export const StyledToggleSwitch = styled(ToggleSwitch)`
    align-self: flex-end;
`

export const StyledSelectCard = styled.div(
    ({theme: {mediaQuery, spacing, colors}}) => css`
        width: 274px;
        background: ${colors.whiteSmoke};
        padding: ${spacing * 2}px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: ${spacing * 2}px;
        border-radius: 8px;
        border: 1px solid ${colors.inputGrey};
        ${mediaQuery.XL} {
            width: 228px;
        }
    `
)

export const StyledImageWrapper = styled.div(
    ({theme: {spacing, typography}}) => css`
        width: 100%;
        & > span {
            width: 100%;
            & > img {
                object-position: center;
                object-fit: cover;
                width: 100%;
                aspect-ratio: 1.5/1;
                margin-bottom: ${spacing * 2}px;
                border-radius: 4px;
                cursor: pointer;
                @supports not (aspect-ratio: 16 / 9) {
                    height: 150px;
                }
            }
        }
        & > p {
            height: 42px;
            ${typography.textSm};
            font-weight: 500;
            word-break: break-all;
            overflow: hidden;
            line-clamp: 2;
            box-orient: vertical;
            display: flex;
        }
    `
)

export const StyledSelects = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: ${spacing * 2}px;
        & > * {
            width: 100%;
        }
    `
)
