import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '../../../queryClient'
import {errorHandler} from '../../../helpers/utils'
import {httpGenerateTransactions} from './adminPayments.http'
import {useUserStore} from '../../user/store'

export const useGenerateTransactions = ({onSuccess}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    return useMutation({
        mutationKey: [MUTATION_KEYS.GENERATE_TRANSACTIONS],
        mutationFn: serviceType => httpGenerateTransactions(serviceType, adminAccessToken),
        onSuccess: () => {
            onSuccess?.()
        },
        onError: errorHandler,
        onSettled: async () => {
            await queryClient.invalidateQueries([QUERY_KEYS.ADMIN_GENERATE_TRANSACTIONS_STATS])
        }
    })
}
