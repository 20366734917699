import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {ScheduleModal} from 'src/features/assignment/components/schedule-modal/ScheduleModal.tsx'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {formatLocaleDate} from 'src/dayjs.ts'

export const AcceptedDatesDontMatchDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {t('assignment:descriptions:accepted_dates_dont_match:title', {
                        date: formatLocaleDate(assignment.dates_dont_match_at, 'LL')
                    })}
                </AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:accepted_dates_dont_match:paragraph:${serviceType}`)}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            <Button onClick={() => setIsScheduleModalOpen(true)}>{t('assignment:descriptions:schedule')}</Button>
            {isScheduleModalOpen && (
                <ScheduleModal
                    assignment={assignment}
                    serviceType={serviceType}
                    onClose={() => setIsScheduleModalOpen(false)}
                />
            )}
        </AssignmentDescriptionWrapper>
    )
}
