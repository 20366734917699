import {BadgeProps} from './Badge'
import styled, {css} from 'styled-components'

export const Badge = styled.div<{
    $size: BadgeProps['size']
    $color: NonNullable<BadgeProps['color']>
    $background: NonNullable<BadgeProps['background']>
    $isClickable: BadgeProps['isClickable']
    $textUppercase: BadgeProps['textUppercase']
    $disabled: BadgeProps['disabled']
}>`
    ${({theme, $size, $background, $color, $isClickable, $disabled, $textUppercase}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        height: ${$size == 'small' ? 18 : 25}px;
        border-radius: ${$size == 'small' ? 4 : 6}px;
        background: ${$background in theme.colors
            ? theme.colors[$background as keyof typeof theme.colors]
            : $background};
        color: ${$color in theme.colors ? theme.colors[$color as keyof typeof theme.colors] : $color};
        ${$size == 'small' ? theme.typography.textSm : theme.typography.textLg};
        font-weight: 500;
        text-transform: ${$textUppercase ? 'uppercase' : 'inherit'};

        ${$isClickable &&
        !$disabled &&
        css`
            ${theme.transition};
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        `}

        ${theme.mediaQuery.SM} {
            height: ${$size == 'small' ? 16 : 22}px;
            padding: 0 6px;
            ${$size == 'small' ? theme.typography.textXs : theme.typography.textMd};
        }
    `}
`
