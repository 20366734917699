import {Outlet} from 'react-router-dom'
import {Snackbar} from '../../components/snackbar/Snackbar'
import {AdminHeader} from '../../components/admin-header/AdminHeader'
import {StyledAdminLayout} from './style'
import {HTMLAttributes} from 'react'

export interface AdminLayoutProps extends HTMLAttributes<HTMLDivElement> {
    background?: 'neutral' | 'white'
}

export const AdminLayout = ({background = 'neutral'}: AdminLayoutProps) => {
    return (
        <StyledAdminLayout $background={background}>
            <AdminHeader />
            <Outlet />
            <Snackbar />
        </StyledAdminLayout>
    )
}
