import {useTranslation} from 'react-i18next'
import {StyledAdminHostImpersonateMenu, StyledAdminHostImpersonateMenuItem} from './style'
import {MenuButton, MenuProvider} from '@ariakit/react'
import {ChevronDownIcon} from 'src/components/icon'
import {useUserStore} from 'src/features/user/store'
import {useAsync} from 'src/hooks/useAsync'
import {errorHandler, makeHttpAuthorizationHeader, openExternalLink, raise} from 'src/helpers/helpers'
import {httpGetAdminHostsImpersonate} from 'src/features/admin-hosts/services/admin-hosts.http'
import {AxiosError} from 'axios'
import {useDispatch} from 'react-redux'
import {showAlert} from 'src/store/appGenerics'
import {ImpersonateServicePath} from 'src/features/admin-hosts/types'
import {elevateUrlEnvMap} from 'src/features/admin-hosts/utils'
import {ADMIN_HOSTS_ENV} from 'src/pages/admin-hosts/AdminHosts'
import {Button} from 'src/components/button/Button'
import {Tooltip} from 'src/components/tooltip/Tooltip'
import {Spinner} from 'src/components/spinner/Spinner'

export const AdminHostsImpersonateDropdown = ({
    externalHostId,
    disabled
}: {
    externalHostId: string | null
    disabled: boolean
}) => {
    const {t} = useTranslation()
    const adminAccessToken = useUserStore(state => state.adminAccessToken) ?? raise('adminAccessToken is nullish')
    const getAdminHostsImpersonate = useAsync()
    const dispatch = useDispatch()

    const handleAdminHostsImpersonate = async (service: ImpersonateServicePath) => {
        try {
            const impersonateResult = await getAdminHostsImpersonate.run(
                httpGetAdminHostsImpersonate({
                    urlParams: {externalId: externalHostId ?? raise('externalHostId is nullish')},
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                })
            )

            openExternalLink(
                `${elevateUrlEnvMap[ADMIN_HOSTS_ENV]}?impersonate=${impersonateResult.data}&service=${service}`
            )
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status == 400) {
                dispatch(
                    showAlert({
                        message: t(`admin_hosts:${error.response.data[0]}`),
                        level: 'error'
                    })
                )
            } else {
                errorHandler(error)
            }
        }
    }

    return (
        <MenuProvider>
            <Tooltip
                content={t('admin_hosts:cannot_impersonate')}
                rootProps={{open: disabled ? undefined : false}}
                triggerProps={{asChild: true}}
            >
                <MenuButton
                    disabled={disabled}
                    render={
                        <Button size="sm" variant="secondary">
                            {t('admin_hosts:impersonate_trigger')}
                            {getAdminHostsImpersonate.isLoading ? <Spinner /> : <ChevronDownIcon />}
                        </Button>
                    }
                />
            </Tooltip>
            <StyledAdminHostImpersonateMenu gutter={8}>
                <StyledAdminHostImpersonateMenuItem onClick={() => handleAdminHostsImpersonate('photoshoot-requests')}>
                    {t('admin_hosts:impersonate_request_a_photoshoot')}
                </StyledAdminHostImpersonateMenuItem>
                <StyledAdminHostImpersonateMenuItem onClick={() => handleAdminHostsImpersonate('co-alarm')}>
                    {t('admin_hosts:impersonate_co_smoke_alarm')}
                </StyledAdminHostImpersonateMenuItem>
                <StyledAdminHostImpersonateMenuItem onClick={() => handleAdminHostsImpersonate('categories')}>
                    {t('admin_hosts:impersonate_categories')}
                </StyledAdminHostImpersonateMenuItem>
            </StyledAdminHostImpersonateMenu>
        </MenuProvider>
    )
}
