import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledErrorMessage} from 'src/components/table/error-message/style'

export const ErrorMessage: FC = () => {
    const {t} = useTranslation()

    return <StyledErrorMessage>{t('errors:default')}</StyledErrorMessage>
}

ErrorMessage.displayName = 'DesktopTableErrorMessage'
