import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {zodResolver} from '@hookform/resolvers/zod'
import {Assignment} from 'src/features/assignment/types'
import {ALERT_LEVELS} from 'src/helpers/constants'
import {useUpdatePersonalNotes} from 'src/features/assignment/services/useUpdatePersonalNotes'
import {FC, useMemo} from 'react'
import {Button} from 'src/components/button/Button'
import {showAlert} from 'src/store/appGenerics.ts'
import {useDispatch} from 'react-redux'
import {Spinner} from 'src/components/spinner/Spinner'
import {z} from 'zod'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {getServiceType} from 'src/features/assignment/utils'
import {TextArea} from 'src/components/textarea/Textarea'

const TEXTAREA_MAX_LENGTH = 2000

const PersonalNotesValidationSchema = z.object({
    notes: z.string().max(TEXTAREA_MAX_LENGTH)
})

export const AssignmentNotesCardForm: FC<{assignment: Assignment}> = ({assignment}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {
        register,
        reset,
        handleSubmit,
        formState: {errors, isDirty}
    } = useForm({
        defaultValues: {notes: assignment.notes ?? ''},
        mode: 'onChange',
        resolver: zodResolver(PersonalNotesValidationSchema)
    })

    const updatePersonalNotes = useUpdatePersonalNotes({
        assignmentId: assignment.id,
        options: {
            onSuccess: data => {
                dispatch(
                    showAlert({
                        message: t('assignment:notes_card:success'),
                        level: ALERT_LEVELS.SUCCESS
                    })
                )

                reset({notes: data.notes ?? ''})
            }
        }
    })

    const onSubmit = handleSubmit(data => {
        updatePersonalNotes.mutate(data.notes)
    })

    const isWalkthrough = useMemo(
        () => getServiceType(assignment.task_media.name, assignment.task_category.name) == 'walkthrough',
        [assignment.task_media, assignment.task_category]
    )

    return (
        <Flexbox render="form" gap={2} onSubmit={onSubmit} direction="column" align="end">
            <TextArea
                placeholder={t('assignment:notes_card:placeholder')}
                maxLength={TEXTAREA_MAX_LENGTH}
                errorMessage={errors.notes?.message}
                rows={4}
                helpText={isWalkthrough ? t('assignment:notes_card:walkthrough_hint') : t('assignment:notes_card:hint')}
                {...register('notes')}
            />

            <Flexbox gap={2} justify="end">
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={() => reset()}
                    disabled={updatePersonalNotes.isLoading || !isDirty}
                >
                    {t('commons:cancel')}
                </Button>
                <Button variant="secondary" type="submit" disabled={updatePersonalNotes.isLoading || !isDirty}>
                    {updatePersonalNotes.isLoading && <Spinner size={20} />}
                    {t('commons:save')}
                </Button>
            </Flexbox>
        </Flexbox>
    )
}
