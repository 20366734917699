import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalRejectedImages = styled.div(
    ({theme: {palette}}) => css`
        background-color: ${palette.neutral[50]};
        border-radius: 8px;
        width: 100%;
    `
)

export const StyledAdminHostApprovalRejected = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)

export const StyledAdminHostApprovalRejectedImagesTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textLg};
        font-weight: 600;
    `
)
