import styled, {css} from 'styled-components'

export const StyledLeftColumn = styled.div`
    ${({theme: {colors, spacing}}) => css`
        padding-right: ${spacing * 12}px;
        border-right: 1px solid ${colors.inputGrey};
    `}
`

export const StyledRightColumn = styled.div`
    ${({theme: {spacing}}) => css`
        padding-left: ${spacing * 12}px;
    `}
`
