import * as Dropdown from '@radix-ui/react-dropdown-menu'
import {useRef} from 'react'
import {ContextMenuIcon} from '../../../theme/icons'
import {StyledIcon, StyledMenu, StyledDivListItem, StyledNavListItem, StyledMenuItems} from './style'
import {ROUTES} from '../../../helpers/constants'
import {SettingsIcon} from '../../../assets/icons/SettingsIcon'
import {AccountCard} from '../../account-card/AccountCard'
import {LogoutIcon} from '../../../assets/icons/LogoutIcon'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {useUserStore} from '../../../features/user/store'

export const NavigationDropdown = ({onLogoutCb, trigger = <ContextMenuIcon />, onOpenChange}) => {
    const {t} = useTranslation()
    const currentUser = useUserStore(store => store.currentUser)
    const ref = useRef()

    return (
        <Dropdown.Root onOpenChange={onOpenChange}>
            <Dropdown.Trigger asChild ref={ref}>
                <StyledIcon>{trigger}</StyledIcon>
            </Dropdown.Trigger>

            <Dropdown.Portal container={ref?.current}>
                <Dropdown.Content sideOffset={4} asChild align="end">
                    <StyledMenu>
                        <StyledDivListItem isAccountInfo>
                            <AccountCard
                                name={currentUser?.name}
                                email={currentUser?.email}
                                profilePhotoUrl={currentUser?.profile_photo_url || ''}
                            />
                        </StyledDivListItem>
                        <StyledMenuItems>
                            <Dropdown.Item asChild>
                                <StyledNavListItem to={ROUTES.SETTINGS}>
                                    <SettingsIcon size={20} />
                                    <span>{t('menu:settings')}</span>
                                </StyledNavListItem>
                            </Dropdown.Item>
                            <Dropdown.Item asChild>
                                <StyledDivListItem onClick={onLogoutCb}>
                                    <LogoutIcon size={20} />
                                    <span>{t('menu:logout')}</span>
                                </StyledDivListItem>
                            </Dropdown.Item>
                        </StyledMenuItems>
                    </StyledMenu>
                </Dropdown.Content>
            </Dropdown.Portal>
        </Dropdown.Root>
    )
}

NavigationDropdown.propTypes = {
    onLogoutCb: PropTypes.func,
    trigger: PropTypes.node,
    onOpenChange: PropTypes.func
}
