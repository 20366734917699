import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {httpGetDeclineReasons} from 'src/features/assignment/services/assignment.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'
import {DeclineReason} from 'src/features/assignment/types.ts'
import {z} from 'zod'

export const useDeclineReasons = () => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.DECLINE_REASONS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetDeclineReasons({
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: z.array(DeclineReason).min(1),
                onZodError: captureException
            })
    })
}
