import i18next from 'i18next'
import {store} from '../../../../index'
import {addStepToChatHistory} from '../../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../../helpers/constants'
import {TicketSubmit} from '../../custom-components/TicketSubmit'
import {CancelShoot} from '../../custom-components/CancelShoot'

export const noShow = (isInShootPage, shoot) => [
    {
        id: 'MB1.4_noshow',
        message: i18next.t('chatbot:commons:host_noshow'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_noshow',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'We are sorry to hear that the Host did not show up to the scheduled appointment.'
                })
            )
            return 'MB1.4_noshow.2'
        }
    },
    {
        id: 'MB1.4_noshow.2',
        message: i18next.t('chatbot:commons:host_noshow_detail'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_noshow.2',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please give us any relevant detail of the no-show.'
                })
            )
            return 'MB1.4_noshow_notes_input'
        }
    },
    {
        id: 'MB1.4_noshow_notes_input',
        user: true,
        validator: value => {
            if (value === '') {
                return i18next.t('chatbot:commons:enter_message')
            } else if (value.length < 30) {
                return i18next.t('chatbot:commons:message_should_be_longer')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_noshow_notes_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB1.4_confirm'
        }
    },
    {
        id: 'MB1.4_noshow_ticket',
        component: <TicketSubmit reason="cancel_noshow" isInShootPage={isInShootPage} shoot={shoot} />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_noshow_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'MB1.4_noshow_cancel'
        },
        replace: true,
        asMessage: true
    },
    {
        id: 'MB1.4_noshow_cancel',
        component: <CancelShoot flowSource="MB1.4_pull" />,
        asMessage: true,
        replace: true,
        trigger: 'MB1.4_noshow.3'
    },
    {
        id: 'MB1.4_noshow.3',
        message: i18next.t('chatbot:mb14:mb1.4_noshow.3'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_noshow.3',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thank you for providing the requested information. Our team will follow up shortly via email to confirm a 50% payment to your account.'
                })
            )
            return 'MB1.4_noshow.4'
        }
    },
    {
        id: 'MB1.4_noshow.4',
        message: i18next.t('chatbot:mb14:mb1.4_noshow.4'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_noshow.4',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'We can also confirm the photoshoot request has been canceled.'
                })
            )
            return 'send_log'
        }
    }
]
