import {useEffect} from 'react'
import {AssignmentCardWrapper} from 'src/features/assignment/components/assignment-card-atoms/AssignmentCardAtoms'
import {AssignmentCompensationCard} from 'src/features/assignment/components/assignment-compensation-card/AssignmentCompensationCard'
import {
    StyledAssignmentContent,
    StyledAssignmentScheduledOverview
} from 'src/features/assignment/components/assignment-content/style'
import {AssignmentHostCard} from 'src/features/assignment/components/assignment-host-card/AssignmentHostCard'
import {AssignmentNotesCard} from 'src/features/assignment/components/assignment-notes-card/AssignmentNotesCard'
import {AssignmentScheduledDate} from 'src/features/assignment/components/assignment-scheduled-date/AssignmentScheduledDate'
import {
    AssignmentShootlistCard,
    LOCALSTORAGE_KEY_SHOOTLIST_CARD_PREFIX
} from 'src/features/assignment/components/assignment-shootlist-card/AssignmentShootlistCard'
import {Assignment} from 'src/features/assignment/types'

export const AssignmentHomesCards = ({assignment}: {assignment: Assignment}) => {
    useEffect(() => {
        // Clear the checklist stored in the local storage if the task status is 'pending_payment'
        if (assignment.task_status.status == 'pending_payment') {
            localStorage.removeItem(`${LOCALSTORAGE_KEY_SHOOTLIST_CARD_PREFIX}-${assignment.id}`)
        }
    }, [assignment.task_status.status])

    return (
        <StyledAssignmentContent>
            <StyledAssignmentScheduledOverview direction="column" gap={6}>
                {assignment.task_status.status == 'pending_assets' && (
                    <AssignmentCardWrapper>
                        <AssignmentScheduledDate date={assignment.to_shoot_at} />
                    </AssignmentCardWrapper>
                )}

                {assignment.task_status.status != 'pending_payment' &&
                assignment.task_status.status != 'completed' &&
                assignment.task_category.name != 'Experiences' &&
                assignment.task_media.name != 'video' ? (
                    <AssignmentShootlistCard assignment={assignment} />
                ) : (
                    <AssignmentNotesCard assignment={assignment} />
                )}
            </StyledAssignmentScheduledOverview>
            <StyledAssignmentScheduledOverview direction="column" gap={6}>
                {assignment.status == 'pending' ? (
                    <AssignmentCompensationCard assignment={assignment} />
                ) : (
                    <>
                        <AssignmentHostCard />
                        <AssignmentCompensationCard assignment={assignment} />

                        {assignment.task_status.status != 'pending_payment' &&
                            assignment.task_status.status != 'completed' &&
                            assignment.task_category.name != 'Experiences' &&
                            assignment.task_media.name != 'video' && <AssignmentNotesCard assignment={assignment} />}
                    </>
                )}
            </StyledAssignmentScheduledOverview>
        </StyledAssignmentContent>
    )
}
