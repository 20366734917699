import PropTypes from 'prop-types'
import {StyledLanguageRadio, StyledSpan} from './style'

export const RADIO_STYLE_TYPES = {
    FILLED: 'filled',
    OUTLINED: 'outlined'
}

export const LanguageRadio = ({className, name, value, label, checked, onChange, ...rest}) => {
    return (
        <StyledLanguageRadio {...rest} className={className}>
            <input type="radio" name={name} checked={checked} onChange={onChange} value={value} />
            <StyledSpan>{label}</StyledSpan>
        </StyledLanguageRadio>
    )
}

LanguageRadio.propTypes = {
    classNames: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.node,
    styleType: PropTypes.oneOf(Object.values(RADIO_STYLE_TYPES)).isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}
