import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_ACTION_KEYS, QUERY_KEYS} from 'src/queryClient.ts'
import {httpSubmitToAirbnb} from 'src/features/assignment/services/assignment.http'
import {Assignment} from 'src/features/assignment/types.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'

export const useSubmitToAirbnb = ({
    assignmentId,
    options
}: {
    assignmentId: Assignment['id']
    options?: UseMutationOptions<Assignment, unknown, string>
}) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.SUBMIT_TO_AIRBNB],
        mutationFn: (photographer_notes: string) =>
            parseAxiosPromise({
                axiosPromise: httpSubmitToAirbnb({
                    params: {
                        photographer_notes: photographer_notes,
                        response_type: 'extended'
                    },
                    urlParams: {assignmentId: assignmentId},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onSuccess: (data, variables, context) => {
            invalidationSignal(QUERY_ACTION_KEYS.SUBMIT_TO_AIRBNB)
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.ASSIGNMENT, data.code]}, data)
            options?.onSuccess?.(data, variables, context)
        },
        onError: errorHandler
    })
}
