import styled, {css} from 'styled-components'
import {CustomScrollbar} from 'src/theme/components.ts'

export const FooterWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 2.5}px;
        padding: ${spacing * 6}px;
    `
)

export const StyledBodyWrapper = styled.div`
    overflow: auto;
    ${CustomScrollbar};
    max-height: 650px;
`

export const StyledTitle = styled.h3(
    ({theme: {spacing, colors, typography}}) => css`
        padding: ${spacing * 6}px;
        ${typography.textXl};
        font-weight: 700;
        color: ${colors.lightBlack};
    `
)

export const StyledSubTitle = styled.h4(
    ({theme: {colors, typography}}) => css`
        color: ${colors.lightBlack};
        ${typography.textMd};
        font-weight: 600;
    `
)

export const InputSectionWrapper = styled.div(
    ({theme: {spacing, colors}}) => css`
        padding: ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
        border-bottom: 1px solid ${colors.smokeGrey};
    `
)

export const RadioWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 11}px;
    `
)
