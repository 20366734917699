import {useTranslation} from 'react-i18next'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    StyledAdminHostApprovalTaskTitle,
    StyledAdminHostApprovalTaskType
} from 'src/features/admin-host-approval/components/admin-host-approval-task-heading/style'
import {AdminHostApprovalTask} from 'src/features/admin-host-approval/types'
import {Badge} from 'src/components/badge/Badge'

const hostApprovalTaskStatusToLabel = {
    new: 'commons:new',
    pending_host_approval: 'commons:pending_host_approval',
    ready_for_review: 'commons:ready_for_review',
    completed: 'commons:completed'
} as const satisfies Record<AdminHostApprovalTask['status'], string>

export const AdminHostApprovalTaskHeading = ({
    adminHostApprovalTask
}: {
    adminHostApprovalTask: AdminHostApprovalTask
}) => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={3}>
            <StyledAdminHostApprovalTaskTitle>
                {adminHostApprovalTask?.entity?.title ??
                    t('commons:listing_n_x', {externalId: adminHostApprovalTask.external_id})}
            </StyledAdminHostApprovalTaskTitle>
            <Flexbox gap={2.5} align="center">
                <Badge variant="blue" shape="roundedRectangle" size="lg">
                    {t(hostApprovalTaskStatusToLabel[adminHostApprovalTask.status])}
                </Badge>
                <StyledAdminHostApprovalTaskType>
                    {adminHostApprovalTask.accessibility_type.name}
                </StyledAdminHostApprovalTaskType>
            </Flexbox>
        </Flexbox>
    )
}
