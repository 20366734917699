import PropTypes, {shape} from 'prop-types'
import {useTranslation} from 'react-i18next'

import {Row} from '../row/TableRow'
import {TableLoader} from '../loader/TableLoader'
import {ErrorMessage} from '../../error-message/ErrorMessage'

import {TABLE_ALIGNMENTS} from '../../../../helpers/constants'

import {StyledNoResultsMessage, StyledPaginatedTbody, StyledTbody} from './style'

export const PaginatedBody = ({
    onClickRow,
    columns,
    columnsRenderers,
    expandedRowsIndexes,
    alternatingRowColors,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    isError,
    tbodyHeight,
    onChangePageCb,
    onRefresh,
    data,
    variant
}) => {
    const {t} = useTranslation()

    if (isError) {
        return (
            <StyledTbody $tbodyHeight={tbodyHeight}>
                <ErrorMessage onRefresh={onRefresh} />
            </StyledTbody>
        )
    }
    if (isLoading || isRefetching) {
        return (
            <StyledTbody $tbodyHeight={tbodyHeight}>
                <TableLoader columns={columns} />
            </StyledTbody>
        )
    }
    if (data.length === 0) {
        return (
            <StyledTbody $tbodyHeight={tbodyHeight}>
                <StyledNoResultsMessage>{t('commons:no_results')}</StyledNoResultsMessage>
            </StyledTbody>
        )
    }

    return (
        <StyledPaginatedTbody
            $tbodyHeight={tbodyHeight}
            data={data}
            endReached={onChangePageCb}
            overscan={100}
            variant={variant}
            itemContent={itemIndex => {
                const item = data[itemIndex]
                const clickableRowPath = onClickRow(item)

                return (
                    <Row
                        item={item}
                        itemIndex={itemIndex}
                        columns={columns}
                        columnsRenderers={columnsRenderers}
                        expandedRowsIndexes={expandedRowsIndexes}
                        alternatingRowColors={alternatingRowColors}
                        clickableRowPath={clickableRowPath}
                        key={itemIndex}
                        variant={variant}
                    />
                )
            }}
            components={{
                ...(isFetchingNextPage && {Footer: () => <TableLoader columns={columns} />})
            }}
        />
    )
}

PaginatedBody.propTypes = {
    alternatingRowColors: PropTypes.bool,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({width}, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            }
        })
    ).isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    isLoading: PropTypes.bool,
    isRefetching: PropTypes.bool,
    isFetchingNextPage: PropTypes.bool,
    isError: PropTypes.bool,
    tbodyHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangePageCb: PropTypes.func,
    onClickRow: PropTypes.func,
    onRefresh: PropTypes.func,
    variant: PropTypes.oneOf(['default', 'grey'])
}
