import styled, {css} from 'styled-components'

export const StyledAssignmentDataItemList = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-wrap: wrap;
        gap: ${spacing * 6}px;
    `
)

export const StyledAssignmentDataTitle = styled.h2(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 700;
    `
)

export const StyledAssignmentDataItem = styled.div<{$isCapitalized?: boolean}>(
    ({theme: {palette, spacing, typography}, $isCapitalized}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        ${typography.textSm};
        color: ${palette.neutral['900']};
        & a {
            color: ${palette.neutral['900']};
            cursor: pointer;
        }

        & svg {
            width: 20px;
            height: 20px;
            fill: ${palette.neutral['600']};
        }
        ${
            $isCapitalized &&
            css`
                text-transform: capitalize;
            `
        }}
    `
)
