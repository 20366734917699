import {useInfiniteQuery} from '@tanstack/react-query'
import {errorHandler} from '../../helpers/utils'
import {httpGetQuoteLogs} from '../../http-requests/admin/quotes.http'
import {QUERY_KEYS} from '../../queryClient'
import {useUserStore} from '../../features/user/store'

export const useAdminQuoteLogs = (quoteId, status) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.ADMIN_QUOTE_LOGS, quoteId, status],
        queryFn: ({pageParam = 1}) => httpGetQuoteLogs(adminAccessToken, quoteId, pageParam, status),
        onError: errorHandler,
        defaultPageParam: 1,
        enabled: !!quoteId
    })
    const onChangePage = () => {
        const currentPage = query.data.pages.at(-1).current_page
        const lastPage = query.data.pages.at(-1).last_page
        if (!query.isFetching) {
            if (currentPage < lastPage) {
                query.fetchNextPage({pageParam: query.data.pages.at(-1).current_page + 1})
            }
        }
    }

    return {
        ...query,
        remappedData: query.data?.pages?.flatMap(page => page?.data) ?? [],
        page: query.data?.pages.at(-1).current_page ?? 1,
        onChangePage
    }
}
