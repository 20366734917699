import {useQuery} from '@tanstack/react-query'
import {httpGetQuoteByCode} from '../../http-requests/admin/quotes.http'
import {errorHandler} from '../../helpers/utils'
import {QUERY_KEYS} from '../../queryClient'
import {useUserStore} from '../../features/user/store'

export const useAdminQuote = quoteId => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    return useQuery({
        queryKey: [QUERY_KEYS.ADMIN_QUOTE, quoteId],
        queryFn: async () => {
            const {data: payload} = await httpGetQuoteByCode(adminAccessToken, quoteId)
            return payload
        },
        onError: errorHandler,
        enabled: !!quoteId
    })
}
