import {httpSubmitTicket} from '../../../http-requests/chatbot.http'
import {useEffect} from 'react'
import {defineTicketInfoByReason} from '../helpers'
import {errorHandler} from '../../../helpers/utils'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {chatbotHistorySelector, chatbotShootSelector} from '../../../store/chatbot'
import {useUserStore} from '../../../features/user/store'

export const TicketSubmit = ({reason, isInShootPage = false, shoot = {}, steps, isNotListed = false}) => {
    const {t} = useTranslation()
    const accessToken = useUserStore(store => store.accessToken)
    const selectedShoot = useSelector(chatbotShootSelector)
    const chatHistory = useSelector(chatbotHistorySelector)
    const recap = chatHistory.map(({message}) => message).join(' --- ') //whole conversation (messages)

    useEffect(() => {
        onSendTicketResults()
    }, [])

    const onSendTicketResults = async () => {
        try {
            await httpSubmitTicket(accessToken, selectedShoot?.quote?.entity_id, selectedShoot?.quote?.task_media_id, {
                ...defineTicketInfoByReason(reason, isInShootPage, shoot, steps, isNotListed),
                recap
            })
        } catch (e) {
            errorHandler(e)
        }
    }

    return (
        <p>
            {t('chatbot:ticket_submit:message')}
            {'.'}
        </p>
    )
}
