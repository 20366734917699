import {Badge as Root} from './style'
import {HTMLAttributes} from 'react'

export const BADGE_SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium'
}

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean
    size: 'small' | 'medium'
    color?: string
    background?: string
    text?: string | number
    isClickable?: boolean
    textUppercase?: boolean
}

/**
 * @deprecated use the new Badge component instead
 * */
export const Badge = ({
    disabled,
    size,
    color = '#687076',
    background = '#DFE0E1',
    text,
    isClickable = false,
    textUppercase = false,
    ...rest
}: BadgeProps) => {
    return (
        <Root
            title={String(text)}
            $size={size}
            $background={background}
            $color={color}
            $isClickable={isClickable}
            $textUppercase={textUppercase}
            $disabled={disabled}
            {...rest}
        >
            {text}
        </Root>
    )
}
