import styled, {css} from 'styled-components'
import {ErrorIcon} from '../../../theme/icons'

export const LogoutErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ErrorIconWrapper = styled(ErrorIcon)`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 4}px;
    `}
`

export const ErrorTitleWrapper = styled.h1`
    ${({theme: {typography}}) => css`
        ${typography.displayMd};
        font-weight: 700;
        text-align: center;
    `}
`
