import {MobileTHead} from 'src/components/table/mobile-t-head/MobileTHead'
import {TableProps} from 'src/components/table/Table.tsx'
import {MobileTBody} from 'src/components/table/mobile-t-body/MobileTBody'
import {StyledMobileTable} from 'src/components/table/mobile-table/style'
import {HTMLAttributes} from 'react'

export const MobileTable = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    data,
    isError,
    expandedRows,
    isChangingPage,
    isLoading,
    onChangePage,
    sorter,
    subRow,
    ...rest
}: TableProps<TData, TSortName> & HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledMobileTable {...rest}>
            {sorter && <MobileTHead columns={columns} sorter={sorter} />}
            <MobileTBody
                columns={columns}
                data={data}
                isError={isError}
                expandedRows={expandedRows}
                isChangingPage={isChangingPage}
                isLoading={isLoading}
                onChangePage={onChangePage}
                subRow={subRow}
            />
        </StyledMobileTable>
    )
}

MobileTable.displayName = 'MobileTable'
