import styled, {css} from 'styled-components'
import {Scrollbar} from 'src/components/scrollbar/Scrollbar.tsx'

export const StyledStepsWrapper = styled.div(
    ({theme: {shadows, palette}}) => css`
        border: 1px solid ${palette.neutral['300']};
        box-shadow: ${shadows.lg};
        border-radius: 12px;
        width: 100%;
    `
)

export const StyledSteps = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(5, minmax(max-content, 1fr));
        gap: ${spacing * 4}px;
        padding: 0 ${spacing * 4}px;
    `
)

export const StyledScrollbar = styled(Scrollbar)(
    ({theme: {spacing}}) => css`
        margin: 0 0 -${spacing * 2}px;
    `
)
