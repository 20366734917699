import styled, {css} from 'styled-components'

export const StyledPopover = styled.div`
    ${({theme: {zIndex}}) => css`
        position: fixed;
        inset: 0;
        z-index: ${zIndex.popover};
    `}
`

export const StyledOverlay = styled.div`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
`

export const StyledModal = styled.section`
    ${({width, borderRadius, overflow, theme: {spacing, typography, colors, transition, palette}}) => css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 95%;
        background: ${palette.neutral.white};
        width: ${width}px;
        max-width: 95vw;
        border-radius: ${borderRadius}px;
        box-shadow: 9px 9px 14px rgba(0, 0, 0, 0.08);
        overflow: ${overflow ? 'visible' : 'auto'};
        & > div > svg {
            width: 24px;
            position: absolute;
            top: 24px;
            right: 24px;
            cursor: pointer;
            ${transition};
            &:hover {
                opacity: 0.8;
            }
        }

        & h2 {
            ${typography.textXl};
            font-weight: 700;
            text-align: center;
        }

        & h4 {
            ${typography.textMd};
            color: ${colors.darkGrey};
            text-align: center;
            margin-top: ${spacing * 10}px;
        }
    `}
`

export const StyledBodyWrapper = styled.div`
    ${({theme: {spacing, colors}}) => css`
        padding: ${spacing * 8}px ${spacing * 6}px ${spacing * 6}px;
        & p {
            color: ${colors.darkGrey};
        }
    `}
`

export const StyledFooterWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 8}px ${spacing * 4}px;
    `}
`

export const StyledActions = styled.div`
    ${({withDivisor, theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        gap: ${spacing * 6}px;
        margin-top: ${withDivisor ? 0 : spacing * 14}px;
    `}
`

export const StyledDivisor = styled.div`
    ${({theme: {colors}}) => css`
        background: ${colors.smokeGrey};
        width: 100%;
        height: 1px;
    `}
`

export const StyledTitleSection = styled.div`
    ${({theme: {spacing}, titleSpacing}) => css`
        margin-bottom: ${titleSpacing ? spacing * 14 : spacing * 6}px;
    `}
`
