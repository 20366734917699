import styled, {css} from 'styled-components'

export const ModalWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 4}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 7.5}px;
    `}
`

export const FormWrapper = styled.form`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 7.5}px;
    `}
`

export const StyledTitle = styled.h3`
    ${({theme: {typography, colors}}) => css`
        ${typography.textMd};
        font-weight: 700;
        color: ${colors.lightBlack};
    `}
`

export const FooterWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 2.5}px;
    `}
`

export const InputsContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `}
`
