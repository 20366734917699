import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {languageSelector, setLanguage, showAlert} from '../../store/appGenerics'
import {RADIO_STYLE_TYPES} from './language-radio/LanguageRadio'
import {appLanguages} from '../../translations/i18n'
import PropTypes from 'prop-types'
import {Modal} from '../modal-legacy/Modal'
import {StyledRoot, StyledRadio} from './style'

export const SelectMenuModal = ({onClose}) => {
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const currentLanguage = useSelector(languageSelector)

    const onSelectLanguage = async e => {
        const value = e.target.value

        await i18n.changeLanguage(value)
        dispatch(setLanguage(value))
        dispatch(
            showAlert({
                message: t('commons:language_changed_success'),
                level: 'success'
            })
        )
        onClose()
    }

    return (
        <Modal title={t('commons:change_language')} onClose={onClose}>
            <StyledRoot>
                {appLanguages.map(language => {
                    const isSelected = currentLanguage === language.value

                    return (
                        <div key={language.code}>
                            <StyledRadio
                                isSelected={isSelected}
                                name="language"
                                styleType={RADIO_STYLE_TYPES.OUTLINED}
                                color={isSelected ? 'white' : 'black'}
                                label={language.label}
                                value={language.value}
                                checked={isSelected}
                                onChange={onSelectLanguage}
                            />
                        </div>
                    )
                })}
            </StyledRoot>
        </Modal>
    )
}

SelectMenuModal.propTypes = {
    onClose: PropTypes.func.isRequired
}
