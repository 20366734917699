import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {errorHandler, raise} from 'src/helpers/helpers.ts'
import {httpChangePassword} from 'src/http-requests/user.http'
import {Modal} from 'src/components/modal/Modal.tsx'
import {useUserStore} from '../../features/user/store'
import {InputText} from 'src/components/input-text/InputText.tsx'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalXCloseButton
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'

export const ChangePasswordModal = ({
    onClose,
    onPasswordChangedCb
}: {
    onClose: () => void
    onPasswordChangedCb: () => void
}) => {
    const {t} = useTranslation()
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const [isLoading, setIsLoading] = useState(false)

    const validationSchema = z
        .object({
            oldPassword: z.string({required_error: t('errors:required')}).min(1, t('errors:required')),
            newPassword: z.string({required_error: t('errors:required')}).min(8, t('errors:password_invalid_length')),
            confirmedNewPassword: z.string({required_error: t('errors:required')})
        })
        .superRefine((values, context) => {
            if (values.newPassword != values.confirmedNewPassword) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: t('errors:passwords_not_match'),
                    path: ['confirmedNewPassword']
                })
            }
        })

    const form = useForm({resolver: zodResolver(validationSchema)})
    const errors = form.formState.errors

    const handleSubmit = form.handleSubmit(async formValues => {
        const dataToSend = {
            current_password: formValues.oldPassword,
            new_password: formValues.newPassword,
            password_confirmation: formValues.confirmedNewPassword
        }

        try {
            setIsLoading(true)
            await httpChangePassword(accessToken, dataToSend)
            onPasswordChangedCb()
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    })

    return (
        <Modal onOverlayClick={onClose} width={480}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <ModalTitle>{t('settings:change_password')}</ModalTitle>
                <InputText
                    label={t('settings:old_password')}
                    type="password"
                    //TODO: remove toString after react hook form upgrade
                    errorMessage={errors.oldPassword?.message?.toString()}
                    {...form.register('oldPassword')}
                    visibilityToggle
                />
                <InputText
                    label={t('commons:new_password')}
                    type="password"
                    errorMessage={errors.newPassword?.message?.toString()}
                    {...form.register('newPassword')}
                    visibilityToggle
                />
                <InputText
                    label={t('commons:confirm_new_password')}
                    type="password"
                    errorMessage={errors.confirmedNewPassword?.message?.toString()}
                    {...form.register('confirmedNewPassword')}
                    visibilityToggle
                />
            </ModalBody>
            <ModalFooter>
                <Button variant="tertiary" onClick={onClose} disabled={isLoading}>
                    {t('commons:cancel')}
                </Button>
                <Button onClick={handleSubmit} disabled={isLoading}>
                    {t('commons:submit')}
                    {isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
