import {useTranslation} from 'react-i18next'
import {SelectUncontrolled} from '../../../../../../../components/select/SelectUncontrolled'
import PropTypes from 'prop-types'
import {retrieveValueForRs} from '../../../../../../../helpers/utils'
import {useDispatch, useSelector} from 'react-redux'
import {
    amenitiesAutocompleteSelector,
    selectImageAmenity,
    selectImageRoom,
    toggleImageUse
} from '../../../../../../../store/matterport'
import {useTheme} from 'styled-components'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import {StyledImageWrapper, StyledSelectCard, StyledSelects, StyledToggleSwitch} from './style'

export const SelectCard = ({image, hasSubmitError = false, setFullScreenImageCb, rooms = []}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const amenitiesAutocomplete = useSelector(amenitiesAutocompleteSelector).map(amenity => ({
        ...amenity,
        label: t(`admin:amenities:${amenity.label?.toLowerCase()}`)
    }))
    const selectedRoomObj = retrieveValueForRs(rooms, image.selectedRoom)
    const selectedAmenityObj = retrieveValueForRs(amenitiesAutocomplete, image.selectedAmenity)

    const onToggle = e => {
        dispatch(
            toggleImageUse({
                imageId: image.id,
                isChecked: e.target.checked,
                isOld: image.isOld
            })
        )
    }

    const onSelectRoom = selectedOption => {
        dispatch(
            selectImageRoom({
                imageId: image.id,
                selectedRoomId: selectedOption.value
            })
        )
    }

    const onSelectAmenity = selectedOption => {
        dispatch(
            selectImageAmenity({
                imageId: image.id,
                selectedAmenityId: selectedOption.value
            })
        )
    }

    return (
        <StyledSelectCard>
            <StyledToggleSwitch
                small
                label={image.isOld ? t('admin:recommend_to_delete') : t('admin:do_not_use_image')}
                isChecked={image.doNotUse}
                onChange={onToggle}
            />
            <StyledImageWrapper>
                <LazyLoadImage
                    src={image.url}
                    alt={image.name || '-'}
                    effect="blur"
                    threshold={100}
                    onClick={() => setFullScreenImageCb(image)}
                />
                <p>{image.name || '-'}</p>
            </StyledImageWrapper>
            <StyledSelects>
                <SelectUncontrolled
                    placeholder={t('admin:select_room')}
                    defaultValue={selectedRoomObj}
                    options={rooms}
                    onChangeCallback={onSelectRoom}
                    disabled={image.isOld || image.doNotUse}
                    controlCustomStyle={{
                        ...(hasSubmitError &&
                            !image.selectedRoom &&
                            !image.doNotUse && {
                                borderColor: theme.colors.red,
                                backgroundColor: theme.colors.lightRed,
                                color: theme.colors.red
                            })
                    }}
                />
                <SelectUncontrolled
                    placeholder={t('admin:select_amenity')}
                    defaultValue={selectedAmenityObj}
                    options={amenitiesAutocomplete}
                    onChangeCallback={onSelectAmenity}
                    disabled={image.isOld || image.doNotUse}
                    controlCustomStyle={{
                        ...(hasSubmitError &&
                            !image.selectedAmenity &&
                            !image.doNotUse && {
                                borderColor: theme.colors.red,
                                backgroundColor: theme.colors.lightRed,
                                color: theme.colors.red
                            })
                    }}
                />
            </StyledSelects>
        </StyledSelectCard>
    )
}

SelectCard.propTypes = {
    image: PropTypes.object,
    hasSubmitError: PropTypes.bool,
    setFullScreenImageCb: PropTypes.func,
    rooms: PropTypes.array
}
