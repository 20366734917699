import {Container} from 'src/components/container/Container'
import {AdminHostApprovalAccessibilityFeatures} from 'src/features/admin-host-approval/components/admin-host-approval-accessibility-features/AdminHostApprovalAccessibilityFeatures'
import {AdminHostApprovalTaskUploader} from 'src/features/admin-host-approval/components/admin-host-approval-task-uploader/AdminHostApprovalTaskUploader'
import {AdminHostApprovalTaskImagesPool} from 'src/features/admin-host-approval/components//admin-host-approval-task-images-pool/AdminHostApprovalTaskImagesPool'
import {Flexbox} from 'src/components/flexbox/Flexbox'

export const AdminHostApprovalNewTaskSection = ({taskId, hasImages}: {taskId: number; hasImages: boolean}) => {
    return (
        <Container fullHeight={false}>
            {hasImages ? (
                <Flexbox direction="column" gap={4}>
                    <AdminHostApprovalAccessibilityFeatures taskId={taskId} />
                    <AdminHostApprovalTaskImagesPool taskId={taskId} />
                </Flexbox>
            ) : (
                <AdminHostApprovalTaskUploader taskId={taskId} />
            )}
        </Container>
    )
}
