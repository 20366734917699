import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {alertSelector, hideAlert} from '../../store/appGenerics'
import {SnackbarStyled, InnerContent, Message, CloseIconStyled} from './style.ts'

export const Snackbar = () => {
    const alert = useSelector(alertSelector)
    const {show, message, level, timeout} = alert
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            // @ts-expect-error [TS2554] The arg that it wants (state) shouldn't be provided from here
            dispatch(hideAlert())
        }
    }, [dispatch])

    const closeAlert = useCallback(() => {
        // @ts-expect-error [TS2554] The arg that it wants (state) shouldn't be provided from here
        dispatch(hideAlert())
    }, [dispatch])

    useEffect(() => {
        if (show) {
            const alertTimeout = setTimeout(() => closeAlert(), timeout)

            return () => clearTimeout(alertTimeout)
        }
    }, [alert, show, timeout, closeAlert])

    return (
        <SnackbarStyled $show={show}>
            <InnerContent $level={level}>
                <Message>{message}</Message>
                <CloseIconStyled onClick={closeAlert} />
            </InnerContent>
        </SnackbarStyled>
    )
}
