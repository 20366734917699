import axios, {AxiosProgressEvent, RawAxiosRequestHeaders} from 'axios'
import {ResponseTypeParam} from 'src/types'
import {z} from 'zod'
import {AdminHostApprovalTask, AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types.ts'

/**
 * httpGetAdminHostApprovalTask
 * Get informations from a single host approval task
 */
type HttpGetAdminHostApprovalTask = {
    urlParams: {taskId: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpGetAdminHostApprovalTask = ({urlParams, params, headers}: HttpGetAdminHostApprovalTask) =>
    axios.get(`navigate/host-approval/${urlParams.taskId}`, {
        params,
        headers
    })

/**
 * httpGetAdminHostApprovalTask
 * Get informations from a single host approval task
 */
type HttpGetAdminHostApprovalTaskImagesOptions = {
    urlParams: {taskId: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpGetAdminHostApprovalTaskImages = ({
    urlParams,
    params,
    headers
}: HttpGetAdminHostApprovalTaskImagesOptions) =>
    axios.get(`navigate/host-approval/${urlParams.taskId}/images`, {
        params,
        headers
    })

/**
 * httpAdminHostApprovalTaskUpload
 * Upload photos in an host approval task
 */
export const HttpAdminHostApprovalTaskUploadPayload = z.array(z.instanceof(File))
export type HttpAdminHostApprovalTaskUploadPayload = z.infer<typeof HttpAdminHostApprovalTaskUploadPayload>

export type HttpUploadHostApprovalTaskImagesOptions = {
    adminAccessToken: string
    urlParams: {taskId: number}
    payload: FormData
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void | undefined
}

export const httpAdminHostApprovalTaskUpload = ({
    urlParams,
    payload,
    adminAccessToken,
    onUploadProgress
}: HttpUploadHostApprovalTaskImagesOptions) =>
    axios.post(`navigate/host-approval/${urlParams.taskId}/images`, payload, {
        onUploadProgress: progressEvent => {
            if (!onUploadProgress) return
            onUploadProgress(progressEvent)
        },
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${adminAccessToken}`}
    })

/**
 * httpGetAccessibilityFeatures
 * Get information from amenities autocomplete
 */
export const httpGetAccessibilityFeatures = () => axios.get(`/amenity`)

/**
 * Get accessibility types
 */
export const httpGetHostApprovalAccessibilityTypes = ({headers}: {headers: RawAxiosRequestHeaders}) =>
    axios.get('navigate/host-approval/types', {headers})

/**
 * httpGetRoomsAutocomplete
 * Get information from rooms autocomplete
 */
export const httpGetAdminHostApprovalTaskRooms = () => axios.get(`/rooms`)

/**
 * httpDeleteHostApprovalTaskImages
 * Delete photos in an host approval task
 */
export type HttpDeleteHostApprovalTaskImagesOptions = {
    headers: RawAxiosRequestHeaders
    urlParams: {taskId: number}
    payload: {
        image_ids: AdminHostApprovalTaskImage['id'][]
    }
    params: ResponseTypeParam
}
export const httpDeleteHostApprovalTaskImages = ({
    headers,
    urlParams,
    params,
    payload
}: HttpDeleteHostApprovalTaskImagesOptions) =>
    axios.delete(`navigate/host-approval/${urlParams.taskId}/images`, {
        headers,
        params: {...params, ...payload}
    })

/**
 * httpGetAdminHostApprovalTask
 * Updates photos in an host approval task
 */
export type HttpUpdateHostApprovalTaskImagesOptions = {
    headers: RawAxiosRequestHeaders
    urlParams: {taskId: number; imageId: number}
    payload: {
        room_id?: number
        amenity_id?: number
        from_photo_gallery?: number
        is_uploaded?: boolean
    }
}

export const httpUpdateHostApprovalTaskImages = ({
    headers,
    urlParams,
    payload
}: HttpUpdateHostApprovalTaskImagesOptions) =>
    axios.post(`navigate/host-approval/${urlParams.taskId}/images/${urlParams.imageId}`, {...payload}, {headers})

/**
 * httpUpdateHostApprovalTaskStatus
 * Send photos to approval flow
 */
export type HttpUpdateHostApprovalTaskStatusOptions = {
    headers: RawAxiosRequestHeaders
    urlParams: {taskId: number}
    payload: {
        accessibility_type_id?: number
        accessibility_project_id?: number
        host_id?: number
        listing_id?: number
        uploader_admin_id?: number
        reviewer_admin_id?: number
        publisher_admin_id?: number
        status?: AdminHostApprovalTask['status']
    }
    params: ResponseTypeParam
}

export const httpUpdateHostApprovalTaskStatus = ({
    headers,
    urlParams,
    payload,
    params
}: HttpUpdateHostApprovalTaskStatusOptions) =>
    axios.put(`navigate/host-approval/${urlParams.taskId}`, payload, {
        params,
        headers
    })

/**
 * httpHostApprovalTaskDownloadImages
 * Download images inside a zip folder
 */
export const httpHostApprovalTaskDownloadImages = ({
    params,
    headers
}: {
    params: {taskId: number}
    headers: RawAxiosRequestHeaders
}) => axios.get(`navigate/host-approval/${params.taskId}/images/download`, {headers, responseType: 'blob'})

/**
 * httpDeleteHostApprovalTask
 * Delete an host approval task
 */
export interface HttpDeleteHostApprovalTask {
    urlParams: {taskId: number}
    headers: RawAxiosRequestHeaders
}

export const httpDeleteHostApprovalTask = ({urlParams, headers}: HttpDeleteHostApprovalTask) =>
    axios.delete(`navigate/host-approval/${urlParams.taskId}`, {headers})

/*Get accessibility projects*/
export const httpGetAccessibilityProjects = ({headers}: {headers: RawAxiosRequestHeaders}) =>
    axios.get('navigate/host-approval/projects', {headers})

/*Get Host*/
interface HttpGetHostOptions {
    headers: RawAxiosRequestHeaders
    params: {host_id: string}
}
export const httpGetHost = ({headers, params}: HttpGetHostOptions) =>
    axios('navigate/host-approval/host-validation', {headers, params})

/*Get Listing*/
interface HttpGetListingOptions {
    headers: RawAxiosRequestHeaders
    params: {listing_id: string}
}
export const httpGetListing = ({headers, params}: HttpGetListingOptions) =>
    axios.get('navigate/host-approval/listing-validation', {headers, params})

/*Get status counter*/
export const httpGetAdminHostApprovalStatusesCounter = ({headers}: {headers: RawAxiosRequestHeaders}) =>
    axios.get('navigate/host-approval/count', {headers})

export interface HttpGetHostApprovalTasksOptions {
    headers: RawAxiosRequestHeaders
    params: ResponseTypeParam & {
        uploader_admin_id?: number
        reviewer_admin_id?: number
        publisher_admin_id?: number
        search?: string
        status: AdminHostApprovalTask['status']
        page: number
        limit: number
    }
}
export const httpGetHostApprovalTasks = ({headers, params}: HttpGetHostApprovalTasksOptions) =>
    axios.get('navigate/host-approval', {headers, params})

/*Create admin task*/
export interface HttpCreateAdminTaskOptions {
    headers: RawAxiosRequestHeaders
    payload: {
        accessibility_type_id: number
        accessibility_project_id: number
        host_id: string
        listing_id: string
        uploader_admin_id: number
    }
}
export const httpCreateAdminTask = ({headers, payload}: HttpCreateAdminTaskOptions) =>
    axios.post(`navigate/host-approval`, payload, {headers})

/*Update admin task*/
export interface HttpUpdateAdminTaskOptions {
    headers: RawAxiosRequestHeaders
    urlParams: {
        taskId: number
    }
    payload: {
        accessibility_type_id?: number
        accessibility_project_id?: number
        host_id?: string
        listing_id?: string
        uploader_admin_id?: number
        reviewer_admin_id?: number
    }
}
export const httpUpdateHostApprovalTask = ({headers, payload, urlParams}: HttpUpdateAdminTaskOptions) =>
    axios.put(`navigate/host-approval/${urlParams.taskId}`, payload, {headers})
