import {AssignmentCardWrapper} from 'src/features/assignment/components/assignment-card-atoms/AssignmentCardAtoms'
import {AssignmentCompensationCard} from 'src/features/assignment/components/assignment-compensation-card/AssignmentCompensationCard'
import {
    StyledAssignmentContent,
    StyledAssignmentScheduledOverview
} from 'src/features/assignment/components/assignment-content/style'
import {AssignmentHostCard} from 'src/features/assignment/components/assignment-host-card/AssignmentHostCard'
import {AssignmentNotesCard} from 'src/features/assignment/components/assignment-notes-card/AssignmentNotesCard'
import {AssignmentScheduledDate} from 'src/features/assignment/components/assignment-scheduled-date/AssignmentScheduledDate'
import {Assignment} from 'src/features/assignment/types'

export const AssignmentWalkthroughCards = ({assignment}: {assignment: Assignment}) => {
    return (
        <StyledAssignmentContent>
            {assignment.status != 'pending' && (
                <StyledAssignmentScheduledOverview direction="column" gap={6}>
                    {assignment.task_status.status == 'pending_assets' && (
                        <AssignmentCardWrapper>
                            <AssignmentScheduledDate date={assignment.to_shoot_at} />
                        </AssignmentCardWrapper>
                    )}
                    <AssignmentNotesCard assignment={assignment} />
                </StyledAssignmentScheduledOverview>
            )}

            <StyledAssignmentScheduledOverview direction="column" gap={6}>
                <AssignmentCompensationCard assignment={assignment} />
                {assignment.status != 'pending' && <AssignmentHostCard />}
            </StyledAssignmentScheduledOverview>
        </StyledAssignmentContent>
    )
}
