import styled, {keyframes, css} from 'styled-components'
import {SpinnerProps} from 'src/components/spinner-legacy/Spinner.tsx'

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

export const Overlay = styled.div<{$overlayFullscreen: SpinnerProps['overlayFullscreen']}>`
    ${({$overlayFullscreen}) => css`
        z-index: 13;
        position: ${$overlayFullscreen ? 'fixed' : 'absolute'};
        inset: 0;
        background: rgba(255, 255, 255, 0.8);
    `}
`

export const SpinnerWrapper = styled.div<{
    $overlayFullscreen: SpinnerProps['overlayFullscreen']
    $center: SpinnerProps['center']
}>`
    ${({theme: {spacing}, $overlayFullscreen, $center}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: ${spacing * 4}px;
        z-index: 14;
        ${$center &&
        css`
            position: ${$overlayFullscreen ? 'fixed' : 'absolute'};
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        `}
    `}
`

export const SpinnerStyled = styled.svg<{$size: SpinnerProps['size']}>`
    ${({$size}) => css`
        width: ${$size}px;
        height: auto;
        animation: ${rotate} 2s linear infinite;
        & circle {
            animation: ${dash} 1.5s ease-in-out infinite;
        }
    `}
`

export const ProgressBar = styled.div<{$progress: SpinnerProps['progress']}>`
    ${({theme: {colors, typography, palette}, $progress}) => css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 240px;
        border-radius: 12px;
        box-shadow: 0 0 0 2px ${colors.airbnbRed} inset;

        p {
            position: relative;
            z-index: 2;
            ${typography.textLg};
            font-weight: 700;
            color: ${palette.neutral.black};
        }

        & > div {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            top: 0;
            bottom: 0;
            left: 0;
            width: ${$progress}%;
            min-width: 24px;
            border-radius: 12px;
            background: ${colors.airbnbRed};
        }
    `}
`
