import {Modal} from 'src/components/modal/Modal.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {StyledModalTitle} from 'src/components/modal-atoms/style.ts'
import {
    StyledButton,
    StyledButtonsWrapper,
    StyledHelperText,
    StyledLoadingOverlay,
    StyledModalBody,
    StyledModalFooter
} from './style.ts'
import dayjs from 'dayjs'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {useAsync} from 'src/hooks/useAsync.ts'
import {httpSendRating} from 'src/http-requests/user.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {errorHandler, raise} from 'src/helpers/helpers.ts'
import {ModalHeader, ModalTitle, ModalXCloseButton} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {FC, ReactElement} from 'react'
import {RATING_MODAL} from 'src/helpers/constants.ts'

export interface FeaturesRatingModalProps {
    heightValueLabel: string
    lowValueLabel: string
    mainQuestion: string
    modalCompletedTitle: string
    modalIcon: ReactElement
    onClose: () => void
    type: 'payment' | 'new-assignment'
}

export const FeaturesRatingModal: FC<FeaturesRatingModalProps> = ({
    heightValueLabel,
    lowValueLabel,
    mainQuestion,
    modalCompletedTitle,
    modalIcon,
    onClose,
    type
}) => {
    const {t} = useTranslation()
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const sendRating = useAsync()

    const sendRatingHandler = (rating: number) => {
        try {
            sendRating.run(httpSendRating(accessToken, rating, type))
            switch (type) {
                case 'new-assignment':
                    localStorage.removeItem(RATING_MODAL.NEW_ASSIGNMENT_RATING_CLOSED_TIME_LS)
                    localStorage.setItem(RATING_MODAL.NEW_ASSIGNMENT_RATING_DONE, dayjs().toISOString())
                    break
                default:
                    localStorage.removeItem(RATING_MODAL.TRANSACTION_RATING_CLOSED_TIME_LS)
                    localStorage.setItem(RATING_MODAL.TRANSACTION_RATING_DONE, dayjs().toISOString())
            }
        } catch (error) {
            errorHandler(error)
        }
    }

    const resetAskLaterTimestamp = () => {
        switch (type) {
            case 'new-assignment':
                localStorage.setItem(RATING_MODAL.NEW_ASSIGNMENT_RATING_CLOSED_TIME_LS, dayjs().toISOString())
                break
            default:
                localStorage.setItem(RATING_MODAL.TRANSACTION_RATING_CLOSED_TIME_LS, dayjs().toISOString())
        }
        onClose()
    }

    return (
        <Modal onOverlayClick={onClose} width={474}>
            {sendRating.isSuccess ? (
                <>
                    <ModalHeader>
                        <ModalXCloseButton onClick={onClose} />
                    </ModalHeader>
                    <StyledModalBody>
                        {modalIcon}
                        <ModalTitle>{modalCompletedTitle}</ModalTitle>
                    </StyledModalBody>
                </>
            ) : (
                <>
                    <StyledModalBody>
                        {modalIcon}
                        <StyledModalTitle>{mainQuestion}</StyledModalTitle>
                        <StyledButtonsWrapper>
                            <Flexbox direction="column" align="center" gap={1}>
                                <StyledButton disabled={sendRating.isLoading} onClick={() => sendRatingHandler(1)}>
                                    1
                                </StyledButton>
                                <StyledHelperText>{lowValueLabel}</StyledHelperText>
                            </Flexbox>
                            <StyledButton disabled={sendRating.isLoading} onClick={() => sendRatingHandler(2)}>
                                2
                            </StyledButton>
                            <StyledButton disabled={sendRating.isLoading} onClick={() => sendRatingHandler(3)}>
                                3
                            </StyledButton>
                            <StyledButton disabled={sendRating.isLoading} onClick={() => sendRatingHandler(4)}>
                                4
                            </StyledButton>
                            <Flexbox direction="column" align="center" gap={1}>
                                <StyledButton disabled={sendRating.isLoading} onClick={() => sendRatingHandler(5)}>
                                    5
                                </StyledButton>
                                <StyledHelperText>{heightValueLabel}</StyledHelperText>
                            </Flexbox>
                        </StyledButtonsWrapper>
                    </StyledModalBody>
                    <StyledModalFooter>
                        <Button disabled={sendRating.isLoading} variant="tertiary" onClick={resetAskLaterTimestamp}>
                            {t('consent_collector:ask_later')}
                        </Button>
                    </StyledModalFooter>
                    {sendRating.isLoading && (
                        <StyledLoadingOverlay>
                            <Spinner size={32} />
                        </StyledLoadingOverlay>
                    )}
                </>
            )}
        </Modal>
    )
}

FeaturesRatingModal.displayName = 'FeaturesRatingModal'
