import {FC, HTMLAttributes} from 'react'

export const BellIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 20, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" {...rest}>
        <path
            d="M18.5972 9.31667C18.5972 7.70769 17.9504 6.16461 16.7992 5.02689C15.6479 3.88916 14.0865 3.25 12.4583 3.25C10.8302 3.25 9.26875 3.88916 8.11748 5.02689C6.96622 6.16461 6.31944 7.70769 6.31944 9.31667C6.31944 16.3944 3.25 18.4167 3.25 18.4167H21.6667C21.6667 18.4167 18.5972 16.3944 18.5972 9.31667Z"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.0833 22.75C13.9182 23.0795 13.6812 23.353 13.396 23.5431C13.1108 23.7333 12.7875 23.8333 12.4583 23.8333C12.1292 23.8333 11.8059 23.7333 11.5207 23.5431C11.2355 23.353 10.9985 23.0795 10.8333 22.75"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
