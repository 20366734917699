import {StyledNoResults, StyledPaginatedTbody, StyledTbody} from './style'
import {Row} from '../row/TableMobileRow'
import {useTranslation} from 'react-i18next'
import {TableMobileLoader} from '../loader/TableMobileLoader'
import {ErrorMessage} from '../../error-message/ErrorMessage'

export const PaginatedBody = ({
    columns,
    expandedRowsIndexes,
    columnsRenderers,
    onClickRow,
    data,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    isError,
    onChangePageCb,
    tbodyHeight,
    onRefresh
}) => {
    const {t} = useTranslation()

    if (isError) {
        return (
            <StyledTbody $tbodyHeight={tbodyHeight}>
                <ErrorMessage onRefresh={onRefresh} />
            </StyledTbody>
        )
    }
    if (isLoading || isRefetching) {
        return (
            <StyledTbody $tbodyHeight={tbodyHeight}>
                <TableMobileLoader columns={columns} />
            </StyledTbody>
        )
    }
    if (data.length === 0) {
        return <StyledNoResults $tbodyHeight={tbodyHeight}>{t('commons:no_results')}</StyledNoResults>
    }

    return (
        <StyledPaginatedTbody
            $tbodyHeight={tbodyHeight}
            data={data}
            endReached={onChangePageCb}
            overscan={100}
            itemContent={itemIndex => {
                const item = data[itemIndex]
                const clickableRowPath = onClickRow(item)

                return (
                    <Row
                        columns={columns}
                        expandedRowsIndexes={expandedRowsIndexes}
                        itemIndex={itemIndex}
                        item={item}
                        columnsRenderers={columnsRenderers}
                        clickableRowPath={clickableRowPath}
                    />
                )
            }}
            components={{...(isFetchingNextPage && {Footer: () => <TableMobileLoader />})}}
        />
    )
}
