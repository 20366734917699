import {useURLParsedParams} from 'src/hooks/useURLParsedParams'
import {useAdminHostApprovalTask} from 'src/features/admin-host-approval/services/useAdminHostApprovalTask'
import {Container} from 'src/components/container/Container'
import {Spinner} from 'src/components/spinner/Spinner'
import {AxiosError} from 'axios'
import {ErrorBox} from 'src/components/error-box/ErrorBox'
import {AlertTriangleIcon} from 'src/components/icon'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {AdminHostApprovalTaskHero} from 'src/features/admin-host-approval/components/admin-host-approval-task-hero/AdminHostApprovalTaskHero'
import {AdminHostApprovalNewTaskSection} from 'src/features/admin-host-approval/components/admin-host-approval-new-task-section/AdminHostApprovalNewTaskSection'
import {
    StyledAdminHostApprovalTask,
    StyledAdminHostApprovalTaskLoadingContainer
} from 'src/pages/admin-host-approval-task/style'
import {useNavigate} from 'react-router'
import {ROUTES} from 'src/helpers/constants'
import {z} from 'zod'
import {useAdminHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useAdminHostApprovalTaskImages'
import {AdminHostApprovalReadyForReviewTaskSection} from 'src/features/admin-host-approval/components/admin-host-approval-ready-for-review-section/AdminHostApprovalReadyForReviewSection'

export const AdminHostApprovalTask = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(z.object({taskId: z.coerce.number()}))
    const navigate = useNavigate()
    const {
        data: adminHostApprovalTask,
        isLoading,
        error
    } = useAdminHostApprovalTask(urlParams.data?.taskId, {
        enabled: urlParams.success
    })

    const {
        data: taskImages,
        isLoading: isLoadingTaskImages,
        isError: isErrorTaskImages
    } = useAdminHostApprovalTaskImages(urlParams.data?.taskId, {
        enabled: urlParams.success
    })

    if (isLoading || isLoadingTaskImages) {
        return (
            <StyledAdminHostApprovalTaskLoadingContainer fullHeight fullWidth>
                <Spinner size={40} />
            </StyledAdminHostApprovalTaskLoadingContainer>
        )
    }
    if (urlParams.error || (error instanceof AxiosError && error.response?.status == 404)) {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('host_approval_task:not_found')} />
            </Container>
        )
    }
    if (error || isErrorTaskImages || !adminHostApprovalTask) {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
            </Container>
        )
    }
    if (adminHostApprovalTask.status == 'pending_host_approval') {
        navigate(ROUTES.ADMIN_HOST_APPROVAL_PENDING_HOST_APPROVAL, {
            replace: true
        })
    }
    if (adminHostApprovalTask.status == 'completed') {
        navigate(ROUTES.ADMIN_HOST_APPROVAL_COMPLETED, {
            replace: true
        })
    }

    return (
        <StyledAdminHostApprovalTask>
            {adminHostApprovalTask.entity && (
                <Helmet
                    title={t('host_approval_task:meta_title', {
                        title:
                            adminHostApprovalTask.entity.title ??
                            t('commons:listing_n_x', {externalId: adminHostApprovalTask.external_id})
                    })}
                />
            )}

            <AdminHostApprovalTaskHero adminHostApprovalTask={adminHostApprovalTask} />

            {adminHostApprovalTask.status == 'new' && (
                <AdminHostApprovalNewTaskSection taskId={urlParams.data.taskId} hasImages={!!taskImages?.length} />
            )}

            {adminHostApprovalTask.status == 'ready_for_review' && (
                <AdminHostApprovalReadyForReviewTaskSection taskId={urlParams.data.taskId} />
            )}
        </StyledAdminHostApprovalTask>
    )
}
