import styled, {css} from 'styled-components'
import {CloseIcon} from 'src/theme/icons.tsx'

export const StyledUploader = styled.div<{$pageHeight: number}>(
    ({theme: {headerHeight}, $pageHeight}) => css`
        min-height: ${$pageHeight - headerHeight}px;
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing}}) => css`
        & > * {
            margin-bottom: ${spacing * 10}px;
        }
    `
)

export const StyledFullscreenImage = styled.div(
    ({theme: {zIndex}}) => css`
        z-index: ${zIndex.fullscreenImage};
        position: fixed;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
            object-fit: contain;
            max-width: calc(100% - 32px);
            max-height: calc(100% - 32px);
            position: relative;
            z-index: ${zIndex.calendarOffRange};
        }
    `
)

export const StyledFullScreenOverlay = styled.div(
    ({theme: {palette}}) => css`
        position: absolute;
        inset: 0;
        background: ${palette.neutral.black}CC;
    `
)

export const StyledCloseIcon = styled(CloseIcon)(
    ({theme: {spacing, palette}}) => css`
        z-index: 4;
        position: absolute;
        top: ${spacing * 4}px;
        right: ${spacing * 4}px;
        width: 32px;
        fill: ${palette.neutral.white};
        cursor: pointer;
    `
)
