import {AvatarFallback, AvatarImage, AvatarProps as RadixAvatarProps} from '@radix-ui/react-avatar'
import {StyledAvatar} from 'src/components/avatar/style.ts'

interface AvatarProps extends RadixAvatarProps {
    name: string
    imageUrl: string
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

const getInitials = (name: string): string => {
    const initials = name.match(/\b\w/g)
    if (initials) {
        return initials.length <= 2 ? initials.join('') : `${initials[0]}${initials.at(-1)}`
    }

    return ''
}

export const Avatar = ({name, imageUrl, size = 'md', ...props}: AvatarProps) => (
    <StyledAvatar $size={size} {...props}>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback delayMs={600}>{getInitials(name)}</AvatarFallback>
    </StyledAvatar>
)

Avatar.displayName = 'Avatar'
