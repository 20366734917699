import {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import {FormCard} from '../../../components/form-card/FormCard'
import {ROUTES} from '../../../helpers/constants'
import {PageHelmet} from '../../../components/page-helmet/PageHelmet'
import {SuccessModal} from '../../../components/success-modal/SuccessModal'
import {useNavigate, useParams} from 'react-router'
import {httpCheckAccountCreationToken, httpCreateAccount} from '../../../http-requests/auth.http'
import {Spinner} from '../../../components/spinner-legacy/Spinner'
import {errorHandler} from '../../../helpers/utils'
import {StyledCreateAccount, StyledCreateAccountContainer} from './style'

export const CreateAccount = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {token} = useParams()
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isValidToken, setIsValidToken] = useState(false)

    const formFields = [
        {name: 'password', type: 'password', label: t('commons:new_password')},
        {
            name: 'password_confirmation',
            type: 'password',
            label: t('commons:confirm_new_password')
        }
    ]

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t('errors:required')).min(8, t('errors:password_invalid_length')),
        password_confirmation: Yup.string()
            .required(t('errors:required'))
            .oneOf([Yup.ref('password')], t('errors:passwords_not_match'))
    })

    useEffect(() => {
        checkToken()
    }, [])

    const setShowSuccessModalCb = useCallback(() => setShowSuccessModal(!showSuccessModal), [showSuccessModal])

    const checkToken = async () => {
        try {
            setIsLoading(true)
            await httpCheckAccountCreationToken(token)
            setIsValidToken(true)
        } catch (e) {
            errorHandler(e)
            if (e?.response?.data?.message === 'invalid_token') navigate(ROUTES.PAGE_NOT_FOUND)
        } finally {
            setIsLoading(false)
        }
    }

    const onSubmit = async formValues => {
        try {
            setIsLoading(true)
            await httpCreateAccount(token, {...formValues})
            setShowSuccessModal(true)
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <StyledCreateAccount>
            <PageHelmet title={t('titles:account_creation')} />

            {showSuccessModal && (
                <SuccessModal
                    onClose={setShowSuccessModalCb}
                    callback={() => navigate(ROUTES.LOGIN)}
                    buttonLabel={t('auth:login')}
                    paragraph="commons:password_updated"
                />
            )}

            <StyledCreateAccountContainer>
                {isValidToken ? (
                    <FormCard
                        title={t('auth:account_creation')}
                        subtitle={t('auth:enter_password')}
                        buttonText={t('commons:submit')}
                        linkText={t('auth:login')}
                        fields={formFields}
                        validationSchema={validationSchema}
                        onSubmitCb={onSubmit}
                        isLoading={isLoading}
                    />
                ) : (
                    <Spinner />
                )}
            </StyledCreateAccountContainer>
        </StyledCreateAccount>
    )
}
