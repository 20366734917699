import {InputText} from 'src/components/input-text/InputText.tsx'
import {useTranslation} from 'react-i18next'
import {Controller, useFormContext, useWatch} from 'react-hook-form'
import {CreateTaskValidationSchema} from 'src/features/admin-host-approval/components/create-task-modal/CreateTaskModal.tsx'
import {debounce, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {ChangeEvent, ReactNode, useMemo, useState} from 'react'
import {httpGetHost} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {useAsync} from 'src/hooks/useAsync.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {StyledLink} from 'src/features/admin-host-approval/components/create-task-modal/host-id-field/style.ts'

export const HostIdField = () => {
    const {t} = useTranslation()
    const [fetchedHost, setFetchedHost] = useState<{first_name: string; id: number} | null>(null)
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')
    const form = useFormContext<CreateTaskValidationSchema>()
    const getHost = useAsync()

    const formHostId = useWatch({control: form.control, name: 'host_id'})

    const onChange = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
        const hostId = event.target.value
        form.setValue('host_id', hostId)
        void form.trigger('host_id')

        if (!hostId) {
            setFetchedHost(null)
            return
        }
        try {
            const response = await getHost.run(
                httpGetHost({
                    headers: makeHttpAuthorizationHeader(adminAccessToken),
                    params: {host_id: hostId}
                })
            )
            setFetchedHost(response.data)
        } catch (error) {
            return
        }
    }, 1000)

    const getHostStatusToHelpText = useMemo(
        () =>
            ({
                idle: undefined,
                loading: <Spinner size={14} />,
                error: formHostId ? (
                    <Flexbox justify="space-between" fullWidth>
                        <span>{t('admin_host_approval:host_id_field_not_found')}</span>
                        <StyledLink href={`https://admin.airbnb.com/users/show/${formHostId}`} target="_blank">
                            {t('admin_host_approval:open_in_airbnb_admin')}
                        </StyledLink>
                    </Flexbox>
                ) : undefined,
                success: fetchedHost?.first_name
            } as const satisfies Record<ReturnType<typeof useAsync>['status'], ReactNode>),
        [fetchedHost, formHostId]
    )

    return (
        <Controller
            control={form.control}
            name="host_id"
            render={() => (
                <InputText
                    type="text"
                    inputSize="sm"
                    placeholder={t('admin_host_approval:host_id_field_placeholder')}
                    label={t('admin_host_approval:host_id_field_label')}
                    errorMessage={form.formState.errors.host_id?.message}
                    helpText={getHostStatusToHelpText[getHost.status]}
                    {...form.register('host_id')}
                    onChange={onChange}
                />
            )}
        />
    )
}
