import React from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import {AssignmentCard} from '../../../../../components/assignments/assignment-card/AssignmentCard'
import {Chip} from '../../../../../components/chip/Chip'
import {Table} from '../../../../../components/table-legacy/Table'

import {postProductionStatusToLabel} from '../../../../../helpers/statuses'
import {formatLocaleDate} from '../../../../../helpers/utils'
import {QUOTE_LOGS_STATUSES} from '../../../../../helpers/constants'
import {useAdminQuoteLogs} from '../../../../../hooks'
import {getAssignmentColumns} from '../columns'

import {StyledAssignmentCardDetailsItem, StyledDetailCell, StyledNoResults} from '../style'
import {ButtonLink} from '../../../../../components/button-link/ButtonLink'

export const PostProductionCard = ({quoteData, selectedQuoteID}) => {
    const {t} = useTranslation()
    const quoteLogsQuery = useAdminQuoteLogs(selectedQuoteID, QUOTE_LOGS_STATUSES.POST_PRODUCTION)

    return (
        <AssignmentCard
            title={
                <>
                    <h3>{t('admin:assignments:post_production:title')}</h3>
                    {!!quoteData?.task_status?.post_prod_status && (
                        <Chip
                            text={t(postProductionStatusToLabel[quoteData?.task_status?.post_prod_status])}
                            rounded
                            size="medium"
                        />
                    )}
                </>
            }
            statusUpdatedAt={formatLocaleDate(quoteData?.task?.updated_at, 'LLL')}
            actions={
                <>
                    {quoteData?.task?.airtable_link && (
                        <ButtonLink to={quoteData?.task?.airtable_link} target="_blank" variant="tertiaryPrimary">
                            {t('admin:assignments:post_production:airtable_task_button')}
                        </ButtonLink>
                    )}
                    {quoteData?.daryl_link && (
                        <ButtonLink
                            to={`https://airbnb.orangelogic.com/asset-management/${quoteData?.task?.daryl_directory_id}?Flat=y&WS=AssetManagement`}
                            target="_blank"
                            variant="tertiaryPrimary"
                        >
                            {t('admin:assignments:post_production:daryl_folder_button')}
                        </ButtonLink>
                    )}
                </>
            }
            details={
                <div>
                    <StyledAssignmentCardDetailsItem>
                        <span>{t('admin:assignments:post_production:retouching_vendor')}</span>
                        <p>{quoteData?.assignments?.[0]?.retoucher || '-'}</p>
                    </StyledAssignmentCardDetailsItem>
                </div>
            }
            tableTitle={t('admin:assignments:post_production:table_title')}
            table={
                quoteLogsQuery.remappedData.length >= 1 || quoteLogsQuery.isFetching ? (
                    <Table
                        columns={getAssignmentColumns()}
                        data={quoteLogsQuery.remappedData}
                        tbodyHeight="224px"
                        isPaginated
                        isLoading={quoteLogsQuery.isLoading}
                        isRefetching={quoteLogsQuery.isRefetching}
                        isFetchingNextPage={quoteLogsQuery.isFetchingNextPage}
                        onChangePageCb={quoteLogsQuery.onChangePage}
                        page={quoteLogsQuery.page}
                        variant="grey"
                        columnsRenderers={{
                            date: item => <span>{formatLocaleDate(item?.updated_at, 'lll')}</span>,
                            status: item => (
                                <Chip
                                    bold
                                    text={
                                        item?.current_status?.post_prod_status
                                            ? t([postProductionStatusToLabel[item.current_status.post_prod_status]])
                                            : '-'
                                    }
                                    rounded
                                    size="medium"
                                />
                            ),
                            details: item => <StyledDetailCell>{item?.details || '-'}</StyledDetailCell>
                        }}
                    />
                ) : (
                    <StyledNoResults>{t('admin:no_history')}</StyledNoResults>
                )
            }
        />
    )
}

PostProductionCard.propTypes = {
    quoteData: PropTypes.shape({
        task: PropTypes.shape({
            updated_at: PropTypes.string
        }),
        retouching_vendor: PropTypes.string,
        statusUpdatedAt: PropTypes.string,
        status: PropTypes.string,
        daryl_link: PropTypes.string,
        assignments: PropTypes.arrayOf(PropTypes.object),
        task_status: PropTypes.shape({
            post_prod_status: PropTypes.string,
            updated_at: PropTypes.string
        })
    }),
    selectedQuoteID: PropTypes.number
}
