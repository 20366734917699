import axios, {RawAxiosRequestHeaders} from 'axios'

export const httpGetAdmins = ({headers}: {headers: RawAxiosRequestHeaders}) => axios.get('/admin', {headers})

export interface HttpForceMatchOptions {
    urlParams: {
        quoteId: number
    }
    payload: {
        photographer_id: number
        ignore_photographer_market: boolean
        ignore_photographer_previous_rejection: boolean
        set_a_manual_rate: boolean
        rate: number
    }
    headers: RawAxiosRequestHeaders
}
export const httpForceMatch = ({urlParams, headers, payload}: HttpForceMatchOptions) =>
    axios.post(`/admin/quotes/${urlParams.quoteId}/navigate-forced-matches`, payload, {headers})

export interface HttpUpdateForcedMatchOptions {
    urlParams: {
        quoteId: number
        forcedMatchId: number
    }
    payload: {
        status: 'inactive'
    }
    headers: RawAxiosRequestHeaders
}
export const httpUpdateForcedMatch = ({urlParams, payload, headers}: HttpUpdateForcedMatchOptions) =>
    axios.patch(`/admin/quotes/${urlParams.quoteId}/navigate-forced-matches/${urlParams.forcedMatchId}`, payload, {
        headers
    })
