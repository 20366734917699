import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_ACTION_KEYS, QUERY_KEYS} from 'src/queryClient.ts'
import {httpUnscheduleAssignment} from 'src/features/assignment/services/assignment.http.ts'
import {Assignment} from 'src/features/assignment/types.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'

export const useUnscheduleAssignment = ({
    assignmentId,
    options
}: {
    assignmentId: Assignment['id']
    options?: UseMutationOptions<Assignment>
}) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UNSCHEDULE_ASSIGNMENT],
        mutationFn: () =>
            parseAxiosPromise({
                axiosPromise: httpUnscheduleAssignment({
                    urlParams: {assignmentId},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            invalidationSignal(QUERY_ACTION_KEYS.ASSIGNMENT_CHANGE_COORDINATION_STATUS)
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.ASSIGNMENT, data.code]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
