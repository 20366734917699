import styled, {css} from 'styled-components'

export const StyledAccordionWrapper = styled.div(
    ({theme: {colors}}) => css`
        border-bottom: 1px solid ${colors.gainsboroGrey};
    `
)

export const StyledAccordionHead = styled.div(
    ({theme: {spacing, palette, mediaQueries, typography}}) => css`
        padding: ${spacing * 3}px ${spacing * 5}px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        hgroup {
            display: flex;
            flex-direction: column;
            gap: ${spacing}px;

            h3 {
                ${typography.textMd};
                font-weight: 500;
            }

            h5 {
                ${typography.textSm};
                font-weight: 400;
                color: ${palette.neutral['500']};
            }
        }

        ${mediaQueries.s} {
            padding: ${spacing * 3}px ${spacing * 2}px;
            hgroup {
                h3 {
                    ${typography.textSm};
                }
                h5 {
                    ${typography.textXs};
                }
            }
        }
    `
)
