import {forwardRef, HTMLAttributes} from 'react'
import {StyledRadio, StyledInput, StyledSmall, StyledTextContainer, StyledLabel, StyledHelpText} from './style'

interface RadioProps extends HTMLAttributes<HTMLInputElement> {
    name?: string
    className?: string
    label?: string
    helperText?: string
    disabled?: boolean
    errors?: {message: string}
    checked?: boolean
    fillRadioImg?: boolean
    fullWidthRadio?: boolean
    isSmall?: boolean
}

/**
 * @deprecated use the new Radio instead
 * */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
    (
        {
            className,
            label,
            helperText,
            disabled,
            errors,
            checked,
            fillRadioImg = false,
            fullWidthRadio = false,
            isSmall,
            ...props
        },
        ref
    ) => {
        return (
            <StyledRadio id={props.name} className={className} $checked={!!checked} $fullWidthRadio={fullWidthRadio}>
                <StyledTextContainer>
                    {!!label && <StyledLabel $disabled={!!disabled}>{label}</StyledLabel>}
                    {!!helperText && <StyledHelpText>{helperText}</StyledHelpText>}
                </StyledTextContainer>
                <StyledInput
                    type="radio"
                    disabled={disabled}
                    ref={ref}
                    checked={checked}
                    $fillRadioImg={fillRadioImg}
                    $isSmall={!!isSmall}
                    {...props}
                />
                {!!errors && !disabled && <StyledSmall>{errors.message}</StyledSmall>}
            </StyledRadio>
        )
    }
)

Radio.displayName = 'Radio'
