import {httpSubmitLog} from '../../../http-requests/chatbot.http'
import {chatHighlights} from '../helpers'
import {useEffect} from 'react'
import {errorHandler} from '../../../helpers/utils'
import {useSelector} from 'react-redux'
import {chatbotHistorySelector, chatbotShootSelector, sessionIdSelector} from '../../../store/chatbot'
import {useUserStore} from '../../../features/user/store'

export const SubmitLog = () => {
    const accessToken = useUserStore(store => store.accessToken)
    const sessionId = useSelector(sessionIdSelector)
    const selectedShoot = useSelector(chatbotShootSelector)
    const chatHistory = useSelector(chatbotHistorySelector)
    const flow = chatHistory.map(({id}) => id).join(', ') // whole conversation (ids)
    const recap = chatHistory.map(({message}) => message).join(' --- ') //whole conversation (messages)
    const highlights = chatHighlights(chatHistory) // A string containing the highlights of the session: all actions-cell taken via API calls
    const isTicketOpen = flow.includes('ticket') // A boolean indicating if a ticket has been opened in this session or not

    useEffect(() => {
        onSubmitLogResults()
    }, [])

    const onSubmitLogResults = async () => {
        try {
            await httpSubmitLog(accessToken, {
                entity_id: selectedShoot?.quote?.entity_id,
                task_media_id: selectedShoot?.quote?.task_media_id,
                flow,
                recap,
                highlights,
                ticket: isTicketOpen,
                thread_id: sessionId
            })
        } catch (e) {
            errorHandler(e)
        }
    }

    return <div />
}
