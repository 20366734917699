import {Badge} from 'src/components/badge/Badge'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalAccessibilityFeatures = styled.div(
    ({theme: {palette, spacing}}) => css`
        background-color: ${palette.neutral[50]};
        padding: ${spacing * 2}px ${spacing * 6}px;
        border-radius: 8px;
        width: 100%;
    `
)

export const StyledAdminHostApprovalAccessibilityTitle = styled.h3`
    font-weight: 600;
`

export const StyledAdminHostApprovalAccessibilityBody = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: ${spacing * 4}px;
        padding: ${spacing * 2}px;

        ${mediaQueries.l} {
            grid-template-columns: repeat(3, 1fr);
        }

        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)

export const StyledAccessibilityFeatureCounter = styled(Flexbox)<{$isHighlight: boolean}>(
    ({theme: {palette, typography, spacing}, $isHighlight}) => css`
        background-color: ${$isHighlight ? palette.neutral.white : palette.neutral['100']};
        color: ${$isHighlight ? palette.neutral[900] : palette.neutral[600]};
        border: 1px solid ${palette.neutral[200]};
        padding: ${spacing * 2}px;
        overflow: hidden;
        font-weight: 500;
        border-radius: 8px;

        & > p {
            ${typography.textSm};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)

export const StyledAccessibilityFeatureCounterBadge = styled(Badge)(
    ({theme: {typography}}) => css`
        ${typography.textXs};
        font-weight: 700;
    `
)
