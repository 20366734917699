import PropTypes, {shape} from 'prop-types'

import {TableHeader} from './header/TableHeader'
import {NotPaginatedBody} from './body/NotPaginatedBody'
import {PaginatedBody} from './body/PaginatedBody'

import {TABLE_ALIGNMENTS} from '../../../helpers/constants'

export const DesktopTable = ({
    isLoading = false,
    isRefetching = false,
    isFetchingNextPage = false,
    isError,
    alternatingRowColors = false,
    tbodyHeight = 'auto',
    columns = [],
    data = [],
    isPaginated,
    onChangePageCb,
    onRefresh,
    page,
    sortColumnCb,
    sorter,
    columnsRenderers,
    onClickRow = () => null,
    expandedRowsIndexes = [],
    variant
}) => {
    const bodyProps = {
        columns,
        columnsRenderers,
        isLoading,
        isRefetching,
        isFetchingNextPage,
        isError,
        tbodyHeight,
        onClickRow,
        onRefresh,
        expandedRowsIndexes,
        alternatingRowColors,
        data,
        page,
        onChangePageCb,
        variant
    }

    return (
        <div>
            <TableHeader columns={columns} sorter={sorter} sortColumnCb={sortColumnCb} variant={variant} />
            {isPaginated ? <PaginatedBody {...bodyProps} /> : <NotPaginatedBody {...bodyProps} />}
        </div>
    )
}

DesktopTable.propTypes = {
    alternatingRowColors: PropTypes.bool,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({width}, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            }
        })
    ).isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    isError: PropTypes.bool,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    footerComponent: PropTypes.element,
    headerComponent: PropTypes.element,
    isLoading: PropTypes.bool,
    isPaginated: PropTypes.bool,
    onChangePageCb: PropTypes.func,
    onClickRow: PropTypes.func,
    onRefresh: PropTypes.func,
    page: PropTypes.number,
    variant: PropTypes.oneOf(['default', 'grey']),
    sortColumnCb: PropTypes.func,
    sorter: PropTypes.object,
    tbodyHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isRefetching: PropTypes.bool,
    isFetchingNextPage: PropTypes.bool
}
