import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {useAssignmentCoordination} from 'src/features/assignment/services/useAssignmentCoordination.ts'

export const AcceptedNoHostDatesDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const assignmentCoordinationMutation = useAssignmentCoordination({
        assignmentId: assignment.id,
        taskId: assignment.task.id
    })

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {t('assignment:descriptions:accepted_no_dates_shared:title', {
                        hostName: assignment.host.first_name
                    })}
                </AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:accepted_no_dates_shared:paragraph:${serviceType}`)}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            <Button
                disabled={assignmentCoordinationMutation.isLoading}
                onClick={() => assignmentCoordinationMutation.mutate({status: 'host_contacted'})}
            >
                {t('assignment:descriptions:accepted_no_dates_shared:cta')}
                {assignmentCoordinationMutation.isLoading && <Spinner size={20} />}
            </Button>
        </AssignmentDescriptionWrapper>
    )
}
