import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import {EVENT_TYPES, Event} from '../../event/Event'
import {formatLocaleDate} from '../../../../helpers/utils'

import {StyledPopover, StyledOverlay, StyledModal, StyledHeader, StyledTotal, StyledDate, StyledBody} from './style'

export const Modal = ({events, date, onClose}) => {
    const {t} = useTranslation()

    return (
        <StyledPopover>
            <StyledOverlay onClick={onClose} />
            <StyledModal>
                <StyledHeader>
                    <StyledDate>{formatLocaleDate(date, 'dddd, D MMMM')}</StyledDate>
                    <StyledTotal>{t('calendar:total_shoots', {count: events.length})}</StyledTotal>
                </StyledHeader>
                <StyledBody>
                    {events?.map(event => (
                        <Event type={EVENT_TYPES.POPUP} key={event.resource.id} event={event} />
                    ))}
                </StyledBody>
            </StyledModal>
        </StyledPopover>
    )
}

Modal.propTypes = {
    onClose: PropTypes.func,
    date: PropTypes.instanceOf(Date),
    events: PropTypes.arrayOf({
        allDay: PropTypes.bool,
        title: PropTypes.node,
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        resource: PropTypes.object
    })
}
