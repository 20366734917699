import {useCallback, useState} from 'react'
import {httpGetCurrentShootsByStatus} from '../http-requests/assignments.http'
import {useInfiniteQuery} from '@tanstack/react-query'
import {errorHandler} from '../helpers/utils'
import {QUERY_KEYS} from '../queryClient'
import {useUserStore} from '../features/user/store'

export const useCurrentShoots = ({search, shootStatus}) => {
    const accessToken = useUserStore(store => store.accessToken)
    const [sorter, setSorter] = useState(null)

    const {data, error, fetchNextPage, ...query} = useInfiniteQuery({
        queryKey: [QUERY_KEYS.CURRENT_SHOOTS, sorter, search, shootStatus],
        queryFn: async ({pageParam = 1}) => {
            const {data: payload} = await httpGetCurrentShootsByStatus(
                {page: pageParam, ...sorter, search, status: shootStatus},
                accessToken
            )
            return payload
        },
        onError: errorHandler,
        defaultPageParam: 1
    })

    const onChangePage = () => {
        const currentPage = data.pages.at(-1).current_page
        const lastPage = data.pages.at(-1).last_page
        if (!query.isFetching) {
            if (currentPage < lastPage) {
                fetchNextPage({pageParam: data.pages.at(-1).current_page + 1})
            }
        }
    }

    //sorter
    const onSortColumn = useCallback((columnName, orderDirection) => {
        setSorter({order_by: columnName, order_direction: orderDirection})
    }, [])

    /**
     * onAddShootToQueryByStatus
     * @param shoot
     * @param status
     */
    /*const onAddShootToQueryByStatus = (shoot,status) => {
    let oldPagesArray = [...data.pages]
    //Add the new shoot to the first page
    oldPagesArray[0] = {
      ...oldPagesArray[0],
      data: [shoot, ...oldPagesArray[0].data]
    }
    const newPagesArray = [...oldPagesArray]

    //setQueriesData can change all the data matched by a partial match in query key
    switch (status){
      case STATUSES.ALL_SHOOTS:
        return queryClient.setQueriesData(
            [QUERY_KEYS.CURRENT_SHOOTS, STATUSES.ALL_SHOOTS],
            (data) => ({ pages: {...newPagesArray}, pageParams: data.pageParams,})
        )
    }
  }*/

    /**
     * onRemoveShootToQueryByStatus
     * @param shoot
     * @param status
     */
    /*const onRemoveShootToQueryByStatus = (shoot,status) => {
    const oldPagesArray = [...data.pages]
    const newPagesArray = oldPagesArray.map((page) => {
      return {
        ...page,
        data: page?.data?.filter((item) => item.id !== shoot.id) ?? []
      }
    })

    //setQueriesData can change all the data matched by a partial match in query key
    queryClient.setQueriesData([QUERY_KEYS.NEW_SHOOTS], (data) => ({
      pages: newPagesArray,
      pageParams: data.pageParams,
    }))
  }*/

    return {
        ...query,
        data: data ? data?.pages?.flatMap(page => page?.data) : [],
        error,
        page: data ? data.pages.at(-1)?.current_page + 1 : 1,
        onChangePage,
        sorter,
        onSortColumn
        //onAddShootToQueryByStatus
    }
}
