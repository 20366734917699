import {z} from 'zod'

export const User = z.object({
    description: z.string().nullable(),
    has_voted: z.boolean(),
    name: z.string().min(1),
    photographers_markets: z.array(
        z.object({
            country: z.string().min(1),
            external_id: z.string().min(1),
            geo: z.string().min(1),
            region: z.string().min(1)
        })
    ),
    profile_photo_url: z.string().url().nullable()
})
export type User = z.infer<typeof User>

export const AdminUser = z.object({
    id: z.number().int().positive(),
    first_name: z.string(),
    last_name: z.string()
})
export type AdminUser = z.infer<typeof AdminUser>

export const Region = z.string().min(1)
export type Region = z.infer<typeof Region>

export const Country = z.object({country: z.string().min(1)})
export type Country = z.infer<typeof Country>

export const Geo = z.object({
    country: z.string().min(1),
    external_id: z.string().min(1),
    geo: z.string().min(1)
})
export type Geo = z.infer<typeof Geo>
