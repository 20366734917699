import styled, {css} from 'styled-components'
import {CalendarIcon, ClockIcon} from '../../theme/icons'

export const StyledLabel = styled.label(
    ({theme: {colors, spacing}}) => css`
        color: ${colors.darkGrey};
        margin-bottom: ${spacing * 2}px;
    `
)

export const StyledDateTimeInput = styled.div`
    ${({theme: {colors, spacing, transition, palette}}) => css`
        width: 100%;

        & .react-datepicker,
        .react-datepicker__time-container {
            border-color: ${colors.lightBlack};
        }

        /*reset icon*/
        & .react-datepicker__close-icon::after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='12px' fill='%23fff'%3E%3Cpath d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E");
            background: ${colors.lightBlack};
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        /*triangle*/
        & .react-datepicker__triangle::after {
            border-bottom-color: ${colors.lightBlack} !important;
        }

        & .react-datepicker__triangle::before {
            border-top-color: ${colors.lightBlack} !important;
        }

        /*today*/
        & .react-datepicker__day--today {
            background: ${colors.grey} !important;
            border-radius: 4px;
            font-weight: 700 !important;
        }

        /*selected day and time*/
        & .react-datepicker__time-list-item--selected,
        .react-datepicker__day--selected {
            background: ${colors.lightBlack} !important;
            color: ${palette.neutral.white} !important;
            font-weight: 700 !important;

            &:hover {
                background: ${colors.darkGrey} !important;
            }
        }

        /*unselected day and time*/
        & .react-datepicker__time-list-item,
        .react-datepicker__day,
        .react-datepicker__day--keyboard-selected {
            background: ${palette.neutral.white};
            color: ${colors.lightBlack};
            font-weight: 400;
            ${transition};
            cursor: pointer;
            &:hover {
                background: ${colors.inputGrey} !important;
                opacity: 0.8;
            }
        }

        /*disabled day*/
        & .react-datepicker__day--disabled {
            color: ${colors.grey};
            opacity: 0.5;
        }

        /*next and previous months day*/
        & .react-datepicker__day--outside-month {
            color: ${colors.grey};
        }

        /*header*/
        & .react-datepicker__header {
            background: ${colors.lightBlack};
        }

        & .react-datepicker__current-month,
        .react-datepicker__day-name,
        .react-datepicker-time__header {
            color: ${palette.neutral.white};
        }

        & .react-datepicker__navigation-icon::before {
            border-color: ${palette.neutral.white};
        }

        /*time panel*/
        & .react-datepicker__time-list {
            &::-webkit-scrollbar {
                width: 8px;
                background: ${colors.lightGrey};
            }

            &::-webkit-scrollbar-thumb {
                background-color: ${colors.grey};
                border-radius: ${spacing * 2}px;

                &:hover {
                    background-color: ${colors.darkGrey};
                }
            }
        }
    `}
`

export const StyledInputWrapper = styled.div`
    ${({theme: {colors, spacing, mediaQuery, typography, transition, palette}, hasIcon}) => css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        width: 100%;
        ${typography.textMd};
        font-weight: 500;
        color: ${colors.lightBlack};
        padding-bottom: ${spacing * 1.5}6px;
        border: none;
        border-bottom-width: ${spacing * 0.5}px;
        border-bottom-style: solid;
        border-bottom-color: ${colors.inputGrey};
        ${transition};
        background: ${palette.neutral.white};

        &:-webkit-autofill {
            -webkit-background-clip: text;
        }

        &:focus {
            outline-width: 0;
            border-bottom-color: ${palette.neutral.black};
        }

        &::placeholder {
            color: ${colors.grey};
            font-weight: 300;
        }

        ${mediaQuery.SM} {
            height: 40px;

            &:focus {
                padding: 0 ${spacing * 3}px;
                border-bottom-width: 0;
                border-radius: ${spacing * 6}px;
                border-bottom-color: ${palette.neutral.white};
                background: ${colors.lightBlue};
            }
        }

        & input {
            width: 100%;
            background: transparent;
            border: none;
            padding: ${hasIcon
                ? `${spacing * 2}px ${spacing * 6}px ${spacing * 2}px ${spacing * 10}px`
                : `${spacing * 2}px ${spacing * 4}px ${spacing * 2}px`};
            ${typography.textMd};
            font-weight: 500;
            ${transition};
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            &:focus {
                outline: 0;
            }
        }
    `}
`

export const StyledClockIcon = styled(ClockIcon)`
    ${({theme: {spacing}}) => css`
        width: 24px;
        position: absolute;
        left: ${spacing * 2}px;
    `}
`

export const StyledCalendarIcon = styled(CalendarIcon)`
    ${({theme: {spacing}}) => css`
        width: 24px;
        position: absolute;
        left: ${spacing * 2}px;
    `}
`

export const StyledNativeInput = styled.div`
    ${({theme: {colors, typography, mediaQuery, spacing, transition, palette}}) => css`
        display: flex;
        justify-content: flex-start;
        flex-flow: column;
        align-items: flex-start;
        & label {
            display: block;
            ${typography.textMd};
            font-weight: 400;
            color: ${colors.lightBlack};
            margin-bottom: ${spacing};
        }
        & input {
            position: relative;
            width: 100%;
            ${typography.textMd};
            font-weight: 500;
            color: ${colors.lightBlack};
            padding-bottom: ${spacing * 1.5}px;
            border: none;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: ${colors.inputGrey};
            ${transition};
            background: transparent;

            &:-webkit-autofill {
                -webkit-background-clip: text;
            }

            &:focus {
                outline-width: 0;
                border-bottom-color: ${palette.neutral.black};
            }

            &::placeholder {
                color: ${colors.grey};
                font-weight: 300;
            }

            ${mediaQuery.SM} {
                height: 40px;

                &:focus {
                    padding: 0 ${spacing * 3}px;
                    border-bottom-width: 0;
                    border-radius: 24px;
                    border-bottom-color: ${palette.neutral.white};
                    background: ${colors.lightBlue};
                }
            }
        }
    `}
`

export const StyledErrors = styled.small`
    ${({theme: {colors, typography}}) => css`
        ${typography.textXs};
        font-weight: 700;
        color: ${colors.red} !important;
    `}
`
