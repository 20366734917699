import {Menu, MenuItem} from '@ariakit/react'
import styled, {css} from 'styled-components'

export const StyledAdminHostImpersonateMenu = styled(Menu)(
    ({theme: {palette, spacing, shadows}}) => css`
        background-color: ${palette.neutral['white']};
        padding: ${spacing * 1.5}px;
        border: 1px solid ${palette.neutral['200']};
        border-radius: 8px;
        box-shadow: ${shadows.lg};
    `
)

export const StyledAdminHostImpersonateMenuItem = styled(MenuItem)(
    ({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm};
        padding: ${spacing * 1.5}px;
        font-weight: 500;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
            background-color: ${palette.neutral['50']};
        }
    `
)
