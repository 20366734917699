import {PullShoots} from '../custom-components/pull-shoots/PullShoots'
import {TicketSubmit} from '../custom-components/TicketSubmit'
import {GetInfo} from '../custom-components/GetInfo'
import {CHATBOT_SOURCES, TASK_STATUSES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import i18next from 'i18next'

export const mb11FlowSteps = (isInShootPage, shoot) => [
    {
        id: 'MB1.1',
        message: i18next.t('chatbot:mb11:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.1',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Have you already completed the photoshoot?'
                })
            )
            return 'MB1.1.2'
        }
    },
    {
        id: 'MB1.1.2',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.1.2',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB1.1.2_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.1.2',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB1.1.2_no'
                }
            }
        ]
    },

    /*****************************/
    /*** COMPLETED SHOOTS FLOW ***/
    /*****************************/
    {
        id: 'MB1.1.2_yes',
        message: i18next.t('chatbot:commons:which_shoot'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.1.2_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Which shoot are you referring to?'
                })
            )
            return 'MB1_yes_pull'
        }
    },
    {
        id: 'MB1_yes_pull',
        component: (
            <PullShoots
                flowSource="MB1_yes_pull"
                statuses={[TASK_STATUSES.COMPLETED, TASK_STATUSES.PENDING_ASSETS, TASK_STATUSES.ASSETS_IN_REVIEW]}
                filterByDate="0"
            />
        ) // Trigger managed in the component
    },
    {
        id: 'MB1_yes_br', // Get the number of bedrooms on the listing
        component: <GetInfo flowSource="MB1_yes_pull" />,
        trigger: 'MB1_yes_br_input',
        asMessage: true
    },
    {
        id: 'MB1_yes_br_input', // Input the number of bedrooms that seems to be in the location
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_yes_br_input',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: value
                })
            )
            return 'MB1_yes_ticket'
        }
    },
    {
        id: 'MB1_yes_ticket',
        component: <TicketSubmit reason="wrong_br_number_completed" isInShootPage={isInShootPage} shoot={shoot} />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_yes_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        },
        asMessage: true
    },

    // Flow if the shoot is completed but the airbnb ID is not in the list
    {
        id: 'MB1_yes_notfound',
        message: i18next.t('chatbot:commons:type_airbnb_id'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_yes_notfound',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please, type the airbnb ID:'
                })
            )
            return 'MB1_yes_notfound_input'
        }
    },
    {
        id: 'MB1_yes_notfound_input',
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_yes_notfound_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB1_yes_notfound_br'
        }
    },
    {
        id: 'MB1_yes_notfound_br', // Get the number of bedrooms on the listing
        message: i18next.t('chatbot:mb11:mb1_yes_notfound_br'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_yes_notfound_br',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'How many bedrooms did you shoot?'
                })
            )
            return 'MB1_yes_notfound_br_input'
        }
    },
    {
        id: 'MB1_yes_notfound_br_input', // Input the number of bedrooms that seems to be in the location
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_yes_notfound_br_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB1_yes_notfound_ticket'
        }
    },
    {
        id: 'MB1_yes_notfound_ticket',
        component: <TicketSubmit reason="wrong_br_number_completed_notfound" />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_yes_notfound_ticket',
                    source: CHATBOT_SOURCES.USER,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        },
        asMessage: true
    },

    // Final steps of the flow if the shoot is completed

    /*********************************/
    /*** NOT-COMPLETED SHOOTS FLOW ***/
    /*********************************/

    {
        id: 'MB1.1.2_no',
        message: i18next.t('chatbot:commons:which_shoot'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.1.2_no',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Which shoot are you referring to?'
                })
            )
            return 'MB1_no_pull'
        }
    },
    {
        id: 'MB1_no_pull',
        component: (
            <PullShoots
                flowSource="MB1_no_pull"
                statuses={[TASK_STATUSES.PENDING, TASK_STATUSES.SCHEDULED, TASK_STATUSES.ACCEPTED]}
                filterByDate="0"
            />
        )
    },

    {
        id: 'MB1_no_br',
        component: <GetInfo flowSource="MB1_no_pull" />,
        trigger: 'MB1_no_br_input',
        asMessage: true
    },
    {
        id: 'MB1_no_br_input',
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_br_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB1_no_br_ticket'
        }
    },
    {
        id: 'MB1_no_br_ticket',
        component: <TicketSubmit reason="wrong_br_number_not_completed" isInShootPage={isInShootPage} shoot={shoot} />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_br_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'MB1_no_confirmation'
        },
        replace: true,
        asMessage: true
    },
    {
        id: 'MB1_no_confirmation',
        message: i18next.t('chatbot:mb11:mb1_no_confirmation'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_confirmation',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thank you for letting us know. Our team will check the listing information provided. If the number of bedroom is incorrect, your assignment will be canceled and a new price quote will be sent to the Host with the correct number of bedrooms. Once the Host will accept the new quote you will be assigned to this photoshoot.'
                })
            )
            return 'send_log'
        }
    },

    // Flow if the shoot is not completed but the airbnb ID is not in the list
    {
        id: 'MB1_no_notfound',
        message: i18next.t('chatbot:commons:type_airbnb_id'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_notfound',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please, type the airbnb ID:'
                })
            )
            return 'MB1_no_notfound_input'
        }
    },
    {
        id: 'MB1_no_notfound_input',
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_notfound_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB1_no_notfound_br'
        }
    },
    {
        id: 'MB1_no_notfound_br',
        message: i18next.t('chatbot:mb11:mb1_no_notfound_br'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_notfound_br',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please, enter the number of bedrooms:'
                })
            )
            return 'MB1_no_notfound_br_input'
        }
    },
    {
        id: 'MB1_no_notfound_br_input', // Input the number of bedrooms that seems to be in the location
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_notfound_br_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB1_no_notfound_ticket'
        }
    },
    {
        id: 'MB1_no_notfound_ticket',
        component: <TicketSubmit reason="wrong_br_not_completed_notfound" />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1_no_notfound_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        },
        asMessage: true
    }
]
