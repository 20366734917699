import styled, {css} from 'styled-components'

const BaseStyle = ({theme: {spacing}}) => css`
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 ${spacing * 6}px 0 ${spacing * 4}px; /*padding-right = padding-left + scrollbar width */
`

const ColorStyles = ({theme: {palette, spacing}}) => ({
    grey: css`
        background-color: ${palette.neutral['50']};
        padding: ${spacing * 2}px ${spacing * 2}px;
        height: unset;
        border-radius: 8px;
        border: 1px solid ${palette.neutral['300']};
        & span {
            padding: 0 ${spacing}px;
        }
    `
})

export const StyledTHead = styled.div`
    ${BaseStyle};
    ${({variant, theme}) => ColorStyles({theme})[variant]};
`

//todo: refactor and remove this function
const getTHAlignment = alignment => {
    switch (alignment) {
        case 'center':
            return 'center'
        case 'right':
            return 'flex-end'
        default:
            return 'flex-start'
    }
}

export const StyledTH = styled.div(
    ({alignment, width, theme: {palette, spacing, typography}}) => css`
        display: flex;
        justify-content: ${getTHAlignment(alignment)};
        align-items: center;
        gap: ${spacing * 2}px;
        padding: 0 ${spacing}px;
        width: ${width};
        font-weight: 500;
        ${typography.textSm};
        color: ${palette.neutral['500']};

        & span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        & svg {
            width: 16px; /*remove it after icons refactor (it will be <Icon size={16}/> in the table/desktop/header/index jsx)*/
            height: 16px; /*remove it after icons refactor (it will be <Icon size={16}/> in the table/desktop/header/index jsx)*/
        }
    `
)

export const SortButton = styled.button(
    ({theme: {palette, transition}}) => css`
        flex: none;
        width: 28px;
        height: 28px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        color: ${palette.neutral['500']};
        border-radius: 8px;
        cursor: pointer;
        ${transition};
        & svg {
            width: 16px; /*remove it after icons refactor (it will be <Icon size={16}/> in the table/desktop/header/index jsx)*/
        }

        &:hover {
            background-color: ${palette.neutral['200']};
        }
    `
)
