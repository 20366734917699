import styled, {css} from 'styled-components'

export const StyledErrorBanner = styled.div(
    ({theme: {colors, typography, spacing}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 4}px;
        ${typography.textXl};
        font-weight: 500;
        color: ${colors.red};
    `
)
