import {PullShoots} from '../custom-components/pull-shoots/PullShoots'
import {TicketSubmit} from '../custom-components/TicketSubmit'
import {CHATBOT_SOURCES, TASK_STATUSES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import i18next from 'i18next'

export const mb23FlowSteps = (isInShootPage, shoot) => [
    {
        id: 'MB2.3',
        message: i18next.t('chatbot:commons:which_shoot'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Which shoot are you referring to?'
                })
            )
            return 'MB2.3_pull'
        }
    },
    {
        id: 'MB2.3_pull',
        component: (
            <PullShoots
                flowSource="MB2.3_pull"
                statuses={[TASK_STATUSES.HARD_CANCELLED, TASK_STATUSES.SCHEDULED, TASK_STATUSES.PENDING_ASSETS]}
                filterByDate="0"
            />
        )
    },

    // ID Found flow
    {
        id: 'MB2.3.1',
        options: [
            {
                value: 'canceled',
                label: i18next.t('chatbot:mb23:mb2.3.1'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB2.3.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'My scheduled photoshoot was canceled within 24 hours'
                        })
                    )
                    return 'MB2.3_notes'
                }
            },
            {
                value: 'rescheduled',
                label: i18next.t('chatbot:mb23:mb2.3.1_rescheduled'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB2.3.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'My scheduled photoshoot was rescheduled within 24 hours'
                        })
                    )
                    return 'MB2.3_notes'
                }
            },
            {
                value: 'no_show',
                label: i18next.t('chatbot:mb23:mb2.3.1_noshow'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB2.3.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host did not show up at the scheduled date and time'
                        })
                    )
                    return 'MB2.3_notes'
                }
            },
            {
                value: 'not_ready',
                label: i18next.t('chatbot:mb23:mb2.3.1_noready'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB2.3.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The listing was not ready at the scheduled date and time'
                        })
                    )
                    return 'MB2.3_notes'
                }
            }
        ]
    },
    {
        id: 'MB2.3_notes',
        message: i18next.t('chatbot:mb23:mb2.3_notes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_notes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'We are sorry about it! Please give us any relevant details of the cancellation, reschedule or no-show:'
                })
            )
            return 'MB2.3_notes_input'
        }
    },
    {
        id: 'MB2.3_notes_input',
        user: true,
        validator: value => {
            if (value === '') {
                return i18next.t('chatbot:commons:enter_message')
            } else if (value.length < 30) {
                return i18next.t('chatbot:commons:message_should_be_longer')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_notes_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB2.3_ticket'
        }
    },
    {
        id: 'MB2.3_ticket',
        component: <TicketSubmit reason="compensation" isInShootPage={isInShootPage} shoot={shoot} />,
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        }
    },

    // ID not-found flow
    {
        id: 'MB2.3_notfound',
        message: i18next.t('chatbot:commons:type_airbnb_id'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_notfound',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please, type the airbnb ID:'
                })
            )
            return 'MB2.3_notfound_input'
        }
    },
    {
        id: 'MB2.3_notfound_input',
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_notfound_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB2.3_notfound_notes'
        }
    },
    {
        id: 'MB2.3_notfound_notes',
        message: i18next.t('chatbot:mb23:mb2.3_notes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_notfound_notes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'We are sorry about it! Please give us any relevant details of the cancellation, reschedule or no-show:'
                })
            )
            return 'MB2.3_notfound_notes_input'
        }
    },
    {
        id: 'MB2.3_notfound_notes_input',
        user: true,
        validator: value => {
            if (value === '') {
                return i18next.t('chatbot:commons:enter_message')
            } else if (value.length < 30) {
                return i18next.t('chatbot:commons:message_should_be_longer')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_notfound_notes_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB2.3_notfound_ticket'
        }
    },
    {
        id: 'MB2.3_notfound_ticket',
        component: <TicketSubmit reason="compensation_notfound" />,
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.3_notfound_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        }
    }
]
