import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalTaskMetadataItem = styled(Flexbox)(
    ({theme: {typography}}) => css`
        ${typography.textSm};

        & b {
            font-weight: 500;
        }
    `
)
