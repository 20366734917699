import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers'
import {MUTATION_KEYS, QUERY_KEYS, queryClient} from 'src/queryClient'
import {
    httpDeleteHostApprovalTaskImages,
    HttpDeleteHostApprovalTaskImagesOptions
} from 'src/features/admin-host-approval/services/adminHostApproval.http'
import {AdminHostApprovalTaskImage} from '../types'

export const useDeleteHostApprovalTaskImages = ({
    taskId,
    options
}: {
    taskId: number
    options?: UseMutationOptions<unknown, unknown, HttpDeleteHostApprovalTaskImagesOptions['payload']>
}) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is null')
    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.HOST_APPROVAL_TASK_IMAGES_DELETE],
        mutationFn: (payload: HttpDeleteHostApprovalTaskImagesOptions['payload']) =>
            httpDeleteHostApprovalTaskImages({
                urlParams: {taskId},
                payload,
                params: {
                    response_type: 'extended'
                },
                headers: makeHttpAuthorizationHeader(adminAccessToken)
            }),
        onMutate: async vars => {
            const queryKey = [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK_IMAGES, taskId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousImages = queryClient.getQueryData<AdminHostApprovalTaskImage[]>(queryKey)

            // Update cached data
            queryClient.setQueriesData({queryKey}, (currentImages: AdminHostApprovalTaskImage[] | undefined) => {
                if (!currentImages) return currentImages
                return currentImages.filter(currentImage => !vars.image_ids.includes(currentImage.id))
            })

            return {previousImages}
        },
        onError: (error, variables, context) => {
            queryClient.setQueriesData(
                {queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK_IMAGES, taskId]},
                context?.previousImages
            )
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK, taskId]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
