import styled, {css} from 'styled-components'
import {Container} from 'src/components/container/Container.tsx'
import {ButtonLink} from 'src/components/button-link/ButtonLink.tsx'

export const StyledAssignment = styled.main`
    display: flex;
    flex-direction: column;
    overflow: auto;
`

export const StyledBackButton = styled(ButtonLink)`
    padding: 0;
    width: max-content;
`

export const StyledLoadingContainer = styled(Container)(
    ({theme: {palette}}) => css`
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${palette.neutral['400']};
    `
)
