import {StyledHeadListItem, StyledIconContainer, StyledInfoContainer, StyledName, StyledEmail} from './style'
import PropTypes from 'prop-types'
import {Avatar} from 'src/components/avatar-legacy/Avatar'
import {HTMLAttributes} from 'react'

interface AccountCardProps extends HTMLAttributes<HTMLDivElement> {
    name: string
    email?: string
    profilePhotoUrl?: string
}

export const AccountCard = ({name, email, profilePhotoUrl, ...rest}: AccountCardProps) => {
    return (
        <StyledHeadListItem {...rest}>
            <StyledIconContainer>
                <Avatar name={name} imageUrl={profilePhotoUrl ?? ''} />
            </StyledIconContainer>
            <StyledInfoContainer>
                <StyledName>{name || '-'}</StyledName>
                <StyledEmail>{email || '-'}</StyledEmail>
            </StyledInfoContainer>
        </StyledHeadListItem>
    )
}

AccountCard.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    profilePhotoUrl: PropTypes.string
}
