import styled, {css} from 'styled-components'

export const HeroWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: baseline;
`

export const StyledTitle = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.displayXs};
        font-weight: 500;
        color: ${palette.neutral['900']};
    `}
`

export const StyledSubtitle = styled.div`
    ${({theme: {palette, mediaQuery, typography}}) => css`
        color: ${palette.neutral['500']};
        ${typography.displayXs};
        font-weight: 500;
        ${mediaQuery.SM} {
            display: none;
        }
    `}
`
