import styled, {css} from 'styled-components'

export const CardComponent = styled.div`
    ${({theme: {spacing, colors, typography, palette}}) => css`
        position: relative;
        padding: ${spacing * 10}px;
        background: ${palette.neutral.white};
        border: 1px solid ${colors.transparentBlack};
        border-radius: 8px;
        & label {
            ${typography.textSm};
            font-weight: 700;
            color: ${palette.neutral.black};
            margin-bottom: ${spacing * 2}px;
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral.black};
        }
    `}
`

export const Head = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        margin-bottom: ${spacing * 18}px;
        & svg {
            width: 20px;
        }
    `}
`

export const Title = styled.h3`
    ${({theme: {palette, typography}}) => css`
        ${typography.textLg};
        font-weight: 700;
        color: ${palette.neutral.black};
        margin: 0;
    `}
`
