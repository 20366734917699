import {StyledTask, StyledCloseIcon, StyledFullscreenImage, StyledFullscreenOverlay} from './style'
import {ConfirmViewSubheader} from './confirm-view/confirm-view-subheader/ConfirmViewSubheader'
import {ConfirmReviewSubheader} from './review-view/confirm-review-subheader/ConfirmReviewSubheader'
import {useCallback, useEffect, useState} from 'react'
import {PageHelmet} from '../../../../components/page-helmet/PageHelmet'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {errorHandler} from '../../../../helpers/utils'
import {httpGetTask} from '../../../../http-requests/admin/matterport.http'
import {useNavigate, useParams} from 'react-router'
import {resetCurrentTask, setCurrentTask, taskSelector} from '../../../../store/matterport'
import {Spinner} from '../../../../components/spinner-legacy/Spinner'
import {MATTERPORT_STATUSES, RESPONSE_TYPES, ROUTES} from '../../../../helpers/constants'
import {ConfirmView} from './confirm-view/ConfirmView'
import {ReviewView} from './review-view/ReviewView'
import useKey from 'use-key-hook'
import {useUserStore} from '../../../../features/user/store'

export const Task = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const task = useSelector(taskSelector)
    const [isLoading, setIsLoading] = useState(true)
    const [subheaderHeight, setSubheaderHeight] = useState(220)
    const [fullScreenImage, setFullScreenImage] = useState(null)
    const leftColumnSpacingFromTop = subheaderHeight + 128
    const isConfirmView = task?.task_matterport?.status === MATTERPORT_STATUSES.READY_PUBLISH

    useEffect(() => {
        getCurrentTask()

        return () => {
            dispatch(resetCurrentTask())
        }
    }, [])

    const setFullScreenImageCb = image => {
        setFullScreenImage(image)
    }

    const checkAllowedStatus = status => {
        if (status !== MATTERPORT_STATUSES.READY_QA && status !== MATTERPORT_STATUSES.READY_PUBLISH) {
            status === MATTERPORT_STATUSES.UPLOAD_MEDIABOX
                ? navigate(ROUTES.ADMIN_MATTERPORT_UPLOADER.replace(':id', taskId), {replace: true})
                : navigate(ROUTES.PAGE_NOT_FOUND, {replace: true})
        }
    }

    const getCurrentTask = async () => {
        try {
            const {data} = await httpGetTask(adminAccessToken, taskId, {
                response_type: RESPONSE_TYPES.EXTENDED
            })

            dispatch(setCurrentTask(data))
            checkAllowedStatus(data?.task_matterport?.status)
        } catch (e) {
            e?.response?.status === 404 ? navigate(ROUTES.PAGE_NOT_FOUND, {replace: true}) : errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const setSubheaderHeightCb = useCallback(height => {
        setSubheaderHeight(height)
    }, [])

    useKey(() => setFullScreenImageCb(null), {detectKeys: [27]})

    return (
        <StyledTask>
            <PageHelmet title={t('titles:admin_matterport')} />

            {fullScreenImage && (
                <StyledFullscreenImage>
                    <StyledFullscreenOverlay onClick={() => setFullScreenImage(null)} />
                    <StyledCloseIcon onClick={() => setFullScreenImage(null)} />
                    <img src={fullScreenImage?.url} alt={fullScreenImage?.name} />
                </StyledFullscreenImage>
            )}

            {isLoading ? (
                <Spinner overlay={false} />
            ) : (
                <>
                    {isConfirmView ? (
                        <ConfirmViewSubheader setSubheaderHeightCb={setSubheaderHeightCb} />
                    ) : (
                        <ConfirmReviewSubheader setSubheaderHeightCb={setSubheaderHeightCb} />
                    )}
                    {isConfirmView ? (
                        <ConfirmView subheaderHeight={subheaderHeight} />
                    ) : (
                        <ReviewView
                            leftColumnSpacingFromTop={leftColumnSpacingFromTop}
                            setFullScreenImageCb={setFullScreenImageCb}
                        />
                    )}
                </>
            )}
        </StyledTask>
    )
}
