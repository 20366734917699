import {TableProps} from 'src/components/table/Table.tsx'
import {StyledDesktopTable} from 'src/components/table/desktop-table/style'
import {DesktopTHead} from 'src/components/table/desktop-t-head/DesktopTHead'
import {DesktopTBody} from 'src/components/table/desktop-t-body/DesktopTBody'
import {HTMLAttributes} from 'react'

export const DesktopTable = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    sorter,
    data,
    onChangePage,
    isError,
    isChangingPage,
    isLoading,
    subRow,
    expandedRows,
    variant = 'default',
    ...rest
}: TableProps<TData, TSortName> & HTMLAttributes<HTMLDivElement>) => {
    return (
        <StyledDesktopTable {...rest}>
            <DesktopTHead columns={columns} sorter={sorter} variant={variant} />
            <DesktopTBody
                data={data}
                columns={columns}
                onChangePage={onChangePage}
                isError={isError}
                isChangingPage={isChangingPage}
                isLoading={isLoading}
                subRow={subRow}
                expandedRows={expandedRows}
                variant={variant}
            />
        </StyledDesktopTable>
    )
}

DesktopTable.displayName = 'DesktopTable'
