import {StyledButtons} from 'src/features/assignment/components/assignment-descriptions/accepted-dates-declined-description/style.ts'
import {Button} from 'src/components/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {Assignment} from 'src/features/assignment/types.ts'
// @ts-expect-error [TS7016] "We don't have types yet"
import {PhotosUploadModal} from 'src/features/assignment/components/photos-upload-modal/PhotosUploadModal.jsx'
import {SubmitToAirbnbModal} from 'src/features/assignment/components/submit-to-airbnb-modal/SubmitToAirbnbModal.tsx'

export const UploadButtons = ({assignment}: {assignment: Assignment}) => {
    const {t} = useTranslation()
    const [openModal, setOpenModal] = useState<'upload_assets' | 'submit_to_airbnb' | null>(null)

    return (
        <>
            <StyledButtons>
                <Button variant="secondary" onClick={() => setOpenModal('upload_assets')}>
                    {t('assignment:descriptions:upload_assets')}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => setOpenModal('submit_to_airbnb')}
                    disabled={!assignment.can_send_to_airbnb}
                >
                    {t('assignment:descriptions:submit_to_airbnb')}
                </Button>
            </StyledButtons>
            {openModal == 'upload_assets' && (
                <PhotosUploadModal
                    canSendToAirbnb={assignment.can_send_to_airbnb}
                    taskStatus={assignment.task_status.status}
                    assignment={assignment}
                    onClose={() => setOpenModal(null)}
                    maxFiles={assignment.medias_to_upload}
                    onSubmit={() => setOpenModal('submit_to_airbnb')}
                />
            )}
            {openModal == 'submit_to_airbnb' && (
                <SubmitToAirbnbModal
                    onClose={() => setOpenModal(null)}
                    assignment={assignment}
                    setSuccessModalCb={() => setOpenModal(null)}
                />
            )}
        </>
    )
}
