import {httpPullShoots, httpGetShootData} from '../../../../http-requests/chatbot.http'
import {useEffect, useState} from 'react'
import {capitalize, errorHandler} from '../../../../helpers/utils'
import {useDispatch} from 'react-redux'
import {addStepToChatHistory, setShoot} from '../../../../store/chatbot'
import {useTranslation} from 'react-i18next'
import {CHATBOT_SOURCES, SERVICE_TYPE} from '../../../../helpers/constants'
import {Button} from '../../../button/Button'
import {useTheme} from 'styled-components'
import {StyledShoot, StyledShoots} from './style'
import PropTypes from 'prop-types'
import {useUserStore} from '../../../../features/user/store'

export const PullShoots = ({flowSource, statuses, statusesNames, filterByDate, triggerNextStep}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const accessToken = useUserStore(store => store.accessToken)
    const [shoots, setShoots] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedShoot, setSelectedShoot] = useState(null)

    useEffect(() => {
        onGetShootsResults()
    }, [])

    const onGetShootsResults = async () => {
        setIsLoading(true)
        try {
            const {data} = await httpPullShoots(accessToken, statuses, statusesNames, filterByDate)
            setShoots(data)
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    // Based on the arrival flow, return the step to trigger if the user found the shoot in the list
    const pullShootsTriggerFound = flowSource => {
        switch (flowSource) {
            case 'MB1_yes_pull':
                return 'MB1_yes_br'
            case 'MB1_no_pull':
                return 'MB1_no_br'
            case 'MB1.2_pull':
                return 'MB1.2_cancel'
            case 'MB1.3_pull':
                return 'MB1.3.1'
            case 'MB1.4_pull':
                return 'MB1.4.1'
            case 'MB2.2_pull':
                return 'MB2.2_issuedate'
            case 'MB2.3_pull':
                return 'MB2.3.1'
            case 'root':
                return 'MB4_specific'
        }
    }

    // Based on the arrival flow, return the step to trigger if the user didn't find the shoot in the list
    const pullShootsTriggerNotFound = flowSource => {
        switch (flowSource) {
            case 'MB1_yes_pull':
                return 'MB1_yes_notfound'
            case 'MB1_no_pull': //mb1.2 e mb1.3
                return 'MB1_no_notfound'
            case 'MB1.4_pull':
                return 'MB5'
            case 'MB2.2_pull':
                return 'MB2.2_notfound'
            case 'MB2.3_pull':
                return 'MB2.3_notfound'
            case 'root':
                return 'MB6'
            default:
                return 'MB4'
        }
    }

    // Get shoot data based on the code:
    const getShootData = async code => {
        try {
            const {data} = await httpGetShootData(accessToken, code)
            dispatch(setShoot(data))
            dispatch(
                addStepToChatHistory({
                    id: flowSource,
                    source: CHATBOT_SOURCES.USER,
                    text: `[Selected shoot ID: ${data?.id}]`
                })
            )
            triggerNextStep({
                value: data,
                trigger:
                    data?.task_service?.id === SERVICE_TYPE.WALKTHROUGH
                        ? 'walkthrough_support'
                        : pullShootsTriggerFound(flowSource)
            })
        } catch (e) {
            errorHandler(e)
        }
    }

    const onSelectShoot = shoot => {
        if (selectedShoot) return
        setSelectedShoot(shoot)
        getShootData(shoot?.code)
    }

    //I don't see my shoot logic
    const onNotListedClick = () => {
        if (selectedShoot) return
        setSelectedShoot('none')
        dispatch(setShoot(null))
        dispatch(
            addStepToChatHistory({
                id: flowSource,
                source: CHATBOT_SOURCES.USER,
                text: `[Selected "The shoot is not listed"]`
            })
        )
        triggerNextStep({
            value: null,
            trigger: pullShootsTriggerNotFound(flowSource)
        })
    }

    return (
        <StyledShoots>
            {isLoading ? (
                <p> {t('chatbot:pull_shoots:loading')} </p>
            ) : (
                <>
                    {!selectedShoot &&
                        shoots?.map((shoot, index) => (
                            <StyledShoot key={index} onClick={() => onSelectShoot(shoot)}>
                                <span>
                                    {t('chatbot:pull_shoots:airbnb_id')}
                                    {shoot?.entity?.external_id}
                                </span>
                                <p>
                                    <span>{t('chatbot:pull_shoots:host_name')}</span>
                                    <strong>{shoot?.host?.first_name}</strong>
                                </p>
                                <p>
                                    <span>{t('chatbot:pull_shoots:media')}</span>
                                    <strong>{capitalize(shoot?.task_media?.name)}</strong>
                                </p>
                            </StyledShoot>
                        ))}
                    {!selectedShoot && (
                        <Button
                            variant="secondary"
                            fullWidth
                            onClick={onNotListedClick}
                            style={{marginTop: shoots?.length > 0 ? theme.spacing * 4 : 0}}
                        >
                            {t('chatbot:pull_shoots:shoot_not_listed')}
                        </Button>
                    )}
                    {selectedShoot && selectedShoot !== 'none' && (
                        <StyledShoot selected>
                            <span>
                                {t('chatbot:pull_shoots:airbnb_id')}
                                {selectedShoot?.entity?.external_id}
                            </span>
                            <p>
                                <span> {t('chatbot:pull_shoots:host_name')}</span>{' '}
                                <strong>{selectedShoot?.host?.first_name}</strong>
                            </p>
                            <p>
                                <span>{t('chatbot:pull_shoots:media')}</span>{' '}
                                <strong>{capitalize(selectedShoot?.task_media?.name)}</strong>
                            </p>
                        </StyledShoot>
                    )}
                    {selectedShoot === 'none' && (
                        <Button variant="primaryBlack" fullWidth className="not-listed-button">
                            {t('chatbot:pull_shoots:shoot_not_listed')}
                        </Button>
                    )}
                </>
            )}
        </StyledShoots>
    )
}

PullShoots.propTypes = {
    flowSource: PropTypes.string,
    statuses: PropTypes.array,
    statusesNames: PropTypes.array,
    filterByDate: PropTypes.string,
    triggerNextStep: PropTypes.func
}
