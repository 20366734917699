import styled, {css} from 'styled-components'
import {InputText} from 'src/components/input-text/InputText'

export const StyledFormCard = styled.div`
    ${({theme: {colors, spacing, mediaQuery, typography, palette}}) => css`
        background: ${palette.neutral.white};
        border: 1px solid ${colors.transparentBlack};
        border-radius: 8px;
        ${typography.textMd};
        position: relative;
        width: 550px;
        min-height: 550px;
        padding: ${spacing * 14}px ${spacing * 20}px;

        & form {
            width: 100%;
        }
        ${mediaQuery.SM} {
            box-shadow: none;
            min-height: auto;
            padding: ${spacing * 8}px ${spacing * 4}px;
            width: auto;
        }
    `}
`

export const StyledHead = styled.div`
    ${({theme: {colors, spacing, typography, palette}}) => css`
        text-align: center;
        margin-bottom: ${spacing * 14}px;

        & h3 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral.black};
        }

        & h4 {
            ${typography.textXs};
            font-weight: 400;
            color: ${colors.darkGrey};
            margin-top: ${spacing * 6}px;
        }
    `}
`

export const StyledFields = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 14}px 0;
        width: auto;
        display: flex;
        flex-flow: column;
        gap: ${spacing * 2}px;
    `}
`

export const StyledActions = styled.div`
    ${({theme: {colors, typography, spacing, transition}}) => css`
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 8}px;

        & a {
            ${typography.textXs};
            font-weight: 600;
            color: ${colors.darkGrey};
            cursor: pointer;
            ${transition};
            &:hover {
                opacity: 0.8;
            }
        }
    `}
`

export const StyledInput = styled(InputText)`
    ${({theme: {spacing}}) => css`
        padding-bottom: ${spacing * 4}px;
    `}
`

export const StyledLabel = styled.label`
    ${({theme: {colors, typography, spacing}}) => css`
        ${typography.textSm};
        font-weight: 400;
        color: ${colors.lightBlack};
        margin-bottom: ${spacing * 2}px;
    `}
`
