import {useQuery} from '@tanstack/react-query'
import {httpGetFutureTransactionStats} from './adminPayments.http'
import {QUERY_KEYS} from '../../../queryClient'
import {useUserStore} from '../../user/store'

export const useGenerateTransactionsStats = serviceType => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    const query = useQuery({
        queryKey: [QUERY_KEYS.ADMIN_GENERATE_TRANSACTIONS_STATS, serviceType],
        queryFn: () => httpGetFutureTransactionStats(adminAccessToken, serviceType)
    })

    return {
        ...query,
        data: query?.data?.data
    }
}
