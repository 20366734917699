import {useWindowWidth} from '@react-hook/window-size'
import {DesktopCalendar} from '../../../../../../../components/calendar-events/desktop/DesktopCalendar'
import {screenWidth} from '../../../../../../../theme/breakpoints'
import {useActiveShootsByRange} from '../../../../../../../hooks/useActiveShootsByRange'
import {isPasteDate} from '../../../../../../../helpers/utils'
import {StyledMobileCalendar} from './style'

export const CalendarView = () => {
    const {data, loadingRange, isError, fetchedMonths, onChangeRange} = useActiveShootsByRange()
    const isLG = useWindowWidth() <= screenWidth.LG
    const isCurrentMonthLoading =
        loadingRange && isPasteDate(loadingRange.start_date) && !isPasteDate(loadingRange.end_date)

    return isLG ? (
        <StyledMobileCalendar
            events={data}
            isCurrentMonthLoading={isCurrentMonthLoading}
            loadingRange={loadingRange}
            isError={isError}
            fetchedMonths={fetchedMonths}
            onChangeRange={onChangeRange}
        />
    ) : (
        <DesktopCalendar
            events={data}
            isCurrentMonthLoading={isCurrentMonthLoading}
            loadingRange={loadingRange}
            isError={isError}
            fetchedMonths={fetchedMonths}
            onChangeRange={onChangeRange}
        />
    )
}
