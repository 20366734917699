import {useTranslation, Trans} from 'react-i18next'
import {HeroWrapper, StyledSubtitle, StyledTitle} from './style'
import {useUserStore} from 'src/features/user/store.ts'
import {useAssignmentsCount} from 'src/features/assignments/services/useAssignmentsCount.ts'

export const Hero = () => {
    const {t} = useTranslation()
    const currentUser = useUserStore(store => store.currentUser)
    const {data} = useAssignmentsCount()

    return (
        <HeroWrapper>
            <StyledTitle>{t('dashboard:hero:title', {name: currentUser?.name})}</StyledTitle>
            <StyledSubtitle>
                <Trans
                    i18nKey="dashboard:hero:subtitle"
                    components={[<u />]}
                    values={{
                        totalAssignments: data ? data.assignments_request ?? 0 : '...',
                        totalShoots: data ? data.assignments_current ?? 0 : '...'
                    }}
                />
            </StyledSubtitle>
        </HeroWrapper>
    )
}
