import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import dayjs, {formatLocaleDate} from 'src/dayjs.ts'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {CheckIcon, XCloseIcon} from 'src/components/icon'
import {StyledButtons, StyledHostDates} from './style.ts'
import {useAcceptAssignment} from 'src/features/assignment/services/useAcceptAssignment.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {useState} from 'react'
import {DeclineAssignmentModal} from 'src/features/assignment/components/decline-modal/DeclineAssignmentModal.tsx'
import {ROUTES} from 'src/helpers/constants.ts'
import {useNavigate} from 'react-router'

const getExpirationDifference = (expirationDate: string): {unit: 'day' | 'hour'; value: number} => {
    const expirationDifferenceInHours = dayjs(expirationDate).diff(dayjs(), 'hour')
    const expirationDifferenceInDays = dayjs(expirationDate).diff(dayjs(), 'day')

    if (expirationDifferenceInHours > 24) {
        return {
            unit: 'day',
            value: expirationDifferenceInDays >= 0 ? expirationDifferenceInDays : 0
        }
    }

    return {
        unit: 'hour',
        value: expirationDifferenceInHours >= 0 ? expirationDifferenceInHours : 0
    }
}

const formatHostDate = (date?: string) => (date ? formatLocaleDate(date, 'llll') : '-')

export const PendingDescription = ({assignment, serviceType}: {assignment: Assignment; serviceType: ServiceType}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)
    const acceptAssignmentMutation = useAcceptAssignment(assignment)
    const expirationDifference = getExpirationDifference(assignment.expiration_date)

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>{t('assignment:make_a_decision')}</AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    <Trans
                        i18nKey={
                            assignment.is_bulk_assignment
                                ? 'assignment:descriptions:pending:paragraph_bulk'
                                : `assignment:descriptions:pending:paragraph:${serviceType}`
                        }
                        components={{strong: <strong />}}
                        values={{
                            difference: t(
                                expirationDifference.unit == 'day' ? 'commons:x_days' : 'assignment:x_hours',
                                {
                                    count: expirationDifference.value
                                }
                            )
                        }}
                    />
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            <Flexbox direction="column" gap={2.5} align="center">
                <StyledButtons>
                    <Button
                        variant="primarySuccess"
                        onClick={() => acceptAssignmentMutation.mutate()}
                        disabled={acceptAssignmentMutation.isLoading}
                    >
                        <CheckIcon />
                        {t('commons:accept')}
                        {acceptAssignmentMutation.isLoading && <Spinner size={20} />}
                    </Button>
                    <Button
                        variant="primaryDanger"
                        onClick={() => setIsDeclineModalOpen(true)}
                        disabled={acceptAssignmentMutation.isLoading}
                    >
                        <XCloseIcon />
                        {t('commons:decline')}
                    </Button>
                </StyledButtons>
                <StyledHostDates>
                    <Trans
                        i18nKey="assignment:host_dates"
                        values={{
                            count: assignment.quote.host_availability_dates.length,
                            date1: formatHostDate(assignment.quote?.host_availability_dates?.[0]?.starting_time),
                            date2: formatHostDate(assignment.quote?.host_availability_dates?.[1]?.starting_time)
                        }}
                        components={{b: <b />}}
                    />
                </StyledHostDates>
            </Flexbox>
            {isDeclineModalOpen && (
                <DeclineAssignmentModal
                    assignment={assignment}
                    onClose={() => setIsDeclineModalOpen(false)}
                    onSuccess={() => navigate(ROUTES.INDEX)}
                    serviceType={serviceType}
                />
            )}
        </AssignmentDescriptionWrapper>
    )
}
