import {PullShoots} from '../custom-components/pull-shoots/PullShoots'
import {GetIssueDate} from '../custom-components/GetIssueDate'
import {TicketSubmit} from '../custom-components/TicketSubmit'
import {CHATBOT_SOURCES, TASK_STATUSES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import i18next from 'i18next'

export const mb22FlowSteps = (isInShootPage, shoot) => [
    {
        id: 'MB2.2',
        message: i18next.t('chatbot:mb22:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.2',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please select the shoot you want payment information for'
                })
            )
            return 'MB2.2_pull'
        }
    },
    {
        id: 'MB2.2_pull',
        component: (
            <PullShoots
                flowSource="MB2.2_pull"
                statuses={[TASK_STATUSES.ASSETS_IN_REVIEW, TASK_STATUSES.COMPLETED]}
                filterByDate="1" // 1 = get only shoots in the last month
            />
        ) // Trigger managed by the component
    },
    {
        id: 'MB2.2_issuedate',
        component: <GetIssueDate flowSource="MB2.2_issuedate" />,
        asMessage: true
    }, // Trigger managed by the component

    // ID not-found flow
    {
        id: 'MB2.2_notfound',
        message: i18next.t('chatbot:mb22:mb2.2_notfound'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.2_notfound',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'For which airbnb ID you want to have information?'
                })
            )
            return 'MB2.2_notfound_input'
        }
    },
    {
        id: 'MB2.2_notfound_input',
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.2_notfound_input',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB2.2_ticket'
        }
    },
    {
        id: 'MB2.2_ticket',
        component: <TicketSubmit reason="payment_issue" isInShootPage={isInShootPage} shoot={shoot} />,
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB2.2_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        }
    }
]
