import {useMemo} from 'react'
import {useAdminHostApprovalTaskRooms} from 'src/features/admin-host-approval/services/useAdminHostApprovalTaskRooms'
import {groupBy, ObjectEntries, ObjectKeys, raise} from 'src/helpers/helpers'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {useAccessibilityFeatures} from 'src/features/admin-host-approval/services/useAccessibilityFeatures'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    StyledHostApprovalRoomsAmenitiesImagesEmpty,
    StyledHostApprovalRoomsAmenitiesTitle,
    StyledRoomsAmenitiesImagesGrid
} from './style'
import {AdminHostApprovalRoomsAmenitiesImagesLoader} from './AdminHostApprovalRoomsAmenitiesImagesLoader'
import {AdminHostApprovalRoomAmenityImageCard} from 'src/features/admin-host-approval/components/admin-host-approval-room-amenity-image-card/AdminHostApprovalRoomAmenityImageCard'

export const AdminHostApprovalRoomsAmenitiesImages = ({
    taskId,
    taskImages,
    isLoadingTaskImages,
    showUploadedToggle,
    emptyResultMessage
}: {
    taskId: number
    taskImages: AdminHostApprovalTaskImage[]
    isLoadingTaskImages?: boolean
    showUploadedToggle?: boolean
    emptyResultMessage: string
}) => {
    const {data: rooms, isLoading: isLoadingRooms} = useAdminHostApprovalTaskRooms({
        staleTime: Infinity
    })
    const {data: amenities, isLoading: isLoadingAmenities} = useAccessibilityFeatures({
        staleTime: Infinity
    })

    const roomsAmenitiesImages = useMemo((): Record<
        NonNullable<AdminHostApprovalTaskImage['room_id']>,
        Record<NonNullable<AdminHostApprovalTaskImage['amenity_id']>, AdminHostApprovalTaskImage[]>
    > | null => {
        // Group images result by room and amenity before display data
        function createNestedMap<T>(
            items: Iterable<T>,
            roomIdSelector: (item: T) => PropertyKey,
            amenityIdSelector: (item: T) => PropertyKey
        ): Record<PropertyKey, Record<PropertyKey, T[]>> {
            const firstGroup = groupBy(items, roomIdSelector)
            const mapResult = {} as Record<PropertyKey, Record<PropertyKey, T[]>>

            for (const [roomId, roomItem] of ObjectEntries(firstGroup)) {
                mapResult[roomId] = groupBy(roomItem, amenityIdSelector)
            }

            return mapResult
        }

        return createNestedMap(
            taskImages,
            ({room_id}) => room_id as NonNullable<AdminHostApprovalTaskImage['room_id']>,
            ({amenity_id}) => amenity_id as NonNullable<AdminHostApprovalTaskImage['amenity_id']>
        )
    }, [taskImages])

    if (isLoadingTaskImages || isLoadingRooms || isLoadingAmenities) {
        return <AdminHostApprovalRoomsAmenitiesImagesLoader />
    }

    if (!taskImages.length) {
        return (
            <StyledHostApprovalRoomsAmenitiesImagesEmpty>
                <StyledHostApprovalRoomsAmenitiesTitle>{emptyResultMessage}</StyledHostApprovalRoomsAmenitiesTitle>
            </StyledHostApprovalRoomsAmenitiesImagesEmpty>
        )
    }

    if (!rooms || !amenities || !roomsAmenitiesImages) {
        return raise('Failed to render rooms amenities images')
    }

    return ObjectKeys(roomsAmenitiesImages).map(roomId => (
        <Flexbox key={roomId} direction="column" gap={4}>
            <StyledHostApprovalRoomsAmenitiesTitle $isBold>
                {rooms.find(room => room.id == roomId)?.name}
            </StyledHostApprovalRoomsAmenitiesTitle>

            {ObjectKeys(roomsAmenitiesImages[roomId]).map(amenityId => (
                <Flexbox direction="column" key={amenityId}>
                    <StyledHostApprovalRoomsAmenitiesTitle>
                        {amenities.find(amenity => amenity.id == amenityId)?.name}
                    </StyledHostApprovalRoomsAmenitiesTitle>
                    <StyledRoomsAmenitiesImagesGrid>
                        {roomsAmenitiesImages[roomId][amenityId].map(image => (
                            <AdminHostApprovalRoomAmenityImageCard
                                key={image.id}
                                taskId={taskId}
                                image={image}
                                isSelected={false}
                                showUploadedToggle={showUploadedToggle}
                            />
                        ))}
                    </StyledRoomsAmenitiesImagesGrid>
                </Flexbox>
            ))}
        </Flexbox>
    ))
}
