import {useTranslation} from 'react-i18next'
import {StyledNoResultsBanner} from './style'

export const NoResultsBanner = () => {
    const {t} = useTranslation()

    return (
        <StyledNoResultsBanner>
            <h3>{t('commons:no_results')}</h3>
        </StyledNoResultsBanner>
    )
}
