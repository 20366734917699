import styled, {css} from 'styled-components'
import {ReactNode} from 'react'

export const StyledCheckBoxContainer = styled.div<{label?: ReactNode}>`
    ${({theme: {spacing}, label}) => css`
        display: flex;
        gap: ${label ? spacing * 2 : 0}px;
    `}
`

export const StyledCheckBoxInputWrapper = styled.label<{$labelPosition: 'left' | 'right'}>`
    ${({theme: {spacing}, $labelPosition}) => css`
        display: flex;
        flex-direction: ${$labelPosition == 'right' ? 'row' : 'row-reverse'};
        width: max-content;
        max-width: 100%;
        gap: ${spacing}px;
    `}
`

export const StyledCheckBox = styled.input<{invisible?: boolean}>`
    ${({theme: {palette, shadows}, invisible}) => css`
        background-color: ${palette.neutral.white};
        width: 20px;
        height: 20px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${palette.neutral['300']};
        transition: ease-in-out 300ms;
        cursor: pointer;

        ${invisible &&
        css`
            visibility: hidden;
            transition: none;
        `}

        &:checked {
            background: ${palette.primary['600']};
            border: 1px solid ${palette.primary['600']};
            content: ${`url("data:image/svg+xml,%3Csvg viewBox='-4 -4 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='${palette.neutral.white.replace(
                '#',
                '%23'
            )}' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");`};
        }

        &:disabled:checked {
            background: ${palette.neutral['100']};
            border: 1px solid ${palette.neutral['300']};
            cursor: not-allowed;
            content: ${`url("data:image/svg+xml,%3Csvg viewBox='-4 -4 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='${palette.neutral[
                '300'
            ].replace(
                '#',
                '%23'
            )}' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");`};
        }

        &:disabled {
            background: ${palette.neutral['100']};
            border: 1px solid ${palette.neutral['300']};
            cursor: not-allowed;
        }

        &:not(:disabled):hover {
            border: 1px solid ${palette.primary['300']};
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 2px ${palette.primary['100']}`};
        }
    `}
`

export const StyledCheckBoxLabel = styled.label`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['900']};
        cursor: pointer;
        & > a {
            text-decoration: underline;
            cursor: pointer;
        }
    `}
`
