import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {FileDropzone} from './dropzone/Dropzone'
import {PaymentsAnalyticsSection} from './analytics/Analytics'
import {Select} from '../../../../components/select/Select'
import {Chip} from '../../../../components/chip/Chip'
import {ClockIcon} from '../../../../assets/icons/ClockIcon'
import {CheckIcon} from '../../../../assets/icons/CheckIcon'
import {showAlert} from '../../../../store/appGenerics'
import {errorHandler, formatLocaleDate} from '../../../../helpers/utils'
import {statusesData} from '../../../../helpers/statuses'
import {PAYMENTS_TYPES_ARRAY, PAYMENTS_STATS, PAYMENTS_FORM_MODEL} from '../../../../helpers/constants'
import {
    PaymentFormWrapper,
    StyledParagraph,
    SubtitleWrapper,
    StyledSubtitle,
    StyledCustomDateTimeInput,
    SubheaderWrapper
} from './style'
import {Skeleton} from '../../../../components/skeleton/Skeleton'
import {usePaymentsStatusStats} from '../../../../features/admin-payments/services/usePaymentsStatusStats'
import {QUERY_KEYS, queryClient} from '../../../../queryClient'
import {httpUploadPaymentsCsv} from '../../../../features/admin-payments/services/adminPayments.http'
import {useUserStore} from '../../../../features/user/store'

const maxUploadSize = 10 // MB
const maxUploadFiles = 1

export const PaymentsImport = () => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [uploadProgress, setUploadProgress] = useState(0)
    const [isProcessing, setIsProcessing] = useState(false)
    const [files, setFiles] = useState([])
    const {data: paymentsStats, isLoading: isLoadingStats} = usePaymentsStatusStats()

    //Validation schema & Form init
    const validationSchema = Yup.object().shape({
        [PAYMENTS_FORM_MODEL.PAYMENT_DATE]: Yup.string().required(t('errors:required')),
        [PAYMENTS_FORM_MODEL.PAYMENT_TYPE]: Yup.object().required(t('errors:required'))
    })
    const {
        reset,
        control,
        getValues,
        formState: {errors, isValid}
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        nativeValidation: false,
        resolver: yupResolver(validationSchema)
    })

    const onUploadProgress = progressEvent => {
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }

    const onUpload = acceptedFiles => {
        if (!acceptedFiles.length) return
        setFiles([...files, ...acceptedFiles])
    }

    const onProcessPayments = async () => {
        // Append each file to the form data
        const formData = new FormData()
        // Append each file to the form data
        for (let i = 0; i < files.length; i++) {
            formData.append(`files[]`, files[i])
        }
        formData.append(
            PAYMENTS_FORM_MODEL.PAYMENT_DATE,
            formatLocaleDate(getValues(PAYMENTS_FORM_MODEL.PAYMENT_DATE), 'YYYY-MM-DD')
        )
        formData.append(PAYMENTS_FORM_MODEL.PAYMENT_TYPE, getValues(PAYMENTS_FORM_MODEL.PAYMENT_TYPE)?.value)

        try {
            setIsProcessing(true)
            await httpUploadPaymentsCsv(adminAccessToken, formData, onUploadProgress)
            await queryClient.invalidateQueries([QUERY_KEYS.ADMIN_PAYMENTS_STATUS_STATS])
            dispatch(showAlert({message: 'Payments file processed correctly', level: 'success'}))
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsProcessing(false)
            setFiles([])
            reset()
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <SubtitleWrapper>
                        <StyledSubtitle>{t('admin:payments:subtitle')}</StyledSubtitle>
                        {isLoadingStats ? (
                            <Skeleton width="200" height={22} />
                        ) : (
                            <>
                                {paymentsStats[PAYMENTS_STATS.PENDING_PAYMENT] > 0 ? (
                                    <Chip
                                        text={t('admin:payments:pending_payments', {
                                            count: paymentsStats[PAYMENTS_STATS.PENDING_PAYMENT]
                                        })}
                                        rounded
                                        borderColor={statusesData.PENDING_ASSETS.borderColor}
                                        borderStyle={statusesData.PENDING_ASSETS.borderStyle}
                                        color={statusesData.PENDING_ASSETS.color}
                                        background={statusesData.PENDING_ASSETS.background}
                                        icon={<ClockIcon size={16} />}
                                        size="medium"
                                    />
                                ) : (
                                    <Chip
                                        text={t('admin:payments:no_payments_pending')}
                                        rounded
                                        borderColor={statusesData.COMPLETED.borderColor}
                                        borderStyle={statusesData.COMPLETED.borderStyle}
                                        color={statusesData.COMPLETED.color}
                                        background={statusesData.COMPLETED.background}
                                        icon={<CheckIcon size={16} />}
                                        size="medium"
                                    />
                                )}
                            </>
                        )}
                    </SubtitleWrapper>
                    <StyledParagraph>{t('admin:payments:process_payments:description')}</StyledParagraph>
                    <PaymentFormWrapper>
                        <StyledCustomDateTimeInput
                            label={t('admin:payments:process_payments:date_label')}
                            placeholder={t('admin:payments:process_payments:date_placeholder')}
                            name={PAYMENTS_FORM_MODEL.PAYMENT_DATE}
                            disabled={isProcessing}
                            showTimeSelect={false}
                            control={control}
                            errors={errors?.date}
                            dateFormat="yyyy-MM-dd"
                            isClearable={false}
                            maxDate={new Date()}
                            showNativeInput={false}
                            hasIcon={false}
                            required
                        />
                        <Select
                            label={t('admin:payments:process_payments:type_label')}
                            placeholder={t('admin:payments:process_payments:type_placeholder')}
                            name={PAYMENTS_FORM_MODEL.PAYMENT_TYPE}
                            control={control}
                            errors={errors.type}
                            controlCustomStyle={{
                                border: `1px solid #ccc`,
                                minWidth: 176,
                                minHeight: 32,
                                borderRadius: 6,
                                padding: '0 10px'
                            }}
                            options={PAYMENTS_TYPES_ARRAY}
                            disabled={isProcessing}
                            required
                        />
                    </PaymentFormWrapper>
                    <StyledParagraph>{t('admin:payments:dropzone:title')}</StyledParagraph>
                    <FileDropzone
                        onDropCb={onUpload}
                        onUpload={onProcessPayments}
                        maxFiles={maxUploadFiles}
                        multiple={false}
                        disableDropzone={isProcessing}
                        isDisabled={isProcessing || files.length === 0 || !isValid}
                        acceptedFiles={files}
                        uploadProgress={uploadProgress}
                        onReset={() => setFiles([])}
                        isProcessing={isProcessing}
                        uploadSizeLimit={maxUploadSize} // MB
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <SubheaderWrapper>
                        <StyledSubtitle>{t('admin:payments:uploaded_recently:title')}</StyledSubtitle>
                        <PaymentsAnalyticsSection isLoading={isLoadingStats} paymentsStats={paymentsStats} />
                    </SubheaderWrapper>
                </div>
            </div>
        </>
    )
}

PaymentsImport.displayName = 'PaymentsImport'
