import {useEffect, useMemo, useState} from 'react'
import {StyledProgressBarWrapper} from './style'
import {useTranslation} from 'react-i18next'

export const PhotosUploadProgress = ({files, uploadState}) => {
    const [counter, setCounter] = useState(0)
    const {t} = useTranslation()

    const totalFiles = useMemo(() => {
        return files.filter(file => Object.hasOwn(file, 'uploadStatus'))
    }, [uploadState])

    useEffect(() => {
        const completedFiles = files.filter(file => file.uploadStatus === 'success')
        if (completedFiles.length > counter) {
            setCounter(completedFiles.length)
        }
    }, [files])

    return (
        <div>
            <h3>
                {!totalFiles.length
                    ? t('single_shooting:waiting')
                    : t('single_shooting:uploading_n_assets', {uploadedCount: counter, totalCount: totalFiles.length})}
            </h3>
            <StyledProgressBarWrapper
                align="center"
                gap={1.5}
                $width="100%"
                $uploadProgress={Math.round((counter / totalFiles.length) * 100)}
            >
                <div>
                    <div />
                </div>
                <span>{`${Math.round((counter / totalFiles.length) * 100)}%`}</span>
            </StyledProgressBarWrapper>
        </div>
    )
}
