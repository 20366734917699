import {
    StyledSubheader,
    StyledData,
    StyledEligible,
    StyledButtonsWrapper,
    StyledTopButtons,
    StyledBackButton,
    StyledBottomButton
} from './style'
import {Button} from '../../../../../../components/button/Button'
import {errorHandler, openLinkInNewTab, renderTableMatterportStatus} from '../../../../../../helpers/utils'
import {useTranslation} from 'react-i18next'
import {Chip} from '../../../../../../components/chip/Chip'
import {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {taskSelector, toDeleteListSelector, toUploadListSelector} from '../../../../../../store/matterport'
import {httpGetUserImpersonate} from '../../../../../../http-requests/admin/users.http'
import {httpCompleteCheckTask} from '../../../../../../http-requests/admin/matterport.http'
import {Spinner} from '../../../../../../components/spinner-legacy/Spinner'
import {CancelIcon, CheckIcon} from '../../../../../../theme/icons'
import {MATTERPORT_STATUSES, ROUTES} from '../../../../../../helpers/constants'
import {useNavigate, useParams} from 'react-router'
import {useUserStore} from '../../../../../../features/user/store'

export const ConfirmViewSubheader = ({setSubheaderHeightCb}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id: taskId} = useParams()
    const setUser = useUserStore(store => store.setUser)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const task = useSelector(taskSelector)
    const toDeleteList = useSelector(toDeleteListSelector)
    const toUploadList = useSelector(toUploadListSelector)
    const allImages = [...toDeleteList, ...toUploadList]
    const status = renderTableMatterportStatus(task?.task_matterport?.status)
    const [isLoading, setIsLoading] = useState(false)
    const ref = useRef()

    useEffect(() => {
        setSubheaderHeightCb(ref?.current?.offsetHeight)
    }, [ref])

    const onImpersonate = async () => {
        try {
            setIsLoading(true)
            const {data: accessToken} = await httpGetUserImpersonate(adminAccessToken, task.photographer_id)
            setUser({
                user: {},
                access_token: accessToken,
                refresh_token: ''
            })
            openLinkInNewTab(task.mediabox_link)
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const linkButtons = [
        {text: 'MYS', onClick: () => openLinkInNewTab(task?.mys_link)},
        {text: 'PDP', onClick: () => openLinkInNewTab(task?.pdp_link)},
        {text: 'DARYL', onClick: () => openLinkInNewTab(task?.daryl_link)},
        {
            text: 'Elevate',
            onClick: () => openLinkInNewTab(task?.host_portal_link)
        },
        {text: 'Mediabox', onClick: onImpersonate}
    ]

    const onSubmit = async () => {
        const dataToSend = allImages.map(image => ({
            id: image.id,
            checked: image.checked,
            action: image.hostAction
        }))

        try {
            setIsLoading(true)
            await httpCompleteCheckTask(adminAccessToken, taskId, dataToSend)
            navigate(ROUTES.ADMIN_MATTERPORT, {
                state: MATTERPORT_STATUSES.COMPLETED
            })
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <StyledSubheader ref={ref}>
            <StyledBackButton
                onClick={() =>
                    navigate(ROUTES.ADMIN_MATTERPORT, {
                        state: task?.task_matterport?.status
                    })
                }
            />
            <div className="container">
                <div className="row">
                    <StyledData className="col-4">
                        <div>
                            <label>{t('admin:listing_title')}</label>
                            <span>{task?.title || '-'}</span>
                        </div>
                        <div>
                            <label>
                                {t('admin:listing_id')}
                                {':'}
                            </label>
                            <span>{task?.external_id || '-'}</span>
                        </div>
                        <div>
                            <label>{t('commons:publisher')}</label>
                            <span>
                                {task?.task_matterport?.uploader_admin
                                    ? `${task?.task_matterport?.publisher_admin?.first_name} ${task?.task_matterport?.publisher_admin?.last_name}`
                                    : '-'}
                            </span>
                        </div>
                        <div>
                            <label>
                                {t('commons:status')}
                                {':'}
                            </label>
                            <span>
                                <Chip
                                    rounded
                                    textUppercase
                                    borderColor={status.color}
                                    background={status.color}
                                    color="white"
                                    text={t(status.label)}
                                />
                            </span>
                        </div>
                        <StyledEligible>
                            <label>
                                {t('admin:eligible_for_accessibility')}
                                {':'}
                            </label>
                            <span>
                                {task?.entity?.exclude_for_category ? (
                                    <>
                                        {t('commons:no')} <CancelIcon />
                                    </>
                                ) : (
                                    <>
                                        {t('commons:yes')} <CheckIcon />
                                    </>
                                )}
                            </span>
                        </StyledEligible>
                    </StyledData>
                    <StyledButtonsWrapper className="col-8">
                        <StyledTopButtons>
                            {linkButtons.map((button, index) => (
                                <Button onClick={button.onClick} size="sm" variant="secondary" key={index}>
                                    {button.text}
                                </Button>
                            ))}
                        </StyledTopButtons>
                        <StyledBottomButton>
                            <Button onClick={onSubmit} variant="primaryBlack">
                                {t('commons:completed')}
                            </Button>
                        </StyledBottomButton>
                    </StyledButtonsWrapper>
                </div>
            </div>

            {isLoading && <Spinner overlayFullscreen />}
        </StyledSubheader>
    )
}
