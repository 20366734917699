import {Skeleton} from '../../../../components/skeleton/Skeleton'
import PropTypes from 'prop-types'
import {StyledTransactionsStatsCard} from './style'

export const TransactionsStatsCard = ({
    isLoading,
    label,
    count
}: {
    isLoading?: boolean
    label: string
    count: number
}) => (
    <StyledTransactionsStatsCard>
        {isLoading ? (
            <>
                <Skeleton width="100" height={21} />
                <Skeleton width="200" height={29} />
            </>
        ) : (
            <>
                <p>{label}</p>
                <h6>{count}</h6>
            </>
        )}
    </StyledTransactionsStatsCard>
)

TransactionsStatsCard.propTypes = {
    isLoading: PropTypes.bool,
    label: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
}
TransactionsStatsCard.displayName = 'TransactionsStatsCard'
