import styled, {css} from 'styled-components'

export const StyledRadio = styled.label<{$checked: boolean; $fullWidthRadio: boolean}>`
    ${({theme: {colors, spacing, transition}, $checked, $fullWidthRadio}) => css`
        background: ${$checked && $fullWidthRadio ? colors.orangeGradient : $checked ? colors.lightGrey : 'none'};
        border: ${$checked && $fullWidthRadio
            ? `2px solid ${colors.darkRedRgba}`
            : $fullWidthRadio
            ? `1px solid ${colors.gainsboroGrey}`
            : 'none'};
        padding: ${spacing * 4}px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
        flex-direction: row;
        position: relative;
        ${transition};
        color: ${colors.lightBlack};
        & > div {
            display: flex;
            align-items: center;
            gap: ${spacing * 4}px;
        }
        ,
        &:hover {
            background: ${$checked && $fullWidthRadio ? colors.orangeGradient : $checked ? colors.lightGrey : 'none'};
            & input {
                ${transition};
                border-color: ${$fullWidthRadio ? `1px solid ${colors.gainsboroGrey}` : colors.lightBlack};
            }
        }
    `}
`

export const StyledLabel = styled.span<{$disabled: boolean}>`
    ${({theme: {colors, typography}, $disabled}) => css`
        color: ${colors.lightBlack};
        text-align: left !important;
        ${typography.textXs};
        font-weight: 500;
        margin-left: 4px;
        cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    `}
`

export const StyledInput = styled.input<{$isSmall: boolean; $fillRadioImg: boolean}>`
    ${({theme: {colors, transition, palette}, $isSmall, $fillRadioImg}) => css`
		flex-shrink: 0;
      width: ${$isSmall ? '12px' : '28px'};
      height: ${$isSmall ? '12px' : '28px'};
      border: 2px solid ${colors.lightBlack};
      border-radius: 100%;
      appearance: none;
      color: ${colors.lightBlack};
      ${transition};
      &:checked {
        color: ${palette.neutral.white};
        border-color: ${colors.lightBlack};
        background: ${colors.lightBlack};
      }

      &[type=radio]:checked {
        content: ${
            $fillRadioImg
                ? `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjgiIGZpbGw9IiNEMzFFNjYiLz4KPGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjMiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=")`
                : `url("data:image/svg+xml,%3Csvg viewBox='-4 -4 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`
        };
        fill: ${palette.neutral.white};
        border-color: ${colors.lightBlack};
      },

    	&[type=radio]:not:(disabled) {
      		cursor: pointer;
		}
      &[type=radio]:disabled {
	  	cursor: not-allowed;
        background: ${colors.lightGrey};
        border: none;
      },
  `}
`

export const StyledSmall = styled.small`
    ${({theme: {colors}}) => css`
        display: grid;
        position: relative;
        grid-row: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        color: ${colors.red};
    `}
`

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0 !important;
`

export const StyledHelpText = styled.label`
    ${({theme: {colors, typography}}) => css`
        ${typography.textXs};
        color: ${colors.darkGrey};
        line-height: 20px;
    `}
`
