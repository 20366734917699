import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {formatLocaleDate, openShootInNewTab} from '../../../helpers/utils'

import {
    StyledEvent,
    StyledEventTitle,
    StyledPopupEvent,
    StyledPopupEventTitle,
    StyledPopupEventLabel,
    StyledPopupEventDates,
    StyledPopupEventIcon
} from './styles'
import {SERVICE_TYPE} from '../../../helpers/constants'
import {SearchIcon} from '../../../assets/icons/SearchIcon'
import {VideoIcon} from '../../../assets/icons/VideoIcon'
import {retrieveCombinedStatus} from '../../../helpers/statuses'

export const EVENT_TYPES = {
    POPUP: 'popup',
    CALENDAR: 'calendar'
}

export const Event = ({event, type = null}) => {
    const {t} = useTranslation()
    const eventData = event?.resource
    const isWalkthrough = eventData.task_service?.id === SERVICE_TYPE.WALKTHROUGH

    const status = eventData?.status
    const taskStatus = eventData.task_status?.status
    const coordinationStatus = eventData.task_status?.coordination_status

    const {
        label: statusLabel,
        color: statusColor,
        background: statusBackground,
        borderStyle: statusBorderStyle,
        icon,
        borderColor: statusBorderColor
    } = retrieveCombinedStatus(taskStatus, status, false, coordinationStatus)

    const onClickEvent = () => {
        openShootInNewTab(event?.resource?.code)
    }
    return (
        <>
            {type !== EVENT_TYPES.POPUP ? (
                <StyledEvent
                    id={`event-${event.resource.id}`}
                    key={event.resource.id}
                    $borderColor={statusBorderColor}
                    $borderStyle={statusBorderStyle}
                    $textColor={statusColor}
                    $background={statusBackground}
                >
                    {icon}
                    <StyledEventTitle>{event.title}</StyledEventTitle>
                </StyledEvent>
            ) : (
                <StyledPopupEvent
                    id={event.id}
                    key={event.id}
                    onClick={onClickEvent}
                    $borderColor={statusBorderColor}
                    $borderStyle={statusBorderStyle}
                    $textColor={statusColor}
                    $background={statusBackground}
                >
                    <StyledPopupEventIcon>
                        {isWalkthrough ? <SearchIcon size={16} /> : <VideoIcon size={16} />}
                    </StyledPopupEventIcon>
                    <StyledPopupEventTitle>{event.title}</StyledPopupEventTitle>
                    <StyledPopupEventDates>{`${formatLocaleDate(event.start, 'hh:mm A')} - ${formatLocaleDate(
                        event.end,
                        'hh:mm A'
                    )}`}</StyledPopupEventDates>
                    <StyledPopupEventLabel $textColor={statusColor}>
                        {icon}
                        {t(statusLabel)}
                    </StyledPopupEventLabel>
                </StyledPopupEvent>
            )}
        </>
    )
}

Event.propTypes = {
    event: PropTypes.object,
    type: PropTypes.oneOf(Object.values(EVENT_TYPES))
}
