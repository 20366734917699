import axios from 'axios'
import {DEFAULT_LIMIT, RESPONSE_TYPES} from '../../helpers/constants'

export const httpGetQuoteByCode = (adminAccessToken, quoteCode) => {
    return axios.get(`/admin/quotes/${quoteCode}`, {
        headers: {
            Authorization: `Bearer ${adminAccessToken}`
        },
        params: {
            response_type: RESPONSE_TYPES.EXTENDED
        }
    })
}

export const httpGetQuoteLogs = (adminAccessToken, quoteId, page, status) => {
    return axios
        .get(`/admin/quotes/${quoteId}/logs`, {
            headers: {
                Authorization: `Bearer ${adminAccessToken}`
            },
            params: {
                response_type: RESPONSE_TYPES.EXTENDED,
                limit: DEFAULT_LIMIT,
                page,
                status
            }
        })
        .then(response => response.data)
}

export const httpPostQuoteAdjustment = (adminAccessToken, quoteId, payload) => {
    return axios.post(`/admin/quotes/${quoteId}/adjustment`, payload, {
        headers: {
            Authorization: `Bearer ${adminAccessToken}`
        }
    })
}
