import styled, {css} from 'styled-components'

export const StyledAirbnbID = styled.div`
    ${({theme: {typography, palette, mediaQuery}}) => css`
        display: flex;
        flex-direction: column;

        & a {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral.black};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: underline;
            cursor: pointer;
        }

        ${mediaQuery.LG} {
            align-items: flex-end;
        }
    `}
`

export const StyledDescription = styled.span`
    ${({theme: {colors, typography}}) => css`
        font-weight: 400;
        ${typography.textXs};
        color: ${colors.darkGrey};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`
