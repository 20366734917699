import styled, {css} from 'styled-components'

export const StyledPaymentCardWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;
        align-items: center;
    `}
`

export const StyledPaymentCard = styled.div`
    ${({theme: {colors, spacing, palette}}) => css`
        display: flex;
        justify-content: space-between;
        width: 368px;
        background: ${palette.neutral.white};
        border: 1px solid ${colors.lightGrey};
        border-radius: 12px;
        padding: ${spacing * 6}px;
        gap: ${spacing * 2}px;
    `}
`

export const StyledPaymentCardTitle = styled.div`
    ${({theme: {colors, typography, spacing}}) => css`
        & > p {
            font-weight: 500;
            ${typography.textSm};
            color: ${colors.darkGrey};
            margin-bottom: ${spacing * 2}px;
        }

        & > h6 {
            font-weight: 600;
            ${typography.displaySm};
            margin: 0;
        }
    `}
`
