import styled, {css} from 'styled-components'
import {Container} from 'src/components/container/Container.tsx'

export const StyledAdminHostApproval = styled.div(
    ({theme: {spacing}}) => css`
        height: 100%;
        overflow-y: hidden;
        display: grid;
        grid-template-rows: auto auto minmax(0, 1fr);
        padding: ${spacing * 16}px 0;
        gap: ${spacing * 8}px;
    `
)

export const StyledAdminHostApprovalHero = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledAdminHostApprovalTitles = styled.hgroup(
    ({theme: {spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        & h1 {
            ${typography.displaySm};
            font-weight: 700;
        }
        & h2 {
            ${typography.textLg};
        }
    `
)

export const StyledTabs = styled(Container)(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
    `
)
