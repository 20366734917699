import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {ROUTES, GUIDE_LINK} from '../../helpers/constants'
import {NavigationIcon} from '../../assets/icons/NavigationIcon'
import {VideoIcon} from '../../assets/icons/VideoIcon'

import {SelectMenuModal} from '../select-menu-modal/SelectMenuModal'
import {languageSelector} from '../../store/appGenerics'
import {appLanguages} from '../../translations/i18n'

import {
    StyledLogo,
    StyledActions,
    StyledFeedbackLink,
    StyledPublicHeader,
    ChangeLanguageWrapper,
    StyledChangeLanguage
} from './style'
import {Flexbox} from '../flexbox/Flexbox'

export const PublicHeader = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const [showModal, setShowModal] = useState(false)
    const pathname = location.pathname

    const currentLanguage = useSelector(languageSelector)
    const currentLanguageObj = appLanguages.find(
        e => e.value === currentLanguage || e.value === localStorage.getItem('i18nextLng')
    )
    const isAdmin = pathname.includes(ROUTES.ADMIN)

    return (
        <>
            <StyledPublicHeader>
                <div className="container-fluid container-md h-100 px-0 px-md-2">
                    <div className="row h-100">
                        <Flexbox justify="center" align="center" className="col-auto">
                            <StyledLogo to={isAdmin ? ROUTES.ADMIN_LOGIN : ROUTES.LOGIN}>{'Mediabox'}</StyledLogo>
                        </Flexbox>
                        <StyledActions className="col">
                            {!isAdmin && (
                                <StyledFeedbackLink href={GUIDE_LINK} target="_blank">
                                    <VideoIcon />
                                    <span>{t('menu:video_walkthrough')}</span>
                                </StyledFeedbackLink>
                            )}
                            <ChangeLanguageWrapper>
                                <StyledChangeLanguage onClick={() => setShowModal(true)}>
                                    <NavigationIcon size={20} />
                                    <span>{currentLanguageObj?.label}</span>
                                </StyledChangeLanguage>
                            </ChangeLanguageWrapper>
                        </StyledActions>
                    </div>
                </div>
            </StyledPublicHeader>
            {showModal && <SelectMenuModal onClose={() => setShowModal(false)} />}
        </>
    )
}
