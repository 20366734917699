import styled, {css} from 'styled-components'

export const StyledTableView = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${spacing * 6}px;
        height: 100%;
    `
)
