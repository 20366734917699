import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {useTranslation} from 'react-i18next'
import {formatLocaleDate} from 'src/dayjs.ts'
import {Button} from 'src/components/button/Button.tsx'
import {useState} from 'react'
import {ScheduleModal} from 'src/features/assignment/components/schedule-modal/ScheduleModal.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {useHostResponsive} from 'src/features/assignment/services/useHostResponsive.ts'

export const AcceptedHostUnresponsiveDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
    const contactHostMutation = useHostResponsive({assignmentId: assignment.id, taskId: assignment.task.id})

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {t('assignment:descriptions:accepted_host_unresponsive:title', {
                        date: formatLocaleDate(assignment.host_unresponsive_at, 'LL')
                    })}
                </AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:accepted_host_unresponsive:paragraph1:${serviceType}`)}
                </AssignmentDescriptionParagraph>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:accepted_host_unresponsive:paragraph2:${serviceType}`, {
                        date: formatLocaleDate(assignment.cancellation_date, 'LL')
                    })}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>
            <Flexbox gap={4}>
                <Button
                    variant="secondary"
                    onClick={() => contactHostMutation.mutate()}
                    disabled={contactHostMutation.isLoading}
                >
                    {t('assignment:descriptions:accepted_host_unresponsive:host_contacted_me')}
                    {contactHostMutation.isLoading && <Spinner size={20} />}
                </Button>
                <Button disabled={contactHostMutation.isLoading} onClick={() => setIsScheduleModalOpen(true)}>
                    {t('assignment:descriptions:schedule')}
                </Button>
            </Flexbox>
            {isScheduleModalOpen && (
                <ScheduleModal
                    assignment={assignment}
                    serviceType={serviceType}
                    onClose={() => setIsScheduleModalOpen(false)}
                />
            )}
        </AssignmentDescriptionWrapper>
    )
}
