import {useCallback, useEffect, useState} from 'react'
import {useTheme} from 'styled-components'
import CreatableSelect from 'react-select/creatable'
import ReactSelect, {components} from 'react-select'
import PropTypes from 'prop-types'

import {CloseIcon} from '../../theme/icons'

import {reactSelectsStyleGenerator, StyledSelect} from './style'

export const SelectUncontrolled = ({
    className,
    options = [],
    label,
    placeholder,
    helpText,
    defaultValue,
    isSearchable = true,
    isClearable = false,
    isCreatable = false,
    addOptionMessage,
    isMulti,
    onChangeCallback,
    closeMenuOnSelect = !isMulti,
    customComponents,
    disabled,
    menuPosition,
    ...rest
}) => {
    const [currentValue, setCurrentValue] = useState(defaultValue)

    useEffect(() => {
        setCurrentValue(defaultValue)
    }, [defaultValue])

    // This extends the default onChange
    const onChangeHandler = value => {
        setCurrentValue(value)
        if (typeof onChangeCallback === 'function') onChangeCallback(value)
    }

    // Label for new item creation
    const createLabel = useCallback(
        value => (
            <span style={{fontSize: 14}}>
                {addOptionMessage}
                <span>{value}</span>
            </span>
        ),
        [addOptionMessage]
    )

    const theme = useTheme()

    const reactSelectsStyle = reactSelectsStyleGenerator(
        theme,
        isMulti,
        isCreatable,
        null,
        null,
        null,
        disabled,
        rest.singleValueCustomStyle,
        rest.optionCustomStyle,
        rest.menuCustomStyle,
        rest.multiValueLabelCustomStyle,
        rest.multiValueCustomStyle,
        rest.controlCustomStyle,
        rest.menuContainerCustomStyle,
        rest.placeholderStyle,
        menuPosition
    )

    const selectProps = {
        options,
        closeMenuOnSelect,
        isSearchable,
        isClearable,
        isMulti,
        isDisabled: rest.readOnly || disabled,
        classNamePrefix: isCreatable ? 'creatable_select' : 'select',
        styles: reactSelectsStyle,
        placeholder,
        menuPosition: 'fixed',
        menuPlacement: 'auto',
        value: currentValue,
        ...rest
    }

    const components = {
        MultiValueRemove,
        ...customComponents
    }

    return (
        <StyledSelect className={className}>
            {!!label && <label>{label}</label>}
            {isCreatable ? (
                <CreatableSelect
                    formatCreateLabel={createLabel}
                    onChange={onChangeHandler}
                    components={components}
                    {...selectProps} // from Component
                />
            ) : (
                <ReactSelect onChange={onChangeHandler} components={components} {...selectProps} />
            )}
            {/* TODO: add error-message component */}
            {!!helpText && (
                <div>
                    <small>{helpText}</small>
                </div>
            )}
        </StyledSelect>
    )
}

const MultiValueRemove = props => {
    const theme = useTheme()

    return (
        <components.MultiValueRemove {...props}>
            <CloseIcon fill={theme.palette.neutral.black} width={20} height={20} />
        </components.MultiValueRemove>
    )
}

SelectUncontrolled.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    helpText: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
    isCreatable: PropTypes.bool,
    addOptionMessage: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChangeCallback: PropTypes.func,
    closeMenuOnSelect: PropTypes.bool,
    customComponents: PropTypes.object,
    disabled: PropTypes.bool,
    menuPosition: PropTypes.string
}
