import PropTypes, {shape} from 'prop-types'

import {MobileTable} from './mobile/TableMobile'
import {DesktopTable} from './desktop/TableDesktop'

import {TABLE_ALIGNMENTS} from '../../helpers/constants'

export const Table = ({footerComponent, headerComponent, isMobileVersion, ...rest}) => {
    return (
        <div>
            {headerComponent}
            {isMobileVersion ? <MobileTable {...rest} /> : <DesktopTable {...rest} />}
            {footerComponent}
        </div>
    )
}

Table.propTypes = {
    alternatingRowColors: PropTypes.bool,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({width}, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            }
        })
    ).isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    isError: PropTypes.bool,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    footerComponent: PropTypes.element,
    headerComponent: PropTypes.element,
    isLoading: PropTypes.bool,
    isRefetching: PropTypes.bool,
    isFetchingNextPage: PropTypes.bool,
    isMobileVersion: PropTypes.bool,
    isPaginated: PropTypes.bool,
    onChangePageCb: PropTypes.func,
    onClickRow: PropTypes.func,
    onRefresh: PropTypes.func,
    page: PropTypes.number,
    sortColumnCb: PropTypes.func,
    sorter: PropTypes.object,
    tbodyHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
