import {useQuery} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {httpGetFreelancerPayments} from 'src/features/freelancer-payments/services/freelancer-payments.http.ts'
import {captureException} from '@sentry/react'
import {FreelancerPayment} from 'src/features/freelancer-payments/types.ts'
import {z} from 'zod'

export const useFreelancerPayments = (paymentDate: string) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.FREELANCER_PAYMENTS_DATES, paymentDate],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetFreelancerPayments({
                    params: {
                        response_type: 'regular',
                        date: paymentDate
                    },
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                onZodError: captureException,
                responseShape: z.array(FreelancerPayment)
            })
    })
}
