import {Button} from 'src/components/button/Button.tsx'
import {
    StyledAdminHostApproval,
    StyledAdminHostApprovalHero,
    StyledAdminHostApprovalTitles,
    StyledTabs
} from 'src/pages/admin-host-approval/style.ts'
import {PlusIcon} from 'src/components/icon'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {useState} from 'react'
import {CreateTaskModal} from 'src/features/admin-host-approval/components/create-task-modal/CreateTaskModal.tsx'
import {Divider} from 'src/components/divider/Divider.tsx'
import {useAdminHostApprovalStatusesCounter} from 'src/features/admin-host-approval/services/useAdminHostApprovalStatusesCounter.ts'
import {Outlet} from 'react-router-dom'
import {ROUTES} from 'src/helpers/constants.ts'
import {StatusTab} from 'src/features/admin-host-approval/components/status-tab/StatusTab.tsx'
import {Container} from 'src/components/container/Container.tsx'
import {useUserStore} from 'src/features/user/store'

export const AdminHostApproval = () => {
    const {t} = useTranslation()
    const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false)
    const adminHostApprovalStatusesCounterQuery = useAdminHostApprovalStatusesCounter()
    const statusesCounter = adminHostApprovalStatusesCounterQuery.data
    const currentAdmin = useUserStore(state => state.currentAdmin)

    return (
        <StyledAdminHostApproval>
            <StyledAdminHostApprovalHero>
                <Helmet title={t('admin_host_approval:meta_title')} />
                <StyledAdminHostApprovalTitles>
                    <h1>{t('commons:host_approval')}</h1>
                    <h2>{t('admin_host_approval:subtitle')}</h2>
                </StyledAdminHostApprovalTitles>
                <Button variant="primaryGrayModern" onClick={() => setIsCreateTaskModalOpen(true)}>
                    <PlusIcon />
                    {t('admin_host_approval:task')}
                </Button>
                {isCreateTaskModalOpen && (
                    <CreateTaskModal
                        defaultValues={{assigned_to: currentAdmin?.id}}
                        onClose={() => setIsCreateTaskModalOpen(false)}
                    />
                )}
            </StyledAdminHostApprovalHero>
            <div>
                <StyledTabs>
                    <StatusTab
                        counter={statusesCounter?.new}
                        to={ROUTES.ADMIN_HOST_APPROVAL_NEW}
                        label={t('commons:new')}
                    />
                    <StatusTab
                        counter={statusesCounter?.pending_host_approval}
                        to={ROUTES.ADMIN_HOST_APPROVAL_PENDING_HOST_APPROVAL}
                        label={t('commons:pending_host_approval')}
                    />
                    <StatusTab
                        counter={statusesCounter?.ready_for_review}
                        to={ROUTES.ADMIN_HOST_APPROVAL_READY_FOR_REVIEW}
                        label={t('commons:ready_for_review')}
                    />
                    <StatusTab
                        counter={statusesCounter?.completed}
                        to={ROUTES.ADMIN_HOST_APPROVAL_COMPLETED}
                        label={t('commons:completed')}
                    />
                </StyledTabs>
                <Divider direction="horizontal" />
            </div>
            <Container>
                <Outlet />
            </Container>
        </StyledAdminHostApproval>
    )
}
