import styled, {css} from 'styled-components'

export const StyledMobileCalendar = styled.div`
    position: relative;
    height: 600px;
    overflow: auto;
`

export const StyledMobileContainer = styled.div`
    ${({theme: {spacing}}) => css`
        overflow-y: scroll;
        margin-top: ${spacing * 6}px;
    `}
`

export const StyledDateElement = styled.div`
    ${({theme: {colors, spacing, typography, palette}}) => css`
        display: flex;
        gap: ${spacing}px;
        background: ${palette.neutral.white};
        border: 1px solid ${colors.borderGrey};
        padding: ${spacing * 1.5}px ${spacing * 2.5}px;
        border-radius: 25px;
        width: fit-content;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
        ${typography.textSm};
        font-weight: 500;
        color: ${colors.darkGrey};
    `}
`

export const StyledDayText = styled.p`
    ${({theme: {colors}}) => css`
        color: ${colors.lightBlack};
    `}
`

export const StyledTodayText = styled.p`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral.white};
    `}
`

export const StyledTodayElement = styled.div`
    ${({theme: {colors, spacing, typography, palette}}) => css`
        display: flex;
        gap: ${spacing}px;
        background: ${palette.neutral.black};
        border: 1px solid ${colors.borderGrey};
        padding: ${spacing * 1.5}px ${spacing * 2.5}px;
        border-radius: 25px;
        width: fit-content;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
        ${typography.textSm};
        font-weight: 500;
        color: ${colors.calendarGrey};
    `}
`

export const StyledEventList = styled.div`
    ${({theme: {spacing, colors}}) => css`
        margin-left: ${spacing * 4}px;
        border-left: 1px solid ${colors.gainsboroGrey};
        padding: ${spacing * 4}px;
    `}
`

export const StyledNoResultsSpan = styled.span`
    ${({theme: {colors, spacing, typography}}) => css`
        display: block;
        padding-top: ${spacing * 6}px;
        ${typography.displayXs};
        font-weight: 500;
        color: ${colors.darkGrey};
        text-align: center;
    `}
`
