import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {BADGE_SIZES} from '../../../../components/badge-legacy/Badge'
import {RequireMyAttention} from './require-my-attention/RequireMyAttention'
import {MySchedule} from './my-schedule/MySchedule'
import {useTheme} from 'styled-components'
import {StyledTab, StyledCalendar, TabsWrapper, StyledIcon, StyledBadge} from './style'
import {useAssignmentsCount} from '../../../../features/assignments/services/useAssignmentsCount'

const MY_SCHEDULE = 'my-schedule'
const REQUIRE_ATTENTION = 'require-attention'

export const Calendar = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [selectedTab, setSelectedTab] = useState(REQUIRE_ATTENTION)
    const {data: shootsCount} = useAssignmentsCount()

    const onChangeTab = id => {
        setSelectedTab(id)
    }

    return (
        <StyledCalendar>
            <TabsWrapper>
                <StyledIcon color="red" size={26} />
                <StyledTab
                    isSelected={selectedTab === REQUIRE_ATTENTION}
                    id={REQUIRE_ATTENTION}
                    onClick={() => onChangeTab(REQUIRE_ATTENTION)}
                >
                    {t('dashboard:calendar:tabs:require_my_attention')}
                    <StyledBadge
                        size={BADGE_SIZES.MEDIUM}
                        background={
                            selectedTab === REQUIRE_ATTENTION
                                ? theme.colors.airbnbDarkPink
                                : theme.colors.lightAirbnbDarkPink
                        }
                        color={
                            selectedTab === REQUIRE_ATTENTION
                                ? theme.palette.neutral.white
                                : theme.colors.airbnbDarkPink
                        }
                        text={shootsCount ? shootsCount.assignments_current ?? 0 : '...'}
                    />
                </StyledTab>
                <StyledTab
                    isSelected={selectedTab === MY_SCHEDULE}
                    id={MY_SCHEDULE}
                    onClick={() => onChangeTab(MY_SCHEDULE)}
                >
                    {t('dashboard:calendar:tabs:my_schedule')}
                </StyledTab>
            </TabsWrapper>
            {selectedTab === MY_SCHEDULE ? <MySchedule /> : <RequireMyAttention />}
        </StyledCalendar>
    )
}
