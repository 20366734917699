import {ArrowUpIcon} from '../../../../../../../assets/icons/ArrowUpIcon'
import {ArrowDownIcon} from '../../../../../../../assets/icons/ArrowDownIcon'
import {StyledAccordionButton, StyledTransactionCell} from './style'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {TransactionsList} from '../transactions-list/TransactionsList'
import {Button} from '../../../../../../../components/button/Button'

export const TransactionColumn = ({
    payments,
    index,
    isMobileVersion,
    expandedRowsIndexes,
    onToggleTransactionsAccordion
}) => {
    const {t} = useTranslation()

    return isMobileVersion ? (
        <>
            {expandedRowsIndexes.includes(index) && <TransactionsList payments={payments} />}
            <Button onClick={() => onToggleTransactionsAccordion(index)} fullWidth variant="secondary">
                {t('transactions:transactions', {count: payments.length})}
                {expandedRowsIndexes.includes(index) ? <ArrowUpIcon size={20} /> : <ArrowDownIcon size={20} />}
            </Button>
        </>
    ) : (
        <StyledTransactionCell>
            {t('transactions:transactions', {count: payments.length})}
            <StyledAccordionButton onClick={() => onToggleTransactionsAccordion(index)}>
                {expandedRowsIndexes.includes(index) ? <ArrowUpIcon size={20} /> : <ArrowDownIcon size={20} />}
            </StyledAccordionButton>
        </StyledTransactionCell>
    )
}

TransactionColumn.propTypes = {
    payments: PropTypes.array,
    index: PropTypes.number,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    onToggleTransactionsAccordion: PropTypes.func,
    isMobileVersion: PropTypes.bool
}
