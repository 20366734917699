import {Container} from 'src/components/container/Container.tsx'
import styled, {css} from 'styled-components'

export const StyledFreelancerPayments = styled(Container)(
    ({theme: {spacing, typography, mediaQueries}}) => css`
        display: grid;
        grid-template-rows: auto auto minmax(0, 1fr);
        gap: ${spacing * 8}px;
        padding-top: ${spacing * 15}px;
        padding-bottom: ${spacing * 6}px;

        & h1 {
            ${typography.displayXs};
            font-weight: 500;
        }

        ${mediaQueries.m} {
            padding-top: ${spacing * 4}px;
            padding-bottom: ${spacing * 4}px;
        }
    `
)
