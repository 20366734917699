import PropTypes from 'prop-types'
import {debounce} from 'lodash-es'
import {yupResolver} from '@hookform/resolvers/yup'
import {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'

import {Modal} from '../../../../../../components/modal-legacy/Modal'
import {Select} from '../../../../../../components/select/Select'
import {Button} from '../../../../../../components/button/Button'
import {Checkbox} from '../../../../../../components/checkbox-legacy/Checkbox'
import {Textarea} from '../../../../../../components/textarea-legacy/Textarea'
import {InputText} from '../../../../../../components/input-text/InputText'

import {showAlert} from '../../../../../../store/appGenerics'
import {ReassignmentModalSchema} from './ReassignmentModal.schema'
import {ALERT_LEVELS, PHOTOGRAPHERS_FILTER_OPTIONS, SERVICE_MEDIAS} from '../../../../../../helpers/constants'
import {httpGetAllPhotographers} from '../../../../../../http-requests/admin/partners.http'
import {clearNullishKeys, errorHandler} from '../../../../../../helpers/utils'
import {useReassignTask} from '../../../../../../hooks/mutations/useReassignTask'
import {usePhotographers} from '../../../../../../hooks/usePhotographers'

import {
    FooterWrapper,
    InputSectionWrapper,
    StyledSubTitle,
    StyledTitle,
    StyledTitleSection,
    StyledLabel,
    TextAreaWrapper,
    StyledOptionContainer,
    StyledName,
    StyledRegion,
    SelectWrapper,
    StyledSelectLabel,
    StyledCheckbox,
    CheckboxSectionWrapper,
    StyledEmail,
    StyledCurrency
} from './style'
import {useUserStore} from '../../../../../../features/user/store'
import {useForceMatch} from '../../../../../../features/admin/services/useForceMatch'

export const ReassignmentModal = ({onClose, quoteData}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const filter =
        quoteData.task_media_id === SERVICE_MEDIAS.INSPECTION
            ? PHOTOGRAPHERS_FILTER_OPTIONS.INSPECTORS
            : PHOTOGRAPHERS_FILTER_OPTIONS.HOMES_PRODUCTION
    const photographersQuery = usePhotographers({filter})

    const reassignMutation = useReassignTask({
        selectedQuoteId: quoteData?.id,
        onSuccess: () => {
            dispatch(showAlert({message: t('admin:assignments:reassignment_confirmed'), level: ALERT_LEVELS.SUCCESS}))
            onClose()
        }
    })
    const forceMatchMutation = useForceMatch({
        quoteId: quoteData?.id,
        onSuccess: () => {
            dispatch(showAlert({message: t('admin:assignments:reassignment_confirmed'), level: ALERT_LEVELS.SUCCESS}))
            onClose()
        }
    })

    const {
        handleSubmit,
        register,
        watch,
        control,
        resetField,
        formState: {errors, isValid}
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(ReassignmentModalSchema),
        defaultValues: {notes: undefined}
    })

    const isSpecificFreelancer = watch('assign_to_specific_freelancer')
    const selectedFreelancer = watch('freelancer')
    const isManualRate = watch('set_a_manual_rate')

    useEffect(() => {
        resetField('freelancer')
    }, [isSpecificFreelancer])

    useEffect(() => {
        if (!isManualRate) {
            resetField('rate')
        }
    }, [isManualRate])

    const onSubmit = formValues => {
        const reformattedValues = clearNullishKeys({...formValues, freelancer: formValues?.freelancer?.value})
        formValues.assign_to_specific_freelancer
            ? forceMatchMutation.mutate({
                  photographer_id: reformattedValues.freelancer,
                  ignore_photographer_market: reformattedValues.ignore_freelancer_market,
                  ignore_photographer_previous_rejection: reformattedValues.ignore_freelancer_previous_rejection,
                  set_a_manual_rate: reformattedValues.set_a_manual_rate,
                  rate: reformattedValues.rate
              })
            : reassignMutation.mutate({taskId: quoteData?.task?.id, payload: reformattedValues})
    }

    const formatFreelancerOptions = freelancers =>
        freelancers?.map(freelancer => ({
            value: freelancer.id,
            label: (
                <StyledOptionContainer key={freelancer.id}>
                    <StyledName>{freelancer.name}</StyledName>
                    <StyledRegion>
                        {freelancer.geos
                            .slice(0, 3)
                            .map(geo => geo.geo)
                            .join(', ')}
                        {freelancer.geos.length > 3 ? (
                            <span>
                                {', +'}
                                {freelancer.geos.length - 3}
                            </span>
                        ) : null}
                    </StyledRegion>
                    <StyledEmail>{freelancer.email}</StyledEmail>
                </StyledOptionContainer>
            )
        })) ?? []

    const loadOptions = async (inputValue, callback) => {
        try {
            const response = await httpGetAllPhotographers(adminAccessToken, {
                name: inputValue,
                limit: 30,
                filter_by: filter
            })
            callback(formatFreelancerOptions(response.data))
        } catch (error) {
            errorHandler(error)
            callback([])
        }
    }

    const loadFreelancerOptions = useCallback(
        debounce((inputValue, callback) => {
            loadOptions(inputValue, callback)
        }, 700),
        []
    )

    return (
        <Modal onClose={onClose} width="432px" isLoading={reassignMutation.isLoading || forceMatchMutation.isLoading}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledTitleSection>
                    <StyledTitle>{t('admin:assignments:reassign')}</StyledTitle>
                    <StyledSubTitle>{t('admin:assignments:reassign_helper')}</StyledSubTitle>
                </StyledTitleSection>
                <InputSectionWrapper>
                    <Checkbox
                        {...register('assign_to_specific_freelancer')}
                        label={t('admin:assignments:assign_specific_freelancer')}
                    />
                    {isSpecificFreelancer && (
                        <SelectWrapper>
                            <StyledSelectLabel>{t('admin:freelancer')}</StyledSelectLabel>
                            <Select
                                singleValueCustomStyle={{
                                    p: {
                                        display: 'none'
                                    }
                                }}
                                menuCustomStyle={{
                                    maxHeight: 250
                                }}
                                optionCustomStyle={{
                                    padding: '6px 0'
                                }}
                                menuContainerCustomStyle={{
                                    maxWidth: 336
                                }}
                                placeholderStyle={{
                                    paddingLeft: 14
                                }}
                                isAsync
                                name="freelancer"
                                isSearchable
                                menuPlacement="bottom"
                                placeholder={t('admin:assignments:select_freelancer')}
                                control={control}
                                loadOptions={loadFreelancerOptions}
                                defaultOptions={formatFreelancerOptions(photographersQuery.remappedData)}
                            />
                        </SelectWrapper>
                    )}
                </InputSectionWrapper>
                {selectedFreelancer && (
                    <CheckboxSectionWrapper>
                        <div>
                            <StyledCheckbox
                                {...register('ignore_freelancer_market')}
                                label={t('admin:assignments:ignore_freelancers_markets')}
                            />
                            <StyledCheckbox
                                {...register('ignore_freelancer_previous_rejection')}
                                label={t('admin:assignments:ignore_rejections')}
                            />
                            <StyledCheckbox
                                {...register('set_a_manual_rate')}
                                label={t('admin:assignments:set_rate')}
                            />
                        </div>
                        {isManualRate && (
                            <InputText
                                type="text"
                                width="273px"
                                placeholder={t('admin:assignments:write_rate')}
                                {...register('rate')}
                                errorMessage={errors.rate?.message}
                                typeIcon={<StyledCurrency>{'USD'}</StyledCurrency>}
                            />
                        )}
                    </CheckboxSectionWrapper>
                )}

                <InputSectionWrapper>
                    <TextAreaWrapper>
                        <StyledLabel>{'Notes'}</StyledLabel>
                        <Textarea placeholder={t('admin:assignments:notes_placeholder')} {...register('notes')} />
                    </TextAreaWrapper>
                </InputSectionWrapper>

                <FooterWrapper>
                    <Button
                        onClick={onClose}
                        variant="tertiary"
                        disabled={reassignMutation.isLoading || forceMatchMutation.isLoading}
                    >
                        {t('commons:dismiss')}
                    </Button>
                    <Button
                        type="submit"
                        disabled={!isValid}
                        isLoading={reassignMutation.isLoading || forceMatchMutation.isLoading}
                    >
                        {t('admin:assignments:reassign')}
                    </Button>
                </FooterWrapper>
            </form>
        </Modal>
    )
}

ReassignmentModal.propTypes = {
    onClose: PropTypes.func,
    quoteData: PropTypes.object
}
