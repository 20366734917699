import {z} from 'zod'

export const CoordinationStatus = z.enum([
    'dates_dont_match',
    'host_contacted',
    'host_dates_shared',
    'host_not_ready',
    'host_unresponsive',
    'manual_coordination',
    'overdue_host_contacted',
    'photographer_not_available',
    'cancellation_requested'
])
export type CoordinationStatus = z.infer<typeof CoordinationStatus>

export const Assignment = z.object({
    airbnb_link: z.string().url(),
    amount: z.number(),
    assets_rejected_reason: z
        .enum([
            'empty_folder',
            'empty_form',
            'exp_empty_folder',
            'exp_individual_assets',
            'exp_insufficient_assets',
            'exp_low_quality',
            'exp_too_much_assets',
            'exp_too_shaky',
            'exp_wrong_folder',
            'exp_wrong_ratio',
            'manual_explanation',
            'missing_assets',
            'not_enough_photos_form',
            'unmerged_brackets',
            'wrong_assets',
            'wrong_file_format',
            'wrong_listing_form'
        ])
        .nullable(),
    bonus_amount: z.coerce.number().nullable(),
    can_send_to_airbnb: z.boolean(),
    cancellation_date: z.string().min(1).nullable(),
    code: z.number().int().min(1),
    dates_dont_match_at: z.string().min(1).nullable(),
    entity: z.object({
        address: z.string().nullable(),
        bedrooms: z.number().nullable(),
        city: z.string().nullable(),
        experience_duration: z.number().nullable(),
        external_id: z.string().min(1),
        title: z.string().nullable()
    }),
    expiration_date: z.string().min(1),
    frame_final_folder_review_link: z.string().url().nullable(),
    host: z.object({
        id: z.number().int().min(1),
        first_name: z.string(),
        email: z.string().email(),
        phone: z.string().nullable(),
        preferred_languages: z.string().nullable()
    }),
    host_contacted_at: z.string().min(1).nullable(),
    host_not_ready_at: z.string().min(1).nullable(),
    host_ready_at: z.string().min(1).nullable(),
    host_responsive_at: z.string().min(1).nullable(),
    host_unresponsive_at: z.string().min(1).nullable(),
    id: z.number().int().min(1),
    is_bulk_assignment: z.boolean(),
    medias_to_upload: z.number().nullable(),
    notes: z.string().min(1).nullable(),
    old_date_shoot: z.string().min(1).nullable(),
    pdf_link: z.string().url().nullable(),
    photographer_not_available_at: z.string().min(1).nullable(),
    photographer_available_at: z.string().min(1).nullable(),
    portrait_bonus_amount: z.coerce.number().nullable(),
    quote: z.object({
        amount: z.number().nullable(),
        host_availability_dates: z
            .array(z.object({starting_time: z.string().min(1)}))
            .length(2)
            .or(z.tuple([]))
    }),
    reminder_at: z.string().min(1).nullable(),
    status: z.enum(['accepted', 'auto_cancelled', 'declined', 'hard_cancelled', 'pending', 'soft_cancelled']),
    task: z.object({
        id: z.number().int().positive(),
        inspection_id: z.string().nullable(),
        daryl_directory_id: z.string().nullable(),
        iconik_url: z.string().nullable()
    }),
    task_category: z.object({
        id: z.number().int().positive(),
        name: z.string().min(1)
    }),
    task_media: z.object({
        id: z.number().int().positive(),
        name: z.enum(['photo', 'video', 'matterport', 'walkthrough'])
    }),
    task_service: z.object({
        id: z.number().int().positive(),
        name: z.string().min(1)
    }),
    task_status: z.object({
        coordination_status: CoordinationStatus.nullable(),
        status: z.enum([
            'accepted',
            'assets_in_review',
            'auto_cancelled',
            'completed',
            'hard_cancelled',
            'needs_more_assets',
            'overdue',
            'pending',
            'pending_assets',
            'pending_payment',
            'scheduled',
            'soft_cancelled',
            'waitlisted'
        ])
    }),
    to_shoot_at: z.string().nullable(),
    total_amount: z.number().nullable(),
    travel_compensation: z.number().nullable()
})
export type Assignment = z.infer<typeof Assignment>

export type ServiceType = 'experience_video' | 'home' | 'matterport' | 'walkthrough'

export const DeclineReason = z.object({
    id: z.number().int().min(1),
    key_name: z.string().min(1)
})

export const Space = z.object({
    id: z.number().int().min(1),
    configuration_space: z.object({
        raws_minimum_images: z.number()
    }),
    space: z.object({
        key_name: z.enum([
            'bathroom',
            'bedroom_1',
            'bedroom_2',
            'bedroom_3',
            'bedroom_4',
            'bedroom_5',
            'bedroom_6',
            'bedroom_7',
            'bedroom_8',
            'bedroom_9',
            'bedroom_10',
            'common_spaces',
            'dining_room',
            'exterior',
            'kitchen',
            'living_room',
            'office_study',
            'other',
            'outdoor_space',
            'pool_host_tub'
        ])
    })
})
export type Space = z.infer<typeof Space>

export const Compensation = z.object({
    compensationKey: z.enum(['photo_session', 'travel', 'profile_photo', 'bonus', 'walkthrough', 'total']),
    currency: z.enum(['EUR', 'USD', 'GBP'])
})
export type Compensation = z.infer<typeof Compensation>

export const AssignmentUrlParams = z.object({
    code: z.coerce.number()
})
