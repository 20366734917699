import styled, {css, DefaultTheme} from 'styled-components'
import {RadioProps} from 'src/components/radio/Radio.tsx'

export const StyledRadioInputWrapper = styled.label(
    ({theme: {spacing, typography}}) => css`
        display: flex;
        gap: ${spacing * 3}px;
        cursor: pointer;

        & > p {
            ${typography.textMd};
            word-break: break-word;
        }
    `
)

export const makeRadioVariantStyle = (theme: DefaultTheme) =>
    ({
        primary: css`
            &:checked {
                background: ${theme.palette.primary['50']};
                border: 1px solid ${theme.palette.primary['600']};
                content: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3' viewBox='0 0 6 6' fill='none'%3E%3Ccircle cx='3' cy='3' r='3' fill='${theme.palette.primary[
                    '600'
                ].replace('#', '%23')}'/%3E%3C/svg%3E");`};
            }
            &:not(:disabled):not(:checked):hover {
                border: 1px solid ${theme.palette.primary['300']};
                box-shadow: ${`${theme.shadows.xs}, 0px 0px 0px 2px ${theme.palette.primary['100']}`};
            }
        `,
        grayModern: css`
            &:checked {
                background: ${theme.palette.grayModern['50']};
                border: 1px solid ${theme.palette.grayModern['600']};
                content: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3' viewBox='0 0 6 6' fill='none'%3E%3Ccircle cx='3' cy='3' r='3' fill='${theme.palette.grayModern[
                    '600'
                ].replace('#', '%23')}'/%3E%3C/svg%3E");`};
            }
            &:not(:disabled):not(:checked):hover {
                border: 1px solid ${theme.palette.grayModern['300']};
                box-shadow: ${`${theme.shadows.xs}, 0px 0px 0px 2px ${theme.palette.grayModern['100']}`};
            }
        `
    } as const satisfies Record<NonNullable<RadioProps['variant']>, ReturnType<typeof css>>)

export const StyledRadio = styled.input<{
    $labelPosition: RadioProps['labelPosition']
    $variant: NonNullable<RadioProps['variant']>
}>(
    ({$labelPosition, $variant, theme}) => css`
        flex: none;
        order: ${$labelPosition == 'left' ? '2' : '0'};
        background-color: ${theme.palette.neutral.white};
        width: 20px;
        height: 20px;
        padding: ${theme.spacing}px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${theme.palette.neutral['300']};
        transition: ease-in-out 300ms;

        &:disabled:checked {
            background: ${theme.palette.neutral['100']};
            border: 1px solid ${theme.palette.neutral['300']};
            cursor: not-allowed;
            content: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3' viewBox='0 0 6 6' fill='none'%3E%3Ccircle cx='3' cy='3' r='3' fill='${theme.palette.neutral[
                '300'
            ].replace('#', '%23')}'/%3E%3C/svg%3E");`};
        }

        &:disabled {
            background: ${theme.palette.neutral['100']};
            border: 1px solid ${theme.palette.neutral['300']};
            cursor: not-allowed;
        }

        ${makeRadioVariantStyle(theme)[$variant]}
    `
)
