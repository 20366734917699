import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {captureException} from '@sentry/react'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types.ts'
import {httpGetAdminHostApprovalTaskImages} from 'src/features/admin-host-approval/services/adminHostApproval.http'
import {useUserStore} from 'src/features/user/store'
import {z} from 'zod'

export const useAdminHostApprovalTaskImages = (
    taskId?: number,
    options?: UseQueryOptions<AdminHostApprovalTaskImage[]>
) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK_IMAGES, taskId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAdminHostApprovalTaskImages({
                    urlParams: {taskId: taskId ?? raise('The task id is not provided')},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: z.array(AdminHostApprovalTaskImage),
                onZodError: captureException
            }),
        ...options
    })
}
