import {
    SelectItemProps,
    SelectPopoverProps,
    Role,
    Select,
    useSelectContext,
    Combobox,
    ComboboxProps,
    ComboboxListProps,
    SelectListProps
} from '@ariakit/react'
import {
    StyledSelectArrow,
    StyledSelectClearIcon,
    StyledSelectComboboxList,
    StyledSelectComboboxPopover,
    StyledSelectItem,
    StyledSelectList,
    StyledSelectPopover,
    StyledSelectTrigger
} from 'src/components/select-atoms/style'
import {ForwardedRef, ReactNode} from 'react'
import {ButtonProps} from 'src/components/button/Button.tsx'
import {ChevronDownIcon} from 'src/components/icon/index.tsx'
import {genericForwardRef, raise} from 'src/helpers/helpers.ts'
import {Scrollbar} from 'src/components/scrollbar/Scrollbar.tsx'
import {ScrollArea} from 'src/components/scroll-area/ScrollArea.tsx'
import {InputText} from 'src/components/input-text/InputText.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'

export const SelectPopover = (props: SelectPopoverProps) => <StyledSelectPopover gutter={4} sameWidth {...props} />
SelectPopover.displayName = 'SelectPopover'

export const SelectComboboxPopover = (props: SelectPopoverProps) => (
    <StyledSelectComboboxPopover gutter={4} sameWidth {...props} />
)
SelectComboboxPopover.displayName = 'SelectComboboxPopover'

export const SelectList = (props: SelectListProps) => (
    <ScrollArea scrollbar={<Scrollbar />}>
        <StyledSelectList {...props} />
    </ScrollArea>
)
SelectList.displayName = 'SelectList'

export const SelectComboboxList = (props: ComboboxListProps) => (
    <ScrollArea scrollbar={<Scrollbar />}>
        <StyledSelectComboboxList {...props} />
    </ScrollArea>
)
SelectComboboxList.displayName = 'SelectComboboxList'

export const SelectItem = (props: SelectItemProps) => <StyledSelectItem {...props} />
SelectItem.displayName = 'SelectItem'

export const SelectComboboxInput = (props: ComboboxProps) => (
    <Combobox autoSelect render={<InputText type="text" inputSize="sm" />} {...props} />
)

interface SelectTriggerProps extends Omit<ButtonProps, 'children'> {
    placeholder?: ReactNode
    displayValue: (value: string | string[]) => ReactNode
    isClearable?: boolean
}

const _SelectTrigger = (
    {placeholder, displayValue, isClearable, ...rest}: SelectTriggerProps,
    ref: ForwardedRef<HTMLSelectElement>
) => {
    const selectContext = useSelectContext() ?? raise('SelectTrigger must be in a SelectProvider')
    const isOpen = selectContext.useState('open')
    const selectValue = selectContext.useState('value')
    const children = selectValue.length >= 1 ? displayValue(selectValue) : undefined

    return (
        <Select
            render={
                <Role.select
                    ref={ref}
                    render={
                        <StyledSelectTrigger $hasChildren={!!children} type="button" variant="secondary" {...rest}>
                            {children || placeholder}
                            <Flexbox align="center" gap={2}>
                                {isClearable && !!selectValue && (
                                    <StyledSelectClearIcon
                                        onClick={event => {
                                            event.stopPropagation()
                                            selectContext.setValue('')
                                        }}
                                    />
                                )}
                                <StyledSelectArrow $isOpen={isOpen} render={<ChevronDownIcon />} />
                            </Flexbox>
                        </StyledSelectTrigger>
                    }
                />
            }
        />
    )
}

//This wrapper is needed to use the generic types in the forwardRef component
export const SelectTrigger = genericForwardRef(_SelectTrigger)

_SelectTrigger.displayName = 'SelectTrigger'
