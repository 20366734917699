import styled, {css} from 'styled-components'

export const StyledList = styled.div`
    ${({theme: {spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 12}px;
        & h4 {
            ${typography.displayXs};
            font-weight: 500;
            margin-bottom: ${spacing * 6}px;
        }

        & h5 {
            ${typography.textMd};
            font-weight: 500;
            margin-bottom: ${spacing * 4}px;
        }
    `}
`

export const StyledAmenitiesList = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `}
`

export const StyledCardsList = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: ${spacing * 8}px;
        margin-bottom: ${spacing * 4}px;
    `}
`
