import {StyledDropzone, StyledError} from './style'
import {useTranslation} from 'react-i18next'
import {Button} from '../../../../../components/button/Button'
import {useDropzone} from 'react-dropzone'
import {useEffect, useState} from 'react'
import {Spinner} from '../../../../../components/spinner-legacy/Spinner'

export const DropzoneView = ({setImagesFromDropzoneCb, uploadSizeLimit, uploadFileLimit, acceptedFiles}) => {
    const {t} = useTranslation()
    const maxUploadSize = uploadSizeLimit || 300 //in MB
    const maxUploadFiles = uploadFileLimit || 100
    const [dropzoneStatus, setDropzoneStatus] = useState({
        isActive: false,
        isUploading: false,
        acceptedFiles: [],
        rejectedFiles: [],
        dropAcceptingChecked: false,
        dropRejectingChecked: false,
        isUploadingFinished: false,
        isAllRejected: false
    })
    const isAllRejectedDueToFormat = dropzoneStatus.rejectedFiles.some(image =>
        image.errors?.some(error => error.code === 'file-invalid-type')
    )
    const totalUploadedSize = Math.floor(
        dropzoneStatus.rejectedFiles
            .map(image => image.file.size)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0) / 1000000
    ) //in MB

    const {getRootProps, getInputProps} = useDropzone({
        accept: {[acceptedFiles]: []},
        maxSize: maxUploadSize * 1000000, //MB to bytes
        maxFiles: maxUploadFiles,
        disabled: dropzoneStatus.isUploading,
        onDragEnter: () => {
            setDropzoneStatus({
                ...dropzoneStatus,
                isActive: true,
                isAllRejected: false
            })
        },
        onDragLeave: () => {
            setDropzoneStatus(prevStatus => ({
                ...prevStatus,
                isActive: false
            }))
        },
        onDrop: acceptedFiles => {
            setDropzoneStatus(prevStatus => ({
                ...prevStatus,
                isActive: false,
                isUploading: !!acceptedFiles.length,
                isAllRejected: !acceptedFiles.length
            }))
        },
        onDropAccepted: files => {
            setDropzoneStatus(prevStatus => ({
                ...prevStatus,
                acceptedFiles: files,
                dropAcceptingChecked: true,
                isUploading: false,
                isUploadingFinished: true
            }))
        },
        onDropRejected: files => {
            setDropzoneStatus(prevStatus => ({
                ...prevStatus,
                rejectedFiles: files,
                dropRejectingChecked: true
            }))
        }
    })

    useEffect(() => {
        if (dropzoneStatus.isUploadingFinished) {
            setImagesFromDropzoneCb({
                accepted:
                    dropzoneStatus.acceptedFiles?.map(file =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })
                    ) || [],
                rejected: dropzoneStatus.rejectedFiles || []
            })
        }
    }, [dropzoneStatus.isUploadingFinished])

    return (
        <StyledDropzone
            $pageHeight={window.innerHeight}
            $isDropzoneActive={dropzoneStatus.isActive}
            $isDropzoneError={dropzoneStatus.isAllRejected}
            {...getRootProps()}
        >
            {dropzoneStatus.isActive ? (
                <h3>{t('admin:release_to_upload')}</h3>
            ) : (
                <>
                    <input {...getInputProps()} />
                    <h3>{t('admin:drop_images_here')}</h3>
                    <p>{t('commons:or')}</p>
                    <Button variant="primaryBlack" onClick={() => getInputProps().ref.current?.click()}>
                        {t('commons:upload_images')}
                    </Button>
                    <small>
                        {t('commons:max_mb_files', {
                            mb: maxUploadSize,
                            files: maxUploadFiles
                        })}
                    </small>
                    {dropzoneStatus.isAllRejected &&
                        (isAllRejectedDueToFormat ? (
                            <StyledError>{t('admin:upload_rejected_format')}</StyledError>
                        ) : dropzoneStatus.rejectedFiles.length > maxUploadFiles ? (
                            <StyledError>
                                {t('admin:upload_rejected_length', {
                                    maxUploadFiles,
                                    uploadedFiles: dropzoneStatus.rejectedFiles.length
                                })}
                            </StyledError>
                        ) : (
                            <StyledError>
                                {t('admin:upload_rejected_size', {
                                    maxUploadSize,
                                    totalUploadedSize
                                })}
                            </StyledError>
                        ))}
                </>
            )}
            {dropzoneStatus.isUploading && <Spinner />}
        </StyledDropzone>
    )
}
