import styled, {css, keyframes} from 'styled-components'
import {ShowMoreIcon} from '../../theme/icons'

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const StyledWrapper = styled.div(
    ({theme: {spacing, mediaQuery, zIndex, colors, transition, palette}, opened}) => css`
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 15%) 0 12px 24px 0;
        overflow: hidden;
        position: fixed;
        bottom: 80px;
        top: initial;
        right: 24px;
        left: initial;
        z-index: ${zIndex.dropdownOverlay};
        transform: scale(${opened ? 1 : 0});
        transform-origin: right bottom;
        ${transition};
        ${mediaQuery.SM} {
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0;
        }
        & .rsc-container {
            width: 376px;
            height: 680px;
            ${mediaQuery.SM} {
                width: ${window.innerWidth}px;
                height: ${window.innerHeight}px;
                border-radius: 0;
            }

            & .rsc-content {
                height: calc(680px - 112px);
                padding: ${spacing * 2}px 0;
                ${mediaQuery.SM} {
                    height: calc(${window.innerHeight}px - 112px);
                }
                & .rsc-ts-user {
                    & .rsc-ts-bubble {
                        margin: 0 0 ${spacing * 2}px;
                        border-radius: 8px;
                        max-width: 65%;
                    }
                }

                & .rsc-ts-bot {
                    & .rsc-ts-image-container {
                        padding: 0 0 ${spacing * 2}px ${spacing * 1.5}px;
                        & .rsc-ts-image {
                            padding: ${spacing * 0.25}px;
                            margin-right: ${spacing}px;
                            margin-bottom: ${spacing * 1.5}px;
                            height: 36px;
                            min-width: 36px;
                            margin-left: ${spacing}px;
                        }
                    }

                    & .rsc-ts-bubble {
                        margin-top: 0;
                        margin-bottom: ${spacing * 2}px;
                        border-radius: ${spacing * 2}px;
                        max-width: 65%;
                    }
                }

                & .rsc-cs {
                    background: transparent;
                    box-shadow: none;
                    display: block;
                    padding: ${spacing}px ${spacing * 2}px;
                    margin-bottom: ${spacing * 4}px;
                }
            }

            & .rsc-os-options {
                padding: 0 ${spacing * 3}px 0;

                & .rsc-os-option {
                    padding: ${spacing}px;
                    display: flex;
                    justify-content: end;
                }

                & .rsc-os-option-element {
                    padding: ${spacing * 2}px ${spacing * 2}px;
                    text-align: center;
                    background: ${palette.neutral.white};
                    border: 1px solid ${colors.grey};
                    font-weight: 500;
                    border-radius: 4px;
                    min-width: 42px;
                }
            }

            & .rsc-footer {
                & textarea {
                    height: 54px;
                    resize: none;
                }

                & input:disabled,
                textarea:disabled {
                    cursor: not-allowed;
                    background: ${colors.gainsboroGrey};
                }
            }
        }
    `
)

export const StyledMessage = styled.div(
    ({theme: {spacing, zIndex, mediaQuery, colors, typography}, showMatterportSection, fadeOut}) => css`
        animation: ${fadeIn} 200ms;
        bottom: 80px;
        right: 24px;
        height: 40px;
        position: fixed;
        z-index: ${zIndex.calendarOffRange};
        background: ${colors.bgWhite};
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        padding: ${spacing}px ${spacing * 4}px;
        border-radius: 40px;
        ${typography.textSm};
        box-shadow: rgb(143 143 143 / 45%) 0 0 32px 0;
        & svg {
            cursor: pointer;
            position: relative;
        }
        ${mediaQuery.SM} {
            display: none;
            ${showMatterportSection &&
            css`
                display: flex;
                bottom: 112px;
                right: 56px;
                height: 32px;
                padding: ${spacing}px ${spacing * 2}px;
                ${typography.textXs};
            `}
        }
        ${fadeOut && StyledFadeOut}
    `
)

const StyledFadeOut = css`
    animation: ${fadeOut} 200ms;
    opacity: 0;
    pointer-events: none;
`

export const StyledHeader = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        -webkit-box-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        fill: ${palette.neutral.white};
        background: ${palette.neutral.black};
        color: ${palette.neutral.white};
        ${typography.textMd};
        font-weight: 600;
        height: 56px;
        -webkit-box-pack: justify;
        padding: 0 ${spacing * 2.5}px;
        & svg {
            width: 24px;
            fill: ${palette.neutral.white}!important;
            cursor: pointer;
        }
    `
)

export const StyledFloating = styled.div(
    ({theme: {zIndex, mediaQuery, palette, transition}}) => css`
        -webkit-box-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: ${palette.neutral.black};
        bottom: 16px;
        border-radius: 100%;
        box-shadow: rgb(0 0 0 / 15%) 0 12px 24px 0;
        height: 56px;
        width: 56px;
        -webkit-box-pack: center;
        position: fixed;
        right: 24px;
        transform: scale(1);
        ${transition};
        z-index: ${zIndex.dropdownMenu};
        & svg {
            width: 30px;
            height: 30px;
            fill: ${palette.neutral.white};
        }
        ${mediaQuery.SM} {
            right: 16px;
            bottom: 24px;
        }
    `
)

export const StyledArrowDownIcon = styled(ShowMoreIcon)(
    ({theme: {palette}}) => css`
        width: 20px !important;
        fill: ${palette.neutral.white};
    `
)
