import {Button, ErrorPage, ErrorWrapper, StyledLogo} from './style'
import {ErrorIcon} from '../../assets/icons/ErrorIcon'

export const ErrorFallback = () => {
    return (
        <ErrorPage>
            <StyledLogo>{'Mediabox'}</StyledLogo>
            <ErrorWrapper>
                <ErrorIcon />
                <h2>{'Ops! Something went wrong'}</h2>
                <Button onClick={() => (window.location.href = '/')}>{'Go to dashboard'}</Button>
            </ErrorWrapper>
        </ErrorPage>
    )
}
