import {StepCard, StepCardProps} from 'src/features/assignment/components/step-card/StepCard.tsx'
import {StyledScrollbar, StyledSteps, StyledStepsWrapper} from 'src/features/assignment/components/steps/style.ts'
import {FC, useEffect, useRef} from 'react'
import {ScrollArea} from 'src/components/scroll-area/ScrollArea.tsx'

type Steps = Array<StepCardProps & {isCurrent: boolean}>
export const Steps: FC<{steps: Steps}> = ({steps}) => {
    const currentStepRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        currentStepRef.current?.scrollIntoView({block: 'nearest', inline: 'start'})
    }, [])

    return (
        <StyledStepsWrapper>
            <ScrollArea scrollbar={<StyledScrollbar orientation="horizontal" />}>
                <StyledSteps>
                    {steps.map(step => (
                        <StepCard {...step} ref={step.isCurrent ? currentStepRef : null} key={step.number} />
                    ))}
                </StyledSteps>
            </ScrollArea>
        </StyledStepsWrapper>
    )
}
