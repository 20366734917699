import styled, {css} from 'styled-components'

export const StyledTableControllerTop = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
        padding: 0 ${spacing * 6}px;
        margin-bottom: ${spacing * 4}px;
        height: 56px;
    `}
`

export const StyledFilters = styled.div`
    ${({hasTopCta, theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 8}px;
        ${!hasTopCta && 'width: 100%'};
    `}
`
