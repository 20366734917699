import axios from 'axios'

export const httpGetMatterportTable = (adminAccessToken, params) => {
    return axios.get('/admin/tasks-matterport', {
        params,
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpChangeTaskAssignment = (taskId, userId, adminAccessToken) => {
    return axios.post(
        `/admin/tasks-matterport/${taskId}/assign`,
        {user: userId},
        {headers: {Authorization: `Bearer ${adminAccessToken}`}}
    )
}

export const httpResetTask = (adminAccessToken, taskId) => {
    return axios.get(`/admin/tasks-matterport/${taskId}/reset`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpSendTaskToHost = (adminAccessToken, taskId, images, isEligible) => {
    return axios.post(
        `/admin/tasks-matterport/${taskId}/assignBedroom`,
        {
            images,
            exclude_for_category: !isEligible
        },
        {headers: {Authorization: `Bearer ${adminAccessToken}`}}
    )
}

export const httpSendBackTask = (adminAccessToken, taskId, note) => {
    return axios.post(
        `/admin/tasks-matterport/${taskId}/sendbackMatterport`,
        {note},
        {headers: {Authorization: `Bearer ${adminAccessToken}`}}
    )
}

export const httpCompleteCheckTask = (adminAccessToken, taskId, images) => {
    return axios.post(
        `/admin/tasks-matterport/${taskId}/completeUpload`,
        {images},
        {headers: {Authorization: `Bearer ${adminAccessToken}`}}
    )
}

export const httpGetTask = (adminAccessToken, taskId, params) => {
    return axios.get(`/admin/tasks-matterport/${taskId}`, {
        params,
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpUploadImages = (adminAccessToken, taskId, photos, onUploadProgress) => {
    return axios.post(`/admin/tasks-matterport/${taskId}/uploadPhotos`, photos, {
        onUploadProgress,
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpDownloadAllImages = (adminAccessToken, taskId) => {
    return axios.get(`/admin/tasks-matterport/${taskId}/downloadPhotos`, {
        responseType: 'arraybuffer',
        headers: {
            Authorization: `Bearer ${adminAccessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const httpDownloadUploadedImages = (adminAccessToken, taskId) => {
    return axios.get(`admin/tasks-matterport/${taskId}/downloadAllData`, {
        responseType: 'arraybuffer',
        headers: {
            Authorization: `Bearer ${adminAccessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const httpConfirmUpload = (adminAccessToken, taskId) => {
    return axios.get(`admin/tasks-matterport/${taskId}/confirmImages`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpGetTablesCounter = adminAccessToken => {
    return axios.get(`/admin/tasks-matterport/count`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpRemoveRestoreImages = (adminAccessToken, taskId, imageId, type) => {
    return axios.get(`admin/tasks-matterport/${taskId}/accessibility_images/${imageId}/deleteOrRestore`, {
        params: {action: type},
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpGetAmenitiesAutocomplete = adminAccessToken => {
    return axios.get('/amenity', {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpGetRoomsAutocomplete = adminAccessToken => {
    return axios.get('/rooms', {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpClaimTask = (adminAccessToken, taskStatus, onlyEligible) => {
    return axios.post(
        '/admin/claimTask',
        {
            status: taskStatus,
            only_eligible: onlyEligible
        },
        {headers: {Authorization: `Bearer ${adminAccessToken}`}}
    )
}
