import styled, {css} from 'styled-components'

export const StyledTitle = styled.h4`
    ${({theme: {spacing}}) => css`
        font-weight: 600;
        margin-bottom: ${spacing * 4}px;
    `}
`

export const UploadWrapper = styled.div`
    ${({theme: {spacing, palette, mediaQuery}}) => css`
        overflow-y: auto;
        padding: ${spacing * 10}px 0;
        background: ${palette.neutral['50']};
        padding-bottom: ${spacing * 10}px;
        ${mediaQuery.SM} {
            padding: ${spacing * 4}px 0;
        }
        ${mediaQuery.LG} {
            gap: ${spacing * 9}px;
        }
    `}
`

export const StyledTabGroup = styled.div`
    ${({theme: {spacing, colors, typography}}) => css`
        display: flex;
        align-items: start;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing * 8}px;
        border-bottom: 1px solid ${colors.extraLightGrey};
        box-sizing: border-box;
        & a {
            background: transparent;
            ${typography.textSm};
            text-decoration: none;
            font-weight: 500;
            color: ${colors.darkGrey};
            padding: ${spacing * 2}px;
            box-sizing: border-box;
            &.active {
                font-weight: 500;
                color: ${colors.airbnbRed};
                border-bottom: 2px solid ${colors.airbnbRed};
            }
        }
    `}
`
