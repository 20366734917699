import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {Assignment} from 'src/features/assignment/types.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {httpGetAssignment} from 'src/features/assignment/services/assignment.http.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'

//TODO: remove queryKey and queryFn from options (using Omit) after the react-query upgrade
export const useAssignment = (code?: number, options?: UseQueryOptions<Assignment>) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.ASSIGNMENT, code],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAssignment({
                    urlParams: {assignmentCode: code ?? raise('The code is not provided')},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        ...options
    })
}
