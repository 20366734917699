import {StyledTRow, StyledTCell, StyledTCellLabel, StyledTCellValue, StyledExpandedRow, StyledSubRow} from './style'
import {useNavigate} from 'react-router'
import {ReactElement, ReactNode} from 'react'

interface RowProps {
    item: unknown
    itemIndex: number
    columnsRenderers: {
        expandedRow: (item: unknown, itemIndex: unknown) => ReactNode
        subRow: (item: unknown, itemIndex: unknown) => ReactNode
    }
    expandedRowsIndexes: number[]
    columns: Array<{label?: string; icon?: ReactElement; mobileFullLength?: boolean; name: string}>
    clickableRowPath?: string
}

export const Row = ({item, itemIndex, columnsRenderers, expandedRowsIndexes, columns, clickableRowPath}: RowProps) => {
    const navigate = useNavigate()

    const onClickRow = () => {
        if (clickableRowPath) navigate(clickableRowPath)
    }

    return (
        <StyledTRow onClick={onClickRow} $clickableRowPath={!!clickableRowPath}>
            {columns.map((column, index) => (
                <StyledTCell key={index}>
                    {(column.label || column.icon) && (
                        <StyledTCellLabel>
                            {column.icon}
                            <span>{column.label}</span>
                        </StyledTCellLabel>
                    )}
                    <StyledTCellValue $mobileFullLength={!!column.mobileFullLength}>
                        {columnsRenderers[column.name as keyof typeof columnsRenderers](item, itemIndex)}
                    </StyledTCellValue>
                </StyledTCell>
            ))}
            {!!columnsRenderers.expandedRow && expandedRowsIndexes.includes(itemIndex) && (
                <StyledExpandedRow>{columnsRenderers.expandedRow(item, itemIndex)}</StyledExpandedRow>
            )}
            {!!columnsRenderers.subRow && <StyledSubRow>{columnsRenderers.subRow(item, itemIndex)}</StyledSubRow>}
        </StyledTRow>
    )
}
