import {useTranslation} from 'react-i18next'
import {Modal} from 'src/components/modal/Modal'
import {useForm} from 'react-hook-form'
import {Checkbox} from 'src/components/checkbox/Checkbox'
import {useSubmitToAirbnb} from 'src/features/assignment/services/useSubmitToAirbnb'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {getServiceType} from 'src/features/assignment/utils'
import {Assignment} from 'src/features/assignment/types.ts'
import {ModalBody, ModalFooter, ModalParagraph, ModalTitle} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {TextArea} from 'src/components/textarea/Textarea.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {StyledAlertIcon} from 'src/features/assignment/components/submit-to-airbnb-modal/style.ts'
import {AlertCircleIcon} from 'src/components/icon'

export const SubmitToAirbnbModal = ({
    onClose,
    assignment,
    setSuccessModalCb
}: {
    onClose: () => void
    assignment: Assignment
    setSuccessModalCb: () => void
}) => {
    const {t} = useTranslation()
    const photoValidationSchema = z.object({
        notes: z.string().nullable(),
        confirm1: z.literal(true, t('errors:required')),
        confirm2: z.literal(true, t('errors:required'))
    })
    const expVideoValidationSchema = z.object({
        notes: z.string().nullable(),
        confirm1_exp: z.literal(true, t('errors:required')),
        confirm2_exp: z.literal(true, t('errors:required')),
        confirm3_exp: z.literal(true, t('errors:required'))
    })
    const isExperienceVideo =
        getServiceType(assignment.task_media.name, assignment.task_category.name) == 'experience_video'

    const submitToAirbnbMutation = useSubmitToAirbnb({
        assignmentId: assignment.id,
        options: {
            onSuccess: () => {
                setSuccessModalCb()
                onClose()
            }
        }
    })

    const form = useForm({
        resolver: zodResolver(isExperienceVideo ? expVideoValidationSchema : photoValidationSchema)
    })
    const errors = form.formState.errors

    const handleSubmit = form.handleSubmit(formValues => {
        submitToAirbnbMutation.mutate(formValues.notes)
    })

    return (
        <Modal onOverlayClick={onClose} width={438}>
            <ModalBody>
                <StyledAlertIcon>
                    <AlertCircleIcon size={20} />
                </StyledAlertIcon>
                <Flexbox direction="column" gap={2}>
                    <ModalTitle>{t('assignment:descriptions:submit_to_airbnb_modal:title')}</ModalTitle>
                    <ModalParagraph>{t('assignment:descriptions:submit_to_airbnb_modal:subtitle')}</ModalParagraph>
                </Flexbox>
                {isExperienceVideo ? (
                    <Flexbox direction="column" gap={2}>
                        <Checkbox
                            id="confirm1_exp"
                            {...form.register('confirm1_exp')}
                            /*TODO: remove ?.toString() after react hook form upgrade*/
                            errorMessage={errors?.confirm1_exp?.message?.toString()}
                            label={t('assignment:descriptions:submit_to_airbnb_modal:checkbox_1_experience')}
                        />
                        <Checkbox
                            id="confirm2_exp"
                            {...form.register('confirm2_exp')}
                            errorMessage={errors?.confirm2_exp?.message?.toString()}
                            label={t('assignment:descriptions:submit_to_airbnb_modal:checkbox_2_experience', {
                                listingId: assignment.entity.external_id,
                                hostName: assignment.host.first_name
                            })}
                        />
                        <Checkbox
                            id="confirm3_exp"
                            {...form.register('confirm3_exp')}
                            errorMessage={errors?.confirm3_exp?.message?.toString()}
                            label={t('assignment:descriptions:submit_to_airbnb_modal:checkbox_2')}
                        />
                    </Flexbox>
                ) : (
                    <Flexbox direction="column" gap={2}>
                        <Checkbox
                            id="confirm1"
                            {...form.register('confirm1')}
                            errorMessage={errors?.confirm1?.message?.toString()}
                            label={t('assignment:descriptions:submit_to_airbnb_modal:checkbox_1', {
                                listingId: assignment.entity.external_id,
                                hostName: assignment.host.first_name
                            })}
                        />
                        <Checkbox
                            id="confirm2"
                            {...form.register('confirm2')}
                            errorMessage={errors?.confirm2?.message?.toString()}
                            label={t('assignment:descriptions:submit_to_airbnb_modal:checkbox_2')}
                        />
                    </Flexbox>
                )}
                <TextArea
                    placeholder={t('assignment:descriptions:submit_to_airbnb_modal:notes_placeholder')}
                    errorMessage={errors?.notes?.message?.toString()}
                    label={t('commons:notes')}
                    {...form.register('notes')}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    fullWidth
                    onClick={handleSubmit}
                    disabled={submitToAirbnbMutation.isLoading || !form.formState.isValid}
                >
                    {t('commons:submit')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
