import {useState} from 'react'
import {useWindowSize} from '@react-hook/window-size'
import PropTypes from 'prop-types'

import {Table} from '../../../../../../components/table-legacy/Table'
import {AirbnbCell} from '../../../../../../components/table-legacy/desktop/cell/airbnb/AirbnbCell'
import {StatusCell} from '../../../../../../components/table-legacy/desktop/cell/status/Status'
import {TransactionsList} from './transactions-list/TransactionsList'
import {TransactionColumn} from './transaction-column/TransactionColumn'

import {useCompletedShoots} from '../../../../../../hooks/useCompletedShoots'
import {screenWidth} from '../../../../../../theme/breakpoints'
import {formatLocaleDate, openLinkInNewTab} from '../../../../../../helpers/utils'
import {getCompletedShootsColumns} from '../../../../../../helpers/columns'

import {ShootDateWrapper} from './style'
import {Button} from '../../../../../../components/button/Button'
import {useTranslation} from 'react-i18next'

export const Completed = ({search}) => {
    const {t} = useTranslation()
    const [pageWidth, pageHeight] = useWindowSize()
    const [expandedRowsIndexes, setExpandedRowsIndexes] = useState([])
    const {data: tableData, error, page, onChangePage, sorter, onSortColumn, ...query} = useCompletedShoots({search})
    const isMobileVersion = pageWidth < screenWidth.LG

    const onToggleTransactionsAccordion = index => {
        setExpandedRowsIndexes(prevState => {
            return prevState.includes(index) ? prevState.filter(e => e !== index) : [...prevState, index]
        })
    }

    return (
        <Table
            data={tableData}
            isPaginated
            isError={!!error}
            isMobileVersion={isMobileVersion}
            isLoading={query.isLoading}
            isRefetching={query.isRefetching}
            isFetchingNextPage={query.isFetchingNextPage}
            onChangePageCb={onChangePage}
            page={page}
            sorter={sorter}
            sortColumnCb={onSortColumn}
            columns={getCompletedShootsColumns()}
            expandedRowsIndexes={expandedRowsIndexes}
            tbodyHeight={pageWidth < screenWidth.LG ? `calc(${pageHeight}px - 160px)` : `calc(${pageHeight}px - 416px)`}
            columnsRenderers={{
                external_id: item => <AirbnbCell value={item.entity?.external_id} item={item} />,
                host: item => <span>{item.host?.first_name ?? '-'}</span>,
                amount: item => <span>{item.total_amount ? `${item.total_amount} USD` : '-'}</span>,
                status: item => <StatusCell item={item} />,
                completed_at: item => (
                    <ShootDateWrapper>{formatLocaleDate(item.completed_at, 'MMM D, YYYY')}</ShootDateWrapper>
                ),
                daryl_cta: item =>
                    item?.task?.daryl_directory_id &&
                    item?.task_category?.name === 'Experiences' &&
                    item?.task_media?.name === 'video' ? (
                        <Button
                            fullWidth
                            variant="secondary"
                            size="xs"
                            onClick={() =>
                                openLinkInNewTab(
                                    `https://airbnb.orangelogic.com/asset-management/${item.task.daryl_directory_id}?Flat=y&WS=AssetManagement`
                                )
                            }
                        >
                            {t('assignment:open_daryl')}
                        </Button>
                    ) : (
                        false
                    ),
                iconik_cta: item =>
                    item?.task?.iconik_url &&
                    item?.task_category?.name === 'Experiences' &&
                    item?.task_media?.name === 'video' ? (
                        <Button
                            disabled={item.task.iconik_url == null}
                            fullWidth
                            variant="secondary"
                            size="xs"
                            onClick={() => openLinkInNewTab(item.task.iconik_url)}
                        >
                            {t('assignment:open_iconik')}
                        </Button>
                    ) : (
                        false
                    ),
                transactions: (item, index) =>
                    item.payments.length ? (
                        <TransactionColumn
                            payments={item.payments}
                            index={index}
                            expandedRowsIndexes={expandedRowsIndexes}
                            onToggleTransactionsAccordion={onToggleTransactionsAccordion}
                            isMobileVersion={isMobileVersion}
                        />
                    ) : null,
                ...(!isMobileVersion && {expandedRow: item => <TransactionsList payments={item.payments} />})
            }}
        />
    )
}

Completed.propTypes = {
    search: PropTypes.string
}
