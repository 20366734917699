import {create} from 'zustand'
import {AdminUser, Country, Geo, Region, User} from 'src/features/user/types.ts'
import {APP_VERSION} from 'src/helpers/constants.ts'
import {raise} from 'src/helpers/helpers.ts'

type UserStore = {
    accessToken: string | null
    adminAccessToken: string | null
    refreshToken: string | null
    currentUser: User | null
    currentAdmin: AdminUser | null
    markets: {
        countries: unknown[]
        markets: unknown[]
        regions: unknown[]
    }
    setUser: (data: {user: User; access_token: string; refresh_token: string; isAdmin: boolean}) => void
    resetUser: (type: 'admin' | 'user' | 'all') => void
    setCurrentUser: (user: User) => void
    setCurrentAvatar: (avatar: string) => void
    setCurrentDescription: (description: string) => void
    setMarkets: (
        options: {type: 'regions'; data: Region[]} | {type: 'countries'; data: Country[]} | {type: 'geos'; data: Geo[]}
    ) => void
    changeUserMarkets: (markets: {
        region: {label: Region; value: Region}
        countries: {label: string; value: string}[]
        markets: Array<{country: string; label: string; value: string}>
    }) => void
    setHasVoted: (hasVoted: boolean) => void
}

export const useUserStore = create<UserStore>((set, getState) => ({
    accessToken: localStorage.getItem('accessToken'),
    adminAccessToken: localStorage.getItem('adminAccessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    currentUser: null,
    currentAdmin: null,
    markets: {
        countries: [],
        markets: [],
        regions: []
    },
    setUser: ({access_token, isAdmin, refresh_token, user}) => {
        if (isAdmin) {
            localStorage.setItem('adminAccessToken', access_token)
            localStorage.setItem('app_v', APP_VERSION)

            set({
                adminAccessToken: access_token,
                currentUser: user
            })
        } else {
            localStorage.setItem('app_v', APP_VERSION)
            localStorage.setItem('accessToken', access_token)
            localStorage.setItem('refreshToken', refresh_token)
            set({
                accessToken: access_token,
                refreshToken: refresh_token,
                currentUser: user
            })
        }
    },
    resetUser: type => {
        localStorage.removeItem('app_v')

        switch (type) {
            case 'admin':
                localStorage.removeItem('adminAccessToken')
                set({
                    currentUser: null,
                    adminAccessToken: null
                })
                break
            case 'user':
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                set({
                    currentUser: null,
                    accessToken: null,
                    refreshToken: null
                })
                break
            default:
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('adminAccessToken')
                localStorage.removeItem('refreshToken')
                set({
                    currentUser: null,
                    accessToken: null,
                    adminAccessToken: null,
                    refreshToken: null
                })
        }
    },
    setCurrentUser: user => set({currentUser: user}),
    setCurrentAvatar: avatar => {
        const user = getState().currentUser ?? raise('User is null')
        set({currentUser: {...user, profile_photo_url: avatar}})
    },
    setCurrentDescription: description => {
        const user = getState().currentUser ?? raise('User is null')
        set({currentUser: {...user, description}})
    },
    setMarkets: ({type, data}) => {
        switch (type) {
            case 'regions':
                set(state => ({
                    markets: {
                        ...state.markets,
                        regions: data.map(region => ({label: region, value: region}))
                    }
                }))
                break
            case 'countries':
                set(state => ({
                    markets: {
                        ...state.markets,
                        countries: data.map(({country}) => ({label: country, value: country}))
                    }
                }))
                break
            default:
                set(state => ({
                    markets: {
                        ...state.markets,
                        markets: data.map(market => ({
                            country: market.country,
                            label: market.geo,
                            value: market.external_id
                        }))
                    }
                }))
        }
    },
    changeUserMarkets: ({region, countries, markets}) => {
        const user = getState().currentUser ?? raise('User is null')
        set({
            currentUser: {
                ...user,
                photographers_markets: markets.map(market => ({
                    region: region.value,
                    country:
                        countries.find(country => market.country == country.value)?.value ?? raise('Country is null'),
                    external_id: market.value,
                    geo: market.label
                }))
            }
        })
    },
    setHasVoted: hasVoted => {
        const user = getState().currentUser ?? raise('User is null')
        set({currentUser: {...user, has_voted: hasVoted}})
    }
}))
