import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Virtuoso} from 'react-virtuoso'

import {Chip} from '../../../../../components/chip/Chip'
import {Spinner} from '../../../../../components/spinner-legacy/Spinner'
import {colors} from '../../../../../theme/colors'
import {formatLocaleDate} from '../../../../../helpers/utils'
import {quoteStatusToLabel, taskStatusToLabel, coordinationStatusToLabel} from '../../../../../helpers/statuses'

import {
    StyledAssignmentCardDetailsItemChips,
    StyledActivityCardDetailsItemTitle,
    StyledActivitiesCard,
    StyledActivityCardDetailsItem,
    StyledActivityCardDetails,
    StyledNoResultsText,
    StyledCoordinationStatusLabel,
    StyledStatusWrapper,
    StyledArrowRight
} from './styles'

export const ActivitiesCard = ({activities, onChangePage, isFetching}) => {
    const {t} = useTranslation()

    const customScrollParent = useRef()

    return (
        <StyledActivitiesCard>
            <h3>{t('admin:assignments:activities')}</h3>
            <StyledActivityCardDetails ref={customScrollParent}>
                {activities.length === 0 ? (
                    <StyledNoResultsText>{t('commons:no_results')}</StyledNoResultsText>
                ) : (
                    <Virtuoso
                        style={{height: '100%'}}
                        data={activities}
                        endReached={onChangePage}
                        overscan={100}
                        itemContent={(index, activity) => {
                            return (
                                <StyledActivityCardDetailsItem $isLast={index === activities.length - 1} key={index}>
                                    <StyledActivityCardDetailsItemTitle>
                                        <span>{formatLocaleDate(activity?.updated_at, 'LLL')}</span>
                                        {activity.user_object === 'admin' ? (
                                            <div>
                                                {activity.admin_name && (
                                                    <h4 title={activity.admin_name}>{activity.admin_name}</h4>
                                                )}
                                                <p>{t('commons:admin')}</p>
                                            </div>
                                        ) : activity.user_object === 'host' ? (
                                            <div>
                                                {activity.host_name && (
                                                    <h4 title={activity.host_name}>{activity.host_name}</h4>
                                                )}
                                                <p>{t('commons:host')}</p>
                                            </div>
                                        ) : (
                                            <div>
                                                {activity.photographer?.name && (
                                                    <h4 title={activity.photographer?.name}>
                                                        {activity.photographer?.name}
                                                    </h4>
                                                )}
                                                <p>{t('commons:freelancer')}</p>
                                            </div>
                                        )}
                                    </StyledActivityCardDetailsItemTitle>
                                    <StyledAssignmentCardDetailsItemChips>
                                        <StyledStatusWrapper>
                                            <Chip
                                                text={
                                                    activity.object === 'task'
                                                        ? t(taskStatusToLabel[activity.previous_status?.status])
                                                        : t(quoteStatusToLabel[activity.previous_status?.status])
                                                }
                                                rounded
                                                size="medium"
                                            />
                                            {activity.object === 'task' &&
                                                activity?.previous_status?.coordination_status && (
                                                    <StyledCoordinationStatusLabel>
                                                        {t(
                                                            coordinationStatusToLabel[
                                                                activity.previous_status?.coordination_status
                                                            ]
                                                        )}
                                                    </StyledCoordinationStatusLabel>
                                                )}
                                        </StyledStatusWrapper>

                                        <StyledArrowRight fill={colors.steelGrey} />
                                        <StyledStatusWrapper>
                                            <Chip
                                                text={
                                                    activity.object === 'task'
                                                        ? t(taskStatusToLabel[activity.current_status?.status])
                                                        : t(quoteStatusToLabel[activity.current_status?.status])
                                                }
                                                rounded
                                                size="medium"
                                            />
                                            {activity.object === 'task' &&
                                                activity?.current_status?.coordination_status && (
                                                    <StyledCoordinationStatusLabel>
                                                        {t(
                                                            coordinationStatusToLabel[
                                                                activity.current_status.coordination_status
                                                            ]
                                                        )}
                                                    </StyledCoordinationStatusLabel>
                                                )}
                                        </StyledStatusWrapper>
                                    </StyledAssignmentCardDetailsItemChips>
                                </StyledActivityCardDetailsItem>
                            )
                        }}
                        components={{
                            ...(isFetching && {Footer: () => <Spinner center={false} overlay={false} size={25} />})
                        }}
                    />
                )}
            </StyledActivityCardDetails>
        </StyledActivitiesCard>
    )
}

ActivitiesCard.propTypes = {
    isFetching: PropTypes.bool,
    onChangePage: PropTypes.func,
    activities: PropTypes.arrayOf(
        PropTypes.shape({
            current_status: PropTypes.object,
            host_name: PropTypes.string,
            id: PropTypes.number,
            object: PropTypes.string,
            photographer_name: PropTypes.string,
            previous_status: PropTypes.object,
            updated_at: PropTypes.string,
            user_id: PropTypes.number,
            user_object: PropTypes.string
        })
    )
}
