import styled, {css} from 'styled-components'
import {Button} from 'src/components/button/Button.tsx'
import {Modal} from 'src/components/modal-atoms/ModalAtoms.tsx'

export const StyledSorterModal = styled(Modal)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 5}px;
    `
)

export const StyledHead = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 5}px ${spacing * 4}px 0;
        ${typography.textLg};
        font-weight: 600;
        color: ${palette.neutral['900']};
    `
)

export const StyledBody = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        height: 100%;
        overflow: auto;
        padding-bottom: ${spacing * 5}px;
    `
)

export const StyledButton = styled(Button)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
`
