import {useWindowSize} from '@react-hook/window-size'
import {CSSProperties, ReactNode, useEffect, useRef, useState} from 'react'
// @ts-expect-error "there are not types for use-key-hook"
import useKey from 'use-key-hook'
import {Spinner} from 'src/components/spinner-legacy/Spinner'
import {Footer} from './footer/Footer'
import {StyledContent, StyledModal, StyledCloseIcon, StyledOverlay, StyledHeader, StyledBody} from './style'
import {createPortal} from 'react-dom'

interface ModalProps {
    onClose?: () => void
    hasCloseIcon?: boolean
    isLoading?: boolean
    title?: string
    children?: ReactNode
    buttonPrimary?: {
        text: string
        onClick: () => void
        disabled?: boolean
    }
    buttonSecondary?: {
        text: string
        onClick: () => void
        disabled?: boolean
    }
    buttonsAlignment?: CSSProperties['justifyContent']
    overflow?: CSSProperties['overflow']
    width?: CSSProperties['width']
}

/**
 * @deprecated "use the new Modal component"
 * */
export const Modal = ({
    onClose,
    hasCloseIcon,
    isLoading,
    title,
    children,
    buttonPrimary,
    buttonSecondary,
    buttonsAlignment = 'space-between',
    overflow,
    width
}: ModalProps) => {
    // @ts-expect-error "useWindowSize wrong types"
    const {height: pageHeight} = useWindowSize()
    const headerRef = useRef<HTMLDivElement | null>(null)
    const footerRef = useRef<HTMLDivElement | null>(null)
    const [bodyHeight, setBodyHeight] = useState(0)

    useKey(onClose, {detectKeys: [27]})

    useEffect(() => {
        setBodyHeight(pageHeight - (headerRef.current?.offsetHeight || 0) - (headerRef.current?.offsetHeight || 0) - 32) //32 = margins
    }, [headerRef, footerRef, pageHeight])

    return createPortal(
        <StyledModal>
            <StyledOverlay onClick={onClose} />
            <StyledContent $width={width}>
                {hasCloseIcon && <StyledCloseIcon size={20} onClick={onClose} />}
                {!!title && (
                    <StyledHeader ref={headerRef}>
                        <h1>{title}</h1>
                    </StyledHeader>
                )}
                {!!children && (
                    <StyledBody $bodyHeight={bodyHeight} $overflow={overflow}>
                        {children}
                    </StyledBody>
                )}
                {(buttonPrimary || buttonSecondary) && (
                    <Footer
                        ref={footerRef}
                        buttonsAlignment={buttonsAlignment}
                        buttonPrimary={buttonPrimary}
                        buttonSecondary={buttonSecondary}
                    />
                )}
                {isLoading && <Spinner overlay />}
            </StyledContent>
        </StyledModal>,
        document.body
    )
}
