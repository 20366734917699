import styled, {css} from 'styled-components'
import {ToggleSwitch} from '../../../../components/toggle-switch/ToggleSwitch'
import {Chip} from '../../../../components/chip/Chip'
import {Button} from '../../../../components/button/Button'
import {Textarea} from '../../../../components/textarea-legacy/Textarea'
import {Flexbox} from '../../../../components/flexbox/Flexbox'
import {Spinner} from '../../../../components/spinner-legacy/Spinner'
import {Avatar} from '../../../../components/avatar-legacy/Avatar'

export const StyledChangePassword = styled(Flexbox)`
    ${({theme: {spacing, mediaQuery}}) => css`
        margin-bottom: ${spacing * 7}px;
        label {
            margin-bottom: 0;
        }
        ${mediaQuery.XS} {
            flex-direction: column;
        }
    `}
`

export const StyledNotificationToggle = styled.div`
    display: inline-flex;
    align-items: center;
`

export const StyledAvatarInputFile = styled.input`
    position: absolute;
    visibility: hidden;
    width: 0px;
    height: 0px;
`

export const StyledAvatarPickerWrapper = styled(Flexbox)`
    position: relative;
    cursor: pointer;
    svg {
        width: 32px;
        height: 32px;
    }
`

export const StyledAvatarPicker = styled(Avatar)`
    width: 54px;
    height: 54px;
`

export const StyledAvatarSpinner = styled(Spinner)`
    border-radius: 100%;
`

export const StyledTextAreaBioWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        width: 100%;
        p {
            margin-top: ${spacing * 3.5}px;
            text-align: right;
        }
    `}
`

export const StyledTextAreaBio = styled(Textarea)`
    ${({theme: {colors, typography, palette, transition}}) => css`
        textarea {
            border-radius: 0;
            border: none;
            border-bottom-width: 1px;
            padding-left: 0;
            border-bottom-style: solid;
            border-bottom-color: ${colors.inputGrey};
            min-height: unset;
            width: 100%;
            ${transition};
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral.black};
            &:focus {
                border-top: none;
                border-right: none;
                border-left: none;
            }
        }
    `}
`

export const StyledProfileInfoMessage = styled.div`
    ${({theme: {colors, typography}}) => css`
        ${typography.textXs};
        font-weight: 500;
        color: ${colors.darkGrey};
    `}
`

export const AvailableToggleSwitch = styled(ToggleSwitch)`
    ${({theme: {spacing, mediaQuery}}) => css`
        position: absolute;
        top: ${spacing * 10}px;
        right: ${spacing * 10}px;
        ${mediaQuery.SM} {
            top: ${spacing * 20}px;
            left: ${spacing * 10}px;
        }
    `}
`

export const SmsToggleSwitch = styled(ToggleSwitch)`
    ${({theme: {spacing}}) => css`
        display: inline-flex;
        align-items: center;
        margin-top: ${spacing * 7}px;
    `}
`

export const CardBodyWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        display: grid;
        grid-template-columns: 476px auto;
        column-gap: ${spacing * 8}px;
        ${mediaQuery.LG} {
            display: block;
        }
    `}
`

export const FullNameWrapper = styled.div`
    ${({theme: {mediaQuery}}) => css`
        & p,
        label {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        ${mediaQuery.SM} {
            flex-flow: column;
        }
    `}
`

export const BetaChip = styled(Chip)`
    ${({theme: {spacing}}) => css`
        display: inline-flex;
        letter-spacing: 2px;
        padding: ${spacing * 0.5}px ${spacing * 2}px;
        margin-left: ${spacing}px;
        text-transform: uppercase;
    `}
`

export const NotificationsWrapper = styled.div`
    ${({theme: {spacing, colors, mediaQuery, transition}}) => css`
        padding-left: ${spacing * 7}px;
        border-left: 1px solid ${colors.inputGrey};
        & a {
            text-decoration: none;
            color: ${colors.darkGreen};
            font-weight: 700;
            ${transition};
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }

        ${mediaQuery.LG} {
            padding-left: 0;
            border-left: 0;
            margin-top: ${spacing * 7}px;
            padding-top: ${spacing * 7}px;
            border-top: 1px solid ${colors.inputGrey};
        }
    `}
`

export const ChangePasswordButton = styled(Button)`
    ${({theme: {mediaQuery, spacing}}) => css`
        ${mediaQuery.SM} {
            margin-top: ${spacing * 14}px;
        }
    `}
`
