import {TableButton} from '../../table-legacy/desktop/button/TableButton'
import {useQueryClient} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import {StyledRefreshCalendarMessage, StyledRefreshCalendarMessageContainer} from './style'
import {QUERY_KEYS} from '../../../queryClient'

export const RefreshCalendarMessage = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    return (
        <StyledRefreshCalendarMessage>
            <StyledRefreshCalendarMessageContainer>
                <span>{t('errors:default')}</span>
                {/*TODO: replace to new button component*/}
                <TableButton
                    text={t('calendar:refresh_calendar')}
                    onClick={() => {
                        queryClient.invalidateQueries({
                            queryKey: [QUERY_KEYS.ACTIVE_SHOOTS_RANGE]
                        })
                    }}
                />
            </StyledRefreshCalendarMessageContainer>
        </StyledRefreshCalendarMessage>
    )
}
