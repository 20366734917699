import {FC, HTMLAttributes} from 'react'

export const CloseIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 20, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.806 4.56966C13.1965 4.17913 13.1965 3.54597 12.806 3.15544C12.4155 2.76492 11.7823 2.76492 11.3918 3.15544L8.09888 6.44834L4.80598 3.15544C4.41546 2.76492 3.78229 2.76492 3.39177 3.15544C3.00125 3.54597 3.00125 4.17913 3.39177 4.56966L6.68466 7.86255L3.39177 11.1554C3.00125 11.546 3.00125 12.1791 3.39177 12.5697C3.78229 12.9602 4.41546 12.9602 4.80598 12.5697L8.09888 9.27676L11.3918 12.5697C11.7823 12.9602 12.4155 12.9602 12.806 12.5697C13.1965 12.1791 13.1965 11.546 12.806 11.1554L9.51309 7.86255L12.806 4.56966Z"
            fill="currentColor"
        />
    </svg>
)
