import styled, {css} from 'styled-components'

export const StyledDeleteTitle = styled.h2`
    ${({theme: {spacing, typography}}) => css`
        ${typography.displayMd};
        font-weight: 500;
        margin-bottom: ${spacing * 8}px;
    `}
`

export const StyledEmptyMessage = styled.p`
    ${({theme: {colors, typography}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${colors.grey};
        text-align: center;
    `}
`
