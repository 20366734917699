import axios, {RawAxiosRequestHeaders} from 'axios'

export const httpAdminLogin = () => {
    return axios.get('/admin/linkAuth')
}

export const httpAdminLogout = (adminAccessToken: string) => {
    return axios.get('/admin/logout', {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpAdminOneLoginLogout = (adminAccessToken: string) => {
    return axios.get('/admin/logoutOnelogin', {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

/**
 * @httpUserMe
 * Get admin data
 */
export const httpUserAdminMe = ({headers}: {headers: RawAxiosRequestHeaders}) => {
    return axios.get(`/admin/me`, {headers})
}
