import styled, {css} from 'styled-components'
import {Modal} from 'src/components/modal/Modal.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'

export const StyledDeclineModal = styled(Modal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    overflow: hidden;
`

export const StyledDeclineModalBodyText = styled.div(
    ({theme: {spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;

        & > p {
            ${typography.textSm};
        }
    `
)

export const StyledSpinner = styled(Spinner)`
    align-self: center;
`
