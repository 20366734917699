import {useTranslation} from 'react-i18next'
import {PageHelmet} from 'src/components/page-helmet/PageHelmet.tsx'
import {UploadWrapper, StyledTitle, StyledTabGroup} from './style'
import {NavLink, Outlet} from 'react-router-dom'
import {ROUTES} from 'src/helpers/constants.ts'
import {Container} from 'src/components/container/Container.tsx'

export const Payments = () => {
    const {t} = useTranslation()

    return (
        <UploadWrapper>
            <PageHelmet title={t('titles:admin_payments')} />
            <Container fullHeight={false}>
                <StyledTitle>{t('admin:payments:title')}</StyledTitle>
                <StyledTabGroup>
                    <NavLink to={ROUTES.ADMIN_PAYMENTS_GENERATE}>{t('admin:payments:export_title')}</NavLink>
                    <NavLink to={ROUTES.ADMIN_PAYMENTS_PROCESS}>{t('admin:payments:import_title')}</NavLink>
                </StyledTabGroup>
                <Outlet />
            </Container>
        </UploadWrapper>
    )
}
