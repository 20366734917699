import styled, {css} from 'styled-components'
import {DESKTOP_TABLE_COLUMNS_PADDING, DESKTOP_TABLE_SCROLLBAR_WIDTH} from 'src/components/table/desktop-table/style'
import {Virtuoso} from 'react-virtuoso'
import {Percentage} from 'src/types'

export const StyledDesktopTBody = styled(Virtuoso)<{$variant?: 'neutral' | 'default'}>`
    ${({theme: {palette}, $variant}) => css`
        overflow-y: scroll !important;
        background-color: ${palette.neutral.white};

        ${$variant == 'default' &&
        css`
            border-radius: 8px;
            border: 1px solid ${palette.neutral['200']};
        `};
        ${$variant == 'neutral' &&
        css`
            color: ${palette.neutral['500']};
        `};

        &::-webkit-scrollbar {
            width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `}
`

export const StyledTRowWrapper = styled.div(
    ({theme: {palette, transition}}) => css`
        border-bottom: 1px solid ${palette.neutral[200]};
        ${transition};
        &:hover {
            background: ${palette.neutral['50']};
        }
    `
)

export const StyledTRow = styled.div`
    display: flex;
`

interface StyledTCellProps {
    $width: Percentage
    $alignment?: 'left' | 'center' | 'right'
}
export const StyledTCell = styled.div<StyledTCellProps>(
    ({$width, $alignment, theme: {typography}}) => css`
        width: ${$width};
        min-height: 72px;
        display: flex;
        align-items: center;
        justify-content: ${$alignment == 'right' ? 'flex-end' : $alignment == 'center' ? 'center' : 'flex-start'};
        padding: 0 ${DESKTOP_TABLE_COLUMNS_PADDING};
        ${typography.textSm};
        white-space: nowrap;

        & > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `
)
