import {useMutation, useQueryClient} from '@tanstack/react-query'
import {errorHandler} from '../../helpers/utils'
import {httpPostQuoteAdjustment} from '../../http-requests/admin/quotes.http'
import {QUERY_KEYS} from '../../queryClient'
import {useUserStore} from '../../features/user/store'

export const useUpdateAdjustment = ({selectedQuoteID, onSuccess}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    const mutation = useMutation({
        mutationFn: refactoredFormValues =>
            httpPostQuoteAdjustment(adminAccessToken, selectedQuoteID, refactoredFormValues),
        onSuccess: () => {
            queryClient.cancelQueries([QUERY_KEYS.ADMIN_QUOTE, selectedQuoteID])
            queryClient.invalidateQueries([QUERY_KEYS.ADMIN_QUOTE, selectedQuoteID])

            onSuccess()
        },
        onError: errorHandler
    })

    return mutation
}
