import {Flexbox} from 'src/components/flexbox/Flexbox'
import {useAdminHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useAdminHostApprovalTaskImages'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button'
import {CheckIcon, ChevronDownIcon, ChevronUpIcon} from 'src/components/icon'
import {Skeleton} from 'src/components/skeleton/Skeleton'
import {AdminHostApprovalRoomsAmenitiesImages} from 'src/features/admin-host-approval/components/admin-host-approval-rooms-amenities-images/AdminHostApprovalRoomsAmenitiesImages'
import {
    StyledAdminHostApprovalRejected,
    StyledAdminHostApprovalRejectedImages,
    StyledAdminHostApprovalRejectedImagesTitle
} from './style'

export const AdminHostApprovalRejectedImages = ({taskId}: {taskId: number}) => {
    const {t} = useTranslation()
    const [isRejectedAccordionOpen, setIsRejectedAccordionOpen] = useState(false)
    const {data: taskImages, isLoading: isLoadingTaskImages} = useAdminHostApprovalTaskImages(taskId, {
        enabled: false
    })

    const rejectedImagesCount = useMemo(
        () =>
            taskImages?.filter(taskImage => taskImage.is_host_approved == false && !taskImage.from_photo_gallery)
                ?.length ?? 0,
        [taskImages]
    )

    return (
        <StyledAdminHostApprovalRejectedImages>
            <StyledAdminHostApprovalRejected>
                {rejectedImagesCount > 0 ? (
                    <Flexbox justify="space-between" fullWidth align="center">
                        {isLoadingTaskImages ? (
                            <Skeleton height={20} width={140} />
                        ) : (
                            <StyledAdminHostApprovalRejectedImagesTitle>
                                {t('host_approval_task:rejected_images_count', {count: rejectedImagesCount})}
                            </StyledAdminHostApprovalRejectedImagesTitle>
                        )}

                        {!isLoadingTaskImages && (
                            <Button
                                variant="tertiary"
                                shape="text"
                                onClick={() => setIsRejectedAccordionOpen(prev => !prev)}
                            >
                                {isRejectedAccordionOpen ? (
                                    <>
                                        {t('commons:collapse')}
                                        <ChevronUpIcon />
                                    </>
                                ) : (
                                    <>
                                        {t('commons:expand')}
                                        <ChevronDownIcon />
                                    </>
                                )}
                            </Button>
                        )}
                    </Flexbox>
                ) : (
                    <Flexbox fullWidth gap={4} align="center">
                        <CheckIcon />
                        <StyledAdminHostApprovalRejectedImagesTitle>
                            {t('host_approval_task:no_images_rejected')}
                        </StyledAdminHostApprovalRejectedImagesTitle>
                    </Flexbox>
                )}
            </StyledAdminHostApprovalRejected>

            {isRejectedAccordionOpen && (
                <AdminHostApprovalRoomsAmenitiesImages
                    taskId={taskId}
                    isLoadingTaskImages={isLoadingTaskImages}
                    emptyResultMessage={t('host_approval_task:no_images_rejected')}
                    taskImages={
                        taskImages
                            ? taskImages.filter(
                                  taskImage => taskImage.amenity_id && taskImage.room_id && !taskImage.is_host_approved
                              )
                            : []
                    }
                />
            )}
        </StyledAdminHostApprovalRejectedImages>
    )
}
