import {StyledTbody} from './style'
import {Row} from '../row/TableMobileRow'
import {ErrorMessage} from '../../error-message/ErrorMessage'

export const NotPaginatedBody = ({
    columns,
    expandedRowsIndexes,
    columnsRenderers,
    onClickRow,
    tbodyHeight,
    isError,
    onRefresh,
    data
}) => {
    if (isError) {
        return (
            <StyledTbody $tbodyHeight={tbodyHeight}>
                <ErrorMessage onRefresh={onRefresh} />
            </StyledTbody>
        )
    }

    return (
        <StyledTbody $tbodyHeight={tbodyHeight}>
            {data.map((item, itemIndex) => {
                const clickableRowPath = onClickRow(item)

                return (
                    <Row
                        columns={columns}
                        expandedRowsIndexes={expandedRowsIndexes}
                        itemIndex={itemIndex}
                        item={item}
                        columnsRenderers={columnsRenderers}
                        clickableRowPath={clickableRowPath}
                    />
                )
            })}
        </StyledTbody>
    )
}
