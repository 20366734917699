import {Modal} from 'src/components/modal/Modal.tsx'
import {
    ModalBody,
    ModalXCloseButton,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalParagraph
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button.tsx'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {useScheduleAssignment} from 'src/features/assignment/services/useScheduleAssignment.ts'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {Controller, useForm} from 'react-hook-form'
import {z} from 'zod'
import dayjs, {formatLocaleDate} from 'src/dayjs.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {StyledDates} from 'src/features/assignment/components/schedule-modal/style.ts'
import {DatePicker} from 'src/components/date-picker/DatePicker.tsx'
import {TimePicker} from 'src/components/time-picker/TimePicker.tsx'
import {useUserStore} from 'src/features/user/store.ts'

const formatDate = (date: string) => formatLocaleDate(date, 'LLLL')

const ValidationSchema = z.object({
    date: z.date(),
    time: z.string().min(1)
})
type ValidationSchema = z.infer<typeof ValidationSchema>

export const ScheduleModal = ({
    assignment,
    serviceType,
    onClose
}: {
    assignment: Assignment
    serviceType: ServiceType
    onClose: () => void
}) => {
    const {t} = useTranslation()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const scheduleAssignmentMutation = useScheduleAssignment({
        assignmentId: assignment.id,
        options: {
            onSuccess: onClose
        }
    })
    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema)
    })

    const handleSubmit = form.handleSubmit(values => {
        //date to send adapted to safari format too
        const dateToSend = dayjs(`${dayjs(values.date).format('YYYY-MM-DD')} ${values.time}`.replace(/-/g, '/')).format(
            'YYYY-MM-DD HH:mm:ss'
        )
        scheduleAssignmentMutation.mutate(dateToSend)
    })

    return (
        <Modal onOverlayClick={onClose} width={480}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} disabled={scheduleAssignmentMutation.isLoading} />
            </ModalHeader>
            <ModalBody>
                <Flexbox direction="column" gap={2}>
                    <ModalTitle>
                        {t(`assignment:descriptions:accepted_dates_declined:schedule_title:${serviceType}`)}
                    </ModalTitle>
                    <ModalParagraph>
                        {t(`assignment:descriptions:accepted_dates_declined:schedule_paragraph:${serviceType}`)}
                    </ModalParagraph>
                </Flexbox>
                <Flexbox gap={4}>
                    <Controller
                        name="date"
                        control={form.control}
                        render={({field}) => (
                            <DatePicker
                                asModal
                                triggerProps={{label: t('commons:date')}}
                                minDate={adminAccessToken ? undefined : dayjs().toDate()}
                                formatter={date => dayjs(date).format('YYYY-MM-DD')}
                                mode="single"
                                numMonths={1}
                                selectedDates={field.value ? [dayjs(field.value).toDate()] : []}
                                onDatesChange={dates => field.onChange(dates[0])}
                            />
                        )}
                    />
                    <Controller
                        name="time"
                        control={form.control}
                        render={({field}) => (
                            <TimePicker
                                asModal
                                triggerProps={{label: t('commons:time')}}
                                selectedTime={field.value}
                                onSelectTime={time => field.onChange(time)}
                            />
                        )}
                    />
                </Flexbox>
                {assignment.old_date_shoot ? (
                    <StyledDates>
                        <h4>
                            {t(
                                `assignment:descriptions:accepted_dates_declined:previous_scheduled_title:${serviceType}`
                            )}
                        </h4>
                        <p>{formatDate(assignment.old_date_shoot)}</p>
                    </StyledDates>
                ) : assignment.quote.host_availability_dates.length == 2 ? (
                    <StyledDates>
                        <h4>{t('assignment:descriptions:accepted_dates_declined:host_dates_title')}</h4>
                        <ul>
                            <li>{formatDate(assignment.quote.host_availability_dates[0].starting_time)}</li>
                            <li>{formatDate(assignment.quote.host_availability_dates[1].starting_time)}</li>
                        </ul>
                    </StyledDates>
                ) : (
                    false
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} variant="tertiary" disabled={scheduleAssignmentMutation.isLoading}>
                    {t('commons:close')}
                </Button>
                <Button
                    type="submit"
                    disabled={scheduleAssignmentMutation.isLoading || !form.formState.isValid}
                    onClick={handleSubmit}
                >
                    {t('assignment:descriptions:schedule')}
                    {scheduleAssignmentMutation.isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
