import {httpSubmitSurvey} from '../../../http-requests/chatbot.http'
import {useEffect} from 'react'
import {errorHandler} from '../../../helpers/utils'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {sessionIdSelector} from '../../../store/chatbot'
import {useUserStore} from '../../../features/user/store'

const valueClassMap = {
    1: 'veryPoor',
    2: 'poor',
    3: 'average',
    4: 'good',
    5: 'excellent'
}

export const SurveySubmit = ({steps}) => {
    const {t} = useTranslation()
    const accessToken = useUserStore(store => store.accessToken)
    const sessionId = useSelector(sessionIdSelector)
    let surveyRating = steps?.s_options_rating?.value
    let surveyNotes = steps?.s_user_comment?.value ? steps?.s_user_comment?.value : 'No comment'
    let startingFlow = Object.values(steps)
        .map(step => step.id)
        .join(', ')
        .split(', ') //TODO: simplify
        .slice(1)
        .join(', ') // This now gives all the flows names, not only the starting one (and cuts off Step "1")

    useEffect(() => {
        onSendSurveyResults()
    }, [])

    const onSendSurveyResults = async () => {
        try {
            await httpSubmitSurvey(accessToken, {
                flow: startingFlow,
                notes: surveyNotes,
                rating: parseInt(surveyRating),
                thread_id: sessionId
            })
        } catch (e) {
            errorHandler(e)
        }
    }

    return (
        <div>
            <p>{t('chatbot:survey_submit:survey_submit_recap')}</p>
            <p>
                {t('chatbot:survey_submit:survey_submit_rating')}
                {valueClassMap[surveyRating]}
            </p>
            <p>
                {t('chatbot:survey_submit:survey_submit_comment')} {surveyNotes}
            </p>
        </div>
    )
}
