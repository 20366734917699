import styled, {css} from 'styled-components'
import {NavLink} from 'react-router-dom'

export const StyledMobileMenuOverlay = styled.div<{$showMobileMenu: boolean; $pageHeight: number}>`
    ${({$showMobileMenu, $pageHeight, theme: {headerHeight, zIndex}}) => css`
        position: fixed;
        z-index: ${zIndex.dropdownMenu};
        top: ${headerHeight}px;
        left: 0;
        right: 0;
        height: ${$showMobileMenu ? `calc(${$pageHeight}px - ${headerHeight}px)` : 0};
        background: ${$showMobileMenu ? 'rgba(102, 102, 102, 0.4)' : 'transparent'};
    `}
`

export const StyledMobileMenu = styled.div<{$showMobileMenu: boolean}>`
    ${({$showMobileMenu, theme: {palette, zIndex, headerHeight}}) => css`
        height: ${$showMobileMenu ? 350 : 0}px; //need to specify the height for the animation to work
        background: ${palette.neutral.white};
        position: fixed;
        top: ${headerHeight}px;
        left: 0;
        right: 0;
        z-index: ${zIndex.dropdownOverlay};
        overflow: hidden;
        transition: ease-in-out 400ms;
    `}
`

export const StyledAccountCardSection = styled.div`
    ${({theme: {spacing, colors}}) => css`
        padding: ${spacing * 4}px ${spacing * 3}px;
        border-top: 1px solid ${colors.gainsboroGrey};
    `}
`

export const StyledMenuListItem = css<{$isBold?: boolean}>`
    ${({theme: {spacing, typography, colors}, $isBold}) => css`
        padding: ${spacing * 2.5}px ${spacing * 4}px;
        width: 100%;
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
        ${typography.textSm};
        font-weight: 500;
        text-decoration: none;
        color: ${colors.lightBlack};
        border-radius: 8px;
        &:hover {
            color: ${colors.lightBlack};
            cursor: pointer;
            background: ${colors.lightGrey};
        }
        ${$isBold &&
        css`
            color: ${colors.lightBlack};
            background: ${colors.lightGrey};
        `}
    `}
`

export const StyledMenuGroup = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
    `}
`

export const NavItemsWrapper = styled.div<{$hasBorder?: boolean}>`
    ${({theme: {spacing, colors}, $hasBorder}) => css`
        padding: ${spacing}px ${spacing * 2}px;
        ${$hasBorder &&
        css`
            border-bottom: 1px solid ${colors.gainsboroGrey};
        `}
    `}
`

export const StyledAnchorListItem = styled.a<{$isBold?: boolean}>`
    ${StyledMenuListItem}
`
export const StyledDivListItem = styled.div<{$isBold?: boolean}>`
    ${StyledMenuListItem}
`
export const StyledNavListItem = styled(NavLink)<{$isBold?: boolean}>`
    ${StyledMenuListItem}
`
