import PropTypes from 'prop-types'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {Modal} from '../../../../components/modal-legacy/Modal'
import {Button} from '../../../../components/button/Button'
import {InputText} from '../../../../components/input-text/InputText'

import {useAdminUpdatePartner} from '../../../../hooks/mutations/useAdminUpdatePartner'
import {EditFreelancerModalSchema} from './EditFreelancerModal.schema'
import {showAlert} from '../../../../store/appGenerics'
import {ALERT_LEVELS} from '../../../../helpers/constants'

import {FormWrapper, ModalWrapper, StyledTitle, FooterWrapper, InputsContainer} from './style'

export const EditModal = ({onClose, defaultValues}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const onSuccessCallback = () => {
        onClose()
        dispatch(
            showAlert({
                message: t('admin:success_edit'),
                level: ALERT_LEVELS.SUCCESS
            })
        )
    }

    const {mutate, isLoading} = useAdminUpdatePartner({onSuccessCallback: onSuccessCallback})

    const {
        handleSubmit,
        formState: {errors, isDirty, isValid},
        register
    } = useForm({
        resolver: yupResolver(EditFreelancerModalSchema),
        mode: 'all',
        defaultValues: {airbnbId: defaultValues.airbnbId, newEmail: defaultValues.email}
    })

    const onSubmit = formValues => {
        const data = {
            airbnb_id: formValues.airbnbId,
            email: formValues.newEmail
        }
        mutate({id: defaultValues.id, dataToChange: data})
    }

    return (
        <Modal onClose={onClose} isLoading={isLoading} width="432px">
            <ModalWrapper>
                <StyledTitle>{t('admin:edit_partner')}</StyledTitle>
                <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                    <InputsContainer>
                        <InputText
                            type="text"
                            label={t('commons:airbnb_id')}
                            errorMessage={errors.airbnbId?.message}
                            {...register('airbnbId')}
                        />
                        <InputText
                            type="text"
                            label={t('commons:email')}
                            errorMessage={errors.newEmail?.message}
                            {...register('newEmail')}
                        />
                    </InputsContainer>
                    <FooterWrapper>
                        <Button onClick={onClose} variant="tertiary" disabled={isLoading}>
                            {t('commons:cancel')}
                        </Button>
                        <Button type="submit" disabled={isLoading || !isDirty || !isValid}>
                            {t('commons:submit')}
                        </Button>
                    </FooterWrapper>
                </FormWrapper>
            </ModalWrapper>
        </Modal>
    )
}

EditModal.propTypes = {
    onClose: PropTypes.func,
    defaultValues: PropTypes.shape({
        airbnbId: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number
    })
}
