import {forwardRef} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import {Controller} from 'react-hook-form'
import * as PropTypes from 'prop-types'
import {formatLocaleDate} from '../../helpers/utils'
import {
    StyledDateTimeInput,
    StyledCalendarIcon,
    StyledClockIcon,
    StyledErrors,
    StyledInputWrapper,
    StyledNativeInput,
    StyledLabel
} from './style'

export const DateTimeInput = forwardRef(function DateTimeInput(
    {
        children,
        label,
        name,
        control,
        errors,
        hasIcon = true,
        isClearable = true,
        placeholder,
        closeOnScroll = false,
        calendarPlacement = 'bottom',
        dateFormat = 'yyyy-MM-dd',
        showTimeSelect = true,
        showTimeSelectOnly,
        showNativeInput,
        isRange,
        startDate,
        endDate,
        includeDates,
        includeDateIntervals,
        includeTimes,
        excludeDates,
        excludeDateIntervals,
        excludeTimes,
        minDate,
        maxDate,
        disabled,
        closeOnSelect,
        openToDate,
        onChangeCb,
        inputValue,
        className,
        ...rest
    },
    ref
) {
    const renderInput = (onChange, value) => {
        return (
            <StyledDateTimeInput className={className}>
                {showNativeInput ? (
                    <StyledNativeInput>
                        <label>
                            <span>{placeholder}</span>
                        </label>
                        <input
                            type={showTimeSelectOnly ? 'time' : showTimeSelect ? 'datetime-local' : 'date'}
                            onChange={onChange}
                            value={value}
                            placeholder="placeholder"
                            min={formatLocaleDate(minDate)}
                            max={formatLocaleDate(maxDate)}
                            {...rest}
                        />
                    </StyledNativeInput>
                ) : (
                    <>
                        {!!label && <StyledLabel>{label}</StyledLabel>}
                        <StyledInputWrapper hasIcon={hasIcon}>
                            {hasIcon && showTimeSelectOnly ? (
                                <StyledClockIcon />
                            ) : hasIcon ? (
                                <StyledCalendarIcon />
                            ) : (
                                <></>
                            )}
                            <DatePicker
                                onChange={onChange}
                                selected={value}
                                isClearable={isClearable}
                                placeholderText={placeholder}
                                closeOnScroll={closeOnScroll}
                                popperPlacement={calendarPlacement}
                                dateFormat={dateFormat}
                                showTimeSelect={showTimeSelect}
                                showTimeSelectOnly={showTimeSelectOnly}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange={isRange}
                                includeDates={includeDates}
                                includeDateIntervals={includeDateIntervals}
                                includeTimes={includeTimes}
                                excludeDates={excludeDates}
                                excludeDateIntervals={excludeDateIntervals}
                                excludeTimes={excludeTimes}
                                minDate={minDate}
                                maxDate={maxDate}
                                disabled={disabled}
                                shouldCloseOnSelect={closeOnSelect}
                                openToDate={openToDate}
                                ref={ref}
                                {...rest}
                            >
                                {children}
                            </DatePicker>
                        </StyledInputWrapper>
                    </>
                )}
                {!!errors && <StyledErrors>{errors.message}</StyledErrors>}
            </StyledDateTimeInput>
        )
    }

    return control ? (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}}) => renderInput(onChange, value)}
        />
    ) : (
        renderInput(onChangeCb, inputValue)
    )
})

DateTimeInput.propTypes = {
    name: PropTypes.string,
    control: PropTypes.any,
    isClearable: PropTypes.bool,
    placeholder: PropTypes.string,
    closeOnScroll: PropTypes.bool,
    calendarPlacement: PropTypes.string,
    dateFormat: PropTypes.string,
    showTimeSelect: PropTypes.bool,
    showTimeSelectOnly: PropTypes.bool,
    showNativeInput: PropTypes.bool,
    isRange: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    includeDates: PropTypes.array,
    includeDateIntervals: PropTypes.array,
    includeTimes: PropTypes.array,
    excludeDates: PropTypes.array,
    excludeDateIntervals: PropTypes.array,
    excludeTimes: PropTypes.array,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    openToDate: PropTypes.string,
    onChangeCb: PropTypes.func,
    inputValue: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string
}
