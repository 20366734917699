import i18next from 'i18next'

export const getAssignmentColumns = () => [
    {
        name: 'date',
        label: i18next.t('commons:date'),
        width: '30%'
    },
    {
        name: 'status',
        label: i18next.t('commons:status'),
        width: '25%'
    },
    {
        name: 'details',
        label: i18next.t('commons:details'),
        width: '40%'
    }
]

export const getAssignmentProductionColumns = () => [
    {
        name: 'date',
        label: i18next.t('commons:date'),
        width: '25%'
    },
    {
        name: 'status',
        label: i18next.t('commons:status'),
        width: '30%'
    },
    {
        name: 'freelancer',
        label: i18next.t('commons:freelancer'),
        width: '25%'
    },
    {
        name: 'actions',
        label: '',
        alignment: 'right',
        width: '20%'
    }
]

export const getPaymentColumns = () => [
    {
        name: 'creation_date',
        label: i18next.t('admin:assignments:payments:creation_date'),
        width: '15%'
    },
    {
        name: 'payment_date',
        label: i18next.t('admin:assignments:payments:payment_date'),
        width: '15%'
    },
    {
        name: 'type',
        label: i18next.t('commons:type'),
        width: '26%'
    },
    {
        name: 'freelancer',
        label: i18next.t('commons:freelancer'),
        width: '14%'
    },
    {
        name: 'host',
        label: i18next.t('commons:host'),
        width: '14%'
    },
    {
        name: 'payin',
        label: i18next.t('admin:payin'),
        alignment: 'right',
        width: '8%'
    },
    {
        name: 'payout',
        label: i18next.t('admin:payout'),
        alignment: 'right',
        width: '8%'
    }
]
