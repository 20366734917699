import {FC, HTMLAttributes} from 'react'

export const ArrowRightIcon: FC<{size?: number; fill?: string} & HTMLAttributes<SVGSVGElement>> = ({
    size = 20,
    fill = 'currentColor',
    ...props
}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5893 3.57741C10.2639 3.25197 9.73622 3.25197 9.41079 3.57741C9.08535 3.90285 9.08535 4.43048 9.41079 4.75592L13.8215 9.16667H4.16671C3.70647 9.16667 3.33337 9.53976 3.33337 10C3.33337 10.4602 3.70647 10.8333 4.16671 10.8333H13.8215L9.41079 15.2441C9.08535 15.5695 9.08535 16.0972 9.41079 16.4226C9.73622 16.748 10.2639 16.748 10.5893 16.4226L16.422 10.5898C16.424 10.5878 16.4261 10.5858 16.428 10.5838C16.5751 10.434 16.666 10.2289 16.6667 10.0025C16.6667 10.0017 16.6667 10.0008 16.6667 10C16.6667 9.99917 16.6667 9.99833 16.6667 9.9975C16.6664 9.88542 16.6439 9.77854 16.6035 9.68101C16.5637 9.58496 16.5053 9.49483 16.428 9.41621C16.4261 9.41421 16.4241 9.41221 16.4221 9.41023M10.5893 3.57741L16.4221 9.41023L10.5893 3.57741Z"
            fill={fill}
        />
    </svg>
)
