import styled, {css} from 'styled-components'
import {ToggleSwitch} from '../../../../../../components/toggle-switch/ToggleSwitch'

export const StyledToggleSwitch = styled(ToggleSwitch)`
    align-self: flex-end;
`

export const StyledCheckCard = styled.div`
    ${({theme: {spacing, colors, mediaQuery}, hasSubmitError}) => css`
        width: 277px;
        background: ${hasSubmitError ? colors.alphaRed : colors.whiteSmoke};
        padding: ${spacing * 2}px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: ${spacing * 2}px;
        border-radius: 8px;
        border: ${hasSubmitError ? `1px solid ${colors.red}` : `1px solid ${colors.inputGrey}`};
        ${mediaQuery.XL} {
            width: 232px;
        }
    `}
`

export const StyledImageWrapper = styled.div`
    ${({theme: {spacing, typography}}) => css`
        width: 100%;
        & > span {
            width: 100%;
            & > img {
                object-position: center;
                object-fit: cover;
                width: 100%;
                aspect-ratio: 1.5/1;
                margin-bottom: ${spacing * 2}px;
                border-radius: 4px;

                @supports not (aspect-ratio: 16/9) {
                    height: 150px;
                }
            }
        }

        & > p {
            height: 42px;
            ${typography.textSm};
            font-weight: 500;
            word-break: break-all;
            overflow: hidden;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            box-orient: vertical;
            -moz-box-orient: vertical;
            -webkit-box-orient: vertical;
            display: flex;
        }
    `}
`
