import {useTranslation} from 'react-i18next'
import {StyledBody, StyledOption} from './style'
import {Modal} from 'src/components/modal-legacy/Modal'
import {ReactNode} from 'react'

export const SorterModal = <T extends {label: ReactNode; value: string}>({
    onClose,
    options,
    sortColumnCb
}: {
    onClose: () => void
    options: T[]
    sortColumnCb: (option: T['value'], orderDirection: 'asc' | 'desc') => void
}) => {
    const {t} = useTranslation()

    const onSetSorter = (option: T) => {
        sortColumnCb(option.value, 'desc')
        onClose()
    }

    return (
        <Modal onClose={onClose} title={t('commons:order_by')} hasCloseIcon width="400px">
            <StyledBody>
                {options.map((option, index) => (
                    <StyledOption key={index} onClick={() => onSetSorter(option)}>
                        {option.label}
                    </StyledOption>
                ))}
            </StyledBody>
        </Modal>
    )
}
