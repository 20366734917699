import {CSSProperties, FC, ReactNode} from 'react'
import {StyledModal, StyledOverlay} from './style.ts'
import {createPortal} from 'react-dom'

interface ModalProps {
    children: ReactNode
    className?: string
    onOverlayClick?: () => void
    width?: CSSProperties['width']
}

export const Modal: FC<ModalProps> = ({children, className, onOverlayClick, width}) => {
    return createPortal(
        <>
            <StyledOverlay onClick={onOverlayClick} />
            <StyledModal width={width} className={className}>
                {children}
            </StyledModal>
        </>,
        document.body
    )
}

Modal.displayName = 'Modal'
