import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {Trans, useTranslation} from 'react-i18next'
import {UploadButtons} from '../../upload-buttons/UploadButtons'
import {StyledAssignmentDescriptionParagraph} from './style'
import {AlertTriangleIcon} from 'src/components/icon'
import {WalkthroughFormButton} from 'src/features/assignment/components/walkthrough-form-button/WalkthroughFormButton.tsx'
import {AssignmentVideoButtons} from '../../assignment-video-buttons/AssignmentVideoButtons'

const rejectedReasonsToTranslationKey = {
    empty_folder: 'assignment:descriptions:needs_more_assets:rejected_reasons:empty_folder',
    empty_form: 'assignment:descriptions:needs_more_assets:rejected_reasons:empty_form',
    exp_empty_folder: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_empty_folder',
    exp_individual_assets: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_individual_assets',
    exp_insufficient_assets: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_insufficient_assets',
    exp_low_quality: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_low_quality',
    exp_too_much_assets: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_too_much_assets',
    exp_too_shaky: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_too_shaky',
    exp_wrong_folder: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_wrong_folder',
    exp_wrong_ratio: 'assignment:descriptions:needs_more_assets:rejected_reasons:exp_wrong_ratio',
    manual_explanation: 'assignment:descriptions:needs_more_assets:rejected_reasons:manual_explanation',
    missing_assets: 'assignment:descriptions:needs_more_assets:rejected_reasons:missing_assets',
    not_enough_photos_form: 'assignment:descriptions:needs_more_assets:rejected_reasons:not_enough_photos_form',
    unmerged_brackets: 'assignment:descriptions:needs_more_assets:rejected_reasons:unmerged_brackets',
    wrong_assets: 'assignment:descriptions:needs_more_assets:rejected_reasons:wrong_assets',
    wrong_file_format: 'assignment:descriptions:needs_more_assets:rejected_reasons:wrong_file_format',
    wrong_listing_form: 'assignment:descriptions:needs_more_assets:rejected_reasons:wrong_listing_form'
} as const satisfies Record<NonNullable<Assignment['assets_rejected_reason']>, string>

export const NeedsMoreAssetsDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {t(`assignment:descriptions:needs_more_assets:title:${serviceType}`)}
                </AssignmentDescriptionTitle>
                {assignment.assets_rejected_reason && (
                    <StyledAssignmentDescriptionParagraph>
                        <AlertTriangleIcon size={20} />
                        <span>{t(rejectedReasonsToTranslationKey[assignment.assets_rejected_reason])}</span>
                    </StyledAssignmentDescriptionParagraph>
                )}
                {assignment.frame_final_folder_review_link && (
                    <AssignmentDescriptionParagraph>
                        <Trans
                            i18nKey="statuses:needs_more_assets_link"
                            components={[
                                <a href={assignment.frame_final_folder_review_link} target="_blank" rel="noreferrer" />
                            ]}
                        />
                    </AssignmentDescriptionParagraph>
                )}
            </AssignmentDescriptionText>

            {assignment.task_media.name == 'walkthrough' ? (
                <WalkthroughFormButton assignment={assignment} />
            ) : assignment.task_category.name == 'Experiences' && assignment.task_media.name == 'video' ? (
                <AssignmentVideoButtons assignment={assignment} />
            ) : (
                <UploadButtons assignment={assignment} />
            )}
        </AssignmentDescriptionWrapper>
    )
}
