import {CHATBOT_SOURCES, TASK_STATUSES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory, setCancellationReason, setShoot} from '../../../store/chatbot'
import {PullShoots} from '../custom-components/pull-shoots/PullShoots'
import i18next from 'i18next'
import {SimpleLink} from '../custom-components/simple-link/SimpleLink'

export const rootFlowStepsHome = name => [
    {
        id: '1',
        message: i18next.t('chatbot:root_home:starting', {name}),
        trigger: () => {
            store.dispatch(setShoot(null))
            store.dispatch(setCancellationReason({text: null, id: null}))
            store.dispatch(
                addStepToChatHistory({
                    id: '1',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `Hi ${name}, you are now talking with our virtual assistant. What can I help you with today?`
                })
            )
            return 'start_generic'
        }
    },
    {
        id: 'start_generic',
        options: [
            {
                value: 'MB0',
                label: i18next.t('chatbot:root_home:start_generic'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'start_generic',
                            source: CHATBOT_SOURCES.USER,
                            text: `🏡 Host wants me to photograph another listing`
                        })
                    )
                    return 'MB0'
                }
            },
            {
                value: 'coordination',
                label: i18next.t('chatbot:root_home:start_generic_coordination'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'start_generic',
                            source: CHATBOT_SOURCES.USER,
                            text: `🤝 Coordination with the Host`
                        })
                    )
                    return 'generic_coordination'
                }
            },
            {
                value: 'rate_questions',
                label: i18next.t('chatbot:root_home:start_generic_rate_questions'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'start_generic',
                            source: CHATBOT_SOURCES.USER,
                            text: `💶 Questions about the rates`
                        })
                    )

                    return 'generic_rate'
                }
            },
            {
                value: 'cancel_shoot',
                label: i18next.t('chatbot:commons:want_cancel_photoshoot'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'start_generic',
                            source: CHATBOT_SOURCES.USER,
                            text: `🚫 I want to cancel the photoshoot`
                        })
                    )
                    return 'MB1.4'
                }
            },
            {
                value: 'payment',
                label: i18next.t('chatbot:root_home:start_generic_payment_questions'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'start_generic',
                            source: CHATBOT_SOURCES.USER,
                            text: `💸 Questions about payment`
                        })
                    )
                    return 'generic_payment'
                }
            },
            {
                value: 'generic_ticket',
                label: i18next.t('chatbot:root_home:start_generic_topic_not_listed'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'start_generic',
                            source: CHATBOT_SOURCES.USER,
                            text: `🧭 My topic is not listed`
                        })
                    )
                    return 'generic_ticket_pull'
                }
            }
        ]
    },
    {
        id: 'walkthrough_support',
        component: (
            <SimpleLink
                text={i18next.t('chatbot:root_shoot:walkthrough_contact_support')}
                link="mailto:luxehosts@airbnb.com"
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'walkthrough_support',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] airbnb Luxe support'
                })
            )
            return 'walkthrough_next'
        }
    },
    {
        id: 'walkthrough_next',
        component: <></>,
        asMessage: false,
        trigger: 'send_log'
    },
    {
        id: 'generic_coordination',
        options: [
            {
                value: 'MB1.2',
                label: i18next.t('chatbot:root_home:generic_coordination'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: `Host wants to schedule in the far future`
                        })
                    )
                    return 'MB1.2'
                }
            },
            {
                value: 'MB1.3',
                label: i18next.t('chatbot:root_home:mb1.3'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: `Host is unresponsive, what do I do?`
                        })
                    )
                    return 'MB1.3'
                }
            },
            {
                value: 'MB1.5',
                label: i18next.t('chatbot:root_home:mb1.5'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host shared shoot dates'
                        })
                    )
                    return 'MB1.5'
                }
            },
            {
                value: 'MB1.6',
                label: i18next.t('chatbot:root_home:mb1.6'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host did not shared shoot dates'
                        })
                    )
                    return 'MB1.6'
                }
            },
            {
                value: 'MB1.7',
                label: i18next.t('chatbot:root_home:mb1.7'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host is not ready to schedule. What do I do?'
                        })
                    )
                    return 'MB1.7'
                }
            },
            {
                value: 'generic_ticket',
                label: i18next.t('chatbot:root_home:generic_other_coordination'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: `Other coordination question`
                        })
                    )
                    return 'MB4'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:commons:previous_options'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_coordination',
                            source: CHATBOT_SOURCES.USER,
                            text: `⬅️ Previous options`
                        })
                    )
                    return 'start_generic'
                }
            }
        ]
    },
    {
        id: 'generic_rate',
        options: [
            {
                value: 'MB1.1',
                label: i18next.t('chatbot:root_home:mb1.1'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_rate',
                            source: CHATBOT_SOURCES.USER,
                            text: `The number of bedrooms in my assignment is wrong`
                        })
                    )
                    return 'MB1.1'
                }
            },
            {
                value: 'generic_ticket',
                label: i18next.t('chatbot:root_home:generic_ticket'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_rate',
                            source: CHATBOT_SOURCES.USER,
                            text: `I have another question related to the rate`
                        })
                    )
                    return 'MB4'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:commons:previous_options'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_rate',
                            source: CHATBOT_SOURCES.USER,
                            text: `⬅️ Previous options`
                        })
                    )
                    return 'start_generic'
                }
            }
        ]
    },
    {
        id: 'generic_payment',
        options: [
            {
                value: 'MB2.1',
                label: i18next.t('chatbot:root_home:mb2.1'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_payment',
                            source: CHATBOT_SOURCES.USER,
                            text: `How do I get the payment?`
                        })
                    )
                    return 'MB2.1'
                }
            },
            {
                value: 'MB2.2',
                label: i18next.t('chatbot:root_home:mb2.2'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_payment',
                            source: CHATBOT_SOURCES.USER,
                            text: `I want to check if my payment has been issued`
                        })
                    )
                    return 'MB2.2'
                }
            },
            {
                value: 'MB2.3',
                label: i18next.t('chatbot:root_home:mb2.3'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_payment',
                            source: CHATBOT_SOURCES.USER,
                            text: `Host canceled or rescheduled within 24 hours prior to the shoot`
                        })
                    )
                    return 'MB2.3'
                }
            },
            {
                value: 'generic_ticket',
                label: i18next.t('chatbot:root_home:another_payment_question'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_payment',
                            source: CHATBOT_SOURCES.USER,
                            text: `I have another payment related question`
                        })
                    )
                    return 'MB4'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:commons:previous_options'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'generic_payment',
                            source: CHATBOT_SOURCES.USER,
                            text: `⬅️ Previous options`
                        })
                    )
                    return 'start_generic'
                }
            }
        ]
    },

    {
        id: 'generic_ticket',
        message: i18next.t('chatbot:commons:which_shoot'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'generic_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `Which shoot are you referring to?`
                })
            )
            return 'generic_ticket_pull'
        }
    },
    {
        id: 'generic_ticket_pull',
        component: <PullShoots flowSource="root" statuses={Object.values(TASK_STATUSES)} filterByDate="0" /> // Trigger managed by the component
    }
]
