import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers'
import {MUTATION_KEYS, QUERY_KEYS, queryClient} from 'src/queryClient'
import {
    httpUpdateHostApprovalTaskImages,
    HttpUpdateHostApprovalTaskImagesOptions
} from 'src/features/admin-host-approval/services/adminHostApproval.http'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'

export const useUpdateHostApprovalTaskImage = ({
    taskId,
    imageId,
    options
}: {
    taskId: number
    imageId: number
    options?: UseMutationOptions<unknown, unknown, HttpUpdateHostApprovalTaskImagesOptions['payload']>
}) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is null')
    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.HOST_APPROVAL_TASK_IMAGES_UPDATE],
        mutationFn: (payload: HttpUpdateHostApprovalTaskImagesOptions['payload']) =>
            httpUpdateHostApprovalTaskImages({
                urlParams: {taskId, imageId},
                payload,
                headers: makeHttpAuthorizationHeader(adminAccessToken)
            }),
        onMutate: async vars => {
            const queryKey = [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK_IMAGES, taskId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousImages = queryClient.getQueryData<AdminHostApprovalTaskImage[]>(queryKey)

            // Update cached data
            queryClient.setQueriesData({queryKey}, (currentImages: AdminHostApprovalTaskImage[] | undefined) => {
                if (!currentImages) return currentImages
                return currentImages.map(currentImage =>
                    currentImage.task_id == taskId && currentImage.id == imageId
                        ? {
                              ...currentImage,
                              room_id: vars.room_id ?? currentImage.room_id,
                              amenity_id: vars.amenity_id ?? currentImage.amenity_id,
                              is_uploaded: vars.is_uploaded ?? currentImage.is_uploaded,
                              from_photo_gallery:
                                  vars.from_photo_gallery == 1 || vars.from_photo_gallery == 0
                                      ? !!vars.from_photo_gallery
                                      : currentImage.from_photo_gallery
                          }
                        : currentImage
                )
            })

            return {previousImages}
        },
        onError: (error, variables, context) => {
            queryClient.setQueriesData(
                {queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK_IMAGES, taskId]},
                context?.previousImages
            )
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
