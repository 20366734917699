import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {parseAxiosPromise} from 'src/helpers/helpers.ts'
import {z} from 'zod'
import {captureException} from '@sentry/react'
import {Room} from 'src/features/admin-host-approval/types.ts'
import {httpGetAdminHostApprovalTaskRooms} from 'src/features/admin-host-approval/services/adminHostApproval.http'

export const useAdminHostApprovalTaskRooms = (options?: UseQueryOptions<Room[]>) =>
    useQuery({
        queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASK_ROOMS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAdminHostApprovalTaskRooms(),
                responseShape: z.array(Room),
                onZodError: captureException
            }),
        ...options
    })
