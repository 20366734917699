import {createSelector, createSlice} from '@reduxjs/toolkit'
import {MATTERPORT_STATUSES} from '../helpers/constants'

const matterport = createSlice({
    name: 'matterport',
    initialState: {
        autocompletes: {
            users: [],
            amenities: [],
            rooms: []
        },
        currentTask: {
            task: {},
            roomsList: [],
            imagesList: [],
            toDeleteList: [],
            toUploadList: [],
            selectedAmenitiesCounter: [],
            imagesFromDropzone: {},
            isEligible: true
        },
        tablesCounter: {
            [MATTERPORT_STATUSES.UPLOAD_MEDIABOX]: 0,
            [MATTERPORT_STATUSES.READY_QA]: 0,
            [MATTERPORT_STATUSES.PENDING_APPROVAL]: 0,
            [MATTERPORT_STATUSES.REVISION]: 0,
            [MATTERPORT_STATUSES.READY_PUBLISH]: 0,
            [MATTERPORT_STATUSES.COMPLETED]: 0
        }
    },

    reducers: {
        setUsersAutocomplete: (state, action) => {
            const users = action.payload

            state.autocompletes.users = users.map(user => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id
            }))
        },
        setAmenitiesAutocomplete: (state, action) => {
            const amenities = action.payload
            const toShowInCounterIds = [1, 4, 5, 7, 10, 12, 14, 16, 17, 18]
            const imagesWithSelectedAmenityIds = state.currentTask.imagesList?.filter(image => !!image.selectedAmenity)
            const amenitiesRemapped = amenities.map(amenity => ({
                roomId: amenity.room_id,
                label: amenity.internal_key,
                value: amenity.id
            }))

            state.autocompletes.amenities = amenitiesRemapped
            state.currentTask.selectedAmenitiesCounter = amenitiesRemapped
                .filter(amenity => toShowInCounterIds.includes(amenity.value))
                .map(amenity => ({
                    id: amenity.value,
                    key: amenity.label,
                    linkedImagesIds: imagesWithSelectedAmenityIds
                        ?.filter(image => image.selectedAmenity === amenity.value)
                        ?.map(image => image.id)
                }))
        },
        setRoomsAutocomplete: (state, action) => {
            state.autocompletes.rooms = action.payload
        },
        setCurrentTask: (state, action) => {
            const data = action.payload

            const roomsList = data?.entity_bedroom?.map(room => ({
                label: room?.name,
                value: room?.id,
                roomId: room?.room_id,
                number: room?.number,
                url: room?.images?.[0]?.url
            }))

            const imagesList = data?.accessibility_images?.map(image => ({
                id: image?.id,
                name: image?.name,
                url: image?.url,
                isOld: !!image?.old,
                selectedRoom: image?.entity_bedroom_id,
                selectedAmenity: image?.amenity_id,
                hostAction: image?.host_action,
                doNotUse: false,
                checked: false
            }))

            state.currentTask = {
                task: data,
                roomsList: roomsList || [],
                imagesList: imagesList || [],
                toDeleteList: imagesList?.filter(image => image?.hostAction === 'removed'),
                toUploadList: imagesList?.filter(image => image?.hostAction !== 'removed'),
                selectedAmenitiesCounter: [],
                imagesFromDropzone: state.currentTask.imagesFromDropzone,
                isEligible: !data?.exclude_for_category
            }
        },
        resetCurrentTask: state => {
            state.currentTask = {
                task: {},
                roomsList: [],
                imagesList: [],
                toDeleteList: [],
                toUploadList: [],
                selectedAmenitiesCounter: [],
                imagesFromDropzone: {},
                isEligible: true
            }
        },
        addAccessibilityImages: (state, action) => {
            const imagesToUpload = action.payload
            const currentAccessibilityImages = state.currentTask.task.accessibility_images
            const currentImagesList = state.currentTask.imagesList

            state.currentTask.task.accessibility_images = [...currentAccessibilityImages, ...imagesToUpload]
            state.currentTask.imagesList = [...currentImagesList, ...imagesToUpload]
        },
        removeRestoreAccessibilityImages: (state, action) => {
            const type = action.payload.type
            const imageToRemoveId = action.payload.imageId
            const currentAccessibilityImages = state.currentTask.task.accessibility_images

            state.currentTask.task.accessibility_images = currentAccessibilityImages.map(image =>
                image.id === imageToRemoveId ? {...image, deleted_on_upload: type === 'delete'} : image
            )
        },
        toggleImageUse: (state, action) => {
            const {imageId, isChecked, isOld} = action.payload
            const prevImagesList = state.currentTask.imagesList
            const prevSelectedAmenitiesCounter = state.currentTask.selectedAmenitiesCounter

            const newImagesList = prevImagesList.map(image =>
                image.id === imageId
                    ? {
                          ...image,
                          doNotUse: isChecked,
                          ...(!isOld && {
                              selectedAmenity: null,
                              selectedRoom: null
                          })
                      }
                    : {...image}
            )

            if (!isOld && isChecked && prevImagesList.some(image => !!image.selectedAmenity)) {
                state.currentTask.selectedAmenitiesCounter = prevSelectedAmenitiesCounter.map(amenity =>
                    amenity.linkedImagesIds.includes(imageId)
                        ? {
                              ...amenity,
                              linkedImagesIds: amenity.linkedImagesIds.filter(id => id !== imageId)
                          }
                        : {...amenity}
                )
            }

            state.currentTask.imagesList = newImagesList
        },
        selectImageRoom: (state, action) => {
            const {imageId, selectedRoomId} = action.payload
            const prevImagesList = state.currentTask.imagesList

            state.currentTask.imagesList = prevImagesList.map(image =>
                image.id === imageId ? {...image, selectedRoom: selectedRoomId} : {...image}
            )
        },
        selectImageAmenity: (state, action) => {
            const {imageId, selectedAmenityId} = action.payload
            const prevImagesList = state.currentTask.imagesList
            const prevSelectedAmenitiesCounter = state.currentTask.selectedAmenitiesCounter

            state.currentTask.imagesList = prevImagesList.map(image =>
                image.id === imageId ? {...image, selectedAmenity: selectedAmenityId} : {...image}
            )

            state.currentTask.selectedAmenitiesCounter = prevSelectedAmenitiesCounter.map(amenity =>
                amenity.linkedImagesIds.includes(imageId)
                    ? {
                          ...amenity,
                          linkedImagesIds: amenity.linkedImagesIds.filter(id => id !== imageId)
                      }
                    : selectedAmenityId === amenity.id
                    ? {
                          ...amenity,
                          linkedImagesIds: [...amenity.linkedImagesIds, imageId]
                      }
                    : {...amenity}
            )
        },
        checkConfirmImage: (state, action) => {
            const {imageId, isDeleteList, isChecked} = action.payload
            const prevToDeleteList = state.currentTask.toDeleteList
            const prevToUploadList = state.currentTask.toUploadList

            if (isDeleteList) {
                state.currentTask.toDeleteList = prevToDeleteList.map(image =>
                    image.id === imageId ? {...image, checked: isChecked} : {...image}
                )
            } else {
                state.currentTask.toUploadList = prevToUploadList.map(image =>
                    image.id === imageId ? {...image, checked: isChecked} : {...image}
                )
            }
        },
        setEligibility: (state, action) => {
            state.currentTask.isEligible = action.payload
        },
        setTablesCounter: (state, action) => {
            state.tablesCounter = action.payload || {}
        },
        changeSingleTableCounter: (state, action) => {
            const {status, count} = action.payload

            state.tablesCounter[status] = count
        }
    }
})

export const matterportReducer = matterport.reducer

const selectSelf = state => state.matterport
export const usersAutocompleteSelector = createSelector(selectSelf, state => state.autocompletes.users)
export const amenitiesAutocompleteSelector = createSelector(selectSelf, state => state.autocompletes.amenities)
export const roomsAutocompleteSelector = createSelector(selectSelf, state => state.autocompletes.rooms)
export const taskSelector = createSelector(selectSelf, state => state.currentTask.task)
export const roomsListSelector = createSelector(selectSelf, state => state.currentTask.roomsList)
export const imagesListSelector = createSelector(selectSelf, state => state.currentTask.imagesList)
export const toDeleteListSelector = createSelector(selectSelf, state => state.currentTask.toDeleteList)
export const toUploadListSelector = createSelector(selectSelf, state => state.currentTask.toUploadList)
export const tablesCounterSelector = createSelector(selectSelf, state => state.tablesCounter)
export const isEligibleSelector = createSelector(selectSelf, state => state.currentTask.isEligible)
export const selectedAmenitiesCounterSelector = createSelector(
    selectSelf,
    state => state.currentTask.selectedAmenitiesCounter
)

export const {
    setUsersAutocomplete,
    setAmenitiesAutocomplete,
    setRoomsAutocomplete,
    setCurrentTask,
    resetCurrentTask,
    addAccessibilityImages,
    removeRestoreAccessibilityImages,
    toggleImageUse,
    selectImageRoom,
    selectImageAmenity,
    checkConfirmImage,
    setEligibility,
    setTablesCounter,
    changeSingleTableCounter
} = matterport.actions
