import styled, {css} from 'styled-components'

export const StyledTransactionCell = styled.span`
    ${({theme: {spacing, colors}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        color: ${colors.darkGrey};
        padding: ${spacing}px ${spacing * 2}px;
    `}
`

export const StyledAccordionButton = styled.button(
    ({theme: {transition}}) => css`
        all: unset;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        ${transition};
        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }
    `
)
