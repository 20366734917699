import {PageHelmet} from 'src/components/page-helmet/PageHelmet.tsx'
import {useTranslation} from 'react-i18next'
import {PageNotFoundWrapper, StyledMessage, StyledTitle} from './style'

export const PageNotFound = () => {
    const {t} = useTranslation()

    return (
        <PageNotFoundWrapper id="page-not-found" className="container" $pageHeight={window.innerHeight}>
            <PageHelmet title={t('titles:page_not_found')} />
            <div className="row">
                <div className="col-12">
                    <StyledTitle>404</StyledTitle>
                    <StyledMessage>{t('page_not_found:page_not_found')}</StyledMessage>
                </div>
            </div>
        </PageNotFoundWrapper>
    )
}
