import axios from 'axios'
import {RESPONSE_TYPES} from '../helpers/constants'

export const httpCancelShoot = (accessToken, id, values) => {
    return axios.post(`/assignment/${id}/cancel`, values, {
        params: {response_type: RESPONSE_TYPES.EXTENDED},
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpGetAcceptShooting = (id, accessToken) => {
    const URL = `/assignment/${id}/accept`
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.get(URL, {headers})
}

export const httpGetUploadMediaLink = (accessToken, assignmentId, payload) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.post(`/assignments/${assignmentId}/media/upload`, payload, {headers})
}

export const httpUploadMedia = (accessToken, url, payload, onUploadProgress) => {
    return axios.put(url, payload, {onUploadProgress})
}

export const httpConfirmUploadMedia = (accessToken, assignmentId, mediaId) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.patch(`/assignments/${assignmentId}/media/${mediaId}/upload-completed`, null, {headers})
}

export const httpConfirmUploadImage = (accessToken, assignmentId, mediaId) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.patch(`/assignments/${assignmentId}/image/${mediaId}/upload-completed`, null, {headers})
}

export const httpDeleteMedia = (accessToken, assignmentId, mediaId) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.delete(`/assignments/${assignmentId}/media/${mediaId}`, {headers})
}

export const httpDeleteImage = (accessToken, assignmentId, mediaId) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.delete(`/assignments/${assignmentId}/image/${mediaId}`, {headers})
}

export const httpGetUploadedMedia = (accessToken, assignmentId) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.get(`/assignments/${assignmentId}/media/uploaded`, {headers})
}

export const httpGetUploadImagesLinks = (accessToken, assignmentId, payload) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.post(`/assignments/${assignmentId}/image/bulk/upload`, payload, {headers})
}

export const httpGetUploadedImages = (accessToken, assignmentId) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.get(`/assignments/${assignmentId}/image`, {headers})
}

export const httpBulkDeleteImages = (accessToken, assignmentId, ids) => {
    const headers = {Authorization: `Bearer ${accessToken}`}
    return axios.delete(`/assignments/${assignmentId}/image`, {
        headers,
        params: {...ids}
    })
}
