import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_ACTION_KEYS, QUERY_KEYS} from 'src/queryClient.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'
import {Assignment} from 'src/features/assignment/types.ts'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'
import {
    httpAssignmentCoordination,
    HttpAssignmentCoordinationOptions
} from 'src/features/assignment/services/assignment.http.ts'

export const useAssignmentCoordination = ({
    assignmentId,
    taskId,
    options
}: {
    assignmentId: number
    taskId: number
    options?: UseMutationOptions<Assignment, unknown, HttpAssignmentCoordinationOptions['payload']>
}) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.ASSIGNMENT_BOH],
        mutationFn: (values: HttpAssignmentCoordinationOptions['payload']) =>
            parseAxiosPromise({
                axiosPromise: httpAssignmentCoordination({
                    payload: values,
                    headers: makeHttpAuthorizationHeader(accessToken),
                    urlParams: {assignmentId, taskId},
                    params: {response_type: 'extended'}
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            invalidationSignal(QUERY_ACTION_KEYS.ASSIGNMENT_CHANGE_COORDINATION_STATUS)
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.ASSIGNMENT, data.code]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
