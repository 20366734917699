import {capitalize, formatLocaleDate, formatTransactionAmount} from '../../../../../../../helpers/utils'
import {
    StyledTransactionAmount,
    StyledTransactionContainer,
    StyledTransactionTitle,
    TransactionsListWrapper
} from './style'
import PropTypes from 'prop-types'

export const PAYMENT_AMOUNT_TYPES = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    NEUTRAL: 'neutral'
}

export const TransactionsList = ({payments}) => {
    return (
        <TransactionsListWrapper>
            {payments?.map(transaction => {
                return (
                    <StyledTransactionContainer key={transaction.id}>
                        <StyledTransactionTitle>
                            <h3>{capitalize(transaction.payment_type)}</h3>
                            <span>{formatLocaleDate(transaction.payment_date, 'MMM D, YYYY')}</span>
                        </StyledTransactionTitle>
                        <div className="amount">
                            {!!transaction.payout_amount && (
                                <StyledTransactionAmount $type={PAYMENT_AMOUNT_TYPES.POSITIVE}>
                                    {formatTransactionAmount(
                                        transaction.payout_photographer_currency_amount,
                                        transaction.payout_amount,
                                        transaction.payout_photographer_currency,
                                        '+'
                                    )}
                                </StyledTransactionAmount>
                            )}
                        </div>
                    </StyledTransactionContainer>
                )
            })}
        </TransactionsListWrapper>
    )
}

TransactionsList.propTypes = {
    payments: PropTypes.array
}
