import {StyledTRow} from '../row/style.ts'
import {Skeleton} from '../../../skeleton/Skeleton'

export const TableMobileLoader = () => {
    return Array.from(Array(3).keys()).map((_, index) => (
        <StyledTRow key={index}>
            <Skeleton height="100%" />
        </StyledTRow>
    ))
}
