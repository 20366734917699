import styled, {css} from 'styled-components'

export const StyledOtherLabel = styled.label(
    ({theme: {colors, typography}}) => css`
        ${typography.textSm};
        font-weight: 600;
        color: ${colors.steelGrey};
    `
)

export const OtherTextAreaWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        padding-top: ${spacing * 3}px;
        padding-right: ${spacing * 6}px;
    `
)
