import styled, {css} from 'styled-components'
import {Container} from '../../../components/container/Container'

export const StyledAdminLogin = styled.div(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 20}px;
        padding-bottom: ${spacing * 20}px;
        overflow-y: auto;
        min-height: 100%;
        display: grid;
    `
)

export const StyledAdminLoginContainer = styled(Container)(
    ({theme: {mediaQuery}}) => css`
        display: grid;
        place-items: center;

        & .formCard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        ${mediaQuery.SM} {
            justify-items: stretch;
        }
    `
)
