import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {MultiParagraphMessage} from '../custom-components/multi-paragraph-message/MultiParagraphMessage'
import {Trans} from 'react-i18next'
import i18next from 'i18next'
import {SimpleLink} from '../custom-components/simple-link/SimpleLink'

export const mb17FlowSteps = () => [
    {
        id: 'MB1.7',
        component: (
            <MultiParagraphMessage
                paragraphs={[
                    <Trans i18nKey="chatbot:mb17:mb1.7_1" components={[<strong />]} />,
                    i18next.t('chatbot:mb17:mb1.7_2'),
                    <Trans i18nKey="chatbot:mb17:mb1.7_3" components={[<br />]} />,
                    <Trans i18nKey="chatbot:mb17:mb1.7_4" components={[<strong />]} />,
                    i18next.t('chatbot:mb17:mb1.7_5')
                ]}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.7',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text:
                        'If the Host is not ready to schedule the photoshoot date, go to the scheduling section and select the option " Host is not Ready to Schedule". ' +
                        'After you have selected this option, the photoshoot will be displayed in the "Require my Attention" section of your Mediabox under the tab "On Hold". ' +
                        'We also recommend that you ask the Host when they will be ready to schedule a date. ' +
                        'If the Host provides you with a date, add it in the following available section, and we will send reminders to you and the Host 7 days before the date you have selected. ' +
                        'If the Host doesn\'t share a reminder date, click the checkbox next to  "Host doesn\'t know when they will be ready" and we will set an automated reminder in 30 days. ' +
                        'You can review this guide for more information and details on the coordination system: https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf'
                })
            )
            return 'MB1.7_link'
        }
    },
    {
        id: 'MB1.7_link',
        component: (
            <SimpleLink
                link="https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf"
                text={i18next.t('chatbot:commons:coordination_system_guide')}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.7_link',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] Coordination system guide'
                })
            )
            return 'send_log'
        }
    }
]
