import {useQuery} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {httpGetAdminHostApprovalStatusesCounter} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'

export const useAdminHostApprovalStatusesCounter = () => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useQuery({
        queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_STATUSES_COUNTER],
        queryFn: () =>
            parseAxiosPromise({
                onZodError: captureException,
                axiosPromise: httpGetAdminHostApprovalStatusesCounter({
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: z.object({
                    new: z.number().int(),
                    pending_host_approval: z.number().int(),
                    ready_for_review: z.number().int(),
                    completed: z.number().int()
                })
            })
    })
}
