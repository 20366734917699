import styled, {css} from 'styled-components'
import {Radio} from '../../../../../../components/radio-legacy/Radio'
import {CustomScrollbar} from '../../../../../../theme/components'

export const StyledBody = styled.div`
    ${CustomScrollbar};
    max-height: 650px;
    overflow: auto;
    @media (max-height: 850px) {
        max-height: 500px;
    }
    @media (max-height: 767px) {
        max-height: 450px;
    }
    @media (max-height: 575px) {
        max-height: 400px;
    }
`

export const StyledTitle = styled.h2(
    ({theme: {typography, colors, spacing}}) => css`
        padding: ${spacing * 6}px;
        ${typography.textXl};
        font-weight: 700;
        color: ${colors.lightBlack};
    `
)

export const InputSectionWrapper = styled.div(
    ({theme: {spacing, colors}}) => css`
        padding: ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
        border-bottom: 1px solid ${colors.smokeGrey};
    `
)

export const FooterWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        padding: ${spacing * 6}px;
    `
)

export const RadioWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing * 4}px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    `
)

export const StyledRadio = styled(Radio)(
    ({theme: {spacing, colors, typography, palette}, width}) => css`
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: ${spacing * 2}px;
        padding: 0;
        min-width: ${width ? `${width}px` : 'auto'};
        & input {
            border: 1px solid ${colors.steelGrey};
            width: 16px;
            height: 16px;
            background-color: ${palette.neutral.white};
        }
        & span {
            color: ${palette.neutral.black};
            font-weight: 400;
            ${typography.textMd};
            margin: 0;
            line-height: 22px;
        }
        & > div {
            max-width: 450px;
        }
        & input:checked {
            border: unset !important;
            background: unset;
        }
    `
)

export const StyledRadioSection = styled.div(
    ({theme: {spacing, colors}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
        padding: ${spacing * 6}px;
        border-bottom: 1px solid ${colors.smokeGrey};
    `
)

export const RadiosWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
    `
)

export const StyledRadioTitle = styled.h4(
    ({theme: {typography, colors}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${colors.lightBlack};
    `
)

export const SelectWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `
)

export const StyledSelectLabel = styled.label(
    ({theme: {typography, colors}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${colors.lightBlack};
    `
)

export const StyledName = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 400;
        color: ${palette.neutral['900']};
    `
)

export const StyledCurrency = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 400;
        color: ${palette.neutral['400']};
    `
)

export const StyledEmail = styled.p(
    ({theme: {typography, colors}}) => css`
        ${typography.textSm};
        font-weight: 400 !important;
        color: ${colors.darkGrey};
    `
)

export const StyledOptionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledLabelInfo = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `
)

export const StyledBodyWrapper = styled.div(
    ({theme: {spacing, colors}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
        padding: ${spacing * 6}px;
        border-bottom: 1px solid ${colors.smokeGrey};
    `
)

export const TextAreaWrapper = styled.div(
    ({theme: {spacing, colors}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        & > label {
            color: ${colors.lightBlack};
            font-weight: 600;
        }
    `
)

export const StyledInfoLabel = styled.label(
    ({theme: {typography, colors}}) => css`
        ${typography.textSm};
        color: ${colors.steelGrey};
        font-weight: 400;
    `
)

export const StyledInfo = styled.p(
    ({theme: {typography, colors}}) => css`
        ${typography.textSm};
        color: ${colors.lightBlack} !important;
        font-weight: 700 !important ;
    `
)

export const InfoWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
    `
)

export const StyledSelectInfoWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `
)

export const InfosWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
    `
)

export const HostInfoWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `
)

export const SelectStyle = {
    singleValueCustomStyle: {
        p: {
            display: 'none'
        },
        svg: {
            display: 'none'
        },
        padding: '6px 8px'
    },
    menuCustomStyle: {
        maxHeight: 250,
        overflowX: 'hidden'
    },
    optionCustomStyle: {
        padding: '6px 8px',
        margin: '2px 8px'
    },
    menuContainerCustomStyle: {
        maxWidth: '336px'
    },
    placeholderStyle: {
        paddingLeft: '14px'
    }
}
