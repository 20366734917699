import styled, {css} from 'styled-components'
import {Container} from 'src/components/container/Container.tsx'

export const AssignmentsWrapper = styled.div<{$hasQuoteData: boolean}>(
    ({theme: {palette, typography, spacing}, $hasQuoteData}) => css`
        background: ${palette.neutral['50']};
        overflow-y: ${$hasQuoteData ? 'auto' : 'hidden'};
        padding-top: ${spacing * 16.5}px;
        padding-bottom: ${spacing * 16.5}px;

        & h1 {
            ${typography.displayXs};
            font-weight: 600;
        }
    `
)

export const AssignmentsContainer = styled(Container)(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 8}px;
    `
)
