import styled, {css} from 'styled-components'

export const StyledDropzone = styled.div<{$pageHeight: number; $isDropzoneActive: boolean; $isDropzoneError: boolean}>(
    ({theme: {spacing, typography, transition, palette}, $pageHeight, $isDropzoneActive, $isDropzoneError}) => css`
        position: relative;
        height: ${$pageHeight - 440}px;
        border: ${$isDropzoneActive
            ? `2px dashed ${palette.neutral.black}`
            : $isDropzoneError
            ? `2px dashed ${palette.danger['600']}`
            : `2px dashed ${palette.neutral['300']}`};
        background: ${$isDropzoneActive
            ? palette.neutral['300']
            : $isDropzoneError
            ? palette.danger['50']
            : palette.neutral['200']};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: ${spacing * 4}px;
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
        & h3 {
            ${typography.displayMd};
            font-weight: 700;
        }
        & p {
            ${typography.textMd};
            font-weight: 500;
        }
        & small {
            ${typography.textSm};
            font-weight: 300;
        }
    `
)

export const StyledError = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        font-weight: 700;
        color: ${palette.danger['600']};
    `
)
