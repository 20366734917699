import {StyledMatterport, StyledContent, StyledHeading} from './style'
import {useTranslation} from 'react-i18next'
import {SelectTable} from './select-table/SelectTable'
import {useCallback, useState, useEffect} from 'react'
import {UploadToMediaboxTable} from './upload-to-mediabox-table/UploadToMediabox'
import {MATTERPORT_STATUSES} from '../../../../helpers/constants'
import {httpGetUsersAutocomplete} from '../../../../http-requests/admin/users.http'
import {httpGetTablesCounter} from '../../../../http-requests/admin/matterport.http'
import {errorHandler} from '../../../../helpers/utils'
import {useDispatch, useSelector} from 'react-redux'
import {setTablesCounter, setUsersAutocomplete, tablesCounterSelector} from '../../../../store/matterport'
import {ReadyForQaTable} from './ready-for-qa-table/ReadyForQaTable'
import {PendingHostApprovalTable} from './pending-host-approval-table/PendingHostApproval'
import {MatterportRevisionTable} from './matterport-revision-table/MatterportRevisionTable'
import {ReadyForPublishTable} from './ready-for-publish-table/ReadyForPublishTable'
import {CompletedTable} from './completed-table/CompletedTable'
import {useLocation} from 'react-router-dom'
import {Spinner} from '../../../../components/spinner-legacy/Spinner'
import {useUserStore} from '../../../../features/user/store'
import {Container} from '../../../../components/container/Container'

export const Matterport = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {state: locationState} = useLocation()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const tablesCounter = useSelector(tablesCounterSelector)
    const [isLoading, setIsLoading] = useState(true)

    const tabs = [
        {
            name: MATTERPORT_STATUSES.UPLOAD_MEDIABOX,
            text: t('admin:upload_to_mediabox'),
            render: <UploadToMediaboxTable tablesCounter={tablesCounter} />
        },
        {
            name: MATTERPORT_STATUSES.READY_QA,
            text: t('admin:ready_for_qa'),
            render: <ReadyForQaTable tablesCounter={tablesCounter} />
        },
        {
            name: MATTERPORT_STATUSES.PENDING_APPROVAL,
            text: t('admin:pending_host_approval'),
            render: <PendingHostApprovalTable tablesCounter={tablesCounter} />
        },
        {
            name: MATTERPORT_STATUSES.REVISION,
            text: t('admin:matterport_revision'),
            render: <MatterportRevisionTable tablesCounter={tablesCounter} />
        },
        {
            name: MATTERPORT_STATUSES.READY_PUBLISH,
            text: t('admin:ready_for_publish'),
            render: <ReadyForPublishTable tablesCounter={tablesCounter} />
        },
        {
            name: MATTERPORT_STATUSES.COMPLETED,
            text: t('admin:completed_task'),
            render: <CompletedTable tablesCounter={tablesCounter} />
        }
    ]
    const [activeTab, setActiveTab] = useState(tabs.find(tab => tab.name === locationState) || tabs[0])

    useEffect(() => {
        void getTablesCounter()
    }, [])

    const getTablesCounter = async () => {
        try {
            const {data} = await httpGetTablesCounter(adminAccessToken)
            dispatch(setTablesCounter(data))
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const setActiveTabCb = useCallback(newActiveTab => {
        setActiveTab(newActiveTab)
    }, [])

    useEffect(() => {
        void getUsersAutocomplete()
    }, [])

    const getUsersAutocomplete = async () => {
        try {
            const {data} = await httpGetUsersAutocomplete(adminAccessToken)
            dispatch(setUsersAutocomplete(data))
        } catch (e) {
            errorHandler(e)
        }
    }

    return (
        <StyledMatterport>
            <StyledHeading>
                <Container fullHeight={false}>
                    <h1>{t('admin:matterport_queues')}</h1>
                </Container>
            </StyledHeading>
            <StyledContent>
                <SelectTable
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTabCb={setActiveTabCb}
                    tablesCounter={tablesCounter}
                />
                {isLoading ? <Spinner overlayFullscreen={false} overlay={false} /> : activeTab.render}
            </StyledContent>
        </StyledMatterport>
    )
}
