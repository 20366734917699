import {FC, HTMLAttributes} from 'react'

export const NavigationIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 20, ...rest}) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
        <path
            d="M10.0025 0.3125C12.5749 0.319783 15.0394 1.34731 16.8552 3.16959C18.6709 4.99187 19.6895 7.46004 19.6875 10.0325C19.6789 12.5465 18.6933 14.9586 16.939 16.7593C15.1847 18.56 12.7991 19.6082 10.2863 19.6825L9.97875 19.6875C7.46431 19.6822 5.05044 18.6994 3.24733 16.9469C1.44421 15.1944 0.393138 12.8095 0.31625 10.2963L0.3125 9.99C0.315151 7.42202 1.33731 4.96015 3.15432 3.14548C4.97133 1.33082 7.43451 0.311836 10.0025 0.3125ZM12.4388 10.9375H7.56C7.75375 14.5588 9.03 17.6162 9.9175 17.8037L10.0025 17.8125C10.8525 17.81 12.1525 14.8563 12.4175 11.275L12.4388 10.9375ZM17.7575 10.9375H14.3175C14.2038 13.3875 13.655 15.6663 12.8325 17.2825C14.1011 16.7883 15.2188 15.9714 16.0749 14.9127C16.931 13.854 17.4959 12.5902 17.7137 11.2463L17.7575 10.9375ZM5.68125 10.9375H2.24375C2.41378 12.3405 2.9608 13.6713 3.82667 14.7883C4.69254 15.9053 5.8449 16.7668 7.16125 17.2812C6.3925 15.7712 5.86625 13.6875 5.70875 11.4237L5.68125 10.9362V10.9375ZM7.16625 2.7175L7.0225 2.775C5.73974 3.30479 4.62106 4.16637 3.78127 5.2713C2.94147 6.37624 2.41081 7.68474 2.24375 9.0625H5.68125C5.7875 6.775 6.27 4.6375 7.005 3.05L7.16625 2.7175ZM9.99375 2.1875C9.11625 2.19 7.76375 5.3275 7.56 9.0625H12.44C12.245 5.43375 10.9675 2.38375 10.075 2.195L9.99375 2.18625V2.1875ZM12.8438 2.7275L12.8725 2.79C13.6763 4.4 14.2087 6.645 14.3188 9.0625H17.7537C17.5804 7.6625 17.0328 6.33515 16.1685 5.22026C15.3041 4.10538 14.1551 3.24422 12.8425 2.7275H12.8438Z"
            fill="currentColor"
        />
    </svg>
)
