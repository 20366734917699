import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

import {AssignmentLogicTooltip} from './assignment-logic-tooltip/AssignmentLogicTooltip'
import {ReassignmentModal} from './reassignment-modal/ReassignmentModal'
import {AssignmentCard} from '../../../../../components/assignments/assignment-card/AssignmentCard'
import {Chip} from '../../../../../components/chip/Chip'
import {Table} from '../../../../../components/table-legacy/Table'
import {Button} from '../../../../../components/button/Button'
import {Spinner} from '../../../../../components/spinner-legacy/Spinner'
import {ArrowDownIcon} from '../../../../../assets/icons/ArrowDownIcon'
import {ArrowUpIcon} from '../../../../../assets/icons/ArrowUpIcon'
import {getAssignmentProductionColumns} from '../columns'
import {errorHandler, formatCurrency, formatLocaleDate, openLinkInNewTab} from '../../../../../helpers/utils'
import {
    assignmentStatusToLabel,
    checkIsCompletedStatus,
    coordinationStatusToLabel,
    taskStatusToLabel
} from '../../../../../helpers/statuses'
import {
    ASSIGNMENT_LOGIC_TOOLTIP,
    ASSIGNMENT_STATUSES,
    QUOTE_LOGS_STATUSES,
    ROUTES,
    TASK_STATUSES
} from '../../../../../helpers/constants'
import {httpGetUserImpersonate} from '../../../../../http-requests/admin/users.http'
import {useAdminQuoteLogs} from '../../../../../hooks'
import {
    StyledDetailCell,
    StyledAssignmentCardDetailsItem,
    StyledNoResults,
    StyledStatusCell,
    StyledSubStatusLabel,
    StyledExpandableRowDetails
} from '../style'
import {useUserStore} from '../../../../../features/user/store'

export const ProductionCard = ({quoteData, selectedQuoteID}) => {
    const {t} = useTranslation()
    const setUser = useUserStore(store => store.setUser)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const quoteLogsQuery = useAdminQuoteLogs(selectedQuoteID, QUOTE_LOGS_STATUSES.PRODUCTION)
    const [expandedRowsIndexes, setExpandedRowsIndexes] = useState([])
    const [showReassignmentModal, setShowReassignmentModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const currentAssignment =
        quoteData.assignments?.find(assignment => assignment.status === 'accepted') ?? quoteData.assignments?.[0]

    const onToggleAccordion = index => {
        setExpandedRowsIndexes(prevState => {
            return prevState.includes(index) ? prevState.filter(e => e !== index) : [...prevState, index]
        })
    }

    const onImpersonate = async () => {
        try {
            setIsLoading(true)
            const {data} = await httpGetUserImpersonate(adminAccessToken, quoteData?.photographer?.id)
            setUser({user: {}, access_token: data, refresh_token: ''})

            if (
                !currentAssignment?.code ||
                checkIsCompletedStatus(
                    currentAssignment?.status,
                    quoteData?.task_status?.status,
                    quoteData?.task_status?.coordination_status
                )
            ) {
                openLinkInNewTab(window.location.origin)
            } else {
                openLinkInNewTab(
                    `${window.location.origin}${ROUTES.SINGLE_ASSIGNMENT_NO_ID}/${currentAssignment?.code}`
                )
            }
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            {showReassignmentModal && (
                <ReassignmentModal onClose={() => setShowReassignmentModal(false)} quoteData={quoteData} />
            )}
            <AssignmentCard
                title={
                    <>
                        <h3>{t('admin:assignments:production:title')}</h3>
                        {quoteData?.task_status?.status && (
                            <Chip text={t(taskStatusToLabel[quoteData.task_status.status])} rounded size="medium" />
                        )}
                        {!!quoteData?.task_status?.coordination_status && (
                            <p>{t(coordinationStatusToLabel[quoteData.task_status.coordination_status])}</p>
                        )}
                    </>
                }
                statusUpdatedAt={formatLocaleDate(quoteData?.photographer?.updated_at, 'LLL')}
                actions={
                    quoteData?.task_status?.status !== TASK_STATUSES.HARD_CANCELLED &&
                    quoteData?.task_status?.status !== TASK_STATUSES.AUTO_CANCELLED ? (
                        <>
                            {quoteData?.task?.id && (
                                <Button onClick={() => setShowReassignmentModal(true)} variant="tertiaryPrimary">
                                    {t('admin:assignments:production:reassign_button')}
                                </Button>
                            )}
                            {quoteData?.photographer?.id ? (
                                <Button variant="tertiaryPrimary" onClick={onImpersonate}>
                                    {t('commons:impersonate')}
                                </Button>
                            ) : null}
                        </>
                    ) : null
                }
                details={
                    <>
                        {isLoading && <Spinner />}
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:production:partner_name')}</span>
                            <p>{quoteData?.photographer?.name || '-'}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:production:partner_id')}</span>
                            <p>{quoteData?.photographer?.airbnb_user_id || '-'}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:production:partner_email')}</span>
                            <p>
                                {quoteData?.photographer?.email ? (
                                    <a href={`mailto:${quoteData.photographer.email}`}>
                                        {quoteData.photographer.email}
                                    </a>
                                ) : (
                                    '-'
                                )}
                            </p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:production:distance')}</span>
                            <p>
                                {`${
                                    currentAssignment?.distance_km ? Math.round(currentAssignment.distance_km) : '-'
                                } km`}
                                {' / '}
                                {`${
                                    currentAssignment?.distance_mile ? Math.round(currentAssignment.distance_mile) : '-'
                                } mi`}
                            </p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('commons:assignment_date')}</span>
                            <p>{formatLocaleDate(currentAssignment?.to_shoot_at)}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:production:assignment_logic')}</span>
                            {currentAssignment?.assignment_logic === ASSIGNMENT_LOGIC_TOOLTIP.FORCED_MATCH ? (
                                <AssignmentLogicTooltip assignment={currentAssignment} />
                            ) : (
                                <p>{currentAssignment?.assignment_logic || '-'}</p>
                            )}
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:production:assignment_rounds')}</span>
                            <p>{quoteData?.task?.accepted_rounds || '-'}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:production:auto_cancellation_on')}</span>
                            <p>{currentAssignment?.autocancellation || '-'}</p>
                        </StyledAssignmentCardDetailsItem>
                    </>
                }
                tableTitle={t('admin:assignments:production:table_title')}
                table={
                    quoteLogsQuery.remappedData.length >= 1 || quoteLogsQuery.isFetching ? (
                        <Table
                            columns={getAssignmentProductionColumns()}
                            data={quoteLogsQuery.remappedData}
                            tbodyHeight="372px"
                            isPaginated
                            isLoading={quoteLogsQuery.isLoading}
                            isFetchingNextPage={quoteLogsQuery.isFetchingNextPage}
                            isRefetching={quoteLogsQuery.isRefetching}
                            onChangePageCb={quoteLogsQuery.onChangePage}
                            expandedRowsIndexes={expandedRowsIndexes}
                            page={quoteLogsQuery.page}
                            variant="grey"
                            columnsRenderers={{
                                date: item => (
                                    <span title={formatLocaleDate(item?.updated_at, 'lll')}>
                                        {formatLocaleDate(item?.updated_at, 'lll')}
                                    </span>
                                ),
                                status: item => (
                                    <StyledStatusCell>
                                        <Chip
                                            bold
                                            text={
                                                item?.current_status?.status
                                                    ? t(taskStatusToLabel[item?.current_status?.status])
                                                    : '-'
                                            }
                                            rounded
                                            size="medium"
                                        />
                                        {item?.current_status?.status === TASK_STATUSES.SCHEDULED &&
                                        item?.extras?.to_shoot_at ? (
                                            <StyledSubStatusLabel
                                                title={t(
                                                    coordinationStatusToLabel[item.current_status.coordination_status]
                                                )}
                                            >
                                                {formatLocaleDate(item.extras.to_shoot_at, 'lll')}
                                            </StyledSubStatusLabel>
                                        ) : null}
                                        {!!item?.current_status?.coordination_status && (
                                            <StyledSubStatusLabel
                                                title={t(
                                                    coordinationStatusToLabel[item.current_status.coordination_status]
                                                )}
                                            >
                                                {t(coordinationStatusToLabel[item.current_status.coordination_status])}
                                            </StyledSubStatusLabel>
                                        )}
                                        {item?.current_status?.status === TASK_STATUSES.WAITLISTED &&
                                        !!item?.extras?.status ? (
                                            <StyledSubStatusLabel
                                                title={t(assignmentStatusToLabel[item.extras.status])}
                                            >
                                                {t(assignmentStatusToLabel[item.extras.status])}
                                            </StyledSubStatusLabel>
                                        ) : null}
                                    </StyledStatusCell>
                                ),
                                freelancer: item => (
                                    <StyledDetailCell title={item?.photographer?.name ?? '-'}>
                                        {item?.photographer?.name ?? '-'}
                                    </StyledDetailCell>
                                ),
                                actions: (log, index) =>
                                    log?.extras?.status === ASSIGNMENT_STATUSES.HARD_CANCELLED ||
                                    log?.extras?.status === ASSIGNMENT_STATUSES.SOFT_CANCELLED ||
                                    log?.extras?.status === ASSIGNMENT_STATUSES.DECLINED ? (
                                        <Button variant="tertiary" size="xs" onClick={() => onToggleAccordion(index)}>
                                            {expandedRowsIndexes.includes(index)
                                                ? t('commons:collapse')
                                                : t('commons:expand')}
                                            {expandedRowsIndexes.includes(index) ? (
                                                <ArrowUpIcon size={20} />
                                            ) : (
                                                <ArrowDownIcon size={20} />
                                            )}
                                        </Button>
                                    ) : null,
                                expandedRow: log => (
                                    <StyledExpandableRowDetails>
                                        <StyledAssignmentCardDetailsItem>
                                            <span>{t('admin:assignments:production:partner_id')}</span>
                                            <p>{log?.photographer?.airbnb_user_id || '-'}</p>
                                        </StyledAssignmentCardDetailsItem>
                                        <StyledAssignmentCardDetailsItem>
                                            <span>{t('admin:assignments:production:partner_email')}</span>
                                            <p>
                                                {log?.photographer?.email ? (
                                                    <a href={`mailto:${log.photographer.email}`}>
                                                        {log.photographer.email}
                                                    </a>
                                                ) : (
                                                    '-'
                                                )}
                                            </p>
                                        </StyledAssignmentCardDetailsItem>
                                        <StyledAssignmentCardDetailsItem>
                                            <span>{t('admin:assignments:payments:freelancer_rate')}</span>
                                            <p>
                                                {log?.extras?.amount ? formatCurrency(log.extras.amount, 'USD') : '-'}
                                            </p>
                                        </StyledAssignmentCardDetailsItem>
                                        <StyledAssignmentCardDetailsItem>
                                            <span>{t('admin:assignments:payments:travel_compensation')}</span>
                                            <p>
                                                {log?.extras?.travel_compensation
                                                    ? formatCurrency(log.extras.travel_compensation, 'USD')
                                                    : '-'}
                                            </p>
                                        </StyledAssignmentCardDetailsItem>
                                        <StyledAssignmentCardDetailsItem>
                                            <span>{t('admin:assignments:production:assignment_logic')}</span>
                                            <p>{log?.extras?.assignment_logic || '-'}</p>
                                        </StyledAssignmentCardDetailsItem>
                                        {log?.extras?.canceled_reason_notes ? (
                                            <StyledAssignmentCardDetailsItem title={log.extras.canceled_reason_notes}>
                                                <span>{t('admin:assignments:production:cancellation_reason')}</span>
                                                <p>{log.extras.canceled_reason_notes}</p>
                                            </StyledAssignmentCardDetailsItem>
                                        ) : log?.extras?.declined_reason_notes ? (
                                            <StyledAssignmentCardDetailsItem title={log.extras.declined_reason_notes}>
                                                <span>{t('admin:assignments:production:decline_reason')}</span>
                                                <p>{log.extras.declined_reason_notes}</p>
                                            </StyledAssignmentCardDetailsItem>
                                        ) : null}
                                    </StyledExpandableRowDetails>
                                )
                            }}
                        />
                    ) : (
                        <StyledNoResults>{t('admin:no_history')}</StyledNoResults>
                    )
                }
            />
        </>
    )
}

ProductionCard.propTypes = {
    quoteData: PropTypes.object,
    selectedQuoteID: PropTypes.number
}
