import styled, {css} from 'styled-components'

export const StyledTRow = styled.div<{$clickableRowPath?: boolean}>`
    ${({theme: {spacing, colors, palette, mediaQuery, transition}, $clickableRowPath}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2.5}px;
        background: ${palette.neutral.white};
        border-radius: 8px;
        border: 1px solid ${colors.transparentBlack};
        padding: ${spacing * 4}px;
        margin-right: ${spacing * 0.5}px;

        &:not(last-child) {
            margin-bottom: ${spacing * 4}px;
        }

        ${$clickableRowPath &&
        css`
            ${transition};
            cursor: pointer;
            &:hover {
                background: ${palette.neutral['50']};
            }
        `}

        ${mediaQuery.small} {
            gap: ${spacing * 4}px;
        }
    `}
`

export const StyledTCell = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing}px;
        min-width: 0;

        * {
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        ${mediaQuery.small} {
            flex-direction: column;
        }
    `}
`

export const StyledTCellLabel = styled.div`
    ${({theme: {spacing, typography, colors}}) => css`
        flex: none;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        ${typography.textXs};
        color: ${colors.darkGrey};

        & svg {
            width: 16px;
        }
    `}
`

export const StyledTCellValue = styled.span<{$mobileFullLength: boolean}>`
    ${({theme: {typography}, $mobileFullLength}) => css`
        ${typography.textXs};
        font-weight: 600;
        width: ${$mobileFullLength ? '100%' : 'auto'};
    `}
`

export const StyledExpandedRow = styled.div`
    width: 100%;
`

export const StyledSubRow = styled.div`
    ${({theme: {colors, typography}}) => css`
        width: 100%;
        color: ${colors.lightBlack};
        ${typography.textXs};
    `}
`
