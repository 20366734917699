import {FC, HTMLAttributes} from 'react'

export const RedDollarIcon: FC<{size?: number} & HTMLAttributes<SVGSVGElement>> = ({size = 48, ...rest}) => (
    <svg viewBox="0 0 48 48" fill="none" width={size} height={size} {...rest}>
        <rect
            x="1.26316"
            y="1.26316"
            width="45.4737"
            height="45.4737"
            rx="22.7368"
            stroke="url(#paint0_linear_511_8112)"
            strokeWidth="2.52632"
        />
        <rect
            x="6.31641"
            y="6.06317"
            width="35.8737"
            height="35.8737"
            rx="17.9368"
            fill="url(#paint1_linear_511_8112)"
            fillOpacity="0.1"
        />
        <mask
            id="mask0_511_8112"
            style={{maskType: 'alpha'}}
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="42"
            height="42"
        >
            <rect x="3.78906" y="3.53687" width="40.9263" height="40.9263" rx="20.4632" fill="black" />
        </mask>
        <g mask="url(#mask0_511_8112)"></g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 9C24.6904 9 25.25 9.55964 25.25 10.25V14H30.25C30.9404 14 31.5 14.5596 31.5 15.25C31.5 15.9404 30.9404 16.5 30.25 16.5H25.25V22.75H27.125C28.6168 22.75 30.0476 23.3426 31.1025 24.3975C32.1574 25.4524 32.75 26.8832 32.75 28.375C32.75 29.8668 32.1574 31.2976 31.1025 32.3525C30.0476 33.4074 28.6168 34 27.125 34H25.25V37.75C25.25 38.4404 24.6904 39 24 39C23.3096 39 22.75 38.4404 22.75 37.75V34H16.5C15.8096 34 15.25 33.4404 15.25 32.75C15.25 32.0596 15.8096 31.5 16.5 31.5H22.75V25.25H20.875C19.3832 25.25 17.9524 24.6574 16.8975 23.6025C15.8426 22.5476 15.25 21.1168 15.25 19.625C15.25 18.1332 15.8426 16.7024 16.8975 15.6475C17.9524 14.5926 19.3832 14 20.875 14H22.75V10.25C22.75 9.55964 23.3096 9 24 9ZM22.75 16.5H20.875C20.0462 16.5 19.2513 16.8292 18.6653 17.4153C18.0792 18.0013 17.75 18.7962 17.75 19.625C17.75 20.4538 18.0792 21.2487 18.6653 21.8347C19.2513 22.4208 20.0462 22.75 20.875 22.75H22.75V16.5ZM25.25 25.25V31.5H27.125C27.9538 31.5 28.7487 31.1708 29.3347 30.5847C29.9208 29.9987 30.25 29.2038 30.25 28.375C30.25 27.5462 29.9208 26.7513 29.3347 26.1653C28.7487 25.5792 27.9538 25.25 27.125 25.25H25.25Z"
            fill="url(#paint2_linear_511_8112)"
        />
        <defs>
            <linearGradient id="paint0_linear_511_8112" x1="0" y1="24" x2="48" y2="24" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E5484D" />
                <stop offset="1" stopColor="#E03177" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_511_8112"
                x1="6.31641"
                y1="24"
                x2="42.1901"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E5484D" />
                <stop offset="1" stopColor="#E03177" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_511_8112"
                x1="15.25"
                y1="24"
                x2="32.75"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E5484D" />
                <stop offset="1" stopColor="#E03177" />
            </linearGradient>
        </defs>
    </svg>
)
