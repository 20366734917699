import {AlertIcon} from '../../assets/icons/AlertIcon'
import {StyledToolbar, TextWrapper, LeftSideWrapper, StyledSubtext, StyledMainText, RightSideWrapper} from './style'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {useMatch} from 'react-router'
import {ROUTES} from '../../helpers/constants'
import {Button} from '../button/Button'
import {useState} from 'react'
import {ChangeStatusModal} from './change-status-modal/ChangeStatusModal'
import {CancelAssignmentModal} from './cancel-assignment-modal/CancelAssignmentModal'

export const ImpersonateToolbar = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const isSettingsPage = useMatch(ROUTES.SETTINGS)
    const isTasksPage = useMatch(ROUTES.SINGLE_TASK)
    const isAssignmentPage = useMatch(ROUTES.SINGLE_ASSIGNMENT)
    const [showStatusModal, setShowStatusModal] = useState(false)
    const [showCancelModal, setShowCancelModal] = useState(false)

    return (
        <>
            <StyledToolbar>
                <div className="container h-100">
                    <div className="row h-100">
                        <LeftSideWrapper className="col">
                            <AlertIcon size={32} color={theme.colors.darkYellow} />
                            <TextWrapper>
                                <StyledMainText>{t('admin:impersonate_mode')}</StyledMainText>
                                <StyledSubtext>{t('commons:admin_alert_text')}</StyledSubtext>
                            </TextWrapper>
                        </LeftSideWrapper>
                        <RightSideWrapper className="col">
                            {isSettingsPage && (
                                <Button variant="secondary" size="sm" onClick={() => setShowStatusModal(true)}>
                                    {t('impersonate_statuses:change_status')}
                                </Button>
                            )}
                            {(!!isTasksPage || !!isAssignmentPage) && (
                                <Button variant="secondary" size="sm" onClick={() => setShowCancelModal(true)}>
                                    {t('admin:assignment_cancellation:cancel_assignment')}
                                </Button>
                            )}
                        </RightSideWrapper>
                    </div>
                </div>
            </StyledToolbar>
            {showStatusModal && <ChangeStatusModal onClose={() => setShowStatusModal(false)} />}
            {showCancelModal && <CancelAssignmentModal onClose={() => setShowCancelModal(false)} />}
        </>
    )
}
