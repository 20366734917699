import {TicketSubmit} from '../custom-components/TicketSubmit'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import i18next from 'i18next'

export const mb0FlowSteps = () => [
    {
        id: 'MB0',
        message: i18next.t('chatbot:mb0:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB0',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Great! Do you know the airbnb ID for this new shoot?'
                })
            )
            return 'MB0.1'
        }
    },
    {
        id: 'MB0.1',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB0.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB0.2'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB0.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB0.1.1'
                }
            }
        ]
    },
    {
        id: 'MB0.1.1',
        message: i18next.t('chatbot:mb0:mb0.1.1'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB0.1.1',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please confirm with the Host which listing they would like photographed. Once you can provide the airbnb ID, we will be able to send a new price quote to the Host and you will be assigned to the photoshoot.'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB0.2',
        message: i18next.t('chatbot:commons:type_airbnb_id'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB0.2',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please, type the airbnb ID:'
                })
            )
            return 'MB0.2.1'
        }
    },
    {
        id: 'MB0.2.1',
        user: true,
        validator: value => {
            if (isNaN(value)) {
                return i18next.t('chatbot:commons:enter_number')
            }
            return true
        },
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB0.2.1',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB0_ticket'
        }
    },
    {
        id: 'MB0_ticket',
        component: <TicketSubmit reason="new_quote" />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB0_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'MB0_CONFIRMATION'
        },
        replace: true,
        asMessage: true
    },
    {
        id: 'MB0_CONFIRMATION',
        message: i18next.t('chatbot:mb0:mb0_confirmation'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB0_CONFIRMATION',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thank you! Please inform the Host they will receive a price quote for this shoot within 48hrs. Once they accept the quote, you will automatically be assigned and will have 48hrs to accept or reject the assignment.'
                })
            )
            return 'send_log'
        }
    }
]
