import styled, {css} from 'styled-components'

export const StyledTooltipWrapper = styled.div(
    ({theme: {typography, spacing}}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        ${typography.textSm};
        font-weight: 500;
    `
)

export const StyledTooltipContent = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `
)

export const StyledTooltipContentItem = styled.div(
    ({theme: {spacing, typography}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        align-items: center;
        ${typography.textXs};
        font-weight: 500;
    `
)

export const StyledQuestionIconWrapper = styled.div(
    ({theme: {spacing}}) => css`
        margin-top: -${spacing * 0.5}px;
    `
)
