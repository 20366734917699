import {StyledChip} from './style'
import {statusColors} from '../../theme/colors'
import {ReactNode} from 'react'

interface ChipProps {
    className?: string
    color?: string
    background?: string
    borderColor?: string
    size: 'small' | 'medium' | 'big'
    rounded: boolean
    text: string
    icon?: ReactNode
    isStrokeIcon?: boolean
    isPathFilledIcon?: boolean
    iconPosition?: 'left' | 'right'
    isClickable?: boolean
    fullWidth?: boolean
    disabled?: boolean
    borderStyle?: string
    textUppercase?: boolean
}

/**
 * @deprecated use the new Badge component instead
 * */
export const Chip = ({
    className,
    color = statusColors.GENERIC.text,
    borderColor = statusColors.GENERIC.borderColor,
    background = statusColors.GENERIC.background,
    borderStyle = 'solid',
    size,
    rounded,
    text,
    icon,
    isStrokeIcon = false,
    isPathFilledIcon = false,
    iconPosition = 'left',
    isClickable = false,
    fullWidth = false,
    disabled = false,
    textUppercase = false,
    ...rest
}: ChipProps) => {
    return (
        <StyledChip
            className={className}
            id={size}
            $color={color}
            $background={background}
            $borderColor={borderColor}
            $borderStyle={borderStyle}
            $size={size}
            $rounded={rounded}
            $isStrokeIcon={isStrokeIcon}
            $isPathFilledIcon={isPathFilledIcon}
            $iconPosition={iconPosition}
            $isClickable={isClickable}
            $fullWidth={fullWidth}
            $disabled={disabled}
            title={text}
            $textUppercase={textUppercase}
            {...rest}
        >
            {icon}
            <span>{text}</span>
        </StyledChip>
    )
}
