import styled, {css} from 'styled-components'

export const HeadTabs = styled.div`
    ${({theme: {colors}}) => css`
        display: flex;
        align-items: center;
        background: ${colors.smokeGrey};
        width: max-content;
        border-radius: 8px;
    `}
`
