import styled, {css, keyframes} from 'styled-components'
import {CSSProperties} from 'react'

const pulse = (color: CSSProperties['fill']) => keyframes`
  50% { fill: ${color} }
  to { fill: transparent }
`

export const StyledSpinner = styled.svg`
    ${({color}) => css`
        margin: 0 auto;
        path:nth-of-type(1) {
            animation: ${pulse(color)} 1s infinite linear;
        }

        path:nth-of-type(2) {
            animation: ${pulse(color)} 1s -0.083s infinite linear;
        }

        path:nth-of-type(3) {
            animation: ${pulse(color)} 1s -0.166s infinite linear;
        }

        path:nth-of-type(4) {
            animation: ${pulse(color)} 1s -0.249s infinite linear;
        }

        path:nth-of-type(5) {
            animation: ${pulse(color)} 1s -0.332s infinite linear;
        }

        path:nth-of-type(6) {
            animation: ${pulse(color)} 1s -0.415s infinite linear;
        }

        path:nth-of-type(7) {
            animation: ${pulse(color)} 1s -0.498s infinite linear;
        }

        path:nth-of-type(8) {
            animation: ${pulse(color)} 1s -0.581s infinite linear;
        }

        path:nth-of-type(9) {
            animation: ${pulse(color)} 1s -0.664s infinite linear;
        }

        path:nth-of-type(10) {
            animation: ${pulse(color)} 1s -0.747s infinite linear;
        }

        path:nth-of-type(11) {
            animation: ${pulse(color)} 1s -0.83s infinite linear;
        }

        path:nth-of-type(12) {
            animation: ${pulse(color)} 1s -0.913s infinite linear;
        }
    `}
`
