import PropTypes from 'prop-types'
import {Modal} from '../../modal-legacy/Modal'
import {ModalWrapper, StyledTitle, FooterWrapper, StyledRadio, RadioWrapper} from './style'
import {Button} from '../../button/Button'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {showAlert} from '../../../store/appGenerics'
import {ALERT_LEVELS, PHOTOGRAPHER_STATUSES} from '../../../helpers/constants'
import {httpChangePhotographerStatus} from '../../../http-requests/admin/partners.http'
import {errorHandler} from '../../../helpers/utils'
import {useUserStore} from '../../../features/user/store'

export const ChangeStatusModal = ({onClose}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const currentUser = useUserStore(store => store.currentUser)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    const {
        handleSubmit,
        register,
        formState: {isDirty}
    } = useForm({
        defaultValues: {
            status: currentUser.status
        }
    })

    const statuses = [
        {
            value: PHOTOGRAPHER_STATUSES.ACTIVE_AVAILABLE,
            label: t('impersonate_statuses:active_available'),
            helperText: t('impersonate_statuses:active_available_info')
        },
        {
            value: PHOTOGRAPHER_STATUSES.ACTIVE_UNAVAILABLE,
            label: t('impersonate_statuses:active_unavailable'),
            helperText: t('impersonate_statuses:active_unavailable_info')
        },
        {
            value: PHOTOGRAPHER_STATUSES.SUSPENDED,
            label: t('impersonate_statuses:suspended'),
            helperText: t('impersonate_statuses:suspended_info')
        },
        {
            value: PHOTOGRAPHER_STATUSES.DEACTIVATED,
            label: t('impersonate_statuses:deactivated'),
            helperText: t('impersonate_statuses:deactivated_info')
        }
    ]

    const onSubmit = async values => {
        try {
            await httpChangePhotographerStatus(currentUser.id, values, adminAccessToken)
            dispatch(
                showAlert({
                    message: t('impersonate_statuses:status_changed_success'),
                    level: ALERT_LEVELS.SUCCESS
                })
            )
        } catch (error) {
            errorHandler(error)
        }
        onClose()
    }

    return (
        <Modal onClose={onClose} width="432px">
            <ModalWrapper onSubmit={handleSubmit(onSubmit)}>
                <StyledTitle>{t('impersonate_statuses:change_status')}</StyledTitle>
                <RadioWrapper>
                    {statuses.map(item => (
                        <StyledRadio
                            name="status"
                            value={item.value}
                            label={item.label}
                            helperText={item.helperText}
                            fillRadioImg
                            isSmall
                            {...register('status')}
                            key={item.value}
                        />
                    ))}
                </RadioWrapper>
                <FooterWrapper>
                    <Button onClick={onClose} variant="tertiary">
                        {t('commons:cancel')}
                    </Button>
                    <Button type="submit" disabled={!isDirty}>
                        {t('commons:submit')}
                    </Button>
                </FooterWrapper>
            </ModalWrapper>
        </Modal>
    )
}

ChangeStatusModal.propTypes = {
    onClose: PropTypes.func
}
