import {useNavigate} from 'react-router'
import PropTypes, {shape} from 'prop-types'

import {TABLE_ALIGNMENTS} from '../../../../helpers/constants'

import {StyledSubRow, StyledTCell, StyledTRow, StyledExpandedRow, StyledTCellText} from './style'

export const Row = ({
    item,
    itemIndex,
    columns,
    columnsRenderers,
    expandedRowsIndexes,
    alternatingRowColors,
    clickableRowPath,
    variant = 'default'
}) => {
    const navigate = useNavigate()

    const onClickRow = () => {
        if (clickableRowPath) navigate(clickableRowPath)
    }
    return (
        <StyledTRow
            alternatingRowColors={alternatingRowColors}
            clickableRowPath={clickableRowPath}
            onClick={clickableRowPath ? onClickRow : null}
            variant={variant}
            id={variant}
        >
            {columns.map((column, index) => (
                <StyledTCell width={column.width} alignment={column.alignment} key={index}>
                    <StyledTCellText allowOverflow={column.allowOverflow}>
                        {columnsRenderers[column?.name]
                            ? columnsRenderers[column?.name](item, itemIndex)
                            : column?.name}
                    </StyledTCellText>
                </StyledTCell>
            ))}
            {!!columnsRenderers.expandedRow && expandedRowsIndexes.includes(itemIndex) && (
                <StyledExpandedRow>{columnsRenderers.expandedRow(item, itemIndex)}</StyledExpandedRow>
            )}
            {!!columnsRenderers.subRow && <StyledSubRow>{columnsRenderers.subRow(item, itemIndex)}</StyledSubRow>}
        </StyledTRow>
    )
}

export const RowVariant = PropTypes.oneOf(['default', 'grey'])

Row.propTypes = {
    item: PropTypes.object.isRequired,
    itemIndex: PropTypes.number.isRequired,
    columns: PropTypes.arrayOf(
        shape({
            alignment: PropTypes.oneOf(Object.values(TABLE_ALIGNMENTS)),
            allowOverflow: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            mobileFullLength: PropTypes.bool,
            name: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            width: ({width}, propName, componentName) => {
                if (!/^\d+%$/.test(width)) {
                    return new Error(
                        `Invalid prop '${propName}' supplied to ${componentName} (${width}). The prop must be a positive percentage number.`
                    )
                }
            }
        })
    ).isRequired,
    columnsRenderers: PropTypes.object.isRequired,
    expandedRowsIndexes: PropTypes.arrayOf(PropTypes.number),
    alternatingRowColors: PropTypes.bool,
    clickableRowPath: PropTypes.string,
    variant: RowVariant
}
