import {useTranslation} from 'react-i18next'
import {CardComponent, Head, Title} from './style'
import {ReactElement, ReactNode} from 'react'

export const Card = ({children, icon, title}: {children: ReactNode; icon: ReactElement; title: string}) => {
    const {t} = useTranslation()

    return (
        <CardComponent>
            <Head>
                {icon}
                <Title>{t(title)}</Title>
            </Head>
            {children}
        </CardComponent>
    )
}
