import {useRef} from 'react'
import {
    StyledAvatarInputFile,
    StyledAvatarPicker,
    StyledAvatarPickerWrapper,
    StyledAvatarSpinner,
    StyledProfileInfoMessage
} from './style'
import {Button} from '../../../../components/button/Button'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {showAlert} from '../../../../store/appGenerics'
import {Flexbox} from '../../../../components/flexbox/Flexbox'

const UPLOAD_LIMIT = 2000000

export const AvatarPicker = ({currentAvatar = '', onChange, onRemove, isLoading = false}) => {
    const {t} = useTranslation()
    const inputRef = useRef('')
    const dispatch = useDispatch()

    const handleClickAvatarButton = () => {
        inputRef.current.click()
    }

    const onFileChange = e => {
        const file = e.target.files[0]
        if (file.size > UPLOAD_LIMIT) {
            dispatch(
                showAlert({
                    message: t('settings:avatar_limit_exceed'),
                    level: 'error'
                })
            )
        } else {
            onChange(file)
        }
    }

    return (
        <Flexbox items="center" gap={5}>
            <StyledAvatarPickerWrapper items="center" justify="center" onClick={handleClickAvatarButton}>
                {isLoading && <StyledAvatarSpinner overlay={true} />}
                <StyledAvatarPicker imageUrl={currentAvatar ?? ''} name="Giulio" />
            </StyledAvatarPickerWrapper>

            <Flexbox align="center" direction="column" gap={1}>
                <Button variant="secondary" onClick={handleClickAvatarButton}>
                    {t('settings:change_avatar')}
                </Button>
                <StyledProfileInfoMessage>
                    {t('settings:avatar_upload_limit', {limit: UPLOAD_LIMIT / 1000000})}
                </StyledProfileInfoMessage>
            </Flexbox>

            {currentAvatar && (
                <Button variant="tertiaryPrimary" disabled={isLoading} onClick={onRemove}>
                    {t('settings:delete_avatar')}
                </Button>
            )}

            <StyledAvatarInputFile
                id="avatar-picker"
                type="file"
                ref={inputRef}
                accept="image/*"
                disabled={isLoading}
                value=""
                onChange={onFileChange}
            />
        </Flexbox>
    )
}

AvatarPicker.propTypes = {
    currentAvatar: PropTypes.string,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    isLoading: PropTypes.bool
}
