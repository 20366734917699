import PropTypes from 'prop-types'
import {useWindowWidth} from '@react-hook/window-size'

import {Tab} from '../tab/Tab'
import {screenWidth} from '../../theme/breakpoints'
import {HeadTabs} from './style'

/**
 * @deprecated use the new Switch component instead
 * */
export const Switch = ({options = []}) => {
    const isSM = useWindowWidth() <= screenWidth.SM

    return (
        <>
            {!!options.length && (
                <HeadTabs>
                    {options.map((tab, index) => (
                        <Tab
                            text={tab.text}
                            size="small"
                            hideText={isSM}
                            icon={tab.icon}
                            isSelected={tab.isSelected}
                            onClick={e => {
                                e.stopPropagation()
                                tab.callback()
                            }}
                            key={index}
                        />
                    ))}
                </HeadTabs>
            )}
        </>
    )
}

Switch.propTypes = {
    options: PropTypes.array
}
