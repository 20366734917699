import i18next from 'i18next'
import {store} from '../../../../index'
import {addStepToChatHistory} from '../../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../../helpers/constants'
import {MultiParagraphMessage} from '../../custom-components/multi-paragraph-message/MultiParagraphMessage'
import {Trans} from 'react-i18next'
import {SimpleLink} from '../../custom-components/simple-link/SimpleLink'
import {CancelShoot} from '../../custom-components/CancelShoot'

const notReadyConfirmCancelShoot = isInShootPage => [
    {
        id: 'not_ready_confirm_cancel_shoot_options',
        options: [
            {
                value: 'confirm',
                label: i18next.t('chatbot:commons:confirm_cancellation'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'not_ready_confirm_cancel_shoot_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Confirm cancellation'
                        })
                    )
                    return 'not_ready_confirm_cancel_shoot_confirmed'
                }
            },
            {
                value: 'abandon',
                label: i18next.t('chatbot:commons:changed_mind'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'not_ready_confirm_cancel_shoot_options',
                            source: CHATBOT_SOURCES.USER,
                            text: "I've changed my mind"
                        })
                    )
                    return isInShootPage ? '3' : 'start_generic'
                }
            }
        ]
    },
    {
        id: 'not_ready_confirm_cancel_shoot_confirmed',
        component: <CancelShoot cancelBy="host" />,
        asMessage: true,
        replace: true,
        trigger: 'not_ready_confirm_cancel_shoot_confirmed_sent'
    },
    {
        id: 'not_ready_confirm_cancel_shoot_confirmed_sent',
        message: i18next.t('chatbot:mb14:mb1.4_cancel_sent'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'not_ready_confirm_cancel_shoot_confirmed_sent',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thank you for letting us know the reason of the cancellation. We can confirm the photoshoot request has been canceled.'
                })
            )
            return 'send_log'
        }
    }
]

export const notReady = isInShootPage => [
    {
        id: 'MB1.4_notready',
        message: i18next.t('chatbot:mb14:mb1.4_notready'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_notready',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'What would you like to do?'
                })
            )
            return 'MB1.4_notready_options'
        }
    },
    {
        id: 'MB1.4_notready_options',
        options: [
            {
                value: 'reminder',
                label: i18next.t('chatbot:mb14:mb1.4_notready_option1'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_notready_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I want to keep this assignment in my queue and send a reminder to the Host'
                        })
                    )
                    return 'MB1.4_notready.reminder'
                }
            },
            {
                value: 'reassign',
                label: i18next.t('chatbot:mb14:mb1.4_notready_option2'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_notready_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'I want to assign this photoshoot to another photographer'
                        })
                    )
                    return 'MB1.4_notready.reassign'
                }
            }
        ]
    },
    {
        id: 'MB1.4_notready.reminder',
        asMessage: true,
        component: (
            <MultiParagraphMessage
                paragraphs={[
                    i18next.t('chatbot:mb14:mb1.4_notready_reminder1'),
                    i18next.t('chatbot:mb14:mb1.4_notready_reminder2'),
                    <Trans i18nKey="chatbot:mb14:mb1.4_notready_reminder3" components={[<br />]} />,
                    i18next.t('chatbot:mb14:mb1.4_notready_reminder4'),
                    i18next.t('chatbot:mb14:mb1.4_notready_reminder5')
                ]}
            />
        ),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_notready.reminder',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text:
                        'If the Host is not ready to schedule the photoshoot date, go to the scheduling section and select the option "Host is not Ready to Schedule". ' +
                        'After you have selected this option, the photoshoot will be displayed in the "Require my Attention" section of your Mediabox under the tab "On Hold". ' +
                        'We also recommend that you ask the Host when they will be ready to schedule a date. ' +
                        'If the Host provides you with a date, add it in the following available section, and we will send reminders to you and the Host 7 days before the date you have selected. ' +
                        'If the Host doesn\'t share a reminder date, click the checkbox next to  "Host doesn\'t know when they will be ready" and we will set an automated reminder in 30 days. ' +
                        'You can review this guide for more information and details on the coordination system:'
                })
            )
            return 'MB1.4_notready.reminder_link'
        }
    },
    {
        id: 'MB1.4_notready.reminder_link',
        component: (
            <SimpleLink
                link="https://airbnb.orangelogic.com/AssetLink/m542yg14288oa33s7cs744p2e00k8jc1.pdf"
                text={i18next.t('chatbot:commons:coordination_system_guide')}
            />
        ),
        asMessage: true,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_notready.reminder_link',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Link] Coordination system guide'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB1.4_notready.reassign',
        message: i18next.t('chatbot:mb14:mb1.4_notready_reassign'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_notready.reassign',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please confirm you want to assign the photoshoot to another photographer because \'The Host is not ready to shoot yet"'
                })
            )
            return 'not_ready_confirm_cancel_shoot_options'
        }
    },
    ...notReadyConfirmCancelShoot(isInShootPage)
]
