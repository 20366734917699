import styled, {css} from 'styled-components'
import {AssignmentDescriptionParagraph} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms'

export const StyledAssignmentDescriptionParagraph = styled(AssignmentDescriptionParagraph)(
    ({theme: {palette, spacing}}) => css`
        color: ${palette.danger[600]};
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing}px;
    `
)
