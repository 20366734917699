import styled, {css} from 'styled-components'

export const StyledAssignmentDescriptionWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${spacing * 20}px;

        ${mediaQueries.m} {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            gap: ${spacing * 8}px;
        }
    `
)

export const StyledAssignmentDescriptionTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.displayXs};
        font-weight: 700;
    `
)

export const StyledAssignmentDescriptionParagraph = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};

        & strong {
            color: ${palette.success['600']};
            font-weight: 500;
        }
        & b {
            font-weight: 500;
        }
        & a {
            cursor: pointer;
            text-decoration: underline;
        }
    `
)
