import {PullShoots} from '../custom-components/pull-shoots/PullShoots'
import {CancelShoot} from '../custom-components/CancelShoot'
import {CHATBOT_SOURCES, TASK_STATUSES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import i18next from 'i18next'

export const mb12FlowSteps = () => [
    {
        id: 'MB1.2',
        message: i18next.t('chatbot:mb12:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.2',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Do you feel comfortable in scheduling the photoshoot in the future and keeping this assignment in your Mediabox queue?'
                })
            )
            return 'MB1.2.1'
        }
    },
    {
        id: 'MB1.2.1',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.2.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB1.2.1_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.2.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB1.2.1_no'
                }
            }
        ]
    },
    {
        id: 'MB1.2.1_yes',
        message: i18next.t('chatbot:mb12:mb1.2.1_yes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.2.1_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Awesome! Please keep the photoshoot in your Mediabox queue and schedule the date once the Host is ready.'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB1.2.1_no',
        message: i18next.t('chatbot:mb12:mb1.2.1_no'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.2.1_no',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Do you want to cancel this assignment?'
                })
            )
            return 'MB1.2.2'
        }
    },
    {
        id: 'MB1.2.2',
        options: [
            {
                value: 'yes',
                label: i18next.t('chatbot:mb12:mb1.2.2'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.2.2',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes, cancel the shoot'
                        })
                    )
                    return 'MB1.2.2_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('chatbot:mb12:mb1.2.2_no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.2.2',
                            source: CHATBOT_SOURCES.USER,
                            text: "No, I don't want to cancel"
                        })
                    )
                    return 'send_log'
                }
            }
        ]
    },
    {
        id: 'MB1.2.2_yes',
        message: i18next.t('chatbot:commons:which_shoot'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.2.2_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Which shoot are you referring to?'
                })
            )
            return 'MB1.2_pull'
        }
    },
    {
        id: 'MB1.2_pull',
        component: (
            <PullShoots
                flowSource="MB1.2_pull"
                statuses={[TASK_STATUSES.ACCEPTED, TASK_STATUSES.SCHEDULED]}
                filterByDate="0"
            />
        )
    },

    // Flow triggered in rootShoot when isInShootPage is true
    {
        id: 'MB1.22', // This is the same as MB1.2, but it's used when isInShootPage is true
        message: i18next.t('chatbot:mb12:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.22',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Do you feel comfortable in scheduling the photoshoot in the future and keeping this assignment in your Mediabox queue?'
                })
            )
            return 'MB1.22.1'
        }
    },
    {
        id: 'MB1.22.1',
        options: [
            {
                value: 'yes',
                label: i18next.t('commons:yes'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.22.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes'
                        })
                    )
                    return 'MB1.22.1_yes'
                }
            },
            {
                value: 'no',
                label: i18next.t('commons:no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.22.1',
                            source: CHATBOT_SOURCES.USER,
                            text: 'No'
                        })
                    )
                    return 'MB1.22.1_no'
                }
            }
        ]
    },
    {
        id: 'MB1.22.1_yes',
        message: i18next.t('chatbot:mb12:mb1.2.1_yes'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.22.1_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Awesome! Please keep the photoshoot in your Mediabox queue and schedule the date once the Host is ready.'
                })
            )
            return 'send_log'
        }
    },
    {
        id: 'MB1.22.1_no',
        message: i18next.t('chatbot:mb12:mb1.2.1_no'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.22.1_yes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Do you want to cancel this assignment?'
                })
            )
            return 'MB1.22.2'
        }
    },
    {
        id: 'MB1.22.2',
        options: [
            {
                value: 'yes',
                label: i18next.t('chatbot:mb12:mb1.2.2'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.22.2',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Yes, cancel the shoot'
                        })
                    )
                    return 'MB1.2_cancel'
                }
            },
            {
                value: 'no',
                label: i18next.t('chatbot:mb12:mb1.2.2_no'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.22.2',
                            source: CHATBOT_SOURCES.USER,
                            text: "No, I don't want to cancel"
                        })
                    )
                    return 'send_log'
                }
            }
        ]
    },

    // Cancel shoot steps for both Dashboard Chatbot and Shoot Page Chatbot
    {
        id: 'MB1.2_cancel',
        component: <CancelShoot flowSource="MB1.2_pull" />,
        trigger: 'MB1.2_confirm',
        replace: true,
        asMessage: true
    },
    {
        id: 'MB1.2_confirm',
        message: i18next.t('chatbot:mb12:mb1.2_confirm'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.2_confirm',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'No problem! We will cancel the photoshoot from your Mediabox queue and assign it to another available photographer.'
                })
            )
            return 'send_log'
        }
    }
]
