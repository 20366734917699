import {CancelShoot} from '../../custom-components/CancelShoot'
import {PullShoots} from '../../custom-components/pull-shoots/PullShoots'
import {CHATBOT_CANCELLATION_REASONS, CHATBOT_SOURCES, TASK_STATUSES} from '../../../../helpers/constants'
import {store} from '../../../../index'
import {addStepToChatHistory, setCancellationReason} from '../../../../store/chatbot'
import i18next from 'i18next'
import {noShow} from './noShow'
import {notReady} from './notReady'
import {reassign} from './reassign'
import {hostCancel} from './hostCancel'

export const mb14FlowSteps = (isInShootPage, shoot) => [
    {
        id: 'MB1.4',
        message: i18next.t('chatbot:mb14:starting'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please select the shoot you wish to cancel'
                })
            )
            return 'MB1.4_pull'
        }
    },
    {
        id: 'MB1.4_pull',
        component: (
            <PullShoots
                flowSource="MB1.4_pull"
                statuses={[TASK_STATUSES.ACCEPTED, TASK_STATUSES.SCHEDULED, TASK_STATUSES.PENDING_ASSETS]}
                filterByDate="0"
            />
        )
    },
    {
        id: 'MB1.4.1',
        message: i18next.t('chatbot:mb14:mb1.4.1'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4.1',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please select the cancellation reason'
                })
            )
            return 'MB1.4_options'
        }
    },
    {
        id: 'MB1.4_options',
        options: [
            {
                value: '13 host',
                label: i18next.t('chatbot:cancellation_reasons:host_not_show_up'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:cancellation_reasons:host_not_show_up'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_NO_SHOW
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Host did not show up'
                        })
                    )
                    return 'MB1.4_noshow'
                }
            },
            {
                value: 'step;MB1.4_unresponsive',
                label: i18next.t('chatbot:cancellation_reasons:host_unresponsive_never_responded'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The Host is unresponsive or never responded'
                        })
                    )
                    return 'MB1.3.1'
                }
            },
            {
                value: 'step;MB1.4_notready',
                label: i18next.t('chatbot:cancellation_reasons:host_not_ready_shoot_yet'),
                trigger: () => {
                    store.dispatch(
                        setCancellationReason({
                            text: i18next.t('chatbot:cancellation_reasons:host_not_ready_shoot_yet'),
                            id: CHATBOT_CANCELLATION_REASONS.HOST_NOT_READY
                        })
                    )
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The Host is not ready to shoot yet'
                        })
                    )
                    return 'MB1.4_notready'
                }
            },
            {
                value: 'step;MB1.4_reassign',
                label: i18next.t('chatbot:cancellation_reasons:reassign_to_another_photographer'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Reassign the photoshoot to another photographer'
                        })
                    )
                    return 'MB1.4_reassign'
                }
            },
            {
                value: 'step;MB1.4_host_cancel',
                label: i18next.t('chatbot:cancellation_reasons:host_wants_cancel'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'The Host wants to cancel'
                        })
                    )
                    return 'MB1.4_host_cancel'
                }
            },
            {
                value: 'other',
                label: i18next.t('chatbot:commons:previous_options'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_options',
                            source: CHATBOT_SOURCES.USER,
                            text: '⬅️ Previous options'
                        })
                    )
                    return isInShootPage ? 'start_specific' : 'start_generic'
                }
            }
        ]
    },
    // Final steps for cancellation
    {
        id: 'MB1.4_notes',
        message: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason
            return i18next.t('chatbot:commons:confirm_reason', {
                cancellationReason: cancellationReason.text
            })
        },
        trigger: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_notes',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `Please confirm you want to cancel this shoot because of "${cancellationReason.text}"`
                })
            )
            return 'MB1.4_notes_options'
        }
    },
    {
        id: 'MB1.4_notes_options',
        options: [
            {
                value: 'confirm',
                label: i18next.t('chatbot:commons:confirm_cancellation'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_notes_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Confirm cancellation'
                        })
                    )
                    return 'MB1.4_cancel'
                }
            },
            {
                value: 'abandon',
                label: i18next.t('chatbot:commons:changed_mind'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_notes_options',
                            source: CHATBOT_SOURCES.USER,
                            text: "I've changed my mind"
                        })
                    )
                    return isInShootPage ? '3' : 'start_generic'
                }
            }
        ]
    },
    {
        id: 'MB1.4_cancel',
        component: <CancelShoot flowSource="MB1.4_pull" />,
        asMessage: true,
        replace: true,
        trigger: 'MB1.4_cancel_sent'
    },
    {
        id: 'MB1.4_cancel_sent',
        message: i18next.t('chatbot:mb14:mb1.4_cancel_sent'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_cancel_sent',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Thank you for letting us know the reason of the cancellation. We can confirm the photoshoot request has been canceled.'
                })
            )
            return 'send_log'
        }
    },
    ...noShow(isInShootPage, shoot),
    ...notReady(isInShootPage),
    ...reassign(isInShootPage),
    ...hostCancel(isInShootPage),

    // Final steps for cancellation
    {
        id: 'MB1.4_confirm',
        message: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason
            return i18next.t('chatbot:commons:confirm_reason', {
                cancellationReason: cancellationReason.text
            })
        },
        trigger: () => {
            const cancellationReason = store.getState().chatbot.cancellationReason

            store.dispatch(
                addStepToChatHistory({
                    id: 'MB1.4_confirm',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `Please confirm you want to cancel this shoot because of "${cancellationReason.text}"`
                })
            )
            return 'MB1.4_confirm_options'
        }
    },
    {
        id: 'MB1.4_confirm_options',
        options: [
            {
                value: 'confirm',
                label: i18next.t('chatbot:commons:confirm_cancellation'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_confirm_options',
                            source: CHATBOT_SOURCES.USER,
                            text: 'Confirm cancellation'
                        })
                    )
                    return 'MB1.4_noshow_ticket'
                }
            },
            {
                value: 'abandon',
                label: i18next.t('chatbot:commons:changed_mind'),
                trigger: () => {
                    store.dispatch(
                        addStepToChatHistory({
                            id: 'MB1.4_confirm_options',
                            source: CHATBOT_SOURCES.USER,
                            text: "I've changed my mind"
                        })
                    )
                    return isInShootPage ? '3' : 'start_generic'
                }
            }
        ]
    }
]
