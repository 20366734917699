import styled, {css} from 'styled-components'

export const StyledTitle = styled.div`
    ${({theme: {spacing, typography}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
        margin-bottom: ${spacing * 8}px;

        & > h2 {
            ${typography.displayMd};
            font-weight: 500;
        }
    `}
`

export const StyledEmptyMessage = styled.p`
    ${({theme: {typography, colors}}) => css`
        ${typography.textMd};
        font-weight: 500;
        color: ${colors.grey};
        text-align: center;
    `}
`
