import {Table} from 'src/components/table/Table.tsx'
import {useAdminHostApprovalTasks} from 'src/features/admin-host-approval/services/useAdminHostApprovalTasks.ts'
import {useTranslation} from 'react-i18next'
import {ChangeEvent} from 'react'
import {IdDescriptionCell} from 'src/features/admin-host-approval/components/id-description-cell/IdDescriptionCell.tsx'
import {formatLocaleDate} from 'src/dayjs.ts'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {ButtonLink} from 'src/components/button-link/ButtonLink.tsx'
import {generatePath} from 'react-router'
import {ROUTES} from 'src/helpers/constants.ts'
import {AdminSelectCell} from 'src/features/admin-host-approval/components/admin-select-cell/AdminSelectCell.tsx'
import {useUpdateHostApprovalTask} from 'src/features/admin-host-approval/services/useUpdateHostApprovalTask.ts'
import {TableView} from 'src/features/admin-host-approval/components/table-view/TableView.tsx'
import {InputText} from 'src/components/input-text/InputText.tsx'
import {debounce} from 'src/helpers/helpers.ts'
import {SearchLgIcon, User01Icon} from 'src/components/icon'
import {useTheme} from 'styled-components'
import {ComboboxProvider, SelectProvider} from '@ariakit/react'
import {AdminSelectPopover} from 'src/features/admin-host-approval/components/admin-select-popover/AdminSelectPopover.tsx'
import {SelectTrigger} from 'src/components/select-atoms/SelectAtoms.tsx'
import {useParsedSearchParams} from 'src/hooks/useParsedSearchParams.ts'
import {z} from 'zod'
import {AdminSelectDisplayValue} from 'src/features/admin-host-approval/components/admin-select-display-value/AdminSelectDisplayValue.tsx'
import {Tooltip} from 'src/components/tooltip/Tooltip.tsx'
import {useDeleteHostApprovalTask} from 'src/features/admin-host-approval/services/useDeleteHostApprovalTask'
import {Spinner} from 'src/components/spinner/Spinner'

export const AdminHostApprovalNew = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {searchParams, setSearchParams} = useParsedSearchParams(
        z.object({
            search: z.string().optional(),
            uploader_admin_id: z.coerce.number().optional()
        })
    )
    const adminHostApprovalTasksQuery = useAdminHostApprovalTasks({
        status: 'new',
        search: searchParams.search,
        uploader_admin_id: searchParams.uploader_admin_id
    })
    const updateAdminUploaderMutation = useUpdateHostApprovalTask({status: 'new'})
    const deleteHostApprovalTask = useDeleteHostApprovalTask({status: 'new'})
    const handleSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({search: event.target.value})
    }, 1000)

    return (
        <TableView>
            <Flexbox align="center" gap={4}>
                <InputText
                    defaultValue={searchParams.search}
                    inputSize="sm"
                    width={240}
                    type="text"
                    onChange={handleSearch}
                    placeholder={t('admin_host_approval:search_placeholder')}
                    typeIcon={<SearchLgIcon size={20} fill={theme.palette.neutral['500']} />}
                />
                <ComboboxProvider resetValueOnHide>
                    <SelectProvider
                        value={searchParams.uploader_admin_id?.toString()}
                        defaultValue=""
                        setValue={value => setSearchParams({uploader_admin_id: value ? Number(value) : undefined})}
                    >
                        <SelectTrigger
                            placeholder={
                                <>
                                    <User01Icon />
                                    {t('admin_host_approval:filter_by_assigned')}
                                </>
                            }
                            displayValue={value =>
                                value ? (
                                    <Flexbox align="center" gap={2}>
                                        <AdminSelectDisplayValue id={value} />
                                    </Flexbox>
                                ) : undefined
                            }
                            isClearable
                        />
                        <AdminSelectPopover />
                    </SelectProvider>
                </ComboboxProvider>
            </Flexbox>
            <Table
                data={adminHostApprovalTasksQuery.flatData}
                columns={[
                    {
                        cellRender: task =>
                            task.entity ? (
                                <IdDescriptionCell
                                    id={task.external_id}
                                    link={`https://admin.airbnb.com/rooms/${task.external_id}`}
                                    name={task.entity.title}
                                />
                            ) : (
                                '-'
                            ),
                        label: t('commons:listing'),
                        width: '14%'
                    },
                    {
                        cellRender: task => (
                            <Tooltip content={task.accessibility_project.name} triggerProps={{asChild: true}}>
                                <span>{task.accessibility_project.name}</span>
                            </Tooltip>
                        ),
                        label: t('commons:project'),
                        width: '14%'
                    },
                    {
                        cellRender: task => (
                            <Tooltip content={task.accessibility_type.name} triggerProps={{asChild: true}}>
                                <span>{task.accessibility_type.name}</span>
                            </Tooltip>
                        ),
                        label: t('commons:type'),
                        width: '14%'
                    },
                    {
                        cellRender: task =>
                            task.host ? (
                                <IdDescriptionCell
                                    id={task.external_host_id}
                                    link={`https://admin.airbnb.com/users/show/${task.external_host_id}`}
                                    name={task.host.first_name}
                                />
                            ) : (
                                '-'
                            ),
                        label: t('commons:host'),
                        width: '14%'
                    },
                    {
                        cellRender: task =>
                            task.uploader_admin ? (
                                <AdminSelectCell
                                    isLoading={updateAdminUploaderMutation.isLoading}
                                    adminId={task.uploader_admin.id}
                                    setValue={value =>
                                        updateAdminUploaderMutation.mutate({
                                            payload: {uploader_admin_id: value},
                                            urlParams: {taskId: task.id}
                                        })
                                    }
                                />
                            ) : (
                                '-'
                            ),
                        label: t('commons:assigned_to'),
                        width: '14%'
                    },
                    {
                        cellRender: task => formatLocaleDate(task.created_at, 'll'),
                        label: t('commons:created_on'),
                        width: '14%'
                    },
                    {
                        cellRender: task => (
                            <Flexbox gap={1}>
                                <Button
                                    disabled={
                                        deleteHostApprovalTask.isLoading &&
                                        deleteHostApprovalTask.variables?.urlParams.taskId == task.id
                                    }
                                    variant="tertiary"
                                    onClick={() =>
                                        deleteHostApprovalTask.mutate({
                                            urlParams: {taskId: task.id}
                                        })
                                    }
                                >
                                    {t('commons:delete')}
                                    {deleteHostApprovalTask.isLoading &&
                                        deleteHostApprovalTask.variables?.urlParams.taskId == task.id && (
                                            <Spinner size={12} />
                                        )}
                                </Button>
                                <ButtonLink
                                    to={generatePath(ROUTES.ADMIN_HOST_APPROVAL_TASK, {taskId: task.id.toString()})}
                                    variant="tertiary"
                                >
                                    {t('commons:open')}
                                </ButtonLink>
                            </Flexbox>
                        ),
                        width: '16%',
                        alignment: 'right'
                    }
                ]}
                isChangingPage={adminHostApprovalTasksQuery.isFetchingNextPage}
                isError={adminHostApprovalTasksQuery.isError}
                isLoading={adminHostApprovalTasksQuery.isLoading}
                onChangePage={adminHostApprovalTasksQuery.fetchNextPage}
                searchValue={searchParams.search}
            />
        </TableView>
    )
}
