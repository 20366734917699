import {TicketSubmit} from '../custom-components/TicketSubmit'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {store} from '../../../index'
import {addStepToChatHistory} from '../../../store/chatbot'
import i18next from 'i18next'

export const mb6FlowSteps = () => [
    {
        id: 'MB6',
        message: i18next.t('chatbot:commons:share_airbnb_id'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB6',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please share the airbnb ID or any information to help us identify the listing in our system.'
                })
            )
            return 'MB6_input_id'
        }
    },
    {
        id: 'MB6_input_id',
        user: true,
        validator: value => (value === '' ? i18next.t('chatbot:commons:enter_message') : true),
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB6_input_id',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB6_question'
        }
    },
    {
        id: 'MB6_question',
        message: i18next.t('chatbot:mb6:mb6_question'),
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB6_question',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: 'Please write your question below. Make sure to be very detailed so we can support faster.'
                })
            )
            return 'MB6_input_question'
        }
    },
    {
        id: 'MB6_input_question',
        user: true,
        validator: value => (value === '' ? i18next.t('chatbot:commons:enter_message') : true),
        trigger: ({value}) => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB6_input_question',
                    source: CHATBOT_SOURCES.USER,
                    text: value
                })
            )
            return 'MB6_ticket'
        }
    },
    {
        id: 'MB6_ticket',
        component: <TicketSubmit reason="generic_ticket" isNotListed />,
        trigger: () => {
            store.dispatch(
                addStepToChatHistory({
                    id: 'MB6_ticket',
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Ticket submitted]'
                })
            )
            return 'send_log'
        },
        asMessage: true
    }
]
