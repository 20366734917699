import 'dayjs/locale/it'
import 'dayjs/locale/en'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import i18next from 'i18next'

export const getTimezone = () => {
    dayjs.extend(utc)
    dayjs.extend(timezone)

    return dayjs.tz.guess()
}

export const formatLocaleDate = (date: dayjs.ConfigType, formatType: string) =>
    dayjs(date).locale(i18next.language).format(formatType)

export default dayjs
