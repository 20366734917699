import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_ACTION_KEYS, QUERY_KEYS} from 'src/queryClient.ts'
import {httpScheduleAssignment} from 'src/features/assignment/services/assignment.http.ts'
import {Assignment} from 'src/features/assignment/types.ts'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {captureException} from '@sentry/react'
import {getTimezone} from 'src/dayjs.ts'
import {useAssignmentsCount} from 'src/features/assignments/services/useAssignmentsCount.ts'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync.ts'

export const useScheduleAssignment = ({
    assignmentId,
    options
}: {
    assignmentId: Assignment['id']
    options?: UseMutationOptions<Assignment, unknown, string>
}) => {
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()
    const assignmentsCountQuery = useAssignmentsCount()

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.SCHEDULE_ASSIGNMENT],
        mutationFn: (selectedDate: string) =>
            parseAxiosPromise({
                axiosPromise: httpScheduleAssignment({
                    payload: {date: selectedDate, timezone: getTimezone()},
                    urlParams: {assignmentId: assignmentId},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(accessToken)
                }),
                responseShape: Assignment,
                onZodError: captureException
            }),
        onSuccess: (data, variables, context) => {
            assignmentsCountQuery.updateShootsCount(
                assignmentsCountQuery.data
                    ? {
                          ...assignmentsCountQuery.data,
                          pending_date: assignmentsCountQuery.data.pending_date - 1
                      }
                    : {
                          need_more_assets: 0,
                          pending_assets: 0,
                          assignments_request: 0,
                          assignments_current: 0,
                          pending_date: 0
                      }
            )
            invalidationSignal(QUERY_ACTION_KEYS.ASSIGNMENT_SCHEDULED)
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.ASSIGNMENT, data.code]}, data)
            options?.onSuccess?.(data, variables, context)
        },
        onError: errorHandler
    })
}
