import styled, {css, DefaultTheme} from 'styled-components'
import {StepStatus} from 'src/features/assignment/components/step-card/StepCard.tsx'

const stepCardVariants = ({theme: {palette, typography, shadows, mediaQueries}}: {theme: DefaultTheme}) =>
    ({
        active: css`
            & .number {
                background: ${palette.primary['600']};
                font-size: ${typography.textMd};
                font-weight: 700;
                color: ${palette.neutral.white};
                box-shadow: ${shadows.md};
            }

            ${mediaQueries.m} {
                background: transparent;
                border: none;
            }
        `,
        completed: css`
            & .number {
                background: ${palette.success['50']};
                color: ${palette.success['600']};
            }

            & h1 {
                color: ${palette.neutral['700']};
            }
        `,
        error: css`
            & .number {
                background: ${palette.danger['600']};
                color: ${palette.neutral.white};
            }
        `,
        inactive: css`
            & .number {
                background: ${palette.neutral['100']};
                color: ${palette.neutral['500']};
            }

            & h1 {
                color: ${palette.neutral['500']};
            }
        `
    } satisfies Record<StepStatus, ReturnType<typeof css>>)

export const StyledStepCard = styled.div<{$status: StepStatus}>(
    ({$status, theme}) => css`
        display: flex;
        align-items: center;
        padding: ${theme.spacing * 4}px;
        gap: ${theme.spacing * 2}px;

        .number {
            border-radius: 50%;
            height: 36px;
            width: 36px;
        }

        & h1 {
            font-size: ${theme.typography.textSm};
            font-weight: 600;
        }

        ${stepCardVariants({theme})[$status]}
    `
)
