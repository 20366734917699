import {useMutation, useQueryClient} from '@tanstack/react-query'
import {usePartners} from '../usePartners'
import {errorHandler} from '../../helpers/utils'
import {httpEditPartner} from '../../http-requests/admin/partners.http'
import {MUTATION_KEYS, QUERY_KEYS} from '../../queryClient'
import {useUserStore} from '../../features/user/store'

export const useAdminUpdatePartner = ({onSuccessCallback}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const query = usePartners()

    const mutation = useMutation({
        mutationKey: [MUTATION_KEYS.EDIT_PARTNER],
        mutationFn: async ({id, dataToChange}) => {
            await httpEditPartner(adminAccessToken, dataToChange, id)
            return {id, dataToChange}
        },
        onSuccess: ({id, dataToChange}) => {
            queryClient.setQueriesData([QUERY_KEYS.PARTNERS], oldData => ({
                ...oldData,
                pages: query.data?.pages?.map(page => ({
                    ...page,
                    data: page.data?.map(item =>
                        item.id == id
                            ? {...item, airbnb_user_id: dataToChange.airbnb_id, email: dataToChange.email}
                            : item
                    )
                }))
            }))
            onSuccessCallback()
        },
        onError: errorHandler
    })

    return mutation
}
