import {Hero} from './hero/Hero'
import {NewAssignments} from './new-assignments/NewAssignments'
import {Calendar} from './active-shoots-sections/ActiveShootsSections'
import {PageHelmet} from '../../../components/page-helmet/PageHelmet'
import {useTranslation} from 'react-i18next'
import {HomepageWrapper} from './style'

export const Dashboard = () => {
    const {t} = useTranslation()

    return (
        <HomepageWrapper>
            <PageHelmet title={t('titles:dashboard')} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Hero />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <NewAssignments />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Calendar />
                    </div>
                </div>
            </div>
        </HomepageWrapper>
    )
}
