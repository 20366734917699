import styled, {css} from 'styled-components'
import {ModalBody, ModalFooter} from 'src/components/modal-atoms/ModalAtoms.tsx'

export const StyledModalBody = styled(ModalBody)`
    align-items: center;
    text-align: center;
`

export const StyledModalFooter = styled(ModalFooter)`
    justify-content: center;
`

export const StyledButtonsWrapper = styled.div`
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(5, 1fr);
    width: 320px;
    max-width: 100%;
`

export const StyledButton = styled.button(
    ({theme: {palette, typography, transition}}) => css`
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${palette.neutral['300']};
        ${transition};
        ${typography.textMd};
        color: ${palette.neutral.black};
        font-weight: 500;
        border-radius: 50%;
        cursor: pointer;
        &:disabled {
            background-color: ${palette.neutral['50']};
            cursor: not-allowed;
        }
        &:not(:disabled):hover {
            background: ${palette.primary['50']};
            color: ${palette.primary['600']};
            border-color: ${palette.primary['600']};
        }
    `
)

export const StyledHelperText = styled.small(
    ({theme: {typography}}) => css`
        ${typography.textXs};
    `
)

export const StyledLoadingOverlay = styled.div(
    ({theme: {palette, opacities}}) => css`
        position: absolute;
        inset: 0;
        background: ${palette.neutral.white}${opacities['60']};
        display: flex;
        justify-content: center;
        align-items: center;
    `
)
