import {Navigate, Outlet} from 'react-router-dom'
import {ALERT_LEVELS, ROUTES} from '../helpers/constants'
import {useUserStore} from '../features/user/store'
import {useEffect} from 'react'
import {makeHttpAuthorizationHeader, parseAxiosPromise} from 'src/helpers/helpers'
import {captureException} from '@sentry/react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {showAlert} from 'src/store/appGenerics'
import {httpUserAdminMe} from 'src/http-requests/admin/auth.http'
import {AdminUser} from 'src/features/user/types'

export const AdminRoute = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    const userMe = async (adminAccessToken: string) => {
        try {
            const user = await parseAxiosPromise({
                axiosPromise: httpUserAdminMe({headers: makeHttpAuthorizationHeader(adminAccessToken)}),
                onZodError: captureException,
                responseShape: AdminUser
            })

            useUserStore.setState(() => ({currentAdmin: user}))
        } catch (e) {
            dispatch(
                showAlert({
                    message: t(`errors:default`),
                    level: ALERT_LEVELS.ERROR
                })
            )
        }
    }

    useEffect(() => {
        if (adminAccessToken) {
            userMe(adminAccessToken)
        }
    }, [adminAccessToken])

    return adminAccessToken ? <Outlet /> : <Navigate to={ROUTES.ADMIN_LOGIN} />
}
