import styled, {css} from 'styled-components'
import {Radio} from 'src/components/radio-legacy/Radio'

export const ModalWrapper = styled.form(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 8}px;
    `
)

export const StyledTitle = styled.h3(
    ({theme: {colors, typography}}) => css`
        ${typography.textMd};
        font-weight: 700;
        color: ${colors.lightBlack};
    `
)

export const FooterWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: flex-end;
        gap: ${spacing * 2.5}px;
    `
)

export const RadioWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `
)

export const StyledRadio = styled(Radio)(
    ({theme: {spacing, colors, typography, palette}}) => css`
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        gap: ${spacing * 2}px;
        padding: 0;

        & input {
            border: 1px solid ${palette.neutral['300']} !important;
            width: 16px;
            height: 16px;
            background-color: ${palette.neutral.white};
        }
        & span {
            color: ${colors.lightBlack};
            font-weight: 500;
            ${typography.textSm};
            margin: 0;
            line-height: 20px;
        }
        & input:checked {
            border: unset !important;
            background: unset;
        }
    `
)
