import {AxiosPromise, isAxiosError, RawAxiosRequestHeaders} from 'axios'
import {z, ZodError, ZodSchema} from 'zod'
import i18next from 'i18next'
import {useUserStore} from 'src/features/user/store.ts'
import {showAlert} from 'src/store/appGenerics.ts'
import {ALERT_LEVELS} from 'src/helpers/constants.ts'
// @ts-expect-error [TS7016] "We don't have types yet"
import {store} from 'src/index.js'
import {UseInfiniteQueryResult} from '@tanstack/react-query'
import {forwardRef, ReactNode, Ref, RefAttributes} from 'react'

export const makeHttpAuthorizationHeader = (accessToken: string): RawAxiosRequestHeaders => ({
    Authorization: `Bearer ${accessToken}`
})

/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

export const parseAxiosPromise = <T extends ZodSchema>({
    axiosPromise,
    responseShape,
    onZodError
}: {
    axiosPromise: AxiosPromise
    responseShape: T
    onZodError?: (error: ZodError) => void
}): Promise<z.infer<T>> =>
    axiosPromise.then(response => {
        const safeParsedResponseData = responseShape.safeParse(response.data)
        if (!safeParsedResponseData.success) {
            console.error(safeParsedResponseData.error.message)
            onZodError?.(safeParsedResponseData.error)
            throw new Error(safeParsedResponseData.error.message)
        }

        return safeParsedResponseData.data
    })

export const errorHandler = (error: unknown) => {
    if (isAxiosError(error)) {
        if (error.response?.status == 401) {
            useUserStore.getState().resetUser('all')
        } else {
            const message =
                error.response?.data.message && error.status != 500
                    ? i18next.t(error.response.data.message)
                    : i18next.t('errors:default')

            store.dispatch(
                showAlert({
                    message: i18next.t(`errors:${message}`),
                    level: ALERT_LEVELS.ERROR
                })
            )
        }
    } else {
        store.dispatch(
            showAlert({
                message: i18next.t('errors:default'),
                level: ALERT_LEVELS.ERROR
            })
        )
    }

    return error
}

export const infiniteQueryFetchNextPage = async (infiniteQuery: UseInfiniteQueryResult) => {
    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetching) {
        await infiniteQuery.fetchNextPage()
    }
}

export const formatLocalePrice = ({locale, currency, amount}: {locale: string; currency: string; amount: number}) => {
    return new Intl.NumberFormat(locale, {style: 'currency', currency}).format(amount)
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export const formatCurrency = (amount = 0, currency: string) =>
    new Intl.NumberFormat('en', {
        style: 'currency',
        currency
    }).format(amount)

export const formatDateWithLocaleIntl = (locale: string, date: number | Date, options?: Intl.DateTimeFormatOptions) =>
    new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        ...options
    }).format(date)

export function genericForwardRef<TRef, TProps = object>(
    render: (props: TProps, ref: Ref<TRef>) => ReactNode
): (props: TProps & RefAttributes<TRef>) => ReactNode {
    return forwardRef(render) as (props: TProps & RefAttributes<TRef>) => ReactNode
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export const ObjectKeys = Object.keys as <T extends object>(object: T) => Array<keyof T>
export const ObjectValues = Object.values as <T extends object>(object: T) => Array<T[keyof T]>
export const ObjectEntries = Object.entries as <T extends object>(
    object: T
) => Array<{[K in keyof T]: [K, T[K]]}[keyof T]>

type Entry<K extends PropertyKey, V> = [K, V]
export const ObjectFromEntries = <K extends PropertyKey, V>(entries: Iterable<Entry<K, V>>) =>
    Object.fromEntries(entries) as {[P in K]: V}

/* transform an array of items into a dictionary by utilizing a key selector function. */
export function groupBy<K extends PropertyKey, T>(items: Iterable<T>, keySelector: (item: T) => K): Record<K, T[]> {
    const grouped = {} as Record<K, T[]>

    for (const item of items) {
        const key = keySelector(item)
        grouped[key] = grouped[key] || []
        grouped[key].push(item)
    }

    return grouped
}

export const openExternalLink = (url: string, target: '_blank' | '_self' = '_blank') => {
    window.open(url, target, 'noopener,noreferrer')
}
