import * as T from '@radix-ui/react-tooltip'
import {StyledContent} from './style'
import {ReactNode} from 'react'
import {TooltipContentProps, TooltipPortalProps} from '@radix-ui/react-tooltip'

export interface TooltipProps {
    children: ReactNode
    content: ReactNode
    position?: TooltipContentProps['side']
    variant?: 'black' | 'orange'
    open: boolean
    container: TooltipPortalProps['container']
}

/**
 * @deprecated use the new Tooltiè component instead
 * */
export const Tooltip = ({children, content, position = 'top', variant = 'black', open, container}: TooltipProps) => {
    return (
        <T.Root delayDuration={200} open={open}>
            <T.Trigger asChild>{children}</T.Trigger>
            <T.Portal container={container}>
                <T.Content asChild sideOffset={4} collisionPadding={8} side={position}>
                    <StyledContent $variant={variant}>
                        {content}
                        <T.Arrow width={8} height={4} />
                    </StyledContent>
                </T.Content>
            </T.Portal>
        </T.Root>
    )
}

Tooltip.displayName = 'Tooltip'
