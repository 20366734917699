import {HTMLAttributes} from 'react'
import {StyledAssignmentCardTitle, StyledAssignmentCardSubtitle, StyledAssignmentCardWrapper} from './style'
import {FlexboxProps} from 'src/components/flexbox/Flexbox'

export const AssignmentCardWrapper = (props: FlexboxProps<'div'>) => (
    <StyledAssignmentCardWrapper direction="column" gap={4} {...props} />
)

export const AssignmentCardTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledAssignmentCardTitle {...props} />
)
export const AssignmentCardSubtitle = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledAssignmentCardSubtitle {...props} />
)
