import styled, {css} from 'styled-components'

export const SettingsWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        height: 100%;
        overflow-y: auto;

        .container {
            display: flex;
            flex-direction: column;
            position: relative;
            gap: ${spacing * 8}px;
            padding-top: ${spacing * 10}px;
            padding-bottom: ${spacing * 10}px;

            ${mediaQuery.SM} {
                padding-top: ${spacing * 4}px;
                padding-bottom: ${spacing * 4}px;
            }
        }
    `}
`
