import {useQuery} from '@tanstack/react-query'
import {httpGetPaymentsStatus} from './adminPayments.http'
import {QUERY_KEYS} from '../../../queryClient'
import {PAYMENTS_STATS} from '../../../helpers/constants'
import {useUserStore} from '../../user/store'

export const usePaymentsStatusStats = () => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken)

    const {data: paymentsStatusData, ...rest} = useQuery({
        queryKey: [QUERY_KEYS.ADMIN_PAYMENTS_STATUS_STATS],
        queryFn: () => httpGetPaymentsStatus(adminAccessToken)
    })

    return {
        ...rest,
        data: {
            [PAYMENTS_STATS.PENDING_PAYMENT]: paymentsStatusData?.data[PAYMENTS_STATS.PENDING_PAYMENT],
            [PAYMENTS_STATS.PAID_LAST_WEEK]: paymentsStatusData?.data[PAYMENTS_STATS.PAID_LAST_WEEK],
            [PAYMENTS_STATS.PROCESSED_LAST_WEEK]: paymentsStatusData?.data[PAYMENTS_STATS.PROCESSED_LAST_WEEK]
        }
    }
}
