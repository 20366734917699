import styled, {css} from 'styled-components'

export const StyledHeadTabs = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    max-width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`

export const StyledLastDivisor = styled.span`
    ${({theme: {colors, spacing}}) => css`
        height: 36px;
        width: 2px;
        background: ${colors.lightGrey};
        margin-right: ${spacing * 2}px;
    `}
`
