import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalTaskTitle = styled.h2(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 700;
    `
)

export const StyledAdminHostApprovalTaskType = styled.span(
    ({theme: {typography}}) => css`
        ${typography.textLg};
    `
)
