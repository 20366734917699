import {useState} from 'react'
import dayjs from 'src/dayjs.ts'
import {PageHelmet} from 'src/components/page-helmet/PageHelmet.tsx'
import {useTranslation} from 'react-i18next'
import {StyledFreelancerPayments} from 'src/pages/private/payments/style.ts'
import {PaymentsList} from 'src/features/freelancer-payments/components/payments-list/PaymentsList.tsx'
import {useFreelancerPaymentsDates} from 'src/features/freelancer-payments/services/useFreelancerPaymentsDates.ts'
import {PaymentsFilters} from 'src/features/freelancer-payments/components/payments-filters/PaymentsFilters.tsx'
import {FeaturesRatingModal} from 'src/components/features-rating-modal/FeaturesRatingModal.tsx'
import {RedDollarIcon} from 'src/assets/icons/RedDollarIcon.tsx'
import {RATING_MODAL} from 'src/helpers/constants.ts'

export const FILTERS_STARTING_YEAR = '2023'
export const CURRENT_YEAR = dayjs().year()
export const CURRENT_MONTH = dayjs().month()
export const yearsOptions = Array.from({length: CURRENT_YEAR - Number(FILTERS_STARTING_YEAR) + 1}, (_, index) => {
    const year = Number(FILTERS_STARTING_YEAR) + index
    return {label: year.toString(), value: year}
})

const checkIsRatingModalOpen = () => {
    if (localStorage.getItem(RATING_MODAL.TRANSACTION_RATING_DONE)) {
        return false
    }
    const ratingsClosedTime = localStorage.getItem(RATING_MODAL.TRANSACTION_RATING_CLOSED_TIME_LS)
    const hasPassed24Hours = dayjs().diff(ratingsClosedTime, 'hour') >= 24
    return !ratingsClosedTime || hasPassed24Hours
}

export const FreelancerPayments = () => {
    const {t} = useTranslation()
    const [isRatingModalOpen, setIsRatingModalOpen] = useState(checkIsRatingModalOpen())
    const monthsOptions = [
        {label: t('months:january'), value: '01'},
        {label: t('months:february'), value: '02'},
        {label: t('months:march'), value: '03'},
        {label: t('months:april'), value: '04'},
        {label: t('months:may'), value: '05'},
        {label: t('months:june'), value: '06'},
        {label: t('months:july'), value: '07'},
        {label: t('months:august'), value: '08'},
        {label: t('months:september'), value: '09'},
        {label: t('months:october'), value: '10'},
        {label: t('months:november'), value: '11'},
        {label: t('months:december'), value: '12'}
    ]
    const freelancerPaymentsDatesQuery = useFreelancerPaymentsDates({months: monthsOptions})

    return (
        <StyledFreelancerPayments>
            <PageHelmet title={t('titles:transactions')} />
            <h1>{t('menu:payments')}</h1>
            <PaymentsFilters months={monthsOptions} changeFilters={freelancerPaymentsDatesQuery.changeFilters} />
            <PaymentsList freelancerPaymentsDateQuery={freelancerPaymentsDatesQuery} />

            {isRatingModalOpen && (
                <FeaturesRatingModal
                    mainQuestion={t('consent_collector:payments_question')}
                    modalCompletedTitle={t('consent_collector:main_thanks_text')}
                    modalIcon={<RedDollarIcon />}
                    heightValueLabel={t('consent_collector:helper_text_very_satisfied')}
                    lowValueLabel={t('consent_collector:helper_text_not_satisfied')}
                    onClose={() => setIsRatingModalOpen(false)}
                    type={'payment'}
                />
            )}
        </StyledFreelancerPayments>
    )
}
