import {CSSProperties, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, useState} from 'react'
import {
    StyledInput,
    StyledInputIconGroup,
    StyledInputText,
    StyledInputWrapper,
    StyledVisibleToggle
} from 'src/components/input-text/style.ts'
import {EyeIcon, EyeOffIcon} from 'src/components/icon'
import {Label} from 'src/components/label/Label.tsx'
import {InputStatusIcon} from 'src/components/input-status-icon/InputStatusIcon.tsx'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText.tsx'

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    label?: ReactNode
    name?: string
    type: HTMLInputTypeAttribute
    typeIcon?: ReactNode
    touched?: boolean
    errorMessage?: string
    visibilityToggle?: boolean
    helpText?: ReactNode
    placeholder?: string
    inputSize?: 'sm' | 'md' | 'lg'
    width?: CSSProperties['width']
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
    (
        {
            label,
            name,
            type,
            typeIcon,
            touched = false,
            errorMessage,
            helpText,
            className,
            disabled,
            placeholder,
            visibilityToggle = false,
            inputSize = 'md',
            width = '100%',
            ...rest
        },
        ref
    ) => {
        const [eyeVisible, setEyeVisible] = useState(false)

        return (
            <StyledInputText className={className} $width={width} direction="column" gap={1.5}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <StyledInputIconGroup align="center">
                    <StyledInputWrapper hasError={!!errorMessage} $size={inputSize} visibilityToggle={visibilityToggle}>
                        {typeIcon}
                        <StyledInput
                            ref={ref}
                            name={name}
                            type={eyeVisible ? 'text' : type}
                            placeholder={placeholder}
                            disabled={disabled}
                            {...rest}
                        />
                        <InputStatusIcon touched={touched} hasError={!!errorMessage} />
                    </StyledInputWrapper>

                    {/*input Action toggle*/}
                    {visibilityToggle && (
                        <StyledVisibleToggle
                            $size={inputSize}
                            $disabled={disabled}
                            onClick={() => setEyeVisible(!eyeVisible)}
                        >
                            {eyeVisible ? <EyeIcon size={20} data-color /> : <EyeOffIcon size={20} data-color />}
                        </StyledVisibleToggle>
                    )}
                </StyledInputIconGroup>
                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledInputText>
        )
    }
)

InputText.displayName = 'InputText'
