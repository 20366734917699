import {useFreelancerPayments} from 'src/features/freelancer-payments/services/useFreelancerPayments.ts'
import {
    StyledAccordionBody,
    StyledAccordionBodyTransaction,
    StyledAirbnbLink,
    StyledAmount
} from 'src/features/freelancer-payments/components/payments-accordion-body/style.ts'
import {ErrorBanner} from 'src/components/error-banner/ErrorBanner.tsx'
import {Skeleton} from 'src/components/skeleton/Skeleton.tsx'
import {AirbnbIcon} from 'src/assets/icons/AirbnbIcon.tsx'
import {formatTransactionAmount} from 'src/features/freelancer-payments/utils.ts'
import {capitalize, raise} from 'src/helpers/helpers.ts'

export const PaymentsAccordionBody = ({paymentDate}: {paymentDate?: string}) => {
    const freelancerPaymentsQuery = useFreelancerPayments(paymentDate ?? raise('paymentDate is undefined'))

    if (freelancerPaymentsQuery.isLoading) {
        return (
            <StyledAccordionBody>
                <StyledAccordionBodyTransaction>
                    <hgroup>
                        <h3>
                            <Skeleton width={80} height={20} />
                        </h3>
                        <StyledAirbnbLink>
                            <Skeleton width={120} height={20} />
                        </StyledAirbnbLink>
                    </hgroup>
                    <StyledAmount>
                        <Skeleton width={80} height={20} />
                    </StyledAmount>
                </StyledAccordionBodyTransaction>
            </StyledAccordionBody>
        )
    }
    if (freelancerPaymentsQuery.isError) {
        return (
            <StyledAccordionBody>
                <ErrorBanner />
            </StyledAccordionBody>
        )
    }

    return (
        <StyledAccordionBody>
            {freelancerPaymentsQuery.data.map((payment, index) => {
                return (
                    <StyledAccordionBodyTransaction key={index}>
                        <hgroup>
                            <h3>{capitalize(payment.payment_type)}</h3>
                            {payment.external_id && (
                                <StyledAirbnbLink>
                                    <AirbnbIcon size={16} />
                                    <a
                                        href={payment.airbnb_link}
                                        target="_blank"
                                        rel="noreferrer"
                                        title={payment.external_id}
                                    >
                                        {payment.external_id}
                                    </a>
                                </StyledAirbnbLink>
                            )}
                        </hgroup>
                        <div className="amount">
                            {!!payment.payout_amount && (
                                <StyledAmount $type="positive">
                                    {formatTransactionAmount({
                                        amount: payment.payout_photographer_currency_amount,
                                        amountUSD: payment.payout_amount,
                                        currency: payment.payout_photographer_currency,
                                        prefix: '+'
                                    })}
                                </StyledAmount>
                            )}
                        </div>
                    </StyledAccordionBodyTransaction>
                )
            })}
        </StyledAccordionBody>
    )
}
