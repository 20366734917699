import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {FC, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledAdminHostApprovalImageCard,
    StyledAdminHostApprovalImageCardImg,
    StyledAdminHostApprovalImageCardPlaceholder,
    StyledAdminHostApprovalImageCardTitle
} from 'src/features/admin-host-approval/components/admin-host-approval-image-card/style'
import {ImageXIcon} from 'src/components/icon'
import {AdminHostApprovalImageCardSkeleton} from './AdminHostApprovalImageCardSkeleton'
import {Tooltip} from 'src/components/tooltip/Tooltip'

interface AdminHostApprovalImageCardProps {
    asSkeleton?: undefined
    isSelected: boolean
    image: AdminHostApprovalTaskImage
    className?: string
    isLoading?: boolean
    startSlot?: ReactNode
    endSlot?: ReactNode
}

interface AdminHostApprovalImageCardSkeletonProps {
    asSkeleton: true
    isSelected?: undefined
    image?: undefined
    isLoading?: false
    className?: string
    startSlot?: ReactNode
    endSlot?: ReactNode
}

export const AdminHostApprovalImageCard: FC<
    AdminHostApprovalImageCardProps | AdminHostApprovalImageCardSkeletonProps
> = ({className, isSelected, image, asSkeleton, isLoading, startSlot, endSlot}) => {
    const {t} = useTranslation()

    if (asSkeleton) {
        return (
            <StyledAdminHostApprovalImageCard className={className} direction="column" gap={4.5} align="stretch">
                <AdminHostApprovalImageCardSkeleton style={{aspectRatio: '3/2'}} />
                <AdminHostApprovalImageCardSkeleton width={80} height={20} />
            </StyledAdminHostApprovalImageCard>
        )
    }

    return (
        <StyledAdminHostApprovalImageCard
            className={className}
            direction="column"
            gap={4.5}
            align="stretch"
            $isSelected={isSelected}
        >
            {startSlot}
            {image.url ? (
                <StyledAdminHostApprovalImageCardImg
                    src={image.url}
                    $isLoading={isLoading}
                    alt={image.name}
                    loading="lazy"
                />
            ) : (
                <StyledAdminHostApprovalImageCardPlaceholder direction="column" align="center" justify="center" gap={2}>
                    <ImageXIcon size={18} />
                    <p>{t('errors:unavailable', {subject: 'preview'})}</p>
                </StyledAdminHostApprovalImageCardPlaceholder>
            )}
            <Tooltip content={image.name} triggerProps={{asChild: true}}>
                <StyledAdminHostApprovalImageCardTitle>{image.name}</StyledAdminHostApprovalImageCardTitle>
            </Tooltip>
            {endSlot}
        </StyledAdminHostApprovalImageCard>
    )
}

AdminHostApprovalImageCard.displayName = 'AdminHostApprovalImageCard'
