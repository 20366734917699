import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button.tsx'
import {
    StyledFiltersWrapper,
    StyledFilter,
    StyledSelectWrapper,
    selectStyleYear,
    selectStyleMonth
} from 'src/features/freelancer-payments/components/payments-filters/style.ts'
// @ts-expect-error [TS7016] "We don't have types yet"
import {Select} from 'src/components/select/Select'
import {useForm} from 'react-hook-form'
import {
    CURRENT_MONTH,
    CURRENT_YEAR,
    FILTERS_STARTING_YEAR,
    yearsOptions
} from 'src/pages/private/payments/FreelancerPayments.tsx'
import {useTheme} from 'styled-components'

export const PaymentsFilters = ({
    months,
    changeFilters
}: {
    months: {label: string; value: string}[]
    changeFilters: (formValues: {monthFrom: string; yearFrom: string; monthTo: string; yearTo: string}) => void
}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const form = useForm({
        defaultValues: {
            monthFrom: months[5],
            monthTo: {label: months[CURRENT_MONTH].label, value: months[CURRENT_MONTH].value},
            yearFrom: {label: FILTERS_STARTING_YEAR.toString(), value: FILTERS_STARTING_YEAR},
            yearTo: {label: CURRENT_YEAR.toString(), value: CURRENT_YEAR}
        }
    })
    const monthFrom = form.watch('monthFrom')
    const yearFrom = form.watch('yearFrom')
    const monthTo = form.watch('monthTo')
    const yearTo = form.watch('yearTo')

    //the from month options should be disabled if they are larger than the selected monthTO and if the year is the same
    const fromMonthsOptions = months.map(month =>
        Number(month?.value) > Number(monthTo?.value) && Number(yearFrom?.value) === Number(yearTo?.value)
            ? {
                  ...month,
                  isDisabled: true
              }
            : month
    )
    //the to month options should be disabled if the month to is smaller than the month from selected and if the years selected are the same
    const toMonthsOptions = months.map(month =>
        Number(month?.value) < Number(monthFrom?.value) && Number(yearTo?.value) === Number(yearFrom?.value)
            ? {
                  ...month,
                  isDisabled: true
              }
            : month
    )
    // the from years options should be disabled if they are larger than the selected yearTo , or if they are the same and the selected omnth from is larger than the selected month to
    const fromYearsOptions = yearsOptions.map(yearOption =>
        Number(yearOption?.value) > Number(yearTo?.value)
            ? {...yearOption, isDisabled: true}
            : Number(yearOption?.value) === Number(yearTo?.value) && Number(monthFrom?.value) > Number(monthTo?.value)
            ? {...yearOption, isDisabled: true}
            : yearOption
    )
    //the to years options should be disabled if they are smaller than the selected yearFrom value or if they are dhe same and the selected month to is smaller than the month from value
    const toYearsOptions = yearsOptions.map(yearOption =>
        Number(yearOption?.value) < Number(yearFrom?.value)
            ? {...yearOption, isDisabled: true}
            : Number(yearOption?.value) === Number(yearFrom?.value) && Number(monthTo?.value) < Number(monthFrom?.value)
            ? {...yearOption, isDisabled: true}
            : yearOption
    )

    const submitFilters = form.handleSubmit(formValues => {
        changeFilters({
            monthFrom: formValues.monthFrom.value,
            yearFrom: formValues.yearFrom.value,
            monthTo: formValues.monthTo.value,
            yearTo: formValues.yearTo.value.toString()
        })
        form.reset(formValues)
    })

    return (
        <StyledFiltersWrapper onSubmit={submitFilters}>
            <StyledFilter>
                <h4>
                    {t('transactions:from')}
                    {':'}
                </h4>
                <StyledSelectWrapper>
                    <Select
                        name="monthFrom"
                        options={fromMonthsOptions}
                        control={form.control}
                        isSearchable={false}
                        {...selectStyleMonth(theme)}
                    />
                    <Select
                        name="yearFrom"
                        options={fromYearsOptions}
                        control={form.control}
                        isSearchable={false}
                        {...selectStyleYear(theme)}
                    />
                </StyledSelectWrapper>
            </StyledFilter>
            <StyledFilter>
                <h4>
                    {t('transactions:to')}
                    {':'}
                </h4>
                <StyledSelectWrapper>
                    <Select
                        name="monthTo"
                        options={toMonthsOptions}
                        control={form.control}
                        isSearchable={false}
                        {...selectStyleMonth(theme)}
                    />
                    <Select
                        name="yearTo"
                        options={toYearsOptions}
                        control={form.control}
                        isSearchable={false}
                        {...selectStyleYear(theme)}
                    />
                </StyledSelectWrapper>
            </StyledFilter>
            <Button size="sm" type="submit" disabled={!form.formState.isDirty}>
                {t('transactions:apply')}
            </Button>
        </StyledFiltersWrapper>
    )
}
