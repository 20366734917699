import {getMetaAttribute} from '../../../helpers/utils'
import {useDispatch, useSelector} from 'react-redux'
import {addStepToChatHistory, chatbotShootSelector} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

export function GetInfo({flowSource}) {
    const dispatch = useDispatch()
    const selectedShoot = useSelector(chatbotShootSelector)
    const bedrooms = getMetaAttribute(selectedShoot?.meta, 'Bedrooms')
    const [paragraph, setParagraph] = useState('')
    const {t} = useTranslation()

    useEffect(() => {
        const hostEmail = selectedShoot?.host?.email
        const hostNumber = selectedShoot?.host?.phone
        switch (flowSource) {
            case 'MB1_yes_pull':
            case 'MB1_no_pull':
                dispatch(
                    addStepToChatHistory({
                        id: flowSource,
                        source: CHATBOT_SOURCES.CHATBOT,
                        text: `This is the number of bedrooms based on the listing info: ${
                            bedrooms || '-'
                        }. How many bedrooms did you shoot?`
                    })
                )
                setParagraph(
                    t('chatbot:get_info:mb1_no_pull', {
                        bedrooms: bedrooms || '-'
                    })
                )
                break
            case 'MB1.3_email':
                dispatch(
                    addStepToChatHistory({
                        id: flowSource,
                        source: CHATBOT_SOURCES.CHATBOT,
                        text: `Please email the Host directly at: ${hostEmail || '-'}`
                    })
                )
                setParagraph(
                    t('chatbot:get_info:mb1.3_email', {
                        hostEmail: hostEmail || '-'
                    })
                )
                break
            case 'MB1.3.3_no':
                dispatch(
                    addStepToChatHistory({
                        id: flowSource,
                        source: CHATBOT_SOURCES.CHATBOT,
                        text: `Please contact the Host at: ${hostNumber || '-'}`
                    })
                )
                setParagraph(
                    t('chatbot:get_info:mb1.3.3_no', {
                        hostNumber: hostNumber || '-'
                    })
                )
                break
        }
    }, [flowSource, selectedShoot, bedrooms, dispatch])

    return <p>{paragraph}</p>
}
