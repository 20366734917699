import PropTypes from 'prop-types'
import {Navigate as navigate} from 'react-big-calendar'
import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'
import {Tab} from '../../../tab/Tab'
import {ChevronIcon} from '../../../../assets/icons/ChevronIcon'
import {formatLocaleDate, getMonthAndYear} from '../../../../helpers/utils'
import {StyledToolbar, StyledHeading, StyledNavBar, StyledTodayTab, StyledChevronIcon} from './style'
import {Spinner} from '../../../spinner-legacy/Spinner'

/* Custom component for react-big-calendar */
export const Toolbar = ({date, onNavigate, onChangeRange, loadingRange, fetchedMonths}) => {
    const {t} = useTranslation()
    const isPreviousMonthLoading =
        loadingRange && getMonthAndYear(dayjs(date).subtract(1, 'month')) === getMonthAndYear(loadingRange.start_date)
    const isNextMonthLoading =
        loadingRange && getMonthAndYear(dayjs(date).add(1, 'month')) === getMonthAndYear(loadingRange.end_date)

    const onPrevious = () => {
        const start = formatLocaleDate(dayjs(date).subtract(2, 'month').startOf('month'))
        const end = formatLocaleDate(dayjs(date).subtract(2, 'month').endOf('month'))

        if (!fetchedMonths.includes(getMonthAndYear(start))) onChangeRange(start, end)
        onNavigate(navigate.PREVIOUS)
    }

    const onToday = () => {
        onNavigate(navigate.TODAY)
    }

    const onNext = () => {
        const start = formatLocaleDate(dayjs(date).add(2, 'month').startOf('month'))
        const end = formatLocaleDate(dayjs(date).add(2, 'month').endOf('month'))

        if (!fetchedMonths.includes(getMonthAndYear(start))) onChangeRange(start, end)
        onNavigate(navigate.NEXT)
    }

    return (
        <StyledToolbar id="desktop-calendar-toolbar">
            <StyledHeading>{formatLocaleDate(date, 'MMMM YYYY')}</StyledHeading>
            <StyledNavBar>
                <Tab
                    iconPosition="right"
                    icon={
                        isPreviousMonthLoading ? (
                            <Spinner size={16} center={false} overlay={false} overlayFullscreen={false} />
                        ) : (
                            <ChevronIcon />
                        )
                    }
                    onClick={isPreviousMonthLoading ? Function.prototype : onPrevious}
                />
                <StyledTodayTab text={t('calendar:today')} isSelected onClick={onToday} />
                <Tab
                    icon={
                        isNextMonthLoading ? (
                            <Spinner size={16} center={false} overlay={false} overlayFullscreen={false} />
                        ) : (
                            <StyledChevronIcon />
                        )
                    }
                    onClick={isNextMonthLoading ? Function.prototype : onNext}
                />
            </StyledNavBar>
        </StyledToolbar>
    )
}

Toolbar.propTypes = {
    date: PropTypes.instanceOf(Date),
    onNavigate: PropTypes.func,
    onChangeRange: PropTypes.func,
    loadingRange: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string
    }),
    fetchedMonths: PropTypes.arrayOf(PropTypes.string)
}
