import {useUserStore} from 'src/features/user/store.ts'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers.ts'
import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {
    HttpDeleteHostApprovalTask,
    httpDeleteHostApprovalTask
} from 'src/features/admin-host-approval/services/adminHostApproval.http.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {AdminHostApprovalTask} from 'src/features/admin-host-approval/types'

export const useDeleteHostApprovalTask = ({
    status,
    options
}: {
    status: AdminHostApprovalTask['status']
    options?: UseMutationOptions<unknown, unknown, Omit<HttpDeleteHostApprovalTask, 'headers'>>
}) => {
    const queryClient = useQueryClient()
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('adminAccessToken is nullish')

    return useMutation({
        ...options,
        mutationFn: ({urlParams}: {urlParams: HttpDeleteHostApprovalTask['urlParams']}) =>
            httpDeleteHostApprovalTask({
                headers: makeHttpAuthorizationHeader(adminAccessToken),
                urlParams
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_TASKS, status]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.ADMIN_HOST_APPROVAL_STATUSES_COUNTER]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
