import styled, {css} from 'styled-components'
import * as Ariakit from '@ariakit/react'

export const StyledPopover = styled(Ariakit.Popover)(
    ({theme: {palette, shadows, zIndex}}) => css`
        z-index: ${zIndex.modalDatePicker};
        background-color: ${palette.neutral.white};
        border-radius: 4px;
        box-shadow: ${shadows.md};
    `
)

export const StyledDialog = styled(Ariakit.Dialog)(
    ({theme: {palette, shadows, zIndex}}) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modalDatePicker};
        background-color: ${palette.neutral.white};
        border-radius: 4px;
        box-shadow: ${shadows.md};
    `
)

export const StyledDialogBackdrop = styled.div(
    ({theme: {palette}}) => css`
        background: ${palette.neutral['600']};
        opacity: 0.6;
    `
)
