import {useTranslation} from 'react-i18next'
import {StyledErrorBanner} from './style'
import {Button} from 'src/components/button/Button.tsx'
import PropTypes from 'prop-types'

export const ErrorBanner = ({onRetry}: {onRetry?: () => void}) => {
    const {t} = useTranslation()

    return (
        <StyledErrorBanner>
            <h3>{t('errors:default')}</h3>
            {onRetry && (
                <Button size="sm" variant="secondary" onClick={onRetry}>
                    {t('commons:retry')}
                </Button>
            )}
        </StyledErrorBanner>
    )
}

ErrorBanner.propTypes = {
    onRetry: PropTypes.func
}
