import {createSelector, createSlice, SliceCaseReducers} from '@reduxjs/toolkit'
import {Assignment} from 'src/features/assignment/types.ts'

interface ChatbotStore {
    currentAssignment: Assignment | null
    shoot: Assignment | null
    cancellationReason: {
        text: string | null
        id: number | null
    }
    chatHistory: {id: number; message: string}[]
    sessionId: string | null
}

const chatbot = createSlice<ChatbotStore, SliceCaseReducers<ChatbotStore>, 'chatbot'>({
    name: 'chatbot',
    initialState: {
        currentAssignment: null,
        shoot: null,
        cancellationReason: {
            text: null,
            id: null
        },
        chatHistory: [],
        sessionId: null
    },

    reducers: {
        setCurrentAssignment: (state, action) => {
            state.currentAssignment = action.payload
        },
        setShoot: (state, action) => {
            state.shoot = action.payload
        },
        setCancellationReason: (state, action) => {
            state.cancellationReason = action.payload
        },
        addStepToChatHistory: (state, action) => {
            const {id, source, text} = action.payload
            const stepToAdd = {id, message: `${source}: ${text}`}
            const currentChatHistory = state.chatHistory

            state.chatHistory = [...currentChatHistory, stepToAdd]
        },
        resetChatHistory: state => {
            state.chatHistory = []
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload
        }
    }
})

export const chatbotReducer = chatbot.reducer

export const {
    setCurrentAssignment,
    setShoot,
    setCancellationReason,
    addStepToChatHistory,
    resetChatHistory,
    setSessionId
} = chatbot.actions

const selectSelf = (state: {chatbot: ChatbotStore}) => state.chatbot
export const currentAssignmentSelector = createSelector(selectSelf, state => state.currentAssignment)
export const chatbotShootSelector = createSelector(selectSelf, state => state.shoot)
export const chatbotCancellationReasonSelector = createSelector(selectSelf, state => state.cancellationReason)
export const chatbotHistorySelector = createSelector(selectSelf, state => state.chatHistory)
export const sessionIdSelector = createSelector(selectSelf, state => state.sessionId)
