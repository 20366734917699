import axios from 'axios'

export const httpGetUserImpersonate = (adminAccessToken: string, userId: number) => {
    return axios.get(`/admin/users/${userId}/impersonate`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpGetUsersAutocomplete = (adminAccessToken: string) => {
    return axios.get('/admin', {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}
