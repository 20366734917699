import {FC, useState} from 'react'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {Assignment} from 'src/features/assignment/types'
import {StyledAssignmentScheduledDate} from 'src/features/assignment/components/assignment-scheduled-date/style'
import {useTranslation} from 'react-i18next'
import {formatLocaleDate} from 'src/dayjs'
import {Button} from 'src/components/button/Button'
import {AssignmentUnscheduleModal} from '../assignment-unschedule-modal/AssignmentUnscheduleModal'

export const AssignmentScheduledDate: FC<{date: Assignment['to_shoot_at']}> = ({date}) => {
    const {t} = useTranslation()
    const [isUnscheduleModalOpen, setIsUnscheduleModalOpen] = useState(false)

    return (
        <>
            <StyledAssignmentScheduledDate gap={4} direction="column">
                <Flexbox direction="column" gap={1}>
                    <h3>{formatLocaleDate(date, 'dddd, D MMMM')}</h3>
                    <p>{formatLocaleDate(date, 'LT')}</p>
                </Flexbox>
                <Button variant="tertiary" shape="text" onClick={() => setIsUnscheduleModalOpen(true)}>
                    {t('assignment:unschedule')}
                </Button>
            </StyledAssignmentScheduledDate>

            {isUnscheduleModalOpen && <AssignmentUnscheduleModal onClose={() => setIsUnscheduleModalOpen(false)} />}
        </>
    )
}
