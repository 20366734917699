import {useQuery, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {httpGetAssignmentCount} from 'src/features/assignments/services/assignments.http.ts'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers.ts'
import {useUserStore} from 'src/features/user/store.ts'
import {AssignmentsCount} from 'src/features/assignments/types.ts'
import {captureException} from '@sentry/react'

export const useAssignmentsCount = () => {
    const queryClient = useQueryClient()
    const accessToken = useUserStore(store => store.accessToken) ?? raise('accessToken is null')

    const query = useQuery({
        queryKey: [QUERY_KEYS.ASSIGNMENTS_COUNT, accessToken],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAssignmentCount({headers: makeHttpAuthorizationHeader(accessToken)}),
                responseShape: AssignmentsCount,
                onZodError: captureException
            })
    })

    const updateShootsCount = (newData: AssignmentsCount) => {
        queryClient.setQueryData([QUERY_KEYS.ASSIGNMENTS_COUNT, accessToken], newData)
    }

    return {...query, updateShootsCount}
}
