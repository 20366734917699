import styled, {css} from 'styled-components'
import {CopyIcon} from '../../../../../theme/icons'

export const StyledAdjustmentType = styled.div<{$wrapText?: boolean}>(
    ({theme: {spacing, colors, typography}, $wrapText}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 0.5}px;
        color: ${colors.darkGrey};

        & > div {
            display: flex;
            align-items: center;
            gap: ${spacing * 1.5}px;
            color: ${colors.darkGrey};
        }
        p {
            overflow-x: hidden;
            text-overflow: ellipsis;
            ${typography.textSm};
            font-weight: 400;
        }
        small {
            ${typography.textXs};
            font-weight: 400;
            ${$wrapText
                ? css`
                      white-space: normal;
                      word-break: break-word;
                  `
                : css`
                      overflow-x: hidden;
                      text-overflow: ellipsis;
                  `}
        }
    `
)

//TODO: make a common component
export const StyledCopyIcon = styled(CopyIcon)(
    ({theme: {spacing, transition}}) => css`
        width: 12px;
        margin-left: ${spacing}px;
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    `
)
