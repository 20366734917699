import styled, {css} from 'styled-components'

export const StyledTab = styled.div`
    ${({theme: {spacing, colors, typography, transition, palette}, isActive}) => css`
        position: relative;
        padding: ${spacing * 2.5}px;
        ${typography.textSm};
        font-weight: 700;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        ${transition};
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
        background: ${isActive ? palette.neutral.black : palette.neutral['50']};
        color: ${isActive ? palette.neutral.white : palette.neutral.black};
        border: ${isActive ? `1px solid ${palette.neutral.black}` : `1px solid ${colors.inputGrey}`};

        & span {
            position: absolute;
            top: 0;
            right: 4px;
            ${typography.textXs};
            font-weight: 700;
            color: ${isActive ? colors.inputGrey : colors.darkGrey};
        }
    `}
`
