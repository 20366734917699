import {CSSProperties, forwardRef, ReactNode} from 'react'
import {NavLinkProps} from 'react-router-dom'
import {ButtonStyleProps} from 'src/components/button/Button.tsx'
import {StyledButtonNavLink, StyledButtonNavLinkDisabled} from 'src/components/button-nav-link/style.ts'

export type ButtonNavLinkProps = Omit<NavLinkProps, 'children' | 'className' | 'style'> &
    ButtonStyleProps & {
        children?: ReactNode
        className?: HTMLAnchorElement['className']
        disabled?: boolean
        style?: CSSProperties
    }

export const ButtonNavLink = forwardRef<HTMLAnchorElement, ButtonNavLinkProps>(
    ({fullWidth, shape = 'rectangle', size = 'md', to, variant = 'primary', ...props}, ref) =>
        props.disabled ? (
            <StyledButtonNavLinkDisabled
                ref={ref}
                $fullWidth={fullWidth}
                $shape={shape}
                $size={size}
                $variant={variant}
                {...props}
            />
        ) : (
            <StyledButtonNavLink
                ref={ref}
                to={to}
                $fullWidth={fullWidth}
                $shape={shape}
                $size={size}
                $variant={variant}
                {...props}
            />
        )
)
ButtonNavLink.displayName = 'ButtonNavLink'
