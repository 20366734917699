import {useEffect, useRef, useCallback} from 'react'

export const useClickOutside = callback => {
    const ref = useRef(null)

    const handleClickOutside = useCallback(
        event => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        },
        [callback]
    )

    const handleEscape = useCallback(
        event => {
            if (event.key === 'Escape') {
                callback()
            }
        },
        [callback]
    )

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        document.addEventListener('keydown', handleEscape)
        return () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('keydown', handleEscape)
        }
    }, [callback, handleClickOutside, handleEscape])

    return ref
}
