import axios from 'axios'

export const httpGetPhotographers = (adminAccessToken, taskId) => {
    return axios.get(`admin/photographers/${taskId}`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpEditPartner = (adminAccessToken, formData, id) => {
    return axios.patch(`/admin/photographers/${id}`, formData, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpChangePhotographerStatus = (photographerId, status, adminAccessToken) => {
    return axios.post(`/admin/photographers/${photographerId}/status`, status, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpGetPaymentsStatus = adminAccessToken => {
    return axios.get(`admin/photographer-payments/count`, {
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpUploadPaymentsCsv = (adminAccessToken, formData, onUploadProgress) => {
    return axios.post(`/admin/photographer-payments/upload-csv`, formData, {
        onUploadProgress,
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${adminAccessToken}`}
    })
}

export const httpGetAllPhotographers = (adminAccessToken, params) => {
    return axios
        .get(`admin/photographers`, {
            headers: {Authorization: `Bearer ${adminAccessToken}`},
            params
        })
        .then(response => response.data)
}

/* usePartners */
export const httpGetAdminTable = (adminAccessToken, params) => {
    return axios.get('/admin/home', {
        params,
        headers: {Authorization: `Bearer ${adminAccessToken}`}
    })
}
