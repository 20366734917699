import {
    StyledAccordionsContainer,
    StyledListHead,
    StyledPaymentsList
} from 'src/features/freelancer-payments/components/payments-list/style.ts'
import {useFreelancerPaymentsDates} from 'src/features/freelancer-payments/services/useFreelancerPaymentsDates.ts'
import {NoResultsBanner} from 'src/components/no-results-banner/NoResultsBanner.tsx'
import {Skeleton} from 'src/components/skeleton/Skeleton.tsx'
import {ErrorBanner} from 'src/components/error-banner/ErrorBanner.tsx'
import {useTranslation} from 'react-i18next'
import {formatTransactionAmount} from 'src/features/freelancer-payments/utils.ts'
import dayjs from 'src/dayjs'
import {formatDateWithLocaleIntl} from 'src/helpers/helpers.ts'
import {PaymentsAccordion} from 'src/features/freelancer-payments/components/payments-accordion/PaymentsAccordion.tsx'

export const PaymentsList = ({
    freelancerPaymentsDateQuery
}: {
    freelancerPaymentsDateQuery: ReturnType<typeof useFreelancerPaymentsDates>
}) => {
    const {
        t,
        i18n: {language}
    } = useTranslation()

    if (freelancerPaymentsDateQuery.isLoading) {
        return (
            <StyledPaymentsList>
                <StyledListHead>
                    <Skeleton height={32} width={240} />
                    <Skeleton height={20} width={160} />
                </StyledListHead>
                <Skeleton height="100%" width="100%" />
            </StyledPaymentsList>
        )
    }
    if (freelancerPaymentsDateQuery.isError) {
        return (
            <StyledPaymentsList>
                <ErrorBanner onRetry={freelancerPaymentsDateQuery.refetch} />
            </StyledPaymentsList>
        )
    }
    if (freelancerPaymentsDateQuery.remappedData.length == 0) {
        return <NoResultsBanner />
    }

    return (
        <StyledPaymentsList>
            <StyledListHead>
                <h3>
                    {t('transactions:paid_out', {
                        amount: formatTransactionAmount({
                            amount: freelancerPaymentsDateQuery.totalPaidOut?.paid_out_photographer_currency_amount,
                            amountUSD: freelancerPaymentsDateQuery.totalPaidOut?.paid_out_amount ?? 0,
                            currency: freelancerPaymentsDateQuery.totalPaidOut?.paid_out_photographer_currency
                        })
                    })}
                </h3>
                <span>
                    {formatDateWithLocaleIntl(language, dayjs(freelancerPaymentsDateQuery.filters.from).toDate(), {
                        month: 'long',
                        year: 'numeric',
                        day: undefined
                    })}
                    {' - '}
                    {formatDateWithLocaleIntl(language, dayjs(freelancerPaymentsDateQuery.filters.to).toDate(), {
                        month: 'long',
                        year: 'numeric',
                        day: undefined
                    })}
                </span>
            </StyledListHead>
            <StyledAccordionsContainer
                data={freelancerPaymentsDateQuery.remappedData}
                endReached={freelancerPaymentsDateQuery.changePage}
                overscan={100}
                itemContent={(_, item) => <PaymentsAccordion key={item.payment_date} paymentDate={item} />}
                components={{
                    Footer: freelancerPaymentsDateQuery.isFetching ? () => <PaymentsAccordion isLoading /> : undefined
                }}
            />
        </StyledPaymentsList>
    )
}
