import {Chip} from 'src/components/chip/Chip'
import styled, {css} from 'styled-components'

export const ContainerWrapper = styled.div`
    ${({theme: {spacing, mediaQuery}}) => css`
        display: flex;
        flex-direction: column;
        padding-bottom: ${spacing * 6}px;
        gap: ${spacing * 3}px;
        ${mediaQuery.LG} {
            gap: ${spacing * 2.5}px;
        }
    `}
`

export const FiltersWrapper = styled.div`
    ${({theme: {spacing, colors, mediaQuery}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: ${spacing * 4}px;
        padding-bottom: ${spacing * 4}px;
        border-bottom: 1px solid ${colors.smokeGrey};
        ${mediaQuery.LG} {
            flex-direction: column;
            border: unset;
            align-items: flex-start;
        }
    `}
`
export const StyledCoordinationStatusChip = styled(Chip)(
    ({theme: {spacing}}) => css`
        padding: ${spacing}px ${spacing * 2}px ${spacing}px ${spacing * 1.5}px;
    `
)
