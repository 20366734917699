import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalTaskDefaultTrigger = styled(Flexbox)<{$isDragActive: boolean}>(
    ({theme: {spacing, palette, typography, transition}, $isDragActive}) => css`
        text-align: center;
        padding: ${spacing * 7}px;
        border: 2px dashed ${palette.neutral[300]};
        border-radius: ${spacing * 4}px;
        cursor: pointer;
        ${transition};
        background-color: ${$isDragActive ? palette.neutral['50'] : palette.neutral.white};

        & em {
            ${typography.textSm};
            color: ${palette.neutral[600]};
        }
    `
)
