import styled, {css} from 'styled-components'
import {CheckboxProps} from './Checkbox'

export const Root = styled.div`
    ${({theme}) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing}px;
    `}
`

export const LabelWrapper = styled.label<{$labelPosition: CheckboxProps['labelPosition']}>`
    ${({theme, $labelPosition}) => css`
        display: flex;
        flex-direction: ${$labelPosition == 'right' ? 'row' : 'row-reverse'};
        width: max-content;
        max-width: 100%;
        gap: ${theme.spacing * 2}px;
    `}
`

//todo: replace LabelText with a generic LabelText component (for checkbox and radio)
export const LabelText = styled.span<{$disabled?: boolean}>`
    ${({theme, $disabled}) => css`
        ${theme.typography.textSm};
        cursor: ${$disabled ? 'not-allowed' : 'pointer'};
        color: ${$disabled ? theme.colors.lightBlack : theme.palette.neutral.black};
    `}
`

export const Input = styled.input`
    ${({theme, disabled}) => css`
        appearance: none;
        flex: none;
        width: 20px;
        height: 20px;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        border: 1px solid ${theme.colors.grey};
        border-radius: ${theme.spacing}px;
        ${theme.transition};

        &:checked {
            fill: ${theme.palette.neutral.white};
            background: ${theme.palette.neutral.black};
            border-color: ${theme.palette.neutral.black};
            content: url("data:image/svg+xml,%3Csvg viewBox='-4 -4 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }

        &:indeterminate {
            fill: ${theme.palette.neutral.black};
            background: ${theme.palette.neutral.white};
            border-color: ${theme.colors.grey};
            content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='11' width='16' height='2' /%3E%3C/svg%3E");
        }

        &:disabled:checked {
            background: ${theme.colors.darkGrey};
            border-color: ${theme.colors.darkGrey};
        }
        &:disabled:not(:checked) {
            background: ${theme.colors.lightGrey};
        }
    `}
`

//todo: replace this component with a TextError generic component
export const TextError = styled.small`
    ${({theme}) => css`
        ${theme.typography.textXs};
        font-weight: 700;
        color: ${theme.colors.red};
    `}
`
