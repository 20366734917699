import styled, {css} from 'styled-components'
import {CustomScrollbar} from '../../../../theme/components'
import {Virtuoso} from 'react-virtuoso'

const BodyStyle = css`
    ${({theme: {palette}, $tbodyHeight}) => css`
        height: ${$tbodyHeight} !important;
        position: relative;
        background: ${palette.neutral.white};
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        overflow-y: auto !important;
        ${CustomScrollbar};
    `}
`
const ColorStyles = () => ({
    grey: css`
        border: 0;
        background: transparent;
    `
})

export const StyledTbody = styled.div`
    ${BodyStyle};
    ${({variant, theme}) => ColorStyles({theme})[variant]};
`

export const StyledPaginatedTbody = styled(Virtuoso)`
    ${BodyStyle};
    ${({variant, theme}) => ColorStyles({theme})[variant]};
`

export const StyledNoResultsMessage = styled.span`
    ${({theme: {spacing, colors, typography}}) => css`
        display: block;
        text-align: center;
        padding-top: ${spacing * 6}px;
        ${typography.displayXs};
        font-weight: 500;
        color: ${colors.grey};
    `}
`
