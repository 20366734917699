import {Spinner} from 'src/components/spinner/Spinner'
import {useDeleteHostApprovalTaskImages} from 'src/features/admin-host-approval/services/useDeleteHostApprovalTaskImages'
import {AdminHostApprovalTaskImage} from 'src/features/admin-host-approval/types'
import {AdminHostApprovalImageCard} from 'src/features/admin-host-approval/components/admin-host-approval-image-card/AdminHostApprovalImageCard'
import {StyledAdminHostApprovalRemoveImageButton} from './style'
import {Trash01Icon} from 'src/components/icon'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {AdminHostApprovalImageRoomSelector} from 'src/features/admin-host-approval/components/admin-host-approval-image-room-selector/AdminHostApprovalImageRoomSelector'
import {AdminHostApprovalImageAccessibilitySelector} from 'src/features/admin-host-approval/components/admin-host-approval-image-accessibility-selector/AdminHostApprovalImageAccessibilitySelector'
import {Switch} from 'src/components/switch/Switch'
import {useTranslation} from 'react-i18next'
import {useUpdateHostApprovalTaskImage} from 'src/features/admin-host-approval/services/useUpdateHostApprovalTaskImage'

export const AdminHostApprovalNewTaskImageCard = ({
    taskId,
    image
}: {
    taskId: number
    image: AdminHostApprovalTaskImage
}) => {
    const {t} = useTranslation()
    const {mutate: removeImage, isLoading: isLoadingRemoveImage} = useDeleteHostApprovalTaskImages({
        taskId
    })

    const {mutate: updateImage, isLoading: isLoadingUpdateImage} = useUpdateHostApprovalTaskImage({
        taskId,
        imageId: image.id
    })

    return (
        <AdminHostApprovalImageCard
            key={image.id}
            isSelected={!!(image.amenity_id && image.room_id)}
            image={image}
            startSlot={
                <StyledAdminHostApprovalRemoveImageButton
                    variant="secondary"
                    shape="square"
                    onClick={() => removeImage({image_ids: [image.id]})}
                    disabled={isLoadingRemoveImage}
                >
                    {isLoadingRemoveImage ? <Spinner size={20} /> : <Trash01Icon size={20} />}
                </StyledAdminHostApprovalRemoveImageButton>
            }
            endSlot={
                <Flexbox direction="column" gap={4}>
                    <AdminHostApprovalImageRoomSelector taskId={taskId} imageId={image.id} roomId={image.room_id} />
                    <AdminHostApprovalImageAccessibilitySelector
                        taskId={taskId}
                        imageId={image.id}
                        amenityId={image.amenity_id}
                    />
                    <Switch
                        label={t('host_approval_task:from_photo_gallery')}
                        disabled={isLoadingUpdateImage}
                        onChange={() =>
                            updateImage({
                                from_photo_gallery: image.from_photo_gallery ? 0 : 1
                            })
                        }
                        size="sm"
                        defaultChecked={image.from_photo_gallery}
                    />
                </Flexbox>
            }
        />
    )
}
