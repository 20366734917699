import {StyledButtons} from 'src/features/assignment/components/assignment-descriptions/accepted-dates-declined-description/style.ts'
import {Button} from 'src/components/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {Assignment} from 'src/features/assignment/types.ts'
// @ts-expect-error [TS7016] "We don't have types yet"
import {VideoUploadModal} from 'src/features/assignment/components/video-upload-modal/VideoUploadModal.jsx'
import {SubmitToAirbnbModal} from 'src/features/assignment/components/submit-to-airbnb-modal/SubmitToAirbnbModal.tsx'
import {AssignmentUploadVideoDropdown} from 'src/features/assignment/components/assignment-upload-video-dropdown/AssignmentUploadVideoDropdown'

export const AssignmentVideoButtons = ({assignment}: {assignment: Assignment}) => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState<'upload_video' | 'submit_to_airbnb' | null>(null)
    const [isDarylButtonClicked, setIsDarylButtonClicked] = useState(false)

    return (
        <StyledButtons>
            <AssignmentUploadVideoDropdown
                assignment={assignment}
                setIsLoadingCb={setIsLoading}
                setClickedDarylButtonCb={() => setIsDarylButtonClicked(true)}
                showUploadVideoModalCb={() => setOpenModal('upload_video')}
            />
            <Button
                variant="primary"
                onClick={() => setOpenModal('submit_to_airbnb')}
                disabled={(!isDarylButtonClicked && !assignment.can_send_to_airbnb) || isLoading}
            >
                {t('assignment:descriptions:submit_to_airbnb')}
            </Button>

            {openModal == 'upload_video' && (
                <VideoUploadModal
                    assignmentId={assignment.id}
                    darylDirectoryId={assignment.task.daryl_directory_id}
                    onClose={() => setOpenModal(null)}
                />
            )}
            {openModal == 'submit_to_airbnb' && (
                <SubmitToAirbnbModal
                    onClose={() => setOpenModal(null)}
                    assignment={assignment}
                    setSuccessModalCb={() => setOpenModal(null)}
                />
            )}
        </StyledButtons>
    )
}
