import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from './palette.ts'

const ResetStyles = createGlobalStyle`
  * {
  '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
  ' scrollbar-width': 'none' /* for Firefox */,
  }

  @font-face {
    font-family: 'Cereal';
    font-weight: 300;
    src: url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/cereal-variable/AirbnbCerealVF_W_Wght.9c00198218646e3b3f345aa4bc3fda51.woff2") format("woff2");
  }
  @font-face {
    font-family: 'Cereal';
    font-weight: 400;
    src: url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/cereal-variable/AirbnbCerealVF_W_Wght.9c00198218646e3b3f345aa4bc3fda51.woff2") format("woff2");
  }
  @font-face {
    font-family: 'Cereal';
    font-weight: 500;
    src: url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Medium-50fc004b3082375f12ff0cfb67bf8e56.woff2") format("woff2");
  }
  @font-face {
    font-family: 'Cereal';
    font-weight: 600;
    src: url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Medium-50fc004b3082375f12ff0cfb67bf8e56.woff2") format("woff2");
  }
  @font-face {
    font-family: 'Cereal';
    font-weight: 700;
    src: url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Bold-bdfb98485e7836ba31b456f65cded088.woff2") format("woff2");
  }
  @font-face {
    font-family: 'Cereal';
    font-weight: 800;
    src: url("https://a0.muscache.com/airbnb/static/airbnb-dls-web/build/fonts/Airbnb_Cereal-Bold-bdfb98485e7836ba31b456f65cded088.woff2") format("woff2");
  }

  html{
    height: 100%;
    font-size: 16px;
    font-family: 'Cereal', sans-serif;
    color: ${palette.light.neutral[900]};
    background-color: ${palette.light.neutral.white};
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  }

  body {
    font-family: 'Cereal', sans-serif; /*TODO: THE FONT FAMILY STYLE COMES FROM THE OLD LEGACY RESET*/
    height: 100%;
  }

  #root {
    height: 100%;
  }
  
  h1, h2, h3, h4, h5, h6, p, span, small {
    margin-bottom: 0;
	font-weight: inherit; /*TODO: THE FONT WEIGHT IT'S NEEDED BECAUSE OF THE OLD CONFIGURATION*/
  }
  label, input, textarea, select, button {
    font-family: inherit;
  }
  
  textarea {
    overflow-wrap: break-word;
  }
  
  //Reset Css

  /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
  *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol, ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
  }
  
  svg {
    flex-shrink: 0;
  }

  /* Removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }

  /* Revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }
  
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  
  
  /*TODO: THE FOLLOW STYLE COMES FROM THE OLD LEGACY RESET*/
  input:focus {
    outline: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
`

export default withTheme(ResetStyles)
