import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'
import {Container} from '../container/Container'

export const StyledAdminHeader = styled.header`
    ${({theme: {headerHeight, colors, palette}}) => css`
        height: ${headerHeight}px;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${colors.inputGrey};
    `}
`

export const StyledLogo = styled(Link)(
    ({theme: {typography, colors}}) => css`
        ${typography.textLg};
        font-weight: 800;
        color: ${colors.lightBlack};
        text-decoration: none;

        &:hover {
            color: ${colors.lightBlack};
        }
    `
)

export const RightLinksWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        & > div {
            padding: ${spacing * 2}px ${spacing * 4}px;
            gap: ${spacing * 2}px;
        }
    `}
`

export const LeftLinksWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 8}px;
    `}
`

export const AdminLinksWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`

export const HeaderWrapper = styled(Container)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`
