import {httpCancelShoot} from '../../../http-requests/single-assignment.http'
import {useEffect} from 'react'
import {errorHandler} from '../../../helpers/utils'
import {useDispatch, useSelector} from 'react-redux'
import {addStepToChatHistory, chatbotCancellationReasonSelector, chatbotShootSelector} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {useUserStore} from '../../../features/user/store'

export function CancelShoot({flowSource, steps, cancelBy}) {
    const dispatch = useDispatch()
    const accessToken = useUserStore(store => store.accessToken)
    const selectedShoot = useSelector(chatbotShootSelector)
    const cancellationReason = useSelector(chatbotCancellationReasonSelector)

    useEffect(() => {
        onCancelShootResults()
    }, [])

    function getCancelValues(flowsSource, steps) {
        if (!flowsSource) {
            return {
                reason_id: cancellationReason.id,
                by: cancelBy
            }
        }
        switch (flowsSource) {
            case 'MB1.2_pull':
                return {
                    reason_id: 15,
                    by: 'host',
                    notes: 'Placeholder notes' // TODO: get notes from user
                }
            case 'MB1.3_pull':
                return {
                    reason_id: 10,
                    by: 'host',
                    notes: 'Placeholder notes' // TODO: get notes from user
                }
            case 'MB1.4_pull':
                if (steps['MB1.4_options'].value.includes(';')) {
                    const reason_source = steps['MB1.4_options'].value.split(';')[1]

                    return {
                        reason_id: parseInt(steps[reason_source].value.split(' ')[0]),
                        by: steps[reason_source].value.split(' ')[1],
                        notes: steps['MB1.4_notes_options'].value
                    }
                } else {
                    return {
                        reason_id: parseInt(steps['MB1.4_options'].value.split(' ')[0]),
                        by: steps['MB1.4_options'].value.split(' ')[1],
                        // For the notes, a unique exception is used for the "no show" option, where the notes are taken from its ticket flow
                        notes:
                            steps['MB1.4_options'].value !== '13 host'
                                ? steps['MB1.4_notes_options'].value
                                : steps['MB1.4_noshow_notes_input'].value
                    }
                }
        }
    }

    const onCancelShootResults = async () => {
        try {
            getCancelValues(flowSource, steps)
            await httpCancelShoot(accessToken, selectedShoot?.id, getCancelValues(flowSource, steps))
            dispatch(
                addStepToChatHistory({
                    id: flowSource,
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Shoot Canceled] Thank you!'
                })
            )
        } catch (e) {
            errorHandler(e)
            dispatch(
                addStepToChatHistory({
                    id: flowSource,
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: '[Shoot Canceled Error] Thank you!'
                })
            )
        }
    }

    // This won't render as replace is always set to true
    return (
        <div>
            <p>{'Thank you!'}</p>
        </div>
    )
}
