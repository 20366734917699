import {RemoveButton, StyledCardFooter, StyledCheckCard, ImageWrapper, Wrapper} from './style'
import {useTranslation} from 'react-i18next'
import {RemoveIcon, RestoreIcon} from '../../../../../theme/icons'
import {httpDownloadUploadedImages, httpRemoveRestoreImages} from '../../../../../http-requests/admin/matterport.http'
import {errorHandler, formatLocaleDate} from '../../../../../helpers/utils'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {useState, useCallback} from 'react'
import {Spinner} from '../../../../../components/spinner-legacy/Spinner'
import {ConfirmUploadModal} from '../confirm-upload-modal/ConfirmUploadModal'
import {removeRestoreAccessibilityImages, taskSelector} from '../../../../../store/matterport'
import {showAlert} from '../../../../../store/appGenerics'
import {Accordion} from '../../../../../components/accordion/Accordion'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import {useUserStore} from '../../../../../features/user/store'

const RESTORE = 'restore'
const DELETE = 'delete'
const TO_UPLOAD = 'to_upload'
const UPLOADED = 'uploaded'
const REMOVED = 'removed'

export const ImagesListView = ({
    imagesFromDropzone = {accepted: [], rejected: []},
    setImagesFromDropzoneCb,
    sectionType = TO_UPLOAD,
    imagesList = [],
    setFullScreenImageCb
}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const task = useSelector(taskSelector)
    const [isLoading, setIsLoading] = useState(false)
    const [showConfirmUploadModal, setShowConfirmUploadModal] = useState(false)

    const setShowConfirmUploadModalCb = useCallback(show => {
        setShowConfirmUploadModal(show)
    }, [])

    const onDownloadImages = async () => {
        try {
            setIsLoading(true)
            const {data} = await httpDownloadUploadedImages(adminAccessToken, taskId)

            const url = window.URL.createObjectURL(new Blob([data], {type: 'application/zip'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${task?.external_id} - ${formatLocaleDate(Date.now())}`)
            document.body.appendChild(link)
            link.click()
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    const onReset = () => {
        setImagesFromDropzoneCb({accepted: [], rejected: []})
    }

    const onRemoveImage = imageToRemoveIndex => {
        setImagesFromDropzoneCb({
            ...imagesFromDropzone,
            accepted: imagesFromDropzone.accepted.filter((image, index) => index !== imageToRemoveIndex)
        })
    }

    const onRemoveRestoreImages = async (imageId, type) => {
        try {
            setIsLoading(true)
            await httpRemoveRestoreImages(adminAccessToken, taskId, imageId, type)
            dispatch(removeRestoreAccessibilityImages({imageId, type}))
            dispatch(
                showAlert({
                    message: type === DELETE ? t('admin:remove_image_success') : t('admin:restore_image_success'),
                    level: 'success'
                })
            )
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Wrapper>
            {showConfirmUploadModal && (
                <ConfirmUploadModal
                    setShowConfirmUploadModalCb={setShowConfirmUploadModalCb}
                    imagesFromDropzone={imagesFromDropzone}
                    setImagesFromDropzoneCb={setImagesFromDropzoneCb}
                />
            )}
            <Accordion
                bodyClassName="accordionBody"
                title={t(
                    sectionType === UPLOADED
                        ? 'admin:already_uploaded'
                        : sectionType === TO_UPLOAD
                        ? 'admin:to_upload'
                        : 'admin:discarded',
                    {length: imagesList?.length || 0}
                )}
                defaultOpen={sectionType !== UPLOADED}
                animated={false}
                headButtons={
                    sectionType === UPLOADED
                        ? [
                              {
                                  text: t('admin:download'),
                                  variant: 'primaryBlack',
                                  onClick: onDownloadImages
                              }
                          ]
                        : sectionType === TO_UPLOAD
                        ? [
                              {
                                  text: t('admin:reset'),
                                  variant: 'secondary',
                                  onClick: onReset
                              },
                              {
                                  text: t('admin:upload'),
                                  variant: 'primaryBlack',
                                  onClick: () => setShowConfirmUploadModal(true)
                              }
                          ]
                        : []
                }
            >
                {!!imagesList.length &&
                    imagesList.map((image, index) => (
                        <StyledCheckCard key={index}>
                            {sectionType === REMOVED ? (
                                <RemoveButton onClick={() => onRemoveRestoreImages(image.id, RESTORE)}>
                                    <RestoreIcon />
                                </RemoveButton>
                            ) : (
                                <RemoveButton
                                    onClick={
                                        sectionType === UPLOADED
                                            ? () => onRemoveRestoreImages(image.id, DELETE)
                                            : () => onRemoveImage(index)
                                    }
                                >
                                    <RemoveIcon />
                                </RemoveButton>
                            )}
                            <ImageWrapper
                                onClick={() => {
                                    setFullScreenImageCb({
                                        url: sectionType === TO_UPLOAD ? image.preview : image.url,
                                        name: image.name
                                    })
                                }}
                            >
                                <LazyLoadImage
                                    src={sectionType === TO_UPLOAD ? image.preview : image.url}
                                    alt={image.name}
                                    effect="blur"
                                    threshold={100}
                                />
                            </ImageWrapper>
                            <StyledCardFooter>
                                <small>
                                    {t('admin:uploaded_at_date', {
                                        date: formatLocaleDate(image.updated_at)
                                    })}
                                </small>
                                <p>{image.name || '-'}</p>
                            </StyledCardFooter>
                        </StyledCheckCard>
                    ))}
            </Accordion>
            {isLoading && <Spinner />}
        </Wrapper>
    )
}
