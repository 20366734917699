import {Overlay, SpinnerWrapper, SpinnerStyled, ProgressBar} from './style.ts'
import {SVGAttributes} from 'react'

export interface SpinnerProps extends SVGAttributes<SVGElement> {
    className?: string
    size?: number
    color?: string
    overlay?: boolean
    overlayFullscreen?: boolean
    center?: boolean
    progress?: number | null
    strokeLineCap?: SVGAttributes<SVGCircleElement>['strokeLinecap']
}

/**
 * @deprecated use the new Spinner instead
 * */
export const Spinner = ({
    className,
    size = 50,
    color = '#333',
    strokeLineCap = 'square',
    overlay = true,
    overlayFullscreen = false,
    center = true,
    progress = null,
    ...rest
}: SpinnerProps) => {
    return (
        <>
            {overlay && <Overlay $overlayFullscreen={overlayFullscreen} />}
            <SpinnerWrapper $center={center} $overlayFullscreen={overlayFullscreen}>
                <SpinnerStyled className={className} viewBox="0 0 50 50" $size={size} {...rest}>
                    <circle
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        stroke={color}
                        strokeWidth="3"
                        strokeLinecap={strokeLineCap}
                    />
                </SpinnerStyled>
                {progress !== null && (
                    <ProgressBar $progress={progress}>
                        <p>
                            {progress}
                            {'%'}
                        </p>
                        <div></div>
                    </ProgressBar>
                )}
            </SpinnerWrapper>
        </>
    )
}
