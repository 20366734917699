import {Modal} from 'src/components/modal/Modal.tsx'
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalParagraph,
    ModalTitle,
    ModalXCloseButton
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {StyledOption} from 'src/features/assignment/components/cannot-schedule-modal/style.ts'
import {Dispatch, SetStateAction, useState} from 'react'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {ModalName} from 'src/features/assignment/components/schedule-buttons/ScheduleButtons.tsx'
import {useAssignmentCoordination} from 'src/features/assignment/services/useAssignmentCoordination.ts'
import {ServiceType} from 'src/features/assignment/types.ts'

export const CannotScheduleModal = ({
    onClose,
    assignmentId,
    taskId,
    setOpenModal,
    serviceType
}: {
    onClose: () => void
    assignmentId: number
    taskId: number
    setOpenModal: Dispatch<SetStateAction<ModalName | null>>
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()
    const [selectedOption, setSelectedOption] = useState<
        null | 'unresponsive_host' | 'host_not_ready' | 'not_available' | 'dates_dont_match'
    >(null)
    const dontMatchDatesMutation = useAssignmentCoordination({assignmentId, taskId})

    const onSubmit = () => {
        if (selectedOption == 'dates_dont_match') {
            dontMatchDatesMutation.mutate({status: 'dates_dont_match'})
        }
        if (selectedOption == 'host_not_ready') {
            setOpenModal('host_not_ready')
        }
        if (selectedOption == 'not_available') {
            setOpenModal('not_available')
        }
        if (selectedOption == 'unresponsive_host') {
            setOpenModal('unresponsive_host')
        }
    }

    return (
        <Modal onOverlayClick={onClose} width={480}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <ModalBody>
                <Flexbox direction="column" gap={2}>
                    <ModalTitle>{t('assignment:descriptions:cannot_schedule_modal:title')}</ModalTitle>
                    <ModalParagraph>{t('assignment:descriptions:cannot_schedule_modal:subtitle')}</ModalParagraph>
                </Flexbox>
                <Flexbox direction="column" gap={4}>
                    <StyledOption
                        $isActive={selectedOption == 'unresponsive_host'}
                        onClick={() => setSelectedOption('unresponsive_host')}
                    >
                        <h4>{t('assignment:descriptions:cannot_schedule_modal:option1_title')}</h4>
                        <p>{t('assignment:descriptions:cannot_schedule_modal:option1_description')}</p>
                    </StyledOption>
                    <StyledOption
                        $isActive={selectedOption == 'host_not_ready'}
                        onClick={() => setSelectedOption('host_not_ready')}
                    >
                        <h4>{t('assignment:descriptions:cannot_schedule_modal:option2_title')}</h4>
                        <p>{t(`assignment:descriptions:cannot_schedule_modal:option2_description:${serviceType}`)}</p>
                    </StyledOption>
                    <StyledOption
                        $isActive={selectedOption == 'not_available'}
                        onClick={() => setSelectedOption('not_available')}
                    >
                        <h4>{t(`assignment:descriptions:cannot_schedule_modal:option3_title`)}</h4>
                        <p>{t('assignment:descriptions:cannot_schedule_modal:option3_description')}</p>
                    </StyledOption>
                    <StyledOption
                        $isActive={selectedOption == 'dates_dont_match'}
                        onClick={() => setSelectedOption('dates_dont_match')}
                    >
                        <h4>{t('assignment:descriptions:cannot_schedule_modal:option4_title')}</h4>
                        <p>{t(`assignment:descriptions:cannot_schedule_modal:option4_description:${serviceType}`)}</p>
                    </StyledOption>
                </Flexbox>
            </ModalBody>
            <ModalFooter>
                <Button variant="tertiary" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button disabled={!selectedOption || dontMatchDatesMutation.isLoading} onClick={onSubmit}>
                    {selectedOption == 'dates_dont_match'
                        ? t('commons:confirm')
                        : t('assignment:descriptions:cannot_schedule_modal:cta')}
                    {dontMatchDatesMutation.isLoading && <Spinner size={20} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
