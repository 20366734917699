import styled, {css} from 'styled-components'
import {Badge} from 'src/components/badge/Badge.tsx'

export const StyledEmptyState = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        color: ${palette.neutral['500']};
    `
)

export const StyledStatusBadge = styled(Badge)(
    ({theme: {palette, typography}}) => css`
        color: ${palette.neutral['500']};
        ${typography.textXs};
        min-height: 24px;
    `
)

export const StyledTruncatedText = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const StyledAdminName = styled(StyledTruncatedText)(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['900']};
        font-weight: 500;
    `
)
