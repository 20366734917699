import styled, {css} from 'styled-components'

export const StyledTypeTabsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        align-items: center;
        box-sizing: border-box;
    `}
`

export const StyledTypeTab = styled.div`
    ${({theme: {colors, spacing, typography, transition, palette}, isActive}) => css`
        cursor: pointer;
        box-sizing: border-box;
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        border: 1px solid ${isActive ? colors.borderGrey : 'unset'};
        border-radius: 8px;
        color: ${isActive ? colors.darkGrey : colors.steelGrey};
        background: ${isActive ? palette.neutral.white : 'transparent'};
        ${typography.textSm};
        font-weight: 600;
        ${transition};
    `}
`

export const StyledSubtitle = styled.h6`
    ${({theme: {colors, spacing, typography}}) => css`
        color: ${colors.lightBlack};
        ${typography.textXl};
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledParagraph = styled.p`
    ${({theme: {colors, spacing}}) => css`
        color: ${colors.darkGrey};
        margin-bottom: ${spacing * 6}px;
    `}
`

export const StyledSelectorLabel = styled.p`
    ${({theme: {colors, typography, spacing}}) => css`
        color: ${colors.greyGhosted};
        ${typography.textSm};
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledAnalyticsCardGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing * 8}px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
    `}
`
