import {parseAxiosPromise} from 'src/helpers/helpers'
import {QUERY_KEYS} from 'src/queryClient'
import {httpGetAccessibilityFeatures} from 'src/features/admin-host-approval/services/adminHostApproval.http'
import {AccessibilityFeature} from 'src/features/admin-host-approval/types'
import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'

export const useAccessibilityFeatures = (options?: UseQueryOptions<AccessibilityFeature[]>) => {
    return useQuery({
        queryKey: [QUERY_KEYS.ACCESSIBILITY_FEATURES],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAccessibilityFeatures(),
                responseShape: AccessibilityFeature.array(),
                onZodError: captureException
            }),
        ...options
    })
}
