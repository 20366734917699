import styled, {css} from 'styled-components'
import {Flexbox} from '../flexbox/Flexbox'

export const StyledProgressBar = styled(Flexbox)<{$progressValue: number}>(
    ({theme: {palette, typography}, $progressValue}) => css`
        width: 100%;
        div {
            height: 8px;
            border-radius: 4px;
        }

        & > .progress-bar-track {
            width: 100%;
            background-color: ${palette.neutral[200]};
            & div {
                background-color: ${palette.grayModern[600]};
                width: ${$progressValue}%;
            }
        }

        & > .progress-bar-indicator {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[700]};
        }
    `
)
