import {StyledEmptyMessage, StyledTitle} from './style'
import {useSelector} from 'react-redux'
import {taskSelector, toUploadListSelector} from '../../../../../../store/matterport'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {DownloadIcon} from '../../../../../../theme/icons'
import {Button} from '../../../../../../components/button/Button'
import {errorHandler, formatLocaleDate} from '../../../../../../helpers/utils'
import {useParams} from 'react-router'
import {useState} from 'react'
import {httpDownloadAllImages} from '../../../../../../http-requests/admin/matterport.http'
import {Spinner} from '../../../../../../components/spinner-legacy/Spinner'
import {List} from '../list/List'
import {useUserStore} from '../../../../../../features/user/store'

export const ToUploadList = ({hasSubmitError, rooms = [], amenities = []}) => {
    const {t} = useTranslation()
    const {id: taskId} = useParams()
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const task = useSelector(taskSelector)
    const toUploadList = useSelector(toUploadListSelector)
    const [isLoading, setIsLoading] = useState(false)

    const onDownloadAllImages = async () => {
        try {
            setIsLoading(true)
            const {data} = await httpDownloadAllImages(adminAccessToken, taskId)

            const url = window.URL.createObjectURL(new Blob([data], {type: 'application/zip'}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${task?.external_id} - ${formatLocaleDate(Date.now())}`)
            document.body.appendChild(link)
            link.click()
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div>
            <StyledTitle>
                <h2>{t('admin:upload_to_mys', {length: toUploadList.length})}</h2>
                {!!toUploadList.length && (
                    <Button onClick={onDownloadAllImages} variant="secondary">
                        {t('admin:download_all_images')}
                        <DownloadIcon />
                    </Button>
                )}
            </StyledTitle>
            {toUploadList.length ? (
                <List
                    isDeleted={false}
                    imagesList={toUploadList}
                    hasSubmitError={hasSubmitError}
                    amenities={amenities}
                    rooms={rooms}
                />
            ) : (
                <StyledEmptyMessage>{t('admin:no_upload_images')}</StyledEmptyMessage>
            )}

            {isLoading && <Spinner overlayFullscreen size={120} />}
        </div>
    )
}

ToUploadList.propTypes = {
    hasSubmitError: PropTypes.bool,
    rooms: PropTypes.array
}
