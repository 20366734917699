import styled, {css} from 'styled-components'

export const StyledToolbar = styled.div(
    ({theme: {colors}}) => css`
        height: 88px;
        background-color: ${colors.bgYellow};
    `
)

export const TextWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `
)

export const LeftSideWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 3}px;
        align-items: center;
        height: 100%;
    `
)

export const RightSideWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
`

export const StyledSubtext = styled.p(
    ({theme: {colors, typography}}) => css`
        ${typography.textXs};
        color: ${colors.lightBlack};
        font-weight: 500;
    `
)

export const StyledMainText = styled.h3(
    ({theme: {colors, typography}}) => css`
        ${typography.textMd};
        color: ${colors.darkYellow};
        font-weight: 500;
    `
)
