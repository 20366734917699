import {QueryClient} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 300000, //5 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    ACTIVE_SHOOTS_RANGE: 'activeShootsByRange',
    ACTIVE_SHOOTS_TABLE: 'activeShootsTable',
    ADMINS: 'admins',
    ASSIGNMENT: 'assignment',
    SPACES: 'spaces',
    COMPENSATION: 'compensation',
    ASSIGNMENTS_COUNT: 'assignments-count',
    NEW_SHOOTS: 'newShoots',
    CURRENT_SHOOTS: 'currentShoots',
    COMPLETED_SHOOTS: 'completedShoots',
    DECLINE_REASONS: 'decline_reasons',
    TRANSACTIONS: 'transactions',
    FREELANCER_PAYMENTS_DATES: 'freelancer-payments-dates',
    FREELANCER_PAYMENTS: 'freelancer-payments',
    UNRESPONSIVE_HOST_REASONS: 'unresponsive-host-reasons',
    PARTNERS: 'partners',
    HOSTS: 'hosts',
    PHOTOGRAPHERS: 'photographers',
    ADMIN_QUOTE: 'adminSelectedQuote',
    ADMIN_QUOTE_LOGS: 'adminQuoteLogs',
    ADMIN_GENERATE_TRANSACTIONS_STATS: 'admin-generate-transactions-stats',
    ADMIN_PAYMENTS_STATUS_STATS: 'admin-payment-status-stats',
    ALL_PHOTOGRAPHERS: 'all_photographers',
    ADMIN_HOST_APPROVAL_TASK: 'admin-host-approval-task',
    ADMIN_HOST_APPROVAL_TASKS: 'admin-host-approval-tasks',
    ADMIN_HOST_APPROVAL_TASK_TYPES: 'admin-host-approval-task-types',
    ADMIN_HOST_APPROVAL_TASK_ROOMS: 'admin-host-approval-task-rooms',
    ADMIN_HOST_APPROVAL_TASK_IMAGES: 'admin-host-approval-task-images',
    ACCESSIBILITY_FEATURES: 'accessibility-features',
    ADMIN_HOST_APPROVAL_PROJECTS: 'admin-host-approval-projects',
    ADMIN_HOST_APPROVAL_STATUSES_COUNTER: 'admin-host-approval-tabs-counter',
    ADMIN_HOSTS: 'admin-hosts'
} as const

export const QUERY_ACTION_KEYS = {
    ASSIGNMENT_DECLINED: 'assignment_declined',
    ASSIGNMENT_ACCEPTED: 'assignment_accepted',
    ASSIGNMENT_SCHEDULED: 'assignment_scheduled',
    SHOOT_UNSCHEDULED: 'shoot_unscheduled',
    ASSIGNMENT_CHANGE_COORDINATION_STATUS: 'assignment_change_coordination_status',
    ASSETS_UPLOADED: 'assets_uploaded',
    SUBMIT_TO_AIRBNB: 'submit_to_airbnb',
    HOST_APPROVAL_TASK_TO_PENDING_HOST_APPROVAL: 'host_approval_task_to_pending_host_approval',
    HOST_APPROVAL_TASK_TO_READY_FOR_REVIEW: 'host_approval_task_to_ready_for_review',
    HOST_APPROVAL_TASK_TO_COMPLETED: 'host_approval_task_to_completed'
} as const

export const MUTATION_KEYS = {
    ACCEPT_ASSIGNMENT: 'accept-assignment',
    DECLINE_ASSIGNMENT: 'decline-assignment',
    SCHEDULE_ASSIGNMENT: 'decline-assignment',
    ASSIGNMENT_MANAL_COORDINATION: 'assignment-manual-coordination',
    ASSIGNMENT_BOH: 'assignment-boh',
    EDIT_PARTNER: 'edit-partner',
    GENERATE_TRANSACTIONS: 'generate-transactions',
    UPDATE_PERSONAL_NOTES: 'update-personal-notes',
    SUBMIT_TO_AIRBNB: 'submit-to-airbnb',
    UNSCHEDULE_ASSIGNMENT: 'unschedule-assignment',
    ASSIGNMENT_CONTACT_HOST: 'contact-host',
    ASSIGNMENT_HOST_RESPONSIVE: 'host-responsive',
    HOST_APPROVAL_TASK_UPLOAD: 'host-approval-task-upload',
    HOST_APPROVAL_TASK_IMAGES_DELETE: 'host-approval-task-images-delete',
    HOST_APPROVAL_TASK_IMAGES_UPDATE: 'host-approval-task-images-update',
    HOST_APPROVAL_SEND_TASK_TO_APPROVAL: 'host-approval-send-task-to-approval'
} as const
