import {useTranslation} from 'react-i18next'
import {ArrowRightIcon} from '../../../../../assets/icons/ArrowRightIcon'
import {StyledOpenIcon} from './style'
import PropTypes from 'prop-types'

export const OpenButton = ({onClick, showText = false}: {onClick?: () => void; showText?: boolean}) => {
    const {t} = useTranslation()

    return (
        <StyledOpenIcon $showText={showText} onClick={onClick}>
            {showText && t('commons:view_more')}
            <ArrowRightIcon />
        </StyledOpenIcon>
    )
}

OpenButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    showText: PropTypes.bool
}
