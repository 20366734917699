import axios from 'axios'
import {FeaturesRatingModalProps} from 'src/components/features-rating-modal/FeaturesRatingModal.tsx'

export const httpGetMe = (accessToken: string) => {
    return axios.get('/showUser', {
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

type HttpChangePasswordPayload = {
    current_password: string
    new_password: string
    password_confirmation: string
}
export const httpChangePassword = (accessToken: string, payload: HttpChangePasswordPayload) => {
    return axios.post('/user/change-password', payload, {headers: {Authorization: `Bearer ${accessToken}`}})
}

type HttpChangeSMSOptionPayload = {
    optin_sms: boolean
}
export const httpChangeSMSOption = (accessToken: string, payload: HttpChangeSMSOptionPayload) => {
    return axios.post('/photographer/opt-in-sms', payload, {headers: {Authorization: `Bearer ${accessToken}`}})
}

type HttpChangeAvailabilityPayload = {
    shoot_available: number
    region: string
}
export const httpChangeAvailability = (accessToken: string, payload: HttpChangeAvailabilityPayload) => {
    return axios.post('/photographer/update', payload, {headers: {Authorization: `Bearer ${accessToken}`}})
}

export const httpGetAllRegions = (accessToken: string) => {
    return axios.get('/regions', {
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpGetFilteredMarketSelect = (accessToken: string, filterName: string, filterValue: string) => {
    return axios.get(`/geoSelect`, {
        params: {[filterName]: filterValue},
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpChangeUserMarkets = (accessToken: string, values: string[]) => {
    return axios.post('/photographer/geos', {geos: values}, {headers: {Authorization: `Bearer ${accessToken}`}})
}

export const httpSendRating = (accessToken: string, ratingScore: number, type: FeaturesRatingModalProps['type']) => {
    const section = () => {
        switch (type) {
            case 'new-assignment':
                return 'Assignment Page - July 2024'
            default:
                return 'Payment Section - July 2023'
        }
    }
    return axios.post(
        '/rate',
        {rating: ratingScore, section: section()},
        {headers: {Authorization: `Bearer ${accessToken}`}}
    )
}

export const httpUploadAvatar = (accessToken: string, userId: number, image: FormData) => {
    return axios.post(`/photographers/${userId}/profile-photo`, image, {
        headers: {Authorization: `Bearer ${accessToken}`}
    })
}

export const httpChangeAvatar = (accessToken: string, userId: number, image: FormData) => {
    return axios.patch(
        `/photographers/${userId}/update`,
        {profile_photo_url: image},
        {
            headers: {Authorization: `Bearer ${accessToken}`}
        }
    )
}

export const httpChangeBio = (accessToken: string, userId: number, description: string) => {
    return axios.patch(
        `/photographers/${userId}/update`,
        {description},
        {
            headers: {Authorization: `Bearer ${accessToken}`}
        }
    )
}
