import styled, {css} from 'styled-components'

export const StyledRoomsAmenitiesImagesGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: ${spacing * 4}px;
        gap: ${spacing * 6}px;
    `
)

export const StyledHostApprovalRoomsAmenitiesTitle = styled.h3<{$isBold?: boolean}>(
    ({theme: {spacing}, $isBold}) => css`
        padding: 0 ${spacing * 6}px;
        width: 100%;
        ${$isBold &&
        css`
            font-weight: 500;
        `}
    `
)

export const StyledHostApprovalRoomsAmenitiesImagesEmpty = styled.div(
    ({theme: {spacing}}) => css`
        padding-bottom: ${spacing * 6}px;
    `
)
