import {httpGetIssueDate} from '../../../http-requests/chatbot.http'
import {errorHandler} from '../../../helpers/utils'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {addStepToChatHistory, chatbotShootSelector} from '../../../store/chatbot'
import {CHATBOT_SOURCES} from '../../../helpers/constants'
import {useUserStore} from '../../../features/user/store'

export const GetIssueDate = ({flowSource, triggerNextStep}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const accessToken = useUserStore(store => store.accessToken)
    const selectedShoot = useSelector(chatbotShootSelector)
    const [isLoading, setIsLoading] = useState(true)
    const [issueDate, setIssueDate] = useState(null)
    const {airbnbId, entityId, shootMediaId} = {
        airbnbId: selectedShoot?.entity?.external_id,
        entityId: selectedShoot?.entity?.id,
        shootMediaId: selectedShoot?.quote?.task_media_id
    }

    useEffect(() => {
        onGetIssueDate()
    }, [])

    const onGetIssueDate = async () => {
        try {
            const {data} = await httpGetIssueDate(accessToken, entityId, shootMediaId)
            const paymentDate = data['payment_date']
            if (paymentDate) setIssueDate(paymentDate)
            dispatch(
                addStepToChatHistory({
                    id: flowSource,
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: paymentDate
                        ? `The payment for Airbnb ID #${airbnbId} has been issued on ${paymentDate}. Please check the Transaction History in your Airbnb account for confirmation.`
                        : `The payment for Airbnb ID #${airbnbId} has not been issued yet. It will be issued within the next 14 working days. Thank you for your patience!`
                })
            )
        } catch (e) {
            errorHandler(e)
            dispatch(
                addStepToChatHistory({
                    id: flowSource,
                    source: CHATBOT_SOURCES.CHATBOT,
                    text: `[Pull payment date error] The payment for Airbnb ID #${airbnbId} has not been issued yet. It will be issued within the next 14 working days. Thank you for your patience!`
                })
            )
        } finally {
            setIsLoading(false)
            triggerNextStep({trigger: 'send_log'})
        }
    }

    return isLoading ? (
        `${t('chatbot:get_issue_date:checking')}`
    ) : issueDate ? (
        <p>
            <Trans
                i18nKey="chatbot:get_issue_date:get_issue_date_yes"
                components={[<b />]}
                values={{airbnbId: airbnbId, issueDate: issueDate}}
            />
        </p>
    ) : (
        <p>
            <Trans
                i18nKey="chatbot:get_issue_date:get_issue_date_no"
                components={[<b />]}
                values={{airbnbId: airbnbId}}
            />
        </p>
    )
}
