import styled, {css} from 'styled-components'
import {Container} from 'src/components/container/Container.tsx'

export const StyledMatterport = styled.div`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    overflow: hidden;
`

export const StyledHeading = styled.div`
    ${({theme: {palette, typography}}) => css`
        background: ${palette.neutral['200']};
        height: 120px;
        display: flex;
        align-items: center;
        & h1 {
            ${typography.displayMd};
        }
    `}
`

export const StyledContent = styled(Container)(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 8}px;
        padding-bottom: ${spacing * 8}px;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${spacing * 8}px;
        overflow: hidden;
    `
)
