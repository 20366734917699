import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'

import {Spinner} from '../../../components/spinner-legacy/Spinner'

import {errorHandler} from '../../../helpers/utils'
import {httpAdminOneLoginLogout} from '../../../http-requests/admin/auth.http'

import {ErrorIconWrapper, LogoutErrorWrapper, ErrorTitleWrapper} from './style'
import {useUserStore} from '../../../features/user/store'

export const AdminLogout = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const resetUser = useUserStore(store => store.resetUser)
    const adminAccessToken = useUserStore(store => store.adminAccessToken)
    const [logoutError, setLogoutError] = useState(false)

    useEffect(() => {
        onLogout()
    }, [])

    const onLogout = async () => {
        try {
            const {data: redirectUrl} = await httpAdminOneLoginLogout(adminAccessToken)
            resetUser('admin')
            window.location.href = redirectUrl
        } catch (e) {
            setLogoutError(true)
            errorHandler(e)
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 position-relative">
                    {logoutError ? (
                        <LogoutErrorWrapper>
                            <ErrorIconWrapper fill={theme.colors.red} />
                            <ErrorTitleWrapper>{t('errors:default')}</ErrorTitleWrapper>
                        </LogoutErrorWrapper>
                    ) : (
                        <Spinner size={120} center />
                    )}
                </div>
            </div>
        </div>
    )
}
