import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminHostApprovalImageCard = styled(Flexbox)<{$isSelected?: boolean; $isSelectable?: boolean}>(
    ({theme: {spacing, palette}, $isSelected}) => css`
        position: relative;
        padding: ${spacing * 2}px;
        border-radius: 12px;
        overflow: hidden;
        & > h4 {
            font-weight: 500;
        }

        ${$isSelected &&
        css`
            outline: ${palette.neutral.black} solid 4px;
            outline-offset: 4px;
        `}
    `
)

export const StyledAdminHostApprovalImageCardTitle = styled.h4`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const StyledAdminHostApprovalImageCardImg = styled.img<{$isLoading?: boolean}>(
    ({theme: {palette, shadows}, $isLoading}) => css`
        display: block;
        background: ${palette.neutral.white};
        aspect-ratio: 3/2;
        width: 100%;
        object-fit: contain;
        box-shadow: ${shadows.sm};
        border-radius: 12px;
        transition: opacity 250ms ease-in-out;
        ${$isLoading &&
        css`
            opacity: 0.6;
        `}
    `
)

export const StyledAdminHostApprovalImageCardPlaceholder = styled(Flexbox)(
    ({theme: {palette, shadows, typography}}) => css`
        ${typography.textSm};
        background: ${palette.neutral.white};
        cursor: pointer;
        border-radius: 12px;
        aspect-ratio: 3/2;
        width: 100%;
        font-weight: 500;
        box-shadow: ${shadows.sm};
    `
)

export const StyledAdminHostApprovalImageCardSkeleton = styled.div`
    display: flex;
    svg {
        flex-shrink: initial;
    }
`
